import { connect } from 'react-redux';
import { ButtonLink, createStyles, makeStyles, Theme } from 'worldapp-ui/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { tasksConfigurableFiltersCreator } from '../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import { getIsActiveFiltersSetModified } from '../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { AppState } from '../../redux/AppState.types';

interface ResetAllFiltersButtonProps {
    resetActiveFiltersSet: () => void;
    enabled: boolean;
}
export const mapStateToProps = (state: AppState) => {
    return {
        enabled: getIsActiveFiltersSetModified(state),
    };
};
const mapDispatchToProps = mapCreatorsToDispatchProps({
    resetActiveFiltersSet: tasksConfigurableFiltersCreator.resetActiveFiltersSet,
});

export const useResetAllFiltersButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        resetAllButton: {
            color: theme.palette.rubyRed.main,
        },
    }),
);

export const ResetAllFiltersButtonComponent = (
    props: ResetAllFiltersButtonProps,
): JSX.Element | null => {
    const { resetActiveFiltersSet, enabled } = props;
    const classes = useResetAllFiltersButtonStyles();
    const { t } = useTranslation();

    return enabled ? (
        <ButtonLink
            variant={'subtitle2'}
            onClick={resetActiveFiltersSet}
            className={classes.resetAllButton}
            data-testid="tasks_filters-reset_all_button"
        >
            {t('TaskFilters.ResetAllButton')}
        </ButtonLink>
    ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetAllFiltersButtonComponent);
