import moment from 'moment';
import { AppState } from '../AppState.types';
import { CalendarView } from './calendar.types';
import { CONTENT_CATEGORY_TASKS } from '../content/category.types';

export const getCalendarView = ({ calendar }: AppState): CalendarView => calendar.calendarView;
export const getWeekendsEnabled = ({ calendar }: AppState): boolean => calendar.weekendsEnabled;
export const getSelectedDate = ({ calendar }: AppState): Date => calendar.selectedDate;

export const getIsCalendarDayView = ({ calendar, menu }: AppState): boolean =>
    menu.navigation.activeCategory === CONTENT_CATEGORY_TASKS &&
    menu.tasksView === 'calendar' &&
    calendar.calendarView === CalendarView.Day;

export const getFirstDayOfCalendar = (state: AppState): Date => {
    switch (getCalendarView(state)) {
        case CalendarView.Month:
            return moment(getSelectedDate(state)).startOf('month').startOf('week').toDate();
        case CalendarView.Week:
            return moment(getSelectedDate(state)).startOf('week').toDate();
        case CalendarView.Day:
        default:
            return getSelectedDate(state);
    }
};
export const getLastDayOfCalendar = (state: AppState): Date => {
    switch (getCalendarView(state)) {
        case CalendarView.Month:
            return moment(getSelectedDate(state))
                .startOf('month')
                .startOf('week')
                .add(6, 'weeks')
                .toDate();
        case CalendarView.Week:
            return moment(getSelectedDate(state)).startOf('week').add(1, 'week').toDate();
        case CalendarView.Day:
        default:
            return getSelectedDate(state);
    }
};

export default {
    getCalendarView,
    getSelectedDate,
    getIsCalendarDayView,
};
