import { Theme, makeStyles, lighten } from 'worldapp-ui/shared';

const POPUP_FOOTER_HEIGHT = 80;

export const startFormPopupStyles = makeStyles((theme: Theme) => ({
    heading: {
        color: theme.palette.neutral[65],
    },
    buttonGroup: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        gap: 16,
        '&[data-ismobile="true"]': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    item: {
        width: '100%',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
    },
    labelSelected: {
        color: theme.palette.primary.main,
    },
    labelText: {
        marginLeft: theme.spacing(),
    },
    search: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    formGroup: {
        width: '100%',
    },
    formControlLabelRoot: {
        borderRadius: theme.spacing(0.5),
        margin: 0,
        color: theme.palette.neutral[75],
        width: '100%',
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.9),
            color: theme.palette.neutral[75],
        },
    },
    formControlLabel: {
        overflow: 'hidden',
        marginRight: theme.spacing(),
    },
    noSearchResults: ({ nothingFoundBottom }: { nothingFoundBottom?: number }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: nothingFoundBottom ? nothingFoundBottom - POPUP_FOOTER_HEIGHT : 'auto',
        marginTop: theme.spacing(),
        color: theme.palette.neutral[65],
    }),
}));
