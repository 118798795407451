import { Converter } from '../location.types';

export const taskDetailViewConverter: Converter = {
    stateToURL: state => {
        if (state.taskDetailView) {
            return {
                query: { details: '' },
            };
        }
    },
    URLToState: url => ({
        taskDetailView: !!url.query.details,
    }),
};
