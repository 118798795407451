import { TaskDefinition } from '../taskDefinitions/taskDefinitions.types';

export const getStatusToIdMap = (
    taskDefinitions: TaskDefinition[],
    hideCompleted?: boolean,
): Record<string, number[]> => {
    const statusMap: Record<string, number[]> = {};

    taskDefinitions.forEach((taskDefinition: TaskDefinition) => {
        taskDefinition.statuses.forEach(({ id, title, closed }) => {
            const hideStatus = hideCompleted && closed;
            if (!hideStatus) {
                if (!statusMap[title]) statusMap[title] = [];
                statusMap[title].push(id);
            }
        });
    });

    return statusMap;
};
