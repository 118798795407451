import React from 'react';
import { FormControlLabel, Grid, Radio, Typography } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actionStyles } from '../ActionStyles.styles';
import { isTaskStatusDisabled } from '../../../redux/content/validateTaskActions';
import { tasksActionsCreator } from '../../../redux/tasksActions/tasksActions';
import { getTasksActionsState } from '../../../redux/tasksActions/taskActionsSelector';
import { getTaskDefinitionsById } from '../../../redux/taskDefinitions/taskDefinitionsSelector';
import { ChangeStatusState } from '../../../redux/tasksActions/tasksActions.types';
import { deviceType } from '../../../utils/browser.utils';

export const ChangeStatusContent = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const tasksActions = useSelector(getTasksActionsState, shallowEqual);
    const taskDefinitions = useSelector(getTaskDefinitionsById);
    const { task, bulkActionsTasks, bulkUpdates } = tasksActions as ChangeStatusState;
    const taskDefinition =
        (task && taskDefinitions[task.taskDefinitionId]) ||
        (bulkActionsTasks && taskDefinitions[bulkActionsTasks[0].taskDefinitionId]);

    const currentStatusId = (task && task.statusId) || bulkUpdates.statusId;
    const onChangeCurrentStatusId = (event: React.ChangeEvent<HTMLInputElement>) =>
        dispatch(tasksActionsCreator.changeCurrentStatusId(parseInt(event.target.value, 10)));

    const classes = actionStyles();
    const isMobileDevice = deviceType === 'mobile';

    return (
        <Grid
            container={true}
            spacing={isMobileDevice ? 0 : 1}
            direction="column"
            className={classes.container}
        >
            {taskDefinition?.statuses.map((status, id) => {
                const isDisabled = isTaskStatusDisabled(
                    status,
                    taskDefinition,
                    task || bulkActionsTasks![0],
                );
                const isChecked = status.id === currentStatusId;

                let labelClass = classes.label;
                if (isChecked) labelClass = classes.labelChecked;
                if (isDisabled) labelClass = classes.labelDisabled;
                return (
                    <Grid item={true} key={`${status.id}_${id}`} className={classes.item}>
                        <FormControlLabel
                            disabled={isDisabled}
                            value={status.id}
                            control={
                                <Radio
                                    color="primary"
                                    checked={isChecked}
                                    onChange={onChangeCurrentStatusId}
                                />
                            }
                            label={
                                <>
                                    <span className={isDisabled ? classes.disabled : ''}>
                                        {status.title}
                                    </span>
                                    {isDisabled && (
                                        <Typography
                                            variant="body2"
                                            data-testid={`radio-change_status-notice-${id}`}
                                            className={classes.disabled}
                                        >
                                            {t('TaskActions.ChangeStatus.SubmissionIsRequired')}
                                        </Typography>
                                    )}
                                </>
                            }
                            data-testid={`radio-change_status-${id}`}
                            classes={{
                                root: classes.labelRoot,
                                label: labelClass,
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
