import { URLPart } from 'worldapp-fe-utils';
import { Converter } from '../../location.types';
import { LoginStatus } from '../../../redux/login/login.types';

const login = 'login';
const logout = 'logout';
const reminder = 'reminder';
const changePassword = 'changePassword';

export const loginConverter: Converter = {
    URLToState: ({ hash }) => {
        if (hash?.startsWith(logout)) {
            return { loginStatus: LoginStatus.LogoutPending };
        }
        if (hash?.startsWith(reminder)) {
            return { loginStatus: LoginStatus.ResetPassword };
        }
        if (hash?.startsWith(changePassword)) {
            return { loginStatus: LoginStatus.ChangePassword };
        }
    },
    stateToURL: ({ loginStatus, portalId }) => {
        const url: URLPart = {};
        switch (loginStatus) {
            case LoginStatus.Login:
            case LoginStatus.Portal: {
                url.hash = login;
                break;
            }
            case LoginStatus.ResetPassword: {
                url.hash = reminder;
                break;
            }
            case LoginStatus.ChangePassword: {
                url.hash = changePassword;
                break;
            }
        }
        if (portalId !== null) url.query = { portalId: `${portalId}` };
        return url;
    },
};
