import React from 'react';
import ToggleButtons from 'worldapp-ui/shared/buttons/ToggleButtons';
import { useTranslation } from 'react-i18next';
import { Icon } from 'worldapp-ui';
import { connect } from 'react-redux';
import { TasksView } from '../../redux/menu/menu.types';
import { getTaskCalendarFeature } from '../../redux/features/featuresSelector';
import { AppState } from '../../redux/AppState.types';
import { TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS } from '../../constants/ui.constants';
import { isMobile } from '../../utils/browser.utils';

export const mapStateToProps = (state: AppState) => ({
    hasFeatureTaskCalendar: getTaskCalendarFeature(state),
});

interface ToggleButton {
    title: string;
    icon: React.ReactElement;
    iconMobile?: React.ReactElement;
    value: string;
    testId: string;
}

const getButtonsList = (
    cardsTitle: string,
    tableTitle: string,
    calendarTitle: string,
    isCalendarShow?: boolean,
): ToggleButton[] => {
    const ButtonsList = [
        {
            icon: <Icon size="l" name="card-view" mode="stroke" />,
            iconMobile: <Icon size="l" name="card-view" mode="stroke" />,
            title: cardsTitle,
            value: 'cards',
            testId: 'toggle-btn_cards',
        },
        {
            icon: <Icon size="l" name="table-view" mode="stroke" />,
            iconMobile: <Icon size="l" name="table-view" mode="stroke" />,
            title: tableTitle,
            value: 'table',
            testId: 'toggle-btn_table',
        },
    ];
    if (isCalendarShow) {
        ButtonsList.push({
            icon: <Icon size="l" name="calendar-view" mode="stroke" />,
            iconMobile: <Icon size="l" name="calendar-view" mode="stroke" />,
            title: calendarTitle,
            value: 'calendar',
            testId: 'toggle-btn_calendar',
        });
    }
    return ButtonsList;
};

interface CardsViewToggleProps {
    hasFeatureTaskCalendar?: boolean;
    viewType: TasksView;
    onChange: (view: any) => void;
}

export const CardsViewToggleComponent = ({
    hasFeatureTaskCalendar,
    viewType,
    onChange,
}: CardsViewToggleProps): JSX.Element => {
    const { t } = useTranslation();
    const showCalendar = hasFeatureTaskCalendar && !isMobile;
    const buttonsList = getButtonsList(
        t('TasksToggle.Cards'),
        t('TasksToggle.Table'),
        t('TasksToggle.Calendar'),
        showCalendar,
    );
    return (
        <div className={TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS}>
            <ToggleButtons buttonsList={buttonsList} value={viewType} onValueChange={onChange} />
        </div>
    );
};

export const CardsViewToggle = connect(mapStateToProps)(CardsViewToggleComponent);
