import { TaskDefinition, TaskDefinitionsById } from './taskDefinitions.types';

import { AppState } from '../AppState.types';

export const getTaskDefinitionsById = (state: AppState): TaskDefinitionsById =>
    state.taskDefinitions.taskDefinitionsById;

export const getTaskDefinitions = (state: AppState): TaskDefinition[] =>
    state.taskDefinitions.taskDefinitions;

export const getTotalTDs = (state: AppState): number => state.taskDefinitions.totalTDs;

export const getTotalTasks = ({ taskDefinitions }: AppState): number => taskDefinitions.totalTasks;

export const getTdIdsWithCreateTask = ({ taskDefinitions }: AppState): number[] =>
    taskDefinitions.tdIdsWithCreateTask;

export const getTaskPermitsLoading = ({ taskDefinitions }: AppState): boolean =>
    taskDefinitions.taskPermitsLoading;

export const getHasTds = ({ taskDefinitions }: AppState): boolean => {
    return taskDefinitions.taskDefinitions.length > 0;
};

export const taskDefinitionsSelector = {
    getTaskDefinitionsById,
    getTaskDefinitions,
};
