import React, { useRef, useCallback } from 'react';
import { TextInput, PasswordInput, ButtonLink, Box, Grid, Typography } from 'worldapp-ui/shared';
import { shallowEqual, useSelector } from 'react-redux';

import { Trans, useTranslation } from 'react-i18next';
import { loginStyles } from './LoginPage.styles';
import { LoginButtonIcon } from './LoginButtonIcon';
import ChangeLanguageDropdown from './ChangeLanguageDropdown';
import { LoginState, LoginStatus } from '../../redux/login/login.types';
import { ActionCallbacks, useActionCreators } from '../../hooks/useActionCreators';
import { handleInput, onKeyPressed } from '../../utils/keyboard.utils';
import BrandImage from './BrandImage';
import { getLoginState } from '../../redux/login/loginSelector';
import ButtonWithIcon from '../Common/ButtonWithIcon';

const actionSelector =
    (loginState: LoginState) =>
    ({ loginCreator }: ActionCallbacks) => ({
        handleChangeUsername: handleInput(loginCreator.changeUsername),
        handleChangePassword: handleInput(loginCreator.changePassword),
        handleForgotPasswordClick: () => loginCreator.changeLoginStatus(LoginStatus.ResetPassword),
        handleSwitchPortal: loginCreator.switchPortal,
        handleAuthorize: loginCreator.authorize,
        handleAuthorizeOnEnter: onKeyPressed('Enter')(() => {
            if (loginState.nextStepEnabled) {
                loginCreator.authorize();
            }
        }),
    });

export const Login = (): JSX.Element => {
    const { t } = useTranslation();
    const inputPasswordRef = useRef<HTMLInputElement>(null);

    const loginState = useSelector(getLoginState, shallowEqual);
    const {
        handleChangeUsername,
        handleChangePassword,
        handleAuthorizeOnEnter,
        handleForgotPasswordClick,
        handleSwitchPortal,
        handleAuthorize,
    } = useActionCreators(actionSelector(loginState));

    const classes = loginStyles();

    const handleChangeFocus = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && inputPasswordRef.current) {
            inputPasswordRef.current.focus();
        }
    }, []);

    const inputProps = {
        inputRef: inputPasswordRef,
    };

    return (
        <Grid container={true} spacing={2} direction="column" wrap="nowrap">
            <Grid item={true} className={classes.fullHeight}>
                <BrandImage className={classes.img} data-testid="brand-image_login" />
            </Grid>
            <Grid item={true}>
                <Box mb={4}>
                    <Typography
                        align="center"
                        variant="body1"
                        className={classes.text}
                        data-testid="login_switch-portal"
                    >
                        <Trans
                            defaults="Login.EnteringSelectedPortal"
                            values={{ portalId: loginState.portalId }}
                            components={[
                                <ButtonLink
                                    key="switch-portal"
                                    onClick={handleSwitchPortal}
                                    underline="always"
                                    className={classes.buttonLink}
                                    data-testid="button_switch-portal"
                                >
                                    _
                                </ButtonLink>,
                            ]}
                        />
                    </Typography>
                </Box>
            </Grid>
            <Grid item={true}>
                <TextInput
                    label={t('Login.USERNAME')}
                    onChange={handleChangeUsername}
                    value={loginState.username}
                    fullWidth={true}
                    errorMessage={t(loginState.errorMessage)}
                    onKeyPress={handleChangeFocus}
                    inputFieldProps={{ autoFocus: true }}
                    data-testid="input_username"
                />
            </Grid>
            <Grid item={true}>
                <PasswordInput
                    label={t('Login.PASSWORD')}
                    showPasswordLabel={t('Login.SHOW_PASSWORD')}
                    hidePasswordLabel={t('Login.HIDE_PASSWORD')}
                    onChange={handleChangePassword}
                    value={loginState.password}
                    fullWidth={true}
                    errorMessage={t(loginState.errorMessage)}
                    onKeyPress={handleAuthorizeOnEnter}
                    data-testid="input_password"
                    inputFieldProps={inputProps}
                />
            </Grid>
            <Grid item={true}>
                <ButtonWithIcon
                    disabled={!loginState.nextStepEnabled}
                    icon={<LoginButtonIcon isLoading={loginState.isLoading} />}
                    title={t('Login.LOGIN')}
                    fullWidth={true}
                    onClick={handleAuthorize}
                    data-testid="button_login"
                />
            </Grid>
            <Box mt={1}>
                <Grid container={true} className={classes.grid}>
                    <ChangeLanguageDropdown />
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container={true} className={classes.grid}>
                    <ButtonLink
                        underline="always"
                        onClick={handleForgotPasswordClick}
                        data-testid="button_forgot-password"
                        className={classes.buttonLink}
                    >
                        {t('Login.FORGOT_PASSWORD')}
                    </ButtonLink>
                </Grid>
            </Box>
        </Grid>
    );
};

export default Login;
