import React, { FunctionComponent } from 'react';
import { DEFAULT_COLOR } from '../../ThemeProvider';

export interface BackgroundImageProps {
    color?: string;
}

const BackgroundImage: FunctionComponent<BackgroundImageProps> = props => {
    let { color = DEFAULT_COLOR } = props;
    // TODO remove after the Core release 10.2
    color = color === '#2467BD' ? DEFAULT_COLOR : color;
    return (
        <svg
            viewBox="0 0 1370 412"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
        >
            <path
                d="M237.527 19L229 19.0439L229.024 24.043L234.201 24.0186L242.44 43.7754L239.447 48.5645C238.446 50.1642 238.395 52.1849 239.31 53.8321C240.225 55.4792 241.962 56.5024 243.847 56.5024H274V51.5034H243.847L243.686 51.2105L246.626 46.5044H265.302C267.12 46.5044 268.793 45.5163 269.673 43.9317L278.683 27.7239C279.116 26.949 279.104 26.0039 278.654 25.239C278.204 24.4767 277.381 24.0088 276.495 24.0088H239.618L237.527 19ZM241.698 29.0078H272.247L265.302 41.5054H246.909L241.698 29.0078ZM243.993 59.002C242.667 59.002 241.395 59.5286 240.457 60.4661C239.519 61.4036 238.992 62.6752 238.992 64.001C238.992 65.3268 239.519 66.5983 240.457 67.5358C241.395 68.4733 242.667 69 243.993 69C245.32 69 246.592 68.4733 247.53 67.5358C248.468 66.5983 248.995 65.3268 248.995 64.001C248.995 62.6752 248.468 61.4036 247.53 60.4661C246.592 59.5286 245.32 59.002 243.993 59.002ZM268.999 59.002C267.672 59.002 266.4 59.5286 265.462 60.4661C264.525 61.4036 263.998 62.6752 263.998 64.001C263.998 65.3268 264.525 66.5983 265.462 67.5358C266.4 68.4733 267.672 69 268.999 69C270.325 69 271.597 68.4733 272.535 67.5358C273.473 66.5983 274 65.3268 274 64.001C274 62.6752 273.473 61.4036 272.535 60.4661C271.597 59.5286 270.325 59.002 268.999 59.002Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                d="M4.43415 27L0 27.0228L0.012698 29.6223L2.70468 29.6096L6.98899 39.8832L5.43221 42.3736C4.9121 43.2054 4.8852 44.2561 5.36111 45.1127C5.83701 45.9692 6.73999 46.5013 7.7204 46.5013H23.3999V43.9018H7.7204L7.63659 43.7495L9.16543 41.3023H18.8769C19.8222 41.3023 20.6921 40.7885 21.1498 39.9645L25.8354 31.5364C26.0603 31.1335 26.0542 30.642 25.8202 30.2443C25.5861 29.8479 25.158 29.6046 24.6977 29.6046H5.5211L4.43415 27ZM6.60297 32.2041H22.4882L18.8769 38.7028H9.31273L6.60297 32.2041ZM7.79659 47.801C7.10688 47.801 6.44542 48.0749 5.95772 48.5624C5.47002 49.0499 5.19603 49.7111 5.19603 50.4005C5.19603 51.0899 5.47002 51.7511 5.95772 52.2386C6.44542 52.7261 7.10688 53 7.79659 53C8.4863 53 9.14776 52.7261 9.63546 52.2386C10.1232 51.7511 10.3971 51.0899 10.3971 50.4005C10.3971 49.7111 10.1232 49.0499 9.63546 48.5624C9.14776 48.0749 8.4863 47.801 7.79659 47.801ZM20.7994 47.801C20.1097 47.801 19.4482 48.0749 18.9605 48.5624C18.4728 49.0499 18.1988 49.7111 18.1988 50.4005C18.1988 51.0899 18.4728 51.7511 18.9605 52.2386C19.4482 52.7261 20.1097 53 20.7994 53C21.4891 53 22.1505 52.7261 22.6382 52.2386C23.1259 51.7511 23.3999 51.0899 23.3999 50.4005C23.3999 49.7111 23.1259 49.0499 22.6382 48.5624C22.1505 48.0749 21.4891 47.801 20.7994 47.801Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                d="M164 67L144 84.8099H149.455V101H162.182V90.2066H165.818V101H178.545V84.8099H184L164 67ZM164 71.8416L174.909 81.5564V83.011V97.4022H169.455V86.6088H158.545V97.4022H153.091V81.5564L164 71.8416Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                d="M70.6 53C70.2318 52.9999 69.8741 53.1226 69.5839 53.3488C69.2937 53.575 69.0875 53.8916 68.9983 54.2482L67.5095 60.1866C65.5706 60.8578 64 62.3785 64 64.5294V76.0588V79.3529C64 80.2621 64.7392 81 65.65 81H70.6V77.7059H90.4V81H95.35C96.2608 81 97 80.2621 97 79.3529V76.0588V64.5294C97 62.3785 95.4294 60.8578 93.4905 60.1866L92.0017 54.2482C91.9125 53.8916 91.7063 53.575 91.4161 53.3488C91.1259 53.1226 90.7682 52.9999 90.4 53H70.6ZM71.8891 56.2941H89.1109L89.9359 59.5882H71.0641L71.8891 56.2941ZM68.95 62.8824H92.05C92.9823 62.8824 93.7 63.5988 93.7 64.5294V66.1765H88.75V69.4706H93.7V74.4118H67.3V69.4706H72.25V66.1765H67.3V64.5294C67.3 63.5988 68.0177 62.8824 68.95 62.8824ZM77.2 66.1765V69.4706H83.8V66.1765H77.2Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M442 62.9378L435.875 65.9867L442 69.0356L426 77L410 69.0356L416.125 65.9867L410 62.9378L416 59.9511L410 56.9644L426 49L442 56.9644L436 59.9511L442 62.9378ZM414.469 56.9646C416.406 57.9291 418.331 58.8831 420.242 59.8268C422.154 60.7705 424.073 61.7298 426 62.7046C427.927 61.7298 429.846 60.7705 431.758 59.8268C433.669 58.8831 435.594 57.9291 437.531 56.9646L426 51.2246L414.469 56.9646ZM433.641 67.0913L426 70.9024L418.359 67.0913L414.469 69.0358L426 74.7758L437.531 69.0358L433.641 67.0913ZM426 68.6779C427.938 67.7134 429.859 66.7542 431.766 65.8001C433.672 64.846 435.594 63.892 437.531 62.9379L433.766 61.0557C432.464 61.709 431.169 62.3545 429.883 62.9923C428.596 63.6301 427.302 64.2757 426 64.929C424.698 64.2757 423.404 63.6301 422.117 62.9923C420.831 62.3545 419.536 61.709 418.234 61.0557L414.469 62.9379C416.406 63.892 418.328 64.846 420.234 65.8001C422.141 66.7542 424.062 67.7134 426 68.6779Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                d="M916.923 309L913 309.02L913.011 311.32L915.393 311.309L919.183 320.397L917.805 322.6C917.345 323.336 917.322 324.265 917.743 325.023C918.164 325.78 918.962 326.251 919.83 326.251H933.7V323.952H919.83L919.755 323.817L921.108 321.652H929.699C930.535 321.652 931.305 321.198 931.709 320.469L935.854 313.013C936.053 312.657 936.048 312.222 935.841 311.87C935.634 311.519 935.255 311.304 934.848 311.304H917.884L916.923 309ZM918.841 313.604H932.893L929.699 319.352H921.238L918.841 313.604ZM919.897 327.401C919.287 327.401 918.702 327.643 918.27 328.074C917.839 328.506 917.596 329.091 917.596 329.7C917.596 330.31 917.839 330.895 918.27 331.326C918.702 331.758 919.287 332 919.897 332C920.507 332 921.092 331.758 921.524 331.326C921.955 330.895 922.197 330.31 922.197 329.7C922.197 329.091 921.955 328.506 921.524 328.074C921.092 327.643 920.507 327.401 919.897 327.401ZM931.399 327.401C930.789 327.401 930.204 327.643 929.773 328.074C929.341 328.506 929.099 329.091 929.099 329.7C929.099 330.31 929.341 330.895 929.773 331.326C930.204 331.758 930.789 332 931.399 332C932.01 332 932.595 331.758 933.026 331.326C933.458 330.895 933.7 330.31 933.7 329.7C933.7 329.091 933.458 328.506 933.026 328.074C932.595 327.643 932.01 327.401 931.399 327.401Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                d="M992.5 345L969 365.953H975.409V385H990.364V372.302H994.636V385H1009.59V365.953H1016L992.5 345ZM992.5 350.696L1005.32 362.125V363.836V380.767H998.909V368.069H986.091V380.767H979.682V362.125L992.5 350.696Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                d="M1356 373L1342 385.572H1345.82V397H1354.73V389.381H1357.27V397H1366.18V385.572H1370L1356 373ZM1356 376.418L1363.64 383.275V384.302V394.46H1359.82V386.841H1352.18V394.46H1348.36V383.275L1356 376.418Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                d="M1071.8 297C1071.14 297 1070.5 297.219 1069.98 297.623C1069.46 298.027 1069.1 298.592 1068.94 299.229L1066.27 309.833C1062.81 311.032 1060 313.747 1060 317.588V338.176V344.059C1060 345.682 1061.32 347 1062.95 347H1071.8V341.118H1107.2V347H1116.05C1117.68 347 1119 345.682 1119 344.059V338.176V317.588C1119 313.747 1116.19 311.032 1112.73 309.833L1110.06 299.229C1109.9 298.592 1109.54 298.027 1109.02 297.623C1108.5 297.219 1107.86 297 1107.2 297H1071.8ZM1074.1 302.882H1104.9L1106.37 308.765H1072.63L1074.1 302.882ZM1068.85 314.647H1110.15C1111.82 314.647 1113.1 315.926 1113.1 317.588V320.529H1104.25V326.412H1113.1V335.235H1065.9V326.412H1074.75V320.529H1065.9V317.588C1065.9 315.926 1067.18 314.647 1068.85 314.647ZM1083.6 320.529V326.412H1095.4V320.529H1083.6Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1289 349.938L1282.88 352.987L1289 356.036L1273 364L1257 356.036L1263.12 352.987L1257 349.938L1263 346.951L1257 343.964L1273 336L1289 343.964L1283 346.951L1289 349.938ZM1261.47 343.965C1263.41 344.929 1265.33 345.883 1267.24 346.827C1269.15 347.771 1271.07 348.73 1273 349.705C1274.93 348.73 1276.85 347.771 1278.76 346.827C1280.67 345.883 1282.59 344.929 1284.53 343.965L1273 338.225L1261.47 343.965ZM1280.64 354.091L1273 357.902L1265.36 354.091L1261.47 356.036L1273 361.776L1284.53 356.036L1280.64 354.091ZM1273 355.678C1274.94 354.713 1276.86 353.754 1278.77 352.8C1280.67 351.846 1282.59 350.892 1284.53 349.938L1280.77 348.056C1279.46 348.709 1278.17 349.355 1276.88 349.992C1275.6 350.63 1274.3 351.276 1273 351.929C1271.7 351.276 1270.4 350.63 1269.12 349.992C1267.83 349.355 1266.54 348.709 1265.23 348.056L1261.47 349.938C1263.41 350.892 1265.33 351.846 1267.23 352.8C1269.14 353.754 1271.06 354.713 1273 355.678Z"
                fill={color}
                fillOpacity="0.3"
            />
            <g opacity="0.3">
                <path
                    d="M345 74C345 72.3438 343.656 71 342 71C340.344 71 339 72.3438 339 74H334V78C334 79.1055 334.895 80 336 80H348C349.105 80 350 79.1055 350 78V74H345Z"
                    stroke={color}
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M353 89.5C353.276 89.5 353.5 89.2761 353.5 89C353.5 88.7239 353.276 88.5 353 88.5H331C330.724 88.5 330.5 88.7239 330.5 89C330.5 89.2761 330.724 89.5 331 89.5H353ZM347 101.5C347.276 101.5 347.5 101.276 347.5 101C347.5 100.724 347.276 100.5 347 100.5H331C330.724 100.5 330.5 100.724 330.5 101C330.5 101.276 330.724 101.5 331 101.5H347ZM344 107.5C344.276 107.5 344.5 107.276 344.5 107C344.5 106.724 344.276 106.5 344 106.5H331C330.724 106.5 330.5 106.724 330.5 107C330.5 107.276 330.724 107.5 331 107.5H344ZM353 95.5C353.276 95.5 353.5 95.2761 353.5 95C353.5 94.7239 353.276 94.5 353 94.5H331C330.724 94.5 330.5 94.7239 330.5 95C330.5 95.2761 330.724 95.5 331 95.5H353Z"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M358.15 110.7L358.25 110.625V110.5V104V103.75H358H356H355.75V104V109.375L352.249 111.999L352.048 112.149L352.199 112.349L353.402 113.951L353.552 114.151L353.752 114.001L358.15 110.7ZM357.75 98.0508V98.2817L357.98 98.3C358.651 98.3534 359.301 98.4717 359.937 98.6365L360.25 98.7175V98.3945V76C360.25 74.2096 358.79 72.75 357 72.75H350H349.75V73V75V75.25H350H357C357.413 75.25 357.75 75.5873 357.75 76V98.0508ZM346.833 115.874L346.761 115.75H346.617H327C326.587 115.75 326.25 115.413 326.25 115V76C326.25 75.5873 326.587 75.25 327 75.25H334H334.25V75V73V72.75H334H327C325.21 72.75 323.75 74.2096 323.75 76V115C323.75 116.79 325.21 118.25 327 118.25H348.074H348.635L348.26 117.833C347.721 117.232 347.243 116.578 346.833 115.874ZM346.75 110C346.75 115.638 351.362 120.25 357 120.25C362.638 120.25 367.25 115.638 367.25 110C367.25 104.362 362.638 99.75 357 99.75C351.362 99.75 346.75 104.362 346.75 110ZM349.25 110C349.25 105.74 352.74 102.25 357 102.25C361.26 102.25 364.75 105.74 364.75 110C364.75 114.26 361.26 117.75 357 117.75C352.74 117.75 349.25 114.26 349.25 110Z"
                    fill={color}
                    stroke={color}
                    strokeWidth="0.5"
                />
            </g>
            <g opacity="0.3">
                <path
                    d="M1182.54 353.673C1182.54 351.645 1180.9 350 1178.89 350C1176.87 350 1175.24 351.645 1175.24 353.673H1169.16V358.571C1169.16 359.925 1170.25 361.02 1171.59 361.02H1186.18C1187.53 361.02 1188.62 359.925 1188.62 358.571V353.673H1182.54Z"
                    stroke={color}
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M1192.26 374.541C1192.54 374.541 1192.76 374.317 1192.76 374.041C1192.76 373.765 1192.54 373.541 1192.26 373.541H1165.51C1165.24 373.541 1165.01 373.765 1165.01 374.041C1165.01 374.317 1165.24 374.541 1165.51 374.541H1192.26ZM1184.97 389.235C1185.24 389.235 1185.47 389.011 1185.47 388.735C1185.47 388.459 1185.24 388.235 1184.97 388.235H1165.51C1165.24 388.235 1165.01 388.459 1165.01 388.735C1165.01 389.011 1165.24 389.235 1165.51 389.235H1184.97ZM1181.32 396.582C1181.6 396.582 1181.82 396.358 1181.82 396.082C1181.82 395.805 1181.6 395.582 1181.32 395.582H1165.51C1165.24 395.582 1165.01 395.805 1165.01 396.082C1165.01 396.358 1165.24 396.582 1165.51 396.582H1181.32ZM1192.26 381.888C1192.54 381.888 1192.76 381.664 1192.76 381.388C1192.76 381.112 1192.54 380.888 1192.26 380.888H1165.51C1165.24 380.888 1165.01 381.112 1165.01 381.388C1165.01 381.664 1165.24 381.888 1165.51 381.888H1192.26Z"
                    stroke={color}
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1197.49 399.118L1197.59 399.043V398.918V390.959V390.709H1197.34H1194.91H1194.66V390.959V397.569L1190.38 400.799L1190.18 400.949L1190.33 401.149L1191.79 403.11L1191.95 403.311L1192.15 403.16L1197.49 399.118ZM1197.09 383.674V383.905L1197.32 383.924C1198.14 383.989 1198.94 384.135 1199.71 384.337L1200.03 384.419V384.095V356.673C1200.03 354.514 1198.28 352.75 1196.13 352.75H1187.62H1187.37V353V355.449V355.699H1187.62H1196.13C1196.66 355.699 1197.09 356.135 1197.09 356.673V383.674ZM1183.72 405.528L1183.65 405.403H1183.5H1159.65C1159.12 405.403 1158.68 404.967 1158.68 404.429V356.673C1158.68 356.135 1159.12 355.699 1159.65 355.699H1168.16H1168.41V355.449V353V352.75H1168.16H1159.65C1157.5 352.75 1155.75 354.514 1155.75 356.673V404.429C1155.75 406.588 1157.5 408.352 1159.65 408.352H1185.27H1185.83L1185.46 407.936C1184.8 407.197 1184.22 406.393 1183.72 405.528ZM1183.72 398.306C1183.72 405.177 1189.3 410.801 1196.13 410.801C1202.96 410.801 1208.54 405.177 1208.54 398.306C1208.54 391.435 1202.96 385.811 1196.13 385.811C1189.3 385.811 1183.72 391.435 1183.72 398.306ZM1186.65 398.306C1186.65 393.057 1190.92 388.76 1196.13 388.76C1201.34 388.76 1205.61 393.057 1205.61 398.306C1205.61 403.556 1201.34 407.852 1196.13 407.852C1190.92 407.852 1186.65 403.556 1186.65 398.306Z"
                    fill={color}
                    stroke={color}
                    strokeWidth="0.5"
                />
            </g>
        </svg>
    );
};

export default BackgroundImage;
