import produce from 'immer';
import {
    CHANGE_SORT_COLUMN,
    CHANGE_SORT_COLUMN_OR_ORDER,
    ChangeSortColumnAction,
    ChangeSortColumnOrOrderAction,
    defaultSortColumn,
    defaultSortOrder,
    RESET_SORT,
    SortBy,
    SortColumnType,
    SortOrder,
    TasksSortAction,
    TasksSortState,
    TOGGLE_SORT_ORDER,
} from './tasksSort.types';
import { FETCH_TDS, TaskDefinition } from '../taskDefinitions/taskDefinitions.types';
import { getCurrentTaskDefinitionId } from '../content/contentSelector';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import {
    HYDRATE_STATE_FROM_STORAGE,
    HydrateStateFromStorageAction,
} from '../initialLoader/initialLoader.types';

export const initialSort = (): {
    column: SortColumnType.AssignDate;
    order: SortOrder.Asc;
} => ({
    column: defaultSortColumn,
    order: defaultSortOrder,
});

export const initialState: TasksSortState = {};

const toggleSortOrder = (draft: TasksSortState, taskDefinitionId: number) => {
    if (!draft[taskDefinitionId]) draft[taskDefinitionId] = initialSort();
    draft[taskDefinitionId]!.order =
        draft[taskDefinitionId]!.order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
};
const changeSortColumn = (
    draft: TasksSortState,
    action: ChangeSortColumnAction | ChangeSortColumnOrOrderAction,
) => {
    const { taskDefinitionId } = action;
    if (!draft[taskDefinitionId]) draft[taskDefinitionId] = initialSort();
    if (action.column === SortColumnType.Custom) {
        draft[taskDefinitionId] = {
            column: SortColumnType.Custom,
            property: action.property,
            order: draft[taskDefinitionId]!.order,
        };
    } else {
        draft[taskDefinitionId] = {
            column: action.column,
            order: draft[taskDefinitionId]!.order,
        };
    }
};

const fixSort = (draft: TasksSortState, taskDefinitions: TaskDefinition[]) => {
    taskDefinitions.forEach(td => {
        const { id, customProperties } = td;
        const taskDefinitionSort = draft[id];
        if (
            taskDefinitionSort &&
            taskDefinitionSort.column === SortColumnType.Custom &&
            customProperties.every(cp => cp.title !== taskDefinitionSort.property)
        ) {
            draft[id] = initialSort();
        }
    });
};

export const reducer = (
    state: TasksSortState = initialState,
    action: TasksSortAction | HydrateStateFromStorageAction,
): TasksSortState =>
    produce(state, (draft: TasksSortState) => {
        switch (action.type) {
            case HYDRATE_STATE_FROM_STORAGE:
                const { tasksSort, menu } = action.state;
                const activeItemId = menu?.navigation?.activeItemId;
                if (!tasksSort || (activeItemId && draft[activeItemId])) return;
                return {
                    ...draft,
                    ...tasksSort,
                };
            case CHANGE_SORT_COLUMN:
                changeSortColumn(draft, action);
                return;
            case TOGGLE_SORT_ORDER:
                toggleSortOrder(draft, action.taskDefinitionId);
                return;
            case CHANGE_SORT_COLUMN_OR_ORDER:
                if (draft[action.taskDefinitionId]?.column === action.column) {
                    toggleSortOrder(draft, action.taskDefinitionId);
                } else {
                    changeSortColumn(draft, action);
                }
                return;
            case FETCH_TDS:
                return fixSort(draft, action.taskDefinitions);
            case RESET_SORT:
                return initialState;
            default:
                return state;
        }
    });

export const tasksSortCreator = {
    changeSortColumn:
        (sortBy: SortBy): ThunkActionVoid =>
        (dispatch, getState, { contentCreator }) => {
            const taskDefinitionId = getCurrentTaskDefinitionId(getState());
            if (!taskDefinitionId) return;
            dispatch({
                ...sortBy,
                taskDefinitionId,
                type: CHANGE_SORT_COLUMN,
            });
            return dispatch(contentCreator.refresh(false));
        },

    changeSortOrder:
        (): ThunkActionVoid =>
        (dispatch, getState, { contentCreator }) => {
            const taskDefinitionId = getCurrentTaskDefinitionId(getState());
            if (!taskDefinitionId) return;
            dispatch({
                taskDefinitionId,
                type: TOGGLE_SORT_ORDER,
            });
            return dispatch(contentCreator.refresh(false));
        },

    changeSortColumnOrOrder:
        (sortBy: SortBy): ThunkActionVoid =>
        (dispatch, getState, { contentCreator }) => {
            const taskDefinitionId = getCurrentTaskDefinitionId(getState());
            if (!taskDefinitionId) return;
            dispatch({
                ...sortBy,
                taskDefinitionId,
                type: CHANGE_SORT_COLUMN_OR_ORDER,
            });
            return dispatch(contentCreator.refresh(false));
        },

    resetSort: (): ThunkActionVoid => dispatch => {
        dispatch({ type: RESET_SORT });
    },
};
