import React, { FunctionComponent, useCallback, useState } from 'react';
import { CARDS_PADDING, CARDS_WIDTHS, MIN_CARDS_CONTAINER_WIDTH } from '../../ui/cards/cardsSizes';
import { useIsMobileScreenWidth } from '../../../hooks/useIsMobileScreenWidth';
import CardsPlaceholder from '../../Placeholders/CardsPlaceholder';
import { Category } from '../../../redux/content/category.types';
import TasksActionsComponents from '../TasksActionsComponents';
import { JustifyValue } from '../../../redux/content/content.types';

export const DELTA_CARDS_VIEW = 0.178;

export const calculateWrapperWidth = (containerWidth?: number): number => {
    if (!containerWidth) return MIN_CARDS_CONTAINER_WIDTH;

    const calculatedCardsInRow =
        (containerWidth + CARDS_PADDING) / (CARDS_WIDTHS.tasks + CARDS_PADDING);

    const cardsInRow =
        calculatedCardsInRow % 1 > DELTA_CARDS_VIEW
            ? Math.ceil(calculatedCardsInRow)
            : Math.floor(calculatedCardsInRow);

    const calculatedContainerWidth =
        CARDS_WIDTHS.tasks * cardsInRow + CARDS_PADDING * (cardsInRow - 1) - 4;
    if (calculatedContainerWidth <= MIN_CARDS_CONTAINER_WIDTH) return MIN_CARDS_CONTAINER_WIDTH;
    return calculatedContainerWidth;
};

interface CardsGridWrapperProps {
    showPlaceholder: boolean;
    category: Category;
    justifyContentValue: JustifyValue;
    hideScroll?: boolean;
}

const CardsGridWrapper: FunctionComponent<CardsGridWrapperProps> = props => {
    const [contentDiv, setContentDiv] = useState<HTMLDivElement | null>(null);
    const setRef = useCallback((ref: HTMLDivElement | null) => {
        setContentDiv(ref);
    }, []);

    const containerWidth = contentDiv?.offsetWidth || 0;
    const isMobileScreen = useIsMobileScreenWidth();
    return (
        <div
            ref={setRef}
            data-testid="cards-grid-wrapper"
            style={{
                width: '100%',
                minWidth: 375,
                height: '100%',
                boxSizing: 'border-box',
                overflow: props.hideScroll ? 'hidden' : 'visible',
            }}
        >
            {props.showPlaceholder ? (
                <CardsPlaceholder
                    containerWidth={containerWidth}
                    isMobileScreen={isMobileScreen}
                    category={props.category}
                    justifyContentValue={props.justifyContentValue}
                />
            ) : (
                props.children
            )}
            <TasksActionsComponents contentDiv={contentDiv} />
        </div>
    );
};

export default CardsGridWrapper;
