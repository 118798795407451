export const ADD_FREQUENTLY_USED_ITEM = 'FrequentlyUsed/ADD_FREQUENTLY_USED_ITEM';

export type FrequentlyUsedItem = 'forms' | 'taskDefinitions';

interface AddItemAction {
    type: typeof ADD_FREQUENTLY_USED_ITEM;
    itemType: FrequentlyUsedItem;
    id: number;
}

export type FrequentlyUsedAction = AddItemAction;

export interface Count {
    [key: number]: number | null;
}

export interface ItemsByDates {
    [key: string]: {
        taskDefinitions: Count;
        forms: Count;
    };
}

export type FrequentlyUsedState = {
    dates: string[];
    itemsByDates: ItemsByDates;
};
