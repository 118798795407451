import React from 'react';
import { Icon, Tooltip, IconButton } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useButtonStyles } from './Buttons.styles';

export interface CopyButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

const CopyButton = (props: CopyButtonProps): JSX.Element => {
    const { onClick, disabled } = props;
    const classes = useButtonStyles();
    const { t } = useTranslation();

    return (
        <Tooltip title={t('ResponseActions.Copy.IconLabel') as string} placement="bottom-start">
            <div
                className={classNames(classes.button, { [classes.buttonDisabled]: disabled })}
                onClick={onClick}
                data-testid="copy-button"
            >
                <IconButton
                    icon={<Icon name="copy" mode="stroke" />}
                    disabled={disabled}
                    aria-label={t('ResponseActions.Copy.IconLabel')}
                />
            </div>
        </Tooltip>
    );
};

export default CopyButton;
