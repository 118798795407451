import React from 'react';
import { Grid, createStyles, makeStyles } from 'worldapp-ui/shared';
import MainHeader from '../MainHeader';
import { isMobile } from '../../../utils/browser.utils';
import { GRID_MOBILE_PADDING, GRID_PADDING } from '../../ui/cards/cardsSizes';

const useDashboardsHeaderPanelStyles = makeStyles(() =>
    createStyles({
        mainLine: {
            padding: `0 ${GRID_PADDING}px 16px ${GRID_PADDING}px`,
            '&[data-ismobile="true"]': {
                padding: `0 16px 8px ${GRID_MOBILE_PADDING}px`,
                height: 40,
                boxSizing: 'border-box',
            },
        },
        contentDivider: {
            margin: `0 ${GRID_PADDING}px`,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            '&[data-ismobile="true"]': {
                margin: `0 ${GRID_MOBILE_PADDING}px`,
            },
        },
    }),
);

const DashboardsHeaderPanel = (): JSX.Element => {
    const classes = useDashboardsHeaderPanelStyles();

    return (
        <>
            <Grid
                container={true}
                alignItems="center"
                className={classes.mainLine}
                data-ismobile={isMobile}
            >
                <MainHeader />
            </Grid>
            <div className={classes.contentDivider} data-ismobile={isMobile} />
        </>
    );
};

export default DashboardsHeaderPanel;
