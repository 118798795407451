import React from 'react';
import { ActionCreators } from './index';
import { loginCreator } from './login/login';
import { userCreator } from './user/user';
import { changePasswordCreator } from './changePassword/changePassword';
import { taskDefinitionsCreator } from './taskDefinitions/taskDefinitions';
import { menuCreator } from './menu/menu';
import { portalSettingsCreator } from './portalSettings/portalSettings';
import { formsCreator } from './forms/forms';
import { contentCreator } from './content/content';
import { contentQuickFiltersCreator } from './contentQuickFilters/contentQuickFilters';
import { tasksActionsCreator } from './tasksActions/tasksActions';
import { responsesActionsCreator } from './responsesActions/responsesActions';
import { tasksSortCreator } from './tasksSort/tasksSort';
import { votingCreator } from './voting/voting';
import { initialLoaderCreator } from './initialLoader/initialLoader';
import { toastsCreator } from './toasts/toasts';
import { tasksConfigurableFiltersCreator } from './tasksConfigurableFilters/tasksConfigurableFilters';
import { tasksTableCreator } from './tasksTable/tasksTable';
import { taskDefinitionAssigneesCreator } from './taskDefinitionAssignees/taskDefinitionAssignees';
import { localizationCreator } from './localization/localization';
import { activeCardCreator } from './activeCard/activeCard';
import { goToURLCreator } from './goToURL/goToURL';
import { commonCreator } from './common/common';
import { brandInfoCreator } from './brandInfo/brandInfo';
import { featuresCreator } from './features/features';
import { frequentlyUsedCreator } from './frequentlyUsed/frequentlyUsed';
import { startFormCreator } from './startForm/startForm';
import { tasksSelectedActionsCreator } from './tasksSelectedActions/tasksSelectedActions';
import { tasksExportCreator } from './tasksExport/tasksExport';
import { calendarCreator } from './calendar/calendar';

export const actionCreators: ActionCreators = {
    loginCreator,
    userCreator,
    changePasswordCreator,
    taskDefinitionsCreator,
    menuCreator,
    portalSettingsCreator,
    formsCreator,
    contentCreator,
    contentQuickFiltersCreator,
    tasksActionsCreator,
    responsesActionsCreator,
    tasksSortCreator,
    votingCreator,
    initialLoaderCreator,
    toastsCreator,
    tasksConfigurableFiltersCreator,
    tasksTableCreator,
    taskDefinitionAssigneesCreator,
    localizationCreator,
    activeCardCreator,
    goToURLCreator,
    commonCreator,
    brandInfoCreator,
    featuresCreator,
    frequentlyUsedCreator,
    startFormCreator,
    tasksSelectedActionsCreator,
    tasksExportCreator,
    calendarCreator,
};

export const ActionCreatorsContext = React.createContext<ActionCreators>(actionCreators);

export const ActionCreatorsProvider = ActionCreatorsContext.Provider;
export const ActionCreatorsConsumer = ActionCreatorsContext.Consumer;
