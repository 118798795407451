import { ApiClient } from './ApiClient';

export const PUBLIC = 'PUBLIC';
export const TOKEN = 'TOKEN';

export interface ApiMethodInfo {
    name: keyof ApiClient;
    accessType: typeof PUBLIC | typeof TOKEN;
}

const publicMethods: Array<keyof ApiClient> = ['login', 'resetPassword', 'getPortalSettings'];

/** This object provides some metadata for api client methods.
 * Right now the only useful information is accessType, it can be TOKEN (default for most api
 * methods),which means that client needs access token in cookies to use this method, or PUBLIC for
 * methods, that can be used without token or anything else.
 */
export const apiMethodInfo = (name: keyof ApiClient): ApiMethodInfo => ({
    name,
    accessType: publicMethods.includes(name) ? PUBLIC : TOKEN,
});
