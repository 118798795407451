import React, { MouseEvent } from 'react';
import { IconButton, WithStyles } from 'worldapp-ui';
import { useTranslation } from 'react-i18next';
import { TaskActionTable } from '../../../redux/content/validateTaskActions';
import {
    ACTIONS_PANEL_ID,
    TABLE_ACTION_ID,
    ActionsIcons,
} from '../../../constants/table.constants';
import { RowDataItem } from '../../../redux/tasksTable/tasksTable.types';

export interface ActionItem {
    name: string;
    onClick: () => void;
}
export interface TableActionsMobileProps {
    rowDataItem: RowDataItem;
    onActionClick: (action: TaskActionTable, taskId: number, taskDefinitionId: number) => void;
}

export interface TableActionsProps extends TableActionsMobileProps {
    right: number;
    classes: WithStyles['classes'];
}

export const TableActions: React.SFC<TableActionsProps> = props => {
    const { rowDataItem, onActionClick, classes, right } = props;
    const { t } = useTranslation();
    const onClick =
        (action: TaskActionTable, taskId: number, taskDefinitionId: number) => (e: MouseEvent) => {
            e.stopPropagation();
            onActionClick(action, taskId, taskDefinitionId);
        };

    if (!rowDataItem.actions || rowDataItem.actions.length === 0) return null;

    return (
        <div
            className={classes.actionPanel}
            style={{ right }}
            data-testid={`${ACTIONS_PANEL_ID}_${rowDataItem.taskId}`}
            role="menu"
        >
            {rowDataItem.actions.map(action => {
                return (
                    <div className={classes.action} key={action} role="menuitem">
                        <IconButton
                            icon={ActionsIcons[action].icon}
                            className={classes.icon}
                            onClick={onClick(
                                action,
                                rowDataItem.taskId,
                                rowDataItem.taskDefinitionId,
                            )}
                            id={`${TABLE_ACTION_ID}_${action}`}
                            data-testid={`${TABLE_ACTION_ID}_${action}`}
                            title={t(ActionsIcons[action].title)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export const TableActionsMobile = ({
    rowDataItem,
    onActionClick,
}: TableActionsMobileProps): ActionItem[] => {
    const { t } = useTranslation();

    return rowDataItem.actions.map(action => ({
        name: t(ActionsIcons[action].title),
        onClick: () => {
            onActionClick(action, rowDataItem.taskId, rowDataItem.taskDefinitionId);
        },
    }));
};
