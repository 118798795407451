import React, { FunctionComponent } from 'react';
import { Typography } from 'worldapp-ui';
import { useTranslation } from 'react-i18next';
import { impersonateHeaderStyles } from './ImpersonateHeader.styles';

export interface ImpersonateHeaderProps {
    contactId: number;
    impersonateMode: boolean;
}

const ImpersonateHeader: FunctionComponent<ImpersonateHeaderProps> = ({
    contactId,
    impersonateMode,
}) => {
    const { t } = useTranslation();
    const classes = impersonateHeaderStyles();

    if (!impersonateMode) return null;
    return (
        <div className={classes.header} data-testid="impersonate-header">
            <Typography variant="h6">
                {t('ImpersonateMode.PreviewingPortal')}:
                <span className={classes.contactId}>{contactId}</span>
            </Typography>
        </div>
    );
};

export default ImpersonateHeader;
