import React, { FunctionComponent } from 'react';
import { useTheme, Grid } from 'worldapp-ui';
import CardPlaceholder from './CardPlaceholder';
import { Category } from '../../redux/content/category.types';
import {
    CARDS_PADDING,
    CARDS_WIDTHS,
    GRID_MOBILE_PADDING,
    GRID_PADDING,
} from '../ui/cards/cardsSizes';
import { isMobile } from '../../utils/browser.utils';

interface CardsPlaceholderProps {
    containerWidth: number;
    isMobileScreen: boolean;
    category: Category;
    justifyContentValue: 'center' | 'flex-start';
}

const CardsPlaceholder: FunctionComponent<CardsPlaceholderProps> = ({
    containerWidth,
    isMobileScreen,
    category,
    justifyContentValue,
}) => {
    const { spacing } = useTheme();
    const cardsInOneRow = Math.floor((containerWidth + spacing(2)) / CARDS_WIDTHS.tasks);
    const totalCards = isMobileScreen ? 1 : cardsInOneRow + 1;
    const padding = isMobile ? GRID_MOBILE_PADDING : GRID_PADDING;
    return (
        <Grid
            container={true}
            spacing={2}
            data-testid="placeholder-cards"
            style={{
                paddingTop: CARDS_PADDING,
                paddingLeft: padding,
                width: '100%',
                justifyContent: justifyContentValue,
            }}
        >
            {Array.from(Array(totalCards)).map((_, index) => (
                <Grid item={true} key={index}>
                    <CardPlaceholder category={category} />
                </Grid>
            ))}
        </Grid>
    );
};

export default CardsPlaceholder;
