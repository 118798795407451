import classNames from 'classnames';
import React from 'react';

import { CssBaseline, resolution, SizeProvider } from 'worldapp-ui/shared';

import { connect } from 'react-redux';
import { isMobile } from '../utils/browser.utils';

import AppHeader from './AppHeader/AppHeader';
import NavigationPanel from './NavigationPanel/NavigationPanel';

import useMainStyles from './Main.styles';

import ApplicationContent from './ApplicationContent/ApplicationContent';
import { AppState } from '../redux/AppState.types';
import { getIsDrawerPermanentOpen } from '../redux/menu/menuSelector';
import ImpersonateHeader from './AppHeader/ImpersonateHeader';
import { getImpersonateMode } from '../redux/portalSettings/portalSettingsSelector';
import { getUserId } from '../redux/user/userSelector';

export interface MainProps {
    isDrawerPermanentOpen: boolean;
    contactId: number;
    impersonateMode: boolean;
}
export const mapStateToProps = (state: AppState): MainProps => ({
    isDrawerPermanentOpen: getIsDrawerPermanentOpen(state),
    contactId: getUserId(state),
    impersonateMode: getImpersonateMode(state),
});

export const Main = (props: MainProps): JSX.Element => {
    const { isDrawerPermanentOpen, contactId, impersonateMode } = props;
    const classes = useMainStyles({ impersonateMode });

    return (
        <div className={classes.root}>
            <CssBaseline />
            <ImpersonateHeader contactId={contactId} impersonateMode={impersonateMode} />
            <AppHeader />
            <div className={classes.app}>
                <NavigationPanel />
                <SizeProvider value={resolution.loRes}>
                    <main
                        className={classNames(
                            classes.content,
                            (isDrawerPermanentOpen || isMobile) && classes.contentSlide,
                        )}
                    >
                        <ApplicationContent />
                    </main>
                </SizeProvider>
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(Main);
