import React, { useCallback, useMemo } from 'react';
import { MultiSelectDropdown } from 'worldapp-ui';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../redux/localization/localizationSelector';
import { getAvailableLanguages } from '../../redux/portalSettings/portalSettingsSelector';

const ChangeLanguageDropdown = (): JSX.Element => {
    const { i18n } = useTranslation();
    const language = useSelector(getLanguage);
    const languages = useSelector(getAvailableLanguages, shallowEqual);

    const onChangeLanguage = useCallback(
        languageChanged => {
            i18n.changeLanguage(languageChanged.value);
        },
        [i18n],
    );

    const languageLabel = useMemo(
        () => languages.find(lang => lang.value === language)?.name,
        [language, languages],
    );

    const languageSelected = useMemo(
        () => languages.find(lang => lang.value === language),
        [language, languages],
    );

    const languagesListWithLabels = languages.map(lang => ({
        label: lang.name,
        value: lang.value,
    }));

    return (
        <MultiSelectDropdown
            onChange={onChangeLanguage}
            singleSelect={true}
            placeholder={languageLabel}
            selectedItems={
                languageSelected
                    ? [{ label: languageSelected.name, value: languageSelected.value }]
                    : null
            }
            items={languagesListWithLabels}
            searchBarAvailable={true}
        />
    );
};

export default ChangeLanguageDropdown;
