import produce from 'immer';
import moment, { Moment } from 'moment';
import ReactGA from 'react-ga4';
import {
    canChangeStatusAfterVoting,
    MainTaskAction,
    SecondaryTaskAction,
} from '../content/validateTaskActions';
import { ApiClient } from '../../datalayer/ApiClient';
import { ApiGW } from '../../types/DTO/api-gw';
import { DataModelRowsDTO, TaskDefinition } from '../taskDefinitions/taskDefinitions.types';
import {
    APPLY_FILTERS,
    BULK_QUEUE_ADD,
    BULK_QUEUE_CHANGE_STATUS,
    BULK_QUEUE_DELETE,
    BulkActionQueueElement,
    CHANGE_CM_VALUES,
    CHANGE_CONTACT_ID,
    CHANGE_DUE_DATE,
    CHANGE_IS_LOADING,
    CHANGE_SEARCH_QUERY,
    CHANGE_STATUS_ID,
    ChangeAssigneeFilter,
    CLOSE_ACTION_POPUP,
    ContactManagerView,
    DISPLAY_CHANGE_ASSIGNEE_CONTENT,
    DISPLAY_CHANGE_ASSIGNEE_FILTERS,
    FILTER_DROPDOWN_FETCH_VALUES_REQUEST,
    FILTER_DROPDOWN_FETCH_VALUES_SUCCESS,
    FILTER_DROPDOWN_INPUT_CHANGE,
    FILTER_DROPDOWN_SELECT_ITEM,
    FILTER_DROPDOWN_TOGGLE_IS_OPEN,
    NullState,
    OPEN_ACTION_POPUP,
    RESET_ALL_FILTERS,
    TasksActionsAction,
    TasksActionsState,
} from './tasksActions.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import i18n from '../../localization/i18n';
import { STATUS_NOT_FOUND } from '../../constants/statusCodes.constants';
import { getImpersonateMode } from '../portalSettings/portalSettingsSelector';
import { getCanChangeStatusAfterVoting } from '../taskDefinitions/taskDefinitionsSelector.reselect';
import { getResultHasBeenSaved, getSubmit, getVotingCreatedItemId } from '../voting/votingSelector';
import { getContentHasActiveItem } from '../content/contentSelector';
import { getTaskMessageAfterVoting } from '../../utils/voting.utils';
import { TOAST_TASK_UPDATE_FILTERS } from '../toasts/toasts.types';
import { Task } from '../content/content.types';
import { getUserId } from '../user/userSelector';
import { getTaskDefinitionsById } from '../taskDefinitions/taskDefinitionsSelector';
import { generateUniqueKey } from '../../utils/helpers.utils';
import { onTaskDetailAction } from '../../analytics';

const CHANGE_STATUS_SUCCESS = 'TaskActions.ChangeStatus.Success';
const CHANGE_STATUS_FAILED = 'TaskActions.ChangeStatus.Fail';

const CHANGE_STATUS_BULK_START = 'TaskActions.ChangeStatus.Bulk.Start';
const CHANGE_STATUS_BULK_SUCCESS = 'TaskActions.ChangeStatus.Bulk.Success';
const CHANGE_STATUS_BULK_FAILED = 'TaskActions.ChangeStatus.Bulk.Fail';

const CHANGE_ASSIGNEE_SUCCESS = 'TaskActions.ChangeAssignee.Success';
const CHANGE_ASSIGNEE_FAILED = 'TaskActions.ChangeAssignee.Fail';

const CHANGE_ASSIGNEE_BULK_START = 'TaskActions.ChangeAssignee.Bulk.Start';
const CHANGE_ASSIGNEE_BULK_SUCCESS = 'TaskActions.ChangeAssignee.Bulk.Success';
const CHANGE_ASSIGNEE_BULK_FAILED = 'TaskActions.ChangeAssignee.Bulk.Fail';

const CHANGE_DUEDATE_SUCCESS = 'TaskActions.ChangeDueDate.Success';
const CHANGE_DUEDATE_FAILED = 'TaskActions.ChangeDueDate.Fail';

const CHANGE_DUEDATE_BULK_START = 'TaskActions.ChangeDueDate.Bulk.Start';
const CHANGE_DUEDATE_BULK_SUCCESS = 'TaskActions.ChangeDueDate.Bulk.Success';
const CHANGE_DUEDATE_BULK_FAILED = 'TaskActions.ChangeDueDate.Bulk.Fail';

const UNASSIGN_SUCCESS = 'TaskActions.UnAssign.Success';
const ASSIGN_TO_ME_SUCCESS = 'TaskActions.AssignToMe.Success';

const CONTACTS_LIMIT = 50;

const nullState: NullState = {
    type: 'NullState',
};

export const initialState: TasksActionsState = {
    ...nullState,
    bulkActionsQueue: [],
};

export class BulkActionError extends Error {
    current: number;

    failed: number;

    constructor({ current, failed }: { current: number; failed: number }) {
        super();
        this.current = current;
        this.failed = failed;
    }
}

export function getInitialFilters(filterFields: ApiGW.ContactFilterDTO[]): ChangeAssigneeFilter[] {
    return filterFields.map(({ id, columnId, title }) => {
        return {
            id,
            columnId,
            title,
            isDisabled: false,
            isLoading: false,
            isOpened: false,
            inputValue: null,
            items: [],
            selectedItemIndex: null,
        };
    });
}

function getFilterById(
    filters: ChangeAssigneeFilter[],
    filterId: number,
): ChangeAssigneeFilter | null {
    const resultFilter = filters.find(filter => filter.id === filterId);
    return resultFilter || null;
}

function getSelectedFilters(filters: ChangeAssigneeFilter[]): ApiGW.DataModelValueDTO[] {
    return filters
        .filter(filter => filter.selectedItemIndex !== null)
        .map(filter => filter.items[filter.selectedItemIndex as number]);
}

const reducer = (
    state: TasksActionsState = initialState,
    action: TasksActionsAction,
): TasksActionsState =>
    produce(state, (draft: TasksActionsState) => {
        switch (action.type) {
            case OPEN_ACTION_POPUP: {
                if (state.type !== nullState.type) {
                    return state;
                }

                const taskActionStateBase = {
                    bulkActionsQueue: state.bulkActionsQueue,
                    bulkUpdates: {},
                    isLoading: false,
                    ...('task' in action ? { task: action.task } : null),
                    ...('bulkActionsTasks' in action
                        ? { bulkActionsTasks: action.bulkActionsTasks }
                        : null),
                };

                if (action.action === 'ChangeAssignee') {
                    return {
                        type: 'ChangeAssignee' as const,
                        activeContactId:
                            'task' in action && action.task.assignee && action.task.assignee.id,
                        activeContactManager: action.activeContactManager,
                        changeAssigneeContent: 'contacts' as const,
                        filtersDraft: [],
                        filters: getInitialFilters(action.contactFilters),
                        contactsTotal: null,
                        contactsFiltered: null,
                        searchQuery: '',
                        offset: 0,
                        hasMoreContacts: true,
                        ...taskActionStateBase,
                    };
                }

                if (action.action === 'ChangeStatus') {
                    return {
                        type: 'ChangeStatus' as const,
                        showVotingMessage: action.showVotingMessage,
                        ...taskActionStateBase,
                    };
                }

                return {
                    type: action.action,
                    ...taskActionStateBase,
                };
            }

            case CLOSE_ACTION_POPUP: {
                return {
                    ...initialState,
                    bulkActionsQueue: draft.bulkActionsQueue,
                };
            }

            case CHANGE_CONTACT_ID: {
                if (draft.type === 'ChangeAssignee') {
                    if (draft.task) {
                        draft.task.assignee = {
                            name: draft.task.assignee?.name ?? '',
                            id: action.activeContactId,
                        };
                    } else if (draft.bulkActionsTasks) {
                        draft.bulkUpdates.assigneeId = action.activeContactId;
                    }
                }
                return;
            }

            case CHANGE_STATUS_ID: {
                if (draft.type === 'ChangeStatus') {
                    if (draft.task) {
                        draft.task.statusId = action.currentStatusId;
                    } else if (draft.bulkActionsTasks) {
                        draft.bulkUpdates.statusId = action.currentStatusId;
                    }
                }
                return;
            }

            case CHANGE_DUE_DATE: {
                if (draft.type === 'ChangeDueDate') {
                    if (draft.task) {
                        draft.task.dueDate = moment.parseZone(action.date).format();
                    } else if (draft.bulkActionsTasks) {
                        draft.bulkUpdates.dueDate = moment.parseZone(action.date).format();
                    }
                } else if (draft.type === 'ConfirmChangeDueDate' && draft.task) {
                    draft.task.dueDate = moment(action.date).format();
                }
                return;
            }

            case FILTER_DROPDOWN_TOGGLE_IS_OPEN: {
                if (draft.type === 'ChangeAssignee') {
                    const activeFilter = getFilterById(draft.filtersDraft, action.filterId);
                    if (activeFilter) {
                        const isOpened = !activeFilter.isOpened;
                        for (const filter of draft.filtersDraft) {
                            filter.isOpened = false;
                        }
                        activeFilter.isOpened = isOpened;
                        if (!isOpened) {
                            activeFilter.inputValue =
                                activeFilter.selectedItemIndex === null
                                    ? null
                                    : activeFilter.items[activeFilter.selectedItemIndex]
                                          .formattedValue;
                            activeFilter.items = [];
                        }
                    }
                }
                return;
            }

            case FILTER_DROPDOWN_FETCH_VALUES_REQUEST: {
                if (draft.type === 'ChangeAssignee') {
                    const activeFilter = getFilterById(draft.filtersDraft, action.filterId);
                    if (activeFilter) {
                        for (const filter of draft.filtersDraft) {
                            if (filter.id !== action.filterId) filter.isDisabled = true;
                        }
                        activeFilter.items = [];
                        activeFilter.isLoading = true;
                    }
                }
                return;
            }

            case FILTER_DROPDOWN_FETCH_VALUES_SUCCESS: {
                if (draft.type === 'ChangeAssignee') {
                    const activeFilter = getFilterById(draft.filtersDraft, action.filterId);
                    if (activeFilter) {
                        for (const filter of draft.filtersDraft) {
                            if (filter.id !== action.filterId) filter.isDisabled = false;
                        }
                        activeFilter.isLoading = false;
                        activeFilter.items = action.values;
                    }
                }
                return;
            }

            case FILTER_DROPDOWN_SELECT_ITEM: {
                if (draft.type === 'ChangeAssignee') {
                    const activeFilter = getFilterById(draft.filtersDraft, action.filterId);
                    if (!activeFilter) return;
                    for (const filter of draft.filtersDraft) {
                        if (filter.id !== action.filterId && filter.selectedItemIndex === null) {
                            filter.items = [];
                        }
                    }
                    activeFilter.selectedItemIndex = action.selectedIndex;
                    activeFilter.isOpened = false;
                    activeFilter.inputValue =
                        action.selectedIndex !== null && activeFilter.items[action.selectedIndex]
                            ? activeFilter.items[action.selectedIndex].formattedValue
                            : null;
                }
                return;
            }

            case FILTER_DROPDOWN_INPUT_CHANGE: {
                if (draft.type === 'ChangeAssignee') {
                    const filter = getFilterById(draft.filtersDraft, action.filterId);
                    if (!filter) return;
                    filter.inputValue = action.value;
                }
                return;
            }

            case CHANGE_CM_VALUES: {
                if (draft.type === 'ChangeAssignee' && draft.activeContactManager) {
                    if (action.resetItems) {
                        draft.activeContactManager.values = action.cmValues;
                        draft.offset = CONTACTS_LIMIT;
                    } else {
                        draft.activeContactManager.values = {
                            ...action.cmValues,
                            items: [
                                ...(draft.activeContactManager.values?.items ?? []),
                                ...action.cmValues.items,
                            ],
                        };
                        draft.offset += CONTACTS_LIMIT;
                    }

                    draft.hasMoreContacts = action.cmValues.items.length === CONTACTS_LIMIT;
                }
                return;
            }

            case RESET_ALL_FILTERS: {
                if (draft.type === 'ChangeAssignee') {
                    draft.filtersDraft = getInitialFilters(draft.filtersDraft);
                }
                return;
            }

            case DISPLAY_CHANGE_ASSIGNEE_CONTENT: {
                if (draft.type === 'ChangeAssignee') {
                    draft.changeAssigneeContent = 'contacts';
                }
                return;
            }

            case DISPLAY_CHANGE_ASSIGNEE_FILTERS: {
                if (draft.type === 'ChangeAssignee') {
                    draft.changeAssigneeContent = 'filter';
                    draft.searchQuery = '';
                    draft.filtersDraft = draft.filters;
                }
                return;
            }

            case CHANGE_SEARCH_QUERY: {
                if (draft.type === 'ChangeAssignee') {
                    draft.searchQuery = action.query;
                }
                return;
            }

            case APPLY_FILTERS: {
                if (draft.type === 'ChangeAssignee') {
                    draft.filters = draft.filtersDraft;
                    draft.filtersDraft = [];
                }
                return;
            }

            case CHANGE_IS_LOADING: {
                if (draft.type !== 'NullState') {
                    draft.isLoading = action.isLoading;
                }
                return;
            }

            case BULK_QUEUE_ADD: {
                const { bulkActionsQueueElement } = action;
                draft.bulkActionsQueue.push(bulkActionsQueueElement);
                break;
            }

            case BULK_QUEUE_CHANGE_STATUS: {
                const { bulkActionsQueueElementKey, bulkActionsQueueElementStatus } = action;
                const bulkActionQueueElement = draft.bulkActionsQueue.find(
                    el => el.key === bulkActionsQueueElementKey,
                );
                if (bulkActionQueueElement)
                    bulkActionQueueElement.status = bulkActionsQueueElementStatus;
                break;
            }

            case BULK_QUEUE_DELETE: {
                const { bulkActionsQueueElementKey } = action;
                draft.bulkActionsQueue = draft.bulkActionsQueue.filter(
                    el => el.key !== bulkActionsQueueElementKey,
                );
                break;
            }

            default:
                return state;
        }
    });

const getNameFieldId = (columns: ApiGW.DataModelColumnsDTO): number => {
    const nameField = columns.items.find(column => column.name.includes('<PRESENTATION/>'))!;

    return nameField.id;
};

const fetchContactManagerData = async (
    taskDefinition: TaskDefinition,
    api: ApiClient,
): Promise<[ContactManagerView, ApiGW.ContactFilterDTO[]]> => {
    const CM = await Promise.all([
        api.fetchCMViewColumns(taskDefinition.contactManager.viewId),
        api.getContactFilterFields(taskDefinition.id),
    ]);

    const nameFieldId = getNameFieldId(CM[0]);
    const activeContactManager = {
        nameFieldId,
        columns: CM[0],
        values: null,
    };

    return [activeContactManager, CM[1].items];
};

export const tasksActionsCreator = {
    openActionPopup: (
        action: SecondaryTaskAction,
        taskId?: number,
        optionalParams?: {
            task?: Task;
            showVotingMessage?: boolean;
        },
    ): ThunkActionVoid => {
        return async (
            dispatch,
            getState,
            {
                api,
                toastsCreator,
                contentSelector,
                tasksActionsCreator: creator,
                taskDefinitionsSelector,
                tasksSelectedActionsSelector,
            },
        ) => {
            const state = getState();
            const impersonateMode = getImpersonateMode(state);
            if (impersonateMode) {
                dispatch(toastsCreator.createImpersonateMessageToast());
                return;
            }

            if (contentSelector.isContentCategoryTasks(state)) {
                const taskDefinitionsById = taskDefinitionsSelector.getTaskDefinitionsById(state);
                const tasks = contentSelector.getTasks(state);

                let task: Task | undefined;
                let bulkActionsTasks: Task[] | undefined;
                if (!taskId) {
                    const bulkActionTasksIds = Object.keys(
                        tasksSelectedActionsSelector.getSelectedTasksMap(state),
                    ).map(id => Number(id));
                    bulkActionsTasks = tasks.filter(t => bulkActionTasksIds.includes(t.id));
                } else {
                    task = optionalParams?.task || tasks.find(item => item.id === taskId);
                }

                if (bulkActionsTasks) {
                    ReactGA.event('bulk_action', { action_type: action });
                }

                if (!task && !bulkActionsTasks) {
                    return;
                }
                switch (action) {
                    case 'ChangeAssignee': {
                        const taskDefinition =
                            (task && taskDefinitionsById[task.taskDefinitionId]) ||
                            (bulkActionsTasks &&
                                taskDefinitionsById[bulkActionsTasks[0].taskDefinitionId]);
                        if (!taskDefinition) {
                            return;
                        }
                        try {
                            const [activeContactManager, contactFilters] =
                                await fetchContactManagerData(taskDefinition, api);

                            if (task) {
                                dispatch({
                                    action,
                                    activeContactManager,
                                    contactFilters,
                                    type: OPEN_ACTION_POPUP,
                                    task,
                                });
                            } else if (bulkActionsTasks) {
                                dispatch({
                                    action,
                                    activeContactManager,
                                    contactFilters,
                                    type: OPEN_ACTION_POPUP,
                                    bulkActionsTasks,
                                });
                            }
                        } catch (error) {
                            dispatch(
                                toastsCreator.createToast(i18n.t(CHANGE_ASSIGNEE_FAILED), 'error'),
                            );
                        }
                        dispatch(creator.changeIsLoading(true));
                        await dispatch(creator.getCMViewValues());
                        return dispatch(creator.changeIsLoading(false));
                    }

                    case 'ChangeStatus': {
                        if (task) {
                            return dispatch({
                                action,
                                task,
                                showVotingMessage: optionalParams?.showVotingMessage,
                                type: OPEN_ACTION_POPUP,
                            });
                        }
                        if (bulkActionsTasks) {
                            return dispatch({
                                action,
                                bulkActionsTasks,
                                showVotingMessage: optionalParams?.showVotingMessage,
                                type: OPEN_ACTION_POPUP,
                            });
                        }
                        break;
                    }

                    case 'AssignToMe': {
                        if (task) {
                            dispatch({
                                action,
                                task,
                                type: OPEN_ACTION_POPUP,
                            });
                            return dispatch(creator.assignToMe());
                        }
                        if (bulkActionsTasks) {
                            dispatch({
                                action,
                                bulkActionsTasks,
                                type: OPEN_ACTION_POPUP,
                            });
                            return dispatch(creator.assignToMeInBulk());
                        }
                        break;
                    }
                    case 'ChangeDueDate':
                    case 'UnAssign': {
                        if (task) {
                            return dispatch({
                                action,
                                task,
                                type: OPEN_ACTION_POPUP,
                            });
                        }
                        if (bulkActionsTasks) {
                            return dispatch({
                                action,
                                bulkActionsTasks,
                                type: OPEN_ACTION_POPUP,
                            });
                        }
                    }
                }
            }
        };
    },

    closeActionPopup: (): ThunkActionVoid => {
        return dispatch => {
            return dispatch({
                type: CLOSE_ACTION_POPUP,
            });
        };
    },

    assignToMe:
        (_?: any, logTaskDetailEvent = false): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            {
                api,
                commonCreator,
                toastsCreator,
                contentCreator,
                tasksActionsCreator: creator,
                taskActionsSelector,
                userSelector,
                activeCardSelectors,
                contentSelector,
            },
        ) => {
            const state = getState();
            const taskId = taskActionsSelector.getTaskId(state);
            const isTaskDetailViewOpen = activeCardSelectors.getTaskDetailViewTaskId(state);
            if (
                (taskActionsSelector.getType(state) === 'AssignToMe' || isTaskDetailViewOpen) &&
                taskId
            ) {
                try {
                    if (logTaskDetailEvent)
                        onTaskDetailAction(
                            'assign to me',
                            contentSelector.getTaskDetailViewTask(state),
                        );
                    dispatch(creator.changeIsLoading(true));
                    await api.assignTask(taskId, userSelector.getUserId(state));
                    dispatch(toastsCreator.createToast(i18n.t(ASSIGN_TO_ME_SUCCESS)));
                } catch (error: any) {
                    if (error.status === STATUS_NOT_FOUND) {
                        return dispatch(commonCreator.handle404());
                    }
                    return dispatch(
                        toastsCreator.createToast(i18n.t(CHANGE_ASSIGNEE_FAILED), 'error'),
                    );
                } finally {
                    dispatch(creator.changeIsLoading(false));
                    await dispatch(creator.closeActionPopup());
                }

                return dispatch(contentCreator.refresh(false));
            }
        },

    changeAssignee:
        (_?: any, logTaskDetailEvent = false): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            {
                api,
                commonCreator,
                toastsCreator,
                contentCreator,
                taskActionsSelector,
                tasksActionsCreator: creator,
                contentSelector,
            },
        ) => {
            const state = getState();
            const taskId = taskActionsSelector.getTaskId(state);
            const activeContactId = taskActionsSelector.getActiveContactId(state);
            if (
                taskActionsSelector.getType(state) === 'ChangeAssignee' &&
                taskId &&
                activeContactId
            ) {
                try {
                    if (logTaskDetailEvent)
                        onTaskDetailAction(
                            'reassign',
                            contentSelector.getTaskDetailViewTask(state),
                        );
                    dispatch(creator.changeIsLoading(true));
                    await api.assignTask(taskId, activeContactId);
                    dispatch(toastsCreator.createToast(i18n.t(CHANGE_ASSIGNEE_SUCCESS)));
                    return dispatch(contentCreator.refresh(false));
                } catch (error: any) {
                    if (error.status === STATUS_NOT_FOUND) {
                        return dispatch(commonCreator.handle404());
                    }
                    return dispatch(
                        toastsCreator.createToast(i18n.t(CHANGE_ASSIGNEE_FAILED), 'error'),
                    );
                } finally {
                    dispatch(creator.changeIsLoading(false));
                    await dispatch(creator.closeActionPopup());
                }
            }
        },

    unAssign:
        (_?: any, logTaskDetailEvent = false): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            {
                api,
                commonCreator,
                toastsCreator,
                contentCreator,
                taskActionsSelector,
                tasksActionsCreator: creator,
                activeCardSelectors,
                contentSelector,
            },
        ) => {
            const state = getState();
            const taskId = taskActionsSelector.getTaskId(state);
            const isTaskDetailViewOpen = activeCardSelectors.getTaskDetailViewTaskId(state);

            if ((taskActionsSelector.getIsUnAssign(state) || isTaskDetailViewOpen) && taskId) {
                try {
                    if (logTaskDetailEvent)
                        onTaskDetailAction(
                            'unassign',
                            contentSelector.getTaskDetailViewTask(state),
                        );
                    dispatch(creator.changeIsLoading(true));
                    await api.unassignTask(taskId);
                    dispatch(toastsCreator.createToast(i18n.t(UNASSIGN_SUCCESS)));
                    return dispatch(contentCreator.refresh(false));
                } catch (error: any) {
                    if (error.status === STATUS_NOT_FOUND) {
                        return dispatch(commonCreator.handle404());
                    }

                    return dispatch(
                        toastsCreator.createToast(i18n.t(CHANGE_ASSIGNEE_FAILED), 'error'),
                    );
                } finally {
                    dispatch(creator.changeIsLoading(false));
                    await dispatch(creator.closeActionPopup());
                }
            }
        },

    setDueDate:
        (logTaskDetailEvent = false): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            {
                api,
                commonCreator,
                toastsCreator,
                contentCreator,
                taskActionsSelector,
                tasksActionsCreator: creator,
                contentSelector,
            },
        ) => {
            const state = getState();
            const taskId = taskActionsSelector.getTaskId(state);
            const date = taskActionsSelector.getDate(state);
            if (
                (taskActionsSelector.getType(state) === 'ChangeDueDate' ||
                    taskActionsSelector.getType(state) === 'ConfirmChangeDueDate') &&
                taskId &&
                date
            ) {
                try {
                    if (logTaskDetailEvent)
                        onTaskDetailAction(
                            'due date',
                            contentSelector.getTaskDetailViewTask(state),
                        );
                    dispatch(creator.changeIsLoading(true));
                    await api.changeDueDate(taskId, date);

                    dispatch(toastsCreator.createToast(i18n.t(CHANGE_DUEDATE_SUCCESS)));
                    return dispatch(contentCreator.refresh(false));
                } catch (error: any) {
                    if (error.status === STATUS_NOT_FOUND) {
                        return dispatch(commonCreator.handle404());
                    }

                    return dispatch(
                        toastsCreator.createToast(i18n.t(CHANGE_DUEDATE_FAILED), 'error'),
                    );
                } finally {
                    dispatch(creator.changeIsLoading(false));
                    await dispatch(creator.closeActionPopup());
                }
            }
        },

    setStatus:
        (logTaskDetailEvent = false): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            {
                api,
                commonCreator,
                toastsCreator,
                contentCreator,
                taskActionsSelector,
                tasksActionsCreator: creator,
                contentSelector,
            },
        ) => {
            const state = getState();
            const taskId = taskActionsSelector.getTaskId(state);
            const currentStatusId = taskActionsSelector.getCurrentStatusId(state);
            const showVotingMessage = taskActionsSelector.getShowVotingMessage(state);

            if (
                taskActionsSelector.getType(state) === 'ChangeStatus' &&
                taskId &&
                currentStatusId
            ) {
                try {
                    if (logTaskDetailEvent)
                        onTaskDetailAction('status', contentSelector.getTaskDetailViewTask(state));
                    dispatch(creator.changeIsLoading(true));
                    await api.setStatus(taskId, currentStatusId);
                    dispatch(toastsCreator.createToast(i18n.t(CHANGE_STATUS_SUCCESS)));
                    await dispatch(contentCreator.refresh(false));
                    if (showVotingMessage) return dispatch(creator.showMessageAfterVoting(true));
                } catch (error: any) {
                    if (error.status === STATUS_NOT_FOUND) {
                        return dispatch(commonCreator.handle404());
                    }

                    return dispatch(
                        toastsCreator.createToast(i18n.t(CHANGE_STATUS_FAILED), 'error'),
                    );
                } finally {
                    dispatch(creator.changeIsLoading(false));
                    await dispatch(creator.closeActionPopup());
                }
            }
        },

    changeActiveContactId:
        (activeContactId: number): ThunkActionVoid =>
        dispatch =>
            dispatch({
                activeContactId,
                type: CHANGE_CONTACT_ID,
            }),

    changeCurrentDueDate: (
        date: Moment,
    ): {
        date: Moment;
        type: typeof CHANGE_DUE_DATE;
    } => ({
        date,
        type: CHANGE_DUE_DATE,
    }),

    changeCurrentStatusId:
        (currentStatusId: number): ThunkActionVoid =>
        dispatch =>
            dispatch({
                currentStatusId,
                type: CHANGE_STATUS_ID,
            }),

    toggleFilterDropdownIsOpen:
        (filterId: number): ThunkActionVoid =>
        (dispatch, getState, { taskActionsSelector, tasksActionsCreator: creator }) => {
            const state = getState();
            const filtersDraft = taskActionsSelector.getFiltersDraft(state);
            if (taskActionsSelector.getType(state) === 'ChangeAssignee' && filtersDraft) {
                const filter = getFilterById(filtersDraft, filterId);
                if (!filter) return;

                if (!filter.isOpened && filter.items.length === 0) {
                    dispatch(creator.fetchContactFilterValues(filterId));
                }
                dispatch({
                    filterId,
                    type: FILTER_DROPDOWN_TOGGLE_IS_OPEN,
                });
            }
        },

    fetchContactFilterValues:
        (filterId: number): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            { api, commonCreator, taskActionsSelector, tasksActionsCreator: creator },
        ) => {
            const state = getState();
            const taskId = taskActionsSelector.getTaskId(state);
            const filtersDraft = taskActionsSelector.getFiltersDraft(state);

            if (taskActionsSelector.getType(state) === 'ChangeAssignee' && filtersDraft && taskId) {
                const filter = getFilterById(filtersDraft, filterId);
                if (!filter) return;
                const selectedFilters = getSelectedFilters(
                    filtersDraft.filter(fltr => fltr.id !== filterId),
                );

                dispatch({
                    filterId,
                    type: FILTER_DROPDOWN_FETCH_VALUES_REQUEST,
                });
                const response = await api.fetchContactFilterValues(
                    filterId,
                    filter.inputValue,
                    taskId,
                    selectedFilters,
                );
                const values = response.items.map((item: ApiGW.ContactFilterRowDTO) => {
                    return item.fields[0];
                });
                if (values.length === 0) {
                    // check if task still exists
                    try {
                        await api.getTask(taskId);
                    } catch (error: any) {
                        if (error.status === STATUS_NOT_FOUND) {
                            await dispatch(creator.closeActionPopup());
                            return dispatch(commonCreator.handle404());
                        }
                    }
                }
                return dispatch({
                    filterId,
                    values,
                    type: FILTER_DROPDOWN_FETCH_VALUES_SUCCESS,
                });
            }
        },

    filterDropdownChangeInput:
        (filterId: number, value: string | null): ThunkActionVoid =>
        async (dispatch, getState, { taskActionsSelector, tasksActionsCreator: creator }) => {
            const state = getState();
            const filtersDraft = taskActionsSelector.getFiltersDraft(state);
            if (taskActionsSelector.getType(state) === 'ChangeAssignee' && filtersDraft) {
                const filter = getFilterById(filtersDraft, filterId);
                if (!filter) return;

                dispatch({
                    filterId,
                    value,
                    type: FILTER_DROPDOWN_INPUT_CHANGE,
                });
                if (filter.selectedItemIndex === null) {
                    dispatch(creator.fetchContactFilterValues(filterId));
                }
            }
        },

    filterDropdownSelectItem:
        (filterId: number, selectedIndex: number | null): ThunkActionVoid =>
        async (dispatch, _, { tasksActionsCreator: creator }) => {
            dispatch({
                filterId,
                selectedIndex,
                type: FILTER_DROPDOWN_SELECT_ITEM,
            });
            dispatch(creator.getCMViewValues(true));
        },

    changeSearchQuery:
        (newValue: string): ThunkActionVoid =>
        async (dispatch, getState, { taskActionsSelector, tasksActionsCreator: creator }) => {
            if (taskActionsSelector.getType(getState()) === 'ChangeAssignee') {
                dispatch({
                    query: newValue,
                    type: CHANGE_SEARCH_QUERY,
                });
                dispatch(creator.getCMViewValues(true));
            }
        },

    resetAllFilters:
        (): ThunkActionVoid =>
        (dispatch, getState, { taskActionsSelector, tasksActionsCreator: creator }) => {
            if (taskActionsSelector.getType(getState()) === 'ChangeAssignee') {
                dispatch({ type: RESET_ALL_FILTERS });
                dispatch(creator.getCMViewValues(true));
            }
        },

    getCMViewValues:
        (resetItems?: boolean): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            { api, commonCreator, taskActionsSelector, tasksActionsCreator: creator },
        ) => {
            const changeAssigneeState = taskActionsSelector.getChangeAssigneeState(getState());

            if (changeAssigneeState) {
                const {
                    changeAssigneeContent,
                    filters,
                    filtersDraft,
                    task,
                    bulkActionsTasks,
                    searchQuery,
                    offset,
                } = changeAssigneeState;
                const selectedFilters =
                    changeAssigneeContent === 'contacts'
                        ? getSelectedFilters(filters)
                        : getSelectedFilters(filtersDraft);

                dispatch(creator.changeIsLoading(true));

                let contactManagerValues: DataModelRowsDTO | null = null;
                if (task) {
                    contactManagerValues = await api.fetchCMViewValues(
                        task.taskDefinitionId,
                        [task!.id],
                        searchQuery,
                        selectedFilters,
                        CONTACTS_LIMIT,
                        resetItems ? 0 : offset,
                    );
                } else if (bulkActionsTasks) {
                    contactManagerValues = await api.fetchCMViewValues(
                        bulkActionsTasks[0].taskDefinitionId,
                        bulkActionsTasks.map(t => t.id),
                        searchQuery,
                        selectedFilters,
                        CONTACTS_LIMIT,
                        resetItems ? 0 : offset,
                    );
                }
                if (contactManagerValues && contactManagerValues.items.length === 0) {
                    // check if task still exists
                    try {
                        // TODO: OP-4295
                        // await api.getTask(task!.id);
                    } catch (error: any) {
                        if (error.status === STATUS_NOT_FOUND) {
                            await dispatch(tasksActionsCreator.closeActionPopup());
                            return dispatch(commonCreator.handle404());
                        }
                    }
                }

                if (contactManagerValues) {
                    dispatch({
                        resetItems,
                        type: CHANGE_CM_VALUES,
                        cmValues: contactManagerValues,
                    });
                }
                dispatch(creator.changeIsLoading(false));
            }
        },

    displayChangeAssigneeContent: (): ThunkActionVoid => dispatch => {
        dispatch({ type: DISPLAY_CHANGE_ASSIGNEE_CONTENT });
    },

    displayChangeAssigneeFilters: (): ThunkActionVoid => dispatch => {
        dispatch({ type: DISPLAY_CHANGE_ASSIGNEE_FILTERS });
    },

    applyChangeAssigneeFilters: (): ThunkActionVoid => dispatch => {
        dispatch({ type: APPLY_FILTERS });
    },

    dispatchMainAction:
        (action: MainTaskAction, taskId: number, taskDefinitionId: number): ThunkActionVoid =>
        (dispatch, getState, extraParams) => {
            const { votingCreator, toastsCreator } = extraParams;

            const state = getState();
            const impersonateMode = getImpersonateMode(state);

            if (impersonateMode) {
                dispatch(toastsCreator.createImpersonateMessageToast());
                return;
            }

            const tasksActions = extraParams.tasksActionsCreator;
            switch (action) {
                case 'FillOutForm':
                    return dispatch(
                        votingCreator.openTaskResponse(taskId, taskDefinitionId, 'edit'),
                    );
                case 'ViewForm':
                    return dispatch(
                        votingCreator.openTaskResponse(taskId, taskDefinitionId, 'view'),
                    );
                case 'ChangeStatus':
                    return dispatch(tasksActions.openActionPopup('ChangeStatus', taskId));
            }
        },

    handleTasksAfterVoting:
        (): ThunkActionVoid =>
        async (dispatch, getState, { tasksActionsCreator: creator, contentSelector, api }) => {
            const state = getState();
            if (!contentSelector.isContentCategoryTasks(state)) return;

            let shouldChangeStatus: boolean;
            shouldChangeStatus = getCanChangeStatusAfterVoting(state);

            const submit = getSubmit(state);
            const contentHasActiveItem = getContentHasActiveItem(state);
            const taskId = getVotingCreatedItemId(state);

            let task: Task | undefined;

            if (!contentHasActiveItem && taskId && submit) {
                const contactId = getUserId(state);
                task = await api.getTask(taskId);
                const taskDefinition = task && getTaskDefinitionsById(state)[task.taskDefinitionId];
                if (taskDefinition) {
                    shouldChangeStatus = canChangeStatusAfterVoting({
                        task,
                        taskDefinition,
                        contactId,
                    });
                }
            } else if (contentHasActiveItem) {
                const tasks = contentSelector.getTasks(state);
                task = tasks.find(activeTask => activeTask.id === taskId);
            }

            if (shouldChangeStatus && task) {
                dispatch(
                    creator.openActionPopup('ChangeStatus', task.id, {
                        task,
                        showVotingMessage: true,
                    }),
                );
            } else {
                dispatch(creator.showMessageAfterVoting(shouldChangeStatus));
            }
        },

    showMessageAfterVoting:
        (shouldChangeStatus: boolean): ThunkActionVoid =>
        async (dispatch, getState, { toastsCreator }) => {
            const state = getState();
            const submit = getSubmit(state);
            const resultHasBeenSaved = getResultHasBeenSaved(state);

            const contentHasActiveItem = getContentHasActiveItem(state);

            const message = getTaskMessageAfterVoting(
                shouldChangeStatus,
                submit,
                contentHasActiveItem,
                resultHasBeenSaved,
            );

            if (message.text) {
                const toastButton = message.showRecent
                    ? {
                          text: i18n.t('Voting.ShowRecent'),
                          action: TOAST_TASK_UPDATE_FILTERS,
                      }
                    : undefined;
                dispatch(
                    toastsCreator.createToast(i18n.t(message.text), 'info', true, toastButton),
                );
            }
        },

    changeIsLoading:
        (isLoading: boolean): ThunkActionVoid =>
        dispatch => {
            dispatch({
                isLoading,
                type: CHANGE_IS_LOADING,
            });
        },

    updateTasksInBulk:
        (bulkUpdates: ApiGW.TaskPartialUpdateDTOV3, bulkActionsTasks: Task[]): ThunkActionVoid =>
        async (dispatch, __, { api }) => {
            const bulkActionsTasksIds = bulkActionsTasks.map(task => task.id);
            const updateResponse: any = await api.updateTasksBatch({
                updates: bulkUpdates,
                filters: {
                    tasks: { include: bulkActionsTasksIds },
                } as any,
            });
            let updateJobIsDone = false;
            while (!updateJobIsDone) {
                // eslint-disable-next-line no-await-in-loop
                await new Promise(r => setTimeout(r, 1000));
                // eslint-disable-next-line no-await-in-loop
                const jobStatusResponse: any = await api.getExportTasksStatus(
                    updateResponse.headers.location,
                );
                if (jobStatusResponse.state !== 'IN-PROGRESS') {
                    updateJobIsDone = true;
                    if (jobStatusResponse.state === 'FAILED') {
                        throw new BulkActionError({
                            current: jobStatusResponse.current,
                            failed: jobStatusResponse.total - jobStatusResponse.current,
                        });
                    }
                }
            }
        },

    startNextBulk:
        (): ThunkActionVoid =>
        async (dispatch, getState, { tasksActionsCreator: creator }) => {
            const { bulkActionsQueue } = getState().tasksActions;
            const hasJobInProgress =
                bulkActionsQueue.filter(el => el.status === 'inProgress').length > 0;
            if (hasJobInProgress) {
                return;
            }
            const waitingQueueElements = bulkActionsQueue
                .filter(el => el.status === 'wait')
                .sort((a, b) => a.time - b.time);
            if (waitingQueueElements.length === 0) {
                return;
            }
            const nextQueueElement = waitingQueueElements[0];

            dispatch({
                type: BULK_QUEUE_CHANGE_STATUS,
                bulkActionsQueueElementKey: nextQueueElement.key,
                bulkActionsQueueElementStatus: 'inProgress',
            });

            switch (nextQueueElement.typeAction) {
                case 'ChangeStatus':
                    dispatch(creator.changeStatusInBulkStart(nextQueueElement));
                    break;
                case 'ChangeAssignee':
                    dispatch(creator.changeAssigneeInBulkStart(nextQueueElement));
                    break;
                case 'AssignToMe':
                    dispatch(creator.assignToMeInBulkStart(nextQueueElement));
                    break;
                case 'UnAssign':
                    dispatch(creator.unAssignInBulkStart(nextQueueElement));
                    break;
                case 'ChangeDueDate':
                    dispatch(creator.setDueDateInBulkStart(nextQueueElement));
                    break;
            }
        },

    addBulkActionIntoQueue:
        (toastMessage: string, bulkActionsQueueElement: BulkActionQueueElement): ThunkActionVoid =>
        async (dispatch, getState, { tasksActionsCreator: creator, toastsCreator }) => {
            dispatch(creator.changeIsLoading(true));
            dispatch({
                type: BULK_QUEUE_ADD,
                bulkActionsQueueElement,
            });

            dispatch(creator.changeIsLoading(false));
            dispatch(creator.closeActionPopup());
            dispatch(
                toastsCreator.createToast(
                    toastMessage,
                    'load',
                    true,
                    undefined,
                    bulkActionsQueueElement.toastKey,
                ),
            );
            dispatch(creator.startNextBulk());
        },

    changeStatusInBulk:
        (): ThunkActionVoid =>
        async (dispatch, getState, { taskActionsSelector, tasksActionsCreator: creator }) => {
            const state = getState();
            const bulkActionsTasks = taskActionsSelector.getBulkActionsTasks(state);
            const bulkUpdates = taskActionsSelector.getBulkUpdates(state);
            const showVotingMessage = taskActionsSelector.getShowVotingMessage(state);
            const typeAction = taskActionsSelector.getType(state);
            if (typeAction === 'ChangeStatus' && bulkUpdates?.statusId && bulkActionsTasks) {
                const toastKey = generateUniqueKey();
                const key = generateUniqueKey();
                const bulkActionsQueueElement: BulkActionQueueElement = {
                    key,
                    time: Date.now(),
                    status: 'wait',
                    bulkActionsTasks,
                    bulkUpdates,
                    showVotingMessage,
                    typeAction,
                    toastKey,
                };
                dispatch(
                    creator.addBulkActionIntoQueue(
                        i18n.t(CHANGE_STATUS_BULK_START, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        bulkActionsQueueElement,
                    ),
                );
            }
        },

    changeStatusInBulkStart:
        (element: BulkActionQueueElement): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            { toastsCreator, contentCreator, tasksActionsCreator: creator },
        ) => {
            const { bulkActionsTasks, bulkUpdates, showVotingMessage, toastKey } = element;
            try {
                await dispatch(creator.updateTasksInBulk(bulkUpdates, bulkActionsTasks));
                dispatch(toastsCreator.removeToast(toastKey));
                dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_STATUS_BULK_SUCCESS, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                    ),
                );
                await dispatch(contentCreator.refresh(false));
                if (showVotingMessage) return dispatch(creator.showMessageAfterVoting(true));
            } catch (error: any) {
                dispatch(toastsCreator.removeToast(toastKey));
                if (error instanceof BulkActionError) {
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_STATUS_BULK_FAILED, { tasksNumber: error.failed }),
                            'error',
                        ),
                    );
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_STATUS_BULK_SUCCESS, { tasksNumber: error.current }),
                        ),
                    );
                    return dispatch(contentCreator.refresh(false));
                }
                return dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_STATUS_BULK_FAILED, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        'error',
                    ),
                );
            } finally {
                await dispatch({
                    type: BULK_QUEUE_DELETE,
                    bulkActionsQueueElementKey: element.key,
                });
                dispatch(creator.startNextBulk());
            }
        },

    changeAssigneeInBulk:
        (): ThunkActionVoid =>
        async (dispatch, getState, { taskActionsSelector, tasksActionsCreator: creator }) => {
            const state = getState();
            const bulkActionsTasks = taskActionsSelector.getBulkActionsTasks(state);
            const bulkUpdates = taskActionsSelector.getBulkUpdates(state);
            const typeAction = taskActionsSelector.getType(state);
            if (
                typeAction === 'ChangeAssignee' &&
                bulkActionsTasks &&
                bulkUpdates &&
                bulkUpdates.assigneeId
            ) {
                const toastKey = generateUniqueKey();
                const key = generateUniqueKey();
                const bulkActionsQueueElement: BulkActionQueueElement = {
                    key,
                    time: Date.now(),
                    status: 'wait',
                    bulkActionsTasks,
                    bulkUpdates,
                    typeAction,
                    toastKey,
                };
                dispatch(
                    creator.addBulkActionIntoQueue(
                        i18n.t(CHANGE_ASSIGNEE_BULK_START, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        bulkActionsQueueElement,
                    ),
                );
            }
        },

    changeAssigneeInBulkStart:
        (element: BulkActionQueueElement): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            { toastsCreator, contentCreator, tasksActionsCreator: creator },
        ) => {
            const { bulkActionsTasks, bulkUpdates, toastKey } = element;
            try {
                await dispatch(creator.updateTasksInBulk(bulkUpdates, bulkActionsTasks));
                dispatch(toastsCreator.removeToast(toastKey));
                dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_ASSIGNEE_BULK_SUCCESS, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                    ),
                );
                return dispatch(contentCreator.refresh(false));
            } catch (error: any) {
                dispatch(toastsCreator.removeToast(toastKey));
                if (error instanceof BulkActionError) {
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_ASSIGNEE_BULK_FAILED, { tasksNumber: error.failed }),
                            'error',
                        ),
                    );
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_ASSIGNEE_BULK_SUCCESS, {
                                tasksNumber: error.current,
                            }),
                        ),
                    );
                    return dispatch(contentCreator.refresh(false));
                }
                return dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_ASSIGNEE_BULK_FAILED, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        'error',
                    ),
                );
            } finally {
                await dispatch({
                    type: BULK_QUEUE_DELETE,
                    bulkActionsQueueElementKey: element.key,
                });
                dispatch(creator.startNextBulk());
            }
        },

    assignToMeInBulk:
        (): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            { tasksActionsCreator: creator, taskActionsSelector, userSelector },
        ) => {
            const state = getState();
            const bulkActionsTasks = taskActionsSelector.getBulkActionsTasks(state);
            const bulkUpdates = { assigneeId: userSelector.getUserId(state) };
            const typeAction = taskActionsSelector.getType(state);
            if (typeAction === 'AssignToMe' && bulkActionsTasks) {
                const toastKey = generateUniqueKey();
                const key = generateUniqueKey();
                const bulkActionsQueueElement: BulkActionQueueElement = {
                    key,
                    time: Date.now(),
                    status: 'wait',
                    bulkActionsTasks,
                    bulkUpdates,
                    typeAction,
                    toastKey,
                };
                dispatch(
                    creator.addBulkActionIntoQueue(
                        i18n.t(CHANGE_ASSIGNEE_BULK_START, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        bulkActionsQueueElement,
                    ),
                );
            }
        },

    assignToMeInBulkStart:
        (element: BulkActionQueueElement): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            { toastsCreator, contentCreator, tasksActionsCreator: creator },
        ) => {
            const { bulkActionsTasks, bulkUpdates, toastKey } = element;
            try {
                await dispatch(creator.updateTasksInBulk(bulkUpdates, bulkActionsTasks));
                dispatch(toastsCreator.removeToast(toastKey));
                dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_ASSIGNEE_BULK_SUCCESS, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                    ),
                );
                return dispatch(contentCreator.refresh(false));
            } catch (error: any) {
                dispatch(toastsCreator.removeToast(toastKey));
                if (error instanceof BulkActionError) {
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_ASSIGNEE_BULK_FAILED, { tasksNumber: error.failed }),
                            'error',
                        ),
                    );
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_ASSIGNEE_BULK_SUCCESS, {
                                tasksNumber: error.current,
                            }),
                        ),
                    );
                    return dispatch(contentCreator.refresh(false));
                }
                return dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_ASSIGNEE_BULK_FAILED, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        'error',
                    ),
                );
            } finally {
                await dispatch({
                    type: BULK_QUEUE_DELETE,
                    bulkActionsQueueElementKey: element.key,
                });
                dispatch(creator.startNextBulk());
            }
        },

    unAssignInBulk:
        (): ThunkActionVoid =>
        async (dispatch, getState, { taskActionsSelector, tasksActionsCreator: creator }) => {
            const state = getState();
            const bulkActionsTasks = taskActionsSelector.getBulkActionsTasks(state);
            const bulkUpdates = { assigneeId: null };
            const typeAction = taskActionsSelector.getType(state);

            if (taskActionsSelector.getIsUnAssign(state) && bulkActionsTasks) {
                const toastKey = generateUniqueKey();
                const key = generateUniqueKey();
                const bulkActionsQueueElement: BulkActionQueueElement = {
                    key,
                    time: Date.now(),
                    status: 'wait',
                    bulkActionsTasks,
                    bulkUpdates,
                    typeAction,
                    toastKey,
                };
                dispatch(
                    creator.addBulkActionIntoQueue(
                        i18n.t(CHANGE_ASSIGNEE_BULK_START, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        bulkActionsQueueElement,
                    ),
                );
            }
        },

    unAssignInBulkStart:
        (element: BulkActionQueueElement): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            { toastsCreator, contentCreator, tasksActionsCreator: creator },
        ) => {
            const { bulkActionsTasks, bulkUpdates, toastKey } = element;
            try {
                await dispatch(creator.updateTasksInBulk(bulkUpdates, bulkActionsTasks));
                dispatch(toastsCreator.removeToast(toastKey));
                dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_ASSIGNEE_BULK_SUCCESS, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                    ),
                );
                return dispatch(contentCreator.refresh(false));
            } catch (error: any) {
                dispatch(toastsCreator.removeToast(toastKey));
                if (error instanceof BulkActionError) {
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_ASSIGNEE_BULK_FAILED, { tasksNumber: error.failed }),
                            'error',
                        ),
                    );
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_ASSIGNEE_BULK_SUCCESS, {
                                tasksNumber: error.current,
                            }),
                        ),
                    );
                    return dispatch(contentCreator.refresh(false));
                }
                return dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_ASSIGNEE_BULK_FAILED, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        'error',
                    ),
                );
            } finally {
                await dispatch({
                    type: BULK_QUEUE_DELETE,
                    bulkActionsQueueElementKey: element.key,
                });
                dispatch(creator.startNextBulk());
            }
        },

    setDueDateInBulk:
        (): ThunkActionVoid =>
        async (dispatch, getState, { taskActionsSelector, tasksActionsCreator: creator }) => {
            const state = getState();
            const bulkActionsTasks = taskActionsSelector.getBulkActionsTasks(state);
            const bulkUpdates = taskActionsSelector.getBulkUpdates(state);
            const typeAction = taskActionsSelector.getType(state);
            const date = bulkUpdates?.dueDate;
            if (typeAction === 'ChangeDueDate' && bulkActionsTasks && date) {
                const toastKey = generateUniqueKey();
                const key = generateUniqueKey();
                const bulkActionsQueueElement: BulkActionQueueElement = {
                    key,
                    time: Date.now(),
                    status: 'wait',
                    bulkActionsTasks,
                    bulkUpdates,
                    typeAction,
                    toastKey,
                };
                dispatch(
                    creator.addBulkActionIntoQueue(
                        i18n.t(CHANGE_DUEDATE_BULK_START, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        bulkActionsQueueElement,
                    ),
                );
            }
        },

    setDueDateInBulkStart:
        (element: BulkActionQueueElement): ThunkActionVoid =>
        async (
            dispatch,
            getState,
            { commonCreator, toastsCreator, contentCreator, tasksActionsCreator: creator },
        ) => {
            const { bulkActionsTasks, bulkUpdates, toastKey } = element;
            try {
                await dispatch(creator.updateTasksInBulk(bulkUpdates, bulkActionsTasks));
                dispatch(toastsCreator.removeToast(toastKey));
                dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_DUEDATE_BULK_SUCCESS, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                    ),
                );
                return dispatch(contentCreator.refresh(false));
            } catch (error: any) {
                dispatch(toastsCreator.removeToast(toastKey));
                if (error.status === STATUS_NOT_FOUND) {
                    return dispatch(commonCreator.handle404());
                }
                if (error instanceof BulkActionError) {
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_DUEDATE_BULK_FAILED, { tasksNumber: error.failed }),
                            'error',
                        ),
                    );
                    dispatch(
                        toastsCreator.createToast(
                            i18n.t(CHANGE_DUEDATE_BULK_SUCCESS, { tasksNumber: error.current }),
                        ),
                    );
                    return dispatch(contentCreator.refresh(false));
                }
                return dispatch(
                    toastsCreator.createToast(
                        i18n.t(CHANGE_DUEDATE_BULK_FAILED, {
                            tasksNumber: bulkActionsTasks.length,
                        }),
                        'error',
                    ),
                );
            } finally {
                await dispatch({
                    type: BULK_QUEUE_DELETE,
                    bulkActionsQueueElementKey: element.key,
                });
                dispatch(creator.startNextBulk());
            }
        },

    openConfirmChangeDatePopup:
        (
            taskId: number,
            date: Moment,
            optionalParams?: {
                task?: Task;
            },
        ): ThunkActionVoid =>
        (dispatch, getState, { toastsCreator, contentSelector }) => {
            const state = getState();
            const impersonateMode = getImpersonateMode(state);
            if (impersonateMode) {
                dispatch(toastsCreator.createImpersonateMessageToast());
                return;
            }

            const tasks = contentSelector.getTasks(state);

            const task = optionalParams?.task || tasks.find(item => item.id === taskId);

            if (!task) {
                return;
            }
            dispatch({
                action: 'ConfirmChangeDueDate',
                task,
                type: OPEN_ACTION_POPUP,
            });
            // Keep original timezone from task's dueDate
            const targetDate = moment(task.dueDate)
                .year(date.year())
                .month(date.month())
                .date(date.date());
            return dispatch(tasksActionsCreator.changeCurrentDueDate(targetDate));
        },
};

export default reducer;
