import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ActionIcon } from 'worldapp-ui/shared';
import { ArrowBack } from 'worldapp-ui/shared/icons';
import { createPortal } from 'react-dom';
import { useDrag } from '@use-gesture/react';
import classNames from 'classnames';
import { RemoveScroll } from 'react-remove-scroll';
import { useDebouncedCallback } from 'use-debounce/lib';
import { animated, useSprings } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import {
    getMainTaskAction,
    getSecondaryTaskActionsTable,
    SecondaryTaskAction,
    taskHasEditAction,
} from '../../../redux/content/validateTaskActions';

import { useActionCreators } from '../../../hooks/useActionCreators';
import { getTaskPermissionsInfo } from '../../../redux/activeCard/activeCardSelectors.reselect';
import { contentLoading, getTasks } from '../../../redux/content/contentSelector';
import TaskDetailViewPanelContent from './TaskDetailViewPanelContent';
import { taskDetailViewStyles } from './TaskDetailView.styles';
import { isMobile } from '../../../utils/browser.utils';
import { TaskDetailViewPlaceholder } from './TaskDetailViewPlaceholder';
import { getType } from '../../../redux/tasksActions/taskActionsSelector';
import { getIsCalendarView } from '../../../redux/menu/menuSelector';
import { getUserId } from '../../../redux/user/userSelector';
import { Task } from '../../../redux/content/content.types';

function clamp(number: number, lower: number, upper: number) {
    // eslint-disable-next-line no-param-reassign
    number = number <= upper ? number : upper;
    // eslint-disable-next-line no-param-reassign
    number = number >= lower ? number : lower;
    return number;
}

export const TasksDetailsCarouselView: React.FC<Record<string, never>> = () => {
    const { tasksActionsCreator, activeCardCreator, tasksExportCreator } = useActionCreators();
    const actionType = useSelector(getType);
    const taskPermissionsInfo = useSelector(getTaskPermissionsInfo);
    const contactId = useSelector(getUserId);
    const isLoading = useSelector(contentLoading);
    const tasks = useSelector(getTasks);
    const isCaledarView = useSelector(getIsCalendarView);
    const { t } = useTranslation();
    const classes = taskDetailViewStyles();

    const [index, setIndex] = useState(taskPermissionsInfo?.task.index || 0);
    const [width, setWidth] = useState(window.innerWidth - 24);

    const userRecentlyChangedCardIndexRef = useRef(false);

    const onResizeDebounced = useDebouncedCallback(() => {
        setWidth(window.innerWidth - 24);
    }, 1000);

    useEffect(() => {
        window.addEventListener('resize', onResizeDebounced.callback);
        return () => window.removeEventListener('resize', onResizeDebounced.callback);
    }, [onResizeDebounced]);

    const [props, api] = useSprings(tasks.length, i => ({
        x: i * width,
        scale: 1,
        display: 'block',
        config: {
            mass: 5,
            tension: 260,
            friction: 85,
        },
    }));

    const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel, swipe }) => {
        if (swipe[0] !== 0) {
            const newIndex = clamp(index + (swipe[0] > 0 ? -1 : 1), 0, tasks.length - 1);
            const oldIndex = index;
            // return last(first) card to place after it was swiped
            if (newIndex === oldIndex) {
                api.start(i => {
                    let display = 'block';
                    if (i < index - 1 || i > index + 1) {
                        display = 'none';
                    }
                    let x = (i - index) * width;
                    if (x > 0) {
                        x -= 12;
                    } else if (x < 0) {
                        x += 12;
                    }
                    return { x, scale: 1, display };
                });
            } else {
                userRecentlyChangedCardIndexRef.current = true;
                activeCardCreator.openTaskDetailView(tasks[newIndex].id);
            }
        } else if (active && Math.abs(mx) > width / 3) {
            activeCardCreator.openTaskDetailView(
                tasks[clamp(index + (xDir > 0 ? -1 : 1), 0, tasks.length - 1)].id,
            );
            userRecentlyChangedCardIndexRef.current = true;
            cancel();
        } else {
            api.start(i => {
                if (i < index - 1 || i > index + 1) return { display: 'none' };
                let x = (i - index) * width + (active ? mx : 0);
                if (i !== index) {
                    if (x > 0) {
                        x -= 12;
                    } else if (x < 0) {
                        x += 12;
                    }
                }
                const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
                return { x, scale, display: 'block' };
            });
        }
    });

    useLayoutEffect(() => {
        api.start(i => {
            let display = 'block';
            if (i < index - 1 || i > index + 1) {
                display = 'none';
            }
            let x = (i - index) * width;
            if (x > 0) {
                x -= 12;
            } else if (x < 0) {
                x += 12;
            }
            if (userRecentlyChangedCardIndexRef.current) {
                return { x, scale: 1, display };
            }
            return { x, scale: 1, display, immediate: true };
        });
        userRecentlyChangedCardIndexRef.current = false;
    }, [index, api, width]);

    useLayoutEffect(() => {
        const indexFromTaskPermissions = taskPermissionsInfo?.task.index;
        if (indexFromTaskPermissions !== undefined) {
            setIndex(indexFromTaskPermissions);
        } else {
            setIndex(0);
        }
    }, [taskPermissionsInfo?.task.index, api, width]);

    const onActionClick = (action: SecondaryTaskAction) => () =>
        taskPermissionsInfo
            ? tasksActionsCreator.openActionPopup(action, taskPermissionsInfo.task.id)
            : null;

    const tdSecondaryActions = taskPermissionsInfo
        ? getSecondaryTaskActionsTable({
              ...taskPermissionsInfo,
          }).reduce((a, action) => ({ ...a, [action]: onActionClick(action) }), {})
        : null;

    const mainAction = taskPermissionsInfo
        ? getMainTaskAction({ ...taskPermissionsInfo })
        : undefined;
    const tdMainTaskAction =
        mainAction && mainAction !== 'ChangeStatus' && taskPermissionsInfo
            ? {
                  onClick: () =>
                      tasksActionsCreator.dispatchMainAction(
                          mainAction,
                          taskPermissionsInfo.task.id,
                          taskPermissionsInfo.taskDefinition.id,
                      ),
                  name: t(`TaskActions.${mainAction}.Label`),
              }
            : undefined;

    const getEditFormAction = (task: Task) =>
        taskHasEditAction(mainAction, task, contactId) && taskPermissionsInfo
            ? {
                  onClick: () =>
                      tasksActionsCreator.dispatchMainAction(
                          'FillOutForm',
                          taskPermissionsInfo.task.id,
                          taskPermissionsInfo.taskDefinition.id,
                      ),
                  name: t(`TaskActions.EditForm.Label`),
              }
            : undefined;

    const leftArrowClickHandler = () => {
        userRecentlyChangedCardIndexRef.current = true;
        activeCardCreator.openTaskDetailView(tasks[index - 1].id);
    };

    const rigthArrowClickHandler = () => {
        userRecentlyChangedCardIndexRef.current = true;
        activeCardCreator.openTaskDetailView(tasks[index + 1].id);
    };

    return createPortal(
        <RemoveScroll>
            <div className={classes.carouselBackdrop}>
                {taskPermissionsInfo &&
                    props.map(({ display, scale, x }, i) => (
                        <animated.div
                            key={i}
                            {...(isMobile && actionType === 'NullState' ? { ...bind() } : null)}
                            className={classes.carouselCardWrapper}
                            style={{
                                x,
                                display,
                                scale,
                            }}
                            data-ismobile={isMobile ? 'true' : 'false'}
                            data-testid="task-details-animated-wrapper"
                        >
                            {i >= index - 1 && i <= index + 1 && (
                                <div className={classes.carouselCardContentWrapper}>
                                    <TaskDetailViewPanelContent
                                        actions={tdSecondaryActions!}
                                        task={tasks[i]}
                                        taskDefinition={taskPermissionsInfo.taskDefinition}
                                        editFormAction={getEditFormAction(tasks[i])}
                                        mainAction={tdMainTaskAction}
                                        onClose={activeCardCreator.closeTaskDetailView}
                                        onExport={param =>
                                            tasksExportCreator.exportTasks(param, true)
                                        }
                                    />
                                </div>
                            )}
                        </animated.div>
                    ))}
                {!isMobile && !isCaledarView && (
                    <>
                        <ActionIcon
                            className={classNames(classes.iconBtn, classes.iconBtnPrev, {
                                [classes.iconBtnDisabled]: index === 0 || !taskPermissionsInfo,
                            })}
                            onClick={leftArrowClickHandler}
                            data-testid="task-detail-btn-left"
                            aria-label={t('TaskDetailView.Previous')}
                        >
                            <ArrowBack />
                        </ActionIcon>
                        <ActionIcon
                            className={classNames(classes.iconBtn, classes.iconBtnNext, {
                                [classes.iconBtnDisabled]:
                                    index === tasks.length - 1 || !taskPermissionsInfo,
                            })}
                            onClick={rigthArrowClickHandler}
                            data-testid="task-detail-btn-right"
                            aria-label={t('TaskDetailView.Next')}
                        >
                            <ArrowBack />
                        </ActionIcon>
                    </>
                )}
                {(isLoading || !taskPermissionsInfo) && (
                    <div
                        className={classes.carouselCardWrapper}
                        data-ismobile={isMobile ? 'true' : 'false'}
                    >
                        <TaskDetailViewPlaceholder />
                    </div>
                )}
            </div>
        </RemoveScroll>,
        document.body,
    );
};
