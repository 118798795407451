import { StorageInterface } from './storage.types';

export const storage: StorageInterface = {
    get: (key: string) => {
        const value = localStorage.getItem(key);
        if (value == null) return null;

        return JSON.parse(value);
    },

    set: (key: string, value: any) => {
        const serializedValue = JSON.stringify(value);
        return localStorage.setItem(key, serializedValue);
    },
};
