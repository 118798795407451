import { TableColumn } from './tasksTable.types';
import { ApiGW } from '../../types/DTO/api-gw';
import { DEFAULT_COLUMN_WIDTH, defaultTableWidth } from './taskTable.constants';

export const TABLE_COLUMN_ID_CUSTOM_PROPERTY_PREFIX = 'custom_value_';

export const getIdFromNumber = (id: number): string =>
    `${TABLE_COLUMN_ID_CUSTOM_PROPERTY_PREFIX}${id}`;

interface SetColumnsWidth {
    columnsWithWidth: TableColumn[];
    rowWidth: number;
}
export const setColumnsWidth = (
    containerWidth: number,
    tableColumns: TableColumn[],
): SetColumnsWidth => {
    let columns = tableColumns.map(column => ({
        ...column,
        width: column.relativeWidth * DEFAULT_COLUMN_WIDTH,
    }));

    const totalColumnWidth = columns.reduce((acc, curr) => acc + (curr.width || 0), 0);

    if (totalColumnWidth < containerWidth) {
        const coeff = containerWidth / totalColumnWidth;
        columns = columns.map(column => ({
            ...column,
            width: (column.width || 0) * coeff,
        }));
    }

    return { columnsWithWidth: columns, rowWidth: totalColumnWidth };
};

export const getCustomPropertyColumnWidth = (
    customProperty: ApiGW.TaskDefinitionCustomPropertyDTO,
): number => {
    if (customProperty.mappedFormItem) {
        switch (customProperty.mappedFormItem.valueType) {
            case 'NUMBER':
            case 'NUMBER_NATURAL':
            case 'AMOUNT':
            case 'PERCENT':
                return defaultTableWidth.customNumber;
            case 'DATE':
                return defaultTableWidth.customDate;
        }
    }
    return defaultTableWidth.customGeneral;
};
