import { URLPart } from 'worldapp-fe-utils';
import { Converter, LocationState } from '../location.types';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import { categories, Category } from '../../redux/content/category.types';

const ALL = 'all';

export const navigationConverter: Converter = {
    stateToURL: (state: LocationState): Partial<URLPart> | undefined => {
        switch (state.activeCategory) {
            case 'tasks':
                return {
                    query: {
                        nav: 'tasks',
                        td:
                            state.activeItemId === ALL_TASKS_ID
                                ? ALL
                                : state.activeItemId?.toString() ?? '',
                    },
                };
            case 'forms':
                return {
                    query: {
                        nav: 'forms',
                        form: state.activeItemId?.toString() ?? '',
                    },
                };
            case 'dashboards':
                return {
                    query: {
                        nav: 'dashboards',
                    },
                };
            default:
                return undefined;
        }
    },
    URLToState: url => {
        const nav = url.query.nav?.[0] as Category;
        const activeCategory = categories.includes(nav) ? nav : null;
        switch (activeCategory) {
            case 'dashboards':
                return {
                    activeCategory,
                };
            case 'forms':
                const activeFormId = Number.parseInt(url.query.form?.[0], 10) || null;
                return {
                    activeCategory,
                    activeItemId: activeFormId,
                };
            case 'tasks':
                const tdItem = url.query.td?.[0];
                const activeTDId =
                    tdItem === ALL ? ALL_TASKS_ID : Number.parseInt(tdItem, 10) || null;
                return {
                    activeCategory,
                    activeItemId: activeTDId,
                };
        }
    },
};
