// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import { PortalId } from '../login/login.types';
import { goto, gotoLoginPage, gotoSAML } from '../../utils/window.location.utils';
import { GO_TO_URL } from './goToURL.types';

export const goToURLCreator = {
    gotoURL:
        (url: string): ThunkActionVoid =>
        async dispatch => {
            await dispatch({
                type: GO_TO_URL,
            });
            goto(url);
        },

    gotoLoginPage:
        (portalId: PortalId): ThunkActionVoid =>
        async dispatch => {
            await dispatch({
                type: GO_TO_URL,
            });
            gotoLoginPage(portalId);
        },

    gotoSAML:
        (id: PortalId): ThunkActionVoid =>
        async dispatch => {
            await dispatch({
                type: GO_TO_URL,
            });
            gotoSAML(id);
        },

    gotoOldPortal:
        (): ThunkActionVoid =>
        async (dispatch, getState, { getPortal2URL }) => {
            await dispatch({
                type: GO_TO_URL,
            });
            const url = getPortal2URL(getState());
            goto(url);
        },
};
