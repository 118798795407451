import { asArray } from 'worldapp-fe-utils';
import { LOGIN_PAGE_URL, PortalId } from '../redux/login/login.types';
import { portalIdToString } from '../redux/login/portalId';

let redirecting = false;
export const resetRedirecting = (): void => {
    redirecting = false;
};

export function goto(url: string): void {
    if (redirecting) return;
    redirecting = true;
    window.location.href = url;
}

export function gotoLoginPage(portalId: PortalId): void {
    const pid = portalIdToString(portalId);
    window.location.href = `${LOGIN_PAGE_URL}${pid}/?login=`;
}

export function gotoSAML(portalId: PortalId): void {
    const pid = portalIdToString(portalId);
    goto(`/gw/v1/auth/portals/${pid}/saml-login`);
}

export function appendParamsToRelativeURL(
    url: string,
    params: Record<string, string | string[]>,
): string {
    const base = window.location.origin;
    const urlObj = new URL(url, base);
    Object.entries(params).forEach(([key, value]) => {
        asArray(value).forEach(subValue => urlObj.searchParams.append(key, subValue));
    });
    return urlObj.href.replace(base, '');
}

export const getOrigin = (): string => window.location.origin;
