import { PreparedURL } from 'worldapp-fe-utils';
import { Converter } from '../location.types';

export const submitConverter: Converter = {
    stateToURL: () => undefined,
    URLToState: (url: PreparedURL) => {
        const submit = !!url.query.submit?.[0];
        return { submit };
    },
};
