import React from 'react';
import classNames from 'classnames';
import { MenuItem, Grid, Text, Icon, resolution } from 'worldapp-ui';
import { browserDetect } from 'worldapp-fe-utils';
import navigationTreeMenuItemStyles from './NavigationTreeMenuItem.style';

interface Props {
    isMenuOpen: boolean;
    title: string;
    isSelected: boolean;
    count?: number;
    onClick: () => void;
    autoFocus?: boolean;
}

const NavigationTreeMenuItem = (props: Props): JSX.Element => {
    const { isMenuOpen, title, isSelected, onClick, ...restProps } = props;
    const classes = navigationTreeMenuItemStyles();
    const { isIE } = browserDetect(window.navigator.userAgent);
    return (
        <MenuItem
            className={classNames(
                classes.menuItem,
                isSelected && classes.selected,
                !isMenuOpen && classes.menuItemClosed,
            )}
            selected={isSelected}
            onClick={onClick}
            aria-label={title}
            {...restProps}
        >
            <Grid
                container={true}
                className={classNames(classes.contentRoot, isSelected && classes.selected)}
            >
                <Icon
                    className={classNames(classes.icon, isMenuOpen && classes.iconWithOpenedMenu)}
                    name="bullet-point"
                    mode={isSelected ? 'fill' : 'stroke'}
                    size="l"
                />

                {isMenuOpen && (
                    <Grid
                        container={true}
                        justify="space-between"
                        className={classes.contentRoot}
                        data-testid="menu-item-label"
                    >
                        <Grid item={true} style={{ minWidth: 0 }}>
                            <Text
                                variant="r14r"
                                size={resolution.loRes}
                                className={classNames(
                                    classes.menuItemText,
                                    isIE && classes.menuItemTextIE,
                                )}
                            >
                                {title}
                            </Text>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </MenuItem>
    );
};

export default NavigationTreeMenuItem;
