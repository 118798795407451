import deepEqual from 'deep-equal';
import { getContentItemId, isContentCategoryTasks } from '../content/contentSelector';
import {
    AppliedFiltersSets,
    ASSIGN_DATE_FILTER_ID,
    ASSIGNEE_FILTER_ID,
    ConfigurableFilterType,
    DateFilterMeta,
    DUE_DATE_FILTER_ID,
    Filter,
    FilterMeta,
    FiltersPopupStage,
    FiltersSet,
    FiltersSetsContentItem,
    ItemFilter,
    ItemFilterMeta,
    SavedFiltersSets,
    STATUSES_FILTER_ID,
    StringFilter,
    StringFilterMeta,
    TD_FILTER_ID,
    TDSavedFiltersSets,
} from './tasksConfigurableFilters.types';
import { TaskQuickFilters } from '../contentQuickFilters/contentQiuckFilters.types';
import {
    getActiveTaskDefinitions,
    getCustomProperties,
    isRequireDueDate,
} from '../taskDefinitions/taskDefinitionsSelector.reselect';
import { getStatusToIdMap } from './taskConfigurableFilters.utils';
import { ApiGW } from '../../types/DTO/api-gw';
import taskDefinitionAssigneesSelector from '../taskDefinitionAssignees/taskDefinitionAssigneesSelector';
import { TasksConfigurableFiltersSelector } from './tasksConfigurableFiltersSelector.types';
import { AppState } from '../AppState.types';
import i18n from '../../localization/i18n';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import { getTaskDefinitions } from '../taskDefinitions/taskDefinitionsSelector';
import { getFilterByTDFeature } from '../features/featuresSelector';
// eslint-disable-next-line import/no-cycle
import { getInitialTaskQuickFilters } from '../contentQuickFilters/contentQuickFiltersSelector';

export const getInitialPredefinedFilters = getInitialTaskQuickFilters;

export const getDefaultFiltersSetFactory =
    (getInitialPredefinedFiltersGetter = getInitialPredefinedFilters) =>
    (state: AppState): FiltersSet => ({
        predefined: { ...getInitialPredefinedFiltersGetter(state) },
        custom: {},
    });
export const getDefaultFiltersSet = getDefaultFiltersSetFactory();

export const getEmptyFiltersSetFactory =
    (getInitialPredefinedFiltersGetter = getInitialPredefinedFilters) =>
    (state: AppState): FiltersSet => {
        const predefined = { ...getInitialPredefinedFiltersGetter(state) };
        Object.keys(predefined).forEach(key => {
            predefined[key as keyof TaskQuickFilters] = false;
        });
        return {
            predefined,
            custom: {},
        };
    };
export const getEmptyFiltersSet = getEmptyFiltersSetFactory();

// ////////////////////////////////////// Simple Selectors ////////////////////////////////////////////////////////////
/* istanbul ignore next */
export const getPopupStage = ({ tasksConfigurableFilters }: AppState): FiltersPopupStage =>
    tasksConfigurableFilters.popupStage;

/* istanbul ignore next */
export const getFiltersMeta = ({ tasksConfigurableFilters }: AppState): FilterMeta[] | null =>
    tasksConfigurableFilters.filtersMeta;

/* istanbul ignore next */
export const getActiveFiltersSet = ({ tasksConfigurableFilters }: AppState): FiltersSet | null =>
    tasksConfigurableFilters.activeFiltersSet;

/* istanbul ignore next */
export const getActiveFiltersSetName = ({ tasksConfigurableFilters }: AppState): string | null =>
    tasksConfigurableFilters.activeFiltersSetName;

/* istanbul ignore next */
export const getAppliedFiltersSets = (state: AppState): AppliedFiltersSets =>
    state.tasksConfigurableFilters.appliedFiltersSets;

/* istanbul ignore next */
export const getSavedFiltersSets = ({ tasksConfigurableFilters }: AppState): SavedFiltersSets =>
    tasksConfigurableFilters.savedFiltersSets;

export const getIsFiltersPopupOpen = ({ tasksConfigurableFilters }: AppState): boolean =>
    [FiltersPopupStage.GeneralFiltersTab, FiltersPopupStage.FiltersSetsTab].includes(
        tasksConfigurableFilters.popupStage,
    );

export const getIsActiveFiltersSetModifiedFactory =
    (emptyFiltersSetGetter = getEmptyFiltersSet) =>
    (state: AppState): boolean =>
        state.tasksConfigurableFilters.activeFiltersSet !== null &&
        !deepEqual(state.tasksConfigurableFilters.activeFiltersSet, emptyFiltersSetGetter(state));

export const getIsActiveFiltersSetModified = getIsActiveFiltersSetModifiedFactory();

// ////////////////////////////////////// Complex Selectors ////////////////////////////////////////////////////////////
export const getActiveFilterByIdFactory =
    (activeFiltersSetGetter = getActiveFiltersSet) =>
    (state: AppState, id: string | number): Filter | null => {
        const filtersSet = activeFiltersSetGetter(state);
        if (!filtersSet) return null;
        return filtersSet.custom[id] ?? null;
    };
export const getActiveFilterById = getActiveFilterByIdFactory();
export const getAcitveAssigneeFilter = (state: AppState): Filter | null =>
    getActiveFilterById(state, 'assignee');

export const getActivePredefinedFiltersFactory =
    (activeFiltersSetGetter = getActiveFiltersSet) =>
    (state: AppState): TaskQuickFilters | null => {
        const activeFiltersSet = activeFiltersSetGetter(state);
        return activeFiltersSet ? activeFiltersSet.predefined : null;
    };
export const getActivePredefinedFilters = getActivePredefinedFiltersFactory();

export const getAppliedFiltersSetFactory =
    (
        isContentTasks = isContentCategoryTasks,
        contentItemIdGetter = getContentItemId,
        appliedFiltersSetsGetter = getAppliedFiltersSets,
        getFilterByTDFeatureGetter = getFilterByTDFeature,
        getInitialPredefinedFiltersGetter = getInitialPredefinedFilters,
    ) =>
    (state: AppState): FiltersSet | null => {
        const activeTaskDefinitionId = contentItemIdGetter(state);
        if (!isContentTasks(state) || !activeTaskDefinitionId) return null;
        const appliedFiltersSets = appliedFiltersSetsGetter(state);

        let filtersSet = appliedFiltersSets[activeTaskDefinitionId] ?? null;

        if (filtersSet && !getFilterByTDFeatureGetter(state)) {
            const customFilters = filtersSet.custom;
            filtersSet = { ...filtersSet, custom: {} };

            Object.entries(customFilters).forEach(([key, filter]) => {
                if (key !== 'taskDefinition') {
                    filtersSet.custom[key] = filter;
                }
            });
        }
        if (!filtersSet)
            filtersSet = {
                predefined: { ...getInitialPredefinedFiltersGetter(state) },
                custom: {},
            };
        return filtersSet;
    };
export const getAppliedFiltersSet = getAppliedFiltersSetFactory();

export const getAppliedAssigneeFilterFactory =
    (appliedFiltersSetGetter = getAppliedFiltersSet) =>
    (state: AppState): StringFilter | null => {
        const appliedFiltersSet = appliedFiltersSetGetter(state);
        if (!appliedFiltersSet) return null;
        return (appliedFiltersSet.custom[ASSIGNEE_FILTER_ID] as StringFilter) ?? null;
    };
export const getAppliedAssigneeFilter = getAppliedAssigneeFilterFactory();

export const getAppliedTDFilterFactory =
    (appliedFiltersSetGetter = getAppliedFiltersSet) =>
    (state: AppState): ItemFilter | null => {
        const appliedFiltersSet = appliedFiltersSetGetter(state);
        if (!appliedFiltersSet) return null;
        return (appliedFiltersSet.custom[TD_FILTER_ID] as ItemFilter) ?? null;
    };
export const getAppliedTDFilter = getAppliedTDFilterFactory();

export const getAppliedFiltersCountFactory =
    (appliedFiltersSetGetter = getAppliedFiltersSet) =>
    (state: AppState): number | null => {
        const appliedFiltersSet = appliedFiltersSetGetter(state);
        if (!appliedFiltersSet) return null;

        return (
            Object.keys(appliedFiltersSet.custom).length +
            Object.values(appliedFiltersSet.predefined).filter(isApplied => isApplied).length
        );
    };
export const getAppliedFiltersCount = getAppliedFiltersCountFactory();

export const getSavedFilterSetsForActiveTDFactory =
    (
        isContentTasks = isContentCategoryTasks,
        contentItemIdGetter = getContentItemId,
        savedFiltersSetsGetter = getSavedFiltersSets,
    ) =>
    (state: AppState): TDSavedFiltersSets | null => {
        const activeTaskDefinitionId = contentItemIdGetter(state);
        const savedFiltersSets = savedFiltersSetsGetter(state);
        if (!isContentTasks(state) || !activeTaskDefinitionId) return null;

        return savedFiltersSets[activeTaskDefinitionId] || null;
    };
export const getSavedFilterSetsForActiveTD = getSavedFilterSetsForActiveTDFactory();

export const getSavedFilterSetsNamesFactory =
    (savedFilterSetsGetter = getSavedFilterSetsForActiveTD) =>
    (state: AppState): string[] => {
        const savedFilterSet = savedFilterSetsGetter(state);
        if (!savedFilterSet) return [];

        return Object.keys(savedFilterSet);
    };
export const getSavedFilterSetsNames = getSavedFilterSetsNamesFactory();

export const getSavedFilterSetsAsFiltersSetsItemsFactory =
    (savedFilterSetsGetter = getSavedFilterSetsForActiveTD) =>
    (state: AppState): FiltersSetsContentItem[] => {
        const savedFilterSet = savedFilterSetsGetter(state);
        if (!savedFilterSet) return [];

        return Object.keys(savedFilterSet)
            .sort()
            .map(name => ({ id: name, label: name }));
    };
export const getSavedFilterSetsAsFiltersSetsItems = getSavedFilterSetsAsFiltersSetsItemsFactory();

export const getFiltersMetaIdsFactory =
    (filtersMetaGetter = getFiltersMeta) =>
    (state: AppState): Array<string | number> => {
        const filtersMeta = filtersMetaGetter(state);
        return filtersMeta ? filtersMeta.map(meta => meta.id) : [];
    };
export const getFiltersMetaIds = getFiltersMetaIdsFactory();

export const getFilterMetaByIdFactory =
    (filtersMetaGetter = getFiltersMeta) =>
    (state: AppState, filterId: number | string): FilterMeta | null => {
        const filtersMeta = filtersMetaGetter(state);
        if (!filtersMeta) return null;
        const meta = filtersMeta.find(m => m.id === filterId);
        return meta ?? null;
    };
export const getFilterMetaById = getFilterMetaByIdFactory();

export const isFiltersPopupLoadingFactory =
    (stageGetter = getPopupStage, filtersMetaGetter = getFiltersMeta) =>
    (state: AppState): boolean => {
        const stage = stageGetter(state);
        return stage !== FiltersPopupStage.Closed && filtersMetaGetter(state) === null;
    };
export const isFiltersPopupLoading = isFiltersPopupLoadingFactory();

export const getAssigneeFilterMetaFactory =
    (
        isContentTasks = isContentCategoryTasks,
        contentItemIdGetter = getContentItemId,
        tdAssigneesGetter = taskDefinitionAssigneesSelector,
    ) =>
    (state: AppState): StringFilterMeta | null => {
        const taskDefinitionId = contentItemIdGetter(state);
        if (!isContentTasks(state) || !taskDefinitionId) return null;

        const assigneeValues = tdAssigneesGetter.getAssigneeValues(state);
        const hasMore = tdAssigneesGetter.getHasMoreValues(state);
        const offset = tdAssigneesGetter.getOffset(state);

        return {
            offset,
            type: ConfigurableFilterType.String,
            id: ASSIGNEE_FILTER_ID,
            label: i18n.t('TaskFilters.Labels.Assignee'),
            items: assigneeValues.map(assignee => assignee.name),
            hasMoreItems: hasMore,
            query: '',
        };
    };
export const getAssigneeFilterMeta = getAssigneeFilterMetaFactory();

export const getStatusFilterMetaFactory =
    (
        isContentTasks = isContentCategoryTasks,
        activeTaskDefinitionsGetter = getActiveTaskDefinitions,
        statusToId = getStatusToIdMap,
    ) =>
    (state: AppState): StringFilterMeta | null => {
        if (!isContentTasks(state)) return null;

        const activeTaskDefinitions = activeTaskDefinitionsGetter(state);
        const statusMap: Record<string, number[]> = statusToId(activeTaskDefinitions);

        return {
            type: ConfigurableFilterType.String,
            id: STATUSES_FILTER_ID,
            label: i18n.t('TaskFilters.Labels.Statuses'),
            items: Object.keys(statusMap).sort(),
            hasMoreItems: false,
            offset: 0,
            query: '',
        };
    };
export const getStatusFilterMeta = getStatusFilterMetaFactory();

export const getDueDateFilterMetaFactory =
    (isContentTasks = isContentCategoryTasks, isFilterEnabled = isRequireDueDate) =>
    (state: AppState): DateFilterMeta | null => {
        if (!isContentTasks(state) || !isFilterEnabled(state)) return null;

        return {
            type: ConfigurableFilterType.Date,
            id: DUE_DATE_FILTER_ID,
            label: i18n.t('TaskFilters.Labels.DueDate'),
        };
    };
export const getDueDateFilterMeta = getDueDateFilterMetaFactory();

export const getAssignDateFilterMetaFactory =
    (isContentTasks = isContentCategoryTasks) =>
    (state: AppState): DateFilterMeta | null => {
        if (!isContentTasks(state)) return null;

        return {
            type: ConfigurableFilterType.Date,
            id: ASSIGN_DATE_FILTER_ID,
            label: i18n.t('TaskFilters.Labels.AssignDate'),
        };
    };
export const getAssignDateFilterMeta = getAssignDateFilterMetaFactory();

export const DEFAULT_CUSTOM_PROPERTIES_LIMIT = 50;

export const getCustomPropertyFiltersMetaFactory =
    (customPropertiesGetter = getCustomProperties) =>
    async (
        state: AppState,
        getPropertyValues: (
            taskPropertyId: number,
            limit: number,
            offset: number,
        ) => Promise<ApiGW.TaskPropertyExistingValuesDTO>,
    ): Promise<FilterMeta[]> => {
        const stringTypes = ['STRING', 'EMAIL', 'BOOLEAN'];
        const numberTypes = ['NUMBER', 'AMOUNT', 'PERCENT', 'NUMBER_NATURAL'];

        const customProperties = customPropertiesGetter(state);
        const promises = customProperties
            .filter(({ usage, mappedFormItem }) => mappedFormItem && (!usage || usage.filter))
            .map(
                (
                    customProperty: ApiGW.TaskDefinitionCustomPropertyDTO,
                ): Promise<{
                    customProperty: ApiGW.TaskDefinitionCustomPropertyDTO;
                    customPropValues: ApiGW.TaskPropertyExistingValuesDTO | null;
                }> => {
                    if (!stringTypes.includes(customProperty.mappedFormItem.valueType)) {
                        return Promise.resolve({ customProperty, customPropValues: null });
                    }
                    return getPropertyValues(
                        customProperty.id,
                        DEFAULT_CUSTOM_PROPERTIES_LIMIT,
                        0,
                    ).then(customPropValues => ({
                        customPropValues,
                        customProperty,
                    }));
                },
            );

        const customPropertiesValues = await Promise.all(promises);

        return customPropertiesValues.map(({ customProperty, customPropValues }) => {
            const { valueType } = customProperty.mappedFormItem;
            const meta = {
                id: customProperty.id,
                label: customProperty.title,
            };
            if (stringTypes.includes(valueType)) {
                return {
                    ...meta,
                    type: ConfigurableFilterType.String,
                    offset: customPropValues?.offset || 0 + DEFAULT_CUSTOM_PROPERTIES_LIMIT,
                    hasMoreItems:
                        customPropValues !== null
                            ? customPropValues?.values.length === DEFAULT_CUSTOM_PROPERTIES_LIMIT
                            : false,
                    items: customPropValues !== null ? customPropValues.values : [],
                    query: '',
                };
            }
            if (numberTypes.includes(valueType)) {
                return {
                    ...meta,
                    type: ConfigurableFilterType.Number,
                };
            }

            return {
                ...meta,
                type: ConfigurableFilterType.Date,
                dateFormat: customProperty.mappedFormItem.valueFormat?.toUpperCase(),
            };
        });
    };
export const getCustomPropertyFiltersMeta = getCustomPropertyFiltersMetaFactory();

export const getTDFilterMetaFactory =
    (
        isContentTasks = isContentCategoryTasks,
        contentItemIdGetter = getContentItemId,
        tdsGetter = getTaskDefinitions,
    ) =>
    (state: AppState): ItemFilterMeta | null => {
        const taskDefinitionId = contentItemIdGetter(state);
        if (!isContentTasks(state) || !taskDefinitionId || taskDefinitionId !== ALL_TASKS_ID)
            return null;

        const tds = tdsGetter(state).map(td => ({ id: td.id, label: td.title }));
        return {
            offset: 0,
            type: ConfigurableFilterType.Item,
            id: TD_FILTER_ID,
            label: i18n.t('TaskFilters.Labels.TaskDefinition'),
            items: tds,
            hasMoreItems: false,
            query: '',
        };
    };
export const getTDFilterMeta = getTDFilterMetaFactory();

const tasksConfigurableFiltersSelector: TasksConfigurableFiltersSelector = {
    // simple
    getPopupStage,
    getFiltersMeta,
    getActiveFiltersSet,
    getActiveFiltersSetName,
    getAppliedFiltersSets,
    getSavedFiltersSets,
    getIsFiltersPopupOpen,
    // complex
    getActiveFilterById,
    getActivePredefinedFilters,
    getAppliedFiltersSet,
    getAppliedAssigneeFilter,
    getAppliedFiltersCount,
    getSavedFilterSetsForActiveTD,
    getSavedFilterSetsNames,
    getSavedFilterSetsAsFiltersSetsItems,
    getFiltersMetaIds,
    getFilterMetaById,
    isFiltersPopupLoading,
    getAssigneeFilterMeta,
    getStatusFilterMeta,
    getDueDateFilterMeta,
    getCustomPropertyFiltersMeta,
    getAssignDateFilterMeta,
    getTDFilterMeta,
    getDefaultFiltersSet,
    getEmptyFiltersSet,
};

export default tasksConfigurableFiltersSelector;
