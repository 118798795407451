import { stateToURL, URLToState } from 'worldapp-fe-utils';
import { Converter } from '../location.types';
import { taskSortConverter } from './taskSortConverter';
import { quickFiltersConverter } from './quickFiltersConverter';
import { navigationConverter } from './navigationConverter';
import { taskViewConverter } from './taskViewConverter';
import { taskDetailViewConverter } from './taskDetailViewConverter';
import { activeCardConverter } from './activeCardConverter';
import { categories, Category } from '../../redux/content/category.types';
import { searchQueryConverter } from './searchQueryConverter';
import { startFormConverter } from './startFormConverter';

const converters: Converter[] = [
    navigationConverter,
    taskSortConverter,
    quickFiltersConverter,
    taskViewConverter,
    taskDetailViewConverter,
    activeCardConverter,
    searchQueryConverter,
    startFormConverter,
];
const defaultNavigation = 'default';

export const defaultNavigationConverter: Converter = {
    URLToState: url => {
        const nav = url.query.nav?.[0];
        if (categories.includes(nav as Category)) {
            return { isDefaultNavigation: false, ...URLToState(converters)(url) };
        }
    },
    stateToURL: state => {
        if (state.isDefaultNavigation) {
            const startForm = startFormConverter.stateToURL(state)?.query?.startForm;
            return {
                query: { nav: defaultNavigation, ...(startForm && { startForm }) },
            };
        }
        return stateToURL(converters)(state);
    },
};
