import { PortalId } from './login.types';

export const NullPortalId: PortalId = null;

export const portalIdToString = (portalId: PortalId): string => {
    return portalId ? portalId.toString() : '';
};

export const fromString = (portalId: string): PortalId => {
    const number = Number.parseInt(portalId, 10);
    return number || null;
};

export const isNumber = (portalId: PortalId): portalId is number => {
    return !!portalId;
};
