import { ApiGW } from '../../types/DTO/api-gw';

export const FETCH_FORMS = 'Forms/FETCH_FORMS';
export const FETCH_FORM = 'Forms/FETCH_FORM';

export interface FetchFormsAction {
    type: typeof FETCH_FORMS;
    forms: Form[];
}

export interface FetchFormAction {
    type: typeof FETCH_FORM;
    form: Form;
}

export type FormsAction = FetchFormsAction | FetchFormAction;

export type Form = ApiGW.FormDTOV3;

export interface FormsState {
    loading: boolean;
    forms: Form[];
}
