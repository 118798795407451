import { Theme, makeStyles } from 'worldapp-ui/shared';

export const startFormButtonStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(2),
        paddingBottom: 0,
    },
    containerCollapsed: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
}));
