import { Grid, makeStyles } from 'worldapp-ui/shared';
import React, { ReactElement } from 'react';

export interface CardBottomPanelProps {
    buttons?: ReactElement[];
}

const useCardBottomPanelStyles = makeStyles(() => ({
    buttonsContainer: {
        flex: 1,
        marginRight: -14,
    },
}));

const CardActionPanel = (props: CardBottomPanelProps): JSX.Element => {
    const classes = useCardBottomPanelStyles();
    const { buttons = [] } = props;

    const handleBubblingEvent = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Grid container alignItems="center" wrap="nowrap">
            {buttons.length > 0 && (
                <Grid
                    container={true}
                    item={true}
                    justify="flex-end"
                    className={classes.buttonsContainer}
                >
                    {buttons.map((button, index) => (
                        <Grid
                            key={index}
                            item={true}
                            onClick={handleBubblingEvent}
                            data-testid={`button-wrapper-${index}`}
                        >
                            {button}
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

export default CardActionPanel;
