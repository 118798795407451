import React from 'react';
import { ToastsProvider } from 'worldapp-ui/shared';
import { useDispatch, useSelector } from 'react-redux';
import Main from './Main';
import { toastsCreator } from '../redux/toasts/toasts';
import { getToasts } from '../redux/toasts/toastsSelector';
import { SSOLogoutFrame } from './SSOLogoutFrame';
import { getIncludedTasksSelected } from '../redux/tasksSelectedActions/tasksSelectedActionsSelector';
import { isMobile, isMobileDevice, isTablet } from '../utils/browser.utils';

export const MainContainer = (): JSX.Element => {
    const dispatch = useDispatch();
    const toasts = useSelector(getToasts);

    const removeToast = (key: string) => dispatch(toastsCreator.removeToast(key));
    const handleToastsButtonClick = (action: string) =>
        dispatch(toastsCreator.handleToastsButtonClick(action));
    const selectedTasks = useSelector(getIncludedTasksSelected);

    const bulkActionMenuVisible = selectedTasks.length >= 1;

    let bottomPosition = isMobileDevice ? '6px' : '18px';

    if (bulkActionMenuVisible) {
        if (!isMobile) bottomPosition = '26px';
        if (isTablet) bottomPosition = '94px';
        if (isMobileDevice) bottomPosition = '86px';
    }

    const toastsContainerRootStyles = {
        left: isMobileDevice ? '12px' : '24px',
        bottom: bottomPosition,
        maxWidth: isMobileDevice ? 'calc(100% - 24px)' : undefined,
    };

    return (
        <>
            <Main />
            <ToastsProvider
                toasts={toasts}
                removeToast={removeToast}
                onButtonClick={handleToastsButtonClick}
                data-testid="toasts-parent-container"
                size="xl"
                containerRootStyles={toastsContainerRootStyles}
                data-ismobile={isMobileDevice}
            />
            <SSOLogoutFrame />
        </>
    );
};
export default MainContainer;
