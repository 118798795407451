import { createSelector } from 'reselect';
import { getContentItemId, getTasks, isContentCategoryTasks } from '../content/contentSelector';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import {
    getTaskDefinitions,
    getTaskDefinitionsById,
    getTdIdsWithCreateTask,
} from './taskDefinitionsSelector';
import { CreateTaskParams, TaskDefinition } from './taskDefinitions.types';
import { getStartFormSelectedItem } from '../startForm/startFormSelector';
import { parseStartFormId } from '../startForm/startForm.utils';
import { getUserId } from '../user/userSelector';
import { canChangeStatusAfterVoting } from '../content/validateTaskActions';
import { getSubmit, getVotingCreatedItemId } from '../voting/votingSelector';

export const getActiveTaskDefinition = createSelector(
    isContentCategoryTasks,
    getTaskDefinitionsById,
    getContentItemId,
    (isContentTasks, taskDefinitionsById, taskDefinitionId) => {
        if (!isContentTasks || !taskDefinitionId) return null;
        return taskDefinitionsById[taskDefinitionId] || null;
    },
);

export const getActiveTaskDefinitions = createSelector(
    isContentCategoryTasks,
    getTaskDefinitions,
    getContentItemId,
    (isContentTasks, taskDefinitions, taskDefinitionId) => {
        if (!isContentTasks || !taskDefinitionId) return [];
        return taskDefinitionId === ALL_TASKS_ID
            ? taskDefinitions
            : taskDefinitions.filter(td => td.id === taskDefinitionId);
    },
);

export const getActiveTaskDefinitionIds = createSelector(
    getActiveTaskDefinitions,
    taskDefinitions => taskDefinitions.map(taskDefinition => taskDefinition.id),
);

export const getCustomProperties = createSelector(getActiveTaskDefinition, taskDefinition => {
    if (taskDefinition === null) return [];
    return taskDefinition.customProperties;
});

export const isRequireDueDate = createSelector(getActiveTaskDefinitions, taskDefinitions => {
    return taskDefinitions.some(taskDefinition => taskDefinition.requireDueDate);
});

export const getCountCalculated = createSelector(
    getTaskDefinitions,
    (taskDefinitions: TaskDefinition[]): boolean => {
        return taskDefinitions.some((td: TaskDefinition) => td.count !== undefined);
    },
);

export const getEmptyTaskDefinitions = createSelector(
    getTaskDefinitions,
    (taskDefinitions: TaskDefinition[]): TaskDefinition[] => {
        return taskDefinitions.filter((task: TaskDefinition) => !task.count);
    },
);

export const getNonEmptyTaskDefinitions = createSelector(
    getTaskDefinitions,
    (taskDefinitions: TaskDefinition[]): TaskDefinition[] => {
        return taskDefinitions.filter((task: TaskDefinition) => !!task.count);
    },
);

export const getTaskDefinitionsIds = createSelector(getTaskDefinitions, taskDefinitions =>
    taskDefinitions.map(taskDefinition => taskDefinition.id),
);

export const getHasCreateTaskPermit = createSelector(getTdIdsWithCreateTask, ids => ids.length > 0);

export const getTDsWithCreateTask = createSelector(
    getTaskDefinitions,
    getTdIdsWithCreateTask,
    (taskDefinitions, ids) => taskDefinitions.filter(td => td.form && ids.includes(td.id)),
);

export const getCreateTaskParams = createSelector(
    getStartFormSelectedItem,
    getTaskDefinitionsById,
    getUserId,
    (selectedItem, taskDefinitionsById, userId): CreateTaskParams | null => {
        const [, id] = parseStartFormId(selectedItem);
        const td = taskDefinitionsById[id];

        if (!td) return null;

        return {
            assigneeId: userId,
            ...(td.defaultTaskDescription && { description: td.defaultTaskDescription }),
            summary: td.defaultTaskSummary,
        };
    },
);

export const getCanChangeStatusAfterVoting = createSelector(
    getTasks,
    getTaskDefinitionsById,
    getVotingCreatedItemId,
    getUserId,
    getSubmit,
    (tasks, taskDefinitionsById, taskId, contactId, submit): boolean => {
        const task = taskId && tasks.find(activeTask => activeTask.id === taskId);
        const taskDefinition = task && taskDefinitionsById[task.taskDefinitionId];

        if (
            !submit ||
            !task ||
            !taskDefinition ||
            !canChangeStatusAfterVoting({
                task,
                taskDefinition,
                contactId,
            })
        ) {
            return false;
        }
        return true;
    },
);
