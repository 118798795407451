import { LocationState } from '../location/location.types';

export const LOCATION_DATA_KEY = 'LOCATION_DATA';

export const readAndClearLocationState = (): LocationState | null => {
    const encodedState = sessionStorage.getItem(LOCATION_DATA_KEY);
    sessionStorage.removeItem(LOCATION_DATA_KEY);
    return encodedState ? JSON.parse(atob(encodedState)) : null;
};

export const writeLocationState = (newLocationData: LocationState): void => {
    sessionStorage.setItem(LOCATION_DATA_KEY, btoa(JSON.stringify(newLocationData)));
};
