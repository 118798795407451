import React, { Component, Suspense } from 'react';
import { Provider } from 'react-redux';

import {
    CircularContouredLoader,
    primaryTheme,
    resolution,
    SizeProvider,
    ThemeProvider as MuiThemeProvider,
} from 'worldapp-ui/shared';
import { store } from './redux/store';
import { MainRouter } from './MainRouter';
import LanguageHelper from './LanguageHelper';
import ThemeProvider from './ThemeProvider';

import 'worldapp-ui/shared/customScroll/customScroll.css';
import { onAppLoaded } from './analytics';

class App extends Component {
    render(): JSX.Element {
        onAppLoaded();
        return (
            <Suspense
                fallback={
                    <MuiThemeProvider theme={primaryTheme}>
                        <CircularContouredLoader />
                    </MuiThemeProvider>
                }
            >
                <Provider store={store}>
                    <SizeProvider value={resolution.hiRes}>
                        <ThemeProvider>
                            <MainRouter />
                            <LanguageHelper />
                        </ThemeProvider>
                    </SizeProvider>
                </Provider>
            </Suspense>
        );
    }
}

export default App;
