import { Converter } from '../../location.types';

const rURL = 'login!rURL=';

export const rURLConverter: Converter = {
    URLToState: ({ hash }) => {
        if (hash?.startsWith(rURL)) {
            return {
                redirectURL: decodeURIComponent(hash?.substring(rURL.length)),
            };
        }
    },
    stateToURL: ({ redirectURL }) => {
        if (redirectURL) {
            return { hash: rURL + encodeURIComponent(redirectURL) };
        }
    },
};
