import { ApiGW } from '../../types/DTO/api-gw';

export const FETCH_ASSIGNEE_VALUES = 'Assignee/FETCH_ASSIGNEE_VALUES';
export const SET_ASSIGNEE_VALUES_LOADING = 'Assignee/SET_ASSIGNEE_VALUES_LOADING';

export interface FetchAssigneeValuesAction {
    type: typeof FETCH_ASSIGNEE_VALUES;
    assigneeValues: ApiGW.TaskAssigneeFilterValueDTOV3[];
    total: number;
    shouldResetState?: boolean;
}

export interface SetAssigneeValuesLoadingAction {
    type: typeof SET_ASSIGNEE_VALUES_LOADING;
}

export type TaskDefinitionAssigneesAction =
    | FetchAssigneeValuesAction
    | SetAssigneeValuesLoadingAction;

export interface TaskDefinitionAssigneesState {
    assigneeValues: ApiGW.TaskAssigneeFilterValueDTOV3[];
    offset: number;
    hasMoreValues: boolean;
}
