import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Link } from 'worldapp-ui/shared';
import useCustomLinkStyles from './CustomLinks.styles';

import { CustomLink } from '../../../redux/menu/menu.types';
import { AppState } from '../../../redux/AppState.types';
import { getCustomLinks } from '../../../redux/portalSettings/portalSettingsSelector';

export const mapStateToProps = (state: AppState) => ({
    customLinks: getCustomLinks(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
    isDrawerOpen: boolean;
};

export const CustomLinksComponent = (props: Props): JSX.Element => {
    const classes = useCustomLinkStyles();
    const { isDrawerOpen, customLinks }: Props = props;
    return (
        <div className={classes.root}>
            {customLinks.map((customLink: CustomLink) => (
                <Grid
                    key={customLink.id}
                    container={true}
                    className={classes.container}
                    data-testid={`customLink_${customLink.label[0].value}`}
                >
                    <Grid item={true}>
                        <Icon name="link" size="l" mode="stroke" className={classes.icon} />
                    </Grid>
                    {isDrawerOpen && (
                        <Grid item={true}>
                            <Link
                                className={classes.link}
                                href={customLink.url}
                                target="_blank"
                                data-testid={`customLink_${customLink.label[0].value}`}
                            >
                                {customLink.label[0].value}
                            </Link>
                        </Grid>
                    )}
                </Grid>
            ))}
        </div>
    );
};

export default connect(mapStateToProps)(CustomLinksComponent);
