import React from 'react';
import { MessageBox } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { tasksActionsCreator } from '../../redux/tasksActions/tasksActions';
import { AppState } from '../../redux/AppState.types';
import {
    getIsConfirmChangeDueDate,
    getIsLoading,
} from '../../redux/tasksActions/taskActionsSelector';
import { getTaskDetailViewTaskId } from '../../redux/activeCard/activeCardSelectors';
import { TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS } from '../../constants/ui.constants';

export const mapStateToProps = (state: AppState) => {
    return {
        isLoading: getIsLoading(state),
        isPopupOpen: getIsConfirmChangeDueDate(state),
        isTaskDetailsOpen: !!getTaskDetailViewTaskId(state),
    };
};

export const mapDispatchToProps = mapCreatorsToDispatchProps({
    closeActionPopup: tasksActionsCreator.closeActionPopup,
    onConfirm: tasksActionsCreator.setDueDate,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export const ConfirmChangeDueDate = (props: Props): JSX.Element | null => {
    const { closeActionPopup, isPopupOpen, isLoading, isTaskDetailsOpen, onConfirm } = props;

    const { t } = useTranslation();

    if (!isTaskDetailsOpen) {
        return (
            <MessageBox
                className={TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS}
                open={isPopupOpen}
                title={t('TaskActions.ConfirmChangeDueDate.PopupTitle')}
                confirmButton={{
                    label: t('TaskActions.ConfirmChangeDueDate.ConfirmButtonTitle'),
                    clickHandler: onConfirm,
                    buttonProps: {
                        disabled: isLoading,
                    },
                }}
                cancelButton={{
                    label: t('TaskActions.ConfirmChangeDueDate.CloseButtonTitle'),
                    clickHandler: closeActionPopup,
                    buttonProps: {
                        disabled: isLoading,
                    },
                }}
            >
                {t('TaskActions.ConfirmChangeDueDate.AreYouSure')}
            </MessageBox>
        );
    }
    return null;
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmChangeDueDate);
