import produce from 'immer';
import {
    CHANGE_QUICK_FILTER,
    COPY_QUICK_FILTERS_TO_APPLIED,
    ONLY_RECENT_QUICK_FILTER,
    QuickFilter,
    QuickFiltersAction,
    QuickFiltersState,
    ResponseQuickFilters,
    TaskQuickFilters,
} from './contentQiuckFilters.types';
import {
    APPLY_FILTERS,
    ApplyFiltersAction,
} from '../tasksConfigurableFilters/tasksConfigurableFilters.types';
// eslint-disable-next-line import/no-cycle
import { ContentQuickFiltersCreator } from './contentQuickFiltersCreator.types';
import { Category } from '../content/category.types';
import {
    HYDRATE_STATE_FROM_STORAGE,
    HydrateStateFromStorageAction,
} from '../initialLoader/initialLoader.types';

export const initialManagerTaskQuickFilters: TaskQuickFilters = {
    openTasks: true,
    myTasks: true,
    teamTasks: false,
    unassignedTasks: false,
    recentTasks: false,
    createdByMe: false,
    overdue: false,
};

export const initialAssigneeTaskQuickFilters: TaskQuickFilters = {
    openTasks: true,
    myTasks: false,
    teamTasks: false,
    unassignedTasks: false,
    recentTasks: false,
    createdByMe: false,
    overdue: false,
};

export const emptyTaskQuickFilters: TaskQuickFilters = {
    openTasks: false,
    myTasks: false,
    teamTasks: false,
    unassignedTasks: false,
    recentTasks: false,
    createdByMe: false,
    overdue: false,
};

export const initialResponseQuickFilters: ResponseQuickFilters = {
    inProgressResponses: true,
    completedResponses: false,
    recentResponses: false,
};

const onlyRecentResponseQuickFilters: ResponseQuickFilters = {
    inProgressResponses: false,
    completedResponses: false,
    recentResponses: true,
};

export const initialState: QuickFiltersState = { tasks: {}, forms: {} };

export const reducer = (
    state = initialState,
    action: QuickFiltersAction | ApplyFiltersAction | HydrateStateFromStorageAction,
): QuickFiltersState =>
    produce(state, (draft: QuickFiltersState) => {
        switch (action.type) {
            case HYDRATE_STATE_FROM_STORAGE:
                const { quickFilters, menu } = action.state;
                const activeItemId = menu?.navigation?.activeItemId;
                if (
                    !quickFilters ||
                    (activeItemId && draft.tasks[activeItemId]) ||
                    (activeItemId && draft.forms[activeItemId])
                )
                    return;
                return {
                    ...draft,
                    ...quickFilters,
                };
            case CHANGE_QUICK_FILTER:
                if (action.filter.category === 'tasks') {
                    if (draft.tasks[action.itemId] == null) {
                        draft.tasks[action.itemId] = { ...action.initialTaskQuickFilters };
                    }
                    draft.tasks[action.itemId]![action.filter.name] =
                        !draft.tasks[action.itemId]![action.filter.name];
                } else if (action.filter.category === 'forms') {
                    if (draft.forms[action.itemId] == null) {
                        draft.forms[action.itemId] = { ...initialResponseQuickFilters };
                    }
                    draft.forms[action.itemId]![action.filter.name] =
                        !draft.forms[action.itemId]![action.filter.name];
                }
                return draft;
            case APPLY_FILTERS: {
                draft.tasks[action.taskDefinitionId] = { ...action.filtersSet.predefined };
                return draft;
            }
            case ONLY_RECENT_QUICK_FILTER: {
                if (action.category === 'tasks') {
                    draft.tasks[action.itemId] = {
                        ...emptyTaskQuickFilters,
                        recentTasks: true,
                    };
                } else if (action.category === 'forms') {
                    draft.forms[action.itemId] = { ...onlyRecentResponseQuickFilters };
                }
                return draft;
            }
            default:
                return draft;
        }
    });

export const contentQuickFiltersCreator: ContentQuickFiltersCreator = {
    changeQuickFilter:
        (filter: QuickFilter) =>
        async (dispatch, getState, { contentCreator, contentQuickFiltersSelector }) => {
            const { content } = getState();
            if (content == null || !content.itemId) {
                return;
            }

            const { getTaskQuickFilters, getInitialTaskQuickFilters } = contentQuickFiltersSelector;
            await dispatch({
                filter,
                itemId: content.itemId,
                type: CHANGE_QUICK_FILTER,
                initialTaskQuickFilters: getInitialTaskQuickFilters(getState()),
            });
            const filters = getTaskQuickFilters(getState());
            dispatch({
                filters,
                itemId: content.itemId,
                category: filter.category,
                type: COPY_QUICK_FILTERS_TO_APPLIED,
            });
            return dispatch(contentCreator.refresh(true));
        },

    selectOnlyRecentQuickFilter:
        (category: Category) =>
        async (dispatch, getState, { contentCreator }) => {
            const { content } = getState();
            if (content == null || !content.itemId) {
                return;
            }

            await dispatch({
                itemId: content.itemId,
                type: ONLY_RECENT_QUICK_FILTER,
                category,
            });
            return dispatch(contentCreator.refresh(true));
        },
};
