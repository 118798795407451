import React, { FunctionComponent, useMemo } from 'react';
import { ThemeProvider as MuiThemeProvider, changePrimaryColor, primaryTheme } from 'worldapp-ui';
import { useSelector } from 'react-redux';
import { brandColorSelector } from './redux/brandInfo/brandInfoSelector';
import { getLastBrandColor } from './utils/storage.utils';

export const DEFAULT_COLOR = primaryTheme.palette.primary.main;

const ThemeProvider: FunctionComponent = props => {
    const brandColor = useSelector(brandColorSelector) || getLastBrandColor();
    const theme = useMemo(
        () =>
            changePrimaryColor(primaryTheme, {
                main: brandColor || DEFAULT_COLOR,
            }),
        [brandColor],
    );

    return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
};

export default ThemeProvider;
