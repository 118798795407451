import { createSelector } from 'reselect';
import { AppState } from '../AppState.types';
import { Language, Languages } from '../../localization/localizationDefaultData';
import { Tabs } from '../menu/menu.types';
import { NavigationTreeItems } from '../../constants/navigation.constants';
import { ApiGW } from '../../types/DTO/api-gw';
import { getTaskDefinitions } from '../taskDefinitions/taskDefinitionsSelector';
import { CONTENT_CATEGORY_TASKS } from '../content/category.types';
import { getOptimizeTasksLoadFeature } from '../features/featuresSelector';

const TASKS_TAB_DEFAULT_NAME = 'Tasks';
const DASHBOARDS_TAB_DEFAULT_NAME = 'Dashboards';

export const getSupportedLanguages = ({ portalSettings }: AppState): string[] =>
    portalSettings.languages.supportedLanguages;

export const getDefaultLanguage = (state: AppState): Language =>
    state.portalSettings.languages.default as Language;

export const getTabs = (state: AppState): Tabs => {
    const tabs = { ...state.portalSettings.tabs };
    if (getOptimizeTasksLoadFeature(state) && !getTaskDefinitions(state)?.length)
        delete tabs[CONTENT_CATEGORY_TASKS];
    return tabs;
};

export const getCustomLinks = ({ portalSettings }: AppState): ApiGW.CustomLinkDTO[] =>
    portalSettings.customLinks;

export const getTasksTabName = ({ portalSettings }: AppState): string =>
    portalSettings.tabs.tasks ? portalSettings.tabs.tasks.name : TASKS_TAB_DEFAULT_NAME;

export const getDashboardsTabName = ({ portalSettings }: AppState): string =>
    portalSettings.tabs.dashboards
        ? portalSettings.tabs.dashboards.name
        : DASHBOARDS_TAB_DEFAULT_NAME;

export const getTabKeys = createSelector(getTabs, tabs => Object.keys(tabs));

export const getNavigationItemsData = createSelector(getTabKeys, tabKeys => {
    return Object.values(NavigationTreeItems).filter(navigationItem =>
        tabKeys.includes(navigationItem.category),
    );
});

export const getAvailableLanguages = createSelector(getSupportedLanguages, supportedLanguages => {
    if (supportedLanguages.length > 0) {
        return Languages.filter(lang => supportedLanguages.includes(lang.value));
    }
    return Languages;
});

export const getCustomLinksCount = createSelector(
    getCustomLinks,
    customLinks => customLinks.length,
);

export const getImpersonateMode = ({ portalSettings }: AppState): boolean =>
    portalSettings.impersonateMode;

export const getHideCompleted = ({ portalSettings }: AppState): boolean =>
    portalSettings.hideCompleted;
