import { Location } from 'history';
import cloneDeep from 'clone-deep';
import {
    createRoutingMiddleware,
    PersistentOptions,
    PreparedURL,
    stateToURL,
    URLToState,
} from 'worldapp-fe-utils';
import { appendParamsToRelativeURL } from '../utils/window.location.utils';
import { defaultLocationState, LocationState } from './location.types';
import { locationSelector, stateHydrator } from './locationState';
import { GO_TO_VOTING } from '../redux/voting/voting.types';
import { readAndClearLocationState, writeLocationState } from '../utils/sessionStorage.util';
import { AppAction } from '../redux';
import { portalVersionConverter } from './converters/portalVersionConverter';

const initialConverter = [portalVersionConverter];

export const URLToLocation = (
    location: Location,
    initialLocationState: LocationState = defaultLocationState,
): LocationState => {
    const query: Record<string, string[]> = {};
    new URLSearchParams(location.search).forEach((value, key) => {
        if (!query[key]) query[key] = [];
        query[key].push(value);
    });
    const url: PreparedURL = {
        query,
        path: location.pathname,
        hash: location.hash.split('#').slice(-1).pop(),
    };
    return { ...cloneDeep(initialLocationState), ...URLToState(initialConverter)(url) };
};

export const locationToURL = (state: LocationState): Location => {
    const { query, path, hash = '' } = stateToURL(initialConverter)(state);
    return {
        hash,
        pathname: `/online${path}/`,
        search: appendParamsToRelativeURL('', query).split('/')[1],
        state: null,
        key: '',
    };
};

export const routingMiddleware = createRoutingMiddleware({
    locationSelector,
    stateHydrator,
    locationToURL,
    URLToLocation,
    persistentOptions: {
        readAndClearLocationState,
        writeLocationState,
        isPersistStateAction: a => a.type === GO_TO_VOTING,
    } as PersistentOptions<AppAction, LocationState>,
});
