import { Theme, makeStyles } from 'worldapp-ui/shared';

export const impersonateHeaderStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        position: 'relative',
        zIndex: theme.zIndex.drawer + 1,
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.neutral.main,
        height: theme.spacing(3),
        color: theme.palette.white.main,
    },
    contactId: {
        marginLeft: theme.spacing(0.5),
    },
}));
