import { AppState } from '../AppState.types';

export const getIsBackUrl = ({ voting }: AppState): boolean => voting.isBackUrl;

export const getSubmit = ({ voting }: AppState): boolean => voting.submit;

export const getVotingCreatedItemId = ({ voting }: AppState): number | null => voting.createdItemId;

export const getVotingStartForm = ({ voting }: AppState): boolean => voting.startForm;

export const getResultHasBeenSaved = ({ voting }: AppState): boolean => voting.resultHasBeenSaved;
