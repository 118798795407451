import React, { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { tasksActionsCreator } from '../../../redux/tasksActions/tasksActions';
import { isContentCategoryTasks } from '../../../redux/content/contentSelector';
import { getTasksActionsState } from '../../../redux/tasksActions/taskActionsSelector';
import { PopoverDialog } from '../../Common/PopoverDialog';
import { ChangeStatusContent } from './ChangeStatusContent';

interface ChangeStatusProps {
    open: boolean;
    anchorElement: HTMLElement;
    closePopover: () => void;
}

export const ChangeStatusPopover: FC<ChangeStatusProps> = ({
    anchorElement,
    open,
    closePopover,
}) => {
    const dispatch = useDispatch();
    const tasksActions = useSelector(getTasksActionsState, shallowEqual);
    const isTasks = useSelector(isContentCategoryTasks);

    const onClose = () => {
        dispatch(tasksActionsCreator.closeActionPopup());
        closePopover();
    };

    const onConfirm = () => {
        dispatch(tasksActionsCreator.setStatus(true));
        closePopover();
    };

    if (isTasks && tasksActions.type === 'ChangeStatus') {
        return (
            <PopoverDialog
                anchorEl={anchorElement}
                open={open}
                onClose={onClose}
                onCancel={onClose}
                onConfirm={onConfirm}
            >
                <div>
                    <ChangeStatusContent />
                </div>
            </PopoverDialog>
        );
    }

    return null;
};
