import { createStyles, makeStyles, Theme } from 'worldapp-ui/shared';

const useCustomLinkStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflowY: 'auto',
        },
        container: {
            alignItems: 'center',
            flexWrap: 'nowrap',
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        },
        link: {
            marginLeft: theme.spacing(2),
            width: theme.spacing(33),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'block',
            fontSize: theme.newTypography.r16r.fontSize,
        },
        icon: {
            color: theme.palette.neutral[75],
        },
    }),
);

export default useCustomLinkStyles;
