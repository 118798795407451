import { fade, makeStyles } from 'worldapp-ui/shared';

export const bottomPanelStyles = makeStyles(theme => ({
    icon: {
        color: 'inherit',
        flexShrink: 0,
    },
    text: {
        color: 'inherit',
        fontSize: theme.typography.subtitle1.fontSize,
        marginLeft: theme.spacing(2),
        ...theme.mixins.truncate,
    },
    bottomMenuContainer: {
        paddingRight: 16,
    },
    bottomMenuItem: {
        color: theme.palette.neutral[75],
        minHeight: 48,
        marginRight: 0,
        borderBottomRightRadius: 4,
        borderTopRightRadius: 4,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
        },
    },
    '& $bottomMenuItem:active': {
        bottomMenuItemLink: {
            color: theme.palette.primary.main,
            backgroundColor: fade(theme.palette.primary.main, 0.32),
        },
    },
    bottomMenuItemLink: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        color: theme.palette.neutral[75],
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));
