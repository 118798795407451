import { ApiGW } from '../../types/DTO/api-gw';
import { Language } from '../../localization/localizationDefaultData';

export const USER_FETCHED = 'User/USER_FETCHED';
export const CHANGE_USER_LANGUAGE = 'User/CHANGE_USER_LANGUAGE';

interface UserFetchedAction {
    type: typeof USER_FETCHED;
    user: ApiGW.UserDTO;
}

interface ChangeUserLanguageAction {
    type: typeof CHANGE_USER_LANGUAGE;
    lang: Language;
}

export type UserAction = UserFetchedAction | ChangeUserLanguageAction;

export interface UserState {
    contactId: number;
    email: string;
    id: number;
    lang: string;
    login: string;
    exists: boolean;
}
