import React from 'react';
import { Grid, Theme, createStyles, makeStyles } from 'worldapp-ui/shared';
import { isMobile } from '../../../utils/browser.utils';
import MainHeader from '../MainHeader';
import { ResponseQuickFilter } from '../ResponseQuickFilters';
import { GRID_MOBILE_PADDING, GRID_PADDING } from '../../ui/cards/cardsSizes';

const useFormResponsesHeaderPanelStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainLine: {
            padding: `0 ${GRID_PADDING}px 16px ${GRID_PADDING}px`,
            '&[data-ismobile="true"]': {
                padding: `0 16px 8px ${GRID_MOBILE_PADDING}px`,
                height: 40,
                boxSizing: 'border-box',
            },
        },
        filterLine: {
            minHeight: theme.spacing(5),
            padding: `8px ${GRID_PADDING}px 16px ${GRID_PADDING}px`,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            '&[data-ismobile="true"]': {
                padding: `0 0 12px ${GRID_MOBILE_PADDING}px`,
            },
        },
        filtersContainer: {
            display: 'flex',
            flex: '1 1 auto',
            overflowY: 'hidden',
            overflowX: 'auto',
            boxSizing: 'border-box',
            scrollbarWidth: 'none',
            '& ::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
        },
        contentDivider: {
            margin: `0 ${GRID_PADDING}px`,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            '&[data-ismobile="true"]': {
                margin: `0 ${GRID_MOBILE_PADDING}px`,
            },
        },
    }),
);

const FormResponsesHeaderPanel = (): JSX.Element => {
    const classes = useFormResponsesHeaderPanelStyles();

    return (
        <>
            <Grid
                container={true}
                alignItems="center"
                className={classes.mainLine}
                data-ismobile={isMobile}
            >
                <MainHeader />
            </Grid>
            <div
                className={classes.filterLine}
                data-testid="main-header-filter-line-container"
                data-ismobile={isMobile}
            >
                <div className={classes.filtersContainer}>
                    <ResponseQuickFilter />
                </div>
            </div>
            <div className={classes.contentDivider} data-ismobile={isMobile} />
        </>
    );
};

export default FormResponsesHeaderPanel;
