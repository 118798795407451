import { Converter, LocationState } from '../location.types';
import {
    defaultSortColumn,
    defaultSortOrder,
    SortColumnType,
    SortOrder,
} from '../../redux/tasksSort/tasksSort.types';

export const taskSortConverter: Converter = {
    stateToURL: state => {
        if (state.activeCategory === 'tasks') {
            const { taskSortingParameter } = state;
            return {
                query: {
                    sparam:
                        taskSortingParameter.column === SortColumnType.Custom
                            ? taskSortingParameter.property
                            : taskSortingParameter.column,
                    sdir: state.taskSortingDirection,
                },
            };
        }
    },
    URLToState: url => {
        const sdir = url.query.sdir?.[0] as SortOrder;
        const taskSortingDirection = Object.values(SortOrder).includes(sdir)
            ? sdir
            : defaultSortOrder;
        const sparam = url.query.sparam?.[0] as SortColumnType;
        if (!sparam) {
            return { taskSortingParameter: { column: defaultSortColumn }, taskSortingDirection };
        }
        if (Object.values(SortColumnType).includes(sparam)) {
            return {
                taskSortingDirection,
                taskSortingParameter: { column: sparam },
            } as Partial<LocationState>;
        }
        return {
            taskSortingDirection,
            taskSortingParameter: {
                column: SortColumnType.Custom,
                property: sparam as string,
            },
        };
    },
};
