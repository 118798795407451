/* eslint-disable import/no-cycle */
import { ThunkActionPromise, ThunkActionVoid } from '../index';
import { getDefaultSelectedItemForCategory } from '../menu/menu';
import i18n from '../../localization/i18n';

export const commonCreator = {
    updateNavigationPanel:
        (): ThunkActionPromise =>
        async (
            dispatch,
            getState,
            { contentSelector, formsCreator, taskDefinitionsCreator, menuCreator },
        ) => {
            await Promise.all([
                dispatch(formsCreator.fetchForms()),
                dispatch(taskDefinitionsCreator.fetchTaskDefinitions()),
            ]);
            const updState = getState();
            const category = contentSelector.getContentCategory(updState);
            if (category && !contentSelector.isItemIdValid(updState)) {
                dispatch(
                    menuCreator.initializeActiveCategoryAndItem(
                        category,
                        getDefaultSelectedItemForCategory(category, updState),
                    ),
                );
            }
        },

    handle404:
        (): ThunkActionVoid =>
        (dispatch, _, { commonCreator: creator, toastsCreator, contentCreator }) => {
            dispatch(toastsCreator.createToast(i18n.t('Errors.ObjectNotFound'), 'error'));
            dispatch(creator.updateNavigationPanel());
            return dispatch(contentCreator.refresh(false));
        },
};
