import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Checkbox,
    CircularContouredLoader,
    FormControlLabel,
    RadioGroup,
    ReactInfiniteScroll,
    lighten,
    makeStyles,
} from 'worldapp-ui';
import SelectionGroupHeader from 'worldapp-ui/shared/selectionControls/shared/SelectionGroupHeader';
import { TransformedSelectionGroupItem } from 'worldapp-ui/shared/selectionControls/TransformedSelectionGroup';
import {
    getAppliedAssigneeFilter,
    getAssigneeFilterMeta,
} from '../../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { EMPTY_ITEM_LABEL } from '../../ConfigurableFilters/ConfigurableFilter';
import { tasksConfigurableFiltersCreator } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { AppState } from '../../../redux/AppState.types';
import {
    ASSIGNEE_FILTER_ID,
    StringFilter,
    StringFilterMeta,
} from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters.types';
import { hasManagementRights } from '../../../redux/contentQuickFilters/contentQuickFiltersSelector';

const useTaskCalendarFiltersStyles = makeStyles(theme => ({
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    filtersHeader: {
        height: 24,
        padding: '0 8px',

        '& h5': {
            ...theme.newTypography.r14m,
        },
    },
    filtersList: {
        height: 'calc(100% - 24px)',
        overflow: 'auto',
        padding: '16px 8px 8px 10px',
    },
    loader: {
        marginTop: theme.spacing(),
    },
    wrapper: {
        margin: -theme.spacing(),
        overflowX: 'hidden',
    },
    formControlLabelRoot: {
        borderRadius: theme.spacing(0.5),
        margin: 0,
        color: theme.palette.neutral[75],
        width: '100%',
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.9),
            color: theme.palette.neutral[75],
        },
    },
    formControlLabel: {
        marginRight: theme.spacing(),
        ...theme.mixins.truncate,
    },

    formControlLabelSelected: {
        marginRight: theme.spacing(),
        color: theme.palette.primary.main,
        ...theme.mixins.truncate,
    },
}));

export interface TaskCalendarFiltersProps {
    isManager: boolean;
    filter: StringFilter | null;
    filterMeta: StringFilterMeta | null;
    toggleAppliedStringFilterValue: (filterId: number | string, itemName: string) => void;
    loadMore: () => void;
}

const mapStateToProps = (state: AppState) => {
    return {
        isManager: hasManagementRights(state),
        filter: getAppliedAssigneeFilter(state),
        filterMeta: getAssigneeFilterMeta(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    toggleAppliedStringFilterValue: tasksConfigurableFiltersCreator.toggleAppliedStringFilterValue,
    loadMore: tasksConfigurableFiltersCreator.getAssigneeFilterMeta,
});

export const TaskCalendarFiltersComponent: FC<TaskCalendarFiltersProps> = ({
    isManager,
    filter,
    filterMeta,
    toggleAppliedStringFilterValue,
    loadMore,
}) => {
    const { t } = useTranslation();
    const classes = useTaskCalendarFiltersStyles();

    if (!isManager) return null;

    const onChange = (item: TransformedSelectionGroupItem) =>
        toggleAppliedStringFilterValue(ASSIGNEE_FILTER_ID, item.value as string);
    if (filterMeta === null) return null;

    const items = filterMeta.items.map((item: string) => {
        const isChecked = !!filter && filter.items.includes(item);
        return {
            label: item === '' ? t(EMPTY_ITEM_LABEL) : item,
            value: item,
            checked: isChecked,
        };
    });
    const isLoading = filterMeta.hasMoreItems && items.length === 0;

    if (isLoading) {
        return (
            <div data-testid="task-calendar-filter_loader" className={classes.loader}>
                <CircularContouredLoader />
            </div>
        );
    }

    // TODO: Move InfiniteScroll to SelectionGroup
    return (
        <div data-testid="task-calendar-filters" className={classes.filtersContainer}>
            <div className={classes.filtersHeader}>
                <SelectionGroupHeader
                    label={t('TaskFilters.Labels.Assignee')}
                    isLoading={isLoading}
                />
            </div>
            <div className={classes.filtersList} id="task-calendar-assignee_selection-group">
                <RadioGroup classes={{ root: classes.wrapper }}>
                    <ReactInfiniteScroll
                        next={loadMore}
                        hasMore={items.length > 0 && filterMeta.hasMoreItems}
                        loader={
                            <div className={classes.loader}>
                                <CircularContouredLoader />
                            </div>
                        }
                        dataLength={items.length}
                        scrollableTarget="task_calendar_assignee-selection_group"
                    >
                        {items.map(button => {
                            return (
                                <div key={button.value}>
                                    <FormControlLabel
                                        label={button.label}
                                        title={button.label}
                                        control={
                                            <Checkbox color={'primary'} aria-label={button.label} />
                                        }
                                        data-testid={`radio-group_${button.value}`}
                                        checked={button.checked}
                                        onChange={() => onChange(button)}
                                        classes={{
                                            root: classes.formControlLabelRoot,
                                            label: button.checked
                                                ? classes.formControlLabelSelected
                                                : classes.formControlLabel,
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </ReactInfiniteScroll>
                </RadioGroup>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCalendarFiltersComponent);
