import { useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useContext, useMemo } from 'react';
import { mapProperties, id, memoizeLast } from 'worldapp-fe-utils';
import { ActionCreators } from '../redux';

import { ActionCreatorsContext } from '../redux/actionCreatorsContext';

export type ActionCallbacks = {
    [K in keyof ActionCreators]: {
        [T in keyof ActionCreators[K]]: ActionCreators[K][T] extends (...args: any) => any
            ? (...params: Parameters<ActionCreators[K][T]>) => void
            : never;
    };
};

const bind = (actionCreators: ActionCreators, dispatch: Dispatch<any>) => {
    return mapProperties<keyof ActionCreators, any, any>(actionCreators, creator => {
        return bindActionCreators(creator, dispatch);
    });
};

const memoizedBind = memoizeLast(bind);

type Selector<T> = (creators: ActionCallbacks) => T;

export function useActionCreators(): ActionCallbacks;
export function useActionCreators<T>(selector: Selector<T>): T;

export function useActionCreators<T>(selector?: Selector<T>): T {
    const dispatch = useDispatch();
    const actionCreators = useContext(ActionCreatorsContext);
    const boundCallbacks = memoizedBind(actionCreators, dispatch);
    const validSelector = selector || (id as any);
    return useMemo(() => validSelector(boundCallbacks), [validSelector, boundCallbacks]);
}
