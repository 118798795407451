import { Dictionary } from 'worldapp-fe-utils';
import { SortBy, SortOrderOptions } from '../tasksSort/tasksSort.types';
import { MainTaskAction, TaskActionTable } from '../content/validateTaskActions';

export const CHANGE_COLUMNS_ORDER = 'TasksTable/CHANGE_COLUMNS_ORDER';

export interface ChangeColumnsOrderAction {
    type: typeof CHANGE_COLUMNS_ORDER;
    taskDefinitionId: number;
    order: string[];
}

export type TasksTableAction = ChangeColumnsOrderAction;

export interface SingleTableState {
    columns: string[];
}

export interface TasksTableState {
    [taskDefinitionId: number]: SingleTableState;
}

export interface TableWidth {
    date: number;
    assignee: number;
    status: number;
    summary: number;
    taskType: number;
    customNumber: number;
    customDate: number;
    customGeneral: number;
}
export interface TableColumn {
    accessor: string;
    Header: string;
    sortColumn: SortBy | undefined;
    relativeWidth: number;
    width?: number;
}

export interface TableRow {
    id: number;
    summary: string | undefined;
    type?: string;
    status: string | undefined;
    assignee: string | undefined;
    dueDate: string | undefined;
    assignDate: string | undefined;
    [key: string]: string | number | undefined;
}

export interface RowDataItem {
    actions: TaskActionTable[];
    mainAction: MainTaskAction | null;
    taskId: number;
    taskDefinitionId: number;
    isClosed: boolean;
    isActive?: boolean;
    isOverdue: boolean;
    shouldHiglhight: boolean;
    inProgress: boolean;
}
export interface RowData {
    [key: number]: RowDataItem;
}
export interface TableData {
    columns: TableColumn[];
    columnsById: Dictionary<TableColumn>;
    data: TableRow[];
    rowData: RowData;
    rowWidth: number;
    sortDirection?: SortOrderOptions;
}
