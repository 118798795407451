import classNames from 'classnames';
import React, { useRef, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    FormControlLabel,
    Grid,
    SearchBox,
    Radio,
    RadioGroup,
    Text,
    ToggleButtonGroup,
} from 'worldapp-ui';
import { Form, Tasks } from 'worldapp-ui/shared/icons';
import { useActionCreators } from '../../../hooks/useActionCreators';
import { getStartFormLists } from '../../../redux/frequentlyUsed/frequentlyUsedSelector';
import { generateStartFormId } from '../../../redux/startForm/startForm.utils';
import {
    getStartFormSearchValue,
    getStartFormSelectedItem,
} from '../../../redux/startForm/startFormSelector';
import { isTaskDefinition } from '../../../redux/taskDefinitions/taskDefinitions.utils';
import FrequentlyUsedCard from '../FrequentlyUsedCard/FrequentlyUsedCard';
import { StartFormNothingFound } from './StartFormNothingFound';
import { startFormPopupStyles } from './StartFormPopup.styles';
import { deviceType } from '../../../utils/browser.utils';

const StartFormPopup: React.FC = () => {
    const { t } = useTranslation();

    const { frequentlyUsedItems, items } = useSelector(getStartFormLists);
    const searchValue = useSelector(getStartFormSearchValue);
    const value = useSelector(getStartFormSelectedItem);
    const classes = startFormPopupStyles();

    const itemRef = useRef<HTMLDivElement>(null);

    const { startFormCreator } = useActionCreators();

    const handleValueChange = (_: any, val: string) => {
        startFormCreator.changeSelectedItem(val);
    };

    const handleRadioClick = (event: MouseEvent<HTMLButtonElement>) => {
        const eventValue = (event.target as HTMLButtonElement).value;
        const newValue = eventValue === value ? '' : eventValue;
        startFormCreator.changeSelectedItem(newValue);
    };

    const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        startFormCreator.changeSearchValue(e.target.value);
    };

    const handleClearSearch = () => {
        startFormCreator.changeSearchValue('');
    };

    useEffect(() => {
        if (itemRef.current) {
            itemRef.current.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        if (items.length === 1 && !searchValue) {
            const type = isTaskDefinition(items[0]) ? 'td' : 'form';
            const id = generateStartFormId(type, items[0].id);
            startFormCreator.changeSelectedItem(id);
        }
    }, [items, searchValue, startFormCreator]);
    const isMobileDevice = deviceType === 'mobile';
    return (
        <>
            {frequentlyUsedItems.length > 0 && (
                <>
                    <Text
                        className={classes.heading}
                        variant="r14r"
                        data-testid="start-form_frequently-used_text"
                    >
                        {t('StartForm.FrequentlyUsed')}
                    </Text>
                    <ToggleButtonGroup
                        className={classes.buttonGroup}
                        value={value}
                        onChange={handleValueChange}
                        exclusive
                        data-testid="start-form_frequently-used"
                        data-ismobile={isMobileDevice}
                    >
                        {frequentlyUsedItems.map(item => {
                            const type = isTaskDefinition(item) ? 'td' : 'form';
                            const id = generateStartFormId(type, item.id);
                            return (
                                <FrequentlyUsedCard
                                    key={id}
                                    type={type}
                                    title={item.title}
                                    value={id}
                                />
                            );
                        })}
                    </ToggleButtonGroup>
                </>
            )}
            {items.length === 0 && !searchValue ? (
                <Text
                    className={classes.heading}
                    variant="r14r"
                    data-testid="start-form_no-forms_text"
                >
                    {t('StartForm.NoAvailableForms')}
                </Text>
            ) : (
                <>
                    <Text
                        className={classes.heading}
                        variant="r14r"
                        data-testid="start-form_choose-form_text"
                    >
                        {t('StartForm.ChooseForm')}
                    </Text>
                    <SearchBox
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        placeholder={t('TaskActions.ChangeAssignee.Search')}
                        size="xl"
                        fullWidth
                        showClearSearch={!!searchValue}
                        onClearSearch={handleClearSearch}
                        className={classes.search}
                        data-testid="start-form_search-box"
                        clearSearchLabel={t('NavigationPanel.ClearSearch')}
                    />
                    {items.length === 0 ? (
                        <StartFormNothingFound />
                    ) : (
                        <Grid container={true} justify={'space-between'} spacing={0}>
                            <RadioGroup
                                value={value}
                                onChange={handleValueChange}
                                className={classes.formGroup}
                                data-testid="start-form_items"
                            >
                                {items.map(item => {
                                    const type = isTaskDefinition(item) ? 'td' : 'form';
                                    const id = generateStartFormId(type, item.id);
                                    const isSelected = value === id;
                                    return (
                                        <Grid item={true} key={id} className={classes.item}>
                                            <FormControlLabel
                                                id={`start-form_item_${id}`}
                                                control={
                                                    <Radio
                                                        color={'primary'}
                                                        onClick={handleRadioClick}
                                                    />
                                                }
                                                label={
                                                    <div
                                                        className={classNames(
                                                            classes.label,
                                                            isSelected && classes.labelSelected,
                                                        )}
                                                        ref={isSelected ? itemRef : null}
                                                    >
                                                        {type === 'form' ? <Form /> : <Tasks />}
                                                        <Text
                                                            variant="r12r"
                                                            className={classes.labelText}
                                                            noWrap
                                                            title={item.title}
                                                        >
                                                            {item.title}
                                                        </Text>
                                                    </div>
                                                }
                                                data-testid={`start-form_item_${id}`}
                                                value={id}
                                                classes={{
                                                    root: classes.formControlLabelRoot,
                                                    label: classes.formControlLabel,
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}
                            </RadioGroup>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

export default StartFormPopup;
