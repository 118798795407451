import produce from 'immer';
import { CHANGE_COLUMNS_ORDER, TasksTableAction, TasksTableState } from './tasksTable.types';
import {
    HYDRATE_STATE_FROM_STORAGE,
    HydrateStateFromStorageAction,
} from '../initialLoader/initialLoader.types';
import { TasksTableCreator } from './tasksTableCreator.types';

export const initialState: TasksTableState = {};

export const reducer = (
    state = initialState,
    action: TasksTableAction | HydrateStateFromStorageAction,
): TasksTableState =>
    produce(state, (draft: TasksTableState) => {
        switch (action.type) {
            case HYDRATE_STATE_FROM_STORAGE:
                const { tasksTable } = action.state;
                if (!tasksTable) return draft;
                return tasksTable as TasksTableState;
            case CHANGE_COLUMNS_ORDER:
                draft[action.taskDefinitionId] = {
                    ...draft[action.taskDefinitionId],
                    columns: action.order,
                };
                return draft;
            default:
                return draft;
        }
    });

export const tasksTableCreator: TasksTableCreator = {
    changeColumnsOrder:
        (order: string[]) =>
        (dispatch, getState, { contentSelector }) => {
            const state = getState();
            const taskDefinitionId =
                contentSelector.isContentCategoryTasks(state) &&
                contentSelector.getContentItemId(state);
            if (taskDefinitionId) {
                return dispatch({
                    taskDefinitionId,
                    order,
                    type: CHANGE_COLUMNS_ORDER,
                });
            }
        },
};
