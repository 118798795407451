import { ignoreDebounceResponse } from 'worldapp-fe-utils';
import produce from 'immer';
import {
    Content,
    CONTENT_CLEAR,
    CONTENT_LOADED,
    CONTENT_LOADING,
    CONTENT_META_CHANGE,
    CONTENT_REQUEST_LIMIT,
    CONTENT_META_INITIALIZE,
    ContentAction,
    ContentState,
    CONTEXT_SEARCH_QUERY_CHANGE,
    FetchContentParams,
    ResponsesOrTasksOrDashboards,
    Task,
    Dashboard,
    Response,
    TOGGLE_IS_LOADING_HEADER,
    SET_CONTENT_INITIAL_STATE,
} from './content.types';
import { getFetchTasksParams } from './fetchTasksSelector';
import {
    Category,
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from './category.types';
import { AppState } from '../AppState.types';
import { ContentCreator } from './contentCreator.types';
import { getFreshContent } from './content.utils';
import { getResponseQuickFilter } from '../contentQuickFilters/contentQuickFiltersSelector';
import i18n from '../../localization/i18n';
import { GO_TO_VOTING } from '../voting/voting.types';
import { TasksSelectedAction } from '../tasksSelectedActions/tasksSelectedActions.types';
import { onContentLoadStart } from '../../analytics';
import { getIsCalendarView } from '../menu/menuSelector';
import { getContextSearchQuery } from './contentSelector';

const OBJECT_NOT_FOUND = 'Errors.ObjectNotFound';
const FORM_NOT_AVAILABLE = 'Errors.FormIsNotAvailable';
const WRONG_ITEM = 'WRONG_ITEM';

class WrongItemError {
    public name = WRONG_ITEM;
}

export const getUpdatedItems = <T extends Array<any>>(
    stateItems: T,
    newItems: T,
    shouldOverwrite: boolean,
): T | T[number][] => {
    return shouldOverwrite ? newItems : [...stateItems, ...newItems];
};

export const getUpdatedContent = (
    draft: Content,
    payload: ResponsesOrTasksOrDashboards,
): Content => {
    const shouldOverwrite = draft.category !== payload.category || draft.itemId !== payload.itemId;
    draft.isLoading = false;
    draft.offset += CONTENT_REQUEST_LIMIT;
    draft.category = payload.category;
    draft.itemId = payload.itemId;
    draft.total = payload.to.total;

    switch (payload.category) {
        case CONTENT_CATEGORY_TASKS:
            draft.items = getUpdatedItems(draft.items, payload.to.tasks, shouldOverwrite) as Task[];

            // TODO: use payload.to.total when it will be properly implemented on backend
            draft.hasMore = payload.to.tasks.length === CONTENT_REQUEST_LIMIT;
            draft.total = 0;
            break;

        case CONTENT_CATEGORY_FORMS:
            draft.items = getUpdatedItems(
                draft.items,
                payload.to.items,
                shouldOverwrite,
            ) as Response[];
            draft.hasMore = payload.to.total > draft.items.length;
            break;

        case CONTENT_CATEGORY_DASHBOARDS:
            const dashboardItems = payload.to.items ?? [];
            draft.items = getUpdatedItems(
                draft.items,
                dashboardItems,
                shouldOverwrite,
            ) as Dashboard[];
            // TODO: use payload.to.total when it will be properly implemented on dashboards
            draft.hasMore = !!payload.to.items && payload.to.items.length === CONTENT_REQUEST_LIMIT;
            draft.total = 0;
            break;
    }

    return draft;
};

export const initialState = null;

export const reducer = (
    state: ContentState = initialState,
    action: ContentAction | TasksSelectedAction,
): ContentState =>
    produce(state, (draft: ContentState) => {
        switch (action.type) {
            case CONTENT_CLEAR:
                if (draft === null) return null;
                draft.offset = 0;
                draft.hasMore = true;
                draft.items = [];
                draft.total = 0;
                if (action.clearSearchQuery) {
                    draft.searchQuery = '';
                }
                return;
            case CONTENT_META_CHANGE:
                return {
                    ...getFreshContent(action.category, action.itemId),
                    searchQuery: draft?.searchQuery ?? '',
                };
            case CONTENT_META_INITIALIZE:
                return {
                    ...getFreshContent(action.category, action.itemId),
                    offset: state?.offset ?? 0,
                    searchQuery: draft?.searchQuery ?? '',
                };
            case CONTENT_LOADING:
                if (draft === null) return null;
                draft.isLoading = true;
                const { itemId, category } = action;
                draft.loadingItem = { itemId, category };
                return;
            case CONTENT_LOADED:
                if (draft === null) return null;
                delete draft.loadingItem;
                return getUpdatedContent(draft, action.result);
            case CONTEXT_SEARCH_QUERY_CHANGE:
                if (draft === null) return null;
                draft.searchQuery = action.value;
                return;
            case TOGGLE_IS_LOADING_HEADER:
                if (draft === null) return null;
                if (draft.category === 'tasks') {
                    draft.isLoadingHeader = !draft.isLoadingHeader;
                }
                return;
            case GO_TO_VOTING:
                if (!action.itemId) return state;
                return {
                    ...getFreshContent(action.category, action.itemId),
                    searchQuery: draft?.searchQuery ?? '',
                };
            case SET_CONTENT_INITIAL_STATE:
                return initialState;
            default:
                return state;
        }
    });

export const stateToFetchContentParams = (
    category: Category,
    itemId: number,
    state: AppState,
    limit: number,
    offset: number,
): FetchContentParams => {
    if (category === CONTENT_CATEGORY_FORMS) {
        return {
            category,
            offset,
            filter: {
                formId: itemId,
                ...getResponseQuickFilter(state, itemId),
            },
            limit,
        };
    }
    if (category === CONTENT_CATEGORY_DASHBOARDS) {
        return {
            category,
            offset,
            limit,
            searchQuery: getContextSearchQuery(state),
        };
    }
    return getFetchTasksParams(itemId, limit, offset, state);
};

export const contentCreator: ContentCreator = {
    refresh:
        (clearSearchQuery: boolean) =>
        (dispatch, getState, { contentCreator: creator }) => {
            const { content } = getState();
            if (content == null) {
                return;
            }
            dispatch({ type: CONTENT_CLEAR, clearSearchQuery });
            return dispatch(creator.loadContent(content.category, content.itemId));
        },

    changeContentMeta:
        (category: Category | null, itemId: number | null) =>
        async (
            dispatch,
            getState,
            {
                contentCreator: creator,
                taskDefinitionsCreator,
                votingCreator,
                tasksConfigurableFiltersCreator,
                calendarSelector,
                contentSelector,
            },
        ) => {
            if (!category) {
                return;
            }
            dispatch({
                category,
                itemId,
                type: CONTENT_META_CHANGE,
            });
            if (category === 'forms' && !contentSelector.getFormsContentInitialized(getState()))
                return;

            if (calendarSelector.getIsCalendarDayView(getState()))
                dispatch(tasksConfigurableFiltersCreator.getAssigneeFilterMeta(true));

            if (category === 'tasks' && itemId) {
                dispatch({ type: TOGGLE_IS_LOADING_HEADER });
                await dispatch(taskDefinitionsCreator.getCreateTaskPermitOrPermits(itemId));
                dispatch({ type: TOGGLE_IS_LOADING_HEADER });
            }

            dispatch(votingCreator.clearVotingState());

            return dispatch(creator.loadContent(category, itemId));
        },

    initializeContentMeta:
        (category: Category | null, itemId: number | null) =>
        async (
            dispatch,
            getState,
            {
                tasksConfigurableFiltersCreator,
                contentCreator: creator,
                taskDefinitionsCreator,
                calendarSelector,
            },
        ) => {
            if (!category) {
                return;
            }
            dispatch({
                category,
                itemId,
                type: CONTENT_META_INITIALIZE,
            });
            await dispatch(tasksConfigurableFiltersCreator.validateAppliedFilters());

            if (calendarSelector.getIsCalendarDayView(getState()))
                dispatch(tasksConfigurableFiltersCreator.getAssigneeFilterMeta(true));

            if (category === 'tasks' && itemId) {
                dispatch({ type: TOGGLE_IS_LOADING_HEADER });
                await dispatch(taskDefinitionsCreator.getCreateTaskPermitOrPermits(itemId));
                dispatch({ type: TOGGLE_IS_LOADING_HEADER });
            }

            const { content } = getState();
            const offset = content?.offset ?? 0;
            return dispatch(
                creator.loadContent(category, itemId, CONTENT_REQUEST_LIMIT + offset, 0),
            );
        },

    loadNext:
        (category: Category | null, itemId: number | null) =>
        (dispatch, getState, { contentCreator: creator }) => {
            const state = getState();

            if (
                (state.content!.isLoading && state.content!.itemId === itemId) ||
                !state.content!.hasMore
            ) {
                return;
            }

            return dispatch(creator.loadContent(category, itemId));
        },

    loadContent:
        (category: Category | null, itemId: number | null, limit?: number, offset?: number) =>
        async (
            dispatch,
            getState,
            {
                api,
                activeCardCreator,
                toastsCreator,
                commonCreator,
                contentCreator: creator,
                formsCreator,
                contentSelector,
            },
        ) => {
            if (!itemId || !category) {
                return;
            }

            const { onLoadEnd, onLoadError } = onContentLoadStart(
                category.toString(),
                itemId.toString(),
            );

            dispatch({ type: CONTENT_LOADING, itemId, category });

            if (category === 'forms') {
                await dispatch(formsCreator.updateForm(itemId));
            }

            const state = getState();
            const params = stateToFetchContentParams(
                category,
                itemId,
                state,
                limit ?? CONTENT_REQUEST_LIMIT,
                offset ?? state.content?.offset ?? 0,
            );

            return api
                .fetchResponsesOrTasksOrDashboards(params)
                .then(result => {
                    if (!contentSelector.isItemLoading(itemId, category, getState()))
                        throw new WrongItemError();
                    dispatch({
                        result,
                        type: CONTENT_LOADED,
                    });
                })
                .then(() => {
                    dispatch(activeCardCreator.validateActiveCardState());
                })
                .then(() => {
                    if (onLoadEnd) onLoadEnd();
                    if (getIsCalendarView(state)) dispatch(creator.loadNext(category, itemId));
                })
                .catch((e: WrongItemError) => {
                    if (e.name !== WRONG_ITEM) throw e;
                })
                .catch(ignoreDebounceResponse)
                .catch(() => {
                    if (category === 'forms') {
                        dispatch(toastsCreator.createToast(i18n.t(FORM_NOT_AVAILABLE), 'error'));
                    } else {
                        dispatch(toastsCreator.createToast(i18n.t(OBJECT_NOT_FOUND), 'error'));
                    }
                    dispatch(commonCreator.updateNavigationPanel());
                    if (onLoadError) onLoadError();
                });
        },

    contextSearchChangeQuery:
        (value: string) =>
        (dispatch, getState, { contentCreator: creator }) => {
            const { content } = getState();
            if (content === null) return;

            dispatch({
                type: CONTEXT_SEARCH_QUERY_CHANGE,
                value,
            });

            if (
                content.category === CONTENT_CATEGORY_TASKS ||
                content.category === CONTENT_CATEGORY_DASHBOARDS
            ) {
                return dispatch(creator.refresh(false));
            }
        },

    setInitialState: () => dispatch => {
        dispatch({ type: SET_CONTENT_INITIAL_STATE });
    },
};
