import {
    ACTIVE_CARD_CLEAR,
    ActiveCardAction,
    ActiveCardState,
    CHANGE_TASK_CONTENT_TAB,
    CLOSE_TASK_DETAILS_VIEW,
    OPEN_TASK_DETAILS_VIEW,
    SWITCHED_TO_TASK_HISTORY,
    TASK_HISTORY_LOADED,
} from './activeCard.types';
import { OPEN_ACTION_POPUP } from '../tasksActions/tasksActions.types';
import { OPEN_ACTION_POPUP as OPEN_RESPONSES_ACTION_POPUP } from '../responsesActions/responsesActions.types';
import { GO_TO_VOTING } from '../voting/voting.types';
/* eslint-disable import/no-cycle */
import { ThunkActionVoid } from '../index';
import { TasksContentTab } from '../content/content.types';
import { prepareTaskHistoryItems } from './activeCardSelectors';
import { ApiGW } from '../../types/DTO/api-gw';
import { onTaskDetailView } from '../../analytics';

export const initialState = null;

// todo move me into content maybe?
export default function reducer(
    state: ActiveCardState = initialState,
    action: ActiveCardAction,
): ActiveCardState {
    switch (action.type) {
        case ACTIVE_CARD_CLEAR:
            return null;
        case CLOSE_TASK_DETAILS_VIEW:
            return state ? { ...state, taskDetailView: false } : state;
        case OPEN_TASK_DETAILS_VIEW:
            return {
                category: 'tasks',
                cardId: action.taskId,
                taskDetailView: true,
                taskContentTab: TasksContentTab.Details,
            };
        case OPEN_ACTION_POPUP: {
            if ('task' in action) {
                return {
                    category: 'tasks',
                    cardId: action.task.id,
                    taskDetailView: state?.taskDetailView,
                };
            }
            return state;
        }
        case OPEN_RESPONSES_ACTION_POPUP:
            return {
                category: 'forms',
                cardId: action.responseId,
            };
        case GO_TO_VOTING:
            return action.cardId ? { category: action.category, cardId: action.cardId } : state;
        case CHANGE_TASK_CONTENT_TAB:
            return { ...state!, taskContentTab: action.tab };
        case SWITCHED_TO_TASK_HISTORY:
            return { ...state!, switchedToTaskHistory: true };
        case TASK_HISTORY_LOADED:
            return { ...state!, taskHistory: action.taskHistory };

        default:
            return state;
    }
}

export const activeCardCreator = {
    validateActiveCardState(): ThunkActionVoid {
        return (dispatch, getState) => {
            const { content, activeCard } = getState();
            if (content != null && content.category === activeCard?.category) {
                const cardExist = content.items.some(
                    (item: { id: number | string }) => item.id === activeCard.cardId,
                );
                if (cardExist) {
                    return;
                }
            }
            dispatch({ type: ACTIVE_CARD_CLEAR });
        };
    },
    openTaskDetailView: (taskId: number): ThunkActionVoid => {
        return (dispatch, getState, { contentSelector }) => {
            dispatch({
                taskId,
                type: OPEN_TASK_DETAILS_VIEW,
            });
            onTaskDetailView(
                TasksContentTab.Details,
                contentSelector.getTaskDetailViewTask(getState()),
            );
        };
    },
    closeTaskDetailView: (): ThunkActionVoid => {
        return dispatch =>
            dispatch({
                type: CLOSE_TASK_DETAILS_VIEW,
            });
    },
    clearActiveCard: (): ThunkActionVoid => {
        return dispatch => dispatch({ type: ACTIVE_CARD_CLEAR });
    },
    changeTab: (tab: TasksContentTab): ThunkActionVoid => {
        return async (dispatch, getState, { api, contentSelector }) => {
            const { cardId, switchedToTaskHistory } = getState().activeCard!;

            dispatch({
                type: CHANGE_TASK_CONTENT_TAB,
                tab,
            });
            if (tab === TasksContentTab.History && !switchedToTaskHistory) {
                dispatch({
                    type: SWITCHED_TO_TASK_HISTORY,
                });
                let taskHistories: ApiGW.TaskHistoriesDTOV3 | undefined;
                try {
                    taskHistories = await api.getTaskHistory(cardId as number);
                } finally {
                    if (getState().activeCard?.cardId === cardId) {
                        dispatch({
                            type: TASK_HISTORY_LOADED,
                            taskHistory: prepareTaskHistoryItems(
                                taskHistories ? taskHistories.items : [],
                            ),
                        });
                    }
                }
            }
            onTaskDetailView(tab, contentSelector.getTaskDetailViewTask(getState()));
        };
    },
};
