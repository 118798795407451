import React from 'react';

export const onKeyPressed =
    (key: string) =>
    (action: () => void) =>
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === key) {
            action();
        }
    };

export const handleInput =
    (action: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
        action(event.target.value);
    };
