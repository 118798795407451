import { makeStyles } from 'worldapp-ui';
import { CALENDAR_VALUES } from './TaskCalendar.styles';

export const useTaskCalendarHeaderStyles = makeStyles(theme => ({
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 24px 16px 18px',
        backgroundColor: theme.palette.white.main,
        borderRadius: 8,
        height: CALENDAR_VALUES.HEADER_HEIGHT,
        marginBottom: CALENDAR_VALUES.HEADER_MARGIN_BOTTOM,
        gap: 8,
        border: `1px solid ${theme.palette.neutral[12]}`,
    },
    headerMonthWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        minWidth: '20%',
    },
    iconLeft: {
        transform: 'rotate(90deg)',
    },
    iconRight: {
        transform: 'rotate(270deg)',
    },
    btnChangeMonth: {
        color: theme.palette.neutral[50],
    },
    btnToday: {
        color: theme.palette.neutral[75],
        fontWeight: 400,
    },
    headerToggleView: {},
    btnWeekendsWrapper: {
        color: theme.palette.neutral[75],
        marginLeft: 'auto',
        minWidth: '20%',
        '& label': {
            justifyContent: 'flex-end',
            padding: 0,
        },
    },
}));

export const useCalendarToggleViewStyles = makeStyles(theme => ({
    headerToggleView: {
        justifyContent: 'center',
        flex: 1,
    },
    root: {
        borderStyle: 'solid',
        borderWidth: '0 0 2px',
        borderColor: 'transparent',
        color: theme.palette.neutral[65],
        padding: '9px 16px 7px',
        backgroundColor: `${theme.palette.white.main} !important`,
        overflow: 'hidden',
        '&:hover': {
            color: theme.palette.primary.main,
        },
        '&$selected': {
            color: theme.palette.neutral.main,
            borderColor: theme.palette.primary.main,
            borderWidth: '0 0 2px',
        },
    },
    label: {},
    selected: {},
}));
