import React from 'react';
import { MessageBox } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { responsesActionsCreator } from '../../../redux/responsesActions/responsesActions';
import { AppState } from '../../../redux/AppState.types';
import {
    isDeleteResponseActive,
    isResponseActionsLoading,
} from '../../../redux/responsesActions/responsesActionsSelector';

export const mapStateToProps = (state: AppState) => {
    return {
        isPopupOpen: isDeleteResponseActive(state),
        isLoading: isResponseActionsLoading(state),
    };
};
const mapDispatchToProps = mapCreatorsToDispatchProps({
    closeActionPopup: responsesActionsCreator.closeActionPopup,
    deleteResponse: responsesActionsCreator.deleteResponse,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export const DeleteResponse = (props: Props): JSX.Element | null => {
    const { closeActionPopup, isPopupOpen, deleteResponse, isLoading } = props;
    const { t } = useTranslation();

    if (!isPopupOpen) return null;

    const onClose = () => closeActionPopup();

    return (
        <MessageBox
            open={true}
            title={t('ResponseActions.DeleteResponse.Label')}
            confirmButton={{
                label: t('ResponseActions.DeleteResponse.ConfirmButtonTitle'),
                clickHandler: deleteResponse,
                buttonProps: {
                    disabled: isLoading,
                },
            }}
            cancelButton={{
                label: t('ResponseActions.DeleteResponse.CloseButtonTitle'),
                clickHandler: onClose,
            }}
        >
            {t('ResponseActions.DeleteResponse.AreYouSure')}
        </MessageBox>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteResponse);
