import { ToastParams } from 'worldapp-ui/shared';
import { AppState } from '../AppState.types';

/* istanbul ignore next */
export const getToasts = ({ toasts }: AppState): ToastParams[] => toasts.toasts;

/* istanbul ignore next */
export const getInitializationToasts = ({ toasts }: AppState): Omit<ToastParams, 'key'>[] =>
    toasts.initializationToasts;

export default {
    getToasts,
    getInitializationToasts,
};
