import React, { useCallback } from 'react';
import { TextInput, ButtonLink, Box, Grid, Typography } from 'worldapp-ui/shared';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { loginCreator } from '../../redux/login/login';
import { getLastPortalId } from '../../utils/storage.utils';
import { fromString, portalIdToString } from '../../redux/login/portalId';
import { LoginButtonIcon } from './LoginButtonIcon';
import { loginStyles } from './LoginPage.styles';
import ChangeLanguageDropdown from './ChangeLanguageDropdown';
import BrandImage from './BrandImage';
import { getLoginState } from '../../redux/login/loginSelector';
import ButtonWithIcon from '../Common/ButtonWithIcon';

export const Portal = (): JSX.Element => {
    const classes = loginStyles();
    const dispatch = useDispatch();

    const loginState = useSelector(getLoginState, shallowEqual);
    const { t } = useTranslation();

    const lastPortalId = getLastPortalId();

    const handleChangePortalId = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(loginCreator.changePortalId(fromString(event.target.value))),
        [dispatch],
    );

    const handleCheckPortal = useCallback(() => {
        dispatch(loginCreator.applyPortalId());
    }, [dispatch]);

    const useLastPortal = useCallback(
        () => dispatch(loginCreator.changePortalId(lastPortalId)),
        [dispatch, lastPortalId],
    );

    const onKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && loginState.nextStepEnabled) {
                handleCheckPortal();
            }
        },
        [handleCheckPortal, loginState.nextStepEnabled],
    );

    return (
        <Grid container={true} spacing={2} direction="column" wrap="nowrap">
            <Grid item={true} className={classes.fullHeight}>
                <BrandImage className={classes.img} data-testid="brand-image_login" />
            </Grid>
            <Grid item={true}>
                <Box mb={4}>
                    <Typography
                        align="center"
                        variant="body1"
                        className={classes.text}
                        data-testid="login_entering-portal"
                    >
                        {t('Login.GOING_TO_ENTER_PORTAL')}
                        {lastPortalId && (
                            <>
                                {t('Login.PREVIOUSLY_USED')}
                                <ButtonLink
                                    onClick={useLastPortal}
                                    underline="always"
                                    className={classes.buttonLink}
                                    data-testid="button_last_portal"
                                >
                                    #{lastPortalId}
                                </ButtonLink>
                            </>
                        )}
                    </Typography>
                </Box>
            </Grid>
            <Grid item={true}>
                <TextInput
                    label={t('Login.PORTAL_ID')}
                    onChange={handleChangePortalId}
                    fullWidth={true}
                    errorMessage={t(loginState.errorMessage)}
                    value={portalIdToString(loginState.portalId)}
                    onKeyPress={onKeyPress}
                    inputFieldProps={{ autoFocus: true }}
                    data-testid="input_portal-id"
                />
            </Grid>
            <Grid item={true}>
                <ButtonWithIcon
                    disabled={!loginState.nextStepEnabled}
                    icon={<LoginButtonIcon isLoading={loginState.isLoading} />}
                    title={t('Login.CONTINUE_TO_LOGIN')}
                    fullWidth={true}
                    onClick={handleCheckPortal}
                    data-testid="button_continue-to-login"
                />
            </Grid>
            <Box mt={1}>
                <Grid container={true} className={classes.grid}>
                    <ChangeLanguageDropdown />
                </Grid>
            </Box>
        </Grid>
    );
};

export default Portal;
