import { ApiGW } from '../../types/DTO/api-gw';

export const BRAND_INFO_FETCHED = 'BrandInfo/BRAND_INFO_FETCHED';
export const CLEAR_BRAND_INFO = 'BrandInfo/CLEAR_BRAND_INFO';

interface BrandInfoFetchedAction {
    type: typeof BRAND_INFO_FETCHED;
    brandInfo: ApiGW.BrandInfoDTOV2;
}

interface ClearBrandInfoAction {
    type: typeof CLEAR_BRAND_INFO;
}

export type BrandInfoAction = BrandInfoFetchedAction | ClearBrandInfoAction;

export type BrandInfoState = ApiGW.BrandInfoDTOV2 | null;
