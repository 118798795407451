import { applyMiddleware, combineReducers, compose, createStore, Reducer } from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { Location } from 'history';
import { enableES5 } from 'immer';
import { initCallbacks } from '../utils/httpCallbacks';
import { routingMiddleware } from '../location/location';
import getInitialState from '../utils/getInitialState';
import login from './login/login';
import user from './user/user';
import menu from './menu/menu';
import taskDefinitions from './taskDefinitions/taskDefinitions';
import portalSettings from './portalSettings/portalSettings';
import forms from './forms/forms';
import { reducer as changePassword } from './changePassword/changePassword';
import { reducer as content } from './content/content';
import { reducer as contentStatus } from './content/contentStatus';
import tasksActions from './tasksActions/tasksActions';
import responsesActions from './responsesActions/responsesActions';
import toasts from './toasts/toasts';
import { reducer as taskDefinitionAssignees } from './taskDefinitionAssignees/taskDefinitionAssignees';
import { reducer as quickFilters } from './contentQuickFilters/contentQuickFilters';
import { reducer as tasksSort } from './tasksSort/tasksSort';
import { reducer as tasksConfigurableFilters } from './tasksConfigurableFilters/tasksConfigurableFilters';
import { AppThunkExtraParam, AppAction } from '.';
import { dataLayer } from '../datalayer/dataLayer';
import activeCard from './activeCard/activeCard';
import defaultNavigation from './defaultNavigation/defaultNavigation';
import { storageMiddleware } from '../storage/storageMiddleware';
import { reducer as tasksTable } from './tasksTable/tasksTable';
import tasksConfigurableFiltersSelector from './tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import contentQuickFiltersSelector from './contentQuickFilters/contentQuickFiltersSelector';
import contentSelector from './content/contentSelector';
import menuSelector from './menu/menuSelector';
import { reducer as localization, localizationCreator } from './localization/localization';
import brandInfo from './brandInfo/brandInfo';
import { AppState } from './AppState.types';
import { taskDefinitionsSelector } from './taskDefinitions/taskDefinitionsSelector';
import { actionCreators } from './actionCreatorsContext';
import { isProductionEnv } from '../utils/env.utils';
import { getPortal2URL } from '../location/getPortal2URL';
import loginSelector from './login/loginSelector';
import taskDefinitionAssigneesSelector from './taskDefinitionAssignees/taskDefinitionAssigneesSelector';
import changePasswordSelector from './changePassword/changePasswordSelector';
import userSelector from './user/userSelector';
import taskActionsSelector from './tasksActions/taskActionsSelector';
import toastsSelector from './toasts/toastsSelector';
import features from './features/features';
import { reducer as frequentlyUsed } from './frequentlyUsed/frequentlyUsed';
import startForm from './startForm/startForm';
import { reducer as voting } from './voting/voting';
import { reducer as tasksSelectedActions } from './tasksSelectedActions/tasksSelectedActions';
import { reducer as tasksExport } from './tasksExport/tasksExport';
import calendar from './calendar/calendar';
import tasksExportSelector from './tasksExport/tasksExportSelector';
import activeCardSelectors from './activeCard/activeCardSelectors';
import featureSelector from './features/featuresSelector';
import tasksSelectedActionsSelector from './tasksSelectedActions/tasksSelectedActionsSelector';
import calendarSelector from './calendar/calendarSelector';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
    interface Navigator {
        msSaveBlob: (blob: Blob, fileName: string) => boolean;
    }
}
// TODO: consider creating the list automatically
const reducers = {
    user,
    login,
    changePassword,
    menu,
    taskDefinitions,
    portalSettings,
    forms,
    content,
    contentStatus,
    quickFilters,
    tasksActions,
    responsesActions,
    toasts,
    tasksSort,
    tasksConfigurableFilters,
    activeCard,
    defaultNavigation,
    tasksTable,
    taskDefinitionAssignees,
    localization,
    brandInfo,
    features,
    frequentlyUsed,
    startForm,
    voting,
    tasksSelectedActions,
    tasksExport,
    calendar,
};

enableES5();

const rootReducer: Reducer<AppState, AppAction> = combineReducers(reducers) as Reducer<
    AppState,
    AppAction
>;

const extraParam: AppThunkExtraParam = {
    ...actionCreators,
    tasksConfigurableFiltersSelector,
    contentQuickFiltersSelector,
    contentSelector,
    menuSelector,
    localizationCreator,
    taskDefinitionsSelector,
    api: dataLayer,
    getPortal2URL,
    loginSelector,
    taskDefinitionAssigneesSelector,
    changePasswordSelector,
    userSelector,
    taskActionsSelector,
    toastsSelector,
    tasksExportSelector,
    activeCardSelectors,
    featureSelector,
    tasksSelectedActionsSelector,
    calendarSelector,
};

class Store {
    create() {
        const composeEnhancers =
            (!isProductionEnv &&
                // eslint-disable-next-line no-underscore-dangle
                window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
            compose;
        const routing = routingMiddleware(window.location as unknown as Location);
        const initialState = routing.initialState(getInitialState());
        const newStore = createStore(
            rootReducer,
            initialState,
            composeEnhancers(
                applyMiddleware(
                    thunkMiddleware.withExtraArgument(extraParam) as ThunkMiddleware<
                        AppState,
                        AppAction,
                        AppThunkExtraParam
                    >,
                    routing.middleware,
                    storageMiddleware,
                ),
            ),
        );
        return newStore;
    }
}

export const storeFactory = new Store();

export const store = storeFactory.create();

initCallbacks(store.dispatch);
