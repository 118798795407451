import React from 'react';
import { Icon } from 'worldapp-ui';
import { ExpandArrows } from 'worldapp-ui/shared/icons';
import { TaskActionTable } from '../redux/content/validateTaskActions';

export const TABLECELL_ROLE = 'gridcell';

export const ACTIONS_PANEL_ID = 'table_actions_panel';
export const TABLE_ACTION_ID = 'table_action';
export const TABLE_ACTION_SVG_ID = 'table_action_svg';

interface ActionIcon {
    icon: JSX.Element;
    iconStroke: JSX.Element;
    title: string;
}

export type TaskActionList = TaskActionTable | 'Export';

export const ActionsIcons: Record<TaskActionList, ActionIcon> = {
    FillOutForm: {
        icon: <Icon name="fill-out-form" size="l" mode="fill" />,
        iconStroke: <Icon name="fill-out-form" size="l" mode="stroke" />,
        title: 'TaskActions.EditForm.Label',
    },
    OpenTaskDetails: {
        icon: <ExpandArrows />,
        iconStroke: <ExpandArrows />,
        title: 'TaskActions.OpenTaskDetails.Label',
    },
    ChangeStatus: {
        icon: <Icon name="change-status" size="l" mode="fill" />,
        iconStroke: <Icon name="change-status" size="l" mode="stroke" />,
        title: 'TaskActions.ChangeStatus.Label',
    },
    ViewForm: {
        icon: <Icon name="visible" size="l" mode="fill" />,
        iconStroke: <Icon name="visible" size="l" mode="stroke" />,
        title: 'TaskActions.ViewForm.Label',
    },
    UnAssign: {
        icon: <Icon name="unassign" size="l" mode="fill" />,
        iconStroke: <Icon name="unassign" size="l" mode="stroke" />,
        title: 'TaskActions.UnAssign.Label',
    },
    AssignToMe: {
        icon: <Icon name="assign-to-me" size="l" mode="fill" />,
        iconStroke: <Icon name="assign-to-me" size="l" mode="stroke" />,
        title: 'TaskActions.AssignToMe.Label',
    },
    ChangeAssignee: {
        icon: <Icon name="reassign" size="l" mode="fill" />,
        iconStroke: <Icon name="reassign" size="l" mode="stroke" />,
        title: 'TaskActions.ChangeAssignee.Label',
    },
    ChangeDueDate: {
        icon: <Icon name="date" size="l" mode="fill" />,
        iconStroke: <Icon name="date" size="l" mode="stroke" />,
        title: 'TaskActions.ChangeDueDate.Label',
    },
    Export: {
        icon: <Icon name="export" size="l" mode="fill" />,
        iconStroke: <Icon name="export" size="l" mode="stroke" />,
        title: 'TaskActions.Export.Label',
    },
};
