import { makeStyles } from 'worldapp-ui/shared';

const useMenuListStyles = makeStyles(theme => ({
    menuList: {
        flexGrow: 1,
        textAlign: 'center',
        width: '100%',
        outline: 'none',
        marginRight: 4,
    },
    menuListCompactView: {
        marginRight: 0,
    },
    link: {
        cursor: 'pointer',
        width: '100%',
        display: 'block',
    },
    linkCollapsed: {
        visibility: 'hidden',
        overflow: 'hidden',
    },
    linkIE: {
        width: theme.spacing(38),
    },
    showEmptyContainer: {
        minHeight: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 8,
    },
    showEmpty: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

export default useMenuListStyles;
