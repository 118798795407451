import { mapProperties, pipeline, doThrow } from 'worldapp-fe-utils';
import { apiClient, ApiClient } from './ApiClient';
import { FetchContentParams, ResponsesOrTasksOrDashboards } from '../redux/content/content.types';

import { ErrorType, RETRY } from '../utils/Http';
import { ErrorCallback, getErrorCallback as getStoreErrorCallback } from '../utils/httpCallbacks';
import { apiMethodInfo, ApiMethodInfo } from './ApiMethodInfo';
import { DASHBOARDS_ITEM_ID } from '../constants/navigation.constants';
import {
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from '../redux/content/category.types';
import { DataLayer } from './DataLayer.types';

const createErrorHandler =
    (apiName: keyof ApiClient, apiCall: () => Promise<unknown>, errorCallback: ErrorCallback) =>
    (error: ErrorType) =>
        errorCallback(error, apiMethodInfo(apiName))
            .then(resolution => {
                if (resolution === RETRY) {
                    return apiCall();
                }
                throw error;
            })
            .catch(doThrow(error));

export const connectErrorHandlers = (api: ApiClient, errorCallback: ErrorCallback): ApiClient => {
    return mapProperties(api, (method, methodName) => (...params: any[]) => {
        const promise = () => (method as (...parameters: unknown[]) => Promise<unknown>)(...params);
        return promise().catch(createErrorHandler(methodName, promise, errorCallback));
    }) as ApiClient;
};

export const createDataLayer = (api: ApiClient): DataLayer => ({
    ...api,
    fetchCMViewValues: pipeline(api.fetchCMViewValues, {
        strategy: 'LAST_ONLY',
        debounce: 400,
    }),
    fetchContactFilterValues: pipeline(api.fetchContactFilterValues, {
        strategy: 'LAST_ONLY',
        debounce: 400,
    }),
    fetchResponsesOrTasksOrDashboards: pipeline(
        (params: FetchContentParams): Promise<ResponsesOrTasksOrDashboards> => {
            switch (params.category) {
                case CONTENT_CATEGORY_FORMS:
                    return api.fetchResponses(params).then(to => ({
                        to,
                        category: params.category,
                        itemId: params.filter.formId,
                    }));
                case CONTENT_CATEGORY_TASKS:
                    return api.fetchTasks(params).then(to => ({
                        to,
                        category: params.category,
                        itemId: params.taskDefinitionId,
                    }));
                case CONTENT_CATEGORY_DASHBOARDS:
                    return api.fetchDashboards(params).then(to => ({
                        to,
                        category: params.category,
                        itemId: DASHBOARDS_ITEM_ID,
                    }));
            }
        },
        { strategy: 'LAST_ONLY' },
    ),
});

const errorCallback: ErrorCallback = (error: ErrorType, apiInfo: ApiMethodInfo) =>
    getStoreErrorCallback()(error, apiInfo);
export const dataLayer: DataLayer = createDataLayer(connectErrorHandlers(apiClient, errorCallback));
