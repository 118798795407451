import { TaskDefinition } from '../taskDefinitions/taskDefinitions.types';
import { ApiGW } from '../../types/DTO/api-gw';
import { Task } from './content.types';

const mainActionList = ['FillOutForm', 'ViewForm', 'ChangeStatus'] as const;
export type MainTaskAction = typeof mainActionList[number];
export const mainTaskActions: readonly MainTaskAction[] = mainActionList;

export const isMainTaskAction = (action: TaskActionTable): action is MainTaskAction =>
    mainTaskActions.includes(action as MainTaskAction);

export const secondaryActionList = [
    'ChangeStatus',
    'UnAssign',
    'AssignToMe',
    'ChangeAssignee',
    'ChangeDueDate',
    'Export',
] as const;
export type SecondaryTaskAction = typeof secondaryActionList[number];
export const secondaryTaskActions: readonly SecondaryTaskAction[] = secondaryActionList;

export const isSecondaryTaskAction = (action: TaskActionTable): action is SecondaryTaskAction =>
    secondaryTaskActions.includes(action as SecondaryTaskAction);

export type TaskAction = MainTaskAction | SecondaryTaskAction;
export type TaskActionTable = MainTaskAction | 'OpenTaskDetails' | SecondaryTaskAction;
export const taskActions: readonly TaskAction[] = [
    'FillOutForm',
    'ViewForm',
    ...secondaryTaskActions,
];

export type BulkTaskAction = SecondaryTaskAction | 'Export';

export interface TaskPermissionsInfo {
    task: Task & { index?: number };
    taskDefinition: TaskDefinition;
    contactId: number;
}

const checkTaskWithTaskDefinition = (info: TaskPermissionsInfo) => {
    if (info.task.taskDefinitionId !== info.taskDefinition.id) {
        throw new Error(`Invalid parameter: task.taskDefinitionId = ${info.task.taskDefinitionId},
         taskDefinition.id = ${info.taskDefinition.id}`);
    }
};

type RightValidator = (info: TaskPermissionsInfo) => boolean;

const isTaskDefinitionHasForm = ({ taskDefinition }: TaskPermissionsInfo): boolean =>
    !!taskDefinition.form;

const isTaskStarted = ({ task }: TaskPermissionsInfo) => task.response.status !== 'AUTO_CREATED';

const isTaskCompleted = ({ task }: TaskPermissionsInfo) => task.response.status === 'COMPLETED';

const canModifyTask = ({ taskDefinition, task }: TaskPermissionsInfo): boolean => {
    const status = taskDefinition.statusesById && taskDefinition.statusesById[task.statusId];
    return (
        taskDefinition.responsiblePartiesPermissions.modifyClosedTask || !status || !status.closed
    );
};

const isResponsibleParty = ({ task, contactId }: { task: Task; contactId: number }): boolean =>
    !!task.assignee && task.assignee.id === contactId;

const canChangeStatusAsResponsibleParty = (info: TaskPermissionsInfo): boolean =>
    canModifyTask(info) && isResponsibleParty(info);

const canUnAssignAsResponsibleParty = (info: TaskPermissionsInfo): boolean =>
    canModifyTask(info) &&
    isResponsibleParty(info) &&
    !!info.taskDefinition.responsiblePartiesPermissions.unassign;

const canChangeAssigneeAsResponsibleParty = (info: TaskPermissionsInfo): boolean =>
    canModifyTask(info) &&
    isResponsibleParty(info) &&
    !!info.taskDefinition.responsiblePartiesPermissions.assign;

const canChangeDueDateAsResponsibleParty = (info: TaskPermissionsInfo): boolean =>
    canModifyTask(info) &&
    isResponsibleParty(info) &&
    !!info.taskDefinition.responsiblePartiesPermissions.editDueDate;

const canChangeStatusAsManager = ({ task }: TaskPermissionsInfo): boolean =>
    !!task.taskPermissions && task.taskPermissions.changeStatus;

const canUnAssignAsManager = ({ task }: TaskPermissionsInfo): boolean =>
    !!task.taskPermissions && task.taskPermissions.unassign;

const canAssignToMeAsManager = ({ task }: TaskPermissionsInfo): boolean =>
    !!task.taskPermissions && task.taskPermissions.reassignToMe;

const canChangeAssigneeAsManager = ({ task }: TaskPermissionsInfo): boolean =>
    !!task.taskPermissions && task.taskPermissions.reassign;

const canChangeDueDateAsManager = ({ task }: TaskPermissionsInfo): boolean =>
    !!task.taskPermissions && task.taskPermissions.changeDueDate;

const statusCanBeChangedManually = ({ taskDefinition }: TaskPermissionsInfo): boolean =>
    !taskDefinition.statusQuestionId;

const taskIsAssigned = ({ task }: TaskPermissionsInfo): boolean => task.assignee != null;

export const taskHasEditAction = (
    mainAction: MainTaskAction | undefined,
    task: Task,
    contactId: number,
): boolean => mainAction === 'ViewForm' && isResponsibleParty({ task, contactId });

export const actionValidators: Record<TaskAction, RightValidator> = {
    FillOutForm: info =>
        isResponsibleParty(info) && isTaskDefinitionHasForm(info) && !isTaskStarted(info),

    ViewForm: info => isTaskDefinitionHasForm(info) && isTaskStarted(info),

    ChangeStatus: info =>
        statusCanBeChangedManually(info) &&
        (canChangeStatusAsResponsibleParty(info) || canChangeStatusAsManager(info)),

    UnAssign: info =>
        taskIsAssigned(info) && (canUnAssignAsResponsibleParty(info) || canUnAssignAsManager(info)),

    AssignToMe: info => !isResponsibleParty(info) && canAssignToMeAsManager(info),

    ChangeAssignee: info =>
        canChangeAssigneeAsResponsibleParty(info) || canChangeAssigneeAsManager(info),

    ChangeDueDate: info =>
        canChangeDueDateAsResponsibleParty(info) || canChangeDueDateAsManager(info),
    Export: () => true,
};

export const getMainTaskAction = (info: TaskPermissionsInfo): MainTaskAction | undefined => {
    checkTaskWithTaskDefinition(info);
    return mainTaskActions.find(action => actionValidators[action](info));
};

const filterOutChangeStatusIfItIsMainAction =
    (info: TaskPermissionsInfo) =>
    (action: SecondaryTaskAction): boolean =>
        action !== 'ChangeStatus' || getMainTaskAction(info) !== 'ChangeStatus';

export const getSecondaryTaskActions = (info: TaskPermissionsInfo): SecondaryTaskAction[] => {
    checkTaskWithTaskDefinition(info);
    return secondaryTaskActions
        .filter(action => actionValidators[action](info))
        .filter(filterOutChangeStatusIfItIsMainAction(info));
};

export const getSecondaryTaskActionsTable = (info: TaskPermissionsInfo): SecondaryTaskAction[] => {
    checkTaskWithTaskDefinition(info);
    return secondaryTaskActions.filter(action => actionValidators[action](info));
};

export const isTaskStatusDisabled = (
    status: ApiGW.TaskStatusDTO,
    taskDefinition: TaskDefinition,
    task: Task,
): boolean => {
    return (
        taskDefinition.requireFormSubmissionToCloseTask &&
        task.response.status !== 'COMPLETED' &&
        status.closed
    );
};

export const canChangeStatusAfterVoting = (info: TaskPermissionsInfo): boolean =>
    actionValidators.ChangeStatus(info) && isResponsibleParty(info) && isTaskCompleted(info);
