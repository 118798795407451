import React from 'react';
import { Icon, IconButton, makeStyles, Tooltip, lighten } from 'worldapp-ui/shared';
import ContextMenu from 'worldapp-ui/shared/contextMenu/PopperContextMenu';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ActionItem } from '../../ApplicationContent/TableView/TableActions';

export interface CardProps {
    menuActions?: ActionItem[];
    disabled?: boolean;
    isTableMenuActions?: boolean;
}

export const contextMenuStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    greyStyle: {
        color: theme.palette.neutral[75],
        '&:hover': {
            color: theme.palette.neutral.main,
            background: `${lighten(theme.palette.primary.main, 0.8)} !important`,
        },
    },
}));

const MoreButton = (props: CardProps): JSX.Element => {
    const { menuActions = [], disabled, isTableMenuActions } = props;
    const classes = contextMenuStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const toggleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleBubblingEvent = (event: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
            event.stopPropagation();
        }
    };

    const button = (
        <div className={classes.root} onClick={toggleContextMenu} data-testid="more-actions-button">
            <IconButton
                icon={<Icon name="dots" mode="stroke" />}
                disabled={disabled}
                className={classNames({ [classes.greyStyle]: isTableMenuActions })}
                aria-label={t('TaskActions.IconTaskActions.Label')}
            />
        </div>
    );
    return (
        <div onClick={handleBubblingEvent} data-testid="context-wrapper">
            <ContextMenu anchorEl={anchorEl} onClose={handleClose} items={menuActions} />
            {!disabled ? (
                <Tooltip
                    title={t('TaskActions.IconTaskActions.Label') as string}
                    placement="bottom-start"
                >
                    {button}
                </Tooltip>
            ) : (
                button
            )}
        </div>
    );
};

export default MoreButton;
