import { createStyles, makeStyles, Theme } from 'worldapp-ui/shared';

const useNavigationPanelStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerWrapper: {
            alignItems: 'center',
            display: 'flex',
            paddingLeft: 16,
            flexShrink: 0,
        },
        drawerMenu: {
            zIndex: theme.zIndex.drawer + 1,
        },
        drawerPaperClose: {
            width: 56,
        },
    }),
);

export default useNavigationPanelStyles;
