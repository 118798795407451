import { DateFilterRange } from '../../components/ConfigurableFilters/DateFilter';
import { TaskQuickFilters } from '../contentQuickFilters/contentQiuckFilters.types';
import { NumberFilterRange } from '../../components/ConfigurableFilters/NumberFilter';
import { ApiGW } from '../../types/DTO/api-gw';

export const GET_FILTERS_META = 'tasksConfigurableFilters/GET_FILTERS_META';
export const TOGGLE_STRING_ITEM_FILTER_VALUE =
    'tasksConfigurableFilters/TOGGLE_STRING_ITEM_FILTER_VALUE';
export const TOGGLE_ITEM_FILTER_VALUE = 'tasksConfigurableFilters/TOGGLE_ITEM_FILTER_VALUE';
export const CLEAR_FILTER_VALUES = 'tasksConfigurableFilters/CLEAR_FILTER_VALUES';
export const RESET_FILTERS_SET_VALUES = 'tasksConfigurableFilters/RESET_FILTERS_SET_VALUES';
export const CHANGE_DATE_FILTER_RANGE = 'tasksConfigurableFilters/CHANGE_DATE_FILTER_RANGE';
export const CHANGE_NUMBER_FILTER_RANGE = 'tasksConfigurableFilters/CHANGE_NUMBER_FILTER_RANGE';
export const APPLY_FILTERS = 'tasksConfigurableFilters/APPLY_FILTERS';
export const TOGGLE_PREDEFINED_FILTER = 'tasksConfigurableFilters/TOGGLE_PREDEFINED_FILTER';
export const VALIDATE_APPLIED_FILTERS = 'tasksConfigurableFilters/VALIDATE_APPLIED_FILTERS';
export const TOGGLE_APPLIED_STRING_FILTER_VALUE =
    'tasksConfigurableFilters/TOGGLE_APPLIED_STRING_FILTER_VALUE';

export const SAVE_FILTERS_SET = 'tasksConfigurableFilters/SAVE_NEW_FILTERS_SET';
export const DELETE_SAVED_FILTERS_SET = 'tasksConfigurableFilters/DELETE_SAVED_FILTERS_SET';
export const CHANGE_POPUP_STAGE = 'tasksConfigurableFilters/CHANGE_POPUP_STAGE';

export const SET_GENERAL_AS_ACTIVE = 'tasksConfigurableFilters/SET_GENERAL_AS_ACTIVE';
export const SET_SAVED_AS_ACTIVE = 'tasksConfigurableFilters/SET_SAVED_AS_ACTIVE';

export const GET_TASK_PROPERTIES_VALUES = 'tasksConfigurableFilters/GET_TASK_PROPERTIES_VALUES';
export const GET_ASSIGNEE_FILTER_META = 'tasksConfigurableFilters/GET_ASSIGNEE_FILTER_META';

export const ASSIGNEE_FILTER_ID = 'assignee';
export const DUE_DATE_FILTER_ID = 'dueDate';
export const ASSIGN_DATE_FILTER_ID = 'assignDate';
export const STATUSES_FILTER_ID = 'statuses';
export const TD_FILTER_ID = 'taskDefinition';

export enum ConfigurableFilterType {
    String = 'String',
    Date = 'Date',
    Number = 'Number',
    Item = 'Item',
}

export enum FiltersPopupStage {
    Closed = 'Closed',
    GeneralFiltersTab = 'GeneralFiltersTab',
    FiltersSetsTab = 'FiltersSetsTab',
    EditSavedFilter = 'EditSavedFilter',
}

export interface FiltersSetsContentItem {
    id: string;
    label: string;
}

export interface FilterItem {
    id: string | number;
    label: string;
}

// ////////////////////////////////////// Meta /////////////////////////////////////////////
interface AbstractFilterMeta {
    type: ConfigurableFilterType;
    id: string | number;
    label: string;
}

export interface StringFilterMeta extends AbstractFilterMeta {
    type: ConfigurableFilterType.String;
    hasMoreItems: boolean;
    offset: number;
    items: string[];
    query: string;
}

export interface DateFilterMeta extends AbstractFilterMeta {
    type: ConfigurableFilterType.Date;
    dateFormat?: string;
}

export interface NumberFilterMeta extends AbstractFilterMeta {
    type: ConfigurableFilterType.Number;
}

export interface ItemFilterMeta extends AbstractFilterMeta {
    type: ConfigurableFilterType.Item;
    hasMoreItems: boolean;
    offset: number;
    items: FilterItem[];
    query: string;
}

export type FilterMeta = StringFilterMeta | DateFilterMeta | NumberFilterMeta | ItemFilterMeta;

// ///////////////////////////////////// Filter ////////////////////////////////////////////
export interface StringFilter {
    type: ConfigurableFilterType.String;
    id: string | number;
    items: (string | number)[];
}

export interface DateFilter {
    type: ConfigurableFilterType.Date;
    id: string | number;
    range: DateFilterRange;
}

export interface NumberFilter {
    type: ConfigurableFilterType.Number;
    id: string | number;
    range: NumberFilterRange;
}

export interface ItemFilter {
    type: ConfigurableFilterType.Item;
    id: string | number;
    items: string[] | number[];
}

export type Filter = StringFilter | DateFilter | NumberFilter | ItemFilter;

// ///////////////////////////////////// FiltersSet ////////////////////////////////////////////
export interface FiltersSet {
    predefined: TaskQuickFilters;
    custom: Record<string | number, Filter>;
}

export type AppliedFiltersSets = Record<number, FiltersSet>;
export type TDSavedFiltersSets = Record<string, FiltersSet>;
export type SavedFiltersSets = Record<number, TDSavedFiltersSets>;

// ///////////////////////////////////// Actions, State ////////////////////////////////////////////
export interface ClearFilterValuesAction {
    type: typeof CLEAR_FILTER_VALUES;
    filterId: number | string;
}

export interface ResetFiltersSetValuesAction {
    type: typeof RESET_FILTERS_SET_VALUES;
    filtersSet: FiltersSet;
}

export interface GetFiltersMetaAction {
    type: typeof GET_FILTERS_META;
    filtersMeta: FilterMeta[];
}

export interface ToggleStringFilterValueAction {
    type: typeof TOGGLE_STRING_ITEM_FILTER_VALUE;
    filterId: number | string;
    value: string | number;
}

export interface ChangeDateFilterRangeAction {
    type: typeof CHANGE_DATE_FILTER_RANGE;
    filterId: number | string;
    range: DateFilterRange;
}

export interface ChangeNumberFilterRangeAction {
    type: typeof CHANGE_NUMBER_FILTER_RANGE;
    filterId: number | string;
    range: NumberFilterRange;
}

export interface ApplyFiltersAction {
    type: typeof APPLY_FILTERS;
    taskDefinitionId: number;
    filtersSet: FiltersSet;
}

export interface TogglePredefinedFilterAction {
    type: typeof TOGGLE_PREDEFINED_FILTER;
    filter: keyof TaskQuickFilters;
}

export interface SaveFiltersSetAction {
    type: typeof SAVE_FILTERS_SET;
    taskDefinitionId: number;
    filtersSetName: string;
    filtersSet: FiltersSet;
}

export interface DeleteSavedFiltersSetAction {
    type: typeof DELETE_SAVED_FILTERS_SET;
    taskDefinitionId: number;
    filtersSetName: string;
}

export interface ChangePopupStageAction {
    type: typeof CHANGE_POPUP_STAGE;
    stage: FiltersPopupStage;
    taskDefinitionId: number;
    defaultFiltersSet?: FiltersSet;
}

export interface SetGeneralAsActiveAction {
    type: typeof SET_GENERAL_AS_ACTIVE;
}

export interface SetSavedAsActiveAction {
    type: typeof SET_SAVED_AS_ACTIVE;
    taskDefinitionId: number;
    filtersSetName: string;
}

export interface ValidateAppliedFiltersAction {
    type: typeof VALIDATE_APPLIED_FILTERS;
    taskDefinitionId: number;
    requireDueDate: boolean;
}

export interface GetTaskPropertiesValuesAction {
    type: typeof GET_TASK_PROPERTIES_VALUES;
    taskPropertiesValues: ApiGW.TaskPropertyExistingValuesDTO;
    taskPropertyId: number;
    resetItems: boolean;
    query?: string;
}

export interface GetAssigneeFilterMetaAction {
    type: typeof GET_ASSIGNEE_FILTER_META;
    filterMeta: StringFilterMeta;
}

export interface ToggleAppliedStringFilterValueAction {
    type: typeof TOGGLE_APPLIED_STRING_FILTER_VALUE;
    taskDefinitionId: number;
    filterId: number | string;
    itemName: string;
    defaultFiltersSet?: FiltersSet;
}

export type TasksConfigurableFiltersAction =
    | ApplyFiltersAction
    | ChangeDateFilterRangeAction
    | ChangeNumberFilterRangeAction
    | ChangePopupStageAction
    | ClearFilterValuesAction
    | DeleteSavedFiltersSetAction
    | GetFiltersMetaAction
    | ResetFiltersSetValuesAction
    | SaveFiltersSetAction
    | SetGeneralAsActiveAction
    | SetSavedAsActiveAction
    | ToggleStringFilterValueAction
    | TogglePredefinedFilterAction
    | ValidateAppliedFiltersAction
    | GetTaskPropertiesValuesAction
    | GetAssigneeFilterMetaAction
    | ToggleAppliedStringFilterValueAction;

export interface TasksConfigurableFiltersState {
    popupStage: FiltersPopupStage;
    filtersMeta: FilterMeta[] | null;
    generalFiltersSet: FiltersSet | null;
    activeFiltersSet: FiltersSet | null;
    activeFiltersSetName: string | null;
    appliedFiltersSets: AppliedFiltersSets;
    savedFiltersSets: SavedFiltersSets;
}

export type DateRangeFilterID = typeof DUE_DATE_FILTER_ID | typeof ASSIGN_DATE_FILTER_ID | number;
