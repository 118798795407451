import React, { ChangeEvent } from 'react';
import { Grid, FormControlLabel, Radio, Text } from 'worldapp-ui/shared';
import { actionStyles } from '../ActionStyles.styles';

interface ChangeAssigneeControlsProps {
    isChecked: boolean;
    id: string | number;
    name: string;
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    accent?: boolean;
}

export const ChangeAssigneeControl = (props: ChangeAssigneeControlsProps): JSX.Element => {
    const { isChecked, id, name, onChange, accent } = props;

    const classes = actionStyles();

    return (
        <Grid item={true} key={`${id}_${id}`} className={classes.item}>
            <FormControlLabel
                value={id}
                classes={{
                    root: classes.labelRoot,
                    label: isChecked ? classes.labelChecked : classes.label,
                }}
                control={<Radio color="primary" checked={isChecked} onChange={onChange} />}
                label={<Text variant={accent ? 'r12m' : 'r12r'}>{name}</Text>}
                data-testid={`radio-change_assignee-${id}`}
            />
        </Grid>
    );
};
