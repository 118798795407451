import React from 'react';
import { useSelector } from 'react-redux';
// TODO switch logo to svg when possible
import logo from '../../img/logoEF.png'; // this is logo from api brand-info
import logoKS from '../../img/logoKS.png'; // this is logo from api brand-info
import { brandImageUrlSelector } from '../../redux/brandInfo/brandInfoSelector';
import { getOrigin } from '../../utils/window.location.utils';

interface BrandImageProps {
    className?: string;
    width?: number;
    height?: number;
}

export const BrandImage = (props: BrandImageProps): JSX.Element => {
    const { className, ...restProps } = props;

    const originLogo = getOrigin().includes('keysurvey') ? logoKS : logo;

    const imgUrl = useSelector(brandImageUrlSelector);

    return <img className={className || ''} src={imgUrl || originLogo} {...restProps} alt="logo" />;
};

export default BrandImage;
