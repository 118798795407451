import React, { FunctionComponent, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'worldapp-ui';
import { startFormPopupStyles } from './StartFormPopup.styles';

export const StartFormNothingFound: FunctionComponent = () => {
    const { t } = useTranslation();
    const nothingFoundRef = useRef<HTMLDivElement>(null);
    const [nothingFoundBottom, setNothingFoundBottom] = useState(0);

    useLayoutEffect(() => {
        setNothingFoundBottom(nothingFoundRef.current?.getBoundingClientRect().bottom || 0);
    }, [nothingFoundRef]);

    const classes = startFormPopupStyles({ nothingFoundBottom });

    return (
        <div ref={nothingFoundRef} className={classes.noSearchResults}>
            <Text variant="r16b" align="center" data-testid="start-form_no-search-results">
                {t('StartForm.NoSearchResults')}
            </Text>
        </div>
    );
};
