import React from 'react';

import { useTranslation } from 'react-i18next';
import { CircularContouredLoader, Text } from 'worldapp-ui';
import { taskDetailViewStyles } from './TaskDetailView.styles';
import { TaskHistoryItem } from '../../../redux/activeCard/activeCard.types';

const TaskHistoryRow = (props: { item: TaskHistoryItem }): JSX.Element => {
    const { item } = props;
    const classes = taskDetailViewStyles();
    const { t } = useTranslation('', { keyPrefix: 'TaskHistory' });
    const { t: tf } = useTranslation('', { keyPrefix: 'TaskHistory.Field' });

    const title = (
        <>
            <span className={classes.thAccentText}>
                {item.created ? t('TaskCreated') : item.label ?? tf(item.type)}
            </span>
            {!item.created && (
                <>
                    {' '}
                    {t('ChangedTo')} <span className={classes.thAccentText}>{item.current}</span>
                </>
            )}
            {` ${t('By')} ${item.user}`}
        </>
    );

    const fields = item.created
        ? item.fields.map(field => `${field.label ?? tf(field.type)}: ${field.value ?? t('Empty')}`)
        : [`${t('Previous')}: ${item.previous ?? t('Empty')}`];

    return (
        <div className={classes.thRow}>
            <div className={classes.thRowNav}>
                <div className={classes.thBullet} />
                <div className={classes.thLine} />
            </div>
            <div className={classes.thRowContent}>
                <div className={classes.thDate}>
                    <Text variant="r12r" className={classes.thDateText}>
                        {item.date}
                    </Text>
                </div>
                <div>
                    <Text variant="r12r" className={classes.thTitle}>
                        {title}
                    </Text>
                </div>
                <div>
                    {fields.map((field, index) => (
                        <div key={index} className={classes.thField}>
                            <Text variant="r12r" className={classes.thFieldText}>
                                {field}
                            </Text>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

interface Props {
    items?: TaskHistoryItem[];
}

export const TaskHistoryTab = (props: Props): JSX.Element => {
    const { items } = props;
    const classes = taskDetailViewStyles();

    return (
        <div className={classes.tdDescriptionSection}>
            {!items && <CircularContouredLoader />}
            {items && (
                <div>
                    {items.map(item => (
                        <TaskHistoryRow key={item.key} item={item} />
                    ))}
                </div>
            )}
        </div>
    );
};
