import React from 'react';

import { useTranslation } from 'react-i18next';
import { ExpandMoreThin } from 'worldapp-ui/shared/icons';
import { Checkbox, IconButton, Text, TextButton } from 'worldapp-ui';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTaskCalendarHeaderStyles } from './TaskCalendarHeader.styles';

import TaskCalendarHeaderToggleView from './TaskCalendarHeaderToggleView';
import {
    getCalendarView,
    getFirstDayOfCalendar,
    getSelectedDate,
    getWeekendsEnabled,
} from '../../../redux/calendar/calendarSelector';
import { calendarCreator } from '../../../redux/calendar/calendar';

const TaskCalendarHeader = (): JSX.Element => {
    const { t } = useTranslation();
    const classes = useTaskCalendarHeaderStyles();

    const weekEnabled = useSelector(getWeekendsEnabled);
    const selectedDate = useSelector(getSelectedDate);
    const calendarView = useSelector(getCalendarView);
    const firstDayOfCalendar = useSelector(getFirstDayOfCalendar);

    const dispatch = useDispatch();

    const onPrevDate = () => dispatch(calendarCreator.setPrevSelectedDate());
    const onNextDate = () => dispatch(calendarCreator.setNextSelectedDate());
    const onToday = () => dispatch(calendarCreator.setSelectedDate(new Date()));
    const weekendsEnabled = useSelector(getWeekendsEnabled);

    const onSwitchWeekends = () => dispatch(calendarCreator.setWeekendsEnabled(!weekEnabled));

    let selectedPeriod = moment(selectedDate).format('MMMM Do, YYYY');
    if (calendarView === 'month') {
        selectedPeriod = moment(selectedDate).format('MMMM YYYY');
    } else if (calendarView === 'week') {
        const startDate = moment(firstDayOfCalendar)
            .clone()
            .add(weekendsEnabled ? 0 : 1, 'days');
        const endDate = moment(firstDayOfCalendar)
            .clone()
            .add(weekendsEnabled ? 6 : 5, 'days');

        if (!moment(startDate).isSame(endDate, 'year')) {
            selectedPeriod = `${startDate.format('MMM D, YYYY')} - ${endDate.format(
                'MMM D, YYYY',
            )}`;
        } else if (!moment(startDate).isSame(endDate, 'month')) {
            selectedPeriod = `${startDate.format('MMM D')} - ${endDate.format(
                'MMM D, ',
            )} ${startDate.format('YYYY')}`;
        } else {
            selectedPeriod = `${startDate.format('MMM')} ${startDate.format('D')}-${endDate.format(
                'D',
            )}, ${startDate.format('YYYY')}`;
        }
    }

    return (
        <div className={classes.headerWrapper}>
            <div className={classes.headerMonthWrapper}>
                <IconButton
                    icon={<ExpandMoreThin type="l" className={classes.iconLeft} />}
                    onClick={onPrevDate}
                    className={classes.btnChangeMonth}
                    data-testid="task-calendar-header-btn_month-left"
                    aria-label={t('TasksCalendar.PrevDate')}
                />
                <Text variant="r20m" data-testid="task-calendar-header-text-selected-date">
                    {selectedPeriod}
                </Text>
                <IconButton
                    icon={<ExpandMoreThin type="l" className={classes.iconRight} />}
                    onClick={onNextDate}
                    className={classes.btnChangeMonth}
                    data-testid="task-calendar-header-btn_month-right"
                    aria-label={t('TasksCalendar.NextDate')}
                />
                <TextButton
                    padding={0}
                    minWidth={60}
                    className={classes.btnToday}
                    onClick={onToday}
                    data-testid="task-calendar-header-btn_today"
                >
                    {t('TasksCalendar.Today')}
                </TextButton>
            </div>
            <TaskCalendarHeaderToggleView />
            <div className={classes.btnWeekendsWrapper}>
                <Checkbox
                    data-testid="task-calendar-header-checkbox-weekends"
                    color="primary"
                    label={t('TasksCalendar.Weekends')}
                    checked={weekEnabled}
                    onClick={onSwitchWeekends}
                    disabled={calendarView === 'day'}
                />
            </div>
        </div>
    );
};
export default TaskCalendarHeader;
