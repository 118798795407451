import { createSelector } from 'reselect';
import { ApiGW } from '../../types/DTO/api-gw';
import { AppState } from '../AppState.types';

export const getForms = ({ forms }: AppState): ApiGW.FormDTOV3[] => forms.forms;

export const getHasForms = ({ forms }: AppState): boolean => forms.forms.length > 0;

export const getFormsIds = createSelector(getForms, forms => forms.map(form => form.id));

export const getFormsLoading = ({ forms }: AppState): boolean => forms.loading;

export const getFormsAvailable = (state: AppState): boolean =>
    getFormsLoading(state) || getHasForms(state);
