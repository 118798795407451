import { ApiGW } from '../../types/DTO/api-gw';
import { AppState } from '../AppState.types';

export const getAssigneeValues = ({
    taskDefinitionAssignees,
}: AppState): ApiGW.TaskAssigneeFilterValueDTOV3[] => {
    return taskDefinitionAssignees.assigneeValues;
};

/* istanbul ignore next */
export const getHasMoreValues = ({ taskDefinitionAssignees }: AppState): boolean =>
    taskDefinitionAssignees.hasMoreValues;

/* istanbul ignore next */
export const getOffset = ({ taskDefinitionAssignees }: AppState): number =>
    taskDefinitionAssignees.offset;

export default { getAssigneeValues, getHasMoreValues, getOffset };
