import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import {
    ClickAwayListener,
    Icon,
    IconButton,
    makeStyles,
    Popper,
    Text,
    Tooltip,
} from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import Cancel from 'worldapp-ui/shared/icons/Cancel';
import classNames from 'classnames';
import useResizeObserver from '@react-hook/resize-observer';

import ReactGA from 'react-ga4';
import { isMobileDevice, isTablet, isDesktop } from '../../../utils/browser.utils';
import { getIncludedTasksSelected } from '../../../redux/tasksSelectedActions/tasksSelectedActionsSelector';
import { useActionCreators } from '../../../hooks/useActionCreators';
import {
    getIsDrawerHoverOpen,
    getIsDrawerPermanentOpen,
    getTasksView,
} from '../../../redux/menu/menuSelector';
import { getAvailableActionsForSelectedTasks } from '../../../redux/content/contentSelector';
import { BulkTaskAction } from '../../../redux/content/validateTaskActions';
import {
    BulkActionsIcons,
    TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
} from '../../../constants/ui.constants';
import { DEFAULT_PADDING } from '../../ui/cards/cardsSizes';
import { getToasts } from '../../../redux/toasts/toastsSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { menuCreator } from '../../../redux/menu/menu';
import { AppState } from '../../../redux/AppState.types';
import { TasksView } from '../../../redux/menu/menu.types';

const TasksBulkActionsMenuStyles = makeStyles(theme => ({
    menuWrapper: {
        left: 0,
        right: 0,
        bottom: 32,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        zIndex: 1,
        '&[data-ismobile="true"]': {
            bottom: 12,
        },
    },
    menu: {
        color: theme.palette.white.main,
        background: theme.palette.neutral.main,
        gap: 8,
        height: 44,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        minWidth: 294,
        margin: '0 48px',
        overflow: 'hidden',
        pointerEvents: 'auto',
        '&[data-ismobile="true"]': {
            gap: 0,
            left: 0,
            margin: '0 12px',
            bottom: 12,
            height: 56,
            transform: 'none',
            width: '100%',
            justifyContent: 'space-between',
            padding: '0 16px',
            minWidth: 350,
            boxSizing: 'border-box',
        },
    },
    menuInfo: {
        display: 'flex',
        gap: 8,
        flexShrink: 0,
        alignItems: 'center',
    },
    menuLine: {
        display: 'flex',
        gap: 8,
        flexShrink: 1,
        flexGrow: 1,
        overflow: 'hidden',
        '&[data-ismobile="true"]': {
            justifyContent: 'space-evenly',
            paddingRight: 16,
            '&.menuActionTextHideLeft': {
                justifyContent: 'flex-start',
            },
        },
    },
    menuActionText: {
        '.menuActionTextHide &': {
            display: 'none',
        },
    },
    menuIcon: {},
    menuText: {},
    menuAction: {
        color: theme.palette.white.main,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        height: 28,
        padding: '0 8px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        gap: 8,
        '&:hover': {
            background: theme.palette.neutral[75],
        },
        '&:focus': {
            background: theme.palette.neutral[75],
        },
        '&[data-isVisible="false"]': {
            visibility: 'hidden',
        },
    },
    menuMoreButton: {
        width: 28,
    },
    menuMore: {
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.neutral.main,
        borderRadius: 4,
        gap: 4,
        padding: 12,
        marginBottom: 10,
        '& [data-isVisible="false"]': {
            visibility: 'visible',
        },
    },
    menuClose: {
        padding: 0,
    },
}));

const mapDispatchToProps = mapCreatorsToDispatchProps(menuCreator);

export const mapStateToProps = (state: AppState) => ({
    isDrawerPermanentOpen: getIsDrawerPermanentOpen(state),
    isDrawerHoverOpen: getIsDrawerHoverOpen(state),
    availableActions: getAvailableActionsForSelectedTasks(state),
    selectedTasks: getIncludedTasksSelected(state),
    tasksView: getTasksView(state),
});

export interface TasksBulkActionsMenuProps {
    contentDiv: HTMLDivElement | null;
    isDrawerPermanentOpen: boolean;
    isDrawerHoverOpen: boolean;
    availableActions: {
        actions: Set<BulkTaskAction>;
        isDifferentPermissions: boolean;
    };
    selectedTasks: number[];
    tasksView: TasksView;
}

export const TasksBulkActionsMenu = (props: TasksBulkActionsMenuProps): JSX.Element | null => {
    const {
        contentDiv,
        isDrawerHoverOpen,
        isDrawerPermanentOpen,
        availableActions,
        selectedTasks,
        tasksView,
    } = props;
    const { tasksActionsCreator, tasksExportCreator, tasksSelectedActionsCreator } =
        useActionCreators();
    const { t } = useTranslation();
    const menuWrapperRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const refArr = useRef([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [maxVisibleMenuItems, setMaxVisibleMenuItems] = useState(0);
    const classes = TasksBulkActionsMenuStyles();

    const { actions, isDifferentPermissions } = availableActions;
    refArr.current = Array.from(actions).map((_, index) => {
        return refArr.current[index] || React.createRef();
    });

    const isToastsPresent = useSelector(getToasts).length >= 1;

    const isDrawerOpen = isDrawerPermanentOpen || isDrawerHoverOpen;

    const updateMenuListSize = useCallback(() => {
        const SPACING_FOR_TOASTS = {
            MENU_OPEN: 244, // 500 (toast width) + 24 (toast.left) - 328 (left panel width)) + 48 (space between toast and menuWrapperRef)
            MENU_CLOSE: 516, // 500 (toast width) + 24 (toast.left) - 56 (left panel width)) + 48 (space between toast and menuWrapperRef)
        };

        if (menuWrapperRef.current && contentDiv && listRef.current) {
            listRef.current.className = classes.menuLine;
            listRef.current.style.width = 'auto';

            let clientMaxWidth = contentDiv.clientWidth;

            if (isDesktop && isToastsPresent) {
                clientMaxWidth -= isDrawerOpen
                    ? SPACING_FOR_TOASTS.MENU_OPEN
                    : SPACING_FOR_TOASTS.MENU_CLOSE;
            }

            menuWrapperRef.current.style.maxWidth = `${clientMaxWidth}px`;

            const clientLeft = contentDiv.getBoundingClientRect().right - clientMaxWidth;

            menuWrapperRef.current.style.left = `${clientLeft}px`;

            const listWidth = listRef.current?.clientWidth || 0;
            let itemWidth = 0;
            let listIndex = -1;
            let isMobileTextHide = false;

            for (let index = 0; index < refArr.current.length; index++) {
                if (itemWidth! < listWidth) {
                    itemWidth += (refArr.current[index] as any).current?.clientWidth;
                    if (index > 0) {
                        itemWidth += DEFAULT_PADDING;
                    }
                }
                if (itemWidth > listWidth) {
                    if (!isMobileDevice) {
                        listRef.current.style.width = `${
                            itemWidth - (refArr.current[index] as any).current?.clientWidth
                        }px`;
                    }
                    listIndex = index;
                    isMobileTextHide = true;
                    break;
                }
            }

            if (isMobileDevice) {
                if (isMobileTextHide) {
                    listRef.current.className += ' menuActionTextHide';
                }
                if (actions.size < 4) {
                    listRef.current.className += ' menuActionTextHideLeft';
                }
                listIndex = actions.size;
            }

            setMaxVisibleMenuItems(listIndex > -1 ? listIndex : actions.size);
        }
    }, [actions.size, contentDiv, classes.menuLine, isToastsPresent, isDrawerOpen, menuWrapperRef]);

    useResizeObserver(props.contentDiv, () => {
        updateMenuListSize();
    });

    useEffect(() => {
        updateMenuListSize();
    }, [tasksView, updateMenuListSize]);

    useEffect(() => {
        ReactGA.event('bulk_popup');
    }, [refArr]);

    const handleAction = (action: BulkTaskAction) => {
        if (action === 'Export') {
            tasksExportCreator.exportTasks();
        } else {
            tasksActionsCreator.openActionPopup(action);
        }
    };

    const handleCloseMenu = () => tasksSelectedActionsCreator.deselectTasks();

    const actionsList = (activeRef: boolean) =>
        Array.from(actions).map((action, i) => {
            return (
                <div
                    key={BulkActionsIcons[action].title}
                    onClick={() => handleAction(action)}
                    className={classes.menuAction}
                    {...(activeRef ? { ref: refArr.current[i] } : null)}
                    data-isvisible={i < maxVisibleMenuItems}
                    data-testid={BulkActionsIcons[action].title}
                >
                    {BulkActionsIcons[action].iconStroke}
                    <span className={classes.menuActionText}>
                        {t(BulkActionsIcons[action].title)}
                    </span>
                </div>
            );
        });

    const [openTooltip, setOpenTooltip] = React.useState(false);

    const handleInfoIconClick = () => {
        setOpenTooltip(!openTooltip);
    };
    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    return createPortal(
        <div
            className={classNames(classes.menuWrapper, TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS)}
            ref={menuWrapperRef}
            data-ismobile={isMobileDevice}
        >
            <div className={classes.menu} data-ismobile={isMobileDevice} data-testid="menu-action">
                {!isMobileDevice && (
                    <div className={classes.menuInfo} data-testid="menu-action_info">
                        {isDifferentPermissions && (
                            <>
                                {isDesktop && (
                                    <Tooltip
                                        title={t('TasksBulkActions.TooltipInfoIcon') as string}
                                        data-testid="menu-action_info-tooltip"
                                    >
                                        <span>
                                            <Icon
                                                name="info"
                                                className={classes.menuIcon}
                                                mode="stroke"
                                                data-testid="menu-action_info-icon"
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                                {isTablet && (
                                    <ClickAwayListener
                                        disableReactTree
                                        onClickAway={handleTooltipClose}
                                    >
                                        <div>
                                            <Tooltip
                                                title={
                                                    t('TasksBulkActions.TooltipInfoIcon') as string
                                                }
                                                data-testid="menu-action_info-tooltip"
                                                onClick={handleInfoIconClick}
                                                open={openTooltip}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                            >
                                                <span>
                                                    <Icon
                                                        name="info"
                                                        className={classes.menuIcon}
                                                        mode="stroke"
                                                        data-testid="menu-action_info-icon"
                                                        onClick={handleInfoIconClick}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </>
                        )}
                        <Text
                            variant="r16m"
                            className={classes.menuText}
                            data-testid="menu-action_selected-tasks"
                        >
                            {selectedTasks?.length}&nbsp;
                            {selectedTasks?.length === 1
                                ? t('TasksBulkActions.TaskSelected')
                                : t('TasksBulkActions.TasksSelected')}
                            {isDesktop && isDifferentPermissions && (
                                <span data-testid="menu-action_different-type">
                                    &nbsp;({t('TasksBulkActions.DifferentType')})
                                </span>
                            )}
                        </Text>
                    </div>
                )}
                <div
                    className={classes.menuLine}
                    ref={listRef}
                    data-testid="menu-action_wrapper-btn-action"
                    data-ismobile={isMobileDevice}
                >
                    {actionsList(true)}
                </div>
                {!isMobileDevice && (
                    <div
                        className={classes.menuMoreButton}
                        onClick={event => setAnchorEl(event.currentTarget)}
                        data-testid="menu-action_btn-more-menu"
                    >
                        {maxVisibleMenuItems < actions.size && (
                            <>
                                <div data-testid="more-actions-button">
                                    <IconButton
                                        icon={<Icon name="dots" mode="stroke" />}
                                        className={classes.menuAction}
                                    />
                                </div>

                                <Popper
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    placement="top-end"
                                    data-testid="menu-action_more-menu"
                                >
                                    <ClickAwayListener
                                        disableReactTree
                                        onClickAway={() => setAnchorEl(null)}
                                    >
                                        <div
                                            className={classNames(
                                                classes.menuMore,
                                                TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
                                            )}
                                        >
                                            {actionsList(false).slice(maxVisibleMenuItems)}
                                        </div>
                                    </ClickAwayListener>
                                </Popper>
                            </>
                        )}
                    </div>
                )}
                <div>
                    <Cancel
                        onClick={handleCloseMenu}
                        className={classNames(classes.menuAction, classes.menuClose)}
                        data-testid="menu-action_btn-close"
                    />
                </div>
            </div>
        </div>,
        document.body,
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksBulkActionsMenu);
