import { createStyles, Theme, makeStyles, fade, lighten } from 'worldapp-ui/shared';

export interface NavTreeStylesProps {
    isCompactView: boolean;
    isIE: boolean;
}

const navTreeStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: (props: NavTreeStylesProps) => ({
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            overflowY: props.isCompactView ? 'hidden' : 'auto',
            overflowX: 'hidden',
            flexGrow: props.isIE ? 1 : 'initial',
            minHeight: theme.spacing(6),
        }),
    }),
);

type ClassKey =
    | 'heading'
    | 'headingActive'
    | 'panelDetails'
    | 'root'
    | 'expanded'
    | 'width'
    | 'headingActiveCompactView';

interface StylesProps {
    isCompactView: boolean;
    isMobile: boolean;
}

export const stylesAccordion = makeStyles<Theme, StylesProps, ClassKey>(theme => ({
    heading: {
        color: theme.palette.neutral[75],
        background: theme.palette.common.white,
        boxSizing: 'border-box',
        height: 48,
        justifyContent: 'flex-start',
        minHeight: '48px!important',
        maxHeight: 48,
        padding: '0 8px 0 16px',
        width: '100%',
        overflow: 'hidden',
        '&>div:nth-child(1)': {
            width: '100%',
        },
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    headingActive: {
        backgroundColor: lighten(theme.palette.primary.main, 0.84),
        color: theme.palette.primary.main,
        boxSizing: 'border-box',
        height: 48,
        justifyContent: 'flex-start',
        minHeight: '48px!important',
        maxHeight: 48,
        padding: '0 8px 0 16px',
        width: 'calc(100% - 12px)',
        borderTopRightRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        borderBottom: 0,
        marginBottom: theme.spacing(0),
        '&>div:nth-child(1)': {
            width: '100%',
        },
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.32),
        },
    },
    headingActiveCompactView: {
        width: '100%',
        borderRadius: 0,
    },
    panelDetails: {
        padding: 0,
    },
    root: (props: StylesProps) => ({
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&>div:nth-child(2)': {
            display: 'none',
        },
        border: '0',
        boxShadow: 'none',
        margin: '0!important',
        width: props.isCompactView ? '100%' : 324,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        minHeight: theme.spacing(6),
    }),
    expanded: (props: StylesProps) => ({
        flex: 1,
        margin: '0 !important',
        '&>div:nth-child(1)': {
            flex: 0,
        },
        '&>div:nth-child(2)': {
            overflowY: props.isCompactView ? 'hidden' : 'auto',
            overflowX: 'hidden',
            flex: 1,
            display: 'flex',
            marginRight: 2,
            ...theme.mixins.scrollOnHover,
        },
        '&>div:nth-child(2)>div:first-child': {
            width: '100%',
        },
    }),
    width: {},
}));

export default navTreeStyles;
