import React, { ReactElement } from 'react';
import { Button, makeStyles } from 'worldapp-ui';
import { CustomButtonProps } from 'worldapp-ui/shared/buttons/Button';
import { BUTTON_HEIGHT } from '../../constants/ui.constants';

const useStyles = makeStyles(theme => ({
    icon: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: theme.spacing(),
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        height: 40,
    },
}));

interface Props extends CustomButtonProps {
    icon: ReactElement;
    title: string;
}

const ButtonWithIcon = (props: Props): JSX.Element => {
    const { icon, title, ...restProps } = props;
    const classes = useStyles();
    return (
        <Button {...restProps} style={{ height: BUTTON_HEIGHT }}>
            {title}
            <div className={classes.icon}>{icon}</div>
        </Button>
    );
};

export default ButtonWithIcon;
