import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'worldapp-ui';
import { useTranslation } from 'react-i18next';
import { tasksActionsCreator } from '../../../redux/tasksActions/tasksActions';
import {
    Contact,
    getType,
    getContacts,
    getChangeAssigneContent,
    getFilterViews,
    getHasFilters,
    getIsChangeAssigneeButtonDisabled,
    getHasMoreContacts,
    getIsLoading,
    getActiveContactId,
    getSearchQuery,
} from '../../../redux/tasksActions/taskActionsSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { ChangeAssigneeFilterView } from '../../../redux/tasksActions/tasksActions.types';
import { ChangeAssigneeFilter, ChangeAssigneeFilterTitle } from './ChangeAssigneeFilter';
import { AppState } from '../../../redux/AppState.types';
import { getTaskDetailViewTaskId } from '../../../redux/activeCard/activeCardSelectors';
import { ChangeAssigneeContent, SearchInput, FilterButton } from './ChangeAssigneeContent';
import { PopoverDialog } from '../../Common/PopoverDialog';
import { actionStyles } from '../ActionStyles.styles';
import { getTaskPermissionsInfo } from '../../../redux/activeCard/activeCardSelectors.reselect';
import {
    getSecondaryTaskActions,
    SecondaryTaskAction,
    TaskPermissionsInfo,
} from '../../../redux/content/validateTaskActions';
import { ChangeAssigneeControl } from './ChangeAssigneeControl';

interface MapStateToPropsReturn {
    type:
        | 'ChangeAssignee'
        | 'ChangeStatus'
        | 'ChangeDueDate'
        | 'ConfirmChangeDueDate'
        | 'UnAssign'
        | 'AssignToMe'
        | 'NullState'
        | 'ChangeStatusPending';
    contacts: Contact[] | null;
    changeAssigneeContent: 'contacts' | 'filter' | null;
    filters: ChangeAssigneeFilterView[] | null;
    hasFilters: boolean;
    isChangeAssigneeButtonDisabled: boolean;
    hasMoreContacts: boolean;
    isLoading: boolean;
    activeContactId: number | null;
    searchValue: string;
    isTaskDetailsOpen: boolean;
    secondaryActions: SecondaryTaskAction[];
}

export const mapStateToProps = (state: AppState): MapStateToPropsReturn => {
    return {
        type: getType(state),
        contacts: getContacts(state),
        changeAssigneeContent: getChangeAssigneContent(state),
        filters: getFilterViews(state),
        hasFilters: getHasFilters(state),
        isChangeAssigneeButtonDisabled: getIsChangeAssigneeButtonDisabled(state),
        hasMoreContacts: getHasMoreContacts(state),
        isLoading: getIsLoading(state),
        activeContactId: getActiveContactId(state),
        searchValue: getSearchQuery(state),
        isTaskDetailsOpen: !!getTaskDetailViewTaskId(state),
        secondaryActions: getSecondaryTaskActions({
            ...(getTaskPermissionsInfo(state) as TaskPermissionsInfo),
        }),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    closeActionPopup: tasksActionsCreator.closeActionPopup,
    changeActiveContactId: tasksActionsCreator.changeActiveContactId,
    changeAssignee: tasksActionsCreator.changeAssignee,
    assignToMe: tasksActionsCreator.assignToMe,
    unAssign: tasksActionsCreator.unAssign,
    displayChangeAssigneeContent: tasksActionsCreator.displayChangeAssigneeContent,
    displayChangeAssigneeFilters: tasksActionsCreator.displayChangeAssigneeFilters,
    changeSearchValue: tasksActionsCreator.changeSearchQuery,
    toggleFilterDropdownIsOpen: tasksActionsCreator.toggleFilterDropdownIsOpen,
    filterDropdownSelectItem: tasksActionsCreator.filterDropdownSelectItem,
    filterDropdownChangeInput: tasksActionsCreator.filterDropdownChangeInput,
    resetAllFilters: tasksActionsCreator.resetAllFilters,
    applyChangeAssigneeFilters: tasksActionsCreator.applyChangeAssigneeFilters,
    getCMViewValues: tasksActionsCreator.getCMViewValues,
});
interface popoverProps {
    open: boolean;
    anchorElement: HTMLElement;
    closePopover: () => void;
}

export type ChangeAssigneePopoverProps = popoverProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ChangeAssigneePopover = memo(
    (props: ChangeAssigneePopoverProps): JSX.Element | null => {
        const {
            anchorElement,
            open,
            closePopover,
            closeActionPopup,
            changeActiveContactId,
            changeAssignee,
            assignToMe,
            unAssign,
            displayChangeAssigneeContent,
            displayChangeAssigneeFilters,
            applyChangeAssigneeFilters,
            changeSearchValue,
            toggleFilterDropdownIsOpen,
            filterDropdownSelectItem,
            filterDropdownChangeInput,
            resetAllFilters,
            getCMViewValues,
            type,
            contacts,
            changeAssigneeContent,
            filters,
            hasFilters,
            isChangeAssigneeButtonDisabled,
            hasMoreContacts,
            isLoading,
            activeContactId,
            searchValue,
            isTaskDetailsOpen,
            secondaryActions,
        } = props;

        if (!isTaskDetailsOpen) return null;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { t } = useTranslation();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [activeAction, setActiveAction] = useState('ChangeAssignee');

        const classes = actionStyles();
        const filterContentActive = changeAssigneeContent === 'filter';

        const secondaryActionsMap: Record<string, string> = secondaryActions.reduce(
            (a: Record<number, boolean>, action) => ({
                ...a,
                [action]: action,
            }),
            {},
        );
        const onClose = () => {
            if (filterContentActive) {
                displayChangeAssigneeContent();
                getCMViewValues(true);
            } else {
                closeActionPopup();
                closePopover();
            }
        };

        const onConfirm = () => {
            if (filterContentActive) {
                applyChangeAssigneeFilters();
                displayChangeAssigneeContent();
            } else {
                switch (activeAction) {
                    case 'ChangeAssignee': {
                        changeAssignee(null, true);
                        break;
                    }
                    case 'AssignToMe': {
                        assignToMe(null, true);
                        break;
                    }
                    case 'UnAssign': {
                        unAssign(null, true);
                        break;
                    }
                }
                closePopover();
            }
        };

        const onAssignToMeAction = () => {
            changeActiveContactId(0);
            setActiveAction('AssignToMe');
        };

        const onUnAssignAction = () => {
            changeActiveContactId(0);
            setActiveAction('UnAssign');
        };

        const onChangeAssigneeAction = (id: number) => {
            changeActiveContactId(id);
            setActiveAction('ChangeAssignee');
        };

        const infiniteScrollProps = {
            next: getCMViewValues,
            hasMore: hasMoreContacts,
            dataLength: contacts?.length || 0,
        };

        const stickyContent =
            type === 'ChangeAssignee' && !isLoading ? (
                <div className={classes.stickyContainer}>
                    {!filterContentActive ? (
                        <div className={classes.stickyContent}>
                            <SearchInput
                                searchValue={searchValue}
                                onSearchQueryChange={changeSearchValue}
                                className={classes.searchBox}
                            />
                            {hasFilters ? (
                                <FilterButton
                                    onFilterClick={displayChangeAssigneeFilters}
                                    className={classes.filterButtonIcon}
                                />
                            ) : undefined}
                        </div>
                    ) : (
                        <div className={classes.filtersSticky}>
                            <ChangeAssigneeFilterTitle
                                contactsFiltered={contacts?.length}
                                hasMoreContacts={hasMoreContacts}
                                onFiltersReset={resetAllFilters}
                            />
                        </div>
                    )}
                </div>
            ) : undefined;

        return (
            <PopoverDialog
                anchorEl={anchorElement}
                open={open}
                onClose={onClose}
                onCancel={onClose}
                onConfirm={onConfirm}
                confirmDisable={isChangeAssigneeButtonDisabled && activeAction === 'ChangeAssignee'}
                isLoading={
                    type === 'NullState' ||
                    (isLoading && !!secondaryActionsMap?.ChangeAssignee && contacts?.length === 0)
                }
                infiniteScroll={
                    changeAssigneeContent === 'contacts' ? infiniteScrollProps : undefined
                }
                stickyContent={stickyContent}
                {...(filterContentActive
                    ? { confirmLabel: t('TaskFilters.ApplyFiltersButton') }
                    : null)}
            >
                {filterContentActive ? (
                    <ChangeAssigneeFilter
                        filters={filters}
                        toggleFilterDropdownIsOpen={toggleFilterDropdownIsOpen}
                        filterDropdownSelectItem={filterDropdownSelectItem}
                        filterDropdownChangeInput={filterDropdownChangeInput}
                    />
                ) : (
                    <Grid container={true} className={classes.contentContainer}>
                        <div className={classes.content}>
                            {secondaryActionsMap?.AssignToMe ? (
                                <ChangeAssigneeControl
                                    key="AssignToMe"
                                    id="AssignToMe"
                                    isChecked={activeAction === 'AssignToMe'}
                                    name={t('TaskActions.AssignToMe.Label')}
                                    onChange={onAssignToMeAction}
                                    data-testid="change-assignee-popover-action_AssignToMe"
                                    accent
                                />
                            ) : null}
                            {secondaryActionsMap?.UnAssign ? (
                                <ChangeAssigneeControl
                                    key="UnAssign"
                                    id="UnAssign"
                                    isChecked={activeAction === 'UnAssign'}
                                    name={t('TaskActions.UnAssign.Label')}
                                    onChange={onUnAssignAction}
                                    data-testid="change-assignee-popover-action_UnAssign"
                                    accent
                                />
                            ) : null}
                            {secondaryActionsMap?.ChangeAssignee &&
                            (secondaryActionsMap?.UnAssign || secondaryActionsMap?.AssignToMe) ? (
                                <div
                                    className={classes.separateLine}
                                    data-testid="change-assignee-popover-action_separate_line"
                                />
                            ) : null}
                            {secondaryActionsMap?.ChangeAssignee ? (
                                <ChangeAssigneeContent
                                    contacts={contacts}
                                    activeContactId={activeContactId}
                                    changeActiveContactId={onChangeAssigneeAction}
                                />
                            ) : null}
                        </div>
                    </Grid>
                )}
            </PopoverDialog>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAssigneePopover);
