import React from 'react';
import {
    ButtonLink,
    ItemCellText,
    ItemCellLoader,
    Popover,
    PopoverButton,
    PopoverSearchContainerPortal,
    Typography,
} from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { ChangeAssigneeFilterView } from '../../../redux/tasksActions/tasksActions.types';

import { actionStyles, changeAssigneeStyles } from '../ActionStyles.styles';

export interface ChangeAssigneeFilterProps {
    filters: ChangeAssigneeFilterView[] | null;
    toggleFilterDropdownIsOpen: (filterId: number) => void;
    filterDropdownSelectItem: (filterId: number, selectedIndex: number | null) => void;
    filterDropdownChangeInput: (filterId: number, value: string | null) => void;
}

export const ChangeAssigneeFilter = (props: ChangeAssigneeFilterProps): JSX.Element => {
    const {
        filters,
        toggleFilterDropdownIsOpen,
        filterDropdownSelectItem,
        filterDropdownChangeInput,
    } = props;
    const classes = actionStyles();
    const { t } = useTranslation();

    const onToggleDropdown = (filterId: number) => () => toggleFilterDropdownIsOpen(filterId);

    const onSelectItem = (filterId: number) => (value: number) =>
        filterDropdownSelectItem(filterId, value);

    const onChangeInput = (filterId: number) => (inputValue: string | null) =>
        filterDropdownChangeInput(filterId, inputValue);

    const popoverStyles = { zIndex: 1 };

    const dropdowns = filters?.map((filter: ChangeAssigneeFilterView) => {
        const onSearch = onChangeInput(filter.id);
        const onClear = () => onSearch(null);
        return (
            <div
                key={filter.columnId}
                className={classes.filter}
                data-testid={`change-assignee-filter_${filter.title}`}
            >
                <Popover
                    popupId={filter.title}
                    onOpen={onToggleDropdown(filter.id)}
                    onClose={onToggleDropdown(filter.id)}
                    disablePortal={true}
                    popoverStyles={popoverStyles}
                    renderToggleButton={({ toggleButtonProps, isOpen, toggleButtonRef }) => (
                        <PopoverButton
                            {...toggleButtonProps}
                            textValue={filter.selectedItem || filter.title}
                            isOpen={isOpen}
                            ref={toggleButtonRef}
                        />
                    )}
                >
                    {({ toggleButtonWidth, popupState }) => {
                        const onItemClick = (value: number) => {
                            popupState.close();
                            onSelectItem(filter.id)(value);
                        };
                        return (
                            <PopoverSearchContainerPortal
                                searchValue={filter.inputValue || ''}
                                onSearchValueChange={onSearch}
                                onClearSearch={onClear}
                                width={toggleButtonWidth}
                                clearIconTitle={t('ChangeLanguageDropdown.ClearIconTitle')}
                            >
                                <div className={classes.popoverItemsContainer}>
                                    {filter.items.map((item, i) => (
                                        <ItemCellText
                                            key={item.formattedValue}
                                            label={item.formattedValue}
                                            value={i}
                                            checked={i === filter.selectedItemIndex}
                                            onClick={onItemClick}
                                        />
                                    ))}
                                    {filter.isLoading && <ItemCellLoader />}
                                </div>
                            </PopoverSearchContainerPortal>
                        );
                    }}
                </Popover>
            </div>
        );
    });

    return <div className={classes.filtersContainer}>{dropdowns}</div>;
};

export interface ChangeAssigneeFilterTitleProps {
    contactsFiltered: number | undefined;
    hasMoreContacts: boolean;
    onFiltersReset: () => void;
}

export const ChangeAssigneeFilterTitle = (props: ChangeAssigneeFilterTitleProps): JSX.Element => {
    const { contactsFiltered, onFiltersReset, hasMoreContacts } = props;
    const { t } = useTranslation();
    const classes = changeAssigneeStyles();
    const contactsFound = hasMoreContacts ? `${contactsFiltered}+` : contactsFiltered;
    const filteredCounts =
        contactsFiltered === null ? null : (
            <Typography
                variant="body1"
                className={classes.foundFilters}
                data-testid="txt_change-assignee-filter_found"
            >
                {t('TaskActions.ChangeAssignee.Filter.ContactsFound', {
                    filteredCount: contactsFound,
                })}
            </Typography>
        );

    return (
        <>
            {filteredCounts}
            <ButtonLink
                underline="none"
                color="error"
                variant="body1"
                onClick={onFiltersReset}
                data-testid="btn_change-assignee-filter_reset-all"
            >
                {t('TaskActions.ChangeAssignee.Filter.ResetAll')}
            </ButtonLink>
        </>
    );
};
