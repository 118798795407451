import { createSelector } from 'reselect';
import { AppState } from '../AppState.types';

export const getAllTasksSelected = ({ tasksSelectedActions }: AppState): boolean =>
    tasksSelectedActions.isAllTasksSelected;

export const getExcludedTasksSelected = ({ tasksSelectedActions }: AppState): Array<number> =>
    tasksSelectedActions.exclude;

export const getIncludedTasksSelected = ({ tasksSelectedActions }: AppState): Array<number> =>
    tasksSelectedActions.include;

export const getTasksSelected = createSelector(
    getAllTasksSelected,
    getExcludedTasksSelected,
    getIncludedTasksSelected,
    (isAllTasksSelected, exclude, include) => {
        if (isAllTasksSelected && exclude.length > 0) return { exclude };
        if (include.length > 0) return { include };
        return null;
    },
);

export const getSelectedTasksMap = createSelector(getIncludedTasksSelected, include => {
    const map: Record<number, boolean> = {};
    for (const taskId of include) {
        map[taskId] = true;
    }
    return map;
});

export default {
    getSelectedTasksMap,
    getTasksSelected,
    getIncludedTasksSelected,
    getExcludedTasksSelected,
    getAllTasksSelected,
};
