import { Converter } from '../location.types';

export const startFormConverter: Converter = {
    stateToURL: state => {
        if (state.startForm) {
            return { query: { startForm: 'true' } };
        }
    },
    URLToState: url => {
        const startForm = !!url.query.startForm?.[0];
        return { startForm };
    },
};
