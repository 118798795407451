import moment from 'moment';

moment.updateLocale('hi', {
    preparse(string: string) {
        return string;
    },
    postformat(string: string) {
        return string;
    },
});

moment.updateLocale('my', {
    preparse(string: string) {
        return string;
    },
    postformat(string: string) {
        return string;
    },
});

moment.updateLocale('km', {
    preparse(string: string) {
        return string;
    },
    postformat(string: string) {
        return string;
    },
});

moment.updateLocale('ta', {
    preparse(string: string) {
        return string;
    },
    postformat(string: string) {
        return string;
    },
});
