import { BACK_PATH } from 'worldapp-fe-utils/build/RoutingMiddleware/routingMiddleware';
import { Converter } from '../location.types';

export const isBackUrlConverter: Converter = {
    URLToState: url => {
        return {
            isBackUrl:
                (url.path.endsWith(BACK_PATH) || url.path.endsWith(`${BACK_PATH}/`)) && !url.hash,
        };
    },
    stateToURL: () => undefined,
};
