import React from 'react';
import { createStyles, makeStyles, PickersDate, DefaultPopup } from 'worldapp-ui/shared';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DefaultPopupProps } from 'worldapp-ui/shared/popup/DefaultPopup';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { defaultPopupButtonsDirectionOptions } from 'worldapp-ui/shared/popup/components/DefaultPopupFooter';
import { tasksActionsCreator } from '../../redux/tasksActions/tasksActions';
import { getLanguage } from '../../redux/localization/localizationSelector';
import { isContentCategoryTasks } from '../../redux/content/contentSelector';
import {
    getBulkActionsTasks,
    getTasksActionsState,
} from '../../redux/tasksActions/taskActionsSelector';
import { getTaskDetailViewTaskId } from '../../redux/activeCard/activeCardSelectors';
import {
    ACTION_POPUP_DESKTOP_WIDTH,
    TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
} from '../../constants/ui.constants';
import Calendar from '../Common/Calendar';

const styles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
        },
    }),
);

interface CalendarContentProps {
    date: PickersDate | null;
    onChange: (date: PickersDate) => void;
    language: string;
}

export const CalendarContent = ({
    date,
    onChange,
    language,
}: CalendarContentProps): JSX.Element => (
    <div className={styles().container}>
        <Calendar value={date} onChange={onChange} language={language} />
    </div>
);

export const ChangeDueDate = (): JSX.Element | null => {
    const dispatch = useDispatch();

    const isTasks = useSelector(isContentCategoryTasks);
    const language = useSelector(getLanguage);
    const tasksActions = useSelector(getTasksActionsState, shallowEqual);
    const isTaskDetailsOpen = useSelector(getTaskDetailViewTaskId);
    const bulkActionsTasks = useSelector(getBulkActionsTasks);

    const { t } = useTranslation();

    const onClose = () => dispatch(tasksActionsCreator.closeActionPopup());

    const onConfirm = () =>
        bulkActionsTasks
            ? dispatch(tasksActionsCreator.setDueDateInBulk())
            : dispatch(tasksActionsCreator.setDueDate());

    const changeCurrentDueDate = (date: Moment) =>
        dispatch(tasksActionsCreator.changeCurrentDueDate(date));

    if (isTasks && tasksActions.type === 'ChangeDueDate' && !isTaskDetailsOpen) {
        const dueDate = bulkActionsTasks
            ? tasksActions.bulkUpdates.dueDate
            : tasksActions.task?.dueDate;

        const popupProps: DefaultPopupProps = {
            id: 'ChangeDueDatePopup',
            open: true,
            contentDesktopWidth: ACTION_POPUP_DESKTOP_WIDTH,
            header: {
                title: t('TaskActions.ChangeDueDate.Label'),
            },
            footer: {
                confirmButton: {
                    label: t('TaskActions.ChangeDueDate.ConfirmButtonTitle'),
                    clickHandler: onConfirm,
                },
                cancelButton: {
                    label: t('TaskActions.ChangeDueDate.CloseButtonTitle'),
                    clickHandler: onClose,
                },
                mobileButtonsDirection: defaultPopupButtonsDirectionOptions.ROW,
            },
            showBlockingLoader: tasksActions.isLoading,
            className: TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
        };

        return (
            <DefaultPopup {...popupProps}>
                <CalendarContent
                    date={dueDate}
                    onChange={changeCurrentDueDate}
                    language={language}
                />
            </DefaultPopup>
        );
    }

    return null;
};
export default ChangeDueDate;
