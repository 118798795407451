import { createSelector } from 'reselect';
import { AppState } from '../AppState.types';
import { Navigation, TasksView } from './menu.types';
import { ActivePanelType } from '../../constants/navigation.constants';
import { getTabs } from '../portalSettings/portalSettingsSelector';
import {
    Category,
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from '../content/category.types';

export const getNavigation = ({ menu }: AppState): Navigation => menu.navigation;

export const getIsDrawerHoverOpen = ({ menu }: AppState): boolean => menu.isDrawerHoverOpen;

export const getIsDrawerPermanentOpen = ({ menu }: AppState): boolean => menu.isDrawerPermanentOpen;

export const getIsInitialized = ({ menu }: AppState): boolean => menu.isInitialized;

export const getIsDrawerOpened = createSelector(
    getIsDrawerHoverOpen,
    getIsDrawerPermanentOpen,
    (isDrawerHoverOpen, isDrawerPermanentOpen) => isDrawerHoverOpen || isDrawerPermanentOpen,
);

export const getTasksView = ({ menu }: AppState): TasksView => menu.tasksView;

export const getIsCalendarView = ({ menu }: AppState): boolean => menu.tasksView === 'calendar';

export const getActiveBottomPanel = ({ menu }: AppState): ActivePanelType => menu.activeBottomPanel;

export const getDefaultCategory = createSelector(getTabs, (tabs): Category | null => {
    if (tabs.tasks) return CONTENT_CATEGORY_TASKS;
    if (tabs.forms) return CONTENT_CATEGORY_FORMS;
    return tabs.dashboards ? CONTENT_CATEGORY_DASHBOARDS : null;
});

export const getAcitveMenuCategory = ({ menu }: AppState): Category | null =>
    menu.navigation.activeCategory;

export default {
    getNavigation,
    getDefaultCategory,
    getIsInitialized,
    getAcitveMenuCategory,
};
