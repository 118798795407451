import { GoToURLAction } from '../goToURL/goToURL.types';
import { ApiGW } from '../../types/DTO/api-gw';
import { GoToVotingAction } from '../voting/voting.types';

export const CHANGE_USERNAME = 'Login/CHANGE_USERNAME';
export const CHANGE_PORTALID = 'Login/CHANGE_PORTALID';
export const CHANGE_PASSWORD = 'Login/CHANGE_PASSWORD';
export const PASSWORD_CHANGED = 'Login/PASSWORD_CHANGED';
export const CHANGE_LOGIN_STATUS = 'Login/CHANGE_LOGIN_STATUS';
export const ADD_ERROR = 'Login/ADD_ERROR';
export const IS_LOADING = 'Login/IS_LOADING';
export const LOGIN = 'Login/LOGIN';
export const SSO_LOGOUT = 'Login/SSO_LOGOUT';
export const REDIRECT = 'Login/REDIRECT';
export const LOGIN_OPTIONS_FETCHED = 'Login/LOGIN_OPTIONS_FETCHED';
export const CHANGE_PORTAL_VERSION = 'Login/CHANGE_PORTAL_VERSION';
export const PAGE_SHOW = 'Login/PAGE_SHOW';

export const LOGIN_PAGE_URL = '/online/';

export type LoginOptions = ApiGW.AuthSettingsDTO extends { type: infer T } ? T : never;

export enum LoginStatus {
    Loading = 'Loading',
    Portal = 'Portal',
    Login = 'Login',
    ChangePassword = 'ChangePassword',
    ResetPassword = 'ResetPassword',
    BackToLogin = 'BackToLogin',
    LoggedIn = 'LoggedIn',
    Shutdown = 'Shutdown',
    LogoutPending = 'LogoutPending',
    LoginToDifferentPortal = 'LoginToDifferentPortal',
    FailedSSOLogin = 'FailedSSOLogin',
}
export type PortalVersion = 'new' | 'old';
interface ChangeUsernameAction {
    type: typeof CHANGE_USERNAME;
    username: string;
}
interface ChangePortalIdAction {
    type: typeof CHANGE_PORTALID;
    portalId: PortalId;
}
interface ChangePasswordAction {
    type: typeof CHANGE_PASSWORD;
    password: string;
}
interface PasswordChangedAction {
    type: typeof PASSWORD_CHANGED;
    username: string;
}
interface ChangeLoginStatusAction {
    type: typeof CHANGE_LOGIN_STATUS;
    loginStatus: LoginStatus;
}

interface AddErrorAction {
    type: typeof ADD_ERROR;
    errorMessage: string;
}

interface IsLoadingAction {
    type: typeof IS_LOADING;
    isLoading: boolean;
}

interface AuthAction {
    type: typeof LOGIN;
    temporary: boolean;
}
interface SSOLogoutAction {
    type: typeof SSO_LOGOUT;
    link: string;
}
interface RedirectAction {
    type: typeof REDIRECT;
}
interface LoginOptionsFetched {
    type: typeof LOGIN_OPTIONS_FETCHED;
    options: LoginOptions;
}

interface ChangePortalVersion {
    type: typeof CHANGE_PORTAL_VERSION;
    version: PortalVersion;
}

interface PageShow {
    type: typeof PAGE_SHOW;
}

export type LoginAction =
    | AddErrorAction
    | ChangeUsernameAction
    | ChangePortalIdAction
    | ChangePasswordAction
    | ChangeLoginStatusAction
    | IsLoadingAction
    | AuthAction
    | PasswordChangedAction
    | GoToURLAction
    | SSOLogoutAction
    | RedirectAction
    | LoginOptionsFetched
    | GoToVotingAction
    | ChangePortalVersion
    | PageShow;

export interface LoginState {
    portalId: PortalId;
    username: string;
    password: string;
    loginStatus: LoginStatus;
    beforeShutdownLoginStatus: LoginStatus | null;
    errorMessage: string;
    isLoading: boolean;
    nextStepEnabled: boolean;
    redirectUrl?: string;
    SSOLogoutLink?: string;
    loginOptions?: LoginOptions;
    newResponseParams?: Record<string, string[]>;
    portalVersion?: PortalVersion;
    failedSSOLogin?: string;
}

export type PortalId = number | null;

export type LogoutInfo = {
    afterLogoutLink: string;
};
