import React, { FunctionComponent, useCallback } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, MenuItem, Icon, Text, resolution } from 'worldapp-ui/shared';
import { useSelector, shallowEqual } from 'react-redux';
import styles from './Languages.styles';
import {
    getAvailableLanguages,
    getImpersonateMode,
} from '../../../redux/portalSettings/portalSettingsSelector';
import { getLanguage } from '../../../redux/localization/localizationSelector';
import { Language } from '../../../localization/localizationDefaultData';
import { useActionCreators } from '../../../hooks/useActionCreators';
import i18n from '../../../localization/i18n';
import { ActivePanelType } from '../../../constants/navigation.constants';

interface LanguagesProps extends WithStyles<typeof styles> {
    isDrawerOpen: boolean;
}

export const Languages: FunctionComponent<LanguagesProps> = ({
    classes,
    isDrawerOpen,
}: LanguagesProps) => {
    const availableLanguages = useSelector(getAvailableLanguages, shallowEqual);
    const selectedLanguage = useSelector(getLanguage);
    const impersonateMode = useSelector(getImpersonateMode);

    const { userCreator, menuCreator, toastsCreator } = useActionCreators();

    const onClick = useCallback(
        (lang: Language) => () => {
            if (lang !== selectedLanguage && !impersonateMode) {
                userCreator.changeUserLanguage(lang);
                i18n.changeLanguage(lang);
                menuCreator.onBottomMenuClick(ActivePanelType.Main);
            } else if (impersonateMode) {
                toastsCreator.createImpersonateMessageToast();
            }
        },
        [selectedLanguage, impersonateMode, userCreator, menuCreator, toastsCreator],
    );

    return (
        <div
            className={classNames(classes.languagesContainer, {
                [classes.languagesContainerClosed]: !isDrawerOpen,
            })}
            role="menu"
            tabIndex={0}
        >
            {availableLanguages.map(lang => {
                const isSelected = lang.value === selectedLanguage;
                const name = isDrawerOpen ? lang.name : lang.name.slice(0, 2);
                return (
                    <MenuItem
                        key={lang.name}
                        className={classNames(
                            classes.menuItem,
                            !isDrawerOpen && classes.closed,
                            isSelected && classes.selected,
                            isSelected && !isDrawerOpen && classes.closedAndSelected,
                        )}
                        selected={isSelected}
                        value={lang.value}
                        onClick={onClick(lang.value)}
                        aria-label={isDrawerOpen ? undefined : name}
                    >
                        {isDrawerOpen && (
                            <Icon
                                name="bullet-point"
                                mode={isSelected ? 'fill' : 'stroke'}
                                size="l"
                                className={classes.iconBullet}
                            />
                        )}
                        <Text variant={isSelected ? 'r14m' : 'r14r'} size={resolution.loRes}>
                            {name}
                        </Text>
                    </MenuItem>
                );
            })}
        </div>
    );
};

export default withStyles(styles)(Languages);
