import { ApiGW } from '../../types/DTO/api-gw';
import { AppState } from '../AppState.types';
import { Category } from '../content/category.types';
import { Dashboard, Response, Task, TasksContentTab } from '../content/content.types';
import { isFetchedContent } from '../content/content.utils';
import { getVotingCreatedItemId } from '../voting/votingSelector';
import { TaskHistoryItem } from './activeCard.types';
import { dateToDateString, dateToDateTimeString } from '../../utils/dateFilter.utils';

const CUSTOM_HISTORY_ITEM = 'CUSTOM';

const selectActiveCardIdForCategory =
    (category: Category) =>
    (state: AppState): number | null => {
        const { activeCard } = state;
        if (activeCard?.category !== category) {
            return null;
        }
        const itemId = Number(activeCard?.cardId);
        return itemId || null;
    };

export const selectActiveTaskId = selectActiveCardIdForCategory('tasks');

export const selectActiveResponseId = selectActiveCardIdForCategory('forms');

export const selectActiveCardId = (state: AppState): number | string | null => {
    const { activeCard } = state;
    if (activeCard?.category === 'dashboards' || activeCard?.category == null) {
        return activeCard?.cardId ?? null;
    }
    return selectActiveCardIdForCategory(activeCard?.category)(state);
};

export const getTaskDetailViewTaskId = ({ activeCard }: AppState): null | number => {
    if (activeCard?.taskDetailView && typeof activeCard?.cardId === 'number') {
        return activeCard?.cardId;
    }
    return null;
};

export const getActiveCardPosition = (state: AppState): number => {
    const activeCardId = getVotingCreatedItemId(state) || selectActiveCardId(state);
    if (activeCardId == null || !isFetchedContent(state.content)) {
        return 0;
    }
    const activeCardIndex = state.content.items.findIndex(
        ({ id }: Task | Response | Dashboard) => id === activeCardId,
    );
    return activeCardIndex === -1 ? 0 : activeCardIndex;
};

export const getTaskContentTab = (state: AppState): TasksContentTab =>
    state.activeCard?.taskContentTab ?? TasksContentTab.Details;

export const getTaskHistory = (state: AppState): TaskHistoryItem[] | undefined =>
    state.activeCard?.taskHistory;

const historyItemToValueString = (
    field: ApiGW.TaskHistoryItemDTO,
    current: boolean,
): string | undefined => {
    const v = current ? 'current' : 'previous';
    switch (field.type) {
        case 'SUMMARY':
        case 'DESCRIPTION':
        case 'STATUS':
            return field[v];
        case 'CONTACT':
        case 'ORIGINATOR':
            return field[v] ? field[v].name : undefined;
        case 'DUEDATE':
            return field[v] ? dateToDateString(field[v]) : undefined;
        default:
            return field[v] as string;
    }
};

const historyItemToLabel = (item: ApiGW.TaskHistoryItemDTO): string | undefined => {
    if (item.type === CUSTOM_HISTORY_ITEM) return item.customProperty.title;
};

export const prepareTaskHistoryItems = (items: ApiGW.TaskHistoryDTOV3[] = []): TaskHistoryItem[] =>
    items
        .map((item, index) => ({
            created: index === items.length - 1 && item.items[0].previous === undefined,
            ...item,
        }))
        .map(item =>
            item.created
                ? [item]
                : item.items.map(hItem => ({
                      created: item.created,
                      user: item.user,
                      updateDate: item.updateDate,
                      items: [hItem],
                  })),
        )
        .flat()
        .map(item => {
            const { created } = item;
            const user = item.user.name;
            const date = dateToDateTimeString(item.updateDate);
            const key = `${created}_${new Date(item.updateDate).getTime()}_${item.items[0].type}`;
            const res: TaskHistoryItem = created
                ? {
                      key,
                      created,
                      user,
                      date,
                      fields: item.items.map(field => ({
                          type: field.type,
                          label: historyItemToLabel(field),
                          value: historyItemToValueString(field, true),
                      })),
                  }
                : {
                      key,
                      created,
                      user,
                      date,
                      type: item.items[0].type,
                      label: historyItemToLabel(item.items[0]),
                      previous: historyItemToValueString(item.items[0], false),
                      current: historyItemToValueString(item.items[0], true),
                  };
            return res;
        });

export default {
    selectActiveCardIdForCategory,
    selectActiveTaskId,
    selectActiveCardId,
    getTaskDetailViewTaskId,
    getActiveCardPosition,
};
