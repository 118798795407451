import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import LoginPage from './components/LoginPage/LoginPage';
import MainContainer from './components/MainContainer';
import { useActionCreators } from './hooks/useActionCreators';
import { isLoggedIn } from './redux/login/loginSelector';
import { getImpersonateMode } from './redux/portalSettings/portalSettingsSelector';

export const MainRouter = (): JSX.Element => {
    const { initialLoaderCreator, loginCreator } = useActionCreators();
    useEffect(() => {
        initialLoaderCreator.onFirstLoad();
    }, [initialLoaderCreator]);

    const authenticated = useSelector(isLoggedIn);
    const impersonateMode = useSelector(getImpersonateMode);

    const beforeUnload = useCallback(async () => {
        await loginCreator.logout();
        return null;
    }, [loginCreator]);

    useEffect(() => {
        if (impersonateMode) {
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, [beforeUnload, impersonateMode]);

    const onPageShow = useCallback(event => {
        if (event.persisted) {
            // reload if pressed back button in browser
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('pageshow', onPageShow);
        return () => window.removeEventListener('pageshow', onPageShow);
    }, [onPageShow]);

    useEffect(() => {
        const showPageHandler = () => {
            loginCreator.handleShowPage();
        };
        window.addEventListener('pageshow', showPageHandler);
        return () => {
            window.removeEventListener('pageshow', showPageHandler);
        };
    }, [loginCreator]);

    return authenticated ? <MainContainer /> : <LoginPage />;
};
