import { RecursivePartial, WithOptional } from 'worldapp-fe-utils';
import { ApiGW } from '../../types/DTO/api-gw';
import { ApiDashboards } from '../../types/DTO/dashboards';
import { ResponseQuickFilters } from '../contentQuickFilters/contentQiuckFilters.types';
import { GoToVotingAction } from '../voting/voting.types';
import {
    Category,
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from './category.types';

export const CONTENT_REQUEST_LIMIT = 100;

export const CONTENT_CLEAR = 'Content/CLEAR';
export const CONTENT_META_CHANGE = 'Content/META_CHANGE';
export const CONTENT_META_INITIALIZE = 'Content/META_INITIALIZE';
export const CONTENT_LOADING = 'Content/LOADING';
export const CONTENT_LOADED = 'Content/LOADED';
export const CONTEXT_SEARCH_QUERY_CHANGE = 'Content/CONTEXT_SEARCH_QUERY_CHANGE';
export const TOGGLE_IS_LOADING_HEADER = 'Content/TOGGLE_IS_LOADING_HEADER';
export const SET_CONTENT_INITIAL_STATE = 'Content/SET_CONTENT_INITIAL_STATE';
export const FORMS_CONTENT_INITIALIZE = 'Content/FORMS_CONTENT_INITIALIZE';

export type Response = ApiGW.ResponseDTO;
export type Task = Omit<
    WithOptional<ApiGW.TaskDTOV3, 'dueDate' | 'assignee' | 'taskPermissions'>,
    'assigneeId' // deprecated, use assignee.id
>;

export type Dashboard = ApiDashboards.DashboardDTO;

export interface ClearContentAction {
    type: typeof CONTENT_CLEAR;
    clearSearchQuery: boolean;
}

export interface LoadingContentAction {
    type: typeof CONTENT_LOADING;
    category: Category;
    itemId: number;
}

export interface LoadedContentAction {
    type: typeof CONTENT_LOADED;
    result: ResponsesOrTasksOrDashboards;
}

export interface ChangeContentMetaAction extends ContentMeta {
    type: typeof CONTENT_META_CHANGE;
}

export interface InitializeContentMetaAction extends ContentMeta {
    type: typeof CONTENT_META_INITIALIZE;
}

export interface ChangeContextSearchQueryAction {
    type: typeof CONTEXT_SEARCH_QUERY_CHANGE;
    value: string;
}

export interface ToggleIsLoadingHeaderAction {
    type: typeof TOGGLE_IS_LOADING_HEADER;
}

export interface SetContentInitialStateAction {
    type: typeof SET_CONTENT_INITIAL_STATE;
}

export interface FormsContentInitializeAction {
    type: typeof FORMS_CONTENT_INITIALIZE;
}

export type ContentAction =
    | ChangeContentMetaAction
    | ClearContentAction
    | LoadingContentAction
    | LoadedContentAction
    | ChangeContextSearchQueryAction
    | InitializeContentMetaAction
    | ToggleIsLoadingHeaderAction
    | GoToVotingAction
    | SetContentInitialStateAction
    | FormsContentInitializeAction;

export interface ContentMeta {
    category: Category;
    itemId: number | null;
}

export interface Loadable {
    isLoading: boolean;
    loadingItem?: {
        category: Category;
        itemId: number | null;
    };
    offset: number;
    hasMore: boolean;
    total: number;
}

export interface Searchable {
    searchQuery: string;
}

export interface Responses extends ContentMeta, Loadable, Searchable {
    category: typeof CONTENT_CATEGORY_FORMS;
    items: Response[];
}
export interface Tasks extends ContentMeta, Loadable, Searchable {
    category: typeof CONTENT_CATEGORY_TASKS;
    items: Task[];
    isLoadingHeader: boolean;
}
export interface Dashboards extends ContentMeta, Loadable, Searchable {
    category: typeof CONTENT_CATEGORY_DASHBOARDS;
    items: Dashboard[];
}

export type Content = Responses | Tasks | Dashboards;
export type ContentState = Content | null;

export interface FetchParams {
    limit: number;
    offset: number;
}

export interface FetchTasksParams {
    category: typeof CONTENT_CATEGORY_TASKS;
    params: RecursivePartial<ApiGW.TasksSearchDTOV2>;
    taskDefinitionId: number;
}

export interface FetchResponsesParams extends FetchParams {
    category: typeof CONTENT_CATEGORY_FORMS;
    filter: ResponseFilterData;
}

export interface FetchDashboardsParams extends FetchParams {
    category: typeof CONTENT_CATEGORY_DASHBOARDS;
    searchQuery: string;
}

export type FetchContentParams = FetchTasksParams | FetchResponsesParams | FetchDashboardsParams;

export interface ResponseFilterData extends ResponseQuickFilters {
    formId: number;
}

export type ResponsesOrTasksOrDashboards = { itemId: number } & (
    | { category: typeof CONTENT_CATEGORY_TASKS; to: ApiGW.TasksDTOV3 }
    | { category: typeof CONTENT_CATEGORY_FORMS; to: ApiGW.ResponsesDTOV3 }
    | { category: typeof CONTENT_CATEGORY_DASHBOARDS; to: ApiDashboards.DashboardsDTO }
);

export type JustifyValue = 'center' | 'flex-start';

export enum TasksContentTab {
    Details = 'Details',
    History = 'History',
}
