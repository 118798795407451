import React, { FC, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    DndContext,
    DragEndEvent,
    useDraggable,
    useDroppable,
    useSensor,
    MouseSensor,
    useSensors,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonLink, Text } from 'worldapp-ui/shared';
import useResizeObserver from '@react-hook/resize-observer';
import { CALENDAR_VALUES, useTaskTableViewStyles } from './TaskCalendar.styles';
import { getTasks, isContentLoading } from '../../../redux/content/contentSelector';
import { Task } from '../../../redux/content/content.types';
import TaskCalendarHeader from './TaskCalendarHeader';
import {
    getCalendarView,
    getFirstDayOfCalendar,
    getLastDayOfCalendar,
    getWeekendsEnabled,
} from '../../../redux/calendar/calendarSelector';
import { PlaceholderBlock } from '../../Placeholders/PlaceholderBlock';
import { CalendarTaskCard } from './CalendarTaskCard';
import { tasksActionsCreator } from '../../../redux/tasksActions/tasksActions';
import { getTask } from '../../../redux/tasksActions/taskActionsSelector';
import {
    getAssignDateFilterDates,
    getDueDateRangeFilterDates,
} from '../../../redux/content/fetchTasksSelector';
import { DateRange, isDateInFilterRange } from '../../../utils/dateFilter.utils';
import { activeCardCreator } from '../../../redux/activeCard/activeCard';
import { CalendarView } from '../../../redux/calendar/calendar.types';
import { getLanguage } from '../../../redux/localization/localizationSelector';
import { calendarCreator } from '../../../redux/calendar/calendar';
import TaskCalendarFilters from './TaskCalendarFilters';
import NoDueDate from './NoDueDate';
import { isRequireDueDate } from '../../../redux/taskDefinitions/taskDefinitionsSelector.reselect';
import Calendar from '../../Common/Calendar';

const MAX_TASKS_PER_DAY = 5;

interface DroppableTdProps {
    id: number;
    date: Moment;
    inDueDateRange?: boolean;
    inAssignDateRange?: boolean;
    tasks: Task[];
    height: string | null;
    width: string | null;
}

interface DraggableTaskChipProps {
    task: Task;
}

const isDayWeekend = (day: number) => day === 0 || day === 6;

const calcTDClasses = (
    date: Moment,
    isOver: boolean,
    dueDateFilter: DateRange,
    assignDateFilter: DateRange,
    hasTask: boolean,
    calendarView: CalendarView,
    classes: ReturnType<typeof useTaskTableViewStyles>,
) => {
    const classList = [classes.td];
    const inDueDateRange = isDateInFilterRange(date, dueDateFilter);
    const inAssignDateRange = isDateInFilterRange(date, assignDateFilter);
    if (isOver) {
        classList.push(classes.tdIsOver);
    } else if (inDueDateRange && inAssignDateRange) {
        classList.push(classes.tdInDueAndAssignDateRange);
    } else if (inDueDateRange) {
        classList.push(classes.tdInDueDateRange);
    } else if (inAssignDateRange) {
        classList.push(classes.tdInAssignDateRange);
    }
    if (isDayWeekend(date.day())) {
        classList.push(classes.weekendDay);
    }
    if (calendarView === 'day') {
        classList.push(classes.dayTbodyTd);
    } else if (calendarView === 'week') {
        classList.push(classes.weekTbodyTd);
    } else if (calendarView === 'month') {
        classList.push(classes.monthTbodyTd);
    }
    return classNames(...classList);
};

export const DraggableTaskChip: FC<DraggableTaskChipProps> = ({ task }) => {
    const dispatch = useDispatch();
    const calendarView = useSelector(getCalendarView);
    const onTaskCardClick = () => {
        dispatch(activeCardCreator.openTaskDetailView(task.id));
    };
    const classes = useTaskTableViewStyles();
    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
        id: task.id,
        data: { taskId: task.id, task },
    });
    const style = {
        transform: CSS.Translate.toString(transform),
    };
    return (
        <div
            style={{ ...style }}
            {...listeners}
            {...attributes}
            key={task.id}
            ref={setNodeRef}
            className={classNames(classes.taskChip, {
                [classes.taskChipDragging]: isDragging,
                [classes.taskChipMonth]: calendarView === 'month',
                [classes.taskChipWeek]: calendarView === 'week',
                [classes.taskChipDay]: calendarView === 'day',
            })}
            onClick={onTaskCardClick}
            data-testid={`taskChipDragging_${task.id}`}
        >
            <CalendarTaskCard task={task} disableTootip={isDragging} />
        </div>
    );
};

export const DroppableTd: FC<DroppableTdProps> = ({ id, date, tasks, height, width }) => {
    const { t } = useTranslation();
    const classes = useTaskTableViewStyles();
    const { setNodeRef, isOver } = useDroppable({ id, data: { date } });
    const dueDateFilter = useSelector(getDueDateRangeFilterDates);
    const assignDateFilter = useSelector(getAssignDateFilterDates);
    const [expanded, setExpanded] = useState(false);
    const isToday = moment().isSame(date, 'day');
    const isLoading = useSelector(isContentLoading);
    const weekendsEnabled = useSelector(getWeekendsEnabled);

    const calendarView = useSelector(getCalendarView);
    let style = {};
    let noTasksStyle = {};
    if (calendarView !== 'month' && height) {
        style = { height: `calc(${height} - 1px)` };
    }
    if (calendarView !== 'month' && width) {
        noTasksStyle =
            calendarView === 'day'
                ? {
                      width: `calc(${width} - ${CALENDAR_VALUES.CALENDAR_CONTAINER_WIDTH}px)`,
                  }
                : {
                      width: weekendsEnabled ? `calc(${width} / 7)` : `calc(${width} / 5)`,
                      height: `calc(${height} - 38px)`,
                  };
    }

    const hasTask = tasks.length > 0;

    return (
        <td
            className={calcTDClasses(
                date,
                isOver,
                dueDateFilter,
                assignDateFilter,
                hasTask,
                calendarView,
                classes,
            )}
            ref={setNodeRef}
            style={{ ...style }}
            data-testid="task-calendar-table-body-td"
        >
            {calendarView === 'month' && (
                <>
                    <div
                        className={
                            isToday ? classNames(classes.data, classes.dataToday) : classes.data
                        }
                    >
                        <Text variant={isToday ? 'r14m' : 'r14r'}>{date.format('D')}</Text>
                    </div>

                    {tasks.slice(0, MAX_TASKS_PER_DAY).map(task => (
                        <DraggableTaskChip task={task} key={task.id} />
                    ))}
                    {expanded &&
                        tasks
                            .slice(MAX_TASKS_PER_DAY)
                            .map(task => <DraggableTaskChip task={task} key={task.id} />)}
                    {tasks.length > MAX_TASKS_PER_DAY && (
                        <ButtonLink
                            underline="none"
                            variant="body2"
                            onClick={() => setExpanded(!expanded)}
                            className={expanded ? classes.showLess : classes.showMore}
                        >
                            {expanded
                                ? t('TasksCalendar.ShowLess')
                                : t('TasksCalendar.ShowMore', {
                                      moreTasks: tasks.length - MAX_TASKS_PER_DAY,
                                  })}
                        </ButtonLink>
                    )}
                </>
            )}
            {isLoading && (
                <PlaceholderBlock
                    data-testid="placeholder-table-cell"
                    height={calendarView === 'month' ? 24 : 64}
                    className={
                        calendarView === 'day'
                            ? classNames(classes.loaderBlock, classes.loaderBlockDay)
                            : classes.loaderBlock
                    }
                />
            )}
            {!isLoading && calendarView !== 'month' && !hasTask && (
                <Text
                    variant={calendarView === 'week' ? 'r12r' : 'r18b'}
                    className={classNames({
                        [classes.emptyDayTextWeekView]: calendarView === 'week',
                        [classes.emptyDayTextDayView]: calendarView === 'day',
                    })}
                    style={{ ...noTasksStyle }}
                    data-testid="task-calendar-table-body-td-noTasks"
                >
                    {t('TasksCalendar.NoTasks')}
                </Text>
            )}
            {!isLoading &&
                calendarView !== 'month' &&
                hasTask &&
                tasks.map(task => <DraggableTaskChip task={task} key={task.id} />)}
        </td>
    );
};

export const TasksCalendar = (): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);
    const contentDiv = ref.current;
    const { t } = useTranslation();
    const classes = useTaskTableViewStyles();
    const tasks = useSelector(getTasks);
    const draftTask = useSelector(getTask);
    const calendarView = useSelector(getCalendarView);
    const language = useSelector(getLanguage);
    const dispatch = useDispatch();
    const [tableWidth, setTableWidth] = useState(0);
    const [tableHeight, setTableHeight] = useState(0);
    const weekendsEnabled = useSelector(getWeekendsEnabled);
    const firstDayOfCalendar = moment(useSelector(getFirstDayOfCalendar));
    const lastDayOfCalendar = useSelector(getLastDayOfCalendar);
    const dueDateAvailable = useSelector(isRequireDueDate);
    const weeks = Array.from({ length: calendarView === 'month' ? 6 : 1 }, (_, week) => {
        return Array.from({ length: 7 }, (__, day) =>
            firstDayOfCalendar.clone().add(week * 7 + day, 'days'),
        ).filter(date => weekendsEnabled || !isDayWeekend(date.day()));
    });
    let calendarTasks: Task[];
    if (calendarView !== 'day') {
        calendarTasks = tasks.filter(
            task => task.dueDate && moment(task.dueDate).isBetween(weeks[0][0], lastDayOfCalendar),
        );
    } else {
        calendarTasks = tasks.filter(
            task => task.dueDate && moment(task.dueDate).isSame(firstDayOfCalendar, 'day'),
        );
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const taskId: number = event.active.data.current?.taskId;
        const task: Task = event.active.data.current?.task;
        const date: Moment = event.over?.data.current?.date;
        if (moment(task.dueDate).isSame(date, 'day')) return;
        dispatch(tasksActionsCreator.openConfirmChangeDatePopup(taskId, date));
    };

    const updateTableSize = useCallback(() => {
        const newHeight = contentDiv?.offsetHeight
            ? Math.max(
                  contentDiv.offsetHeight -
                      (CALENDAR_VALUES.HEADER_HEIGHT +
                          CALENDAR_VALUES.HEADER_MARGIN_BOTTOM +
                          CALENDAR_VALUES.SUBHEADER_HEIGHT),
                  CALENDAR_VALUES.MIN_TABLE_HEIGHT,
              )
            : tableHeight;

        const newWidth = contentDiv?.offsetWidth
            ? Math.max(
                  contentDiv.offsetWidth - 2 * CALENDAR_VALUES.LEFT_RIGHT_PADDING,
                  CALENDAR_VALUES.MIN_TABLE_WIDTH,
              )
            : tableWidth;
        setTableWidth(newWidth);
        setTableHeight(newHeight);
    }, [contentDiv, tableWidth, tableHeight]);

    const tableWidthPX = tableWidth ? `${tableWidth}px` : '';
    const tableHeightPX = tableHeight ? `${tableHeight}px` : '';

    useResizeObserver(contentDiv, () => updateTableSize());

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 5,
        },
    });
    const sensors = useSensors(mouseSensor);

    const getDayName = (i: number) =>
        (t('TasksCalendar.Days', { returnObjects: true }) as string[])[i];
    const tableHeader = Array.from({ length: 7 })
        .map((_, i) => firstDayOfCalendar.clone().add(i, 'days'))
        .map(date => ({
            isToday: moment().isSame(date, 'day'),
            text: getDayName(date.day()) + (calendarView === 'month' ? '' : ` ${date.format('D')}`),
            weekend: isDayWeekend(date.day()),
        }))
        .filter(el => weekendsEnabled || !el.weekend);

    const onChangeDate = (date: Moment) => {
        dispatch(calendarCreator.setSelectedDate(date.toDate()));
    };

    const tasksByDate = (date: moment.Moment) => {
        const res = calendarTasks.filter(
            task =>
                (task.id !== draftTask?.id || task === draftTask) &&
                moment(task.dueDate).isSame(date, 'day'),
        );
        if (
            draftTask &&
            moment(draftTask.dueDate).isSame(date, 'day') &&
            !res.find(task => task.id === draftTask!.id)
        )
            res.push(draftTask);
        return res;
    };

    return (
        <div className={classes.root} data-testid="task-calendar" ref={ref}>
            <TaskCalendarHeader />
            {dueDateAvailable ? (
                <>
                    <table
                        className={classNames(classes.table, classes.tableHeader)}
                        data-testid="task-calendar-table-header"
                    >
                        {calendarView !== 'day' && (
                            <thead>
                                <tr>
                                    {tableHeader.map((element, index) => {
                                        const classList = [classes.th];
                                        if (element.weekend) {
                                            classList.push(classes.weekendDay);
                                        }
                                        if (element.isToday && calendarView === 'week') {
                                            classList.push(classes.dataTodayWeek);
                                        }
                                        if (calendarView === 'week') {
                                            classList.push(classes.weekTheadTh);
                                        }
                                        return (
                                            <th className={classNames(...classList)} key={index}>
                                                <Text variant="r12r">{element.text}</Text>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                        )}
                        {calendarView === 'day' && (
                            <thead>
                                <tr>
                                    <th className={classes.dayTheadTh} />
                                    <td className={classes.dayTheadTd}>
                                        {firstDayOfCalendar.format('ddd D')}
                                    </td>
                                </tr>
                            </thead>
                        )}
                    </table>
                    <div
                        data-testid="task-calendar-table"
                        className={classNames(classes.mainTableContainer, {
                            [classes.mainTableContainerWeek]: calendarView === 'week',
                        })}
                        style={{ maxHeight: tableHeightPX }}
                        tabIndex={0}
                    >
                        {calendarView !== 'day' && (
                            <table
                                className={classNames(classes.table, classes.tableBody)}
                                style={{ width: tableWidthPX }}
                            >
                                <tbody>
                                    <DndContext
                                        autoScroll
                                        sensors={sensors}
                                        onDragEnd={handleDragEnd}
                                        modifiers={[restrictToFirstScrollableAncestor]}
                                    >
                                        {weeks.map((week, i) => (
                                            <tr key={i}>
                                                {week.map(date => (
                                                    <DroppableTd
                                                        key={date.format()}
                                                        id={date.unix()}
                                                        date={date}
                                                        height={tableHeightPX}
                                                        width={tableWidthPX}
                                                        tasks={tasksByDate(date)}
                                                    />
                                                ))}
                                            </tr>
                                        ))}
                                    </DndContext>
                                </tbody>
                            </table>
                        )}
                        {calendarView === 'day' && (
                            <div
                                className={classes.dayViewContainer}
                                style={{ height: tableHeightPX }}
                            >
                                <div className={classes.dayViewContainerLeft}>
                                    <div className={classes.dayViewCalendarContainer}>
                                        <Calendar
                                            value={firstDayOfCalendar}
                                            onChange={onChangeDate}
                                            language={language}
                                        />
                                    </div>
                                    <div className={classes.dayViewFiltersContainer}>
                                        <TaskCalendarFilters />
                                    </div>
                                </div>
                                <div className={classes.dayViewTasksContainer}>
                                    <table className={classes.tableDay}>
                                        <tbody>
                                            <tr>
                                                <DroppableTd
                                                    key={firstDayOfCalendar.format()}
                                                    id={firstDayOfCalendar.unix()}
                                                    date={firstDayOfCalendar}
                                                    height={tableHeightPX}
                                                    width={tableWidthPX}
                                                    tasks={calendarTasks}
                                                />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <NoDueDate />
            )}
        </div>
    );
};
