import { createStyles, makeStyles, Theme, TransformedSelectionGroup } from 'worldapp-ui/shared';
import React from 'react';
import { connect } from 'react-redux';
import { TransformedSelectionGroupItem } from 'worldapp-ui/shared/selectionControls/TransformedSelectionGroup';
import { useTranslation } from 'react-i18next';
import {
    ASSIGN_DATE_FILTER_ID,
    ConfigurableFilterType,
    Filter,
    FilterItem,
    FilterMeta,
} from '../../redux/tasksConfigurableFilters/tasksConfigurableFilters.types';
import { DateFilter, DateFilterOption, DateFilterRange } from './DateFilter';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { tasksConfigurableFiltersCreator } from '../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import {
    getActiveFilterById,
    getFilterMetaById,
    DEFAULT_CUSTOM_PROPERTIES_LIMIT,
} from '../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { AppState } from '../../redux/AppState.types';
import { NumberFilter, NumberFilterRange } from './NumberFilter';
import { getLanguage } from '../../redux/localization/localizationSelector';

interface ConfigurableFilterOwnProps {
    filterId: number | string;
}

export interface ConfigurableFilterProps extends ConfigurableFilterOwnProps {
    filter: Filter | null;
    filterId: number | string;
    filterMeta: FilterMeta | null;
    language: string;
    changeDateFilterRange: (filterId: number | string, range: DateFilterRange) => void;
    changeNumberFilterRange: (filterId: number | string, range: NumberFilterRange) => void;
    clearFilterValues: (filterId: number | string) => void;
    toggleStringOrItemFilterValue: (filterId: number | string, value: string | number) => void;
    getTaskPropertyValues: (
        taskPropertyId: number,
        limit: number,
        offset: number,
        resetItems: boolean,
        query?: string,
    ) => void;
    getAssigneeFilterMeta: () => void;
}

export const EMPTY_ITEM_LABEL = 'TaskFilters.EmptyItemLabel';

/* istanbul ignore next */
const mapStateToProps = (state: AppState, ownProps: ConfigurableFilterOwnProps) => {
    return {
        filter: getActiveFilterById(state, ownProps.filterId),
        filterMeta: getFilterMetaById(state, ownProps.filterId),
        language: getLanguage(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    changeDateFilterRange: tasksConfigurableFiltersCreator.changeDateFilterRange,
    changeNumberFilterRange: tasksConfigurableFiltersCreator.changeNumberFilterRange,
    clearFilterValues: tasksConfigurableFiltersCreator.clearFilterValues,
    toggleStringOrItemFilterValue:
        tasksConfigurableFiltersCreator.toggleStringOrItemFilterValueIsChecked,
    getTaskPropertyValues: tasksConfigurableFiltersCreator.getTaskPropertyValues,
    getAssigneeFilterMeta: tasksConfigurableFiltersCreator.getAssigneeFilterMeta,
});

const useConfigurableFilterStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterSection: {
            paddingTop: 16,
            color: theme.palette.neutral.main,
        },
    }),
);

export const ConfigurableFilterComponent = ({
    filter,
    filterId,
    filterMeta,
    language,
    changeDateFilterRange,
    changeNumberFilterRange,
    clearFilterValues,
    toggleStringOrItemFilterValue,
    getTaskPropertyValues,
    getAssigneeFilterMeta,
}: ConfigurableFilterProps): JSX.Element | null => {
    const classes = useConfigurableFilterStyles();
    const { t } = useTranslation();

    let testType: string;
    if (filterMeta === null) return null;

    const handleStringFilterChange = (item: TransformedSelectionGroupItem) =>
        toggleStringOrItemFilterValue(filterId, item.value);

    const handleStringFilterClear = () => clearFilterValues(filterId);

    const handleDateFilterChange = (range: DateFilterRange) =>
        changeDateFilterRange(filterId, range);

    const handleNumberFilterChange = (range: NumberFilterRange) => {
        changeNumberFilterRange(filterId, range);
    };

    if (
        filterMeta.type === ConfigurableFilterType.String ||
        filterMeta.type === ConfigurableFilterType.Item
    ) {
        const loadMoreValues = (query?: string) => {
            let { offset } = filterMeta;

            const resetItems = query === undefined ? false : filterMeta.query !== query;
            if (resetItems) {
                offset = 0;
            }
            getTaskPropertyValues(
                Number(filterId),
                DEFAULT_CUSTOM_PROPERTIES_LIMIT,
                offset,
                resetItems,
                query,
            );
        };

        const items =
            filterMeta.type === ConfigurableFilterType.String
                ? filterMeta.items.map((item: string) => {
                      const isChecked =
                          !!filter &&
                          filter.type === ConfigurableFilterType.String &&
                          filter.items.includes(item);
                      return {
                          label: item === '' ? t(EMPTY_ITEM_LABEL) : item,
                          value: item,
                          checked: isChecked,
                      };
                  })
                : filterMeta.items.map((item: FilterItem) => {
                      const isChecked =
                          !!filter &&
                          filter.type === ConfigurableFilterType.String &&
                          filter.items.includes(item.id);
                      return {
                          label: item.label === '' ? t(EMPTY_ITEM_LABEL) : item.label,
                          value: item.id,
                          checked: isChecked,
                      };
                  });
        const clearLabel = t('TaskFilters.StringFilter.ClearLabel');
        if (items.length > 20) {
            testType = 'dropdown';
        } else if (items.length > 6) {
            testType = 'collapsible';
        } else {
            testType = 'string';
        }

        const infiniteScroll = {
            next: loadMoreValues,
            hasMore: filterMeta.hasMoreItems,
            dataLength: items.length,
        };

        let onSearch: ((query?: string | undefined) => void) | undefined = loadMoreValues;

        if (filterMeta.id === 'assignee') {
            infiniteScroll.next = getAssigneeFilterMeta;
            onSearch = undefined;
        }
        if (filterMeta.id === 'taskDefinition') {
            onSearch = undefined;
        }

        return (
            <div
                className={classes.filterSection}
                key={filterMeta.label}
                data-testid={`tasks_filters-${filterMeta.label}`}
            >
                <div data-testid={`type_${testType}`}>
                    <TransformedSelectionGroup
                        label={filterMeta.label}
                        items={items}
                        onSelectItem={handleStringFilterChange}
                        onClear={
                            items.some(item => item.checked) ? handleStringFilterClear : undefined
                        }
                        placeholder={t('TaskFilters.StringFilter.ComboboxPlaceholder')}
                        multiSelectComboboxProps={{
                            noOptionsText: t('TaskFilters.StringFilter.ComboboxNoOptions'),
                            selectedText: t('TaskFilters.StringFilter.ComboboxSelectedText'),
                            popoverSearchContainerProps: {
                                searchPlaceholder: t('TaskFilters.StringFilter.SearchPlaceholder'),
                                clearIconTitle: t('TaskFilters.StringFilter.ClearIconTitle'),
                            },
                            selectionGroupHeaderProps: {
                                clearLabel,
                            },
                            onSearch,
                        }}
                        selectionGroupProps={{
                            defaultSelectionGroupProps: {
                                selectionGroupHeaderProps: {
                                    clearLabel,
                                },
                                collapsedViewProps: {
                                    showAllLabel: t('TaskFilters.StringFilter.CollapseShowAll'),
                                    hideLabel: t('TaskFilters.StringFilter.CollapseHide'),
                                },
                                loaderText: t('TaskFilters.StringFilter.Loading'),
                            },
                        }}
                        infiniteScroll={infiniteScroll}
                    />
                </div>
            </div>
        );
    }
    if (filterMeta.type === ConfigurableFilterType.Date) {
        const disableFutureDates = filterMeta.id === ASSIGN_DATE_FILTER_ID;
        const selectedRange =
            filter && filter.type === ConfigurableFilterType.Date
                ? filter.range
                : ({ option: DateFilterOption.None } as DateFilterRange);
        return (
            <div
                className={classes.filterSection}
                key={filterMeta.label}
                data-testid={`tasks_filters-${filterMeta.label}`}
            >
                <div data-testid="type_date">
                    <DateFilter
                        selectedRange={selectedRange}
                        onChange={handleDateFilterChange}
                        label={filterMeta.label}
                        language={language}
                        disableFutureDates={disableFutureDates}
                        dateFormat={filterMeta.dateFormat}
                    />
                </div>
            </div>
        );
    }
    if (filterMeta.type === ConfigurableFilterType.Number) {
        const selectedRange =
            filter && filter.type === ConfigurableFilterType.Number
                ? filter.range
                : { from: null, to: null };
        const showClearButton = selectedRange.from != null || selectedRange.to != null;
        return (
            <div
                className={classes.filterSection}
                key={filterMeta.label}
                data-testid={`tasks_filters-${filterMeta.label}`}
            >
                <div data-testid="type_number">
                    <NumberFilter
                        selectedRange={selectedRange}
                        onChange={handleNumberFilterChange}
                        label={filterMeta.label}
                        onClear={showClearButton ? handleStringFilterClear : undefined}
                    />
                </div>
            </div>
        );
    }
    return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurableFilterComponent);
