import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { placeholderStyles } from './Placeholder.styles';
import { PlaceholderBlock } from './PlaceholderBlock';
import { Category } from '../../redux/content/category.types';

interface CardPlaceholderProps {
    category: Category;
}

const CardPlaceholder: FunctionComponent<CardPlaceholderProps> = ({ category }) => {
    const classes = placeholderStyles();

    switch (category) {
        case 'dashboards':
            return (
                <div
                    className={classNames(classes.card, classes.dashboardCard)}
                    data-testid="placeholder-card-dashboard"
                >
                    <div className={classes.cardMain}>
                        <PlaceholderBlock height={92} />
                    </div>
                    <div className={classNames(classes.cardBottom, classes.cardBottomDashboards)}>
                        <PlaceholderBlock className={classes.cardMiddleBlock} />
                        <PlaceholderBlock width={208} />
                    </div>
                </div>
            );
        case 'forms':
            return (
                <div
                    className={classNames(classes.card, classes.formCard)}
                    data-testid="placeholder-card-form"
                >
                    <div className={classes.cardMain}>
                        <PlaceholderBlock height={20} width={104} />
                        <PlaceholderBlock className={classes.cardMiddleBlock} height={32} />
                        <PlaceholderBlock />
                    </div>
                    <div className={classes.cardBottom}>
                        <PlaceholderBlock width={208} />
                    </div>
                </div>
            );
        case 'tasks':
        default:
            return (
                <div
                    className={classNames(classes.card, classes.taskCard)}
                    data-testid="placeholder-card-task"
                >
                    <div className={classes.cardMain}>
                        <PlaceholderBlock height={20} />
                        <PlaceholderBlock className={classes.cardMiddleBlock} height={32} />
                        <PlaceholderBlock width={208} />
                    </div>
                    <div className={classes.cardBottom}>
                        <PlaceholderBlock width={208} />
                    </div>
                </div>
            );
    }
};

export default CardPlaceholder;
