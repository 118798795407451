import React from 'react';
import { connect } from 'react-redux';
import { DefaultPopup, Grid } from 'worldapp-ui/shared';
import { DefaultPopupProps } from 'worldapp-ui/shared/popup/DefaultPopup';
import { useTranslation } from 'react-i18next';
import { defaultPopupButtonsDirectionOptions } from 'worldapp-ui/shared/popup/components/DefaultPopupFooter';
import { tasksActionsCreator } from '../../../redux/tasksActions/tasksActions';
import {
    Contact,
    getType,
    getContacts,
    getChangeAssigneContent,
    getFilterViews,
    getHasFilters,
    getIsChangeAssigneeButtonDisabled,
    getHasMoreContacts,
    getIsLoading,
    getActiveContactId,
    getSearchQuery,
    getBulkActionsTasks,
} from '../../../redux/tasksActions/taskActionsSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { ChangeAssigneeFilterView } from '../../../redux/tasksActions/tasksActions.types';
import { ChangeAssigneeFilterTitle, ChangeAssigneeFilter } from './ChangeAssigneeFilter';
import { ChangeAssigneeContent, SearchInput, FilterButton } from './ChangeAssigneeContent';
import { AppState } from '../../../redux/AppState.types';
import { getTaskDetailViewTaskId } from '../../../redux/activeCard/activeCardSelectors';
import { changeAssigneeStyles } from '../ActionStyles.styles';
import { Task } from '../../../redux/content/content.types';
import {
    ACTION_POPUP_DESKTOP_WIDTH,
    TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
} from '../../../constants/ui.constants';

interface MapStateToPropsReturn {
    type:
        | 'ChangeAssignee'
        | 'ChangeStatus'
        | 'ChangeDueDate'
        | 'ConfirmChangeDueDate'
        | 'UnAssign'
        | 'AssignToMe'
        | 'NullState'
        | 'ChangeStatusPending';
    contacts: Contact[] | null;
    changeAssigneeContent: 'contacts' | 'filter' | null;
    filters: ChangeAssigneeFilterView[] | null;
    hasFilters: boolean;
    isChangeAssigneeButtonDisabled: boolean;
    hasMoreContacts: boolean;
    isLoading: boolean;
    activeContactId: number | null;
    searchValue: string;
    isTaskDetailsOpen: boolean;
    bulkActionsTasks: Task[] | null;
}

export const mapStateToProps = (state: AppState): MapStateToPropsReturn => {
    return {
        type: getType(state),
        contacts: getContacts(state),
        changeAssigneeContent: getChangeAssigneContent(state),
        filters: getFilterViews(state),
        hasFilters: getHasFilters(state),
        isChangeAssigneeButtonDisabled: getIsChangeAssigneeButtonDisabled(state),
        hasMoreContacts: getHasMoreContacts(state),
        isLoading: getIsLoading(state),
        activeContactId: getActiveContactId(state),
        searchValue: getSearchQuery(state),
        isTaskDetailsOpen: !!getTaskDetailViewTaskId(state),
        bulkActionsTasks: getBulkActionsTasks(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    closeActionPopup: tasksActionsCreator.closeActionPopup,
    changeActiveContactId: tasksActionsCreator.changeActiveContactId,
    changeAssignee: tasksActionsCreator.changeAssignee,
    displayChangeAssigneeContent: tasksActionsCreator.displayChangeAssigneeContent,
    displayChangeAssigneeFilters: tasksActionsCreator.displayChangeAssigneeFilters,
    changeSearchValue: tasksActionsCreator.changeSearchQuery,
    toggleFilterDropdownIsOpen: tasksActionsCreator.toggleFilterDropdownIsOpen,
    filterDropdownSelectItem: tasksActionsCreator.filterDropdownSelectItem,
    filterDropdownChangeInput: tasksActionsCreator.filterDropdownChangeInput,
    resetAllFilters: tasksActionsCreator.resetAllFilters,
    applyChangeAssigneeFilters: tasksActionsCreator.applyChangeAssigneeFilters,
    getCMViewValues: tasksActionsCreator.getCMViewValues,
    changeAssigneeInBulk: tasksActionsCreator.changeAssigneeInBulk,
});

export type ChangeAssigneeProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ChangeAssignee = (props: ChangeAssigneeProps): JSX.Element | null => {
    const {
        closeActionPopup,
        changeActiveContactId,
        changeAssignee,
        displayChangeAssigneeContent,
        displayChangeAssigneeFilters,
        applyChangeAssigneeFilters,
        changeSearchValue,
        toggleFilterDropdownIsOpen,
        filterDropdownSelectItem,
        filterDropdownChangeInput,
        resetAllFilters,
        getCMViewValues,
        type,
        contacts,
        changeAssigneeContent,
        filters,
        hasFilters,
        isChangeAssigneeButtonDisabled,
        hasMoreContacts,
        isLoading,
        activeContactId,
        searchValue,
        isTaskDetailsOpen,
        bulkActionsTasks,
        changeAssigneeInBulk,
    } = props;
    const { t } = useTranslation();
    const classes = changeAssigneeStyles();

    if (
        type !== 'ChangeAssignee' ||
        changeAssigneeContent === null ||
        contacts === null ||
        filters === null ||
        isTaskDetailsOpen
    )
        return null;

    let onClose: () => void;
    let onConfirm: () => void;
    let okButtonName: string;

    const closeButtonTitle = t('TaskActions.ChangeAssignee.Cancel');
    if (changeAssigneeContent === 'filter') {
        onClose = () => {
            displayChangeAssigneeContent();
            getCMViewValues(true);
        };

        onConfirm = () => {
            applyChangeAssigneeFilters();
            displayChangeAssigneeContent();
        };

        okButtonName = t('TaskActions.ChangeAssignee.Apply');
    } else {
        onClose = closeActionPopup;
        onConfirm = bulkActionsTasks ? changeAssigneeInBulk : changeAssignee;

        okButtonName = t('TaskActions.ChangeAssignee.Ok');
    }
    const confirmButtonProps = {
        disabled: isChangeAssigneeButtonDisabled,
    };

    let popupContent: JSX.Element = (
        <Grid container={true} spacing={1} direction="column">
            <ChangeAssigneeContent
                contacts={contacts}
                activeContactId={activeContactId}
                changeActiveContactId={changeActiveContactId}
            />
        </Grid>
    );

    let popupStickyContent: JSX.Element | undefined = (
        <div className={classes.titleContainer}>
            <SearchInput searchValue={searchValue} onSearchQueryChange={changeSearchValue} />
        </div>
    );
    let popupHeaderTitle: string = t('TaskActions.ChangeAssignee.ReassignTask');
    let popupHeaderAdditionalContent: JSX.Element | undefined = hasFilters ? (
        <FilterButton onFilterClick={displayChangeAssigneeFilters} />
    ) : undefined;

    if (changeAssigneeContent === 'filter') {
        popupContent = (
            <div className={classes.filtersTitleContainer}>
                <ChangeAssigneeFilter
                    filters={filters}
                    toggleFilterDropdownIsOpen={toggleFilterDropdownIsOpen}
                    filterDropdownSelectItem={filterDropdownSelectItem}
                    filterDropdownChangeInput={filterDropdownChangeInput}
                />
            </div>
        );
        popupStickyContent = undefined;
        popupHeaderTitle = t('TaskActions.ChangeAssignee.Filter.ContactFilters');
        popupHeaderAdditionalContent = (
            <ChangeAssigneeFilterTitle
                contactsFiltered={contacts.length}
                hasMoreContacts={hasMoreContacts}
                onFiltersReset={resetAllFilters}
            />
        );
    }

    const infiniteScrollProps = {
        next: getCMViewValues,
        hasMore: hasMoreContacts,
        dataLength: contacts.length,
    };

    const popupProps: DefaultPopupProps = {
        id: 'ChangeAssigneePopup',
        open: true,
        contentDesktopWidth: ACTION_POPUP_DESKTOP_WIDTH,
        showBlockingLoader: isLoading && contacts.length === 0,
        stickyContent: popupStickyContent,
        header: {
            title: popupHeaderTitle,
            headerAdditionalContent: popupHeaderAdditionalContent,
        },
        footer: {
            confirmButton: {
                label: okButtonName,
                clickHandler: onConfirm,
                buttonProps: confirmButtonProps,
            },
            cancelButton: {
                label: closeButtonTitle,
                clickHandler: onClose,
            },
            mobileButtonsDirection: defaultPopupButtonsDirectionOptions.ROW,
        },
        infiniteScroll: changeAssigneeContent === 'contacts' ? infiniteScrollProps : undefined,
        fixedHeight: true,
        className: TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
    };

    return <DefaultPopup {...popupProps}>{popupContent}</DefaultPopup>;
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAssignee);
