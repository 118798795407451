import React from 'react';
import { useSelector } from 'react-redux';
import TasksConfigurableFiltersPopup from './TasksConfigurableFiltersPopup';
import { getPopupStage } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { FiltersPopupStage } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters.types';
import EditFiltersSetPopup from './EditFiltersSetPopup';
import SaveFilterSetMessageBox from '../SaveFilterSetMessageBox';

export const TasksFiltersPopupHandler = (): JSX.Element | null => {
    const popupStage = useSelector(getPopupStage);
    const [isMessageBoxShown, setIsMessageBoxShown] = React.useState(false);
    if (popupStage === FiltersPopupStage.Closed) return null;
    let popup = null;

    const handleOpenMessageBox = () => {
        setIsMessageBoxShown(true);
    };

    const handleCloseMessageBox = () => {
        setIsMessageBoxShown(false);
    };

    if (
        popupStage === FiltersPopupStage.FiltersSetsTab ||
        popupStage === FiltersPopupStage.GeneralFiltersTab
    ) {
        popup = <TasksConfigurableFiltersPopup openMessageBox={handleOpenMessageBox} />;
    } else if (popupStage === FiltersPopupStage.EditSavedFilter) {
        popup = <EditFiltersSetPopup openMessageBox={handleOpenMessageBox} />;
    }

    return (
        <>
            {popup}
            {isMessageBoxShown && (
                <SaveFilterSetMessageBox isOpened={true} closeMessageBox={handleCloseMessageBox} />
            )}
        </>
    );
};
