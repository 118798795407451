import { createSelector } from 'reselect';
import { getTasks, isContentCategoryTasks } from '../content/contentSelector';
import { Task } from '../content/content.types';
import { getTaskDefinitionsById } from '../taskDefinitions/taskDefinitionsSelector';
import { getUserId } from '../user/userSelector';
import { TaskPermissionsInfo } from '../content/validateTaskActions';
import { getTaskDetailViewTaskId } from './activeCardSelectors';

export const getTaskDetailViewTask = createSelector(
    isContentCategoryTasks,
    getTasks,
    getTaskDetailViewTaskId,
    (isContentTasks, tasks, detailViewTaskId) => {
        if (!isContentTasks || !tasks || !detailViewTaskId) return null;
        const currentTaskDetailIIndex = (tasks as Task[]).findIndex(
            task => task.id === detailViewTaskId,
        );
        return tasks[currentTaskDetailIIndex]
            ? { ...tasks[currentTaskDetailIIndex], index: currentTaskDetailIIndex }
            : null;
    },
);

export const getTaskDetailViewTD = createSelector(
    getTaskDetailViewTask,
    getTaskDefinitionsById,
    (task, taskDefinitionsById) => {
        if (!task) return null;
        return taskDefinitionsById[task.taskDefinitionId] || null;
    },
);

export const getTaskPermissionsInfo = createSelector(
    getTaskDetailViewTask,
    getTaskDetailViewTD,
    getUserId,
    (task, taskDefinition, contactId) => {
        if (!task || !taskDefinition || !contactId) return null;
        return {
            task,
            taskDefinition,
            contactId,
        } as TaskPermissionsInfo;
    },
);
