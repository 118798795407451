import { RefObject } from 'react';

export const handleTableScroll = (
    tableRef: RefObject<HTMLDivElement>,
    isScrolled: boolean,
    left: number,
    changeIsScrolled: (b: boolean) => void,
    changeScrollLeft: (n: number) => void,
    loadContent: () => void,
    hasMore: boolean,
): void => {
    if (tableRef.current) {
        const { scrollTop, scrollLeft, scrollHeight, clientHeight } = tableRef.current;

        if (!isScrolled && scrollTop > 0) {
            changeIsScrolled(true);
        } else if (isScrolled && scrollTop === 0) {
            changeIsScrolled(false);
        }

        if (scrollLeft === left) {
            const isReachedBottom = scrollHeight - scrollTop < 2 * clientHeight;
            if (isReachedBottom && hasMore) {
                loadContent();
            }
        } else if (scrollLeft !== left) {
            changeScrollLeft(scrollLeft);
        }
    }
};
