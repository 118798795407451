import { createStyles, makeStyles, Theme } from 'worldapp-ui/shared';

interface MainStylesProps {
    impersonateMode: boolean;
}

const useMainStyles = makeStyles((theme: Theme) =>
    createStyles({
        app: {
            display: 'flex',
            flex: 1,
            height: ({ impersonateMode }: MainStylesProps) =>
                `calc(100% - ${impersonateMode ? theme.spacing(9) : theme.spacing(6)}px)`,
            position: 'relative',
        },
        content: {
            background: theme.palette.neutral[6],
            flexGrow: 1,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
        },
        contentSlide: {
            marginLeft: 0,
        },
        root: {
            height: '100%',
            overflow: 'hidden',
        },
    }),
);

export default useMainStyles;
