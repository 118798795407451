import React, { RefObject, useRef, useState, useLayoutEffect } from 'react';
import { createStyles, makeStyles, Text } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/browser.utils';

interface NothingFoundProps {
    label: string;
    hasSearchQuery?: boolean;
}
const loaderStyles = makeStyles(theme =>
    createStyles({
        wrapper: {
            color: theme.palette.neutral[65],
            padding: theme.spacing(5),
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            textAlign: 'center',
            '&[data-ismobile="true"]': {
                padding: theme.spacing(2.5),
            },
        },
    }),
);
const NothingFound = (props: NothingFoundProps): JSX.Element => {
    const refNothingFound = useRef(null) as RefObject<HTMLDivElement>;
    const { t } = useTranslation();

    const [offsetTop, setOffsetTop] = useState(0);

    useLayoutEffect(() => {
        setOffsetTop(refNothingFound.current?.getBoundingClientRect().top || 0);
    }, [refNothingFound]);

    useLayoutEffect(() => {
        function updateSize() {
            setOffsetTop(refNothingFound.current?.getBoundingClientRect().top || 0);
        }
        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const { label, hasSearchQuery } = props;
    const classes = loaderStyles({ offsetTop });
    return (
        <div
            className={classes.wrapper}
            data-testid={'nothing-found'}
            ref={refNothingFound}
            data-ismobile={isMobile}
        >
            <Text variant={isMobile ? 'r22b' : 'r30b'}>
                {hasSearchQuery ? t('ApplicationContent.NoItems') : label}
            </Text>
        </div>
    );
};

export default NothingFound;
