export const FEATURES_CHECKED = 'Features/FEATURES_CHECKED';

export const features = [
    'create_new_task',
    'online_in_iOS_opx202',
    'calendar_month_portal_opx142',
    'calendar_week_portal_opx142',
    'calendar_day_portal_opx142',
    'portal3_always_show_start_form',
    'TD_filtering_portal_opx484',
    'task_history_portal_opx488',
    'optimize_tasks_load_opx506',
    'optimize_forms_title_load_opx540',
    'portal_all_task_experiment_opx540',
];

export type Feature = typeof features[number];

export type FeaturesPayload = {
    [key in Feature]: boolean;
};

interface FeatureFetchedAction {
    type: typeof FEATURES_CHECKED;
    features: FeaturesPayload;
}

export type FeaturesAction = FeatureFetchedAction;

export type FeaturesState = FeaturesPayload | null;
