import { TaskDefinition } from '../taskDefinitions/taskDefinitions.types';
import {
    Content,
    ContentState,
    Dashboards,
    Responses,
    ResponsesOrTasksOrDashboards,
    Tasks,
    JustifyValue,
} from './content.types';
import {
    Category,
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from './category.types';
import { ApiDashboards } from '../../types/DTO/dashboards';
import { ApiGW } from '../../types/DTO/api-gw';
import { CARDS_PADDING, CARDS_WIDTHS } from '../../components/ui/cards/cardsSizes';
import { deviceType } from '../../utils/browser.utils';

export const allOpenStatuses = (taskDefinitions: TaskDefinition[]): number[] => {
    return taskDefinitions
        .flatMap(td => td.statuses)
        .filter(status => !status.closed)
        .map(status => status.id);
};

export const isFetchedTasks = (state: ContentState): state is Tasks =>
    !!state && state.category === CONTENT_CATEGORY_TASKS;

export const isFetchedResponses = (state: ContentState): state is Responses =>
    !!state && state.category === CONTENT_CATEGORY_FORMS;

export const isFetchedDashboards = (state: ContentState): state is Dashboards =>
    !!state && state.category === CONTENT_CATEGORY_DASHBOARDS;

export const isFetchedContent = (state: ContentState): state is Dashboards | Tasks | Responses =>
    !!state;

export const getFreshContent = (category: Category, itemId: number | null): Content => ({
    category,
    itemId,
    isLoading: false,
    items: [],
    offset: 0,
    hasMore: true,
    total: 0,
    searchQuery: '',
    isLoadingHeader: false,
});

export const getContentItems = (
    result: ResponsesOrTasksOrDashboards,
): ApiGW.TaskDTOV3[] | ApiGW.ResponseDTO[] | ApiDashboards.DashboardDTO[] => {
    return (result.category === 'tasks' ? result.to.tasks : result.to.items) ?? [];
};

export const getContentJustifyValue = (width: number, contentCategory: Category): JustifyValue => {
    if (width === 0 && deviceType === 'mobile') return 'center';
    return width < CARDS_WIDTHS[contentCategory] * 2 + CARDS_PADDING * 2 ? 'center' : 'flex-start';
};
