import { createStyles, makeStyles, Theme } from 'worldapp-ui';

const navigationTreeMenuItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItem: {
            color: theme.palette.neutral[75],
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '& $selected, &:hover': {
                color: theme.palette.primary.main,
                backgroundColor: 'transparent',
            },
            '&:focus': {
                backgroundColor: 'transparent',
            },
            boxSizing: 'border-box',
            minHeight: 48,
            padding: `
            ${theme.spacing()}px
            ${theme.spacing(2)}px
        `,
            position: 'relative',
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
        },
        menuItemClosed: {
            '& $selected, &:hover, &:focus': {
                backgroundColor: 'transparent',
            },
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        },
        menuItemText: {
            color: 'inherit',
        },
        menuItemTextContainer: {
            padding: 0,
        },
        selected: {
            '&:before': {
                backgroundColor: theme.palette.primary.main,
                borderBottomRightRadius: theme.shape.borderRadius,
                borderTopRightRadius: theme.shape.borderRadius,
                bottom: 0,
                boxShadow:
                    '0px 9px 24px rgba(21, 101, 192, 0.25), 0px 9px 24px rgba(21, 101, 192, 0.12)',
                content: '""',
                display: 'block',
                left: 0,
                position: 'absolute',
                top: 0,
                width: 4,
            },
            '& $icon, & $menuItemText': {
                color: theme.palette.primary.main,
                fontWeight: theme.typography.h5.fontWeight,
            },
            backgroundColor: 'transparent !important',
        },
        contentRoot: {
            '& $selected, &:hover': {
                '& $icon, & $menuItemText': {
                    color: theme.palette.primary.main,
                },
            },
            flexWrap: 'nowrap',
            alignItems: 'center',
        },
        icon: { flexShrink: 0 },
        menuItemTextIE: {
            maxWidth: theme.spacing(28),
        },
        peopleIcon: {
            margin: `0 ${theme.spacing()}px`,
        },
        counter: {
            marginLeft: theme.spacing(2),
        },
        iconWithOpenedMenu: {
            margin: '0px 4px 0px 12px',
        },
    }),
);

export default navigationTreeMenuItemStyles;
