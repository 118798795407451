import React from 'react';
import { connect } from 'react-redux';

import { createStyles, makeStyles, Text, Theme } from 'worldapp-ui';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { menuCreator } from '../../redux/menu/menu';

import { AppState } from '../../redux/AppState.types';

import { getNavigation } from '../../redux/menu/menuSelector';

import TasksList from './NavigationTree/TasksList/TasksList';
import NavigationSubMenu from './NavigationTree/NavigationSubMenu';
import { CONTENT_CATEGORY_FORMS, CONTENT_CATEGORY_TASKS } from '../../redux/content/category.types';
import { deviceType } from '../../utils/browser.utils';

import { GRID_MOBILE_PADDING } from '../ui/cards/cardsSizes';
import FormsList from './NavigationTree/FormsList/FormsList';

export const mapStateToProps = (state: AppState) => ({
    navigation: getNavigation(state),
});

const mapDispatchToProps = mapCreatorsToDispatchProps({ ...menuCreator });

export type NavigationPaneliOSProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & { onClose: () => void };

const navPaneliOSStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: GRID_MOBILE_PADDING,
            overflowY: 'auto',
            overflowX: 'hidden',
            minHeight: theme.spacing(6),
        },
        label: {
            marginLeft: GRID_MOBILE_PADDING,
        },
        labelTablet: {
            marginLeft: 28,
        },
    }),
);

export const NavigationPaneliOS = ({
    navigation,
    setActiveItem,
    onClose,
}: NavigationPaneliOSProps): JSX.Element => {
    const { t } = useTranslation();

    const onItemSelectHandler = (itemId: number | null) => {
        setActiveItem(itemId);
        onClose();
    };

    const classes = navPaneliOSStyles();

    const isTablet = deviceType === 'tablet';

    const content = (
        <>
            <Text
                variant="r22b"
                className={classNames(classes.label, { [classes.labelTablet]: isTablet })}
            >
                {t('NavigationPanel.Show')}
            </Text>
            <div className={classes.container}>
                {navigation.activeCategory === CONTENT_CATEGORY_TASKS && (
                    <TasksList
                        onItemSelect={onItemSelectHandler}
                        selectedItem={navigation.activeItemId}
                        iOSView={true}
                        data-testid="navigation-panel-iOs_tasks-list"
                    />
                )}
                {navigation.activeCategory === CONTENT_CATEGORY_FORMS && (
                    <FormsList
                        onItemSelect={onItemSelectHandler}
                        selectedItem={navigation.activeItemId}
                        iOSView={true}
                        data-testid="navigation-panel-iOs_forms-list"
                    />
                )}
            </div>
        </>
    );

    return !isTablet ? (
        <NavigationSubMenu
            onClickBack={onClose}
            isDrawerOpen
            backButtonLabel={t('NavigationPanel.BackButtonLabel')}
            withoutBorder
        >
            {content}
        </NavigationSubMenu>
    ) : (
        content
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationPaneliOS);
