import React from 'react';
import { Icon } from 'worldapp-ui';
import FormsList from '../components/NavigationPanel/NavigationTree/FormsList/FormsList';
import TasksList from '../components/NavigationPanel/NavigationTree/TasksList/TasksList';
import { NavigationTreeItem } from '../constants/navigation.constants';
import {
    Category,
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from '../redux/content/category.types';
import { Navigation, Tabs } from '../redux/menu/menu.types';

export function getCategoryByIndex(
    index: number,
    navigationItemsData: NavigationTreeItem[],
): Category {
    return navigationItemsData[index].category;
}

export const getSelectedItem = (category: string, navigation: Navigation): number | null =>
    navigation.activeCategory === category ? navigation.activeItemId : null;

interface NavigationItems {
    content: JSX.Element | null;
    icon: () => JSX.Element;
    id: 'tasks' | 'forms' | 'dashboards';
    title: string;
}

export const getNavigationItems = (
    item: NavigationTreeItem,
    tabs: Tabs,
    handleItemSelect: (itemId: number | null) => void,
    navigation: Navigation,
): NavigationItems => {
    switch (item.category) {
        case CONTENT_CATEGORY_TASKS: {
            const isActive = navigation.activeCategory === 'tasks';
            const mode = isActive ? 'fill' : 'stroke';
            const content = (
                <TasksList
                    onItemSelect={handleItemSelect}
                    selectedItem={getSelectedItem(CONTENT_CATEGORY_TASKS, navigation)}
                />
            );

            const icon = () => <Icon name="tasks" size="l" mode={mode} />;
            return {
                content,
                icon,
                id: item.category,
                title: tabs.tasks ? tabs.tasks.name : item.title,
            };
        }
        case CONTENT_CATEGORY_FORMS: {
            const isActive = navigation.activeCategory === 'forms';
            const mode = isActive ? 'fill' : 'stroke';
            const content = (
                <FormsList
                    onItemSelect={handleItemSelect}
                    selectedItem={getSelectedItem(CONTENT_CATEGORY_FORMS, navigation)}
                />
            );

            const icon = () => <Icon name="forms2" size="l" mode={mode} />;
            return {
                content,
                icon,
                id: item.category,
                title: tabs.forms ? tabs.forms.name : item.title,
            };
        }
        case CONTENT_CATEGORY_DASHBOARDS: {
            const isActive = navigation.activeCategory === 'dashboards';
            const mode = isActive ? 'fill' : 'stroke';
            const icon = () => <Icon name="dashboards" size="l" mode={mode} />;
            return {
                content: null,
                icon,
                id: item.category,
                title: tabs.dashboards ? tabs.dashboards.name : item.title,
            };
        }
    }
};
