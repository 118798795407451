import { ActivePanelType } from '../../constants/navigation.constants';
import { ApiGW } from '../../types/DTO/api-gw';
import { FetchTDSAction } from '../taskDefinitions/taskDefinitions.types';
import { FetchFormsAction } from '../forms/forms.types';
import { Category } from '../content/category.types';

export const TOGGLE_DRAWER_PERMANENT = 'Menu/TOGGLE_DRAWER_PERMANENT';
export const TOGGLE_DRAWER_HOVER = 'Menu/TOGGLE_DRAWER_HOVER';
export const SET_ACTIVE_BOTTOM_PANEL = 'Menu/SET_ACTIVE_BOTTOM_PANEL';
export const SET_ACTIVE_CATEGORY = 'Menu/SET_ACTIVE_CATEGORY';
export const SET_ACTIVE_ITEM = 'Menu/SET_ACTIVE_ITEM';
export const CHANGE_TASKS_VIEW = 'Menu/CHANGE_TASKS_VIEW';
export const CHANGE_ACTIVE_CATEGORY_EXPANDED = 'Menu/CHANGE_ACTIVE_CATEGORY_EXPANDED';
export const SET_MENU_INTIALIZED = 'Menu/SET_MENU_INTIALIZED';

interface ToggleDrawerPermanentAction {
    isOpen?: boolean;
    type: typeof TOGGLE_DRAWER_PERMANENT;
}

interface ToggleDrawerHoverAction {
    isOpen?: boolean;
    type: typeof TOGGLE_DRAWER_HOVER;
}

interface SetActiveBottomPanelAction {
    panel: ActivePanelType;
    type: typeof SET_ACTIVE_BOTTOM_PANEL;
}

export interface Navigation {
    activeCategory: Category | null;
    activeItemId: number | null;
    activeCategoryExpanded: boolean;
}

interface SetActiveCategory {
    category: Category | null;
    itemId: number | null;
    activeCategoryExpanded: boolean;
    type: typeof SET_ACTIVE_CATEGORY;
}

interface SetActiveItem {
    itemId: number | null;
    type: typeof SET_ACTIVE_ITEM;
}

export interface ChangeTasksViewAction {
    type: typeof CHANGE_TASKS_VIEW;
    view: TasksView;
}

interface ChangeActiveCategoryExpandedAction {
    type: typeof CHANGE_ACTIVE_CATEGORY_EXPANDED;
    activeCategoryExpanded: boolean;
}

interface SetMenuInitializedAction {
    type: typeof SET_MENU_INTIALIZED;
}

export type MenuAction =
    | ToggleDrawerPermanentAction
    | ToggleDrawerHoverAction
    | SetActiveBottomPanelAction
    | SetActiveCategory
    | SetActiveItem
    | ChangeTasksViewAction
    | FetchTDSAction
    | FetchFormsAction
    | ChangeActiveCategoryExpandedAction
    | SetMenuInitializedAction;

export interface MenuState {
    isInitialized: boolean;
    isDrawerPermanentOpen: boolean;
    isDrawerHoverOpen: boolean;
    activeBottomPanel: ActivePanelType;
    navigation: Navigation;
    tasksView: TasksView;
}

export type CustomLink = ApiGW.CustomLinkDTO;

export type Tab = Omit<ApiGW.TabDTO, 'defaultName'>;

export type Tabs = {
    [tab in Category]?: Tab;
};

export const tasksViews = ['cards', 'table', 'calendar'] as const;
export type TasksView = typeof tasksViews[number];
export const defaultTasksView: TasksView = 'cards';
