import { updateFavicons } from 'worldapp-fe-utils';
import { apiClient } from '../datalayer/ApiClient';

export const updateBrandedFavicons = (): void => {
    apiClient.getBrandInfo(null).then(brandInfo => {
        const favicons = brandInfo.favicons as unknown as {
            [index: string]: string;
        };
        updateFavicons(favicons);
    });
};
