import { Converter } from '../location.types';

export const rURLConverter: Converter = {
    stateToURL: ({ redirectURL }) => {
        if (redirectURL) {
            return { query: { rURL: encodeURIComponent(redirectURL) } };
        }
    },
    URLToState: ({ query }) => {
        const rURL = query.rURL?.[0];
        if (rURL) {
            return { redirectURL: decodeURIComponent(rURL) };
        }
    },
};
