import React, { useCallback, useState } from 'react';
import { Grid } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useResizeObserver from '@react-hook/resize-observer';
import DashboardCard from '../../ui/cards/DashboardCard';
import { DashboardCardsGridProps } from './CardsGrid.types';
import { getDashboardCards } from '../../../redux/content/contentSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { votingCreator } from '../../../redux/voting/voting';
import { AppState } from '../../../redux/AppState.types';
import NothingFound from '../../ui/nothingFound/NothingFound';
import {
    CARDS_MAX_WIDTHS,
    CARDS_PADDING,
    CARDS_WIDTHS,
    GRID_MOBILE_PADDING,
    GRID_PADDING,
} from '../../ui/cards/cardsSizes';
import { isMobile } from '../../../utils/browser.utils';
import { JustifyValue } from '../../../redux/content/content.types';
import { getContentJustifyValue } from '../../../redux/content/content.utils';

const mapDispatchToProps = mapCreatorsToDispatchProps({
    onCardClick: votingCreator.goToDashboards,
});

export const mapStateToProps = (state: AppState): DashboardCardsGridProps => {
    return {
        cards: getDashboardCards(state),
    };
};

type CardStateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        parentDiv: HTMLDivElement | null;
        justifyFunction: (a: JustifyValue) => void;
    };

export const DashboardCardsComponent = (props: CardStateProps): JSX.Element => {
    const { t } = useTranslation();
    const { cards, onCardClick, parentDiv, justifyFunction } = props;
    const gridPadding = isMobile ? GRID_MOBILE_PADDING : GRID_PADDING;
    const [parentWidth, setParentWidth] = useState(0);

    const updateParentWidth = useCallback(() => {
        const newParentWidth = parentDiv?.offsetWidth || 0;
        setParentWidth(newParentWidth);
        const newAlign = getContentJustifyValue(
            newParentWidth ? newParentWidth - 2 * GRID_PADDING : 0,
            'dashboards',
        );
        justifyFunction(newAlign);
    }, [parentDiv, justifyFunction]);

    useResizeObserver(parentDiv, () => updateParentWidth());

    const width = parentWidth || CARDS_WIDTHS.dashboards;
    const cardWidthWithPaddings = CARDS_WIDTHS.dashboards + CARDS_PADDING;
    const widthWithoutPaddings = width - 2 * gridPadding + CARDS_PADDING;
    const itemsPerRow = Math.floor(widthWithoutPaddings / cardWidthWithPaddings) || 1;
    let cardWidth = Math.min(
        widthWithoutPaddings / itemsPerRow - CARDS_PADDING,
        CARDS_MAX_WIDTHS.dashboards,
    );
    cardWidth = cardWidth < CARDS_WIDTHS.dashboards ? CARDS_WIDTHS.dashboards : cardWidth;
    if (cards.length === 0) {
        return <NothingFound label={t('DashboardCardsGrid.NoDashboards')} />;
    }

    return (
        <>
            {cards.map(card => {
                const onCardClickHandler = () => onCardClick(card.id);

                const cardProps = {
                    name: card.name,
                    searchQuery: card.searchQuery,
                    image: card.image,
                    created: card.created,
                    author: card.author,
                    onCardClick: onCardClickHandler,
                };

                return (
                    <Grid item={true} key={card.name}>
                        <DashboardCard
                            {...cardProps}
                            data-testid={`card-dashboard_id-${card.id}`}
                            width={cardWidth}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

export const DashboardCardsGrid = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardCardsComponent);
