import { createStyles, Theme, makeStyles, lighten } from 'worldapp-ui/shared';

export const actionStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingLeft: 0,
            margin: 0,
            marginLeft: -theme.spacing(1.5),
        },
        filtersContainer: {
            paddingLeft: 0,
            margin: 0,
            width: '100%',
        },
        item: {
            width: '100%',
        },
        labelRoot: {
            display: 'flex',
            margin: 0,
            borderRadius: theme.spacing(0.5),
            color: theme.palette.neutral[75],
            '&:hover': {
                background: lighten(theme.palette.primary.main, 0.9),
                color: theme.palette.neutral[75],
            },
        },
        label: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
        },
        labelChecked: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            color: theme.palette.primary.main,
        },
        labelDisabled: {
            color: theme.palette.neutral[65],
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
        },
        popoverItemsContainer: {
            maxHeight: theme.spacing(20),
            overflowY: 'auto',
        },
        filter: {
            marginBottom: 16,
        },
        stickyContainer: {
            flexShrink: 0,
            width: '100%',
            padding: '24px 24px 0 24px',
        },
        stickyContent: {
            display: 'flex',
            alignItems: 'center',
        },
        contentContainer: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
        },
        filtersSticky: {
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
        filterButtonIcon: {
            color: theme.palette.neutral[75],
            width: 24,
            minWidth: 24,
            height: 24,
        },
        searchBox: {
            height: 28,
            background: theme.palette.neutral[4],
            border: 'none',
            '& svg': {
                marginRight: 4,
            },
        },
        separateLine: {
            boxShadow: `inset 0px -1px 0px ${theme.palette.neutral[12]}`,
            margin: '6px 0',
            height: 1,
        },
        disabled: {
            color: theme.palette.neutral[65],
        },
    }),
);

export const changeAssigneeStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleContainer: {
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            boxShadow: `inset 0px -1px 0px ${theme.palette.neutral[12]}`,
        },
        filtersTitleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
        filterButton: {
            marginLeft: 10,
        },
        title: {
            lineHeight: `${theme.spacing(4)}px`,
            width: '100%',
        },
        foundFilters: {
            color: theme.palette.neutral[75],
            marginRight: theme.spacing(4),
        },
    }),
);
