/* eslint-disable import/no-cycle */
import { RecursivePartial } from 'worldapp-fe-utils';
import moment, { Moment } from 'moment';
import {
    getAppliedAssigneeFilter,
    getAppliedFiltersSet,
    getAppliedTDFilter,
} from '../tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import {
    DateRange,
    dateFilterOptionToRange,
    dateToString,
    savedDateRangeToDateRange,
} from '../../utils/dateFilter.utils';
import {
    ASSIGN_DATE_FILTER_ID,
    ConfigurableFilterType,
    DateRangeFilterID,
    DUE_DATE_FILTER_ID,
    STATUSES_FILTER_ID,
} from '../tasksConfigurableFilters/tasksConfigurableFilters.types';
import { DateFilterOption } from '../../components/ConfigurableFilters/DateFilter';
import { getTaskQuickFilters } from '../contentQuickFilters/contentQuickFiltersSelector';
import { TaskQuickFilters } from '../contentQuickFilters/contentQiuckFilters.types';
import { ApiGW } from '../../types/DTO/api-gw';
import { FetchTasksParams } from './content.types';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import { SortColumnType, Sorting } from '../tasksSort/tasksSort.types';
import { getUserId } from '../user/userSelector';
import { getActiveTaskDefinitions } from '../taskDefinitions/taskDefinitionsSelector.reselect';
import { getStatusToIdMap } from '../tasksConfigurableFilters/taskConfigurableFilters.utils';
import { getContextSearchQuery } from './contentSelector';
import { getCurrentTaskSort } from '../tasksSort/tasksSortSelector';
import { CONTENT_CATEGORY_TASKS } from './category.types';
import { allOpenStatuses } from './content.utils';
import { getAssigneeValues } from '../taskDefinitionAssignees/taskDefinitionAssigneesSelector';
import { AppState } from '../AppState.types';
import { TaskPropertyFilter, TaskPropertyRange } from '../../types/custom';
import { DATE_FORMAT } from '../frequentlyUsed/frequentlyUsed';
import { getIsCalendarView } from '../menu/menuSelector';
import { getFirstDayOfCalendar, getLastDayOfCalendar } from '../calendar/calendarSelector';

const getCustomPropertyIdToSort = (
    tasksSort: Sorting,
    state: AppState,
    taskDefinitionId: number,
): number | undefined => {
    if (tasksSort.column !== SortColumnType.Custom) {
        return;
    }
    return state.taskDefinitions.taskDefinitionsById[taskDefinitionId]?.customProperties.find(
        prop => prop.title === tasksSort.property,
    )?.id;
};

export const getPredefinedTaskFiltersFactory =
    (
        appliedFiltersSetSelector = getAppliedFiltersSet,
        taskQuickFiltersSelector = getTaskQuickFilters,
    ) =>
    (state: AppState): TaskQuickFilters => {
        const appliedFiltersSet = appliedFiltersSetSelector(state);
        const quickFilters = taskQuickFiltersSelector(state);

        let predefinedFilters: TaskQuickFilters = appliedFiltersSet
            ? appliedFiltersSet.predefined
            : quickFilters;

        if (
            !predefinedFilters.myTasks &&
            !predefinedFilters.teamTasks &&
            !predefinedFilters.unassignedTasks
        ) {
            predefinedFilters = {
                ...predefinedFilters,
                myTasks: true,
                teamTasks: true,
                unassignedTasks: true,
            };
        }

        return predefinedFilters;
    };
export const getPredefinedTaskFilters = getPredefinedTaskFiltersFactory();

export const getDateRangeFilterDatesFactory =
    (
        filterID: DateRangeFilterID,
        appliedFiltersSetSelector = getAppliedFiltersSet,
        dateFilterOptionToRangeHelper = dateFilterOptionToRange,
        taskQuickFiltersSelector = getTaskQuickFilters,
    ) =>
    (state: AppState): DateRange => {
        const quickFilters = taskQuickFiltersSelector(state);
        const appliedFiltersSet = appliedFiltersSetSelector(state);
        const predefinedFilters: TaskQuickFilters = appliedFiltersSet
            ? appliedFiltersSet.predefined
            : quickFilters;
        if (!appliedFiltersSet && !predefinedFilters.overdue) return {};

        const dateRangeFilter = appliedFiltersSet?.custom[filterID];
        if (!dateRangeFilter && predefinedFilters.overdue && filterID === DUE_DATE_FILTER_ID) {
            const yesterday = dateFilterOptionToRange(DateFilterOption.Yesterday).to as Date;
            return { to: yesterday };
        }
        if (
            !dateRangeFilter ||
            dateRangeFilter.type !== ConfigurableFilterType.Date ||
            (dateRangeFilter.range.option === DateFilterOption.Custom &&
                dateRangeFilter.range.from === null &&
                dateRangeFilter.range.to === null)
        )
            return {};

        const { from, to } =
            dateRangeFilter.range.option === DateFilterOption.Custom
                ? savedDateRangeToDateRange(
                      dateRangeFilter.range as {
                          from?: string | Moment;
                          to?: string | Moment;
                      },
                  )
                : dateFilterOptionToRangeHelper(dateRangeFilter.range.option);

        const [fromNormalized, toNormalized] = from && to && from > to ? [to, from] : [from, to];

        return {
            from: fromNormalized !== null ? fromNormalized : undefined,
            to: toNormalized !== null ? toNormalized : undefined,
        };
    };

export const getDueDateRangeFilterDates = getDateRangeFilterDatesFactory(DUE_DATE_FILTER_ID);
export const getAssignDateFilterDates = getDateRangeFilterDatesFactory(ASSIGN_DATE_FILTER_ID);

export const getDateRangeFilterParamsFactory = (
    filterID: DateRangeFilterID,
    appliedFiltersSetSelector = getAppliedFiltersSet,
    dateFilterOptionToRangeHelper = dateFilterOptionToRange,
    dateToStringHelper = dateToString,
    taskQuickFiltersSelector = getTaskQuickFilters,
): ((state: AppState) =>
    | {
          from?: string;
          to?: string;
      }
    | undefined) => {
    const dateRangeDates = getDateRangeFilterDatesFactory(
        filterID,
        appliedFiltersSetSelector,
        dateFilterOptionToRangeHelper,
        taskQuickFiltersSelector,
    );
    return (state: AppState): { from?: string; to?: string } | undefined => {
        let dateRange = dateRangeDates(state);

        const calendarView = getIsCalendarView(state);
        if (calendarView && filterID === DUE_DATE_FILTER_ID) {
            const calendarFrom = getFirstDayOfCalendar(state);
            const calendarTo = getLastDayOfCalendar(state);
            const newRange: DateRange = { from: dateRange.from, to: dateRange.to };

            if (!newRange.from || calendarFrom > newRange.from) {
                newRange.from = calendarFrom;
            }
            if (!newRange.to || calendarTo < newRange.to) {
                newRange.to = calendarTo;
            }
            if (newRange.to < newRange.from) {
                newRange.to = dateRange.from || dateRange.to;
                newRange.from = newRange.to; // Can't have empty range. Refactor to not make any requests instead.
            }
            dateRange = newRange;
        }
        if (!dateRange.from && !dateRange.to) return undefined;
        return {
            from: dateRange.from ? dateToStringHelper(dateRange.from) : undefined,
            to: dateRange.to ? dateToStringHelper(dateRange.to) : undefined,
        };
    };
};
export const getDueDateRangeFilterParams = getDateRangeFilterParamsFactory(DUE_DATE_FILTER_ID);
export const getAssignDateFilterParams = getDateRangeFilterParamsFactory(ASSIGN_DATE_FILTER_ID);

export const getCustomPropertiesFilterParamsFactory =
    (appliedFiltersSetSelector = getAppliedFiltersSet) =>
    (state: AppState): TaskPropertyFilter[] | undefined => {
        const appliedFiltersSet = appliedFiltersSetSelector(state);
        if (!appliedFiltersSet) return undefined;

        return Object.values(appliedFiltersSet.custom)
            .filter(f => Number.isInteger(f.id as any))
            .map(filter => {
                const filterParams = { property: filter.id as number };
                switch (filter.type) {
                    case ConfigurableFilterType.String: {
                        const items = filter.items.map(item =>
                            item === '' ? (null as any) : item,
                        );
                        return {
                            ...filterParams,
                            values: { include: items },
                        };
                    }
                    case ConfigurableFilterType.Number: {
                        return {
                            ...filterParams,
                            values: {
                                range: {
                                    from: filter.range.from as any,
                                    to: filter.range.to as any,
                                },
                            },
                        };
                    }
                    case ConfigurableFilterType.Date: {
                        const getFilterParams = getDateRangeFilterParamsFactory(
                            filter.id as number,
                        );
                        const range = getFilterParams(state);
                        return {
                            ...filterParams,
                            values: {
                                range: {
                                    from: range ? (range.from as any) : undefined,
                                    to: range ? (range.to as any) : undefined,
                                },
                            },
                        };
                    }
                    default: {
                        return { ...filterParams, values: { include: [] } };
                    }
                }
            });
    };
export const getCustomPropertiesFilterParams = getCustomPropertiesFilterParamsFactory();

export const getAssigneeIdsFromAppliedFiltersFactory =
    (
        appliedAssigneeFilterSelector = getAppliedAssigneeFilter,
        assigneeValuesSelector = getAssigneeValues,
    ) =>
    (state: AppState): number[] | null => {
        const assigneeFilterIds: number[] = [];
        const appliedAssigneeFilter = appliedAssigneeFilterSelector(state);
        if (!appliedAssigneeFilter) return null;

        const assigneeValues = assigneeValuesSelector(state);
        appliedAssigneeFilter.items.forEach(assigneeName => {
            const assigneeValue = assigneeValues.find(assignee => assignee.name === assigneeName);
            if (assigneeValue) {
                assigneeFilterIds.push(assigneeValue.contactId);
            }
        });
        return assigneeFilterIds;
    };
export const getAssigneeIdsFromAppliedFilters = getAssigneeIdsFromAppliedFiltersFactory();

export const getAssigneeFilterParamsFactory =
    (
        predefinedTaskFiltersSelector = getPredefinedTaskFilters,
        userIdSelector = getUserId,
        assigneeIdsFromAppliedFiltersSelector = getAssigneeIdsFromAppliedFilters,
    ) =>
    (state: AppState): any => {
        const assigneeFilterIds = assigneeIdsFromAppliedFiltersSelector(state);
        const include = assigneeFilterIds !== null ? assigneeFilterIds : [];
        const predefinedFilters = predefinedTaskFiltersSelector(state);
        const userId = userIdSelector(state);

        if (assigneeFilterIds === null) {
            if (
                predefinedFilters.teamTasks &&
                predefinedFilters.myTasks &&
                predefinedFilters.unassignedTasks
            ) {
                return undefined;
            }
            if (predefinedFilters.teamTasks) {
                const exclude: number[] = [];
                if (!predefinedFilters.myTasks) exclude.push(userId);
                if (!predefinedFilters.unassignedTasks) exclude.push(null as any);
                return { exclude };
            }
            if (predefinedFilters.myTasks) include.push(userId);
            if (predefinedFilters.unassignedTasks) include.push(null as any);
            return { include };
        }

        if (predefinedFilters.teamTasks && predefinedFilters.myTasks) {
            return { include };
        }

        if (predefinedFilters.myTasks) {
            if (include.includes(userId)) {
                return { include: [userId] };
            }
            return { include: [] };
        }

        if (predefinedFilters.teamTasks) {
            return { include: include.filter(id => id !== userId) };
        }

        return { include: [] };
    };
export const getAssigneeFilterParams = getAssigneeFilterParamsFactory();

export const getStatusesFilterParamsFactory =
    (
        predefinedTaskFiltersSelector = getPredefinedTaskFilters,
        activeTaskDefinitionsSelector = getActiveTaskDefinitions,
        appliedFiltersSetSelector = getAppliedFiltersSet,
        statusToIdMapHelper = getStatusToIdMap,
        allOpenStatusesHelper = allOpenStatuses,
    ) =>
    (state: AppState): { include: number[] } | undefined => {
        const predefinedFilters = predefinedTaskFiltersSelector(state);
        const taskDefinitions = activeTaskDefinitionsSelector(state);
        const appliedFiltersSet = appliedFiltersSetSelector(state);
        const statusMap = statusToIdMapHelper(taskDefinitions);

        let statuses: number[] = predefinedFilters.openTasks
            ? allOpenStatusesHelper(taskDefinitions)
            : [];
        if (appliedFiltersSet) {
            const statusesFilter = appliedFiltersSet.custom[STATUSES_FILTER_ID];
            if (statusesFilter && statusesFilter.type === ConfigurableFilterType.String) {
                statusesFilter.items.forEach(status => {
                    if (statusMap[status]) {
                        statuses = statuses.concat(statusMap[status]);
                    }
                });
            }
        }

        return statuses.length ? { include: Array.from(new Set(statuses)) } : undefined;
    };
export const getStatusesFilterParams = getStatusesFilterParamsFactory();

export const getUpdatedFilterParamsFactory =
    (
        appliedFiltersSetSelector = getAppliedFiltersSet,
        predefinedTaskFiltersSelector = getPredefinedTaskFilters,
    ) =>
    (state: AppState): TaskPropertyRange | undefined => {
        const appliedFiltersSet = appliedFiltersSetSelector(state);
        const predefinedFilters = predefinedTaskFiltersSelector(state);

        let updated: TaskPropertyRange | undefined;

        if (appliedFiltersSet?.predefined.recentTasks || predefinedFilters.recentTasks) {
            updated = {
                from: moment().subtract(2, 'days').format(DATE_FORMAT),
                to: moment().format(DATE_FORMAT),
            };
        }

        return updated;
    };

export const getUpdatedFilterParams = getUpdatedFilterParamsFactory();

export const getCreatedByFilterParamFactory =
    (
        appliedFiltersSetSelector = getAppliedFiltersSet,
        predefinedTaskFiltersSelector = getPredefinedTaskFilters,
        getContactIdSelector = getUserId,
    ) =>
    (state: AppState): { include: number[] } | undefined => {
        const appliedFiltersSet = appliedFiltersSetSelector(state);
        const predefinedFilters = predefinedTaskFiltersSelector(state);
        const userId = getContactIdSelector(state);

        let createdBy;

        if (appliedFiltersSet?.predefined.createdByMe || predefinedFilters.createdByMe) {
            createdBy = {
                include: [userId],
            };
        }

        return createdBy;
    };

export const getCreatedByFilterParam = getCreatedByFilterParamFactory();

export const getTDFilterParamsFactory =
    (getAppliedTDFilterGetter = getAppliedTDFilter) =>
    (state: AppState, taskDefinitionId: number): { include: number[] } | undefined => {
        if (taskDefinitionId === ALL_TASKS_ID) {
            const tds = getAppliedTDFilterGetter(state)?.items as number[] | undefined;
            if (tds?.length) {
                return { include: [...tds] };
            }
            return undefined;
        }
        return { include: [taskDefinitionId] };
    };
export const getTDFilterParams = getTDFilterParamsFactory();

export const getFetchTasksParams = (
    taskDefinitionId: number,
    limit: number,
    offset: number,
    state: AppState,
): FetchTasksParams => {
    const tasksSort = getCurrentTaskSort(state);

    const filter: RecursivePartial<ApiGW.TasksFilterDTOV2> = {
        assignee: getAssigneeFilterParams(state),
        dueDate: getDueDateRangeFilterParams(state),
        assignDate: getAssignDateFilterParams(state),
        properties: getCustomPropertiesFilterParams(state),
        statuses: getStatusesFilterParams(state),
        taskDefinitions: getTDFilterParams(state, taskDefinitionId),
        search: getContextSearchQuery(state),
        updated: getUpdatedFilterParams(state),
        createdBy: getCreatedByFilterParam(state),
    } as RecursivePartial<ApiGW.TasksFilterDTOV2>;

    const params: RecursivePartial<ApiGW.TasksSearchDTOV2> = {
        limit,
        offset,
        sort: [
            {
                column: tasksSort.column as unknown as ApiGW.TasksSearchSortDTO.ColumnEnum,
                order: tasksSort.order as unknown as ApiGW.TasksSearchSortDTO.OrderEnum,
                property: getCustomPropertyIdToSort(tasksSort, state, taskDefinitionId),
            },
            {
                column: SortColumnType.Summary as unknown as ApiGW.TasksSearchSortDTO.ColumnEnum,
                order: tasksSort.order as unknown as ApiGW.TasksSearchSortDTO.OrderEnum,
            },
        ],
        filter: filter as ApiGW.TasksFilterDTOV2,
    };

    return {
        category: CONTENT_CATEGORY_TASKS,
        params,
        taskDefinitionId,
    };
};
