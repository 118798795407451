import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Typography, MenuList, MenuItem, Link, Icon } from 'worldapp-ui/shared';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { menuCreator } from '../../../redux/menu/menu';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { ActivePanelType, bottomNavigationList } from '../../../constants/navigation.constants';
import { AppState } from '../../../redux/AppState.types';
import { getIsDrawerHoverOpen, getIsDrawerPermanentOpen } from '../../../redux/menu/menuSelector';
import { getCustomLinksCount } from '../../../redux/portalSettings/portalSettingsSelector';
import { bottomPanelStyles } from './BottomPanel.styles';

const HELP_LINK = 'https://help.opx.form.com/hc/latest/new-online-portal';

const mapDispatchToProps = mapCreatorsToDispatchProps(menuCreator);

export const mapStateToProps = (state: AppState) => ({
    isDrawerHoverOpen: getIsDrawerHoverOpen(state),
    isDrawerPermanentOpen: getIsDrawerPermanentOpen(state),
    customLinksCount: getCustomLinksCount(state),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const BottomPanel: React.SFC<Props> = (props: Props) => {
    const { onBottomMenuClick, isDrawerHoverOpen, isDrawerPermanentOpen, customLinksCount } = props;
    const { t } = useTranslation();
    const classes = bottomPanelStyles();
    const isDrawerOpen = isDrawerPermanentOpen || isDrawerHoverOpen;

    const onClick = useCallback(
        (panel: ActivePanelType) => () => {
            onBottomMenuClick(panel);
        },
        [onBottomMenuClick],
    );

    return (
        <div className={classNames({ [classes.bottomMenuContainer]: isDrawerOpen })}>
            <MenuList>
                {bottomNavigationList.map(navItem => {
                    if (!customLinksCount && navItem.id === ActivePanelType.CustomLinks) {
                        return null;
                    }
                    const iconName =
                        navItem.id === ActivePanelType.CustomLinks ? 'link' : 'multilingual';

                    return (
                        <MenuItem
                            key={navItem.id}
                            onClick={onClick(navItem.id)}
                            data-testid={`bottom-panel-menu-item-${navItem.id}`}
                            className={classes.bottomMenuItem}
                            aria-label={isDrawerOpen ? undefined : t(navItem.title)}
                        >
                            <span className={classes.bottomMenuItemLink} key={`link-${navItem.id}`}>
                                <Icon
                                    className={classes.icon}
                                    name={iconName}
                                    mode="stroke"
                                    size="l"
                                />
                                {isDrawerOpen && (
                                    <Typography className={classes.text}>
                                        {t(navItem.title)}
                                    </Typography>
                                )}
                            </span>
                        </MenuItem>
                    );
                })}
                <MenuItem
                    data-testid={`bottom-panel-menu-item-Help`}
                    className={classes.bottomMenuItem}
                    aria-label={isDrawerOpen ? undefined : t('NavigationPanel.HelpTab')}
                >
                    <Link
                        href={HELP_LINK}
                        target="_blank"
                        underline="none"
                        className={classes.bottomMenuItemLink}
                        aria-label={t('NavigationPanel.HelpTab')}
                    >
                        <Icon className={classes.icon} name="help" mode="stroke" size="l" />
                        {isDrawerOpen && (
                            <Typography className={classes.text}>
                                {t('NavigationPanel.HelpTab')}
                            </Typography>
                        )}
                    </Link>
                </MenuItem>
            </MenuList>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomPanel);
