import React from 'react';
import { MessageBox } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { tasksActionsCreator } from '../../redux/tasksActions/tasksActions';
import { AppState } from '../../redux/AppState.types';
import {
    getBulkActionsTasks,
    getIsLoading,
    getIsUnAssign,
} from '../../redux/tasksActions/taskActionsSelector';
import { getTaskDetailViewTaskId } from '../../redux/activeCard/activeCardSelectors';
import { TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS } from '../../constants/ui.constants';

export const mapStateToProps = (state: AppState) => {
    return {
        isLoading: getIsLoading(state),
        isPopupOpen: getIsUnAssign(state),
        isTaskDetailsOpen: !!getTaskDetailViewTaskId(state),
        bulkActionsTasks: getBulkActionsTasks(state),
    };
};

export const mapDispatchToProps = mapCreatorsToDispatchProps({
    closeActionPopup: tasksActionsCreator.closeActionPopup,
    unAssign: tasksActionsCreator.unAssign,
    unAssignInBulk: tasksActionsCreator.unAssignInBulk,
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export const UnAssign = (props: Props): JSX.Element | null => {
    const {
        closeActionPopup,
        isPopupOpen,
        unAssign,
        unAssignInBulk,
        bulkActionsTasks,
        isLoading,
        isTaskDetailsOpen,
    } = props;

    const { t } = useTranslation();

    if (!isTaskDetailsOpen) {
        return (
            <MessageBox
                className={TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS}
                open={isPopupOpen}
                title={t('TaskActions.UnAssign.PopupTitle')}
                confirmButton={{
                    label: t('TaskActions.UnAssign.ConfirmButtonTitle'),
                    clickHandler: bulkActionsTasks ? unAssignInBulk : unAssign,
                    buttonProps: {
                        disabled: isLoading,
                    },
                }}
                cancelButton={{
                    label: t('TaskActions.UnAssign.CloseButtonTitle'),
                    clickHandler: closeActionPopup,
                    buttonProps: {
                        disabled: isLoading,
                    },
                }}
            >
                {t('TaskActions.UnAssign.AreYouSure')}
            </MessageBox>
        );
    }
    return null;
};
export default connect(mapStateToProps, mapDispatchToProps)(UnAssign);
