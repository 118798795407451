import { Converter } from '../location.types';
import { TasksView, tasksViews } from '../../redux/menu/menu.types';

export const taskViewConverter: Converter = {
    stateToURL: state => (state.tasksView ? { query: { view: state.tasksView } } : undefined),
    URLToState: url => {
        const tasksView = url.query.view?.[0] as TasksView;
        return {
            tasksView: tasksViews.includes(tasksView) ? tasksView : null,
        };
    },
};
