import React, { FunctionComponent } from 'react';
import { Divider as DefaultDivider, makeStyles } from 'worldapp-ui';

const dividerStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.neutral[12],
    },
}));

export const Divider: FunctionComponent = () => {
    const classes = dividerStyles();
    return <DefaultDivider orientation="horizontal" classes={{ root: classes.root }} />;
};
