import { createSelector } from 'reselect';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
// eslint-disable-next-line import/no-cycle
import {
    initialAssigneeTaskQuickFilters,
    initialManagerTaskQuickFilters,
    initialResponseQuickFilters,
} from './contentQuickFilters';
import { ResponseQuickFilters, TaskQuickFilters } from './contentQiuckFilters.types';

import {
    getContentItemId,
    isContentCategoryForms,
    isContentCategoryTasks,
} from '../content/contentSelector';
import { isFetchedTasks } from '../content/content.utils';
import { AppState } from '../AppState.types';
import {
    getTaskDefinitions,
    getTaskDefinitionsById,
    getTdIdsWithCreateTask,
    getTotalTasks,
} from '../taskDefinitions/taskDefinitionsSelector';
import { getForms } from '../forms/formsSelector';
import { getHideCompleted } from '../portalSettings/portalSettingsSelector';

const MY_TASKS = 'TaskQuickFilters.MyTasks';
const TEAM_TASKS = 'TaskQuickFilters.TeamTasks';
const UNASSIGNED = 'TaskQuickFilters.Unassigned';
const OPENED = 'TaskQuickFilters.Opened';
const RECENT = 'TaskQuickFilters.Recent';
const CREATED_BY_ME = 'TaskQuickFilters.CreatedByMe';
const OVERDUE = 'TaskQuickFilters.Overdue';
export interface TasksQuickFiltersButton {
    key: keyof TaskQuickFilters;
    text: string;
    testId: string;
}

export const buttonsForManager: TasksQuickFiltersButton[] = [
    { key: 'recentTasks', text: RECENT, testId: 'btn-quick-filter_recent-tasks' },
    { key: 'myTasks', text: MY_TASKS, testId: 'btn-quick-filter_my-tasks' },
    { key: 'teamTasks', text: TEAM_TASKS, testId: 'btn-quick-filter_team-tasks' },
    { key: 'unassignedTasks', text: UNASSIGNED, testId: 'btn-quick-filter_unassigned' },
    { key: 'openTasks', text: OPENED, testId: 'btn-quick-filter_opened-tasks' },
    { key: 'createdByMe', text: CREATED_BY_ME, testId: 'btn-quick-filter_created-by-me' },
    { key: 'overdue', text: OVERDUE, testId: 'btn-quick-filter_overdue' },
];

const buttonsForAssignee: TasksQuickFiltersButton[] = [
    { key: 'recentTasks', text: RECENT, testId: 'btn-quick-filter_recent-tasks' },
    { key: 'openTasks', text: OPENED, testId: 'btn-quick-filter_opened-tasks' },
    { key: 'createdByMe', text: CREATED_BY_ME, testId: 'btn-quick-filter_created-by-me' },
    { key: 'overdue', text: OVERDUE, testId: 'btn-quick-filter_overdue' },
];

export const hasManagementRights = createSelector(
    isContentCategoryTasks,
    getContentItemId,
    getTaskDefinitions,
    getTaskDefinitionsById,
    (isTasks, activeTaskDefinitionId, taskDefinitions, taskDefinitionsById) => {
        if (!isTasks || !activeTaskDefinitionId) return false;
        if (activeTaskDefinitionId === ALL_TASKS_ID) {
            return taskDefinitions.some(td => td.managed);
        }
        const taskDefinition = taskDefinitionsById[activeTaskDefinitionId];
        return (taskDefinition && taskDefinition.managed) || false;
    },
);

const getShowCreatedByMeButton = createSelector(
    isContentCategoryTasks,
    getContentItemId,
    getTaskDefinitions,
    getTdIdsWithCreateTask,
    (isTasks, activeTaskDefinitionId, taskDefinitions, tdIdsWithCreateTask) => {
        if (!isTasks || !activeTaskDefinitionId) return false;
        if (activeTaskDefinitionId === ALL_TASKS_ID) {
            return taskDefinitions.some(td => tdIdsWithCreateTask.includes(td.id));
        }
        return tdIdsWithCreateTask.includes(activeTaskDefinitionId);
    },
);

export const getQuickFilterButtons = createSelector(
    hasManagementRights,
    getHideCompleted,
    getShowCreatedByMeButton,
    (isManager, hideCompleted, showCreatedByMeButton) =>
        (isManager ? buttonsForManager : buttonsForAssignee).filter(button => {
            switch (button.key) {
                case 'openTasks': {
                    return !hideCompleted;
                }

                case 'createdByMe': {
                    return showCreatedByMeButton;
                }
                default:
                    return true;
            }
        }),
);

export const getInitialTaskQuickFilters = createSelector(hasManagementRights, isManager =>
    isManager ? initialManagerTaskQuickFilters : initialAssigneeTaskQuickFilters,
);

export const getFoundTasksCount = ({ content }: AppState): number => {
    return isFetchedTasks(content) ? content.items.length : 0;
};

export const getTaskQuickFilters = (state: AppState): TaskQuickFilters => {
    const taskDefinitionId = getContentItemId(state);
    return (
        (taskDefinitionId && state.quickFilters.tasks[taskDefinitionId]) ||
        getInitialTaskQuickFilters(state)
    );
};

export function getResponseQuickFilter(state: AppState, itemId: number): ResponseQuickFilters {
    return state.quickFilters.forms[itemId] || initialResponseQuickFilters;
}

export function getCurrentResponseQuickFilter(state: AppState): ResponseQuickFilters {
    const itemId = state.content?.itemId;
    return (itemId && state.quickFilters.forms[itemId]) || initialResponseQuickFilters;
}

export const getTotalTasksCount = createSelector(
    isContentCategoryTasks,
    getContentItemId,
    getTaskDefinitionsById,
    getTotalTasks,
    (isTasks, activeTaskDefinitionId, taskDefinitionsById, totalTasks) => {
        if (!isTasks || !activeTaskDefinitionId) return null;
        if (activeTaskDefinitionId === ALL_TASKS_ID) return totalTasks;
        const td = taskDefinitionsById[activeTaskDefinitionId];
        if (!td || td.count === undefined) return null;
        return td.count;
    },
);

export const getTotalResponsesCount = createSelector(
    isContentCategoryForms,
    getContentItemId,
    getForms,
    (isForms, activeFormId, forms) => {
        if (!isForms || !activeFormId) return null;
        const activeForm = forms.find(form => form.id === activeFormId);
        if (!activeForm || !activeForm.settings) return null;
        return activeForm.settings.totalVotedResponses;
    },
);

const contentQuickFiltersSelector: {
    getTaskQuickFilters: (state: AppState) => TaskQuickFilters;
    getInitialTaskQuickFilters: (state: AppState) => TaskQuickFilters;
    hasManagementRights: (state: AppState) => boolean;
} = { getTaskQuickFilters, getInitialTaskQuickFilters, hasManagementRights };

export default contentQuickFiltersSelector;
