import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, MessageBox, TextInput } from 'worldapp-ui/shared';
import { Danger as DangerIcon } from 'worldapp-ui/shared/icons';
import { tasksConfigurableFiltersCreator } from '../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { getSavedFilterSetsNames } from '../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { AppState } from '../../redux/AppState.types';

export interface SaveFilterSetMessageBoxProps {
    existingNames: string[];
    isOpened: boolean;
    closeMessageBox: () => void;
    saveFiltersSet: (filtersSetName: string) => void;
}

/* istanbul ignore next */
const mapStateToProps = (state: AppState) => ({
    existingNames: getSavedFilterSetsNames(state),
});

const mapDispatchToProps = mapCreatorsToDispatchProps({
    saveFiltersSet: tasksConfigurableFiltersCreator.saveFiltersSet,
});

const useSaveFilterStyles = makeStyles(theme => ({
    wrapper: {
        minHeight: theme.spacing(9),
    },
    root: {
        width: '100%',
        '& .MuiFormLabel-root.Mui-error': {
            color: 'inherit',
        },
    },
    iconWrapper: {
        color: theme.palette.rubyRed.main,
        top: theme.spacing(3.2),
        position: 'relative',
        width: theme.spacing(2.2),
    },
}));

export const SaveFilterSetMessageBoxComponent = (
    props: SaveFilterSetMessageBoxProps,
): JSX.Element => {
    const { existingNames, isOpened, closeMessageBox, saveFiltersSet } = props;

    const [filterSetName, setFilterSetName] = React.useState('');
    const { t } = useTranslation();

    const classes = useSaveFilterStyles();

    const handleChangeFilterSetName: React.ChangeEventHandler<HTMLInputElement> = e => {
        setFilterSetName(e.target.value);
    };

    const handleConfirmation = () => {
        saveFiltersSet(filterSetName);
        closeMessageBox();
    };

    const existingFilterSetName = existingNames.find(
        name => name.toLowerCase() === filterSetName.toLowerCase(),
    );

    const errorMessage = existingFilterSetName
        ? t('TaskFilters.FiltersSetExist', {
              name: existingFilterSetName,
          })
        : '';

    const errorMessageIcon = existingFilterSetName ? (
        <DangerIcon classes={{ root: classes.iconWrapper }} />
    ) : null;

    const content = (
        <div className={classes.wrapper}>
            <TextInput
                label={t('TaskFilters.MessageBoxInputLabel')}
                value={filterSetName}
                onChange={handleChangeFilterSetName}
                errorMessage={errorMessage}
                errorMessageAlign={'left'}
                inputFieldProps={{
                    fullWidth: true,
                    endAdornment: errorMessageIcon,
                    'aria-label': t('TaskFilters.MessageBoxInputLabel'),
                }}
                data-testid="filters_set__message_box__name_input"
                classes={{ root: classes.root }}
            />
        </div>
    );

    return (
        <MessageBox
            open={isOpened}
            title={t('TaskFilters.MessageBoxTitle')}
            confirmButton={{
                label: t('TaskFilters.MessageBoxConfirmButton'),
                clickHandler: handleConfirmation,
                buttonProps: {
                    disabled: !!(!filterSetName || errorMessage),
                },
            }}
            cancelButton={{
                label: t('Common.CANCEL'),
                clickHandler: closeMessageBox,
            }}
        >
            {content}
        </MessageBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveFilterSetMessageBoxComponent);
