import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Text,
    Theme,
    Tooltip,
    SwipeableDrawer,
} from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { ExpandMoreThin } from 'worldapp-ui/shared/icons';
import classNames from 'classnames';
import { selectMainHeader } from '../../redux/selectors';
import { AppState } from '../../redux/AppState.types';
import { getTasksTabName } from '../../redux/portalSettings/portalSettingsSelector';
import { deviceType, isMobile } from '../../utils/browser.utils';
import NavigationPaneliOS from '../NavigationPanel/NavigationPaneliOS';
import { getOnlineIniOsFeature } from '../../redux/features/featuresSelector';
import { GRID_MOBILE_PADDING } from '../ui/cards/cardsSizes';
import { getContentCategory } from '../../redux/content/contentSelector';
import { CONTENT_CATEGORY_DASHBOARDS } from '../../redux/content/category.types';

/* istanbul ignore next */
const mapStateToProps = (state: AppState) => {
    return {
        header: selectMainHeader(state),
        tasksTabName: getTasksTabName(state),
        contentCategory: getContentCategory(state),
        hasFeatureOnlineIniOs: getOnlineIniOsFeature(state),
    };
};

export type MainHeaderProps = ReturnType<typeof mapStateToProps>;

const useMainHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainHeaderWrapper: {
            display: 'flex',
            alignItems: 'center',
            minWidth: 0,
            paddingRight: theme.spacing(2),
            color: theme.palette.neutral.main,
        },

        btnExpand: {
            marginRight: 4,
        },
        iconExpand: {
            color: theme.palette.neutral.main,
            height: 32,
            width: 32,
        },
        drawerPaper: {
            width: '100%',
        },
        drawerPaperTablet: {
            paddingTop: GRID_MOBILE_PADDING,
            height: '40%',
        },
        drawerPaperTabletLandscape: {
            height: '50%',
        },
    }),
);

export const MainHeaderComponent = ({
    header,
    tasksTabName,
    contentCategory,
    hasFeatureOnlineIniOs,
}: MainHeaderProps): JSX.Element => {
    const { t } = useTranslation();
    const classes = useMainHeaderStyles();
    const [isOpenNavigationPaneliOS, toggleNavigationPaneliOS] = useState(false);
    const [angle, setAngle] = useState(window.screen.orientation?.angle || 0);
    useEffect(() => {
        function updateSize() {
            setAngle(window.screen.orientation?.angle);
        }
        window.addEventListener('resize', updateSize);
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);
    const title = t(header, { tasksTabName });
    const isTablet = deviceType === 'tablet';
    const iOSNavigation = hasFeatureOnlineIniOs && contentCategory !== CONTENT_CATEGORY_DASHBOARDS;
    return (
        <Grid item={true} className={classes.mainHeaderWrapper}>
            {iOSNavigation && (
                <>
                    <IconButton
                        icon={<ExpandMoreThin type="l" className={classes.iconExpand} />}
                        onClick={() => toggleNavigationPaneliOS(true)}
                        className={classes.btnExpand}
                        data-testid="main-header-btn_open-td-list"
                    />
                    <SwipeableDrawer
                        anchor={isTablet ? 'bottom' : 'left'}
                        open={isOpenNavigationPaneliOS}
                        onClose={() => toggleNavigationPaneliOS(false)}
                        classes={{
                            paper: classNames(classes.drawerPaper, {
                                [classes.drawerPaperTablet]: isTablet,
                                [classes.drawerPaperTabletLandscape]: angle > 0,
                            }),
                        }}
                        onOpen={() => toggleNavigationPaneliOS(true)}
                        data-testid="navigation-panel-ios_container"
                    >
                        <NavigationPaneliOS
                            onClose={() => toggleNavigationPaneliOS(false)}
                            data-testid="navigation-panel-ios"
                        />
                    </SwipeableDrawer>
                </>
            )}
            <Tooltip title={title} placement="bottom-start">
                <Text
                    noWrap={true}
                    variant={isMobile ? 'r22b' : 'r30b'}
                    data-testid="main-header-title"
                >
                    {title}
                </Text>
            </Tooltip>
        </Grid>
    );
};

export default connect(mapStateToProps)(MainHeaderComponent);
