import React, { useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { CircularContouredLoader, useTheme } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { NotOptional } from 'worldapp-fe-utils';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { contentCreator } from '../../redux/content/content';
import {
    getContentCategory,
    getContentItemId,
    getContentItemsCount,
    getIsLoadingHeader,
    hasMoreContent,
    isContentEmpty,
    isContentLoading,
} from '../../redux/content/contentSelector';
import { TasksHeaderPanel } from './HeaderPanel/TasksHeaderPanel';
import FormResponsesHeaderPanel from './HeaderPanel/FormResponsesHeaderPanel';
import { TaskCardsGrid } from './CardsGrid/TaskCardsGrid';
import { ResponseCardsGrid } from './CardsGrid/ResponseCardsGrid';
import { DashboardCardsGrid } from './CardsGrid/DashboardCardsGrid';
import DashboardsHeaderPanel from './HeaderPanel/DashboardsHeaderPanel';
import { TaskTableView } from './TableView/TaskTableView';
import { InfiniteScroll } from './InfiniteScroll/InfiniteScroll';
import {
    Category,
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from '../../redux/content/category.types';
import { AppState } from '../../redux/AppState.types';
import CardsGridWrapper from './CardsGrid/CardsGridWrapper';
import { getTasksView, getIsInitialized } from '../../redux/menu/menuSelector';
import NothingFound from '../ui/nothingFound/NothingFound';
import HeaderPlaceholder from '../Placeholders/HeaderPlaceholder';
import TablePlaceholder from '../Placeholders/TablePlaceholder';
import TasksActionsComponents from './TasksActionsComponents';
import StartFormButton from '../StartForm/StartFormButton/StartFormButton';
import { isMobile } from '../../utils/browser.utils';
import { getContentJustifyValue } from '../../redux/content/content.utils';
import { GRID_PADDING } from '../ui/cards/cardsSizes';
import { TasksCalendar } from './TasksCalendar/TasksCalendar';
import ConfirmChangeDueDate from '../Actions/ConfirmChangeDueDate';
import { TaskDetailViewDialog } from './TaskDetailView/TaskDetailViewDialog';
import { gridContainerStyles, useApplicationContentStyles } from './ApplicationContent.styles';
import { JustifyValue } from '../../redux/content/content.types';
import { getFormsAvailable } from '../../redux/forms/formsSelector';

const CONTENT_INFINITE_SCROLL_ID = 'content-infinite-scroll-container';
const CONTAINER_SIDE_SPACING = 3;
const TABLE_SIDE_BORDER_WIDTH = 1;

interface ContentDiscriminatorProps {
    category: Category;
    loadMore?: () => void;
    hasMore?: boolean;
    parentDiv: HTMLDivElement | null;
    justifyFunction: (a: JustifyValue) => void;
}

interface ContentHeaderProps {
    category: Category;
    isInitialized: boolean;
    isLoadingHeader: boolean;
    formsAvailable: boolean;
}

/* istanbul ignore next */
export const mapStateToProps = (state: AppState) => {
    return {
        contentExists: !isContentEmpty(state),
        hasMore: hasMoreContent(state),
        cardsCount: getContentItemsCount(state),
        contentCategory: getContentCategory(state),
        contentItemId: getContentItemId(state),
        tasksView: getTasksView(state),
        isLoading: isContentLoading(state),
        isInitialized: getIsInitialized(state),
        isLoadingHeader: getIsLoadingHeader(state),
        formsAvailable: getFormsAvailable(state),
    };
};

export const mapDispatchToProps = mapCreatorsToDispatchProps({
    loadMore: contentCreator.loadNext,
});

export const ContentGrid = ({
    category,
    loadMore,
    hasMore,
    parentDiv,
    justifyFunction,
}: ContentDiscriminatorProps): JSX.Element => {
    switch (category) {
        case CONTENT_CATEGORY_FORMS:
            return <ResponseCardsGrid parentDiv={parentDiv} justifyFunction={justifyFunction} />;
        case CONTENT_CATEGORY_DASHBOARDS:
            return <DashboardCardsGrid parentDiv={parentDiv} justifyFunction={justifyFunction} />;
        case CONTENT_CATEGORY_TASKS:
            return <TaskCardsGrid loadMore={loadMore || (() => null)} hasMore={hasMore || false} />;
    }
};

export const ContentHeader = ({
    category,
    isInitialized,
    isLoadingHeader,
    formsAvailable,
}: ContentHeaderProps): JSX.Element => {
    const classes = useApplicationContentStyles();

    if (!isInitialized || isLoadingHeader) {
        return <HeaderPlaceholder />;
    }

    return (
        <div
            className={classes.contentHeader}
            data-testid="content-management-area"
            data-ismobile={isMobile}
        >
            {category === CONTENT_CATEGORY_FORMS && formsAvailable && <FormResponsesHeaderPanel />}
            {category === CONTENT_CATEGORY_DASHBOARDS && <DashboardsHeaderPanel />}
            {category !== CONTENT_CATEGORY_FORMS && category !== CONTENT_CATEGORY_DASHBOARDS && (
                <TasksHeaderPanel />
            )}
        </div>
    );
};

export type ApplicationContentProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        containerWidth?: number;
    };

export const Content = (
    props: NotOptional<ApplicationContentProps, 'contentCategory'> & {
        contentDiv: HTMLDivElement | null;
    },
): JSX.Element => {
    const {
        contentCategory,
        tasksView,
        cardsCount,
        contentItemId,
        hasMore,
        loadMore,
        containerWidth,
        isLoading,
        contentDiv,
        formsAvailable,
    } = props;
    const { t } = useTranslation();
    const classes = useApplicationContentStyles();
    const loadMoreContent = () => {
        return loadMore(contentCategory, contentItemId);
    };
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    const [justifyContentValue, setJustifyContentValue] = useState('flex-start' as JustifyValue);

    let containerWidthWithoutPadding = 0;

    if (contentCategory === 'tasks') {
        containerWidthWithoutPadding = containerWidth
            ? containerWidth -
              theme.spacing(CONTAINER_SIDE_SPACING) * 2 -
              TABLE_SIDE_BORDER_WIDTH * 2
            : 0;
    } else {
        containerWidthWithoutPadding = containerWidth ? containerWidth - 2 * GRID_PADDING : 0;
    }

    const showPlaceholder = cardsCount === 0 && isLoading === true;

    useCallback(() => {
        const justifyContentValueNew = getContentJustifyValue(
            containerWidthWithoutPadding,
            contentCategory,
        );
        setJustifyContentValue(justifyContentValueNew);
    }, [containerWidthWithoutPadding, contentCategory]);

    if (contentCategory === 'forms' && !formsAvailable) {
        return <NothingFound label={t('ApplicationContent.EmptyForms')} />;
    }

    if (tasksView === 'table' && contentCategory === 'tasks') {
        return (
            <>
                {showPlaceholder ? (
                    <TablePlaceholder />
                ) : (
                    <>
                        <TaskTableView
                            containerWidth={containerWidthWithoutPadding}
                            isLoading={isLoading}
                        />
                        {isMobile && contentCategory === 'tasks' && (
                            <div className={classes.mobileFab}>
                                <StartFormButton isCollapsed={true} />
                            </div>
                        )}
                    </>
                )}
                <TasksActionsComponents contentDiv={contentDiv} />
            </>
        );
    }

    if (tasksView === 'cards' && contentCategory === 'tasks') {
        return (
            <div id={CONTENT_INFINITE_SCROLL_ID} ref={ref} className={classes.gridWrapper}>
                <CardsGridWrapper
                    showPlaceholder={showPlaceholder}
                    category={contentCategory}
                    justifyContentValue={justifyContentValue}
                    hideScroll
                >
                    <ContentGrid
                        category={contentCategory}
                        loadMore={loadMoreContent}
                        hasMore={hasMore}
                        parentDiv={ref.current}
                        justifyFunction={setJustifyContentValue}
                    />
                    {isMobile && (
                        <div className={classes.mobileFab}>
                            <StartFormButton isCollapsed={true} />
                        </div>
                    )}
                </CardsGridWrapper>
            </div>
        );
    }

    if (tasksView === 'calendar' && contentCategory === 'tasks') {
        return (
            <div data-testid="task-calendar-parent" className={classes.calendarContainer}>
                <TasksCalendar />
                <ConfirmChangeDueDate />
                <TaskDetailViewDialog />
            </div>
        );
    }

    return (
        <div id={CONTENT_INFINITE_SCROLL_ID} ref={ref} className={classes.gridWrapper}>
            <CardsGridWrapper
                showPlaceholder={showPlaceholder}
                category={contentCategory}
                justifyContentValue={justifyContentValue}
            >
                <InfiniteScroll
                    hasMore={hasMore}
                    length={cardsCount}
                    loadMore={loadMoreContent}
                    category={contentCategory}
                    scrollableTarget={ref}
                    isLoading={isLoading}
                    justifyContentValue={justifyContentValue}
                >
                    <ContentGrid
                        category={contentCategory}
                        parentDiv={ref.current}
                        justifyFunction={setJustifyContentValue}
                    />
                </InfiniteScroll>
                {isMobile && contentCategory === CONTENT_CATEGORY_FORMS && (
                    <div className={classes.mobileFab}>
                        <StartFormButton isCollapsed={true} />
                    </div>
                )}
            </CardsGridWrapper>
        </div>
    );
};

export const ApplicationContentComponent = (props: ApplicationContentProps): JSX.Element => {
    const { contentCategory, contentExists, isInitialized, isLoadingHeader, formsAvailable } =
        props;
    const { t } = useTranslation();
    const [contentDiv, setContentDiv] = useState<HTMLDivElement | null>(null);

    const setRef = useCallback((ref: HTMLDivElement | null) => {
        setContentDiv(ref);
    }, []);

    const classes = gridContainerStyles();
    if (contentCategory === null && contentExists) {
        return <CircularContouredLoader />;
    }

    return (
        <div className={classes.mainContainer}>
            {contentExists && (
                <ContentHeader
                    category={contentCategory as Category}
                    isInitialized={isInitialized}
                    isLoadingHeader={isLoadingHeader}
                    formsAvailable={formsAvailable}
                />
            )}
            <div className={classes.columnGridContainer} data-testid="content-area" ref={setRef}>
                {contentExists ? (
                    <Content
                        {...props}
                        contentCategory={contentCategory as Category}
                        containerWidth={contentDiv?.offsetWidth}
                        contentDiv={contentDiv}
                    />
                ) : (
                    <NothingFound label={t('ApplicationContent.Empty')} />
                )}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationContentComponent);
