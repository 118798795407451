import {
    FEATURES_CHECKED,
    FeaturesAction,
    FeaturesState,
    features,
    FeaturesPayload,
} from './features.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionPromise } from '../index';

export const initialState: FeaturesState = null;

export default function reducer(state = initialState, action: FeaturesAction): FeaturesState {
    switch (action.type) {
        case FEATURES_CHECKED:
            return action.features;
        default:
            return state;
    }
}

export const featuresCreator = {
    checkFeatures: (): ThunkActionPromise => {
        return async (dispatch, _, { api }) => {
            try {
                const fetchedFeatures = await api.checkFeatures(features);

                const featuresPayload = {} as FeaturesPayload;

                for (let index = 0; index < fetchedFeatures.items.length; index++) {
                    const feature = fetchedFeatures.items[index];
                    featuresPayload[feature.name] = feature.enabled;
                }

                dispatch({ type: FEATURES_CHECKED, features: featuresPayload });
            } catch (e) {
                return Promise.reject(e);
            }
        };
    },
};
