import { createStyles, Theme, lighten } from 'worldapp-ui/shared';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = (theme: Theme) =>
    createStyles({
        menuItem: {
            '& $selected, &:hover, &:focus': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.main,
            },
            boxSizing: 'border-box',
            borderBottomRightRadius: 4,
            borderTopRightRadius: 4,
            color: theme.palette.neutral[75],
            height: 48,
            justifyContent: 'flex-start',
            minHeight: '48px!important',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            width: `calc(100% - 4px)`,
        },
        selected: {
            backgroundColor: `${lighten(theme.palette.primary.main, 0.84)}!important`,
            boxSizing: 'border-box',
            color: theme.palette.neutral.main,
            fontWeight: theme.newTypography.r16m.fontWeight,
            justifyContent: 'flex-start',
        },
        closed: {
            width: '100%',
            padding: '0px 16px',
        },
        closedAndSelected: {
            width: '100%',
            borderRadius: 0,
        },
        languagesContainer: {
            overflowY: 'auto',
            margin: '8px 4px 0 0',
        },
        languagesContainerClosed: {
            marginRight: 0,
        },
        iconBullet: {
            marginRight: 4,
        },
    });

export default styles;
