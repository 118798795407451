import { CONTENT_CLEAR, CONTENT_META_CHANGE } from '../content/content.types';
import { DefaultNavigationAction, DefaultNavigationState } from './defaultNavigation.types';
import { CHANGE_QUICK_FILTER } from '../contentQuickFilters/contentQiuckFilters.types';
import { CHANGE_TASKS_VIEW } from '../menu/menu.types';
import {
    CHANGE_SORT_COLUMN,
    CHANGE_SORT_COLUMN_OR_ORDER,
    TOGGLE_SORT_ORDER,
} from '../tasksSort/tasksSort.types';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import { OPEN_ACTION_POPUP } from '../tasksActions/tasksActions.types';
import { GO_TO_VOTING } from '../voting/voting.types';
import { Category } from '../content/category.types';
import { OPEN_TASK_DETAILS_VIEW } from '../activeCard/activeCard.types';

export const initialState = true;
export const defaultCategory: Category = 'tasks';
export const defaultItemId: number = ALL_TASKS_ID;

export default function reducer(
    state: DefaultNavigationState = initialState,
    action: DefaultNavigationAction,
): DefaultNavigationState {
    switch (action.type) {
        case CONTENT_META_CHANGE:
            return state && action.itemId === defaultItemId && action.category === defaultCategory;
        case CHANGE_QUICK_FILTER:
        case CHANGE_TASKS_VIEW:
        case CHANGE_SORT_COLUMN:
        case CHANGE_SORT_COLUMN_OR_ORDER:
        case TOGGLE_SORT_ORDER:
        case CONTENT_CLEAR:
        case OPEN_TASK_DETAILS_VIEW:
        case OPEN_ACTION_POPUP:
            return false;
        case GO_TO_VOTING:
            return action.cardId || action.itemId ? false : state;
        default:
            return state;
    }
}
