import React from 'react';
import { Text, ToggleButton, Truncate } from 'worldapp-ui';
import { Form, Tasks } from 'worldapp-ui/shared/icons';
import { StartFormItem } from '../../../redux/startForm/startForm.types';
import { frequentlyUsedCardStyles } from './FrequentlyUsedCard.styles';
import { deviceType } from '../../../utils/browser.utils';

interface FrequentlyUsedCardProps {
    value: string;
    type: StartFormItem;
    title: string;
}

const FrequentlyUsedCard: React.FC<FrequentlyUsedCardProps> = props => {
    const { value, type, title, ...restProps } = props;

    const { root, label, text, icon, selected } = frequentlyUsedCardStyles();
    const isMobileDevice = deviceType === 'mobile';
    const iconSize = isMobileDevice ? 'medium' : 'extra-large';
    return (
        <ToggleButton
            value={value}
            aria-label={`${value}`}
            classes={{ root, label, selected }}
            data-testid={`btn_frequently-used_${value}`}
            {...restProps}
            data-ismobile={isMobileDevice}
        >
            <div className={icon} data-ismobile={isMobileDevice}>
                {type === 'form' ? <Form size={iconSize} /> : <Tasks size={iconSize} />}
            </div>
            <Text variant="r12r" className={text} data-ismobile={isMobileDevice}>
                <Truncate lines={2} tagName="span">
                    {title}
                </Truncate>
            </Text>
        </ToggleButton>
    );
};

export default FrequentlyUsedCard;
