import { createSelector } from 'reselect';
import moment from 'moment';
import { DueDate } from '../../components/ui/cards/TaskCard';
import { TaskDefinition } from '../taskDefinitions/taskDefinitions.types';
import {
    DashboardCardProps,
    ResponseCardProps,
    TaskCardContentProps,
} from '../../components/ApplicationContent/CardsGrid/CardsGrid.types';
import {
    BulkTaskAction,
    getMainTaskAction,
    getSecondaryTaskActions,
    getSecondaryTaskActionsTable,
    secondaryActionList,
    taskHasEditAction,
} from './validateTaskActions';
import { Content, Dashboard, Response, Task } from './content.types';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import {
    Category,
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from './category.types';
import { CardSelector, ContentSelector } from './contentSelector.types';
import { AppState } from '../AppState.types';
import { getUserId } from '../user/userSelector';
import { getTaskDetailViewTaskId, selectActiveTaskId } from '../activeCard/activeCardSelectors';
import {
    getTaskDefinitionsById,
    taskDefinitionsSelector,
} from '../taskDefinitions/taskDefinitionsSelector';
import { getIsInitialized } from '../menu/menuSelector';
import { ApiGW } from '../../types/DTO/api-gw';
import { ApiDashboards } from '../../types/DTO/dashboards';
import { getVotingCreatedItemId } from '../voting/votingSelector';
import { getTasksActionsState } from '../tasksActions/taskActionsSelector';
import { LOCALES } from '../../constants/main.constants';
import { getSelectedTasksMap } from '../tasksSelectedActions/tasksSelectedActionsSelector';
import { getFormsAvailable } from '../forms/formsSelector';
import { getTabs } from '../portalSettings/portalSettingsSelector';

export const getStatus: ContentSelector['getStatus'] = (
    statusId: number,
    taskDefinition?: TaskDefinition,
) => {
    if (!taskDefinition) {
        return undefined;
    }
    const status = taskDefinition.statusesById ? taskDefinition.statusesById[statusId] : null;
    return status ? { status: status.title, isClosed: status.closed } : undefined;
};

export const getDueDate = (task: Task, isStatusClosed?: boolean, now = new Date()): DueDate => {
    if (!task.dueDate) return { dueDateStatus: 'notSet' };

    const dueDate = new Date(task.dueDate);
    dueDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    const dateString = moment.parseZone(task.dueDate).format('MM/DD/YYYY');
    const overdue: DueDate = { dueDateStatus: 'overdue', date: dateString };
    const set: DueDate = { dueDateStatus: 'set', date: dateString };

    if (isStatusClosed === undefined) return set;

    if (isStatusClosed) {
        if (!task.closed) return set;
        const closedDate = new Date(task.closed);
        closedDate.setHours(0, 0, 0, 0);
        return dueDate >= closedDate ? set : overdue;
    }

    return dueDate >= now ? set : overdue;
};

export const getUpdated = (timeString: string): string => {
    const date = new Date(timeString);
    const localeDateString = date.toLocaleDateString(LOCALES);
    const localeTimeString = date.toLocaleTimeString(LOCALES, {
        hour: '2-digit',
        minute: '2-digit',
    });
    return `${localeDateString} ${localeTimeString}`;
};

export const cardSelector: CardSelector = {
    cardFromResponse:
        (
            activeFormId: number,
            searchQuery?: string,
            activeResponseId?: number,
            createdItemId?: number,
            isDeleteResponseActive?: boolean,
        ) =>
        (response: Response): ResponseCardProps => {
            return {
                activeFormId,
                searchQuery,
                type: 'response',
                id: response.id,
                isCompleted: response.status === 'COMPLETED',
                title: response.label,
                updated: getUpdated(response.updated),
                isActive: activeResponseId === response.id,
                shouldHighlight: createdItemId === response.id,
                isDeleteResponseActive,
            };
        },
    cardFromDashboard:
        (searchQuery: string) =>
        (dashboard: Dashboard): DashboardCardProps => {
            return {
                type: 'dashboard',
                id: dashboard.id,
                name: dashboard.name,
                searchQuery,
                created: getUpdated(dashboard.created),
                author: dashboard.author,
            };
        },
};

export const getContentCategory = (state: AppState): Category | null =>
    state.content && state.content.category;

export const getContentCategoryExits = (category: Category | null, state: AppState): boolean =>
    Object.keys(getTabs(state)).some(item => item === category);

/* istanbul ignore next */
export const getContentItemsCount = (state: AppState): number => {
    return state.content ? state.content.items.length : 0;
};

/* istanbul ignore next */
export const hasMoreContent = (state: AppState): boolean =>
    state.content === null || state.content.hasMore;

/* istanbul ignore next */
export const getContentItemId = (state: AppState): number | null =>
    state.content && state.content.itemId;

/* istanbul ignore next */
export const getContextSearchQuery = (state: AppState): string =>
    state.content ? state.content.searchQuery : '';

export const getContentItems = ({
    content,
}: AppState): ApiGW.ResponseDTO[] | Task[] | ApiDashboards.DashboardDTO[] | null =>
    content?.items || null;

export const getContentTotal = ({ content }: AppState): number | null => content?.total || null;

export const isContentCategoryTasks = createSelector(
    getContentCategory,
    category => category === CONTENT_CATEGORY_TASKS,
);

export const isContentCategoryForms = createSelector(
    getContentCategory,
    category => category === CONTENT_CATEGORY_FORMS,
);

export const isContentCategoryDashboards = createSelector(
    getContentCategory,
    category => category === CONTENT_CATEGORY_DASHBOARDS,
);

export const isAllTasksSelected = createSelector(
    isContentCategoryTasks,
    getContentItemId,
    (isTasks, taskDefinitionId) => {
        if (!isTasks) return false;
        return taskDefinitionId === ALL_TASKS_ID;
    },
);

export const getTasks = createSelector(
    isContentCategoryTasks,
    getContentItems,
    (isTasks, tasks) => {
        if (!isTasks || !tasks) return [];
        return tasks as Task[];
    },
);

export const getTaskDetailViewTask = (state: AppState): Task | undefined => {
    const taskId = getTaskDetailViewTaskId(state);
    if (!state) return;
    return getTasks(state).find(t => t.id === taskId);
};

export const getTasksIds = createSelector(getTasks, tasks => tasks.map(task => task.id));

export const getCurrentTaskDefinitionId = (state: AppState): number | null => {
    if (state.content && state.content.category === 'tasks') {
        return state.content.itemId;
    }
    return null;
};

export const contentLoading = (state: AppState): boolean =>
    state.content === null || state.content?.isLoading;

/* istanbul ignore next */
export const isContentLoading = (state: AppState): boolean =>
    state.content === null || state.content.isLoading || state.content.hasMore;

export const isItemLoading = (itemId: number, category: Category, state: AppState): boolean =>
    state.content?.loadingItem?.itemId === itemId &&
    state.content?.loadingItem?.category === category;

export const isItemIdValid = (state: AppState): boolean => {
    const { itemId, category } = state.content as Content;
    if (category === CONTENT_CATEGORY_TASKS) {
        return state.taskDefinitions.taskDefinitions.find(td => td.id === itemId) !== undefined;
    }
    if (category === CONTENT_CATEGORY_FORMS) {
        return state.forms.forms.find(form => form.id === itemId) !== undefined;
    }
    return true;
};

export const getFoundResponsesCount = createSelector(
    isContentCategoryForms,
    getContentTotal,
    (isContentForms, total) => {
        return isContentForms ? total : 0;
    },
);

export const getDashboardCards = createSelector(
    isContentCategoryDashboards,
    getContentItems,
    getContextSearchQuery,
    (isDashboards, dashboards, searchQuery) => {
        if (!isDashboards || !dashboards) return [];
        return (dashboards as Dashboard[]).map(cardSelector.cardFromDashboard(searchQuery));
    },
);

export const getTasksCards = createSelector(
    isContentCategoryTasks,
    getContentItems,
    getTaskDefinitionsById,
    getUserId,
    selectActiveTaskId,
    getContextSearchQuery,
    getVotingCreatedItemId,
    getTaskDetailViewTaskId,
    getTasksActionsState,
    (
        isTasks,
        tasks,
        taskDefinitionsById,
        contactId,
        activeTaskId,
        searchQuery,
        createdItemId,
        taskDetailViewId,
        tasksActionsState,
    ): TaskCardContentProps[] => {
        if (!isTasks || !tasks) return [];
        return (tasks as Task[]).map(task => {
            const taskDefinition = taskDefinitionsById[task.taskDefinitionId];
            const { status = undefined, isClosed: isStatusClosed = undefined } =
                getStatus(task.statusId, taskDefinition) || {};
            const mainAction = taskDefinition
                ? getMainTaskAction({ task, taskDefinition, contactId })
                : undefined;

            const secondaryActions = taskDefinition
                ? getSecondaryTaskActions({ task, taskDefinition, contactId })
                : [];
            const isTaskAction = tasksActionsState.type !== 'NullState';

            return {
                secondaryActions,
                mainAction,
                status,
                isStatusClosed,
                type: 'task' as const,
                id: task.id,
                assignee: task.assignee ? task.assignee.name : undefined,
                taskDefinition: taskDefinition ? taskDefinition.title : undefined,
                taskDefinitionId: task.taskDefinitionId,
                title: task.summary,
                searchQuery,
                dueDate: getDueDate(task, isStatusClosed),
                isActive: activeTaskId === task.id,
                shouldHighlight: !isTaskAction && createdItemId === task.id,
                isTaskDetailViewOpened: taskDetailViewId !== null,
                hasEditAction: taskHasEditAction(mainAction, task, contactId),
            };
        });
    },
);

export const isSearchQueryNotEmpty = createSelector(
    getContextSearchQuery,
    searchQuery => searchQuery !== '',
);

export const isContentEmpty = createSelector(
    getIsInitialized,
    getTabs,
    getFormsAvailable,
    (isMenuInitialized, tabs, formsAvailable): boolean => {
        return isMenuInitialized && !tabs.tasks && !tabs.dashboards && !formsAvailable;
    },
);

export const getIsLoadingHeader = (state: AppState): boolean =>
    (state.content?.category === 'tasks' && state.content.isLoadingHeader) || false;

export const getContentHasActiveItem = createSelector(
    getContentItems,
    getVotingCreatedItemId,
    (items, id) => {
        if (!items) return false;
        return items.some(item => item.id === id);
    },
);

export const getAvailableActionsForSelectedTasks = createSelector(
    getTasks,
    getSelectedTasksMap,
    taskDefinitionsSelector.getTaskDefinitionsById,
    getUserId,
    (tasks, selectedTasksMap, taskDefinitionsById, userId) => {
        const bulkActionTasksIds = Object.keys(selectedTasksMap).map(id => Number(id));
        const bulkActionsTasks = tasks.filter(t => bulkActionTasksIds.includes(t.id));
        const taskDefinitionsIds = bulkActionsTasks.reduce(
            (acc, current) => acc.add(current.taskDefinitionId),
            new Set<number>(),
        );
        let isDifferentPermissions = false;
        const allActions: Set<BulkTaskAction> = new Set([
            ...secondaryActionList,
        ] as BulkTaskAction[]);
        if (taskDefinitionsIds.size > 1) {
            allActions.delete('ChangeStatus');
            allActions.delete('ChangeAssignee');
            isDifferentPermissions = true;
        }
        const tasksActions = bulkActionsTasks.map(t =>
            getSecondaryTaskActionsTable({
                task: t,
                taskDefinition: taskDefinitionsById[t.taskDefinitionId]!,
                contactId: userId,
            }),
        );
        for (let count = 0; count < tasksActions.length; count += 1) {
            for (const action of Array.from(allActions)) {
                if (action !== 'Export' && !tasksActions[count].includes(action)) {
                    allActions.delete(action);
                    if (count !== 0) {
                        isDifferentPermissions = true;
                    }
                }
            }
            if (tasksActions[count].length !== allActions.size) {
                isDifferentPermissions = true;
            }
        }
        return { actions: allActions, isDifferentPermissions };
    },
);

export const getFormsContentInitialized = ({ contentStatus }: AppState): boolean =>
    contentStatus.formsInitialized;

const selector: ContentSelector = {
    getStatus,
    getDueDate,
    getUpdated,
    cardSelector,
    getContentCategory,
    getContentCategoryExits,
    isContentCategoryTasks,
    isContentCategoryForms,
    isAllTasksSelected,
    getTasks,
    getTaskDetailViewTask,
    getContentItemsCount,
    hasMoreContent,
    getContentItemId,
    getContextSearchQuery,
    isContentLoading,
    isItemLoading,
    isItemIdValid,
    getCurrentTaskDefinitionId,
    getFormsContentInitialized,
};

export default selector;
