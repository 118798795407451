import { URLPart } from 'worldapp-fe-utils';
import { BACK_PATH } from 'worldapp-fe-utils/build/RoutingMiddleware/routingMiddleware';
import { Converter } from '../location.types';
import { LoginStatus } from '../../redux/login/login.types';

const FOREIGN_ID_PARAM = 'foreignId=';

export const loginPageConverter: Converter = {
    stateToURL: state => {
        switch (state.loginStatus) {
            case LoginStatus.Login:
            case LoginStatus.Portal:
                return { query: { login: '' } };
            case LoginStatus.ChangePassword:
                return { query: { changePassword: '' } } as URLPart;
            case LoginStatus.ResetPassword:
                return { query: { resetPassword: '' } };
            case LoginStatus.LogoutPending:
                return { query: { logout: '' } };
            default:
                return undefined;
        }
    },
    URLToState: ({ hash, path, query }) => {
        if (
            (path.endsWith(BACK_PATH) || path.endsWith(`${BACK_PATH}/`)) &&
            hash?.indexOf(FOREIGN_ID_PARAM) === 0
        ) {
            return {
                failedSSOLogin: hash.slice(FOREIGN_ID_PARAM.length),
            };
        }

        if (query.changePassword?.[0] != null) {
            return { loginStatus: LoginStatus.ChangePassword };
        }
        if (query.resetPassword?.[0] != null) {
            return { loginStatus: LoginStatus.ResetPassword };
        }
        if (query.logout?.[0] != null) {
            return { loginStatus: LoginStatus.LogoutPending };
        }
    },
};
