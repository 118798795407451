import { Count } from './frequentlyUsed.types';

export const mergeCounts = (acc: Count, items: Count): Count => {
    const result = {
        ...acc,
    };
    const tdIds = Object.keys(items).map(key => Number(key));
    for (let index = 0; index < tdIds.length; index++) {
        const id = tdIds[index];
        result[id] = (result[id] || 0) + items[id]!;
    }
    return result;
};

export const filterItemsByIds = (object: Count, ids: number[]): Count =>
    Object.keys(object)
        .filter(key => ids.includes(Number(key)))
        .reduce((filteredObj, key) => {
            const id = Number(key);
            return {
                ...filteredObj,
                [id]: object[id],
            };
        }, {});
