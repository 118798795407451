import produce from 'immer';
import { ToastButton } from 'worldapp-ui';
import { ToastType } from 'worldapp-ui/shared/toasts/ToastsController';
import { generateUniqueKey } from '../../utils/helpers.utils';
import {
    CLEAR_INITIALIZATION_TOASTS,
    CREATE_TOAST,
    REMOVE_TOAST,
    ToastsAction,
    ToastsState,
    TOAST_RESPONSE_UPDATE_FILTERS,
    TOAST_TASK_UPDATE_FILTERS,
    TOAST_RETRY_TASK_EXPORT,
} from './toasts.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import i18n from '../../localization/i18n';

export const initialState: ToastsState = {
    toasts: [],
    initializationToasts: [],
};

export const reducer = (state: ToastsState = initialState, action: ToastsAction): ToastsState =>
    produce(state, (draft: ToastsState) => {
        switch (action.type) {
            case CREATE_TOAST: {
                draft.toasts.push({
                    message: action.message,
                    key: action.key,
                    type: action.toastType,
                    autoHide: action.autoHide,
                    ...(action.button && { button: action.button }),
                });
                break;
            }

            case REMOVE_TOAST: {
                draft.toasts = draft.toasts.filter(toast => toast.key !== action.key);
                break;
            }

            case CLEAR_INITIALIZATION_TOASTS: {
                draft.initializationToasts = [];
                break;
            }

            default:
                return state;
        }
    });

export const toastsCreator = {
    createToast:
        (
            message: string,
            toastType: ToastType = 'info',
            autoHide: boolean = true,
            button?: ToastButton,
            key: string = generateUniqueKey(),
        ): ThunkActionVoid =>
        dispatch => {
            dispatch({
                message,
                key,
                toastType,
                autoHide,
                button,
                type: CREATE_TOAST,
            });
        },

    removeToast:
        (key: string): ThunkActionVoid =>
        dispatch => {
            return dispatch({
                key,
                type: REMOVE_TOAST,
            });
        },

    createImpersonateMessageToast:
        (): ThunkActionVoid =>
        (dispatch, _, { toastsCreator: toasts }) => {
            dispatch(toasts.createToast(i18n.t('ImpersonateMode.NotAllowedMessage')));
        },

    initializeToasts:
        (): ThunkActionVoid =>
        (dispatch, getState, { toastsCreator: creator, toastsSelector }) => {
            const initializationToasts = toastsSelector.getInitializationToasts(getState());
            initializationToasts.forEach(initializationToast => {
                dispatch(
                    creator.createToast(
                        i18n.t(initializationToast.message),
                        initializationToast.type,
                    ),
                );
            });
            dispatch({ type: CLEAR_INITIALIZATION_TOASTS });
        },

    handleToastsButtonClick:
        (action: string): ThunkActionVoid =>
        (dispatch, _, { contentQuickFiltersCreator, tasksExportCreator }) => {
            switch (action) {
                case TOAST_TASK_UPDATE_FILTERS:
                    dispatch(contentQuickFiltersCreator.selectOnlyRecentQuickFilter('tasks'));
                    break;
                case TOAST_RESPONSE_UPDATE_FILTERS:
                    dispatch(contentQuickFiltersCreator.selectOnlyRecentQuickFilter('forms'));
                    break;
                case TOAST_RETRY_TASK_EXPORT:
                    dispatch(tasksExportCreator.exportTasks());
                    break;
                default:
                    break;
            }
        },
};

export default reducer;
