import { Converter } from '../../location.types';
import {
    CONTENT_CATEGORY_DASHBOARDS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from '../../../redux/content/category.types';

const oldPortalCategoriesMap = [
    { new: CONTENT_CATEGORY_TASKS, old: 'tasks' },
    { new: CONTENT_CATEGORY_FORMS, old: 'forms' },
    { new: CONTENT_CATEGORY_FORMS, old: 'responses' },
    { new: CONTENT_CATEGORY_DASHBOARDS, old: 'dashboards' },
] as const;

export const categoryConverter: Converter = {
    URLToState: ({ hash }) => {
        if (hash == null) {
            return;
        }
        const hashTokens = hash.split(/[!,/]/, 6);
        const category = oldPortalCategoriesMap.find(cat => cat.old === hashTokens[0]);
        if (category == null) {
            return;
        }
        let activeCardId: string | undefined;
        let activeItemId: number | undefined;
        let submit: boolean | undefined;
        let taskDetailView: boolean | undefined;
        switch (category.old) {
            case 'dashboards':
                activeCardId = hashTokens[1];
                break;
            case 'responses':
            case 'forms':
                activeItemId = Number(hashTokens[1]);
                break;
            case 'tasks':
                activeItemId = Number(hashTokens[1]);
                activeCardId = hashTokens[2];
                if (hashTokens[3] === 'submit') {
                    submit = true;
                }
                if (hashTokens[4] === 'true') {
                    taskDetailView = true;
                }
                break;
        }
        if (activeItemId != null && Number.isNaN(activeItemId)) {
            activeItemId = undefined;
        }
        return {
            activeCategory: category.new,
            activeCardId,
            activeItemId,
            submit,
            taskDetailView,
            isDefaultNavigation: false,
        };
    },
    stateToURL: ({ activeCategory, activeCardId, activeItemId, submit, taskDetailView }) => {
        if (!activeCategory) {
            return;
        }
        const oldCategory = oldPortalCategoriesMap.find(cat => cat.new === activeCategory)?.old;

        switch (activeCategory) {
            case CONTENT_CATEGORY_DASHBOARDS:
                return {
                    hash: activeCardId != null ? `${oldCategory}!${activeCardId}` : oldCategory,
                };
            case CONTENT_CATEGORY_FORMS:
                return {
                    hash: activeItemId != null ? `${oldCategory}!${activeItemId}` : oldCategory,
                };
            case CONTENT_CATEGORY_TASKS:
                let hash = oldCategory as string;
                if (activeItemId == null) {
                    return { hash };
                }
                hash = `${hash}!${activeItemId}`;
                if (activeCardId == null) {
                    return { hash };
                }
                hash = `${hash}/${activeCardId}`;
                if (!submit) {
                    return { hash };
                }
                hash = `${hash}/submit`;
                if (!taskDetailView) {
                    return { hash };
                }
                hash = `${hash}/true`;
                return { hash };
        }
    },
};
