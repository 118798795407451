import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { primaryTheme } from 'worldapp-ui/shared';
import { FALLBACK_LANGUAGE, isInLanguagesList } from './localizationDefaultData';
import { storage } from '../storage/localStorage';
import { isProductionEnv } from '../utils/env.utils';

const detector = {
    name: 'lookupLocalStorage',
    lookup: () => {
        const localizationState = storage.get('localization');
        const language = localizationState?.language;
        if (isInLanguagesList(language)) return language;
        return undefined;
    },
    cacheUserLanguage: () => null,
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(detector);

i18n.use(languageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: FALLBACK_LANGUAGE,
        debug: !isProductionEnv,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath:
                // TODO: remove this check, handle files serving via webpack
                isProductionEnv
                    ? '/online/static/i18n/{{ns}}/{{lng}}.json'
                    : '/static/i18n/{{ns}}/{{lng}}.json',
        },
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: '.',
        react: {
            wait: true,
        },
        detection: {
            order: ['lookupLocalStorage', 'navigator'],
            checkWhitelist: true,
            excludeCacheFor: ['cimode', 'localStorage'],
        },
    });

i18n.on('languageChanged', (lang: string) => {
    const dir = i18n.dir(lang);
    document.body.setAttribute('dir', dir);
    primaryTheme.direction = dir;
});

export default i18n;
