import React from 'react';
import { Card, CardContent, CardMedia, Text, Grid, Truncate } from 'worldapp-ui/shared';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import dashboardPlaceholder from '../../../img/dashboardPlaceholder.svg';
import { cardStyles } from './card.styles';
import DashboardTitle from './DashboardTitle';

export interface DashboardCardProps {
    name?: string;
    image?: string;
    author?: {
        name?: string;
    };
    created?: string;
    searchQuery?: string;
    onCardClick?: () => void;
    width?: number;
}

const DashboardCard = (props: DashboardCardProps): JSX.Element => {
    const { name, image, author, created, searchQuery, onCardClick, width, ...restProps } = props;
    const { t } = useTranslation();
    const classes = cardStyles({ cardWidth: width });

    return (
        <Card
            className={classNames(classes.card, classes.cardWithAction, classes.dashboardCard)}
            data-testid="card-dashboard"
            onClick={onCardClick}
            {...restProps}
        >
            <CardMedia
                className={classes.media}
                component="img"
                image={image || dashboardPlaceholder}
                title={name}
                alt={name}
                data-testid="card-dashboard-image"
            />
            <CardContent className={classes.dashboardCardContent}>
                <Grid container direction="column">
                    <Grid item xs zeroMinWidth>
                        <DashboardTitle
                            title={name}
                            searchQuery={searchQuery}
                            className={classes.dashboardTitle}
                        />
                    </Grid>
                    <Grid item>
                        <Text variant="r12r">
                            <Truncate lines={2}>
                                {t('DashboardCard.AuthorAndCreatedDate', {
                                    author: author?.name,
                                    date: created,
                                })}
                            </Truncate>
                        </Text>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default DashboardCard;
