import React from 'react';
import {
    TextInput,
    Button,
    CircularIndeterminate,
    Box,
    Grid,
    Typography,
} from 'worldapp-ui/shared';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { loginCreator } from '../../redux/login/login';
import { LoginStatus } from '../../redux/login/login.types';
import { fromString } from '../../redux/login/portalId';
import { loginStyles } from './LoginPage.styles';
import BrandImage from './BrandImage';
import { getLoginState } from '../../redux/login/loginSelector';
import ChangeLanguageDropdown from './ChangeLanguageDropdown';
import ButtonWithIcon from '../Common/ButtonWithIcon';

export const ResetPasswordIcon = (props: { isLoading: boolean }): JSX.Element | null => {
    if (props.isLoading) {
        return <CircularIndeterminate contrastColor={true} />;
    }
    return null;
};

export const ResetPassword = (): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const loginState = useSelector(getLoginState, shallowEqual);

    const classes = loginStyles();

    const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(loginCreator.changeUsername(event.target.value));
    };

    const handleChangePortalId = (event: React.ChangeEvent<HTMLInputElement>) =>
        dispatch(loginCreator.changePortalId(fromString(event.target.value)));

    const handleResetPassword = () => dispatch(loginCreator.resetPassword());

    const handleCancelClick = () => dispatch(loginCreator.changeLoginStatus(LoginStatus.Login));

    return (
        <Grid container={true} spacing={2} direction="column" wrap="nowrap">
            <Grid item={true} className={classes.fullHeight}>
                <BrandImage className={classes.img} data-testid="brand-image_login" />
            </Grid>
            <Grid item={true}>
                <Box mb={4}>
                    <Typography
                        align="center"
                        variant="body1"
                        className={classes.text}
                        data-testid="login_submit-credentials"
                    >
                        {t('Login.SUBMIT_CREDENTIALS')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item={true}>
                <TextInput
                    label={t('Login.PORTAL_ID')}
                    onChange={handleChangePortalId}
                    fullWidth={true}
                    errorMessage={t(loginState.errorMessage)}
                    value={loginState.portalId}
                    data-testid="input_portal-id"
                />
            </Grid>
            <Grid item={true}>
                <TextInput
                    label={t('Login.USERNAME')}
                    onChange={handleChangeUsername}
                    value={loginState.username}
                    fullWidth={true}
                    errorMessage={t(loginState.errorMessage)}
                    data-testid="input_username"
                />
            </Grid>
            <Grid item={true}>
                <ButtonWithIcon
                    disabled={!loginState.nextStepEnabled}
                    icon={<ResetPasswordIcon isLoading={loginState.isLoading} />}
                    title={t('Login.RESET_PASSWORD')}
                    fullWidth={true}
                    onClick={handleResetPassword}
                    data-testid="button_reset-password"
                />
            </Grid>
            <Grid item={true}>
                <Button
                    fullWidth={true}
                    onClick={handleCancelClick}
                    data-testid="button_cancel"
                    variant="outlined"
                >
                    {t('Common.CANCEL')}
                </Button>
            </Grid>
            <Box mt={1}>
                <Grid container={true} className={classes.grid}>
                    <ChangeLanguageDropdown />
                </Grid>
            </Box>
            <Grid item={true}>
                <Box mb={2}>
                    <Typography align="center" variant="caption" className={classes.text}>
                        {t('Login.YOU_WILL_RECEIVE')}
                    </Typography>
                </Box>
                <Typography align="center" variant="caption" className={classes.text}>
                    {t('Login.CONTACT_ADMINISTRATOR')}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ResetPassword;
