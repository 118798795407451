import { makeStyles } from 'worldapp-ui';

export const useButtonStyles = makeStyles(() => ({
    button: {
        width: 46,
        height: 42,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonDisabled: {
        pointerEvents: 'none',
        cursor: 'default',
    },
}));
