import React from 'react';
import {
    Box,
    ButtonLink,
    createStyles,
    Grid,
    makeStyles,
    PasswordInput,
    Theme,
    Typography,
} from 'worldapp-ui/shared';
import { connect } from 'react-redux';

import { Trans, useTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { loginCreator } from '../../redux/login/login';
import { changePasswordCreator } from '../../redux/changePassword/changePassword';
import { handleInput, onKeyPressed } from '../../utils/keyboard.utils';
import { LoginButtonIcon } from './LoginButtonIcon';
import { useCleanup } from '../../hooks/useCleanup';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { useChangeFocus } from '../../hooks/useChangeFocus';
import {
    UpdatePasswordErrors,
    UpdatePasswordErrorType,
    UpdatePasswordState,
} from '../../redux/changePassword/changePassword.types';
import {
    getChangePasswordState,
    getIsChangePasswordAllowed,
    getIsChangePasswordButtonDisabled,
} from '../../redux/changePassword/changePasswordSelector';
import ChangeLanguageDropdown from './ChangeLanguageDropdown';
import { AppState } from '../../redux/AppState.types';
import { loginStyles } from './LoginPage.styles';
import ButtonWithIcon from '../Common/ButtonWithIcon';

const changePasswordStyles = makeStyles((theme: Theme) =>
    createStyles({
        text: {
            color: theme.palette.neutral[65],
            lineHeight: theme.typography.body1.lineHeight,
            display: 'block',
        },
        buttonLink: {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.body1.fontWeight,
        },
    }),
);
export const mapStateToProps = (state: AppState) => {
    return {
        ...getChangePasswordState(state),
        isChangeAllowed: getIsChangePasswordAllowed(state),
        isButtonDisabled: getIsChangePasswordButtonDisabled(state),
    };
};

export const mapDispatchToProps = mapCreatorsToDispatchProps({
    ...changePasswordCreator,
    switchPortal: loginCreator.switchPortal,
});

export type Props = UpdatePasswordState &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const getErrorMessageHelper = (
    errors: UpdatePasswordErrors,
    t: i18next.TFunction,
    errorType: UpdatePasswordErrorType,
): string | string[] => {
    const { message, display } = errors[errorType];
    if (message === null || !display) {
        return '';
    }
    if (errorType === UpdatePasswordErrorType.PASSWORD) {
        return message.split('\r\n').slice(1);
    }
    return t(message);
};

export const ChangePasswordComponent = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const loginClasses = loginStyles();
    const classes = changePasswordStyles();
    const getErrorMessage = getErrorMessageHelper.bind(null, props.errors, t);

    const handleErrorMessageOnBlur = (errorType: UpdatePasswordErrorType) => () => {
        props.toggleDisplayErrorMessage(errorType, true);
    };

    const handleChangePassword = (): void => {
        if (props.isChangeAllowed) {
            props.sendNewPassword();
        }
    };

    const passwordsStateManager = {
        showPassword: props.showPasswords,
        toggleShowPassword: (showPasswords: boolean) => props.toggleShowPasswords(showPasswords),
    };

    useCleanup(props.resetState);

    const { focusTarget, handleChangeFocus } = useChangeFocus();

    return (
        <Grid container={true} spacing={2} direction="column" wrap="nowrap">
            <Grid item={true}>
                <Box mb={2}>
                    <Typography align="center" variant="h2" color="primary">
                        {t('Login.ENTER_NEW_PASSWORD')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item={true}>
                <Box mb={2}>
                    <Typography
                        align="center"
                        variant="body1"
                        data-testid="login_switch-portal"
                        className={classes.text}
                    >
                        <Trans
                            defaults="Login.LoggedWithTemporaryPassword"
                            components={[
                                <br key="br-first" />,
                                <br key="br-second" />,
                                <ButtonLink
                                    key="switch-portal-link"
                                    onClick={props.switchPortal}
                                    underline="always"
                                    data-testid="button_switch-portal"
                                    className={classes.buttonLink}
                                >
                                    switch_portal
                                </ButtonLink>,
                            ]}
                        />
                    </Typography>
                </Box>
            </Grid>
            <Grid item={true}>
                <PasswordInput
                    label={t('Login.PASSWORD')}
                    onChange={handleInput(props.changeNewPassword)}
                    onBlur={handleErrorMessageOnBlur(UpdatePasswordErrorType.PASSWORD)}
                    fullWidth={true}
                    value={props.password}
                    errorMessage={getErrorMessage(UpdatePasswordErrorType.PASSWORD)}
                    data-testid="input_password"
                    stateManager={passwordsStateManager}
                    onKeyPress={onKeyPressed('Enter')(handleChangeFocus)}
                    inputFieldProps={{ autoFocus: true }}
                />
            </Grid>
            <Grid item={true}>
                <PasswordInput
                    label={t('Common.CONFIRM')}
                    onChange={handleInput(props.changeConfirmPassword)}
                    onBlur={handleErrorMessageOnBlur(UpdatePasswordErrorType.CONFIRM_PASSWORD)}
                    fullWidth={true}
                    value={props.confirmPassword}
                    errorMessage={
                        getErrorMessage(UpdatePasswordErrorType.GENERAL) ||
                        getErrorMessage(UpdatePasswordErrorType.CONFIRM_PASSWORD)
                    }
                    onKeyPress={onKeyPressed('Enter')(handleChangePassword)}
                    data-testid="confirm_password"
                    stateManager={passwordsStateManager}
                    inputFieldProps={focusTarget}
                />
            </Grid>
            <Grid item={true}>
                <ButtonWithIcon
                    disabled={props.isButtonDisabled}
                    icon={<LoginButtonIcon isLoading={props.isLoading} />}
                    title={t('Login.CONTINUE_TO_LOGIN')}
                    fullWidth={true}
                    onClick={handleChangePassword}
                    data-testid="button_login"
                />
            </Grid>
            <Box mt={1}>
                <Grid container={true} className={loginClasses.grid}>
                    <ChangeLanguageDropdown />
                </Grid>
            </Box>
        </Grid>
    );
};

export const ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordComponent);
