export const OPEN_ACTION_POPUP = 'ResponsesActions/OPEN_ACTION_POPUP';
export const CLOSE_ACTION_POPUP = 'ResponsesActions/CLOSE_ACTION_POPUP';
export const CHANGE_IS_LOADING = 'ResponsesActions/CHANGE_IS_LOADING';

export type ResponseAction = 'DeleteResponse';

interface InitialState {
    isLoading: boolean;
}

interface ResponseActionState extends InitialState {
    type: ResponseAction;
    formId: number;
    responseId: number;
}

export interface OpenPopupAbstractAction {
    type: typeof OPEN_ACTION_POPUP;
    action: ResponseAction;
    formId: number;
    responseId: number;
}

export interface ClosePopupAction {
    type: typeof CLOSE_ACTION_POPUP;
}

export interface OpenPopupDefaultAction extends OpenPopupAbstractAction {
    action: 'DeleteResponse';
}

export type OpenPopupAction = OpenPopupDefaultAction;

export interface ChangeIsLoadingAction {
    type: typeof CHANGE_IS_LOADING;
    isLoading: boolean;
}

export type ResponsesActionsAction = OpenPopupAction | ClosePopupAction | ChangeIsLoadingAction;

export interface DeleteResponseState extends ResponseActionState {
    type: 'DeleteResponse';
}

export interface NullState extends InitialState {
    type: 'NullState';
}

export type ResponsesActionsState = DeleteResponseState | NullState;
