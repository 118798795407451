import React from 'react';
import { MessageBox } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from '../../redux/AppState.types';
import { getFailedSSOLogin } from '../../redux/login/loginSelector';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { loginCreator } from '../../redux/login/login';
import { LoginStatus } from '../../redux/login/login.types';

/* istanbul ignore next */
const mapStateToProps = (state: AppState) => {
    return {
        userName: getFailedSSOLogin(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    changeLoginStatus: loginCreator.changeLoginStatus,
});

export type FailedSSOLoginProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const FailedSSOLoginComponent = ({
    userName,
    changeLoginStatus,
}: FailedSSOLoginProps): JSX.Element => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        changeLoginStatus(LoginStatus.Portal);
    };

    return (
        <MessageBox
            title={t('Login.FailedSSOLogin.Title')}
            open={true}
            confirmButton={{
                label: t('Login.FailedSSOLogin.ConfirmButton'),
                clickHandler: handleConfirm,
            }}
        >
            {t('Login.FailedSSOLogin.Message1', {
                userName,
            })}
            <br />
            <br />
            {t('Login.FailedSSOLogin.Message2', {
                userName,
            })}
        </MessageBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FailedSSOLoginComponent);
