import {
    CalendarAction,
    CalendarState,
    defaultCalendarView,
    SET_WEEKENDS_ENABLED,
    SET_SELECTED_DATE,
    CalendarView,
    CHANGE_CALENDAR_VIEW,
} from './calendar.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import {
    HYDRATE_STATE_FROM_STORAGE,
    HydrateStateFromStorageAction,
} from '../initialLoader/initialLoader.types';

export const initialState: CalendarState = {
    selectedDate: new Date(),
    weekendsEnabled: true,
    calendarView: defaultCalendarView,
};

export default function reducer(
    state = initialState,
    action: CalendarAction | HydrateStateFromStorageAction,
): CalendarState {
    switch (action.type) {
        case HYDRATE_STATE_FROM_STORAGE:
            const { calendar } = action.state;
            if (!calendar) return state;
            return {
                ...state,
                weekendsEnabled: calendar.weekendsEnabled ?? initialState.weekendsEnabled,
                calendarView: calendar.calendarView ?? initialState.calendarView,
            };
        case SET_WEEKENDS_ENABLED:
            return {
                ...state,
                weekendsEnabled: action.enabled,
            };
        case SET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.date,
            };
        case CHANGE_CALENDAR_VIEW:
            return {
                ...state,
                calendarView: action.calendarView,
            };
        default:
            return state;
    }
}

export const calendarCreator = {
    setWeekendsEnabled: (enabled: boolean): CalendarAction => ({
        enabled,
        type: SET_WEEKENDS_ENABLED,
    }),

    changeCalendarView:
        (calendarView: CalendarView): ThunkActionVoid =>
        (dispatch, _, { contentCreator, taskDefinitionAssigneesCreator }) => {
            dispatch({
                calendarView,
                type: CHANGE_CALENDAR_VIEW,
            });
            if (calendarView === CalendarView.Day)
                dispatch(taskDefinitionAssigneesCreator.fetchAssigneeValues(true));
            return dispatch(contentCreator.refresh(false));
        },

    setSelectedDate:
        (date: Date): ThunkActionVoid =>
        (dispatch, _, { contentCreator }) => {
            dispatch({ date, type: SET_SELECTED_DATE });
            return dispatch(contentCreator.refresh(false));
        },

    moveSelectedDate:
        (forward: boolean): ThunkActionVoid =>
        (dispatch, getState, { calendarSelector }) => {
            const calendarView = calendarSelector.getCalendarView(getState());
            const selectedDate = calendarSelector.getSelectedDate(getState());
            const date = new Date(selectedDate);
            const delta = forward ? 1 : -1;
            switch (calendarView) {
                case CalendarView.Day:
                    date.setDate(selectedDate.getDate() + delta);
                    break;
                case CalendarView.Week:
                    date.setDate(selectedDate.getDate() + delta * 7);
                    break;
                case CalendarView.Month:
                    date.setMonth(selectedDate.getMonth() + delta);
                    break;
            }

            dispatch(calendarCreator.setSelectedDate(date));
        },

    setPrevSelectedDate: (): ThunkActionVoid => calendarCreator.moveSelectedDate(false),
    setNextSelectedDate: (): ThunkActionVoid => calendarCreator.moveSelectedDate(true),
};
