import { createStyles, makeStyles } from 'worldapp-ui';
import { GRID_MOBILE_PADDING, GRID_PADDING } from '../ui/cards/cardsSizes';
import { CALENDAR_VALUES } from './TasksCalendar/TaskCalendar.styles';

export const gridContainerStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        columnGridContainer: {
            flexGrow: 1,
            overflow: 'auto',
            height: '100%',
            position: 'relative',
        },
    }),
);

export const useApplicationContentStyles = makeStyles(() =>
    createStyles({
        gridWrapper: {
            overflowY: 'auto',
            height: '100%',
        },
        loader: {
            marginTop: 20,
        },
        contentHeader: {
            paddingTop: GRID_PADDING,
            '&[data-ismobile="true"]': {
                paddingTop: 12,
            },
        },
        mobileFab: {
            position: 'absolute',
            bottom: GRID_MOBILE_PADDING,
            right: GRID_MOBILE_PADDING,
        },
        calendarContainer: {
            minWidth: `${
                CALENDAR_VALUES.MIN_TABLE_WIDTH + 2 * CALENDAR_VALUES.LEFT_RIGHT_PADDING
            }px`,
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
        },
    }),
);
