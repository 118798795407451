import produce from 'immer';
import { ContentAction, FORMS_CONTENT_INITIALIZE } from './content.types';

export type ContentStatusState = { formsInitialized: boolean };

export const initialState = { formsInitialized: false };

export const reducer = (
    state: ContentStatusState = initialState,
    action: ContentAction,
): ContentStatusState =>
    produce(state, (draft: ContentStatusState) => {
        switch (action.type) {
            case FORMS_CONTENT_INITIALIZE:
                draft.formsInitialized = true;
                break;
            default:
                return state;
        }
    });
