import { RefObject } from 'react';

export type ScrollParameters = {
    elementHeight: number;
    elementPadding: number;
    elementPosition: number;
    elementsCount: number;
    containerScrollHeightCorrection: number;
};

const calculateScroll = (
    scrollHeight: number,
    { elementHeight, elementPadding, elementPosition, elementsCount }: ScrollParameters,
): number => {
    const lines = Math.floor((scrollHeight + elementPadding) / (elementHeight + elementPadding));
    const elementsPerLine = Math.ceil(elementsCount / lines);
    const activeCardLine = Math.floor(elementPosition / elementsPerLine);
    return Math.floor((scrollHeight * activeCardLine) / lines - elementPadding / 2);
};

export const scrollToElement = (
    { current: scrollableTarget }: RefObject<HTMLDivElement>,
    params: ScrollParameters,
): void => {
    if (scrollableTarget == null) {
        return;
    }
    const { scrollHeight } = scrollableTarget;
    const scroll = calculateScroll(scrollHeight + params.containerScrollHeightCorrection, params);
    scrollableTarget.scrollTo(0, scroll);
};
