import React from 'react';
import { useSelector } from 'react-redux';
import { getTaskDetailViewTaskId } from '../../../redux/activeCard/activeCardSelectors';
import { TasksDetailsCarouselView } from './TasksDetailsCarouselView';

export const TaskDetailViewDialog = (): JSX.Element | null => {
    const isTaskDetailsOpen = useSelector(getTaskDetailViewTaskId);
    if (!isTaskDetailsOpen) return null;

    return <TasksDetailsCarouselView />;
};
