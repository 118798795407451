import { makeStyles, fade, lighten } from 'worldapp-ui';
import { GRID_MOBILE_PADDING, GRID_PADDING } from '../../ui/cards/cardsSizes';

export const TABLE_HEADER_HEIGHT = 48;
export const TABLE_ROW_HEIGHT = 36;

export const taskTableViewStyles = makeStyles(theme => ({
    actionPanel: {
        visibility: 'hidden',
        position: 'absolute',
        top: 0,
        background:
            'linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 15%,rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%)',
        height: '100%',
        paddingLeft: 50,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        '&:before': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
        },
    },
    table: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        cursor: 'default',
        borderCollapse: 'collapse',
        padding: `0 ${GRID_PADDING}px 8px `,
        boxSizing: 'border-box',
        '&[data-ismobile="true"]': {
            padding: `0 ${GRID_MOBILE_PADDING}px 64px`,
        },
    },
    tableHeader: {
        // width: '100%',
        height: TABLE_HEADER_HEIGHT,
        position: 'sticky',
        top: 0,
        zIndex: theme.zIndex.appBar,
        display: 'inline-block',
        background: theme.palette.neutral[6],
    },
    tableHeaderRow: {
        color: theme.palette.neutral.main,
        backgroundColor: theme.palette.common.white,
        border: `solid 1px ${fade(theme.palette.neutral[50], 0.24)}`,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        height: 'inherit',
        textAlign: 'left',
        display: 'inline-flex',
        overflow: 'hidden',
        width: 'calc(100% - 2px)',
    },
    tableHeaderRowScroll: {
        boxShadow: `0px 4px 4px ${fade(theme.palette.neutral.main, 0.15)}`,
    },
    th: {
        height: '100%',

        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },

        '& .MuiSvgIcon-root, & .color_default': {
            color: theme.palette.neutral[50],
        },
        '& .color_active': {
            color: theme.palette.primary.main,
        },
        '& .order_DESC': {
            transform: 'rotate(180deg)',
        },
        '& .typography': {
            lineHeight: `${TABLE_HEADER_HEIGHT - 2}px`, // 2 - height of top and bottom borders
        },
    },
    tableBody: {
        display: 'inline-block',
        borderTop: 'none',
        overflow: 'hidden',
        paddingBottom: 16,
    },
    tableBodyRow: {
        position: 'relative',
        alignItems: 'center',
        height: TABLE_ROW_HEIGHT,
        color: theme.palette.neutral.main,
        display: 'inline-flex',
        borderLeft: `1px solid ${fade(theme.palette.neutral[50], 0.24)}`,
        borderRight: `1px solid ${fade(theme.palette.neutral[50], 0.24)}`,
        overflow: 'hidden',
        '&[data-ismobile="true"]': {
            height: TABLE_HEADER_HEIGHT,
        },
        '&:nth-child(2n+1)': {
            '& $actionPanel:before': {
                background: `linear-gradient(to left, 
                ${fade(theme.palette.primary.main, 0)} 0%,
                ${fade(theme.palette.primary.main, 0.08)} 15%,
                ${fade(theme.palette.primary.main, 0.08)} 85%,
                ${fade(theme.palette.primary.main, 0)} 100%)`,
            },
            '& $tableMoreActionButton:before': {
                background: `linear-gradient(270deg, ${fade(
                    theme.palette.primary.main,
                    0.08,
                )} 32.53%, ${fade(theme.palette.primary.main, 0)} 100%)`,
            },

            '& $actionPanel $icon': {
                '&:hover': {
                    backgroundColor: fade(theme.palette.primary.main, 0.16),
                },
            },
        },
        '&:hover $actionPanel': {
            visibility: 'visible',
        },
        '&:hover': {
            boxShadow: `0px 4px 4px ${fade(theme.palette.neutral.main, 0.15)}`,
            zIndex: 1,
            '& + $tableBodyRowActive': {
                borderTopColor: 'transparent',
            },
        },
        '&:last-child': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            borderBottom: `1px solid ${fade(theme.palette.neutral[50], 0.24)}`,
            '&$tableBodyRowActive': {
                borderBottom: `1px solid ${theme.palette.primary.main}`,
            },
        },
    },
    tableBodyRowActive: {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    tableBodyRowActionActive: {
        cursor: 'pointer',
    },
    tableBodyRowHiglighted: {
        animationDuration: '7s',
        animationDelay: '0s',
        animationName: `$highlight`,
        animationTimingFunction: 'ease-in',
        zIndex: 1,
    },
    tableBodyRowInner: {
        display: 'inherit',
        width: '100%',
        height: '100%',
    },
    tableBodyRegularRow: {
        backgroundColor: theme.palette.common.white,
        '&:nth-child(2n+1)': {
            '& $td': {
                backgroundColor: fade(theme.palette.primary.main, 0.08),
            },
        },
    },
    tableBodyClosedRow: {
        color: theme.palette.neutral[75],
        backgroundColor: '#F6F7F7',
    },
    tableBodyOverdueRow: {
        color: theme.palette.rubyRed.main,
    },
    td: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingLeft: 25,
        paddingRight: theme.spacing(),
        fontSize: theme.typography.body1.fontSize,
        display: 'flex',
        alignItems: 'center',
    },
    selectionColumn: {
        paddingLeft: 10,
    },
    loader: {
        width: '100%',
        justifyContent: 'center',
    },
    action: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    icon: {
        width: 28,
        height: 28,
        padding: 0,
        minWidth: 'unset',
        borderRadius: 4,
        boxShadow: 'none',
        color: theme.palette.neutral[75],
        '&:hover': {
            color: theme.palette.neutral.main,
            backgroundColor: lighten(theme.palette.primary.main, 0.88),
            boxShadow: `0px 2px 4px ${fade(theme.palette.neutral.main, 0.1)}`,
        },
        '&:active': {
            borderColor: theme.palette.primary.main,
            backgroundColor: lighten(theme.palette.primary.main, 0.75),
        },
    },
    tableMoreActionButton: {
        position: 'absolute',
        right: 8,
        height: TABLE_HEADER_HEIGHT,
        width: 124,
        paddingRight: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: 'linear-gradient(270deg, #FFFFFF 32.53%, rgba(255, 255, 255, 0) 100%)',
        '&:before': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
        },
    },
    tableBodyRowActionInProgress: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    tableBodyRowLoader: {
        position: 'absolute',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
    },
    '@keyframes highlight': {
        '0%': {
            boxShadow: `0px 4px 4px ${fade(theme.palette.neutral.main, 0.15)}`,
        },
        '90%': {
            boxShadow: `0px 4px 4px ${fade(theme.palette.neutral.main, 0.15)}`,
        },
        '100%': {
            boxShadow: 'none',
        },
    },
    // Tooltip
    tooltipContainer: {
        padding: '4px 8px',
    },
    tooltipContent: {
        color: `${theme.palette.neutral[65]}`,
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
}));
