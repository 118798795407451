import { createSelector } from 'reselect';
import { AppState } from '../AppState.types';
import { getForms, getFormsIds } from '../forms/formsSelector';
import { getStartFormSearchValue } from '../startForm/startFormSelector';
import { isTaskDefinition } from '../taskDefinitions/taskDefinitions.utils';
import {
    getTaskDefinitionsIds,
    getTDsWithCreateTask,
} from '../taskDefinitions/taskDefinitionsSelector.reselect';
import { ItemsByDates } from './frequentlyUsed.types';
import { filterItemsByIds, mergeCounts } from './frequentlyUsed.utils';

export const getFrequentlyUsedItems = ({ frequentlyUsed }: AppState): ItemsByDates =>
    frequentlyUsed.itemsByDates;

export const getFrequentlyUsedItemsCounts = createSelector(
    getFrequentlyUsedItems,
    getTaskDefinitionsIds,
    getFormsIds,
    (itemsByDates, tdsIds, formsIds) => {
        const unfilteredCounts = Object.values(itemsByDates).reduce(
            (acc, date) => {
                const { taskDefinitions, forms } = date;
                const updatedTds = mergeCounts(acc.taskDefinitions, taskDefinitions);
                const updatedForms = mergeCounts(acc.forms, forms);
                return { taskDefinitions: updatedTds, forms: updatedForms };
            },
            { taskDefinitions: {}, forms: {} },
        );

        const filteredTdsCount = filterItemsByIds(unfilteredCounts.taskDefinitions, tdsIds);
        const filteredFormsCount = filterItemsByIds(unfilteredCounts.forms, formsIds);

        return {
            taskDefinitions: filteredTdsCount,
            forms: filteredFormsCount,
        };
    },
);

export const getFrequentlyUsedItemsLength = createSelector(
    getFrequentlyUsedItemsCounts,
    itemsCounts => {
        return (
            Object.keys(itemsCounts.taskDefinitions).length + Object.keys(itemsCounts.forms).length
        );
    },
);

export const getStartFormItems = createSelector(
    getTDsWithCreateTask,
    getForms,
    getFrequentlyUsedItemsCounts,
    (tds, forms, itemsCounts) => {
        const items = [...tds, ...forms];

        return items.sort((prevItem, item) => {
            const prevCount = isTaskDefinition(prevItem)
                ? itemsCounts.taskDefinitions[prevItem.id]
                : itemsCounts.forms[prevItem.id];
            const count = isTaskDefinition(item)
                ? itemsCounts.taskDefinitions[item.id]
                : itemsCounts.forms[item.id];

            if (!prevCount && count) return 1;
            if (prevCount && !count) return -1;
            if (prevCount && count && prevCount !== count) return count - prevCount;
            return prevItem.title.toLowerCase() > item.title.toLowerCase() ? 1 : -1;
        });
    },
);

export const getStartFormLists = createSelector(
    getStartFormItems,
    getFrequentlyUsedItemsLength,
    getStartFormSearchValue,
    (items, frequentlyUsedItemsLength, searchValue) => {
        const separatorIndex = frequentlyUsedItemsLength < 3 || items.length < 5 ? 0 : 3;
        return {
            frequentlyUsedItems: items.slice(0, separatorIndex),
            items: items
                .slice(separatorIndex)
                .filter(item => item.title.toLowerCase().includes(searchValue.toLowerCase())),
        };
    },
);
