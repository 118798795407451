import { useSelector } from 'react-redux';
import React from 'react';
import { getSSOLogoutLink } from '../redux/login/loginSelector';
import { ActionCallbacks, useActionCreators } from '../hooks/useActionCreators';

export const actionCreatorsSelector =
    ({ goToURLCreator }: ActionCallbacks) =>
    (): void =>
        goToURLCreator.gotoLoginPage(null);

export const SSOLogoutFrame = (): JSX.Element | null => {
    const gotoLoginPage = useActionCreators(actionCreatorsSelector);
    const link = useSelector(getSSOLogoutLink);

    if (!link) {
        return null;
    }
    return <iframe src={link} onLoad={gotoLoginPage} />;
};
