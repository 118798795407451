import { NumericDictionary } from 'worldapp-fe-utils';

export const SET_EXPORT_STATUS = 'TasksExport/SET_EXPORT_STATUS';
export const START_EXPORT = 'TasksExport/START_EXPORT';

export type TasksExportState = NumericDictionary<TasksExportStatus>;

export type TasksExportStatus = {
    location: string;
    progress?: string;
    total?: number;
    current?: number;
};

export interface GetTaskExportStatusAction {
    taskDefinitionId: number;
    status: TasksExportStatus;
    currentTaskId?: number;
    type: typeof SET_EXPORT_STATUS;
}
export interface StartExportStatusAction {
    type: typeof START_EXPORT;
}

export type TasksExportAction = GetTaskExportStatusAction | StartExportStatusAction;
