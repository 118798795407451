import { Category } from '../redux/content/category.types';

export const ALL_TASKS_ID = -1;
export const DASHBOARDS_ITEM_ID = -1;

export enum ActivePanelType {
    Main = 'Main',
    CustomLinks = 'CustomLinks',
    Languages = 'Languages',
}

export interface BottomNavigationListElement {
    readonly id: ActivePanelType;
    readonly title: string;
}

export const bottomNavigationList = [
    {
        id: ActivePanelType.Languages,
        title: 'NavigationPanel.LanguageTab',
    },
    {
        id: ActivePanelType.CustomLinks,
        title: 'NavigationPanel.CustomLinksTab',
    },
] as const;

export interface NavigationTreeItem {
    category: Category;
    title: string;
}

export const NavigationTreeItems: Record<Category, NavigationTreeItem> = {
    tasks: {
        category: 'tasks',
        title: 'Tasks',
    },
    forms: {
        category: 'forms',
        title: 'Forms',
    },
    dashboards: {
        category: 'dashboards',
        title: 'Dashboards',
    },
} as const;
