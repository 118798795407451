import React, {
    useCallback,
    useRef,
    useState,
    FunctionComponent,
    RefObject,
    useEffect,
} from 'react';
import { useThrottleCallback } from '@react-hook/throttle';
import { useSelector } from 'react-redux';
import {
    useTable,
    useRowSelect,
    useBlockLayout,
    Row,
    Cell,
    useColumnOrder,
    TableInstance,
} from 'react-table';
import classNames from 'classnames';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd_fork';

import { useTheme, CircularContouredLoader, Tooltip, Text } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT, taskTableViewStyles } from './TaskTableViewStyles';
import { TableViewColumn } from './table.types';
import TaskTableRow from './TaskTableRow';
import {
    isMainTaskAction,
    isSecondaryTaskAction,
    TaskActionTable,
    MainTaskAction,
} from '../../../redux/content/validateTaskActions';
import { handleTableScroll } from '../../../utils/table.utils';
import { TableHeaderColumn } from './TableHeaderColumn';

import { tableSelector } from '../../../redux/tasksTable/taskTableSelector';
import { useActionCreators } from '../../../hooks/useActionCreators';

import NothingFound from '../../ui/nothingFound/NothingFound';
import { scrollToElement } from '../../../utils/scroll.utils';
import { useScrollTable } from './useScrollTable';
import { isSearchQueryNotEmpty } from '../../../redux/content/contentSelector';
import TableSelectedControl from './TableSelectedControl';

import { getSelectedTasksMap } from '../../../redux/tasksSelectedActions/tasksSelectedActionsSelector';
import { GRID_PADDING } from '../../ui/cards/cardsSizes';
import { isMobile } from '../../../utils/browser.utils';
import { getActiveCardPosition } from '../../../redux/activeCard/activeCardSelectors';

interface TasksTableProps {
    containerWidth: number;
    isLoading?: boolean;
}

const THROTTLE_INTERVAL = 50;

export const getOnDragEnd = (
    allColumns: TableViewColumn[],
    callback: (order: string[]) => void,
) => {
    return ({ source, destination, draggableId }: DropResult): JSX.Element | undefined => {
        if (!destination) return;
        if (source.index === destination.index && source.droppableId === destination.droppableId) {
            return;
        }

        const order = allColumns.map((col: TableViewColumn) => col.id);
        order.splice(source.index, 1);
        order.splice(destination.index, 0, draggableId);

        callback(order);
    };
};

export const TaskTableView: FunctionComponent<TasksTableProps> = props => {
    const { containerWidth, isLoading } = props;
    const [isScrolled, changeIsScrolled] = useState(false);
    const [scrollLeft, changeScrollLeft] = useState(0);

    const tableRef = useRef(null) as RefObject<HTMLDivElement>;

    const { t } = useTranslation();

    const scrollBarWidth =
        (tableRef.current?.offsetWidth || 0) - (tableRef.current?.clientWidth || 0);

    const {
        tasksSortCreator,
        tasksActionsCreator,
        activeCardCreator,
        tasksTableCreator,
        contentCreator,
        tasksSelectedActionsCreator,
        tasksExportCreator,
    } = useActionCreators();

    // Redux selector
    const tableProps = useSelector(tableSelector(containerWidth - scrollBarWidth));
    const hasSearchQuery = useSelector(isSearchQueryNotEmpty);
    const activeElementPosition = useSelector(getActiveCardPosition);

    const selectedTasks = useSelector(getSelectedTasksMap);

    // react-table hook
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        dispatch,
    } = useTable(
        {
            columns: tableProps.columns,
            data: tableProps.data,
            autoResetSelectedRows: false,
            initialState: {
                selectedRowIds: selectedTasks,
            },
            useControlledState: (newState: any) => {
                return { ...newState, selectedRowIds: selectedTasks };
            },
            getRowId: (row: any) => row.taskId,
        } as any,
        useBlockLayout,
        useColumnOrder,
        useRowSelect,
        hooks =>
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }: any) => (
                        <TableSelectedControl
                            checkboxProps={getToggleAllRowsSelectedProps()} // fixme
                            onAllTasksClick={
                                tasksSelectedActionsCreator.toggleAllLoadedTasksSelected
                            }
                        />
                    ),
                    width: 50,
                    Cell: ({ row }: any) => (
                        <TableSelectedControl
                            taskId={tableProps.rowData[row.index].taskId}
                            checkboxProps={row.getToggleRowSelectedProps()}
                            onTaskClick={tasksSelectedActionsCreator.toggleTaskSelected}
                        />
                    ),
                },
                ...columns,
            ]),
    ) as TableInstance<Record<string, unknown>>;

    useEffect(() => {
        dispatch({ type: 'reselectAllRows' });
    }, [dispatch, tableProps.data.length]);

    useScrollTable(
        () =>
            scrollToElement(tableRef, {
                elementPadding: 0,
                elementHeight: TABLE_ROW_HEIGHT,
                elementsCount: tableProps.data.length,
                elementPosition: activeElementPosition,
                containerScrollHeightCorrection: -TABLE_HEADER_HEIGHT,
            }),
        tableProps.data.length === 0,
    );
    // Callbacks
    const theme = useTheme();
    const handleHeaderClick = useCallback(
        (columnId: string) => () => {
            const clickedColumn = tableProps.columnsById[columnId];
            if (clickedColumn && clickedColumn.sortColumn) {
                tasksSortCreator.changeSortColumnOrOrder(clickedColumn.sortColumn);
            }
        },
        [tasksSortCreator, tableProps.columnsById],
    );

    const handleActionClick = useCallback(
        (action: TaskActionTable, taskId: number, taskDefinitionId: number) => {
            if (isMainTaskAction(action)) {
                tasksActionsCreator.dispatchMainAction(action, taskId, taskDefinitionId);
            } else if (action === 'Export') {
                tasksExportCreator.exportTasks(taskId);
            } else if (isSecondaryTaskAction(action)) {
                tasksActionsCreator.openActionPopup(action, taskId);
            } else if (action === 'OpenTaskDetails') {
                activeCardCreator.openTaskDetailView(taskId);
            }
        },
        [tasksActionsCreator, activeCardCreator, tasksExportCreator],
    );

    const loadContent = () => contentCreator.loadNext('tasks', tableProps.itemId);

    const handleScrollThrottle = useThrottleCallback(
        () =>
            handleTableScroll(
                tableRef,
                isScrolled,
                scrollLeft,
                changeIsScrolled,
                changeScrollLeft,
                loadContent,
                tableProps.hasMore,
            ),
        THROTTLE_INTERVAL,
    );

    const handleRowClick = useCallback(
        (action: MainTaskAction, taskId: number, taskDefinitionId: number) => {
            if (action === 'ViewForm' || action === 'FillOutForm')
                tasksActionsCreator.dispatchMainAction(action, taskId, taskDefinitionId);
        },
        [tasksActionsCreator],
    );

    const handleRowOutsideClick = useCallback(() => {
        activeCardCreator.clearActiveCard();
    }, [activeCardCreator]);

    // End of callbacks

    // Styling table
    const classes = taskTableViewStyles();

    const hiddenContentWidth = tableProps.rowWidth - containerWidth;
    const calculatedDistanceToRight =
        hiddenContentWidth - scrollLeft + scrollBarWidth + GRID_PADDING;
    const distanceToRight = calculatedDistanceToRight > 0 ? calculatedDistanceToRight : 0;

    // Render the UI for table
    if (tableProps.data.length === 0) {
        if (isLoading) return <CircularContouredLoader />;
        return <NothingFound hasSearchQuery={hasSearchQuery} label={t('TaskCardsGrid.NoTasks')} />;
    }

    return (
        <>
            {/* Table */}
            <div
                {...getTableProps()}
                className={classes.table}
                onScroll={handleScrollThrottle}
                ref={tableRef}
                data-testid="tasks-table"
                data-ismobile={isMobile}
            >
                {/* Table Header */}
                <div className={classes.tableHeader} role="row">
                    <DragDropContext
                        onDragEnd={getOnDragEnd(
                            allColumns as TableViewColumn[],
                            tasksTableCreator.changeColumnsOrder,
                        )}
                        disableVerticalAutoScroll={true}
                    >
                        <Droppable droppableId="droppable" direction="horizontal">
                            {provided => (
                                <div
                                    {...headerGroups[0].getHeaderGroupProps()}
                                    {...provided.droppableProps}
                                    role="columnheader"
                                    className={classNames(
                                        classes.tableHeaderRow,
                                        isScrolled && classes.tableHeaderRowScroll,
                                    )}
                                    ref={provided.innerRef}
                                    style={{
                                        backgroundColor: theme.palette.white?.main,
                                    }}
                                >
                                    <div style={{ display: 'none' }}>{provided.placeholder}</div>
                                    {/* Header Columns */}
                                    {allColumns.map((column: any, index: number) => {
                                        const headerProps = column.getHeaderProps
                                            ? column.getHeaderProps()
                                            : {};
                                        delete headerProps.role;
                                        return (
                                            <TableHeaderColumn
                                                key={column.id}
                                                column={column as TableViewColumn}
                                                className={classNames(classes.th, {
                                                    [classes.selectionColumn]:
                                                        column.id === 'selection',
                                                })}
                                                headerProps={headerProps}
                                                handleClick={handleHeaderClick}
                                                index={index}
                                                sortDirection={tableProps.sortDirection}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                {/* Table Body */}
                <div {...getTableBodyProps()} className={classes.tableBody}>
                    {/* Table Rows */}
                    {rows.map((row: Row<Record<string, unknown>>) => {
                        prepareRow(row);
                        const rowDataItem = tableProps.rowData[row.index];
                        const rowProps = row.getRowProps ? row.getRowProps() : {};

                        return (
                            <TaskTableRow
                                {...rowProps}
                                key={rowDataItem.taskId}
                                classes={classes}
                                distanceToRight={distanceToRight}
                                rowDataItem={rowDataItem}
                                onRowClick={handleRowClick}
                                onActionClick={handleActionClick}
                                isTaskPopupOpened={tableProps.isTaskPopupOpened}
                                onRowOutsideClick={handleRowOutsideClick}
                            >
                                {/* Row cells */}
                                {row.cells.map(
                                    (cell: Cell<Record<string, unknown>>, ind: number) => {
                                        const cellProps = cell.getCellProps
                                            ? cell.getCellProps()
                                            : {};
                                        // Cell
                                        const cellContent = (
                                            <div
                                                {...cellProps}
                                                key={ind}
                                                className={classNames(classes.td, {
                                                    [classes.selectionColumn]:
                                                        cell.column.id === 'selection',
                                                })}
                                                style={{ width: cell.column.width }}
                                                data-testid={`tasks-table_cell_${
                                                    cell.column.Header instanceof Function
                                                        ? ''
                                                        : cell.column.Header
                                                }`}
                                            >
                                                {cell.render('Cell')}
                                            </div>
                                        );
                                        return cell.column.id === 'summary' ? (
                                            <Tooltip
                                                key={ind}
                                                maxWidth={400}
                                                title={
                                                    <div className={classes.tooltipContainer}>
                                                        <Text
                                                            variant="r14r"
                                                            className={classes.tooltipContent}
                                                        >
                                                            {cell.render('Cell')}
                                                        </Text>
                                                    </div>
                                                }
                                            >
                                                {cellContent}
                                            </Tooltip>
                                        ) : (
                                            cellContent
                                        );
                                    },
                                )}
                            </TaskTableRow>
                        );
                    })}
                    {tableProps.hasMore && (
                        <div
                            id="infinite-scroll_loader"
                            className={classNames(classes.tableBodyRow, classes.loader)}
                            role="row"
                        >
                            <CircularContouredLoader
                                aria-label={t('TaskCardsGrid.NoTasks')}
                                role="cell"
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
