import { ALL_TASKS_ID, DASHBOARDS_ITEM_ID } from '../constants/navigation.constants';
import { AppState } from './AppState.types';
import { CONTENT_CATEGORY_DASHBOARDS, CONTENT_CATEGORY_TASKS } from './content/category.types';
import { getDashboardsTabName, getTasksTabName } from './portalSettings/portalSettingsSelector';
import i18n from '../localization/i18n';

export function selectMainHeader(state: AppState): string {
    const { content } = state;
    if (!content) return '';
    const { category: activeCategory, itemId: activeItemId } = content;
    if (!activeItemId) return '';

    if (activeCategory === CONTENT_CATEGORY_TASKS && activeItemId === ALL_TASKS_ID) {
        return i18n.t('MainHeader.AllTasks', { tasksTabName: getTasksTabName(state) });
    }

    if (activeCategory === CONTENT_CATEGORY_DASHBOARDS && activeItemId === DASHBOARDS_ITEM_ID) {
        return getDashboardsTabName(state);
    }

    const findTitleById = (collection: Array<{ id: number; title: string }>, id: number) => {
        const item = collection.find(value => value.id === id);
        return item ? item.title : '';
    };

    if (activeCategory === CONTENT_CATEGORY_TASKS) {
        const taskDefinition = state.taskDefinitions.taskDefinitionsById[activeItemId];
        return taskDefinition !== undefined ? taskDefinition.title : '';
    }

    return findTitleById(state.forms.forms, activeItemId);
}
