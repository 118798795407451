import produce from 'immer';
import {
    TaskDefinitionAssigneesAction,
    TaskDefinitionAssigneesState,
    FETCH_ASSIGNEE_VALUES,
    SET_ASSIGNEE_VALUES_LOADING,
} from './taskDefinitionAssignees.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionPromise } from '../index';

export const DEFAULT_ASSIGNEE_LIMIT = 50;

export const initialState: TaskDefinitionAssigneesState = {
    assigneeValues: [],
    hasMoreValues: false,
    offset: 0,
};

export const reducer = (
    state = initialState,
    action: TaskDefinitionAssigneesAction,
): TaskDefinitionAssigneesState =>
    produce(state, (draft: TaskDefinitionAssigneesState) => {
        switch (action.type) {
            case FETCH_ASSIGNEE_VALUES: {
                draft.assigneeValues = action.shouldResetState
                    ? action.assigneeValues
                    : [...draft.assigneeValues, ...action.assigneeValues];

                draft.hasMoreValues = draft.assigneeValues.length < action.total;
                draft.offset = action.shouldResetState
                    ? DEFAULT_ASSIGNEE_LIMIT
                    : draft.offset + DEFAULT_ASSIGNEE_LIMIT;

                return;
            }
            case SET_ASSIGNEE_VALUES_LOADING: {
                draft.assigneeValues = [];
                draft.hasMoreValues = true;
                return;
            }
            default:
                return draft;
        }
    });

export const taskDefinitionAssigneesCreator = {
    fetchAssigneeValues:
        (shouldResetState?: boolean): ThunkActionPromise =>
        async (dispatch, getState, { api, contentSelector, taskDefinitionAssigneesSelector }) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getContentItemId(state);
            if (!contentSelector.isContentCategoryTasks(state) || !taskDefinitionId) return;
            const offset = shouldResetState ? 0 : taskDefinitionAssigneesSelector.getOffset(state);
            if (shouldResetState) {
                dispatch({ type: SET_ASSIGNEE_VALUES_LOADING });
            }

            const response = await api.getAssigneeValues(
                taskDefinitionId,
                DEFAULT_ASSIGNEE_LIMIT,
                offset,
            );

            dispatch({
                type: FETCH_ASSIGNEE_VALUES,
                assigneeValues: response.items,
                total: response.total,
                shouldResetState,
            });
        },
};
