import React from 'react';
import { connect } from 'react-redux';
import { DefaultPopup, makeStyles, TabsControl } from 'worldapp-ui/shared';
import { DefaultPopupProps } from 'worldapp-ui/shared/popup/DefaultPopup';
import { TabsControlItem } from 'worldapp-ui/shared/tabs/TabsControl';
import { useTranslation } from 'react-i18next';
import { defaultPopupButtonsDirectionOptions } from 'worldapp-ui/shared/popup/components/DefaultPopupFooter';
import {
    getIsFiltersPopupOpen,
    getPopupStage,
    isFiltersPopupLoading,
} from '../../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { tasksConfigurableFiltersCreator } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import { FiltersPopupStage } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters.types';
import ResetAllFiltersButton from '../ResetAllFiltersButton';
import FiltersListContent from '../Content/FiltersListContent';
import FiltersSetsContent from '../Content/FiltersSetsContent';
import { AppState } from '../../../redux/AppState.types';

export interface FiltersPopupOwnProps {
    openMessageBox: () => void;
}

export interface TaskConfigurableFiltersPopupProps extends FiltersPopupOwnProps {
    currentStage: FiltersPopupStage;
    isPopupOpen: boolean;
    showLoader: boolean;
    applyFilters: () => void;
    changePopupStage: (stage: FiltersPopupStage) => void;
}

const mapStateToProps = (state: AppState) => {
    return {
        currentStage: getPopupStage(state),
        isPopupOpen: getIsFiltersPopupOpen(state),
        showLoader: isFiltersPopupLoading(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    applyFilters: tasksConfigurableFiltersCreator.applyFilters,
    changePopupStage: tasksConfigurableFiltersCreator.changePopupStage,
});

const useTaskConfigurableFiltersStyles = makeStyles(() => ({
    popupContentWrapper: {
        height: '100vh',
    },
    stickyWrapper: {
        paddingTop: 12,
    },
}));

export const TaskConfigurableFiltersPopupComponent = (
    props: TaskConfigurableFiltersPopupProps,
): JSX.Element | null => {
    const {
        currentStage,
        isPopupOpen,
        showLoader,
        applyFilters,
        changePopupStage,
        openMessageBox,
    } = props;
    const { t } = useTranslation();
    const classes = useTaskConfigurableFiltersStyles();

    if (!isPopupOpen) return null;

    const handleGoToSetsTab = () => {
        changePopupStage(FiltersPopupStage.FiltersSetsTab);
    };

    const handleGoToGeneralFiltersTab = () => {
        changePopupStage(FiltersPopupStage.GeneralFiltersTab);
    };

    const handlePopupClose = () => {
        changePopupStage(FiltersPopupStage.Closed);
    };

    const tabsControlItems: TabsControlItem[] = [
        {
            value: FiltersPopupStage.GeneralFiltersTab,
            label: t('TaskFilters.GeneralFiltersTab'),
            onClick: handleGoToGeneralFiltersTab,
        },
        {
            value: FiltersPopupStage.FiltersSetsTab,
            label: t('TaskFilters.FiltersSetsTab'),
            onClick: handleGoToSetsTab,
        },
    ];

    const stickyContent = (
        <TabsControl
            items={tabsControlItems}
            value={currentStage}
            className={classes.stickyWrapper}
        />
    );
    const headerContent =
        currentStage === FiltersPopupStage.GeneralFiltersTab ? (
            <ResetAllFiltersButton />
        ) : undefined;
    const additionalButton =
        currentStage === FiltersPopupStage.GeneralFiltersTab
            ? { label: t('TaskFilters.CreateFiltersSetButton'), clickHandler: openMessageBox }
            : undefined;

    const popupProps: DefaultPopupProps = {
        id: 'TaskConfigurableFiltersPopup',
        showBlockingLoader: showLoader,
        open: isPopupOpen,
        stickyContent,
        header: {
            title: t('TaskFilters.FiltersPopupTitle'),
            headerAdditionalContent: headerContent,
        },

        footer: {
            confirmButton: {
                label: t('TaskFilters.ApplyFiltersButton'),
                clickHandler: applyFilters,
            },
            cancelButton: {
                label: t('Common.CANCEL'),
                clickHandler: handlePopupClose,
            },
            additionalButton,
            mobileButtonsDirection: defaultPopupButtonsDirectionOptions.ROW,
        },
        contentWrapperClassName: classes.popupContentWrapper,
    };

    const popupContent =
        currentStage === FiltersPopupStage.GeneralFiltersTab ? (
            <FiltersListContent />
        ) : (
            <FiltersSetsContent openMessageBox={openMessageBox} />
        );

    return <DefaultPopup {...popupProps}>{popupContent}</DefaultPopup>;
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskConfigurableFiltersPopupComponent);
