// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '..';
import { getContentCategory, getContentItemId } from '../content/contentSelector';
import { getTdIdsWithCreateTask } from '../taskDefinitions/taskDefinitionsSelector';
import {
    CHANGE_SEARCH_VALUE,
    CHANGE_SELECTED_ITEM,
    CLEAR_START_FORM,
    StartFormAction,
    StartFormState,
} from './startForm.types';
import { generateStartFormId } from './startForm.utils';

export const initialState: StartFormState = {
    searchValue: '',
    selectedItem: '',
};

export default function reducer(state = initialState, action: StartFormAction): StartFormState {
    switch (action.type) {
        case CHANGE_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.searchValue,
            };
        case CHANGE_SELECTED_ITEM:
            return {
                ...state,
                selectedItem: action.selectedItem,
            };
        case CLEAR_START_FORM:
            return initialState;
        default:
            return state;
    }
}

export const startFormCreator = {
    changeSearchValue: (searchValue: string): ThunkActionVoid => {
        return dispatch => {
            dispatch({ searchValue, type: CHANGE_SEARCH_VALUE });
        };
    },

    changeSelectedItem: (selectedItem: string): ThunkActionVoid => {
        return dispatch => {
            dispatch({ selectedItem, type: CHANGE_SELECTED_ITEM });
        };
    },

    clearStartForm: (): ThunkActionVoid => dispatch => {
        dispatch({ type: CLEAR_START_FORM });
    },

    selectStartFormItem:
        (): ThunkActionVoid =>
        (dispatch, getState, { startFormCreator: creator }) => {
            const state = getState();
            const category = getContentCategory(state);
            const itemId = getContentItemId(state);

            if (category === null || itemId === null) return;

            const tdIdsWithCreateTask = getTdIdsWithCreateTask(state);
            if (category === 'tasks' && tdIdsWithCreateTask.includes(itemId)) {
                const startFormItem = generateStartFormId('td', itemId);
                dispatch(creator.changeSelectedItem(startFormItem));
            } else if (category === 'forms') {
                const startFormItem = generateStartFormId('form', itemId);
                dispatch(creator.changeSelectedItem(startFormItem));
            }
        },
};
