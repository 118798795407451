import React from 'react';
import { Icon, IconButton, Tooltip } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { useButtonStyles } from './Buttons.styles';

export interface DeleteButtonProps {
    onClick: () => void;
}

const DeleteButton = (props: DeleteButtonProps): JSX.Element => {
    const { onClick } = props;
    const classes = useButtonStyles();
    const { t } = useTranslation();

    return (
        <Tooltip
            title={t('ResponseActions.DeleteResponse.IconLabel') as string}
            placement="bottom-start"
        >
            <div className={classes.button} onClick={onClick} data-testid="delete-button">
                <IconButton
                    icon={<Icon name="trash" mode="stroke" />}
                    aria-label={t('ResponseActions.DeleteResponse.IconLabel')}
                />
            </div>
        </Tooltip>
    );
};

export default DeleteButton;
