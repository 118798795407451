import React from 'react';
import { connect } from 'react-redux';
import { MenuList } from 'worldapp-ui/shared';
import classNames from 'classnames';
import { Form } from '../../../../redux/forms/forms.types';
import useMenuListStyles from '../MenuList.styles';
import { AppState } from '../../../../redux/AppState.types';
import { getIsDrawerOpened } from '../../../../redux/menu/menuSelector';
import { getForms } from '../../../../redux/forms/formsSelector';
import NavigationTreeMenuItem from '../NavigationTreeMenuItem';

export const mapStateToProps = (state: AppState) => ({
    forms: getForms(state),
    isDrawerOpen: getIsDrawerOpened(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
    onItemSelect: (itemId: number) => void;
    selectedItem: number | null;
    iOSView?: boolean;
};

export const FormsList = (props: Props): JSX.Element | null => {
    const { isDrawerOpen, forms, selectedItem, onItemSelect, iOSView = false } = props;
    const classes = useMenuListStyles();

    if (!forms.length) {
        return null;
    }
    const fullView = isDrawerOpen || iOSView;
    const onItemSelectHandler = (itemId: number) => () => onItemSelect(itemId);

    return (
        <MenuList
            className={classNames(classes.menuList, {
                [classes.menuListCompactView]: fullView,
            })}
        >
            {forms.map((form: Form) => (
                <NavigationTreeMenuItem
                    key={`${form.id} ${form.title}`}
                    isMenuOpen={fullView}
                    title={form.title}
                    isSelected={selectedItem === form.id}
                    // count={form.settings.totalVotedResponses}
                    onClick={onItemSelectHandler(form.id)}
                    data-testid={`forms-item-${form.id}`}
                    autoFocus={selectedItem === form.id}
                />
            ))}
        </MenuList>
    );
};

export default connect(mapStateToProps)(FormsList);
