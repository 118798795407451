import React from 'react';
import Badge from 'worldapp-ui/shared/badges/Badge';
import { Grid, Theme, createStyles, makeStyles, Icon } from 'worldapp-ui/shared';
import FilterButton from 'worldapp-ui/shared/buttons/FilterButton';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { contentQuickFiltersCreator } from '../../redux/contentQuickFilters/contentQuickFilters';
import { TaskQuickFilters } from '../../redux/contentQuickFilters/contentQiuckFilters.types';
import { tasksConfigurableFiltersCreator } from '../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import {
    getFoundTasksCount,
    getQuickFilterButtons,
    getTaskQuickFilters,
    getTotalTasksCount,
} from '../../redux/contentQuickFilters/contentQuickFiltersSelector';
import { getAppliedFiltersCount } from '../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { TasksFiltersPopupHandler } from '../ConfigurableFilters/Popup/TasksFiltersPopupHandler';
import { AppState } from '../../redux/AppState.types';
import { isMobile } from '../../utils/browser.utils';
import { GRID_MOBILE_PADDING } from '../ui/cards/cardsSizes';

const useFilterButtonsContainerStyles = makeStyles((theme: Theme) =>
    createStyles({
        badge: {
            margin: 0,
        },
        found: {
            lineHeight: `${theme.spacing(4)}px`,
            color: theme.palette.neutral[65],
            margin: theme.spacing(1, 0.5),
        },
        container: {
            flexWrap: 'nowrap',
            overflowX: 'auto',
            margin: '0 0 0 -4px',
            width: 'auto' /* https://github.com/mui-org/material-ui/issues/17142 */,
            paddingRight: 8,
            paddingTop: 8,
            '&[data-ismobile="true"]': {
                paddingLeft: GRID_MOBILE_PADDING,
                paddingRight: GRID_MOBILE_PADDING,
            },
        },
        filterButton: {
            whiteSpace: 'nowrap',
        },
    }),
);

const mapDispatchToProps = mapCreatorsToDispatchProps({
    changeQuickFilter: contentQuickFiltersCreator.changeQuickFilter,
    openConfigurableFilters: tasksConfigurableFiltersCreator.openFiltersPopup,
});

export const mapStateToProps = (state: AppState) => ({
    appliedFiltersCount: getAppliedFiltersCount(state),
    buttons: getQuickFilterButtons(state),
    filters: getTaskQuickFilters(state),
    foundTasks: getFoundTasksCount(state),
    totalTasks: getTotalTasksCount(state),
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export const TaskQuickFilterComponent = ({
    appliedFiltersCount,
    buttons,
    filters,
    changeQuickFilter,
    openConfigurableFilters,
}: Props): JSX.Element => {
    const classes = useFilterButtonsContainerStyles();
    const { t } = useTranslation();

    const onClick = (name: keyof TaskQuickFilters) => () =>
        changeQuickFilter({ name, category: 'tasks' });

    return (
        <>
            <Grid
                container={true}
                alignItems="center"
                spacing={1}
                className={classes.container}
                data-ismobile={isMobile}
            >
                {buttons.map(({ key, text, testId }, index) => {
                    const itemIndex = index + 1;
                    return (
                        <Grid
                            item={true}
                            key={key}
                            {...(isMobile ? { style: { order: itemIndex + 1 } } : '')}
                        >
                            <FilterButton
                                isChecked={filters[key]}
                                onClick={onClick(key)}
                                data-testid={testId}
                                className={classes.filterButton}
                            >
                                {t(text)}
                            </FilterButton>
                        </Grid>
                    );
                })}
                <Grid
                    item={true}
                    {...(isMobile ? { style: { order: 1 } } : '')}
                    data-testid="badge_tasks_filters_wrapper"
                >
                    <Badge
                        badgeContent={appliedFiltersCount}
                        className={classes.badge}
                        data-testid="badge_tasks_filters"
                        data-ismobile={isMobile}
                    >
                        <FilterButton
                            isChecked={appliedFiltersCount !== null}
                            data-testid="btn_tasks_filters"
                            onClick={openConfigurableFilters}
                            icon={<Icon name="filter" mode="stroke" size="l" />}
                            title={t('TaskFilters.FiltersPopupTitle')}
                        />
                    </Badge>
                </Grid>
                {/* <Grid item={true}>
                    <Typography
                        variant="body1"
                        className={classes.found}
                        data-testid="txt_found-tasks"
                    >
                        {t('TaskQuickFilters.FoundTasksText', {
                            foundTasks,
                            totalTasks,
                        })}
                    </Typography>
                </Grid> */}
            </Grid>
            <TasksFiltersPopupHandler />
        </>
    );
};

export const TaskQuickFilter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TaskQuickFilterComponent);
