import { useEffect, useState } from 'react';

export const useScrollTable = (scroll: () => void, isLoading: boolean): void => {
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        if (scrolled || isLoading) return;
        scroll();
        setScrolled(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
};
