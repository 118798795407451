import React from 'react';
import { ActionIcon, Icon } from 'worldapp-ui';
import { ExportIcon } from 'worldapp-ui/shared/icons';
import { BulkTaskAction } from '../redux/content/validateTaskActions';

export const BUTTON_HEIGHT = 40;
export const TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS = 'ignore-task-card-outside-click';
export const ACTION_POPUP_DESKTOP_WIDTH = 460;

interface ActionIcon {
    icon: JSX.Element;
    iconStroke: JSX.Element;
    title: string;
}

export const BulkActionsIcons: Record<BulkTaskAction, ActionIcon> = {
    ChangeStatus: {
        icon: <Icon name="change-status" size="l" mode="fill" />,
        iconStroke: <Icon name="change-status" size="l" mode="stroke" />,
        title: 'TaskActions.ChangeStatus.Label',
    },
    UnAssign: {
        icon: <Icon name="unassign" size="l" mode="fill" />,
        iconStroke: <Icon name="unassign" size="l" mode="stroke" />,
        title: 'TaskActions.UnAssign.Label',
    },
    AssignToMe: {
        icon: <Icon name="assign-to-me" size="l" mode="fill" />,
        iconStroke: <Icon name="assign-to-me" size="l" mode="stroke" />,
        title: 'TaskActions.AssignToMe.Label',
    },
    ChangeAssignee: {
        icon: <Icon name="reassign" size="l" mode="fill" />,
        iconStroke: <Icon name="reassign" size="l" mode="stroke" />,
        title: 'TaskActions.ChangeAssignee.Label',
    },
    ChangeDueDate: {
        icon: <Icon name="date" size="l" mode="fill" />,
        iconStroke: <Icon name="date" size="l" mode="stroke" />,
        title: 'TaskActions.ChangeDueDate.Label',
    },
    Export: {
        icon: <ExportIcon />,
        iconStroke: <ExportIcon />,
        title: 'TaskActions.Export.Label',
    },
};
