import React from 'react';
import { connect } from 'react-redux';
import { DefaultPopup } from 'worldapp-ui/shared';
import { DefaultPopupProps } from 'worldapp-ui/shared/popup/DefaultPopup';
import { useTranslation } from 'react-i18next';
import { defaultPopupButtonsDirectionOptions } from 'worldapp-ui/shared/popup/components/DefaultPopupFooter';
import {
    getActiveFiltersSetName,
    getIsActiveFiltersSetModified,
} from '../../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { tasksConfigurableFiltersCreator } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import { FiltersPopupOwnProps } from './TasksConfigurableFiltersPopup';
import { FiltersPopupStage } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters.types';
import ResetAllFiltersButton from '../ResetAllFiltersButton';
import FiltersListContent from '../Content/FiltersListContent';
import { AppState } from '../../../redux/AppState.types';

const mapStateToProps = (state: AppState) => {
    return {
        filtersSetName: getActiveFiltersSetName(state),
        isFiltersSetModified: getIsActiveFiltersSetModified(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    changePopupStage: tasksConfigurableFiltersCreator.changePopupStage,
    saveFiltersSet: tasksConfigurableFiltersCreator.saveFiltersSet,
});

export interface EditFiltersSetPopupProps extends FiltersPopupOwnProps {
    filtersSetName: string | null;
    isFiltersSetModified: boolean;
    changePopupStage: (stage: FiltersPopupStage) => void;
    saveFiltersSet: (filtersSetName: string) => void;
}

export const EditFiltersSetPopupComponent = (
    props: EditFiltersSetPopupProps,
): JSX.Element | null => {
    const {
        filtersSetName,
        isFiltersSetModified,
        openMessageBox,
        saveFiltersSet,
        changePopupStage,
    } = props;
    const { t } = useTranslation();

    if (!filtersSetName) return null;

    const handleSave = () => {
        saveFiltersSet(filtersSetName);
        changePopupStage(FiltersPopupStage.FiltersSetsTab);
    };

    const handleSaveAsNew = () => {
        openMessageBox();
    };

    const handleCancel = () => {
        changePopupStage(FiltersPopupStage.FiltersSetsTab);
    };

    const popupProps: DefaultPopupProps = {
        id: 'EditFiltersSetPopup',
        open: true,
        header: {
            title: filtersSetName,
            headerAdditionalContent: isFiltersSetModified ? <ResetAllFiltersButton /> : undefined,
        },

        footer: {
            confirmButton: {
                label: t('TaskFilters.FiltersSetSave'),
                clickHandler: handleSave,
            },
            cancelButton: {
                label: t('Common.CANCEL'),
                clickHandler: handleCancel,
            },
            additionalButton: {
                label: t('TaskFilters.FiltersSetSaveAsNew'),
                clickHandler: handleSaveAsNew,
            },
            mobileButtonsDirection: defaultPopupButtonsDirectionOptions.ROW,
        },
    };

    return (
        <DefaultPopup {...popupProps}>
            <FiltersListContent />
        </DefaultPopup>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFiltersSetPopupComponent);
