import { fade, lighten, makeStyles } from 'worldapp-ui';

export const CALENDAR_VALUES = {
    LEFT_RIGHT_PADDING: 24,
    HEADER_HEIGHT: 56,
    HEADER_MARGIN_BOTTOM: 8,
    SUBHEADER_HEIGHT: 32,
    MIN_TABLE_HEIGHT: 250,
    MIN_TABLE_WIDTH: 520,
    CALENDAR_CONTAINER_WIDTH: 340,
    CALENDAR_CONTAINER_HEIGHT: 305,
    DAY_TASK_CARD_MAX_WIDTH: '',
};

export const useTaskTableViewStyles = makeStyles(theme => ({
    root: {
        padding: `0 ${CALENDAR_VALUES.LEFT_RIGHT_PADDING}px`,
        height: '100%',
    },
    mainTableContainer: {
        position: 'relative',
        overflow: 'auto',
        marginRight: '-16px',
        marginLeft: '-1px',
        paddingLeft: 1,
        maxHeight: 'calc(100vh - 316px)',
        ...theme.mixins.scrollOnHover,
    },
    mainTableContainerWeek: {
        height: '100%',
        '& > table': {
            height: '100%',
        },
    },
    table: {
        background: theme.palette.white.main,
        borderCollapse: 'collapse',
        borderStyle: 'hidden',
        overflow: 'hidden',
        tableLayout: 'fixed',
        boxShadow: `0 0 0 1px ${theme.palette.neutral[12]}`,
    },
    tableHeader: {
        borderRadius: '8px 8px 0 0',
        marginBottom: 1,
        width: '100%',
        height: `${CALENDAR_VALUES.SUBHEADER_HEIGHT - 1}px`,
    },
    tableBody: {
        marginRight: '-8px',
        width: 'calc(100% - 16px)',
    },
    th: {
        color: theme.palette.neutral.main,
        border: `1px solid ${theme.palette.neutral[12]}`,
        textAlign: 'right',
        fontWeight: 'normal',
        padding: '8px 8px 0',
        verticalAlign: 'top',
        boxSizing: 'border-box',
    },
    td: {
        color: theme.palette.neutral.main,
        border: `1px solid ${theme.palette.neutral[12]}`,
        verticalAlign: 'baseline',
        padding: '8px 8px 4px',
        boxSizing: 'border-box',
    },
    monthTbodyTd: {
        height: 142,
    },
    weekTbodyTd: {
        height: '100%',
        verticalAlign: 'top',
    },
    weekTheadTh: {
        textAlign: 'center',
    },
    dayTheadTd: {
        padding: '9px 12px 0',
        borderLeft: `1px solid ${theme.palette.neutral[12]}`,
        fontWeight: 400,
        textAlign: 'left',
        width: `calc(100% - ${CALENDAR_VALUES.CALENDAR_CONTAINER_WIDTH}px)`,
        verticalAlign: 'top',
    },
    dayTheadTh: {
        width: CALENDAR_VALUES.CALENDAR_CONTAINER_WIDTH,
    },
    dayTbodyTh: {
        width: CALENDAR_VALUES.CALENDAR_CONTAINER_WIDTH,
        verticalAlign: 'top',
    },
    dayTbodyTd: {
        border: 'none',
        width: `calc(100% - ${CALENDAR_VALUES.CALENDAR_CONTAINER_WIDTH}px)`,
        padding: '12px 24px 8px 0',
    },
    calendarContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 12,
    },
    loaderBlock: {
        margin: '4px 0',
    },
    loaderBlockDay: {
        maxWidth: CALENDAR_VALUES.DAY_TASK_CARD_MAX_WIDTH,
    },
    weekendDay: {
        color: theme.palette.neutral[65],
    },
    emptyDayTextWeekView: {
        position: 'fixed',
        padding: '0 8px',
        marginLeft: '-8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.neutral[65],
        textAlign: 'center',
        boxSizing: 'border-box',
        zIndex: 1,
    },
    emptyDayTextDayView: {
        position: 'relative',
        height: '100%',
        padding: '0 8px',
        marginLeft: '-8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.neutral[65],
        textAlign: 'center',
        boxSizing: 'border-box',
        zIndex: 1,
    },
    tdIsOver: {
        background: fade(theme.palette.primary.main, 0.32),
    },
    tdInDueDateRange: {
        background: '#fdf3e0',
    },
    tdInAssignDateRange: {
        background: fade(theme.palette.primary.main, 0.08),
    },
    tdInDueAndAssignDateRange: {
        background: `repeating-linear-gradient(
            -45deg,
            #fdf3e0 0 8px,
            ${fade(theme.palette.primary.main, 0.08)} 8px 16px
          )`,
    },
    data: {
        textAlign: 'right',
        marginBottom: 8,
    },
    dataToday: {
        width: 24,
        height: 24,
        borderRadius: 24,
        textAlign: 'center',
        padding: '4px 0',
        margin: '-4px -6px 4px auto',
        color: theme.palette.neutral.main,
        backgroundColor: lighten(theme.palette.primary.main, 0.84),
        boxSizing: 'border-box',
    },
    dataTodayWeek: {
        color: theme.palette.neutral.main,
        backgroundColor: lighten(theme.palette.primary.main, 0.92),
        '& *': {
            fontWeight: 500,
        },
    },
    taskChip: {
        cursor: 'pointer',
        background: theme.palette.white.main,
        position: 'relative',
        zIndex: 10,
        borderRadius: 4,
    },
    taskChipMonth: {
        margin: '4px 0',
        boxShadow: `0px 2px 4px ${fade(theme.palette.neutral.main, 0.1)}`,
    },
    taskChipWeek: {
        margin: '4px 0 8px',
        border: `1px solid ${theme.palette.neutral[12]}`,
    },
    taskChipDay: {
        margin: '4px 0 8px',
        border: `1px solid ${theme.palette.neutral[12]}`,
        maxWidth: CALENDAR_VALUES.DAY_TASK_CARD_MAX_WIDTH,
    },
    taskChipDragging: {
        zIndex: 100,
    },
    showMore: {
        color: theme.palette.neutral[65],
        lineHeight: '16px',
    },
    showLess: {
        lineHeight: '16px',
    },

    dayViewContainer: {
        display: 'flex',
        marginRight: 16,
        background: theme.palette.white.main,
        boxShadow: `0 0 0 1px ${theme.palette.neutral[12]}`,
    },
    dayViewContainerLeft: {
        overflow: 'auto',
        width: CALENDAR_VALUES.CALENDAR_CONTAINER_WIDTH,
        display: 'flex',
        flexDirection: 'column',
        ...theme.mixins.scrollOnHover,
    },
    dayViewCalendarContainer: {
        height: CALENDAR_VALUES.CALENDAR_CONTAINER_HEIGHT,
    },
    dayViewFiltersContainer: {
        height: `calc(100%  - ${CALENDAR_VALUES.CALENDAR_CONTAINER_HEIGHT}px)`,
        minHeight: 80,
        padding: '16px 32px 8px',
        overflow: 'hidden',
    },
    tableDay: {
        borderCollapse: 'collapse',
        borderStyle: 'hidden',
        overflow: 'hidden',
        tableLayout: 'fixed',
        width: '100%',
    },
    dayViewTasksContainer: {
        overflow: 'auto',
        display: 'flex',
        marginRight: '-16px',
        flex: '1 0 10%',
    },
}));
