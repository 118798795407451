import produce from 'immer';

import { wait } from 'worldapp-fe-utils';
import ReactGA from 'react-ga4';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import {
    HYDRATE_STATE_FROM_STORAGE,
    HydrateStateFromStorageAction,
} from '../initialLoader/initialLoader.types';
import {
    SET_EXPORT_STATUS,
    START_EXPORT,
    TasksExportAction,
    TasksExportState,
} from './tasksExport.types';
import i18n from '../../localization/i18n';
import { TOAST_RETRY_TASK_EXPORT } from '../toasts/toasts.types';
import { ExportStatus, MAX_EXPORT_TASKS_LIMIT } from '../../constants/export.constants';
import { generateUniqueKey } from '../../utils/helpers.utils';
import { onTaskDetailAction } from '../../analytics';

const EXPORT_START = 'TaskActions.Export.Start';
const EXPORT_SUCCESSFUL = 'TaskActions.Export.Success';
const EXPORT_FAIL = 'TaskActions.Export.Fail';

export const initialState: TasksExportState = {};

// TODO: Move to fe-utuils
export type ResponseBlob = {
    body: Blob;
    headers: any;
};
export function downloadFile(response: ResponseBlob): void {
    const headersContentDisposition = response.headers['content-disposition'].split(';');
    let filename = '';
    if (headersContentDisposition[0] === 'attachment') {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(headersContentDisposition[1]);
        filename = matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : '';
    }

    const URL = window.URL || window.webkitURL;
    const blob = new Blob([response.body], {
        type: response.headers['content-type'],
    });
    const downloadUrl = URL.createObjectURL(blob);
    if (filename) {
        // use HTML5 a[download] attribute to specify filename
        const a = document.createElement('a');
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
            window.location.href = downloadUrl;
        } else {
            a.href = downloadUrl;
            a.download = filename;
            a.ariaLabel = 'export';
            document.body.appendChild(a);
            a.click();
        }
    } else {
        window.location.href = downloadUrl;
    }

    setTimeout(() => {
        URL.revokeObjectURL(downloadUrl);
    }, 100); // cleanup
}

export const reducer = (
    state: TasksExportState = initialState,
    action: TasksExportAction | HydrateStateFromStorageAction,
): TasksExportState =>
    produce(state, (draft: TasksExportState) => {
        switch (action.type) {
            case HYDRATE_STATE_FROM_STORAGE:
                const { tasksExport } = action.state;
                if (!tasksExport) return draft;
                const tasksExportStatus = Object.values(tasksExport)[0];
                if (tasksExportStatus?.progress === ExportStatus.IN_PROGRESS) {
                    const taskExportId = +Object.keys(tasksExport)[0];
                    return {
                        ...draft,
                        tasksExport: {
                            [taskExportId]: tasksExportStatus,
                        },
                    };
                }
                return draft;
            case SET_EXPORT_STATUS: {
                const { taskDefinitionId, status } = action;
                draft[taskDefinitionId] = status;
                return draft;
            }
            default:
                return state;
        }
    });

export const tasksExportCreator = {
    exportTasks:
        (currentTaskId?: number, logTaskDetailEvent = false): ThunkActionVoid =>
        (
            dispatch,
            getState,
            {
                api,
                tasksExportCreator: creator,
                toastsCreator,
                contentSelector,
                tasksExportSelector,
            },
        ) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getCurrentTaskDefinitionId(state);
            const isBulk = state.tasksSelectedActions.include.length > 0;
            if (!taskDefinitionId) return;
            const params = tasksExportSelector.getTasksExportParams(
                taskDefinitionId,
                MAX_EXPORT_TASKS_LIMIT,
                0,
                state,
                currentTaskId,
            );
            if (logTaskDetailEvent)
                onTaskDetailAction('export', contentSelector.getTaskDetailViewTask(state));
            if (isBulk) ReactGA.event('bulk_action', { action_type: 'Export' });

            const toastKey = generateUniqueKey();
            return api
                .exportTasks(params)
                .then(({ headers }: any) => {
                    const { location } = headers;
                    if (!currentTaskId) {
                        if (isBulk) {
                            dispatch(
                                toastsCreator.createToast(
                                    i18n.t(EXPORT_START),
                                    'load',
                                    true,
                                    undefined,
                                    toastKey,
                                ),
                            );
                        } else {
                            dispatch(
                                creator.setExportStatus(
                                    taskDefinitionId,
                                    location,
                                    ExportStatus.IN_PROGRESS,
                                ),
                            );
                        }
                    }
                    dispatch({
                        type: START_EXPORT,
                    });
                    return dispatch(
                        creator.getExportTasksStatus(
                            taskDefinitionId,
                            location,
                            currentTaskId,
                            isBulk,
                        ),
                    );
                })
                .then(() => {
                    if (isBulk) {
                        dispatch(toastsCreator.removeToast(toastKey));
                    }
                    return dispatch(toastsCreator.createToast(i18n.t(EXPORT_SUCCESSFUL), 'info'));
                })
                .catch(() => {
                    if (isBulk) {
                        dispatch(toastsCreator.removeToast(toastKey));
                    }
                    dispatch(creator.setExportStatus(taskDefinitionId, '', ExportStatus.FAILED));
                    return dispatch(
                        toastsCreator.createToast(i18n.t(EXPORT_FAIL), 'error', true, {
                            text: i18n.t('TaskActions.Export.Retry'),
                            action: TOAST_RETRY_TASK_EXPORT,
                        }),
                    );
                });
        },
    setExportStatus:
        (
            taskDefinitionId: number,
            location: string,
            progress?: string,
            current?: number,
            total?: number,
        ): ThunkActionVoid =>
        dispatch =>
            dispatch({
                taskDefinitionId,
                status: { location, progress, current, total },
                type: SET_EXPORT_STATUS,
            }),
    getExportTasksStatus:
        (
            taskDefinitionId: number,
            location: string,
            currentTaskId?: number,
            isBulk?: boolean,
        ): ThunkActionVoid =>
        async (dispatch, getState, { api }) => {
            if (location.length > 0) {
                const checkExport = () =>
                    api.getExportTasksStatus(location!).then(async (result: any) => {
                        if (!currentTaskId && !isBulk) {
                            dispatch({
                                taskDefinitionId,
                                status: {
                                    location,
                                    progress: result.state,
                                    current: result.current,
                                    total: result.total,
                                },
                                type: SET_EXPORT_STATUS,
                            });
                        }

                        if (result.state === ExportStatus.IN_PROGRESS) {
                            await wait(1000);
                            await checkExport();
                        } else if (result.state === ExportStatus.SUCCESS) {
                            api.downloadTasksExportedFile(result.resourceDetails!.url)
                                .then((response: any) => {
                                    downloadFile(response);
                                })
                                .catch((response: any) => response);
                        }
                    });
                await checkExport();
            }
        },
};
