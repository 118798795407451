import React, { useState } from 'react';
import { MessageBox, createStyles, makeStyles, Theme, Icon, IconButton } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { loginCreator } from '../../redux/login/login';

const LogoutButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.neutral.main,
        },
    }),
);

const mapDispatchToProps = mapCreatorsToDispatchProps({ logout: loginCreator.logout });

type Props = ReturnType<typeof mapDispatchToProps>;

export const LogoutButton = (props: Props): JSX.Element => {
    const [isPopupOpen, togglePopup] = useState(false);
    const { t } = useTranslation();

    const showPopup = (on: boolean) => () => {
        togglePopup(on);
    };

    const onConfirm = () => {
        props.logout();
    };

    const classes = LogoutButtonStyles();

    return (
        <div>
            <IconButton
                icon={<Icon name="logout" size="l" mode="stroke" className={classes.icon} />}
                onClick={showPopup(true)}
                data-testid={'btn-logout'}
                title={t('Logout.PopupTitle')}
            />
            <MessageBox
                open={isPopupOpen}
                title={t('Logout.PopupTitle')}
                confirmButton={{ label: t('Logout.ConfirmButtonTitle'), clickHandler: onConfirm }}
                cancelButton={{
                    label: t('Logout.CloseButtonTitle'),
                    clickHandler: showPopup(false),
                }}
            >
                {t('Logout.ConfirmationMessage')}
            </MessageBox>
        </div>
    );
};
export default connect(null, mapDispatchToProps)(LogoutButton);
