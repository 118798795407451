import cloneDeep from 'clone-deep';
import { Converter } from '../location.types';

export const newResponseParamsConverter: Converter = {
    stateToURL: () => undefined,
    URLToState: ({ query }) => {
        const newResponse = query.new?.[0];
        const nav = query.nav?.[0];
        const formId = query.form?.[0];

        if (newResponse !== undefined && nav === 'forms' && formId !== undefined) {
            const params = cloneDeep(query);
            delete params.new;
            delete params.nav;
            delete params.form;

            return { newResponseParams: params };
        }
    },
};
