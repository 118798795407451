import React, { useLayoutEffect } from 'react';
import { Card, CardContent, Grid, Text } from 'worldapp-ui/shared';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Calendar12Icon } from 'worldapp-ui/shared/icons';
import CardActionPanel from './bottomPanel/CardActionPanel';
import Title from './Title';
import CopyButton from '../buttons/CopyButton';
import DeleteButton from '../buttons/DeleteButton';
import EditButton from '../buttons/EditButton';
import { StatusCompleted, Status } from './Status';
import { cardStyles } from './card.styles';

export interface FormResponseCardProps {
    isCompleted?: boolean;
    title?: string;
    searchQuery?: string;
    taskDefinition?: string;
    onClick?: () => void;
    onCopy?: () => void;
    onDelete?: () => void;
    onEdit?: () => void;
    updated?: string;
    isActive?: boolean;
    isActionLoading?: boolean;
    shouldHighlight?: boolean;
    isDeleteResponseActive?: boolean;
    width?: number;
}

export const LastChanged = (props: {
    lastChangedDate?: string;
    className?: string;
}): JSX.Element => {
    const classes = cardStyles({});
    const { t } = useTranslation();
    return (
        <Grid container alignItems="center" wrap="nowrap">
            <Calendar12Icon className={classes.icon12} />
            <Text
                variant="r12r"
                noWrap={true}
                className={classes.label}
                data-testid="card-task-last_changed_date"
            >
                {t('ResponseCard.LastChangeDate', { date: props.lastChangedDate })}
            </Text>
        </Grid>
    );
};

const FormResponseCard = (props: FormResponseCardProps): JSX.Element => {
    const {
        isCompleted,
        title,
        searchQuery,
        updated,
        onCopy,
        onDelete,
        onEdit,
        onClick,
        isActive = false,
        isActionLoading,
        shouldHighlight,
        isDeleteResponseActive,
        width,
        ...restProps
    } = props;
    const classes = cardStyles({ cardWidth: width });
    const buttons = [];
    const { t } = useTranslation();

    const [isCardActive, setIsCardActive] = React.useState(isActive);

    useLayoutEffect(() => {
        setIsCardActive(isActive);
    }, [isActive]);

    const ref = useOnclickOutside(
        () => {
            if (isCardActive && !isDeleteResponseActive) {
                setIsCardActive(false);
            }
        },
        { disabled: !isCardActive },
    );

    if (onEdit) {
        buttons.push(<EditButton onClick={onEdit} disabled={isActionLoading} />);
    }
    if (onCopy) {
        buttons.push(<CopyButton onClick={onCopy} disabled={isActionLoading} />);
    }
    if (onDelete) {
        buttons.push(<DeleteButton onClick={onDelete} />);
    }

    const statusChip = isCompleted ? (
        <StatusCompleted status={t('ResponseCard.StatusCompleted')} color="primary" />
    ) : (
        <Status status={t('ResponseCard.StatusInProgress')} color="primary" />
    );

    return (
        <Card
            className={classNames(classes.card, classes.responseCard, {
                [classes.active]: isCardActive,
                [classes.highlighted]: shouldHighlight,
                [classes.cardWithAction]: !!onClick,
                [classes.cardCloses]: isCompleted,
            })}
            ref={ref}
            {...restProps}
        >
            <CardContent className={classes.cardContent} onClick={onClick}>
                <Grid container={true} justify="space-between" className={classes.cardHeader}>
                    {statusChip}
                </Grid>
                <Title title={title} searchQuery={searchQuery} />
                {updated && <LastChanged lastChangedDate={updated} />}
                <CardActionPanel buttons={buttons} />
            </CardContent>
        </Card>
    );
};

export default FormResponseCard;
