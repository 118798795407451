import { makeStyles, ChipStatus, ColorPropTypes, fade } from 'worldapp-ui/shared';
import React from 'react';

interface ChipStatusProps {
    status?: string;
    color?: ColorPropTypes;
}

const useStatusStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.neutral.main,
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        marginRight: 0,
    },
}));

export const Status = (props: ChipStatusProps): JSX.Element => {
    const classes = useStatusStyles(props);
    return props.status ? (
        <ChipStatus
            label={props.status}
            color={props.color || 'primary'}
            data-testid="card-response-status"
            classes={classes}
        />
    ) : (
        <div />
    );
};

const useStatusCompletedStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.neutral.main,
        backgroundColor: theme.palette.green.main && fade(theme.palette.green.main, 0.3),
        marginRight: 0,
    },
    background: {
        background: theme.palette.white.main,
        borderRadius: 4,
        minWidth: 72,
    },
}));
export const StatusCompleted = (props: ChipStatusProps): JSX.Element => {
    const { background: backgroundClassName, ...classes } = useStatusCompletedStyles(props);

    return props.status ? (
        <div className={backgroundClassName}>
            <ChipStatus
                label={props.status}
                color={props.color || 'primary'}
                data-testid="card-response-status"
                classes={classes}
            />
        </div>
    ) : (
        <div />
    );
};
