import {
    Category,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_TASKS,
} from '../content/category.types';

export const CHANGE_QUICK_FILTER = 'Content/CHANGE_QUICK_FILTER';
export const COPY_QUICK_FILTERS_TO_APPLIED = 'Content/COPY_QUICK_FILTERS_TO_APPLIED';
export const ONLY_RECENT_QUICK_FILTER = 'Content/ONLY_RECENT_QUICK_FILTER';

export interface ChangeQuickFiltersAction {
    type: typeof CHANGE_QUICK_FILTER;
    filter: QuickFilter;
    itemId: number;
    initialTaskQuickFilters: TaskQuickFilters;
}

export interface CopyQuickFiltersToAppliedAction {
    type: typeof COPY_QUICK_FILTERS_TO_APPLIED;
    filters: TaskQuickFilters;
    itemId: number;
    category: Category;
}

export interface OnlyRecentQuickFiltersAction {
    type: typeof ONLY_RECENT_QUICK_FILTER;
    itemId: number;
    category: Category;
}

export type QuickFiltersAction =
    | ChangeQuickFiltersAction
    | CopyQuickFiltersToAppliedAction
    | OnlyRecentQuickFiltersAction;

export interface TaskQuickFilters {
    myTasks: boolean;
    teamTasks: boolean;
    unassignedTasks: boolean;
    openTasks: boolean;
    recentTasks: boolean;
    createdByMe: boolean;
    overdue: boolean;
}

export interface ResponseQuickFilters {
    inProgressResponses: boolean;
    completedResponses: boolean;
    recentResponses: boolean;
}

export type QuickFilters =
    | { filters: ResponseQuickFilters; type: 'response' }
    | { filters: TaskQuickFilters; type: 'task' };

export type QuickFilter =
    | {
          category: typeof CONTENT_CATEGORY_TASKS;
          name: keyof TaskQuickFilters;
      }
    | {
          category: typeof CONTENT_CATEGORY_FORMS;
          name: keyof ResponseQuickFilters;
      };

export interface QuickFiltersState {
    tasks: {
        [taskDefinitionId: number]: TaskQuickFilters | undefined;
    };
    forms: {
        [formId: number]: ResponseQuickFilters | undefined;
    };
}
