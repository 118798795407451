import { useEffect } from 'react';

export function useCleanup(action: () => void): void {
    useEffect(
        () =>
            function cleanup() {
                action();
            },
        [action],
    );
}
