import produce from 'immer';

// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import {
    TOGGLE_TASK_SELECTED,
    TasksSelectedAction,
    TasksSelectedState,
    TOGGLE_ALL_TASK_SELECTED,
    ToggleTaskSelectedAction,
    DESELECT_TASKS,
    TOGGLE_TASKS_IN_ROW_SELECTED,
    ToggleTasksInRowSelectedAction,
} from './tasksSelectedActions.types';
import { ClearContentAction, CONTENT_CLEAR } from '../content/content.types';
import { MenuAction, SET_ACTIVE_CATEGORY, SET_ACTIVE_ITEM } from '../menu/menu.types';
import { ActiveCardAction } from '../activeCard/activeCard.types';
import { BULK_QUEUE_ADD, BulkQueueAdd } from '../tasksActions/tasksActions.types';
import {
    SET_EXPORT_STATUS,
    START_EXPORT,
    StartExportStatusAction,
    TasksExportAction,
} from '../tasksExport/tasksExport.types';

export const initialState: TasksSelectedState = {
    isAllTasksSelected: false,
    include: [],
    exclude: [],
    lastSelectedTaskId: null,
};

const toggleTaskSelected = (draft: TasksSelectedState, action: ToggleTaskSelectedAction) => {
    const { taskId, checked } = action;
    let includedTasks = draft.include;
    let excludedTasks = draft.exclude;

    if (draft.isAllTasksSelected) {
        if (checked) {
            excludedTasks = draft.exclude.filter(id => id !== taskId);
        } else excludedTasks.push(taskId);
    } else if (checked) {
        includedTasks.push(taskId);
    } else includedTasks = draft.include.filter(id => id !== taskId);

    draft.include = [...includedTasks];
    draft.exclude = [...excludedTasks];
    draft.lastSelectedTaskId = checked ? taskId : null;
};

const toggleTasksInRowSelected = (
    draft: TasksSelectedState,
    action: ToggleTasksInRowSelectedAction,
) => {
    const { taskId, taskIds } = action;

    draft.include = [...taskIds];
    draft.exclude = [];
    draft.lastSelectedTaskId = taskId;
};

const toggleAllTasksSelected = (draft: TasksSelectedState) => {
    if (!(draft.isAllTasksSelected && draft.exclude.length > 0)) {
        draft.isAllTasksSelected = !draft.isAllTasksSelected;
    }
    draft.exclude = [];
    draft.include = [];
};

export const reducer = (
    state: TasksSelectedState = initialState,
    action:
        | TasksSelectedAction
        | ClearContentAction
        | MenuAction
        | ActiveCardAction
        | BulkQueueAdd
        | TasksExportAction
        | StartExportStatusAction,
): TasksSelectedState =>
    produce(state, (draft: TasksSelectedState) => {
        switch (action.type) {
            case DESELECT_TASKS: {
                return initialState;
            }
            case TOGGLE_TASK_SELECTED:
                toggleTaskSelected(draft, action);
                return draft;

            case TOGGLE_TASKS_IN_ROW_SELECTED:
                toggleTasksInRowSelected(draft, action);
                return draft;

            case TOGGLE_ALL_TASK_SELECTED:
                toggleAllTasksSelected(draft);
                return draft;

            case SET_ACTIVE_CATEGORY:
            case SET_ACTIVE_ITEM:
            case CONTENT_CLEAR:
            case BULK_QUEUE_ADD:
            case SET_EXPORT_STATUS:
            case START_EXPORT:
                return initialState;
            default:
                return state;
        }
    });

export const tasksSelectedActionsCreator = {
    toggleTaskSelected:
        (taskId: number, checked: boolean, shiftKey?: boolean): ThunkActionVoid =>
        (dispatch, getState) => {
            const state = getState();
            const { lastSelectedTaskId } = state.tasksSelectedActions;
            const items = state.content?.items;
            if (shiftKey) {
                const endIndex = items!.findIndex(item => item.id === taskId);
                let startIndex =
                    lastSelectedTaskId === null
                        ? -1
                        : items!.findIndex(item => item.id === lastSelectedTaskId);
                if (startIndex === -1) startIndex = endIndex;
                const taskIds = items
                    ?.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1)
                    .map(item => item.id) as number[];
                dispatch({ taskId, taskIds, type: TOGGLE_TASKS_IN_ROW_SELECTED });
            } else {
                dispatch({
                    taskId,
                    checked,
                    type: TOGGLE_TASK_SELECTED,
                });
            }
        },

    toggleAllTasksSelected: (): ThunkActionVoid => dispatch => {
        return dispatch({ type: TOGGLE_ALL_TASK_SELECTED });
    },

    toggleAllLoadedTasksSelected: (): ThunkActionVoid => (dispatch, getState) => {
        const { content, tasksSelectedActions } = getState();
        const items = content?.items || [];
        const allTasksSelected = items.length === tasksSelectedActions.include.length;
        if (allTasksSelected) {
            return dispatch({ type: DESELECT_TASKS });
        }
        const taskIds = items.map(item => item.id) as number[];
        return dispatch({ taskId: null, taskIds, type: TOGGLE_TASKS_IN_ROW_SELECTED });
    },

    deselectTasks: (): ThunkActionVoid => dispatch => {
        return dispatch({ type: DESELECT_TASKS });
    },
};
