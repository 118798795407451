import React, { ReactNode, Fragment } from 'react';
import { fade, makeStyles, Icon, Text, resolution } from 'worldapp-ui';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: 12,
        marginTop: -3,
    },
    toolbarIcon: {
        color: theme.palette.neutral[75],
        borderRadius: 'initial',
        borderBottom: `1px solid ${fade(theme.palette.neutral[50], 0.24)}`,
        padding: theme.spacing(2),
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'start',
        height: 60,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
            '& .MuiTypography-subtitle1': {
                color: theme.palette.primary.main,
            },
        },
    },
    toolbarIconWithoutBorder: {
        border: 0,
    },
}));

interface Props {
    children: ReactNode;
    onClickBack?: () => void;
    isDrawerOpen: boolean;
    backButtonLabel?: string;
    withoutBorder?: boolean;
}

const NavigationSubMenu = (props: Props): JSX.Element => {
    const { children, onClickBack, isDrawerOpen, backButtonLabel = 'Back', withoutBorder } = props;
    const classes = useStyles();
    return (
        <Fragment>
            <div
                className={classNames(classes.toolbarIcon, {
                    [classes.toolbarIconWithoutBorder]: !!withoutBorder,
                })}
                aria-label="Open search"
                onClick={onClickBack}
                data-testid="panel-menu-back"
            >
                <Icon name="back" size="l" mode="stroke" className={classes.icon} />

                {isDrawerOpen ? (
                    <Text variant={'r16r'} size={resolution.loRes} data-testid="back-label">
                        {backButtonLabel}
                    </Text>
                ) : (
                    ''
                )}
            </div>
            {children}
        </Fragment>
    );
};

export default NavigationSubMenu;
