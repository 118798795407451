import React, { ChangeEvent } from 'react';
import { SelectDropdown, makeStyles, Icon, IconButton, lighten } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContextMenu from 'worldapp-ui/shared/contextMenu/PopperContextMenu';
import classNames from 'classnames';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { tasksSortCreator } from '../../redux/tasksSort/tasksSort';
import {
    getCurrentTaskSortOrder,
    getTaskSortOptions,
    SortColumnOption,
} from '../../redux/tasksSort/tasksSortSelector';
import { SortOrder } from '../../redux/tasksSort/tasksSort.types';
import { AppState } from '../../redux/AppState.types';
import { isMobile } from '../../utils/browser.utils';

const useSortedByTextStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.neutral[75],
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
            marginRight: 0,
        },
    },
    select: {
        color: theme.palette.neutral.main,
        marginRight: 4,
    },
    iconButton: {
        color: theme.palette.neutral.main,
    },
    iconButtonActive: {
        backgroundColor: lighten(theme.palette.primary.main, 0.75),
        '&[data-ismobile="true"]:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.75),
        },
    },
}));

const mapStateToProps = (state: AppState) => ({
    options: getTaskSortOptions(state),
    order: getCurrentTaskSortOrder(state),
});

const mapDispatchToProps = mapCreatorsToDispatchProps({
    changeSortColumn: tasksSortCreator.changeSortColumn,
    changeSortOrder: tasksSortCreator.changeSortOrder,
});

export type TasksSortProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const TasksSort = (props: TasksSortProps): JSX.Element => {
    const { options, order, changeSortColumn, changeSortOrder } = props;
    const classes = useSortedByTextStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const toggleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const arrowButton =
        order === SortOrder.Asc ? (
            <Icon name="sorting-up" mode="stroke" size="l" />
        ) : (
            <Icon name="sorting-down" mode="stroke" size="l" />
        );
    const ariaLabel = order === SortOrder.Asc ? t('TasksSort.ASC') : t('TasksSort.DESC');

    const activeOption = options.find((option: SortColumnOption) => option.isActive);

    const onChange = (e: ChangeEvent<{ value: unknown }>) => {
        const newSelectedOption = options.find(
            (option: SortColumnOption) => option.value === e.target.value,
        );
        if (newSelectedOption && newSelectedOption.sortBy !== null) {
            changeSortColumn(newSelectedOption.sortBy);
        }
    };

    const translatedOptions = options.map(option => ({ ...option, name: t(option.name) }));

    const mobileTranslatedOptions = options.map(option => ({
        name: t(option.name),
        onClick: () => changeSortColumn(option.sortBy!),
        icon: option.isActive ? arrowButton : undefined,
        onIconClick: changeSortOrder,
        selected: option.isActive,
    }));

    return (
        <div className={classes.container}>
            {!isMobile ? (
                <>
                    <SelectDropdown
                        isBold
                        disableIcon
                        selectedValue={activeOption ? activeOption.value : ''}
                        values={translatedOptions}
                        onChange={onChange}
                        className={classes.select}
                    />
                    <IconButton
                        icon={arrowButton}
                        onClick={changeSortOrder}
                        className={classes.iconButton}
                        data-testid="btn-sort_menu-change-order"
                        aria-label={ariaLabel}
                    />
                </>
            ) : (
                <>
                    <ContextMenu
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        items={mobileTranslatedOptions}
                    />

                    <IconButton
                        icon={arrowButton}
                        onClick={toggleContextMenu}
                        className={classNames(classes.iconButton, {
                            [classes.iconButtonActive]: !!anchorEl,
                        })}
                        data-testid="btn-mobile-sort_menu-change-order"
                        data-ismobile={isMobile}
                    />
                </>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksSort);
