import React from 'react';
import { DateRange, SelectionGroup } from 'worldapp-ui/shared';
import { SelectionGroupItem } from 'worldapp-ui/shared/selectionControls/SelectionGroup';
import { DateRangeValue } from 'worldapp-ui/shared/inputs/DateRange';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';

export enum DateFilterOption {
    None = 'None',
    Yesterday = 'Yesterday',
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    PreviousWeek = 'PreviousWeek',
    CurrentWeek = 'CurrentWeek',
    NextWeek = 'NextWeek',
    PreviousMonth = 'PreviousMonth',
    CurrentMonth = 'CurrentMonth',
    NextMonth = 'NextMonth',
    Custom = 'Custom',
}

const dateFilterOptionLabels = {
    [DateFilterOption.None]: 'TaskFilters.DateFilter.NoneOption',
    [DateFilterOption.Yesterday]: 'TaskFilters.DateFilter.YesterdayOption',
    [DateFilterOption.Today]: 'TaskFilters.DateFilter.TodayOption',
    [DateFilterOption.Tomorrow]: 'TaskFilters.DateFilter.TomorrowOption',
    [DateFilterOption.PreviousWeek]: 'TaskFilters.DateFilter.PreviousWeekOption',
    [DateFilterOption.CurrentWeek]: 'TaskFilters.DateFilter.CurrentWeekOption',
    [DateFilterOption.NextWeek]: 'TaskFilters.DateFilter.NextWeekOption',
    [DateFilterOption.PreviousMonth]: 'TaskFilters.DateFilter.PreviousMonthOption',
    [DateFilterOption.CurrentMonth]: 'TaskFilters.DateFilter.CurrentMonthOption',
    [DateFilterOption.NextMonth]: 'TaskFilters.DateFilter.NextMonthOption',
    [DateFilterOption.Custom]: 'TaskFilters.DateFilter.CustomOption',
};

type PredefinedFilterOption = Exclude<DateFilterOption, DateFilterOption.Custom>;

export type DateFilterChangeHandler = (range: DateFilterRange) => void;

interface PredefinedFilterRange {
    option: PredefinedFilterOption;
}

interface CustomFilterRange {
    option: DateFilterOption.Custom;
    from: Moment | null;
    to: Moment | null;
}

export type DateFilterRange = PredefinedFilterRange | CustomFilterRange;

export interface DateFilterProps {
    selectedRange: DateFilterRange;
    onChange: DateFilterChangeHandler;
    label: string;
    language: string;
    disableFutureDates?: boolean;
    dateFormat?: string;
}

export const DateFilter = ({
    selectedRange,
    onChange,
    label,
    language,
    disableFutureDates,
    dateFormat,
}: DateFilterProps): JSX.Element => {
    const { t } = useTranslation();
    const dateRangeValue =
        selectedRange.option === DateFilterOption.Custom
            ? { from: selectedRange.from, to: selectedRange.to }
            : { from: null, to: null };

    const options = Object.entries(dateFilterOptionLabels)
        .filter(
            ([key]) =>
                !(
                    disableFutureDates &&
                    (key === DateFilterOption.NextMonth ||
                        key === DateFilterOption.NextWeek ||
                        key === DateFilterOption.Tomorrow)
                ),
        )
        .map(([key, value]) => {
            return {
                value: key,
                label: t(value),
                checked: key === selectedRange.option,
            };
        });

    const handleSelectedOptionChange = (item: SelectionGroupItem) => {
        onChange({
            option: item.value as DateFilterOption,
            from: item.value === DateFilterOption.Custom ? dateRangeValue.from : null,
            to: item.value === DateFilterOption.Custom ? dateRangeValue.to : null,
        });
    };

    const handleDateRangeInputChange = (value: DateRangeValue) => {
        onChange({
            option: selectedRange.option,
            from: value.from,
            to: value.to,
        });
    };

    return (
        <>
            <SelectionGroup
                twoColumnsView={true}
                label={label}
                items={options}
                onChange={handleSelectedOptionChange}
                control="radio"
            />
            <DateRange
                value={dateRangeValue}
                onChange={handleDateRangeInputChange}
                disabled={selectedRange.option !== DateFilterOption.Custom}
                fromPlaceholder={t('TaskFilters.DateFilter.FromPlaceholder')}
                toPlaceholder={t('TaskFilters.DateFilter.ToPlaceholder')}
                language={language}
                disableFutureDates={disableFutureDates}
                dateFormat={dateFormat}
                calendarProps={{
                    leftArrowButtonProps: { 'aria-label': t('Calendar.Prev') },
                    rightArrowButtonProps: { 'aria-label': t('Calendar.Next') },
                }}
            />
        </>
    );
};
