import { persistLocationData } from 'worldapp-fe-utils';
import { checkIsNextStepEnabled, getLoginError } from '../../utils/login.utils';
import { removeAuthenticated, setAuthenticated, setLastPortalId } from '../../utils/storage.utils';
import { ErrorType } from '../../utils/Http';
import {
    ADD_ERROR,
    CHANGE_LOGIN_STATUS,
    CHANGE_PASSWORD,
    CHANGE_PORTALID,
    CHANGE_USERNAME,
    IS_LOADING,
    LOGIN,
    LOGIN_OPTIONS_FETCHED,
    LoginAction,
    LoginState,
    LoginStatus,
    PAGE_SHOW,
    PASSWORD_CHANGED,
    PortalId,
    REDIRECT,
    SSO_LOGOUT,
    CHANGE_PORTAL_VERSION,
} from './login.types';
import { isNumber, NullPortalId } from './portalId';
// eslint-disable-next-line import/no-cycle
import { ThunkActionPromise, ThunkActionVoid } from '../index';
import { CLEAR_BRAND_INFO } from '../brandInfo/brandInfo.types';
import { GO_TO_URL } from '../goToURL/goToURL.types';
import { GO_TO_VOTING } from '../voting/voting.types';
import { getImpersonateMode } from '../portalSettings/portalSettingsSelector';

export const initialState: LoginState = {
    portalId: NullPortalId,
    username: '',
    password: '',
    loginStatus: LoginStatus.Loading,
    beforeShutdownLoginStatus: null,
    errorMessage: '',
    isLoading: false,
    nextStepEnabled: false,
};

export default function reducer(state: LoginState = initialState, action: LoginAction): LoginState {
    if (state.loginStatus === LoginStatus.Shutdown && action.type !== 'Login/PAGE_SHOW') {
        return state;
    }
    switch (action.type) {
        case LOGIN: {
            const newState = {
                password: '',
                username: action.temporary ? state.username : '',
                loginStatus: action.temporary ? LoginStatus.ChangePassword : LoginStatus.LoggedIn,
            };
            return {
                ...state,
                ...newState,
            };
        }
        case CHANGE_USERNAME: {
            const newState = {
                ...state,
                username: action.username,
                errorMessage: '',
            };
            return {
                ...newState,
                nextStepEnabled: checkIsNextStepEnabled(newState),
            };
        }
        case CHANGE_PASSWORD: {
            const newState = {
                ...state,
                password: action.password,
                errorMessage: '',
            };
            return {
                ...newState,
                nextStepEnabled: checkIsNextStepEnabled(newState),
            };
        }
        case CHANGE_PORTALID: {
            const newState = {
                ...state,
                portalId: action.portalId,
                errorMessage: '',
            };
            return {
                ...newState,
                nextStepEnabled: checkIsNextStepEnabled(newState),
            };
        }
        case CHANGE_LOGIN_STATUS: {
            const newState = {
                ...state,
                loginStatus: action.loginStatus,
                errorMessage: '',
                password: '',
            };
            return {
                ...newState,
                nextStepEnabled: checkIsNextStepEnabled(newState),
            };
        }
        case ADD_ERROR: {
            const newState = {
                ...state,
                errorMessage: action.errorMessage,
            };
            return {
                ...newState,
                nextStepEnabled: checkIsNextStepEnabled(newState),
            };
        }
        case IS_LOADING: {
            const newState = {
                ...state,
                isLoading: action.isLoading,
            };
            return {
                ...newState,
                nextStepEnabled: checkIsNextStepEnabled(newState),
            };
        }
        case PASSWORD_CHANGED:
            return {
                ...state,
                loginStatus: LoginStatus.Login,
                username: action.username,
                password: '',
                errorMessage: '',
                isLoading: false,
            };
        case GO_TO_URL: {
            return {
                ...state,
                loginStatus: LoginStatus.Shutdown,
                beforeShutdownLoginStatus: state.loginStatus,
            };
        }
        case SSO_LOGOUT: {
            return { ...state, SSOLogoutLink: action.link };
        }
        case REDIRECT: {
            return { ...state, redirectUrl: undefined };
        }
        case LOGIN_OPTIONS_FETCHED: {
            return { ...state, loginOptions: action.options };
        }
        case GO_TO_VOTING: {
            return { ...state, newResponseParams: undefined };
        }
        case CHANGE_PORTAL_VERSION: {
            return { ...state, portalVersion: action.version };
        }
        case PAGE_SHOW: {
            return state.beforeShutdownLoginStatus
                ? {
                      ...state,
                      loginStatus: state.beforeShutdownLoginStatus,
                      beforeShutdownLoginStatus: null,
                  }
                : state;
        }
        default:
            return state;
    }
}

export const loginCreator = {
    authorize(): ThunkActionVoid {
        return (dispatch, getState, { api, initialLoaderCreator, loginSelector }) => {
            const { portalId, username, password, loginStatus } = loginSelector.getLoginState(
                getState(),
            );
            dispatch(loginCreator.changeIsLoading(true));
            if (!isNumber(portalId)) {
                return;
            }
            return api
                .login(username, password, portalId)
                .then(async ({ temporary }) => {
                    setLastPortalId(portalId);
                    setAuthenticated();
                    if (!temporary) {
                        await dispatch(initialLoaderCreator.initializeMain());
                    }
                    dispatch({
                        temporary,
                        type: LOGIN,
                    });
                })
                .catch(err => {
                    dispatch(loginCreator.addError(err, loginStatus));
                })
                .finally(() => {
                    dispatch(loginCreator.changeIsLoading(false));
                });
        };
    },
    logout: (withoutApiCall?: boolean): ThunkActionPromise => {
        return async (dispatch, getState, { api, goToURLCreator, loginSelector }) => {
            const state = getState();
            if (loginSelector.getLoginStatus(state) === LoginStatus.Shutdown) {
                return;
            }
            const impersonadeMode = getImpersonateMode(state);
            const portalId = loginSelector.getPortalId(state);
            const { afterLogoutLink } = withoutApiCall
                ? { afterLogoutLink: '' }
                : await api.logout(impersonadeMode);
            removeAuthenticated();
            if (afterLogoutLink) {
                dispatch({ type: SSO_LOGOUT, link: afterLogoutLink });
            } else {
                dispatch(goToURLCreator.gotoLoginPage(portalId));
            }
            // TODO: catch authorize errors
        };
    },

    changeUsername: (username: string): ThunkActionVoid => {
        return dispatch => {
            dispatch({
                username,
                type: CHANGE_USERNAME,
            });
        };
    },

    passwordChanged(username: string): ThunkActionVoid {
        return async dispatch => {
            dispatch({
                username,
                type: PASSWORD_CHANGED,
            });
        };
    },

    changePassword: (password: string): ThunkActionVoid => {
        return dispatch => {
            dispatch({
                password,
                type: CHANGE_PASSWORD,
            });
        };
    },

    changePortalId: (portalId: PortalId): ThunkActionVoid => {
        return dispatch => {
            dispatch({
                portalId,
                type: CHANGE_PORTALID,
            });
        };
    },

    changeLoginStatus: (newStatus: LoginStatus): ThunkActionVoid => {
        return async dispatch => {
            if (newStatus === LoginStatus.Portal) {
                dispatch({
                    type: CLEAR_BRAND_INFO,
                });
            }
            dispatch({
                type: CHANGE_LOGIN_STATUS,
                loginStatus: newStatus,
            });
        };
    },

    applyPortalId(): ThunkActionVoid {
        return async (
            dispatch,
            getState,
            { brandInfoCreator, loginCreator: login, loginSelector },
        ) => {
            try {
                dispatch(login.changeIsLoading(true));
                await dispatch(brandInfoCreator.fetchBrandInfo());
                await dispatch(brandInfoCreator.checkVersion());

                const portalVersion = loginSelector.getPortalVersion(getState());

                if (portalVersion !== 'old') {
                    return dispatch(login.gotoLoginPage());
                }
                return dispatch(login.changeLoginStatus(LoginStatus.Portal));
            } catch (err: any) {
                dispatch(login.changeLoginStatus(LoginStatus.Portal));
                return dispatch(login.addError(err, loginSelector.getLoginStatus(getState())));
            } finally {
                dispatch(login.changeIsLoading(false));
            }
        };
    },

    gotoLoginPage(): ThunkActionVoid {
        return async (
            dispatch,
            getState,
            { goToURLCreator, loginCreator: login, loginSelector },
        ) => {
            const state = getState();
            if (loginSelector.getLoginOptions(state) === 'SAML') {
                const portalId = loginSelector.getPortalId(state);
                setLastPortalId(portalId);
                await dispatch(persistLocationData());
                return dispatch(goToURLCreator.gotoSAML(portalId));
            }
            return dispatch(login.changeLoginStatus(LoginStatus.Login));
        };
    },
    fetchLoginOptions(): ThunkActionVoid {
        return async (dispatch, _, { api }) => {
            const { type } = await api.getLoginOptions();
            dispatch({ type: LOGIN_OPTIONS_FETCHED, options: type });
        };
    },
    addError: (error: ErrorType, loginStatus: LoginStatus): ThunkActionVoid => {
        return dispatch => {
            const errorMessage = getLoginError(error.status, loginStatus);
            dispatch({
                errorMessage,
                type: ADD_ERROR,
            });
        };
    },

    changeIsLoading: (isLoading: boolean): ThunkActionVoid => {
        return dispatch => {
            dispatch({
                isLoading,
                type: IS_LOADING,
            });
        };
    },

    switchPortal(): ThunkActionVoid {
        return dispatch => {
            dispatch(loginCreator.changeLoginStatus(LoginStatus.Portal));
            dispatch(loginCreator.changePortalId(null));
            dispatch(loginCreator.changeUsername(''));
            dispatch(loginCreator.changePassword(''));
        };
    },

    resetPassword(): ThunkActionVoid {
        return (dispatch, getState, { api, loginSelector }) => {
            const { portalId, username, loginStatus } = loginSelector.getLoginState(getState());
            if (!portalId) {
                return;
            }
            dispatch(this.changeIsLoading(true));
            return api
                .resetPassword(portalId, username)
                .then(() => {
                    dispatch(this.changeLoginStatus(LoginStatus.BackToLogin));
                })
                .catch((err: ErrorType) => {
                    dispatch(this.addError(err, loginStatus));
                })
                .finally(() => {
                    dispatch(this.changeIsLoading(false));
                });
        };
    },
    gotoRedirectURLIfNeeded:
        (): ThunkActionVoid =>
        (dispatch, getState, { loginSelector, goToURLCreator }) => {
            const redirectUrl = loginSelector.getRedirectUrl(getState());
            if (redirectUrl) {
                dispatch({ type: REDIRECT });
                return dispatch(goToURLCreator.gotoURL(redirectUrl));
            }
        },

    handleShowPage(): ThunkActionVoid {
        return dispatch => {
            dispatch({ type: PAGE_SHOW });
        };
    },
};
