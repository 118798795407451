import React, { useCallback } from 'react';
import { Checkbox, makeStyles } from 'worldapp-ui';
import { TableToggleCommonProps } from 'react-table';

export interface TableSelectedControlProps {
    checkboxProps: TableToggleCommonProps;
    onAllTasksClick?: () => void;
    onTaskClick?: (taskDefinitionId: number, checked: boolean) => void;
    taskId?: number;
}

const tableSelectedControlStyles = makeStyles(() => ({
    control: {
        marginTop: -2,
    },
}));

const TableSelectedControl: React.SFC<TableSelectedControlProps> = props => {
    const classes = tableSelectedControlStyles();
    const handleToggle = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            if (typeof props.onAllTasksClick === 'function') {
                props.onAllTasksClick();
            }
            if (typeof props.onTaskClick === 'function' && props.taskId) {
                props.onTaskClick(props.taskId, !props.checkboxProps.checked);
            }
        },
        [props],
    );
    return (
        <div
            onClick={handleToggle}
            data-testid={
                props.taskId ? `task_selected_control-${props.taskId}` : 'all_task_selected_control'
            }
        >
            <Checkbox color="primary" {...props.checkboxProps} className={classes.control} />
        </div>
    );
};

export default TableSelectedControl;
