export const SET_WEEKENDS_ENABLED = 'Calendar/SET_WEEKENDS_ENABLED';
export const SET_SELECTED_DATE = 'Calendar/SET_SELECTED_DATE';
export const CHANGE_CALENDAR_VIEW = 'Calendar/CHANGE_CALENDAR_VIEW';

interface SetWeekendsEnabledAction {
    enabled: boolean;
    type: typeof SET_WEEKENDS_ENABLED;
}

interface SetSelectedDateAction {
    date: Date;
    type: typeof SET_SELECTED_DATE;
}

interface ChangeCalendarViewAction {
    calendarView: CalendarView;
    type: typeof CHANGE_CALENDAR_VIEW;
}

export enum CalendarView {
    Month = 'month',
    Week = 'week',
    Day = 'day',
}

export type CalendarAction =
    | SetWeekendsEnabledAction
    | SetSelectedDateAction
    | ChangeCalendarViewAction;

export interface CalendarState {
    selectedDate: Date;
    weekendsEnabled: boolean;
    calendarView: CalendarView;
}

export const tasksViews = ['cards', 'table', 'calendar'] as const;
export type TasksView = typeof tasksViews[number];
export const defaultTasksView: TasksView = 'cards';

export const defaultCalendarView = CalendarView.Month;
