import { extractTextFromHtml } from 'worldapp-fe-utils';
import { DEFAULT_TDS_LIMIT } from '../../constants/main.constants';
import { groupBy } from '../../utils/groupBy.utils';

import {
    FETCH_TDS,
    FETCH_META,
    TasksAction,
    TasksDefinitionsState,
    TaskDefinition,
    GET_CREATE_TASK_PERMIT,
    TASK_PERMITS_LOADING,
} from './taskDefinitions.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionPromise } from '../index';
import { getTaskDefinitionsIds } from './taskDefinitionsSelector.reselect';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import { ApiGW } from '../../types/DTO/api-gw';
import { getAlwaysShowStartButtonFeature } from '../features/featuresSelector';

const CREATE_TASK_PERMIT = 'create-task';

export const initialState: TasksDefinitionsState = {
    taskDefinitions: [],
    taskDefinitionsById: {},
    totalTDs: 0,
    totalTasks: 0,
    tdIdsWithCreateTask: [],
    taskPermitsLoading: false,
};

export default function reducer(state = initialState, action: TasksAction): TasksDefinitionsState {
    switch (action.type) {
        case FETCH_TDS: {
            const taskDefinitions = action.taskDefinitions.map(td => ({
                ...td,
                title: extractTextFromHtml(td.title),
                statusesById: groupBy(td.statuses, 'id'),
            }));
            return {
                ...state,
                taskDefinitions,
                taskDefinitionsById: groupBy(taskDefinitions, 'id'),
                totalTDs: action.totalTDs,
            };
        }
        case FETCH_META: {
            const taskDefinitions = action.taskDefinitions.map(td => ({
                ...td,
                statusesById: groupBy(td.statuses, 'id'),
            }));

            return {
                ...state,
                taskDefinitions,
                taskDefinitionsById: groupBy(taskDefinitions, 'id'),
                totalTasks: action.totalTasks,
            };
        }

        case GET_CREATE_TASK_PERMIT: {
            return {
                ...state,
                tdIdsWithCreateTask: action.tdIdsWithCreateTask,
            };
        }

        case TASK_PERMITS_LOADING: {
            return {
                ...state,
                taskPermitsLoading: action.isLoading,
            };
        }

        default:
            return state;
    }
}

export const taskDefinitionsCreator = {
    fetchTaskDefinitions: (): ThunkActionPromise => {
        return (dispatch, getState, { api, featureSelector }) => {
            return api
                .getTaskDefinitions(DEFAULT_TDS_LIMIT, 0)
                .then(response => {
                    dispatch({
                        type: FETCH_TDS,
                        taskDefinitions: response.taskDefinitions,
                        totalTDs: response.total,
                        noAllTasks: featureSelector.getNoAllTasksFeature(getState()),
                    });
                })
                .then(() => dispatch(taskDefinitionsCreator.fetchTDSMeta()))
                .catch(err => err);
        };
    },

    fetchTDSMeta: (): ThunkActionPromise => {
        return (dispatch, getState, { api }) => {
            const state = getState();

            return Promise.all(
                state.taskDefinitions.taskDefinitions.map((td: TaskDefinition) =>
                    api.getTDMeta(td.id),
                ),
            )
                .then(responses =>
                    responses.reduce(
                        (items, response) => items.concat(response.items),
                        [] as ApiGW.TaskDefinitionMetaDTO[],
                    ),
                )
                .then(tdItems => {
                    const taskDefinitions = state.taskDefinitions.taskDefinitions.map(
                        (td: TaskDefinition) => {
                            const taskDefinition = { ...td };
                            tdItems.forEach(item => {
                                if (taskDefinition.id === item.taskDefinitionId) {
                                    taskDefinition.count = item.count;
                                    taskDefinition.managed = item.managed;
                                }
                            });

                            return taskDefinition;
                        },
                    );
                    const totalTasks = tdItems.reduce((acc, task) => acc + task.count, 0);
                    return dispatch({
                        taskDefinitions,
                        totalTasks,
                        type: FETCH_META,
                    });
                })
                .catch(err => err);
        };
    },

    getCreateTaskPermitsOnInitialLoad: (): ThunkActionPromise => {
        return async (dispatch, getState, { api, toastsCreator }) => {
            const taskDefinitionsIds = getTaskDefinitionsIds(getState());
            const tdIdsWithCreateTask: number[] = [];
            for (let index = 0; index < taskDefinitionsIds.length; index++) {
                try {
                    const id = taskDefinitionsIds[index];
                    // eslint-disable-next-line no-await-in-loop
                    const permit = await api.getTaskDefinitionPermit(id, CREATE_TASK_PERMIT);
                    if (permit.items.length > 0) {
                        tdIdsWithCreateTask.push(id);
                        break;
                    }
                } catch (error: any) {
                    dispatch(toastsCreator.createToast(error.message, 'error'));
                }
            }

            dispatch({ type: GET_CREATE_TASK_PERMIT, tdIdsWithCreateTask });
        };
    },

    getCreateTaskPermits: (): ThunkActionPromise => {
        return async (dispatch, getState, { api, toastsCreator }) => {
            try {
                dispatch({ type: TASK_PERMITS_LOADING, isLoading: true });
                const taskDefinitionsIds = getTaskDefinitionsIds(getState());

                const result = await Promise.all(
                    taskDefinitionsIds.map(id =>
                        api
                            .getTaskDefinitionPermit(id, CREATE_TASK_PERMIT)
                            .then(res => (res.items.length > 0 ? id : null)),
                    ),
                );
                const tdIdsWithCreateTask = result.filter(value => value !== null) as number[];

                dispatch({ type: GET_CREATE_TASK_PERMIT, tdIdsWithCreateTask });
                dispatch({ type: TASK_PERMITS_LOADING, isLoading: false });
            } catch (error: any) {
                dispatch(toastsCreator.createToast(error.message, 'error'));
                dispatch({ type: TASK_PERMITS_LOADING, isLoading: false });
            }
        };
    },

    getCreateTaskPermit: (taskDefinitionId: number): ThunkActionPromise => {
        return async (dispatch, _, { api, toastsCreator }) => {
            try {
                const result = await api
                    .getTaskDefinitionPermit(taskDefinitionId, CREATE_TASK_PERMIT)
                    .then(res => (res.items.length > 0 ? [taskDefinitionId] : []));

                dispatch({ type: GET_CREATE_TASK_PERMIT, tdIdsWithCreateTask: result });
            } catch (error: any) {
                dispatch(toastsCreator.createToast(error.message, 'error'));
            }
        };
    },

    getCreateTaskPermitOrPermits: (activeItemId: number): ThunkActionPromise => {
        return async (dispatch, getState, { taskDefinitionsCreator: tdsCreator }) => {
            if (activeItemId === ALL_TASKS_ID) {
                if (!getAlwaysShowStartButtonFeature(getState()))
                    await dispatch(tdsCreator.getCreateTaskPermitsOnInitialLoad());
            } else {
                await dispatch(tdsCreator.getCreateTaskPermit(activeItemId));
            }
        };
    },
};
