import cloneDeep from 'clone-deep';
import { Dictionary, NumericDictionary } from 'worldapp-fe-utils';

type SomeDictionary<E, K extends string | number> = K extends number
    ? NumericDictionary<E>
    : Dictionary<E>;
type Mapper<E, K extends string | number> = K extends number ? (e: E) => number : (e: E) => string;

export function groupByMapper<E>(entities: E[], keyMapper: Mapper<E, string>): Dictionary<E>;
export function groupByMapper<E>(entities: E[], keyMapper: Mapper<E, number>): NumericDictionary<E>;
export function groupByMapper<E, K extends string | number>(
    entities: E[],
    keyMapper: Mapper<E, K>,
): SomeDictionary<E, K> {
    const reduceMapById = (groupedEntities: SomeDictionary<E, K>, entity: E) => {
        const key = keyMapper(entity);
        const newEntities = cloneDeep(groupedEntities);
        if (typeof key === 'number') {
            (newEntities as NumericDictionary<E>)[key] = entity;
        } else {
            (newEntities as Dictionary<E>)[key] = entity;
        }
        return newEntities;
    };

    return entities.reduce(reduceMapById, {} as SomeDictionary<E, K>) as any;
}

export function groupBy<E, P extends keyof E>(
    entities: E[],
    property: E[P] extends string | number ? P : never,
): SomeDictionary<E, E[P] extends string ? string : number> {
    return groupByMapper<E>(entities, ((e: E) => e[property]) as any) as any;
}
