import React from 'react';
import { Grid } from 'worldapp-ui/shared';
import { taskDetailViewStyles } from './TaskDetailView.styles';
import { PlaceholderBlock } from '../../Placeholders/PlaceholderBlock';

export const TaskDetailViewPlaceholder = (): JSX.Element => {
    const classes = taskDetailViewStyles();
    return (
        <Grid
            container={true}
            direction="column"
            wrap="nowrap"
            justify="space-between"
            className={classes.placeholderRoot}
        >
            <div className={classes.tdContainer}>
                <div className={classes.tdHeader}>
                    <PlaceholderBlock width={156} height={28} />
                    <div className={classes.tdHeaderIcon}>
                        <PlaceholderBlock width={120} height={28} />
                    </div>
                </div>
                <div className={classes.tdBody}>
                    <div className={classes.tdActionSection}>
                        <div>
                            <PlaceholderBlock width={72} height={16} />
                        </div>
                        <div className={classes.tdTitle}>
                            <PlaceholderBlock width={532} height={40} />
                        </div>

                        <PlaceholderBlock width={200} height={24} />
                        <PlaceholderBlock
                            width={200}
                            height={24}
                            className={classes.placeholderBlockMiddle}
                        />
                        <PlaceholderBlock width={200} height={24} />
                    </div>
                    {/* Task description */}
                    <div className={classes.tdDescriptionSection}>
                        <div>
                            <PlaceholderBlock width={72} height={16} />
                            <PlaceholderBlock
                                width={532}
                                height={40}
                                className={classes.placeholderBlock}
                            />

                            <div className={classes.placeholderBlockMiddle}>
                                <PlaceholderBlock width={72} height={16} />
                                <PlaceholderBlock
                                    width={200}
                                    height={24}
                                    className={classes.placeholderBlock}
                                />
                            </div>
                            <div className={classes.placeholderBlock}>
                                <PlaceholderBlock width={72} height={16} />
                                <PlaceholderBlock
                                    width={200}
                                    height={24}
                                    className={classes.placeholderBlock}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    );
};
