import { browserDetect } from 'worldapp-fe-utils';

const browser = browserDetect(window.navigator.userAgent);

export const { isMobile } = browser;
export const { isIE } = browser;
export const { deviceType } = browser;
export const isMobileDevice = deviceType === 'mobile';
export const isTablet = deviceType === 'tablet';
export const isDesktop = !(isMobileDevice || isTablet);

export const getScrollbarWidth = (): number => {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.overflow = 'scroll';
    document.body.appendChild(scrollDiv);

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
};
