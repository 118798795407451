import { Converter as GenericConverter } from 'worldapp-fe-utils';
import { defaultTasksView, TasksView } from '../redux/menu/menu.types';
import { QuickFilters } from '../redux/contentQuickFilters/contentQiuckFilters.types';
import {
    defaultSortColumn,
    defaultSortOrder,
    SortBy,
    SortOrder,
} from '../redux/tasksSort/tasksSort.types';
import { Category } from '../redux/content/category.types';
import { LoginStatus } from '../redux/login/login.types';

export interface LocationState {
    activeCategory: Category | null;
    activeItemId: number | null;
    portalId: number | null;
    tasksView: TasksView | null;
    taskSortingParameter: SortBy;
    taskSortingDirection: SortOrder;
    quickFilters: QuickFilters | null;
    taskDetailView: boolean;
    activeCardId: string | null;
    loginStatus?: LoginStatus;
    isDefaultNavigation: boolean | null;
    searchQuery: string | null;
    submit: boolean;
    offset: number;
    redirectURL: string | null;
    newResponseParams: Record<string, string[]> | null;
    isBackUrl?: boolean;
    respondentId?: string;
    startForm?: boolean;
    resultHasBeenSaved?: boolean;
    failedSSOLogin?: string;
}

export const defaultLocationState: LocationState = {
    activeCategory: null,
    activeItemId: null,
    portalId: null,
    tasksView: defaultTasksView,
    taskDetailView: false,
    quickFilters: null,
    taskSortingParameter: { column: defaultSortColumn },
    taskSortingDirection: defaultSortOrder,
    activeCardId: null,
    isDefaultNavigation: null,
    searchQuery: null,
    submit: false,
    offset: 0,
    redirectURL: null,
    newResponseParams: null,
};

export type Converter = GenericConverter<LocationState>;
