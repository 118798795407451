import { Middleware } from 'redux';
import { CHANGE_COLUMNS_ORDER } from '../redux/tasksTable/tasksTable.types';
import { storage } from './localStorage';
import { getStorageKey } from './storage.utils';
import { AppAction } from '../redux';
import {
    APPLY_FILTERS,
    DELETE_SAVED_FILTERS_SET,
    SAVE_FILTERS_SET,
    TOGGLE_APPLIED_STRING_FILTER_VALUE,
} from '../redux/tasksConfigurableFilters/tasksConfigurableFilters.types';
import { CHANGE_LANGUAGE } from '../redux/localization/localization.types';
import { AppState } from '../redux/AppState.types';
import {
    CHANGE_QUICK_FILTER,
    COPY_QUICK_FILTERS_TO_APPLIED,
} from '../redux/contentQuickFilters/contentQiuckFilters.types';
import { ADD_FREQUENTLY_USED_ITEM } from '../redux/frequentlyUsed/frequentlyUsed.types';
import { CHANGE_TASKS_VIEW, SET_ACTIVE_CATEGORY, SET_ACTIVE_ITEM } from '../redux/menu/menu.types';
import {
    CHANGE_SORT_COLUMN,
    CHANGE_SORT_COLUMN_OR_ORDER,
    RESET_SORT,
    TOGGLE_SORT_ORDER,
} from '../redux/tasksSort/tasksSort.types';
import { SET_EXPORT_STATUS } from '../redux/tasksExport/tasksExport.types';
import { CHANGE_CALENDAR_VIEW, SET_WEEKENDS_ENABLED } from '../redux/calendar/calendar.types';

type ActionType = AppAction extends { type: infer C } ? C : never;

type ReducersToPersist = {
    reducers: Array<keyof AppState>;
    sharedBetweenUsers?: boolean;
    reducersItems?: Array<string>;
};

export const actionsToPersist: Partial<Record<ActionType, ReducersToPersist>> = {
    [SAVE_FILTERS_SET]: {
        reducers: ['tasksConfigurableFilters'],
    },
    [DELETE_SAVED_FILTERS_SET]: {
        reducers: ['tasksConfigurableFilters'],
    },
    [APPLY_FILTERS]: {
        reducers: ['tasksConfigurableFilters', 'quickFilters'],
    },
    [TOGGLE_APPLIED_STRING_FILTER_VALUE]: {
        reducers: ['tasksConfigurableFilters'],
    },
    [CHANGE_QUICK_FILTER]: {
        reducers: ['tasksConfigurableFilters', 'quickFilters'],
    },
    [COPY_QUICK_FILTERS_TO_APPLIED]: {
        reducers: ['tasksConfigurableFilters'],
    },
    [CHANGE_COLUMNS_ORDER]: {
        reducers: ['tasksTable'],
    },
    [CHANGE_LANGUAGE]: {
        reducers: ['localization'],
        sharedBetweenUsers: true,
    },
    [ADD_FREQUENTLY_USED_ITEM]: {
        reducers: ['frequentlyUsed'],
    },
    [SET_ACTIVE_CATEGORY]: {
        reducers: ['menu'],
        reducersItems: ['navigation', 'tasksView'],
    },
    [SET_ACTIVE_ITEM]: {
        reducers: ['menu'],
        reducersItems: ['navigation', 'tasksView'],
    },
    [CHANGE_TASKS_VIEW]: {
        reducers: ['menu'],
        reducersItems: ['navigation', 'tasksView'],
    },
    [CHANGE_SORT_COLUMN]: {
        reducers: ['tasksSort'],
    },
    [TOGGLE_SORT_ORDER]: {
        reducers: ['tasksSort'],
    },
    [CHANGE_SORT_COLUMN_OR_ORDER]: {
        reducers: ['tasksSort'],
    },
    [RESET_SORT]: {
        reducers: ['tasksSort'],
    },
    [SET_EXPORT_STATUS]: {
        reducers: ['tasksExport'],
    },
    [CHANGE_CALENDAR_VIEW]: {
        reducers: ['calendar'],
        reducersItems: ['calendarView', 'weekendsEnabled'],
    },
    [SET_WEEKENDS_ENABLED]: {
        reducers: ['calendar'],
        reducersItems: ['calendarView', 'weekendsEnabled'],
    },
};

export const persistentReducers = Array.from(
    new Set(
        (Object.values(actionsToPersist) as ReducersToPersist[]).reduce(
            (acc: Array<keyof AppState>, curr) => [...acc, ...curr?.reducers],
            [],
        ),
    ),
);

export const storageMiddleware: Middleware = store => next => action => {
    const result = next(action);
    const reducersToPersist = actionsToPersist[action.type as ActionType] || null;

    if (reducersToPersist) {
        const state = store.getState();
        const userId = state.user.contactId;
        const { sharedBetweenUsers, reducers, reducersItems } = reducersToPersist;

        reducers.forEach(reducerName => {
            const stateToPersist = reducersItems
                ? reducersItems.reduce(
                      (prev, item) => ({ ...prev, [item]: state[reducerName][item] }),
                      {},
                  )
                : state[reducerName];

            const storageName = sharedBetweenUsers
                ? reducerName
                : getStorageKey(reducerName, userId);

            storage.set(storageName, stateToPersist);
        });
    }

    return result;
};
