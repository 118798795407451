import React, { ChangeEvent, memo } from 'react';
import { SearchBox, IconButton } from 'worldapp-ui/shared';
import { Filter as FilterIcon } from 'worldapp-ui/shared/icons';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../../redux/tasksActions/taskActionsSelector';
import { changeAssigneeStyles } from '../ActionStyles.styles';
import { ChangeAssigneeControl } from './ChangeAssigneeControl';

interface ChangeAssigneeContentProps {
    contacts: Contact[] | null;
    activeContactId: number | null;
    changeActiveContactId: (value: number) => void;
}

export const ChangeAssigneeContent = memo((props: ChangeAssigneeContentProps) => {
    const { contacts, activeContactId, changeActiveContactId } = props;
    const onChangeActiveContactId = (event: React.ChangeEvent<HTMLInputElement>) =>
        changeActiveContactId(parseInt(event.target.value, 10));

    return (
        <>
            {contacts?.map(({ name, id }) => {
                const isChecked = id === activeContactId;
                return (
                    <ChangeAssigneeControl
                        key={id}
                        id={id}
                        isChecked={isChecked}
                        name={name}
                        onChange={onChangeActiveContactId}
                    />
                );
            })}
        </>
    );
});

export interface SearchProps {
    searchValue: string;
    onSearchQueryChange: (searchQuery: string) => void;
    className?: string;
}
export const SearchInput = (props: SearchProps): JSX.Element => {
    const { searchValue, onSearchQueryChange, className } = props;
    const { t } = useTranslation();

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onSearchQueryChange(e.target.value);
    };
    const onClear = () => onSearchQueryChange('');

    return (
        <SearchBox
            value={searchValue}
            onChange={onChange}
            data-testid="search-input_input"
            placeholder={t('TaskActions.ChangeAssignee.Search')}
            size="xl"
            fullWidth
            showClearSearch={!!searchValue}
            onClearSearch={onClear}
            clearSearchLabel={t('NavigationPanel.ClearSearch')}
            {...(className ? { className } : {})}
        />
    );
};

export interface FilterButtonProps {
    onFilterClick: () => void;
    className?: string;
}
export const FilterButton = (props: FilterButtonProps): JSX.Element => {
    const { onFilterClick, className } = props;
    const classes = changeAssigneeStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.filterButton}>
            <IconButton
                icon={<FilterIcon />}
                onClick={onFilterClick}
                data-testid="btn_change-assignee-filter"
                {...(className ? { className } : {})}
                aria-label={t('TaskActions.ChangeAssignee.ContactFilters')}
            />
        </div>
    );
};
