import React from 'react';
import { createStyles, Grid, makeStyles, Theme, FilterButton } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ResponseQuickFilters } from '../../redux/contentQuickFilters/contentQiuckFilters.types';
import { contentQuickFiltersCreator } from '../../redux/contentQuickFilters/contentQuickFilters';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { CONTENT_CATEGORY_FORMS } from '../../redux/content/category.types';
import { AppState } from '../../redux/AppState.types';
import {
    getCurrentResponseQuickFilter,
    getTotalResponsesCount,
} from '../../redux/contentQuickFilters/contentQuickFiltersSelector';
import { getFoundResponsesCount } from '../../redux/content/contentSelector';
import { getHideCompleted } from '../../redux/portalSettings/portalSettingsSelector';
import { isMobile } from '../../utils/browser.utils';
import { GRID_MOBILE_PADDING } from '../ui/cards/cardsSizes';

interface ResponsesQuickFiltersButton {
    key: keyof ResponseQuickFilters;
    text: string;
    testId: string;
}

const buttons: ResponsesQuickFiltersButton[] = [
    {
        key: 'recentResponses',
        text: 'ResponseQuickFilters.Recent',
        testId: 'btn-quick-filter_recent',
    },
    {
        key: 'inProgressResponses',
        text: 'ResponseQuickFilters.InProgress',
        testId: 'btn-quick-filter_in-progress',
    },
    {
        key: 'completedResponses',
        text: 'ResponseQuickFilters.Completed',
        testId: 'btn-quick-filter_completed',
    },
];

const useFormResponsesHeaderPanelStyles = makeStyles((theme: Theme) =>
    createStyles({
        found: {
            color: theme.palette.neutral[65],
            marginLeft: theme.spacing(1),
        },
        filterButton: {
            margin: 0,
            whiteSpace: 'nowrap',
        },
        container: {
            flexWrap: 'nowrap',
            overflowX: 'auto',
            margin: '0 0 0 -4px',
            width: 'auto' /* https://github.com/mui-org/material-ui/issues/17142 */,
            paddingRight: isMobile ? GRID_MOBILE_PADDING : 0,
        },
    }),
);

const mapDispatchToProps = mapCreatorsToDispatchProps({
    changeQuickFilter: contentQuickFiltersCreator.changeQuickFilter,
});

export const mapStateToProps = (state: AppState) => ({
    filters: getCurrentResponseQuickFilter(state),
    foundResponses: getFoundResponsesCount(state),
    totalResponses: getTotalResponsesCount(state),
    hideCompleted: getHideCompleted(state),
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export const ResponseQuickFilterComponent = ({
    filters,
    changeQuickFilter,
    hideCompleted,
}: Props): JSX.Element => {
    const classes = useFormResponsesHeaderPanelStyles();
    const { t } = useTranslation();

    const onClick = (name: keyof ResponseQuickFilters) => () =>
        changeQuickFilter({ name, category: CONTENT_CATEGORY_FORMS });

    return (
        <Grid container={true} alignItems="center" spacing={1} className={classes.container}>
            {buttons.map(({ key, text, testId }) => {
                if (key === 'completedResponses' && hideCompleted) return null;

                return (
                    <Grid item={true} key={key}>
                        <FilterButton
                            isChecked={filters[key]}
                            onClick={onClick(key)}
                            data-testid={testId}
                            className={classes.filterButton}
                        >
                            {t(text)}
                        </FilterButton>
                    </Grid>
                );
            })}
            {/* <Grid item={true}>
                <Typography
                    variant={'body1'}
                    className={classes.found}
                    data-testid="txt_found-responses"
                >
                    {t('ResponseQuickFilters.FoundResponsesText', {
                        foundResponses,
                        totalResponses,
                    })}
                </Typography>
            </Grid> */}
        </Grid>
    );
};

export const ResponseQuickFilter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResponseQuickFilterComponent);
