import { AppState } from '../AppState.types';

export const getUserId = (state: AppState): number => state.user.contactId;

export const getUserEmail = (state: AppState): string => state.user.email;

export const getUserLogin = (state: AppState): string => state.user.login;

export const isUserExists = (state: AppState): boolean => state.user.exists;

export const getUserLanguage = (state: AppState): string => state.user.lang;

export default {
    getUserId,
    getUserEmail,
    getUserLogin,
    isUserExists,
    getUserLanguage,
};
