import { Moment } from 'moment';
import { SecondaryTaskAction } from '../content/validateTaskActions';
import { ApiGW } from '../../types/DTO/api-gw';
import { DataModelRowsDTO } from '../taskDefinitions/taskDefinitions.types';
import { Task } from '../content/content.types';

export const OPEN_ACTION_POPUP = 'TasksActions/OPEN_ACTION_POPUP';
export const CLOSE_ACTION_POPUP = 'TasksActions/CLOSE_ACTION_POPUP';
export const CHANGE_CONTACT_ID = 'TasksActions/CHANGE_CONTACT_ID';
export const CHANGE_STATUS_ID = 'TasksActions/CHANGE_STATUS_ID';
export const CHANGE_DUE_DATE = 'TasksActions/CHANGE_DUE_DATE';
export const CHANGE_CM_VALUES = 'TasksActions/CHANGE_CM_VALUES';

export const FILTER_DROPDOWN_FETCH_VALUES_REQUEST =
    'TasksActions/FILTER_DROPDOWN_FETCH_VALUES_REQUEST';
export const FILTER_DROPDOWN_FETCH_VALUES_SUCCESS =
    'TasksActions/FILTER_DROPDOWN_FETCH_VALUES_SUCCESS';
export const FILTER_DROPDOWN_TOGGLE_IS_OPEN = 'TasksActions/FILTER_DROPDOWN_TOGGLE_IS_OPEN';
export const FILTER_DROPDOWN_SELECT_ITEM = 'TasksActions/FILTER_DROPDOWN_SELECT_ITEM';
export const FILTER_DROPDOWN_INPUT_CHANGE = 'TasksActions/FILTER_DROPDOWN_INPUT_CHANGE';
export const UPDATE_CONTACTS_FILTERED_COUNT = 'TasksActions/UPDATE_CONTACTS_FILTERED_COUNT';
export const RESET_ALL_FILTERS = 'TasksActions/RESET_ALL_FILTERS';
export const DISPLAY_CHANGE_ASSIGNEE_CONTENT =
    'TasksActions/DISPLAY_CHANGE_ASSIGNEE_CONTENT_ACTION';
export const DISPLAY_CHANGE_ASSIGNEE_FILTERS =
    'TasksActions/DISPLAY_CHANGE_ASSIGNEE_FILTERS_ACTION';
export const CHANGE_SEARCH_QUERY = 'TasksActions/CHANGE_SEARCH_QUERY';
export const CONTACT_MANAGER_FETCH_VALUES_SUCCESS =
    'TasksActions/CONTACT_MANAGER_FETCH_VALUES_SUCCESS';
export const APPLY_FILTERS = 'TasksActions/APPLY_FILTERS';

export const CHANGE_IS_LOADING = 'TasksActions/CHANGE_IS_LOADING';
export const BULK_QUEUE_ADD = 'TasksActions/BULK_QUEUE_ADD';
export const BULK_QUEUE_CHANGE_STATUS = 'TasksActions/BULK_QUEUE_CHANGE_STATUS';
export const BULK_QUEUE_UPDATE_TOAST_KEY = 'TasksActions/BULK_QUEUE_UPDATE_TOAST_KEY';
export const BULK_QUEUE_DELETE = 'TasksActions/BULK_QUEUE_DELETE';

interface ApplyFiltersAction {
    type: typeof APPLY_FILTERS;
}

interface FetchContactManagerValuesSuccessAction {
    type: typeof CONTACT_MANAGER_FETCH_VALUES_SUCCESS;
    values: DataModelRowsDTO;
}

interface DisplayChangeAssigneeContentAction {
    type: typeof DISPLAY_CHANGE_ASSIGNEE_CONTENT;
}

interface DisplayChangeAssigneeFiltersAction {
    type: typeof DISPLAY_CHANGE_ASSIGNEE_FILTERS;
}

interface ResetAllFilters {
    type: typeof RESET_ALL_FILTERS;
}

interface UpdateContactsFilteredCount {
    type: typeof UPDATE_CONTACTS_FILTERED_COUNT;
    count: number;
}

export interface ToggleFilterDropdownIsOpenAction {
    type: typeof FILTER_DROPDOWN_TOGGLE_IS_OPEN;
    filterId: number;
}

interface FilterDropdownInputChangeAction {
    type: typeof FILTER_DROPDOWN_INPUT_CHANGE;
    filterId: number;
    value: string | null;
}

interface FilterDropdownSelectItemAction {
    type: typeof FILTER_DROPDOWN_SELECT_ITEM;
    filterId: number;
    selectedIndex: number | null;
}

export interface FetchContactFilterValuesSuccessAction {
    type: typeof FILTER_DROPDOWN_FETCH_VALUES_SUCCESS;
    filterId: number;
    values: ApiGW.DataModelValueDTO[];
}

export interface FetchContactFilterValuesRequestAction {
    type: typeof FILTER_DROPDOWN_FETCH_VALUES_REQUEST;
    filterId: number;
}

interface ChangeSearchQueryAction {
    type: typeof CHANGE_SEARCH_QUERY;
    query: string;
}

export interface ContactManagerView {
    columns: ApiGW.DataModelColumnsDTO;
    values: DataModelRowsDTO | null;
    nameFieldId: number;
}

export interface TaskActionState {
    type: SecondaryTaskAction | 'ConfirmChangeDueDate';
    isLoading: boolean;
    task?: Task;
    bulkActionsTasks?: Task[];
    bulkUpdates: ApiGW.TaskPartialUpdateDTOV3;
}

export type OpenPopupAbstractAction =
    | {
          type: typeof OPEN_ACTION_POPUP;
          action: SecondaryTaskAction;
          task: Task;
      }
    | {
          type: typeof OPEN_ACTION_POPUP;
          action: SecondaryTaskAction;
          bulkActionsTasks: Task[];
      };

export type OpenChangeAssigneePopup = {
    action: 'ChangeAssignee';
    activeContactManager: ContactManagerView;
    contactFilters: ApiGW.ContactFilterDTO[];
} & OpenPopupAbstractAction;

export type OpenChangeStatusPopup = {
    action: 'ChangeStatus';
    showVotingMessage?: boolean;
} & OpenPopupAbstractAction;

export type OpenPopupDefaultAction = {
    action: 'UnAssign' | 'AssignToMe' | 'ChangeDueDate';
} & OpenPopupAbstractAction;

export type OpenConfirmChangeDueDatePopup = {
    type: typeof OPEN_ACTION_POPUP;
    action: 'ConfirmChangeDueDate';
    task: Task;
};

export type OpenPopupAction =
    | OpenPopupDefaultAction
    | OpenChangeAssigneePopup
    | OpenChangeStatusPopup
    | OpenConfirmChangeDueDatePopup;

export type BulkActionQueueElement = {
    key: string;
    time: number;
    status?: 'inProgress' | 'wait';
    bulkActionsTasks: Task[];
    bulkUpdates: ApiGW.TaskPartialUpdateDTOV3;
    typeAction: string;
    showVotingMessage?: boolean;
    toastKey: string;
};

interface ClosePopupAction {
    type: typeof CLOSE_ACTION_POPUP;
}

interface ChangeContactIdAction {
    type: typeof CHANGE_CONTACT_ID;
    activeContactId: number;
}

interface ChangeCurrentDueDateAction {
    type: typeof CHANGE_DUE_DATE;
    date: Moment;
}

interface ChangeStatusIdAction {
    type: typeof CHANGE_STATUS_ID;
    currentStatusId: number;
}

interface ChangeCMValuesAction {
    type: typeof CHANGE_CM_VALUES;
    cmValues: DataModelRowsDTO;
    resetItems?: boolean;
}

interface ChangeIsLoadingAction {
    type: typeof CHANGE_IS_LOADING;
    isLoading: boolean;
}

export interface BulkQueueAdd {
    type: typeof BULK_QUEUE_ADD;
    bulkActionsQueueElement: BulkActionQueueElement;
}
export interface BulkQueueChange {
    type: typeof BULK_QUEUE_CHANGE_STATUS;
    bulkActionsQueueElementKey: string;
    bulkActionsQueueElementStatus: 'inProgress' | 'wait';
}
export interface BulkQueueUpdateToastkey {
    type: typeof BULK_QUEUE_UPDATE_TOAST_KEY;
    bulkActionsQueueElementKey: string;
    newToastKey: string;
}
export interface BulkQueueDelete {
    type: typeof BULK_QUEUE_DELETE;
    bulkActionsQueueElementKey: string;
}

export type TasksActionsAction =
    | OpenPopupAction
    | ClosePopupAction
    | ChangeContactIdAction
    | ChangeCurrentDueDateAction
    | ChangeStatusIdAction
    | UpdateContactsFilteredCount
    | FetchContactFilterValuesRequestAction
    | FetchContactFilterValuesSuccessAction
    | FilterDropdownSelectItemAction
    | FilterDropdownInputChangeAction
    | ToggleFilterDropdownIsOpenAction
    | DisplayChangeAssigneeContentAction
    | DisplayChangeAssigneeFiltersAction
    | ResetAllFilters
    | ApplyFiltersAction
    | ChangeSearchQueryAction
    | FetchContactManagerValuesSuccessAction
    | ChangeCMValuesAction
    | ChangeIsLoadingAction
    | BulkQueueAdd
    | BulkQueueChange
    | BulkQueueUpdateToastkey
    | BulkQueueDelete;

export type ChangeAssigneeContent = 'contacts' | 'filter';

export interface ChangeAssigneeFilter {
    id: number;
    columnId: number;
    title: string;
    inputValue: string | null;
    items: ApiGW.DataModelValueDTO[];
    selectedItemIndex: number | null;
    isLoading: boolean;
    isDisabled: boolean;
    isOpened: boolean;
}

export interface ChangeAssigneeFilterView extends ChangeAssigneeFilter {
    suggestions: Array<{ label: string }>;
    selectedItem: string | null;
}

export interface ChangeAssigneeState extends TaskActionState {
    type: 'ChangeAssignee';
    activeContactManager: ContactManagerView;
    changeAssigneeContent: ChangeAssigneeContent;
    filters: ChangeAssigneeFilter[];
    filtersDraft: ChangeAssigneeFilter[];
    searchQuery: string;
    offset: number;
    hasMoreContacts: boolean;
}

export interface ChangeStatusState extends TaskActionState {
    type: 'ChangeStatus';
    showVotingMessage?: boolean;
}

export interface ChangeDueDateState extends TaskActionState {
    type: 'ChangeDueDate';
}

export interface ConfirmChangeDueDateState extends TaskActionState {
    type: 'ConfirmChangeDueDate';
}

export interface UnAssignState extends TaskActionState {
    type: 'UnAssign';
}

export interface AssignToMeState extends TaskActionState {
    type: 'AssignToMe';
}

export interface NullState {
    type: 'NullState';
}

export type TasksActionsState = {
    bulkActionsQueue: BulkActionQueueElement[];
} & (
    | ChangeAssigneeState
    | ChangeStatusState
    | ChangeDueDateState
    | ConfirmChangeDueDateState
    | UnAssignState
    | AssignToMeState
    | NullState
);
