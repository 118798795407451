import React, { RefObject, useRef } from 'react';
import { createStyles, makeStyles, Text } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/browser.utils';

const LEARN_MORE_LINK = 'https://help.opx.form.com/hc/latest/due-date';

const styles = makeStyles(theme =>
    createStyles({
        wrapper: {
            color: theme.palette.neutral[65],
            padding: theme.spacing(5),
            height: 'calc(100% - 80px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            textAlign: 'center',
            '&[data-ismobile="true"]': {
                padding: theme.spacing(2.5),
            },
            margin: '0px 0px 20px 0px',
            minHeight: '152px',
        },
        text: {
            margin: 20,
        },
        a: {
            color: theme.palette.neutral[65],
        },
    }),
);
const NoDueDate = (): JSX.Element => {
    const refNothingFound = useRef(null) as RefObject<HTMLDivElement>;
    const { t } = useTranslation();

    const classes = styles();
    return (
        <div
            className={classes.wrapper}
            data-testid={'no-due-date'}
            ref={refNothingFound}
            data-ismobile={isMobile}
        >
            <Text variant={isMobile ? 'r22b' : 'r30b'} className={classes.text}>
                {t('TasksCalendar.NoDueDate')}
            </Text>
            <Text variant={'r18m'} className={classes.text} style={{ maxWidth: 400 }}>
                {t('TasksCalendar.NoDueDateDetails')}
            </Text>
            <Text variant={'r14r'} className={classes.text}>
                <a href={LEARN_MORE_LINK} target="_blank" rel="noreferrer" className={classes.a}>
                    {' '}
                    {t('TasksCalendar.NoDueDateLearnMore')}
                </a>
            </Text>
        </div>
    );
};

export default NoDueDate;
