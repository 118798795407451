import superagent, { ResponseError } from 'superagent';
import { httpOptions } from './helpers.utils';

interface Opts {
    origin?: string | null;
}

type methodType = 'get' | 'post' | 'put' | 'patch' | 'delete';

export type ErrorType = ResponseError;

export const RETRY = 'RETRY';
export const ABORT = 'ABORT';
export type CallbackResolution = typeof RETRY | typeof ABORT;

export class Http {
    static prepareParams(params: Record<string, unknown>): any {
        return JSON.parse(JSON.stringify(params));
    }

    private readonly origin: string;

    constructor(opts: Opts = {}) {
        this.origin = opts.origin || '';
    }

    get<T, R extends Record<string, unknown> | undefined = Record<string, any> | undefined>(
        url: string,
        params?: R,
    ): Promise<T> {
        return this.doMethod<T, R>('get', url, params);
    }

    post<T, R extends Record<string, unknown> | undefined = Record<string, any> | undefined>(
        url: string,
        params?: R,
    ): Promise<T> {
        return this.doMethod<T, R>('post', url, params);
    }

    put<T, R extends Record<string, unknown> | undefined = Record<string, any> | undefined>(
        url: string,
        params?: R,
    ): Promise<T> {
        return this.doMethod<T, R>('put', url, params);
    }

    patch<T, R extends Record<string, unknown> | undefined = Record<string, any> | undefined>(
        url: string,
        params?: R,
    ): Promise<T> {
        return this.doMethod<T, R>('patch', url, params);
    }

    delete<T, R extends Record<string, unknown> | undefined = Record<string, any> | undefined>(
        url: string,
        params?: R,
    ): Promise<T> {
        return this.doMethod<T, R>('delete', url, params);
    }

    doMethod<T, R extends Record<string, unknown> | undefined = Record<string, any> | undefined>(
        method: methodType,
        url: string,
        params?: R,
    ): Promise<T> {
        return this.prepareRequest(url, method, params).then(res => res.body || {});
    }

    prepareRequest<R extends Record<string, unknown> | undefined = Record<string, any> | undefined>(
        url: string,
        method: methodType,
        params: R,
    ): superagent.SuperAgentRequest {
        const requestUrl = this.origin + url;

        const request = superagent[method](requestUrl);

        if (params instanceof Object) {
            request.send(Http.prepareParams(params));
        }
        request.set('Accept', 'application/json');
        if (method === 'get') {
            request.set('Cache-control', 'no-cache, no-store, must-revalidate');
            request.set('Expires', '0');
            request.set('Pragma', 'no-cache');
        }
        return request;
    }
}

export const http = new Http(httpOptions);
