import React from 'react';
import { Icon, IconButton, makeStyles, TextButton } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';

import { getCurrentTaskExport } from '../../redux/tasksExport/tasksExportSelector';
import { tasksExportCreator } from '../../redux/tasksExport/tasksExport';
import { AppState } from '../../redux/AppState.types';
import { ExportStatus } from '../../constants/export.constants';
import { getFoundTasksCount } from '../../redux/contentQuickFilters/contentQuickFiltersSelector';
import { isMobile } from '../../utils/browser.utils';
import { getIncludedTasksSelected } from '../../redux/tasksSelectedActions/tasksSelectedActionsSelector';

const useSortedByTextStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
            marginRight: 0,
        },
    },
    progressMessage: {
        marginRight: 8,
    },
    progressMessageMobile: {
        position: 'absolute',
        top: 6,
        left: 0,
        right: 0,
        marginRight: 0,
        textAlign: 'center',
    },
    icon: {
        color: theme.palette.neutral.main,
    },
    btnExport: {
        color: theme.palette.neutral.main,
    },
}));

export const mapStateToProps = (state: AppState) => ({
    status: getCurrentTaskExport(state),
    contentTasksTotal: getFoundTasksCount(state),
    isTasksSelected: getIncludedTasksSelected(state).length > 0,
});

const mapDispatchToProps = mapCreatorsToDispatchProps({
    exportTasks: tasksExportCreator.exportTasks,
});

export type TasksExportProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & { isTasksSelected: boolean };

export const TasksExport = (props: TasksExportProps): JSX.Element => {
    const { exportTasks, status, contentTasksTotal, isTasksSelected } = props;
    const classes = useSortedByTextStyles();
    const { t } = useTranslation();

    const handleTasksExport = () => {
        exportTasks();
    };

    const isExportInProgress = status?.progress === ExportStatus.IN_PROGRESS;

    return (
        <div className={classes.container}>
            {!!status?.total && isExportInProgress && (
                <span
                    data-testid="btn-export_tasks_progress"
                    className={classNames(classes.progressMessage, {
                        [classes.progressMessageMobile]: isMobile,
                    })}
                >
                    {t(`Exporting, please wait... ${status?.current}/${status?.total} tasks`)}
                </span>
            )}
            {isMobile ? (
                <IconButton
                    icon={<Icon name="export" mode="stroke" className={classes.icon} />}
                    onClick={handleTasksExport}
                    disabled={contentTasksTotal === 0}
                    inProgress={isExportInProgress}
                    data-testid="btn-mobile-export_tasks"
                />
            ) : (
                <TextButton
                    inProgress={isExportInProgress}
                    disabled={contentTasksTotal === 0 || isTasksSelected}
                    className={classes.btnExport}
                    onClick={handleTasksExport}
                    data-testid="btn-export_tasks"
                >
                    {t('TaskActions.Export.ExportAll')}
                </TextButton>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksExport);
