import { lighten, makeStyles } from 'worldapp-ui';

export const frequentlyUsedCardStyles = makeStyles(theme => ({
    root: {
        borderWidth: `1px !important`,
        borderStyle: 'solid !important',
        borderColor: `${theme.palette.neutral[12]} !important`,
        borderRadius: `${theme.spacing(0.5)}px !important`,
        height: theme.spacing(12.75),
        width: theme.spacing(17),
        color: theme.palette.neutral[75],
        padding: 0,
        backgroundColor: `${theme.palette.white.main} !important`,
        overflow: 'hidden',
        '&:hover': {
            borderColor: `${theme.palette.primary.main} !important`,
            boxShadow: `4px 4px 16px ${lighten(theme.palette.primary.main, 0.8)}`,
            '& $icon': {
                borderBottomColor: theme.palette.primary.main,
            },
        },
        '&$selected': {
            borderColor: `${theme.palette.primary.main} !important`,
            color: theme.palette.primary.main,
            '& $icon': {
                borderBottomColor: theme.palette.primary.main,
                background: lighten(theme.palette.primary.main, 0.84),
            },
        },
        '&[data-ismobile="true"]': {
            height: 40,
            width: '100%',
            '& .MuiToggleButton-label': {
                flexDirection: 'row',
            },
            '&$selected $icon': {
                border: `1px solid ${theme.palette.primary.main}`,
            },
        },
    },
    label: {
        height: '100%',
        flexDirection: 'column',
        '&[data-ismobile="true"]': {
            height: 40,
            flexDirection: 'row',
        },
    },
    icon: {
        flex: 2,
        background: theme.palette.neutral[6],
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.neutral[12],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '&[data-ismobile="true"]': {
            flex: '0 0 40px',
            height: 40,
            borderBottomColor: 'transparent',
            borderRadius: 4,
            border: `1px solid ${theme.palette.neutral[12]}`,
            background: lighten(theme.palette.primary.main, 0.84),
            marginLeft: -1,
        },
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: theme.spacing(4),
        padding: `0 ${theme.spacing()}px`,
        width: '100%',
        textAlign: 'left',
    },
    selected: {},
}));
