import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { CircularIndeterminate, StickToBottom, Link, Text } from 'worldapp-ui/shared';

import { LoginStatus } from '../../redux/login/login.types';
import { loginStyles } from './LoginPage.styles';
import Login from './Login';
import Portal from './Portal';
import ResetPassword from './ResetPassword';
import BackToLogin from './BackToLogin';
import { ChangePassword } from './ChangePassword';
import BackgroundImage from './BackgroundImage';
import { brandColorSelector } from '../../redux/brandInfo/brandInfoSelector';
import { getLoginStatus } from '../../redux/login/loginSelector';
import LoginToDifferentPortal from './LoginToDifferentPortal';
import { getLastBrandColor } from '../../utils/storage.utils';
import FailedSSO from './FailedSSOLogin';

interface ActiveStepProps {
    loginStatus: LoginStatus;
}

export const ActiveStep = ({ loginStatus }: ActiveStepProps): JSX.Element | null => {
    switch (loginStatus) {
        case LoginStatus.Portal:
            return <Portal />;
        case LoginStatus.Login:
            return <Login />;
        case LoginStatus.ResetPassword:
            return <ResetPassword />;
        case LoginStatus.ChangePassword:
            return <ChangePassword />;
        case LoginStatus.Loading:
        case LoginStatus.Shutdown:
            return <CircularIndeterminate size={80} />;
        case LoginStatus.BackToLogin:
            return <BackToLogin />;
        case LoginStatus.LoginToDifferentPortal:
            return <LoginToDifferentPortal />;
        case LoginStatus.FailedSSOLogin:
            return <FailedSSO />;
        default:
            return null;
    }
};

const LoginPage = (): JSX.Element => {
    const classes = loginStyles();

    const loginStatus = useSelector(getLoginStatus, shallowEqual);
    const color = useSelector(brandColorSelector, shallowEqual) || getLastBrandColor();

    // TODO: OP-2352 fix me, pls
    const ActiveStepWrapper = () => <ActiveStep loginStatus={loginStatus} />;
    const showBackground = ![
        LoginStatus.Loading,
        LoginStatus.Shutdown,
        LoginStatus.LoginToDifferentPortal,
    ].includes(loginStatus);

    return (
        <div className={classes.container}>
            {showBackground && (
                <div className={classes.background}>
                    <BackgroundImage color={color} />
                </div>
            )}
            <div className={classes.content}>
                <ActiveStepWrapper />
            </div>
            {showBackground && (
                <StickToBottom className={classes.bottom}>
                    <Link
                        href="https://www.form.com/"
                        target="_blank"
                        underline="none"
                        className={classes.formComLink}
                        data-testid="link_to_formcom"
                    >
                        <Text variant="r14r">FORM | The Digital Assistant for the Frontline</Text>
                    </Link>
                </StickToBottom>
            )}
        </div>
    );
};

export default LoginPage;
