import { createStyles, Theme, makeStyles } from 'worldapp-ui/shared';

export const loginStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
            flexDirection: 'column',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            width: theme.spacing(43),
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(2),
            boxSizing: 'border-box',
            flex: 1,
        },
        img: {
            objectFit: 'contain',
            height: 50,
            width: '100%',
        },
        text: {
            color: theme.palette.neutral[65],
            lineHeight: theme.typography.body1.lineHeight,
            display: 'block',
        },
        bottom: {
            paddingBottom: theme.spacing(3),
        },
        bottomImg: {
            maxWidth: theme.spacing(12),
        },
        fullHeight: {
            height: '100%',
        },
        grid: {
            padding: `0 ${theme.spacing()}px`,
            justifyContent: 'center',
            alignItems: 'center',
        },
        buttonLink: {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.body1.fontWeight,
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            padding: theme.spacing(4),
            boxSizing: 'border-box',
            height: '100%',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            zIndex: -1,
        },
        formComLink: {
            display: 'block',
            padding: theme.spacing(2),
        },
    }),
);
