import React, { Fragment } from 'react';
import { StickToBottom, DrawerMenu, Icon, IconButton } from 'worldapp-ui/shared';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { menuCreator } from '../../redux/menu/menu';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { ActivePanelType } from '../../constants/navigation.constants';
import CustomLinks from './CustomLinks/CustomLinks';
import NavigationTree from './NavigationTree/NavigationTree';
import UserInfo from './UserInfo/UserInfo';
import BottomPanel from './BottomPanel/BottomPanel';
import Languages from './Languages/Languages';
import useNavigationPanelStyles from './NavigationPanel.styles';
import { AppState } from '../../redux/AppState.types';
import {
    getActiveBottomPanel,
    getIsDrawerHoverOpen,
    getIsDrawerPermanentOpen,
    getIsInitialized,
} from '../../redux/menu/menuSelector';
import MenuPlaceholder from '../Placeholders/MenuPlaceholder';
import StartFormButton from '../StartForm/StartFormButton/StartFormButton';
import { useDrawerShadow } from '../../hooks/useDrawerShadow';
import { Divider } from './Divider/Divider';
import NavigationSubMenu from './NavigationTree/NavigationSubMenu';

const mapDispatchToProps = mapCreatorsToDispatchProps(menuCreator);

export const mapStateToProps = (state: AppState) => ({
    isDrawerHoverOpen: getIsDrawerHoverOpen(state),
    isDrawerPermanentOpen: getIsDrawerPermanentOpen(state),
    activeBottomPanel: getActiveBottomPanel(state),
    isInitialized: getIsInitialized(state),
});

export type NavigationPanelProps = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

const MENU_TOGGLE_CONTAINER_ID = 'menu-toggle_container';

export const NavigationPanel = ({
    isDrawerPermanentOpen,
    isDrawerHoverOpen,
    toggleDrawerHover,
    toggleDrawerPermanent,
    activeBottomPanel,
    onBottomMenuClick,
    isInitialized,
}: NavigationPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const backButtonLabel = t('NavigationPanel.BackButtonLabel');

    const isActivePanel = (panel: ActivePanelType) => activeBottomPanel === panel;
    const classes = useNavigationPanelStyles();

    const onClick = (panel: ActivePanelType) => () => {
        onBottomMenuClick(panel);
    };

    const onMenuClick = () => toggleDrawerPermanent();

    const isDrawerOpen = isDrawerPermanentOpen || isDrawerHoverOpen;

    const shadow = useDrawerShadow(isDrawerOpen);

    return (
        <DrawerMenu
            isDrawerPanelPermanentOpen={isDrawerPermanentOpen}
            isDrawerHoverOpen={isDrawerHoverOpen}
            toggleDrawerHover={toggleDrawerHover}
            onClose={onMenuClick}
            drawerProps={{
                drawerShadow: shadow,
                idToOmitToggle: MENU_TOGGLE_CONTAINER_ID,
                drawerClosedMobileScreenWidth: 56,
            }}
        >
            {isActivePanel(ActivePanelType.Main) && (
                <Fragment>
                    <div className={classes.headerWrapper} id={MENU_TOGGLE_CONTAINER_ID}>
                        <IconButton
                            icon={<Icon name="menu" size="l" mode="stroke" />}
                            aria-label="Open drawer"
                            onClick={onMenuClick}
                            data-testid="btn-open-drawer"
                        />
                        <UserInfo isDrawerOpen={isDrawerOpen} />
                    </div>
                    <Divider />
                    {isInitialized ? (
                        <Fragment>
                            <StartFormButton isCollapsed={!isDrawerOpen} />
                            <NavigationTree isCompactView={!isDrawerOpen} />
                            <StickToBottom>
                                <Divider />
                                <BottomPanel />
                            </StickToBottom>
                        </Fragment>
                    ) : (
                        <MenuPlaceholder />
                    )}
                </Fragment>
            )}
            {isActivePanel(ActivePanelType.Languages) && (
                <NavigationSubMenu
                    isDrawerOpen={isDrawerOpen}
                    onClickBack={onClick(ActivePanelType.Main)}
                    data-testid={'panel-menu-popup-lang'}
                    backButtonLabel={backButtonLabel}
                >
                    <Languages isDrawerOpen={isDrawerOpen} />
                </NavigationSubMenu>
            )}
            {isActivePanel(ActivePanelType.CustomLinks) && (
                <NavigationSubMenu
                    isDrawerOpen={isDrawerOpen}
                    onClickBack={onClick(ActivePanelType.Main)}
                    data-testid={'panel-menu-popup-links'}
                    backButtonLabel={backButtonLabel}
                >
                    <CustomLinks isDrawerOpen={isDrawerOpen} />
                </NavigationSubMenu>
            )}
        </DrawerMenu>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationPanel);
