import React from 'react';
import { ButtonLink, Grid, makeStyles, NumberRange, Typography } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';

type InputValue = number | null;
export type NumberFilterRange = {
    from: InputValue;
    to: InputValue;
};

type NumberFilterChangeHandler = (range: NumberFilterRange) => void;

export interface NumberFilterProps {
    selectedRange: NumberFilterRange;
    onChange: NumberFilterChangeHandler;
    label: string;
    onClear?: () => void;
}
const useStyles = makeStyles(theme => ({
    clearButton: {
        color: theme.palette.rubyRed.main,
    },
    label: {
        maxWidth: '80%',
    },
}));
export const NumberFilter = ({
    selectedRange,
    onChange,
    label,
    onClear,
}: NumberFilterProps): JSX.Element => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <>
            <Grid container direction="row" justify={'space-between'} wrap={'nowrap'} spacing={2}>
                <Grid item className={classes.label}>
                    <Typography variant={'h5'}>{label}</Typography>
                </Grid>
                {onClear && (
                    <Grid item>
                        <ButtonLink
                            onClick={onClear}
                            className={classes.clearButton}
                            data-testid="number_filter_clear_btn"
                            variant={'body1'}
                        >
                            {t('TaskFilters.StringFilter.ClearLabel')}
                        </ButtonLink>
                    </Grid>
                )}
            </Grid>
            <NumberRange
                value={selectedRange}
                onChange={onChange}
                fromPlaceholder={t('TaskFilters.NumberFilter.FromPlaceholder')}
                toPlaceholder={t('TaskFilters.NumberFilter.ToPlaceholder')}
            />
        </>
    );
};
