import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AccentButton,
    BlockingLoader,
    CircularContouredLoader,
    makeStyles,
    MUIPopover,
    PopoverProps,
    ReactInfiniteScroll,
    StrokeButton,
} from 'worldapp-ui';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        minWidth: 305,
        minHeight: 133,
        maxHeight: 384,
    },
    controlsWrapper: {
        display: 'flex',
        justifyContent: 'right',
        height: 60,
        gap: 8,
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.neutral[12]}`,
        paddingRight: 24,
        flexShrink: 0,
    },
    contentWrapper: (props: Props) => ({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: props.withoutPaddings ? 0 : '0 24px',
        margin: props.withoutPaddings ? 0 : '14px 0',
        overflow: 'auto',
        minHeight: 34,
        position: 'relative',
    }),
    stickyContentWrapper: {
        display: 'flex',
        flexShrink: 0,
    },
    loader: {
        height: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
}));

type Props = PopoverProps & {
    onConfirm: () => void;
    onCancel: () => void;
    stickyContent?: JSX.Element | undefined;
    confirmDisable?: boolean;
    isLoading?: boolean;
    infiniteScroll?:
        | undefined
        | {
              next: (resetItems?: boolean | undefined) => void;
              hasMore: boolean;
              dataLength: number;
          };
    confirmLabel?: string;
    withoutPaddings?: boolean;
};

export const PopoverDialog = (props: Props): JSX.Element => {
    const classes = useStyles(props);
    const {
        children,
        onCancel,
        onConfirm,
        stickyContent,
        confirmDisable,
        isLoading,
        infiniteScroll,
        confirmLabel,
        ...rest
    } = props;
    const { t } = useTranslation();
    const contentId = 'content';
    const content = (
        <>
            {infiniteScroll ? (
                <ReactInfiniteScroll
                    next={infiniteScroll.next}
                    hasMore={infiniteScroll.dataLength > 0 && infiniteScroll.hasMore}
                    dataLength={infiniteScroll.dataLength}
                    loader={
                        <div className={classes.loader}>
                            <CircularContouredLoader />
                        </div>
                    }
                    style={{ overflow: 'hidden' }}
                    scrollableTarget={contentId}
                    data-testid="popover_infinite-scroll"
                >
                    {children}
                </ReactInfiniteScroll>
            ) : (
                children
            )}
        </>
    );
    return (
        <MUIPopover
            classes={{ paper: classes.paper }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...rest}
        >
            {!!stickyContent && (
                <div className={classes.stickyContentWrapper} data-testid="popover_sticky-content">
                    {stickyContent}
                </div>
            )}
            <div className={classes.contentWrapper} id={contentId}>
                {isLoading ? <BlockingLoader data-testid="popover_loader" /> : content}
            </div>
            <div className={classes.controlsWrapper}>
                <StrokeButton data-testid="popover-dialog-cancel-btn" onClick={onCancel}>
                    {t('Common.CANCEL')}
                </StrokeButton>
                <AccentButton
                    data-testid="popover-dialog-ok-btn"
                    onClick={onConfirm}
                    disabled={confirmDisable}
                >
                    {confirmLabel || t('Common.OK')}
                </AccentButton>
            </div>
        </MUIPopover>
    );
};
