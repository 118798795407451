import cloneDeep from 'clone-deep';
import { useMemo } from 'react';
import { TaskDefinition, TaskDefinitionsById } from '../taskDefinitions/taskDefinitions.types';
import { SortColumnType, TasksSortState, defaultSortOrder } from '../tasksSort/tasksSort.types';
import { groupBy } from '../../utils/groupBy.utils';
import { getDueDate, getStatus } from '../content/contentSelector';
import {
    getMainTaskAction,
    getSecondaryTaskActionsTable,
    MainTaskAction,
    taskHasEditAction,
} from '../content/validateTaskActions';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import { Task } from '../content/content.types';
import { RowData, SingleTableState, TableColumn, TableData, TableRow } from './tasksTable.types';
import { ApiGW } from '../../types/DTO/api-gw';
import { getCustomPropertyColumnWidth, getIdFromNumber, setColumnsWidth } from './tasksTableUtils';
import {
    defaultColumns,
    defaultColumnsWithoutDueDate,
    typeColumn,
    UNASSIGNED_VALUE,
} from './taskTable.constants';
import { isFetchedTasks } from '../content/content.utils';
import { AppState } from '../AppState.types';
import { getTaskSort } from '../tasksSort/tasksSortSelector';
import { getTaskDetailViewTaskId, selectActiveTaskId } from '../activeCard/activeCardSelectors';
import { getVotingCreatedItemId } from '../voting/votingSelector';
import { getTaskQuickFilters } from '../contentQuickFilters/contentQuickFiltersSelector';
import { TaskQuickFilters } from '../contentQuickFilters/contentQiuckFilters.types';
import { getAppliedFiltersSet } from '../tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { getType } from '../tasksActions/taskActionsSelector';

const getDefaultColumns = (
    taskDefinitionId: number,
    taskDefinitionsById: TaskDefinitionsById,
): TableColumn[] => {
    if (taskDefinitionId !== ALL_TASKS_ID && !taskDefinitionsById[taskDefinitionId]) {
        return defaultColumns;
    }
    const taskDefinitions =
        taskDefinitionId === ALL_TASKS_ID
            ? Object.values(taskDefinitionsById)
            : [taskDefinitionsById[taskDefinitionId]];

    return taskDefinitions.some(td => td.requireDueDate)
        ? defaultColumns
        : defaultColumnsWithoutDueDate;
};

export const getActiveItemBasedColumns = (
    defaults: TableColumn[],
    activeItemId: number,
    customProperties: ApiGW.TaskDefinitionCustomPropertyDTO[] = [],
    managed: boolean,
    appliedFilters?: TaskQuickFilters | TaskQuickFilters | undefined,
): TableColumn[] => {
    const columns = cloneDeep(defaults);
    if (activeItemId === ALL_TASKS_ID) {
        columns.splice(1, 0, typeColumn);
        return columns;
    }

    const customColumns = customProperties
        .filter(({ usage, mappedFormItem }) => {
            if (mappedFormItem && !managed) return !usage || usage.myTasks;
            return (
                mappedFormItem &&
                (!usage ||
                    (appliedFilters?.teamTasks && usage.manager) ||
                    (appliedFilters?.myTasks && usage.myTasks))
            );
        })
        .map((customProperty: ApiGW.TaskDefinitionCustomPropertyDTO): TableColumn => {
            return {
                relativeWidth: getCustomPropertyColumnWidth(customProperty),
                accessor: getIdFromNumber(customProperty.id),
                Header: customProperty.title,
                sortColumn: { column: SortColumnType.Custom, property: customProperty.title },
            };
        });
    return [...columns, ...customColumns];
};

export const getColumns = (
    defaults: TableColumn[],
    taskDefinitionId: number,
    customProperties: ApiGW.TaskDefinitionCustomPropertyDTO[] = [],
    managed: boolean,
    tasksTableState?: SingleTableState | undefined,
    appliedFilters?: TaskQuickFilters | TaskQuickFilters | undefined,
): TableColumn[] => {
    const calculatedColumns = getActiveItemBasedColumns(
        defaults,
        taskDefinitionId,
        customProperties,
        managed,
        appliedFilters,
    );
    if (!tasksTableState || !tasksTableState.columns) return calculatedColumns;

    const sortedColumns: TableColumn[] = [];
    tasksTableState.columns.forEach(accessor => {
        const column = calculatedColumns.find(col => col.accessor === accessor);
        if (column) {
            sortedColumns.push(column);
        }
    });
    const newColumns = calculatedColumns.filter(
        col => !tasksTableState.columns.includes(col.accessor),
    );

    return [...sortedColumns, ...newColumns];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withSort = (tasksSort: TasksSortState, taskDefinitionId: number, table: TableData) => {
    const sortState = getTaskSort(tasksSort, taskDefinitionId);
    const setSearchMarkerIfNeeded = (column: TableColumn) => {
        const { sortColumn } = column;
        const isColumnSelected =
            !!sortColumn &&
            sortColumn.column === sortState.column &&
            (sortColumn.column !== SortColumnType.Custom ||
                sortState.column !== SortColumnType.Custom ||
                sortColumn.property === sortState.property);
        return isColumnSelected
            ? {
                  ...column,
                  sortOrder: sortState.order,
              }
            : column;
    };

    return {
        ...table,
        columns: table.columns.map(setSearchMarkerIfNeeded),
        sortDirection: sortState.order,
    };
};

export const tableDataForAllTasks = (
    columns: TableColumn[],
    tasks: Task[],
    taskDefinitionsById: TaskDefinitionsById,
    contactId: number,
    containerWidth: number,
    activeTaskId?: number | null,
    createdItemId?: number | null,
): TableData => {
    const data: TableRow[] = [];
    const rowData: RowData = {};

    tasks.forEach((task, id) => {
        const rowId = id;
        const td = taskDefinitionsById[task.taskDefinitionId];
        const mainAction = td ? getMainTaskAction({ task, taskDefinition: td, contactId }) : null;
        const secondaryActions = td
            ? getSecondaryTaskActionsTable({ task, taskDefinition: td, contactId })
            : [];

        const status = getStatus(task.statusId, td);

        const isFillOutFormAvailable = taskHasEditAction(
            mainAction as MainTaskAction,
            task,
            contactId,
        );

        rowData[rowId] = {
            actions: isFillOutFormAvailable
                ? ['FillOutForm', 'OpenTaskDetails', ...secondaryActions]
                : ['OpenTaskDetails', ...secondaryActions],
            mainAction: mainAction || null,
            taskId: task.id,
            taskDefinitionId: task.taskDefinitionId,
            isClosed: status?.isClosed || false,
            isActive: activeTaskId === task.id,
            isOverdue: getDueDate(task, status?.isClosed).dueDateStatus === 'overdue',
            shouldHiglhight: createdItemId === task.id,
            inProgress: false,
        };

        data.push({
            id: rowId,
            summary: task.summary,
            type: td ? td.title : '',
            status: status?.status,
            assignee: (task.assignee && task.assignee.name) || UNASSIGNED_VALUE,
            dueDate: task.dueDate ? new Date(task.dueDate).toLocaleDateString() : '',
            assignDate: task.assignDate ? new Date(task.assignDate).toLocaleDateString() : '',
            taskId: task.id,
        });
    });

    const { columnsWithWidth, rowWidth } = setColumnsWidth(containerWidth, columns);

    return {
        columns: columnsWithWidth,
        data,
        columnsById: groupBy(columns, 'accessor'),
        rowData,
        rowWidth,
    };
};

export const tableDataForTD = (
    columns: TableColumn[],
    tasks: Task[],
    activeTD: TaskDefinition,
    contactId: number,
    containerWidth: number,
    activeTaskId?: number | null,
    createdItemId?: number | null,
): TableData => {
    const data: TableRow[] = [];
    const rowData: RowData = {};

    tasks.forEach((task, id) => {
        const rowId = id;
        const mainAction = getMainTaskAction({ task, taskDefinition: activeTD, contactId });

        const secondaryActions = getSecondaryTaskActionsTable({
            task,
            taskDefinition: activeTD,
            contactId,
        });

        const status = getStatus(task.statusId, activeTD);

        const isFillOutFormAvailable = taskHasEditAction(mainAction, task, contactId);

        rowData[rowId] = {
            actions: isFillOutFormAvailable
                ? ['FillOutForm', 'OpenTaskDetails', ...secondaryActions]
                : ['OpenTaskDetails', ...secondaryActions],
            mainAction: mainAction || null,
            taskId: task.id,
            taskDefinitionId: task.taskDefinitionId,
            isClosed: status?.isClosed || false,
            isOverdue: getDueDate(task, status?.isClosed).dueDateStatus === 'overdue',
            isActive: activeTaskId === task.id,
            shouldHiglhight: createdItemId === task.id,
            inProgress: false,
        };

        const dataRow: TableRow = {
            id: rowId,
            summary: task.summary,
            status: status?.status,
            assignee: (task.assignee && task.assignee.name) || UNASSIGNED_VALUE,
            dueDate: task.dueDate ? new Date(task.dueDate).toLocaleDateString() : '',
            assignDate: task.assignDate ? new Date(task.assignDate).toLocaleDateString() : '',
            taskId: task.id,
        };

        task.customValues.forEach(value => {
            dataRow[getIdFromNumber(value.id)] = value.value;
        });

        data.push(dataRow);
    });

    const { columnsWithWidth, rowWidth } = setColumnsWidth(containerWidth, columns);

    return {
        columns: columnsWithWidth,
        data,
        columnsById: groupBy(columns, 'accessor'),
        rowData,
        rowWidth,
    };
};

export const taskTableSelector = (
    taskDefinitionId: number,
    tasks: Task[],
    taskDefinitionsById: TaskDefinitionsById,
    contactId: number,
    containerWidth: number,
    tasksTableState?: SingleTableState,
    activeTaskId?: number | null,
    createdItemId?: number | null,
    appliedFilters?: TaskQuickFilters | TaskQuickFilters,
): TableData => {
    const activeTaskDefinition = taskDefinitionsById[taskDefinitionId];
    const customProperties = activeTaskDefinition ? activeTaskDefinition.customProperties : [];
    const managed = activeTaskDefinition ? activeTaskDefinition.managed : false;
    const defaultCols = getDefaultColumns(taskDefinitionId, taskDefinitionsById);
    const columns = getColumns(
        defaultCols,
        taskDefinitionId,
        customProperties,
        managed || false,
        tasksTableState,
        appliedFilters,
    );

    if (taskDefinitionId === ALL_TASKS_ID) {
        return tableDataForAllTasks(
            columns,
            tasks,
            taskDefinitionsById,
            contactId,
            containerWidth,
            activeTaskId,
            createdItemId,
        );
    }

    if (taskDefinitionId !== null) {
        const activeTd = taskDefinitionsById[taskDefinitionId];
        if (activeTd)
            return tableDataForTD(
                columns,
                tasks,
                activeTd,
                contactId,
                containerWidth,
                activeTaskId,
                createdItemId,
            );
    }

    return {
        columns: [],
        columnsById: {},
        data: [],
        rowData: {},
        rowWidth: 0,
        sortDirection: defaultSortOrder,
    };
};

export const tableSelectorFactory =
    (isTasks = isFetchedTasks, withSortDecorator = withSort, taskTableGetter = taskTableSelector) =>
    (containerWidth: number) =>
    (state: AppState): any => {
        const { content, taskDefinitions, tasksSort, user, tasksTable } = state;
        const activeTaskId = selectActiveTaskId(state);
        const createdItemId = getVotingCreatedItemId(state);
        const appliedFilters =
            getAppliedFiltersSet(state)?.predefined || getTaskQuickFilters(state);
        if (isTasks(content)) {
            const tableData = useMemo(
                () =>
                    taskTableGetter(
                        content.itemId!,
                        content.items,
                        taskDefinitions.taskDefinitionsById,
                        user.contactId,
                        containerWidth,
                        tasksTable[content.itemId!],
                        activeTaskId,
                        createdItemId,
                        appliedFilters,
                    ),
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [
                    content.itemId,
                    content.items,
                    taskDefinitions.taskDefinitionsById,
                    tasksTable,
                    user.contactId,
                    containerWidth,
                    activeTaskId,
                    createdItemId,
                ],
            );

            const withSortData = useMemo(
                () => withSortDecorator(tasksSort, content.itemId!, tableData),
                [content.itemId, tableData, tasksSort],
            );
            const taskDetailViewId = getTaskDetailViewTaskId(state);
            const taskActionType = getType(state);

            return {
                ...withSortData,
                hasMore: content.hasMore,
                itemId: content.itemId,
                isTaskPopupOpened: taskDetailViewId !== null || taskActionType !== 'NullState',
            };
        }
        return {
            columns: [],
            columnsById: {},
            data: [],
            rowData: {},
            hasMore: false,
            itemId: ALL_TASKS_ID,
            rowWidth: 0,
            sortDirection: defaultSortOrder,
            isTaskPopupOpened: false,
        };
    };
export const tableSelector = tableSelectorFactory();
