import React from 'react';
import { Text } from 'worldapp-ui/shared';
import Highlighter from 'react-highlight-words';

const HighlightTag = (props: { children: React.ReactNode }) => {
    return <mark data-testid="context-search_highlighted-text">{props.children}</mark>;
};

const DashboardTitle = (props: {
    title?: string;
    searchQuery?: string;
    className?: string;
}): JSX.Element => {
    return (
        <Text
            variant="r14m"
            noWrap={true}
            className={props.className}
            data-testid="card-dashboard-title"
        >
            <Highlighter
                highlightTag={HighlightTag}
                searchWords={[props.searchQuery ?? '']}
                textToHighlight={props.title || ''}
            />
        </Text>
    );
};

export default DashboardTitle;
