import { initialState as userState } from '../redux/user/user';
import { initialState as loginState } from '../redux/login/login';
import { initialState as changePasswordState } from '../redux/changePassword/changePassword';
import { initialState as menuState } from '../redux/menu/menu';
import { initialState as taskDefinitionsState } from '../redux/taskDefinitions/taskDefinitions';
import { initialState as portalSettingsState } from '../redux/portalSettings/portalSettings';
import { initialState as formsState } from '../redux/forms/forms';
import { initialState as contentState } from '../redux/content/content';
import { initialState as contentStatusState } from '../redux/content/contentStatus';
import { initialState as taskActionsInitialState } from '../redux/tasksActions/tasksActions';
import { initialState as responsesActionsInitialState } from '../redux/responsesActions/responsesActions';
import { initialState as quickFiltersInitialState } from '../redux/contentQuickFilters/contentQuickFilters';
import { initialState as toastsState } from '../redux/toasts/toasts';
import { initialState as tasksSortInitialState } from '../redux/tasksSort/tasksSort';
import { initialState as activeCardInitialState } from '../redux/activeCard/activeCard';
import { initialState as defaultNavigationInitialState } from '../redux/defaultNavigation/defaultNavigation';
import { initialState as tasksConfigurableFiltersState } from '../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import { initialState as tasksTableInitialState } from '../redux/tasksTable/tasksTable';
import { initialState as taskDefinitionAssigneesInitialState } from '../redux/taskDefinitionAssignees/taskDefinitionAssignees';
import { initialState as localizationInitialState } from '../redux/localization/localization';
import { initialState as brandInfoInitialState } from '../redux/brandInfo/brandInfo';
import { initialState as featuresInitialState } from '../redux/features/features';
import { initialState as frequentlyUsedInitialState } from '../redux/frequentlyUsed/frequentlyUsed';
import { initialState as startFormInitialState } from '../redux/startForm/startForm';
import { initialState as votingInitialState } from '../redux/voting/voting';
import { initialState as tasksSelectedActionsInitialState } from '../redux/tasksSelectedActions/tasksSelectedActions';
import { initialState as tasksExportInitialState } from '../redux/tasksExport/tasksExport';
import { initialState as calendarInitialState } from '../redux/calendar/calendar';
import { AppState } from '../redux/AppState.types';

const getInitialState = (): AppState => {
    return {
        user: userState,
        login: loginState,
        changePassword: changePasswordState,
        menu: menuState,
        taskDefinitions: taskDefinitionsState,
        portalSettings: portalSettingsState,
        forms: formsState,
        content: contentState,
        contentStatus: contentStatusState,
        quickFilters: quickFiltersInitialState,
        tasksActions: taskActionsInitialState,
        responsesActions: responsesActionsInitialState,
        toasts: toastsState,
        tasksSort: tasksSortInitialState,
        tasksConfigurableFilters: tasksConfigurableFiltersState,
        activeCard: activeCardInitialState,
        defaultNavigation: defaultNavigationInitialState,
        tasksTable: tasksTableInitialState,
        taskDefinitionAssignees: taskDefinitionAssigneesInitialState,
        localization: localizationInitialState,
        brandInfo: brandInfoInitialState,
        features: featuresInitialState,
        frequentlyUsed: frequentlyUsedInitialState,
        startForm: startFormInitialState,
        voting: votingInitialState,
        tasksSelectedActions: tasksSelectedActionsInitialState,
        tasksExport: tasksExportInitialState,
        calendar: calendarInitialState,
    };
};

export default getInitialState;
