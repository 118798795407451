import moment from 'moment';
import { produce } from 'immer';
import {
    ADD_FREQUENTLY_USED_ITEM,
    FrequentlyUsedAction,
    FrequentlyUsedItem,
    FrequentlyUsedState,
} from './frequentlyUsed.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import {
    HydrateStateFromStorageAction,
    HYDRATE_STATE_FROM_STORAGE,
} from '../initialLoader/initialLoader.types';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const initialState: FrequentlyUsedState = {
    dates: [],
    itemsByDates: {},
};

export const incrementLastRecord = (
    draft: FrequentlyUsedState,
    lastDate: string,
    type: FrequentlyUsedItem,
    id: number,
): void => {
    const count = draft.itemsByDates[lastDate][type][id];
    draft.itemsByDates[lastDate][type][id] = count ? count + 1 : 1;
};

export const pushNewRecord = (
    draft: FrequentlyUsedState,
    today: string,
    type: FrequentlyUsedItem,
    id: number,
): void => {
    if (draft.dates.length === 7) {
        const deletedDate = draft.dates.shift();
        delete draft.itemsByDates[deletedDate!];
    }
    draft.dates.push(today);
    draft.itemsByDates[today] = {
        taskDefinitions: {},
        forms: {},
    };
    draft.itemsByDates[today][type][id] = 1;
};

export const reducer = (
    state: FrequentlyUsedState = initialState,
    action: FrequentlyUsedAction | HydrateStateFromStorageAction,
): FrequentlyUsedState =>
    produce(state, (draft: FrequentlyUsedState) => {
        switch (action.type) {
            case HYDRATE_STATE_FROM_STORAGE: {
                if (!action.state.frequentlyUsed) return;
                const { dates, itemsByDates } = action.state.frequentlyUsed as FrequentlyUsedState;
                if (dates) {
                    draft.dates = dates;
                }
                if (itemsByDates) {
                    draft.itemsByDates = itemsByDates;
                }
                return;
            }
            case ADD_FREQUENTLY_USED_ITEM: {
                const lastDate = draft.dates[draft.dates.length - 1];
                const today = moment().format(DATE_FORMAT);
                const lastModificationWasToday = today === lastDate;

                if (lastModificationWasToday) {
                    incrementLastRecord(draft, lastDate, action.itemType, action.id);
                } else {
                    pushNewRecord(draft, today, action.itemType, action.id);
                }
                return;
            }
            default:
                return state;
        }
    });

export const frequentlyUsedCreator = {
    addItem:
        (itemType: FrequentlyUsedItem, id: number): ThunkActionVoid =>
        dispatch => {
            dispatch({
                type: ADD_FREQUENTLY_USED_ITEM,
                itemType,
                id,
            });
        },
};
