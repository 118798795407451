import { connect } from 'react-redux';
import React from 'react';
import { createStyles, makeStyles, Theme } from 'worldapp-ui/shared';
import { getFiltersMetaIds } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import ConfigurableFilter from '../ConfigurableFilter';
import PredefinedFiltersList from '../PredefinedFiltersList';
import { AppState } from '../../../redux/AppState.types';
import { isMobileDevice } from '../../../utils/browser.utils';

export interface FiltersListContentProps {
    customFilterIds: Array<string | number>;
}

export const useFiltersListContentStyles = makeStyles((theme: Theme) =>
    createStyles({
        generalFiltersWrapper: {
            marginLeft: -theme.spacing(0.5),
            display: 'flex',
            flex: '1 1 auto',
            flexWrap: 'wrap',
            gap: 8,
            overflowY: 'hidden',
            overflowX: 'auto',
            boxSizing: 'border-box',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
            '&[data-ismobile="true"]': {
                marginLeft: -24,
                marginRight: -24,
                display: 'flex',
                padding: '0 24px',
                flexWrap: 'nowrap',
            },
        },
    }),
);

/* istanbul ignore next */
const mapStateToProps = (state: AppState) => {
    return {
        customFilterIds: getFiltersMetaIds(state),
    };
};

export const FiltersListContentComponent = (props: FiltersListContentProps): JSX.Element => {
    const { customFilterIds } = props;
    const classes = useFiltersListContentStyles();
    const customFilters = customFilterIds.map(id => <ConfigurableFilter filterId={id} key={id} />);
    return (
        <>
            <div className={classes.generalFiltersWrapper} data-ismobile={isMobileDevice}>
                <PredefinedFiltersList />
            </div>
            {customFilters}
        </>
    );
};

export default connect(mapStateToProps)(FiltersListContentComponent);
