import { Language } from '../../localization/localizationDefaultData';

export const CHANGE_LANGUAGE = 'Localization/CHANGE_LANGUAGE';

export interface ChangeLanguageAction {
    type: typeof CHANGE_LANGUAGE;
    language: Language;
}

export type LocalizationAction = ChangeLanguageAction;

export interface LocalizationState {
    language: Language;
}
