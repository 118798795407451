import { makeStyles, Theme } from 'worldapp-ui/shared';
import { CARDS_HEIGHTS, GRID_MOBILE_PADDING, GRID_PADDING } from '../ui/cards/cardsSizes';

export const placeholderStyles = makeStyles((theme: Theme) => ({
    card: {
        width: theme.spacing(42),
        background: theme.palette.common.white,
        borderRadius: 8,
        border: `0.5px solid ${theme.palette.neutral[12]}`,
        boxSizing: 'border-box',
    },
    taskCard: {
        height: CARDS_HEIGHTS.tasks,
    },
    formCard: {
        height: CARDS_HEIGHTS.forms,
    },
    dashboardCard: {
        height: CARDS_HEIGHTS.dashboards,
    },
    cardMain: {
        paddingTop: 12,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(),
    },
    cardBottom: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    cardBottomDashboards: {
        flexDirection: 'column',
        alignItems: 'start',
    },
    cardMiddleBlock: {
        marginTop: 12,
        marginBottom: 12,
    },

    menuWrapper: {
        margin: 0,
        width: '100%',
        padding: theme.spacing(2.5),
    },
    headerWrapper: {
        paddingTop: theme.spacing(3),
        paddingLeft: GRID_PADDING,
        paddingRight: GRID_PADDING,
        '&[data-ismobile="true"]': {
            paddingLeft: GRID_MOBILE_PADDING,
            paddingRight: GRID_MOBILE_PADDING,
        },
    },
    headerSecondRow: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    tableContainer: {
        width: `calc(100% - ${GRID_PADDING * 2}px)`,
        height: theme.spacing(24),
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        background: theme.palette.common.white,
        marginTop: theme.spacing(8),
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 8,
        marginLeft: GRID_PADDING,
        overflow: 'hidden',
        '&[data-ismobile="true"]': {
            width: `calc(100% - ${GRID_MOBILE_PADDING * 2}px)`,
            marginLeft: GRID_MOBILE_PADDING,
        },
    },
    tableColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
}));

interface BlockStylesProps {
    width?: number;
    height?: number;
}

export const blockStyles = makeStyles((theme: Theme) => ({
    block: {
        borderRadius: theme.spacing(0.5),
        width: (props: BlockStylesProps) => (props.width ? props.width : '100%'),
        height: (props: BlockStylesProps) => (props.height ? props.height : theme.spacing(2)),
        animationDuration: '1s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: `$placeHolder`,
        animationTimingFunction: 'linear',
        backgroundColor: '#f6f7f8',
        background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
        backgroundSize: '800px 104px',
        position: 'relative',
    },
    darkBlock: {
        backgroundColor: '#e3e3e3',
        background: 'linear-gradient(to right, #e3e3e3 8%, #cacaca 18%, #e3e3e3 33%)',
        backgroundSize: '800px 104px',
    },
    '@keyframes placeHolder': {
        '0%': {
            backgroundPosition: '-468px 0',
        },
        '100%': {
            backgroundPosition: '468px 0',
        },
    },
}));
