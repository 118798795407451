import React from 'react';
import classNames from 'classnames';
import { Text } from 'worldapp-ui/shared';
import { taskDetailViewStyles } from './TaskDetailView.styles';

interface TDViewValueRowProps {
    title: string;
    value: string;
    dataTestid?: string;
    hasError?: boolean;
}

export const TDViewDescriptionValue = (props: TDViewValueRowProps): JSX.Element => {
    const { title, value, dataTestid, hasError } = props;
    const classes = taskDetailViewStyles();

    return (
        <div className={classes.tdDescriptionValue}>
            <div>
                <Text variant="r12m" className={classes.tdDescriptionTitle}>
                    {title}
                </Text>
            </div>
            <div>
                <Text
                    variant="r12r"
                    data-testid={`${dataTestid}_value`}
                    className={classNames(classes.tdFieldValue, hasError && classes.error)}
                >
                    {value}
                </Text>
            </div>
        </div>
    );
};
