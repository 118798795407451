import { Category } from '../content/category.types';

export const GO_TO_VOTING = 'gotoURL/GO_TO_VOTING';
export const CLEAR_VOTING_STATE = 'voting/CLEAR_VOTING_STATE';

export interface GoToVotingAction {
    type: typeof GO_TO_VOTING;
    category: Category;
    cardId?: string | number;
    itemId?: number;
}

interface ClearVotingStateAction {
    type: typeof CLEAR_VOTING_STATE;
}

export type VotingAction = ClearVotingStateAction;

export interface VotingState {
    isBackUrl: boolean;
    submit: boolean;
    createdItemId: number | null;
    startForm: boolean;
    resultHasBeenSaved: boolean;
}
