import { createSelector } from 'reselect';
import { AppState } from '../AppState.types';
import { LoginOptions, LoginState, LoginStatus, PortalId, PortalVersion } from './login.types';

/* istanbul ignore next */
export const getLoginState = ({ login }: AppState): LoginState => login;

/* istanbul ignore next */
export const getLoginStatus = ({ login }: AppState): LoginStatus => login.loginStatus;

/* istanbul ignore next */
export const getPortalId = ({ login }: AppState): PortalId => login.portalId;

/* istanbul ignore next */
export const getUsername = ({ login }: AppState): string => login.username;

export const getPortalVersion = ({ login }: AppState): PortalVersion | undefined =>
    login.portalVersion;

export const getRedirectUrl = ({ login }: AppState): string | null => login.redirectUrl ?? null;

export const getNewResponseParams = ({ login }: AppState): Record<string, string[]> | null =>
    login.newResponseParams ?? null;

export const getSSOLogoutLink = ({ login }: AppState): string | null => login.SSOLogoutLink ?? null;

export const getLoginOptions = ({ login }: AppState): LoginOptions | null =>
    login.loginOptions ?? null;

export const isLoggedIn = createSelector(
    getLoginStatus,
    loginStatus => loginStatus === LoginStatus.LoggedIn,
);

export const getFailedSSOLogin = ({ login }: AppState): string | null =>
    login.failedSSOLogin ?? null;

export default {
    getLoginState,
    getLoginStatus,
    getPortalId,
    getUsername,
    getRedirectUrl,
    getNewResponseParams,
    getSSOLogoutLink,
    getLoginOptions,
    isLoggedIn,
    getPortalVersion,
};
