import React from 'react';
import { Task } from '../../../redux/content/content.types';
import { TaskDefinition } from '../../../redux/taskDefinitions/taskDefinitions.types';
import { TDViewDescriptionValue } from './TDViewDescriptionValue';
import { getIdFromString } from '../../../utils/helpers.utils';

interface TDCustomValuesProps {
    customValues: Task['customValues'];
    customProperties: TaskDefinition['customProperties'];
}

export const TDViewCustomValues = (props: TDCustomValuesProps): JSX.Element => {
    const { customValues, customProperties } = props;

    return (
        <>
            {customValues.map(value => {
                const property = customProperties
                    .filter(({ usage }) => !usage || usage.taskDetail)
                    .find(p => p.id === value.id);

                if (!property) {
                    return null;
                }

                const id = `task-dtview_cv_${getIdFromString(property.title)}`;

                return (
                    <TDViewDescriptionValue
                        key={`${value.id}_${value.value}`}
                        title={`${property.title}:`}
                        value={value.value}
                        dataTestid={id}
                    />
                );
            })}
        </>
    );
};
