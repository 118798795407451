import React, { useRef, FunctionComponent } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { TableRowProps } from 'react-table';
import classNames from 'classnames';
import { CircularContouredLoader, WithStyles } from 'worldapp-ui/shared';

import useOnclickOutside from 'react-cool-onclickoutside';
import { useSelector } from 'react-redux';
import { RowDataItem } from '../../../redux/tasksTable/tasksTable.types';
import { MainTaskAction, TaskActionTable } from '../../../redux/content/validateTaskActions';

import { TableActions, TableActionsMobile } from './TableActions';
import { isMobile } from '../../../utils/browser.utils';
import MoreButton from '../../ui/buttons/MoreButton';
import { GRID_MOBILE_PADDING, GRID_PADDING } from '../../ui/cards/cardsSizes';
import { getTasksInActiveJobsMap } from '../../../redux/tasksActions/taskActionsSelector';

export interface TaskTableRowProps extends TableRowProps {
    classes: WithStyles['classes'];
    distanceToRight: number;
    rowDataItem: RowDataItem;
    onActionClick: (action: TaskActionTable, taskId: number, taskDefinitionId: number) => void;
    onRowClick: (action: MainTaskAction, taskId: number, taskDefinitionId: number) => void;
    onRowOutsideClick: () => void;
    isTaskPopupOpened: boolean;
}

const TaskTableRow: FunctionComponent<TaskTableRowProps> = props => {
    const {
        children,
        classes,
        distanceToRight,
        rowDataItem,
        onActionClick,
        onRowClick,
        onRowOutsideClick,
        isTaskPopupOpened,
        ...rowProps
    } = props;
    const { taskId } = rowDataItem;
    const rowRef = useRef(null);

    const tasksInActiveJobsMap = useSelector(getTasksInActiveJobsMap);
    rowDataItem.inProgress = tasksInActiveJobsMap[taskId];

    const ref = useOnclickOutside(
        () => {
            if (rowDataItem.isActive) {
                onRowOutsideClick();
            }
        },
        {
            disabled: !rowDataItem.isActive || isTaskPopupOpened,
        },
    );
    const { isIntersecting } = useIntersectionObserver(rowRef);

    const handleRowClick = () => {
        if (rowDataItem.mainAction)
            onRowClick(rowDataItem.mainAction, taskId, rowDataItem.taskDefinitionId);
    };
    const distanceToRightMobileCorrection = GRID_PADDING - GRID_MOBILE_PADDING + 4;
    return (
        // Row
        <div
            {...rowProps}
            className={classNames(classes.tableBodyRow, {
                [classes.tableBodyClosedRow]: rowDataItem.isClosed,
                [classes.tableBodyRegularRow]: !rowDataItem.isClosed,
                [classes.tableBodyRowActive]: rowDataItem.isActive,
                [classes.tableBodyOverdueRow]: rowDataItem.isOverdue && !rowDataItem.isClosed,
                [classes.tableBodyRowHiglighted]: rowDataItem.shouldHiglhight,
                [classes.tableBodyRowActionActive]: rowDataItem.mainAction,
                [classes.tableBodyRowActionInProgress]: rowDataItem.inProgress,
            })}
            onClick={handleRowClick}
            data-testid={`tasks-table_row_${taskId}`}
            ref={rowRef}
            data-ismobile={isMobile}
        >
            <div ref={ref} className={classes.tableBodyRowInner}>
                {children}
                {!isMobile && isIntersecting && (
                    <TableActions
                        classes={classes}
                        onActionClick={onActionClick}
                        right={distanceToRight}
                        rowDataItem={rowDataItem}
                    />
                )}
                {isMobile && (
                    <div
                        className={classes.tableMoreActionButton}
                        style={{ right: distanceToRight - distanceToRightMobileCorrection }}
                    >
                        <MoreButton
                            menuActions={TableActionsMobile({ rowDataItem, onActionClick })}
                            key="more"
                            isTableMenuActions
                        />
                    </div>
                )}
            </div>
            {rowDataItem.inProgress ? (
                <div
                    className={classes.tableBodyRowLoader}
                    style={{
                        width: `calc(100% - ${distanceToRight}px)`,
                    }}
                    data-testid="task-table_row_loader"
                >
                    <CircularContouredLoader />
                </div>
            ) : null}
        </div>
    );
};

export default TaskTableRow;
