import { createSelector } from 'reselect';
import {
    UpdatePasswordErrors,
    UpdatePasswordErrorType,
    UpdatePasswordState,
} from './changePassword.types';
import { AppState } from '../AppState.types';

export const getChangePasswordState = ({ changePassword }: AppState): UpdatePasswordState =>
    changePassword;

export const getPassword = ({ changePassword }: AppState): string => changePassword.password;

export const getConfirmPassword = ({ changePassword }: AppState): string =>
    changePassword.confirmPassword;

export const getErrors = ({ changePassword }: AppState): UpdatePasswordErrors =>
    changePassword.errors;

export const getIsChangePasswordButtonDisabled = createSelector(
    getPassword,
    getConfirmPassword,
    (password, confirmPassword) => !password.length || !confirmPassword.length,
);

export const getIsChangePasswordAllowed = createSelector(
    getErrors,
    errors => !errors[UpdatePasswordErrorType.CONFIRM_PASSWORD].message,
);

export default {
    getChangePasswordState,
    getPassword,
    getConfirmPassword,
    getErrors,
};
