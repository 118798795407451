import React from 'react';
import { Text } from 'worldapp-ui/shared';
import Highlighter from 'react-highlight-words';
import classNames from 'classnames';
import { cardStyles } from './card.styles';

const HighlightTag = (props: { children: React.ReactNode }) => {
    return <mark data-testid="context-search_highlighted-text">{props.children}</mark>;
};

const Title = (props: {
    title?: string;
    searchQuery?: string;
    className?: string;
}): JSX.Element => {
    const classes = cardStyles({});

    return (
        <div className={classes.titleWrapper}>
            <Text
                variant="r14r"
                className={classNames(classes.title, props.className)}
                data-testid="card-title"
            >
                <Highlighter
                    highlightTag={HighlightTag}
                    searchWords={[props.searchQuery ?? '']}
                    textToHighlight={props.title || ''}
                />
            </Text>
        </div>
    );
};

export default Title;
