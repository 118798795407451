import { PreparedURL } from 'worldapp-fe-utils';
import { Converter } from '../location.types';

export const respondentIdConverter: Converter = {
    stateToURL: () => undefined,
    URLToState: (url: PreparedURL) => {
        const respondentId = url.query.respondentId?.[0];
        return { respondentId };
    },
};
