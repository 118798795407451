import { TableColumn } from './tasksTable.types';
import { SortColumnType } from '../tasksSort/tasksSort.types';

export const defaultTableWidth = {
    date: 1.1,
    assignDate: 1.3,
    assignee: 1.5,
    status: 1.5,
    summary: 2,
    taskType: 2,
    customNumber: 1,
    customDate: 1,
    customGeneral: 1.5,
};

export const UNASSIGNED_VALUE = 'Unassigned';

export const DEFAULT_COLUMN_WIDTH = 110;

const summaryColumn = {
    accessor: 'summary',
    Header: 'TasksTable.Columns.Summary',
    relativeWidth: defaultTableWidth.summary,
    sortColumn: { column: SortColumnType.Summary },
} as const;

const statusColumn = {
    accessor: 'status',
    Header: 'TasksTable.Columns.Status',
    relativeWidth: defaultTableWidth.status,
    sortColumn: { column: SortColumnType.Status },
} as const;

const assigneeColumn = {
    accessor: 'assignee',
    Header: 'TasksTable.Columns.Assignee',
    relativeWidth: defaultTableWidth.assignee,
    sortColumn: { column: SortColumnType.Assignee },
} as const;

const dueDateColumn = {
    accessor: 'dueDate',
    Header: 'TasksTable.Columns.DueDate',
    relativeWidth: defaultTableWidth.date,
    sortColumn: { column: SortColumnType.DueDate },
} as const;

const assignDateColumn = {
    accessor: 'assignDate',
    Header: 'TasksTable.Columns.AssignDate',
    relativeWidth: defaultTableWidth.assignDate,
    sortColumn: { column: SortColumnType.AssignDate },
} as const;

export const typeColumn = {
    accessor: 'type',
    Header: 'TasksTable.Columns.TaskDefinition',
    relativeWidth: defaultTableWidth.taskType,
    sortColumn: undefined,
} as const;

export const defaultColumns: TableColumn[] = [
    summaryColumn,
    statusColumn,
    assigneeColumn,
    dueDateColumn,
    assignDateColumn,
];

export const defaultColumnsWithoutDueDate: TableColumn[] = [
    summaryColumn,
    statusColumn,
    assigneeColumn,
    assignDateColumn,
];
