import { RefObject, useRef } from 'react';

interface UseChangeFocus {
    focusTarget: {
        inputRef: RefObject<HTMLInputElement>;
    };
    handleChangeFocus: () => void;
}

export function useChangeFocus(): UseChangeFocus {
    const inputPasswordRef = useRef<HTMLInputElement>(null);

    const handleChangeFocus = () => {
        if (inputPasswordRef.current) {
            inputPasswordRef.current.focus();
        }
    };

    const focusTarget = {
        inputRef: inputPasswordRef,
    };

    return { focusTarget, handleChangeFocus };
}
