import { stateToURL } from 'worldapp-fe-utils';
import { AppState } from '../redux/AppState.types';
import { portal2Converters } from './converters/portalVersionConverter';
import { locationSelector } from './locationState';
import { appendParamsToRelativeURL } from '../utils/window.location.utils';

export const getPortal2URL = (state: AppState): string => {
    const locationState = locationSelector(state);
    const { query, path, hash = '' } = stateToURL(portal2Converters)(locationState);
    const pathWithQuery = appendParamsToRelativeURL(
        `/portal${locationState.portalId !== null ? '/' : ''}${path}`,
        query,
    );
    return hash ? `${pathWithQuery}#${hash}` : pathWithQuery;
};
