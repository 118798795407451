import React, { useState } from 'react';
import { Draggable, DraggableStateSnapshot } from 'react-beautiful-dnd_fork';
import { Theme, Typography, useTheme } from 'worldapp-ui/shared';
import { Drag as DragIcon, Sort as SortIcon } from 'worldapp-ui/shared/icons';

import { useTranslation } from 'react-i18next';
import { TableViewColumn } from './table.types';
import { SortOrderOptions } from '../../../redux/tasksSort/tasksSort.types';
import { TABLE_COLUMN_ID_CUSTOM_PROPERTY_PREFIX } from '../../../redux/tasksTable/tasksTableUtils';

export interface TableHeaderColumnProps {
    column: TableViewColumn;
    headerProps: any;
    className: string;
    handleClick: (id: string) => void;
    index: number;
    sortDirection?: SortOrderOptions;
}

export const getBoxShadow = (snapshot: DraggableStateSnapshot, theme: Theme): string =>
    snapshot.isDragging
        ? `2px 0px 4px ${theme.palette.neutral[6]}, -2px 0px 4px ${theme.palette.neutral[6]}`
        : 'none';

export const getBorder = (snapshot: DraggableStateSnapshot, theme: Theme): string =>
    snapshot.isDragging ? `1px solid ${theme.palette.neutral[12]}` : 'none';

export const TableHeaderColumn = (props: TableHeaderColumnProps): JSX.Element => {
    const { column, headerProps, className, handleClick, index, sortDirection } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    const [isCellHovered, setIsCellHovered] = useState(false);
    const selectionColumn = column.id === 'selection';
    const columnName =
        column.id.startsWith(TABLE_COLUMN_ID_CUSTOM_PROPERTY_PREFIX) || selectionColumn
            ? column.render('Header')
            : t(column.render('Header') as string);

    return (
        <Draggable draggableId={column.id} index={index} isDragDisabled={selectionColumn}>
            {(provided, snapshot) => {
                const draggableProps = {
                    ...provided.draggableProps,
                    style: {
                        ...provided.draggableProps.style,
                        ...headerProps.style,
                        backgroundColor: 'inherit',
                        border: getBorder(snapshot, theme),
                        boxShadow: getBoxShadow(snapshot, theme),
                    },
                };

                const iconDragVisibility =
                    isCellHovered || snapshot.isDragging ? 'visible' : 'hidden';
                const iconSortVisibility =
                    column.sortColumn && (column.sortOrder || isCellHovered || snapshot.isDragging)
                        ? 'visible'
                        : 'hidden';
                const iconSortColor = column.sortColumn && column.sortOrder ? 'active' : 'default';
                return (
                    <div
                        {...headerProps}
                        {...draggableProps}
                        {...provided.dragHandleProps}
                        className={className}
                        onClick={handleClick(column.id)}
                        data-testid={`tasks-table_header_row_${
                            selectionColumn ? 'selectionColumn' : column.Header
                        }`}
                        ref={provided.innerRef}
                        id={column.id}
                    >
                        <div
                            onMouseEnter={() => setIsCellHovered(true)}
                            onMouseLeave={() => setIsCellHovered(false)}
                            data-testid={`tasks-table_header_cell_${
                                selectionColumn ? 'selectionColumn' : column.Header
                            }`}
                        >
                            {!selectionColumn && (
                                <DragIcon
                                    data-testid={`tasks-table_header_dragicon`}
                                    style={{ visibility: iconDragVisibility }}
                                />
                            )}
                            <Typography variant="subtitle1" noWrap={true} className={'typography'}>
                                {columnName}
                            </Typography>
                            {!selectionColumn && (
                                <SortIcon
                                    data-testid={`tasks-table_header_sorticon`}
                                    className={`order_${sortDirection} color_${iconSortColor}`}
                                    style={{ visibility: iconSortVisibility }}
                                />
                            )}
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );
};
