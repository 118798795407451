import { ToastButton, ToastParams } from 'worldapp-ui';
import { ToastType } from 'worldapp-ui/shared/toasts/ToastsController';

export const CREATE_TOAST = 'Toasts/CREATE_TOAST';
export const REMOVE_TOAST = 'Toasts/REMOVE_TOAST';
export const CLEAR_INITIALIZATION_TOASTS = 'Toasts/CLEAR_INITIALIZATION_TOASTS';

// TOAST BUTTON ACTIONS:
export const TOAST_TASK_UPDATE_FILTERS = 'TOAST_TASK_UPDATE_FILTERS';
export const TOAST_RESPONSE_UPDATE_FILTERS = 'TOAST_RESPONSE_UPDATE_FILTERS';
export const TOAST_RETRY_TASK_EXPORT = 'TOAST_RETRY_TASK_EXPORT';

export interface ToastInfo {
    message: string;
    toastType: ToastType;
    autoHide?: boolean;
    button?: ToastButton;
}
interface CreateToastAction extends ToastInfo {
    type: typeof CREATE_TOAST;
    key: string;
}
interface RemoveToastAction {
    type: typeof REMOVE_TOAST;
    key: string;
}

interface ClearInitializationToastsAction {
    type: typeof CLEAR_INITIALIZATION_TOASTS;
}

export type ToastsAction = CreateToastAction | RemoveToastAction | ClearInitializationToastsAction;

export interface ToastsState {
    toasts: ToastParams[];
    initializationToasts: Omit<ToastParams, 'key'>[];
}
