import { updateFavicons } from 'worldapp-fe-utils';
import {
    BRAND_INFO_FETCHED,
    CLEAR_BRAND_INFO,
    BrandInfoAction,
    BrandInfoState,
} from './brandInfo.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import { CHANGE_PORTAL_VERSION } from '../login/login.types';
import { setLastBrandColor } from '../../utils/storage.utils';

export const initialState: BrandInfoState = null;
const PORTAL = '/portal';

export default function reducer(state = initialState, action: BrandInfoAction): BrandInfoState {
    switch (action.type) {
        case BRAND_INFO_FETCHED:
            return {
                ...state,
                ...action.brandInfo,
            };
        case CLEAR_BRAND_INFO:
            return initialState;
        default:
            return state;
    }
}

export const brandInfoCreator = {
    fetchBrandInfo(): ThunkActionVoid {
        return async (dispatch, getState, { api, loginSelector }) => {
            const state = getState();
            if (state.brandInfo != null) {
                return;
            }
            const portalId = loginSelector.getPortalId(state);
            const brandInfo = await api.getBrandInfo(portalId);
            setLastBrandColor(brandInfo.color);
            updateFavicons(
                brandInfo.favicons as unknown as {
                    [index: string]: string;
                },
            );
            dispatch({
                brandInfo,
                type: BRAND_INFO_FETCHED,
            });
        };
    },
    checkVersion:
        (): ThunkActionVoid =>
        (dispatch, getState, { goToURLCreator }) => {
            const { brandInfo } = getState();
            const portalUrl = brandInfo?.portalUrl;
            if (portalUrl && new URL(portalUrl).pathname.startsWith(PORTAL)) {
                dispatch({
                    type: CHANGE_PORTAL_VERSION,
                    version: 'old',
                });
                return dispatch(goToURLCreator.gotoOldPortal());
            }
        },
};
