import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import LogoutButton from '../../LoginPanel/LogoutButton';
import { AppState } from '../../../redux/AppState.types';
import { getUserEmail, getUserLogin } from '../../../redux/user/userSelector';
import useUserInfoStyles from './UserInfo.styles';

export const mapStateToProps = (state: AppState) => ({
    login: getUserLogin(state),
    email: getUserEmail(state),
});

interface UserInfoProps {
    login: string;
    email: string;
    isDrawerOpen: boolean;
}

export const UserInfo = ({ login, email, isDrawerOpen }: UserInfoProps): JSX.Element => {
    const classes = useUserInfoStyles();

    return (
        <div className={classes.root}>
            {isDrawerOpen && (
                <Fragment>
                    <div className={classes.content}>
                        <div className={classes.textContainer}>
                            <div className={classes.nameText} data-testid="txt-user-login">
                                {login}
                            </div>
                            <div className={classes.mailText} data-testid="txt-user-email">
                                {email}
                            </div>
                        </div>
                    </div>
                    <LogoutButton />
                </Fragment>
            )}
        </div>
    );
};

export default connect(mapStateToProps)(UserInfo);
