import {
    ResponseAction,
    ResponsesActionsState,
    ResponsesActionsAction,
    NullState,
    OPEN_ACTION_POPUP,
    CLOSE_ACTION_POPUP,
    CHANGE_IS_LOADING,
} from './responsesActions.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import { isFetchedResponses } from '../content/content.utils';
import i18n from '../../localization/i18n';
import { getImpersonateMode } from '../portalSettings/portalSettingsSelector';
import { getResultHasBeenSaved, getSubmit } from '../voting/votingSelector';
import { getContentHasActiveItem } from '../content/contentSelector';
import { getResponseMessageAfterVoting } from '../../utils/voting.utils';
import { TOAST_RESPONSE_UPDATE_FILTERS } from '../toasts/toasts.types';

const DELETE_RESPONSE_SUCCESS = 'ResponseActions.DeleteResponse.Success';
const DELETE_RESPONSE_FAILED = 'ResponseActions.DeleteResponse.Fail';

const nullState: NullState = {
    type: 'NullState',
    isLoading: false,
};

export const initialState = nullState;

export default function reducer(
    state: ResponsesActionsState = initialState,
    action: ResponsesActionsAction,
): ResponsesActionsState {
    switch (action.type) {
        case OPEN_ACTION_POPUP: {
            if (state.type !== nullState.type) {
                return state;
            }

            return {
                type: action.action,
                formId: action.formId,
                responseId: action.responseId,
                isLoading: false,
            };
        }
        case CLOSE_ACTION_POPUP: {
            return initialState;
        }

        case CHANGE_IS_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }

        default:
            return state;
    }
}

export const responsesActionsCreator = {
    openActionPopup: (
        action: ResponseAction,
        formId: number,
        responseId: number,
    ): ThunkActionVoid => {
        return (dispatch, getState, { toastsCreator, votingCreator }) => {
            const state = getState();
            const impersonateMode = getImpersonateMode(state);

            if (impersonateMode) {
                dispatch(toastsCreator.createImpersonateMessageToast());
                return;
            }

            dispatch(votingCreator.clearVotingState());

            if (isFetchedResponses(state.content)) {
                return dispatch({
                    action,
                    formId,
                    responseId,
                    type: OPEN_ACTION_POPUP,
                });
            }
        };
    },

    closeActionPopup: (): ThunkActionVoid => {
        return dispatch =>
            dispatch({
                type: CLOSE_ACTION_POPUP,
            });
    },

    deleteResponse: (): ThunkActionVoid => {
        return async (
            dispatch,
            getState,
            { api, contentCreator, toastsCreator, responsesActionsCreator: formsActions },
        ) => {
            const state = getState().responsesActions;
            if (state.type === 'DeleteResponse') {
                try {
                    dispatch(formsActions.changeIsLoading(true));
                    const { responseId } = state;
                    await api.deleteResponse(responseId);
                    dispatch(toastsCreator.createToast(i18n.t(DELETE_RESPONSE_SUCCESS)));
                    return dispatch(contentCreator.refresh(false));
                } catch (error) {
                    dispatch(toastsCreator.createToast(i18n.t(DELETE_RESPONSE_FAILED), 'error'));
                } finally {
                    await dispatch(formsActions.closeActionPopup());
                    dispatch(formsActions.changeIsLoading(false));
                }
            }
        };
    },

    changeIsLoading:
        (isLoading: boolean): ThunkActionVoid =>
        dispatch =>
            dispatch({ isLoading, type: CHANGE_IS_LOADING }),

    handleResponsesAfterVoting:
        (): ThunkActionVoid =>
        (dispatch, getState, { toastsCreator, contentSelector }) => {
            const state = getState();
            if (!contentSelector.isContentCategoryForms(state)) return;

            const submit = getSubmit(state);
            const contentHasActiveItem = getContentHasActiveItem(state);

            const resultHasBeenSaved = getResultHasBeenSaved(state);

            const message = getResponseMessageAfterVoting(
                submit,
                contentHasActiveItem,
                resultHasBeenSaved,
            );

            if (message.text) {
                dispatch(
                    toastsCreator.createToast(
                        i18n.t(message.text),
                        'info',
                        true,
                        message.showRecent
                            ? {
                                  text: i18n.t('Voting.ShowRecent'),
                                  action: TOAST_RESPONSE_UPDATE_FILTERS,
                              }
                            : undefined,
                    ),
                );
            }
        },
};
