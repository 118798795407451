import React from 'react';
import { useSelector } from 'react-redux';
import { TaskDetailViewDialog } from './TaskDetailView/TaskDetailViewDialog';
import ChangeAssignee from '../Actions/ChangeAssignee/ChangeAssignee';
import UnAssign from '../Actions/UnAssign';
import ChangeDueDate from '../Actions/ChangeDueDate';
import ChangeStatus from '../Actions/ChangeStatus/ChangeStatus';
import TasksBulkActionsMenu from '../Actions/TasksBulkActionsMenu/TasksBulkActionsMenu';
import { getIncludedTasksSelected } from '../../redux/tasksSelectedActions/tasksSelectedActionsSelector';

interface TasksActionsComponentsProps {
    contentDiv: HTMLDivElement | null;
}

const TasksActionsComponents = (props: TasksActionsComponentsProps): JSX.Element => {
    const selectedTasks = useSelector(getIncludedTasksSelected);
    return (
        <>
            <TaskDetailViewDialog />
            <ChangeAssignee />
            <UnAssign />
            <ChangeDueDate />
            <ChangeStatus />
            {selectedTasks.length >= 1 && <TasksBulkActionsMenu contentDiv={props.contentDiv} />}
        </>
    );
};

export default TasksActionsComponents;
