import produce from 'immer';
import {
    QuickFilters,
    QuickFiltersState,
} from '../redux/contentQuickFilters/contentQiuckFilters.types';
import { LocationState } from './location.types';
import { SortBy, SortColumnType, TasksSortState } from '../redux/tasksSort/tasksSort.types';
import { getDefaultSelectedItemForCategory } from '../redux/menu/menu';
import {
    getCurrentResponseQuickFilter,
    getTaskQuickFilters,
} from '../redux/contentQuickFilters/contentQuickFiltersSelector';
import { getCurrentTaskSort, getCurrentTaskSortOrder } from '../redux/tasksSort/tasksSortSelector';
import { getFreshContent } from '../redux/content/content.utils';
import { AppState } from '../redux/AppState.types';
import { Content } from '../redux/content/content.types';
import { getTasksView } from '../redux/menu/menuSelector';
import { getPortalId } from '../redux/login/loginSelector';
import { LoginStatus } from '../redux/login/login.types';
import { VotingState } from '../redux/voting/voting.types';

export const loginStatusesAllowedInURL = [
    LoginStatus.LogoutPending,
    LoginStatus.ChangePassword,
    LoginStatus.ResetPassword,
];

const selectQuickFilters = (state: AppState): QuickFilters | undefined => {
    if (!state.content) {
        return;
    }
    const activeCategory = state.content.category;
    if (activeCategory === 'tasks') {
        return { type: 'task', filters: getTaskQuickFilters(state) };
    }
    if (activeCategory === 'forms') {
        return { type: 'response', filters: getCurrentResponseQuickFilter(state) };
    }
};

const selectTaskSortingParameter = (state: AppState): SortBy => {
    const sorting = getCurrentTaskSort(state);
    return sorting.column === SortColumnType.Custom
        ? { column: SortColumnType.Custom, property: sorting.property }
        : { column: sorting.column };
};

const getTasksSort = ({
    activeItemId,
    activeCategory,
    taskSortingDirection,
    taskSortingParameter,
}: LocationState): TasksSortState => {
    if (activeCategory !== 'tasks' || !activeItemId) return {};
    return {
        [activeItemId]: {
            ...taskSortingParameter,
            order: taskSortingDirection,
        },
    };
};

function getContent(
    { activeCategory, activeItemId, searchQuery, offset }: LocationState,
    state: AppState,
): Content | undefined {
    if (!activeCategory) {
        return;
    }
    const itemId = activeItemId ?? getDefaultSelectedItemForCategory(activeCategory, state);
    if (itemId) {
        return {
            ...getFreshContent(activeCategory, itemId),
            searchQuery: searchQuery ?? '',
            offset,
        };
    }
}

function getQuickFilters({
    quickFilters,
    activeItemId,
    activeCategory,
}: LocationState): QuickFiltersState | undefined {
    if (!(quickFilters && activeItemId)) {
        return;
    }
    if (quickFilters.type === 'task' && activeCategory === 'tasks') {
        return {
            tasks: { [activeItemId]: quickFilters.filters },
            forms: {},
        };
    }
    if (quickFilters.type === 'response' && activeCategory === 'forms') {
        return {
            forms: { [activeItemId]: quickFilters.filters },
            tasks: {},
        };
    }
}

function getTasksConfigurableAppliedFilters({
    quickFilters,
    activeItemId,
    activeCategory,
}: LocationState) {
    if (!(quickFilters && activeItemId)) {
        return;
    }
    if (quickFilters.type === 'task' && activeCategory === 'tasks') {
        return {
            [activeItemId]: {
                predefined: { ...quickFilters.filters },
                custom: {},
            },
        };
    }
}

function getActiveCard({ activeCategory, activeCardId, taskDetailView }: LocationState) {
    if (activeCategory) {
        const cardId = activeCategory === 'dashboards' ? activeCardId : Number(activeCardId);
        if (cardId) {
            return {
                category: activeCategory,
                cardId,
                taskDetailView,
            };
        }
    }
}

function getVoting({
    isBackUrl,
    respondentId,
    startForm,
    submit,
    activeCardId,
    activeCategory,
    resultHasBeenSaved,
}: LocationState) {
    const votingState: VotingState = {
        isBackUrl: false,
        submit: false,
        createdItemId: null,
        startForm: startForm ?? false,
        resultHasBeenSaved: resultHasBeenSaved ?? false,
    };

    if (isBackUrl) {
        votingState.isBackUrl = isBackUrl;
        votingState.submit = submit;

        if (activeCategory === 'forms' && respondentId) {
            votingState.createdItemId = Number(respondentId);
        } else if (activeCategory === 'tasks') {
            votingState.createdItemId = activeCardId ? Number(activeCardId) : null;
        }
    }

    return votingState;
}

const filterAllowedLoginStatus = (status?: LoginStatus) =>
    loginStatusesAllowedInURL.find(s => s === status);

export const locationSelector = (state: AppState): LocationState => ({
    activeCategory: state.content?.category ?? null,
    activeItemId: state.content?.itemId ?? null,
    portalId: getPortalId(state),
    tasksView: state.content?.category === 'tasks' ? getTasksView(state) : null,
    taskDetailView: !!state.activeCard?.taskDetailView,
    taskSortingParameter: selectTaskSortingParameter(state),
    taskSortingDirection: getCurrentTaskSortOrder(state),
    quickFilters: selectQuickFilters(state) ?? null,
    activeCardId: state.activeCard?.cardId?.toString() ?? null,
    isDefaultNavigation: state.defaultNavigation,
    searchQuery: state.content?.searchQuery ?? null,
    submit: false,
    offset: state.content?.offset ?? 0,
    redirectURL: state.login.redirectUrl ?? null,
    loginStatus: state.login.loginStatus,
    newResponseParams: state.login.newResponseParams ?? null,
    startForm: !!state.startForm.selectedItem,
});

export const stateHydrator = (locationState: LocationState, state: AppState): AppState => {
    const {
        activeItemId,
        activeCategory,
        portalId,
        tasksView,
        loginStatus,
        redirectURL,
        newResponseParams,
        failedSSOLogin,
    } = locationState;
    return produce(state, (draft: AppState) => {
        draft.menu.navigation.activeItemId = activeItemId ?? draft.menu.navigation.activeItemId;
        draft.menu.navigation.activeCategory =
            activeCategory ?? draft.menu.navigation.activeCategory;
        draft.content = getContent(locationState, state) ?? draft.content;
        draft.login.portalId = portalId ?? draft.login.portalId;
        draft.menu.tasksView = tasksView ?? draft.menu.tasksView;
        draft.tasksSort = { ...draft.tasksSort, ...getTasksSort(locationState) };
        draft.quickFilters = getQuickFilters(locationState) ?? draft.quickFilters;
        draft.tasksConfigurableFilters.appliedFiltersSets =
            getTasksConfigurableAppliedFilters(locationState) ??
            draft.tasksConfigurableFilters.appliedFiltersSets;
        draft.activeCard = getActiveCard(locationState) ?? draft.activeCard;
        draft.defaultNavigation = locationState.isDefaultNavigation ?? draft.defaultNavigation;
        draft.login.loginStatus = filterAllowedLoginStatus(loginStatus) ?? draft.login.loginStatus;
        draft.login.redirectUrl = redirectURL ?? draft.login.redirectUrl;
        draft.login.newResponseParams = newResponseParams ?? draft.login.newResponseParams;
        draft.login.failedSSOLogin = failedSSOLogin;
        if (failedSSOLogin) draft.login.loginStatus = LoginStatus.FailedSSOLogin;
        draft.toasts.initializationToasts = [];
        draft.voting = getVoting(locationState);
    });
};
