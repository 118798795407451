import { Converter } from '../location.types';

export const portalIdConverter: Converter = {
    URLToState: url => ({ portalId: Number(url.path?.split('/')[2]) || null }),
    stateToURL: state => {
        const portal = (state && state.portalId && state.portalId.toString()) || '';
        return {
            path: portal,
        };
    },
};
