import { createStyles, makeStyles, Theme } from 'worldapp-ui/shared';

const useUserInfoStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            alignItems: 'center',
            display: 'flex',
        },
        textContainer: {
            width: theme.spacing(25),
            display: 'flex',
            flexDirection: 'column' as const,
            [theme.breakpoints.down('md')]: {
                width: theme.spacing(21),
            },
        },
        mailText: {
            color: theme.palette.neutral[65],
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            marginTop: theme.spacing(0.25),
        },
        nameText: {
            color: theme.palette.neutral.main,
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
            lineHeight: theme.typography.h5.lineHeight,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        root: {
            color: theme.palette.neutral[50],
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            position: 'relative' as const,
            width: '100%',
            zIndex: 10,
            height: theme.spacing(8),
        },
    }),
);

export default useUserInfoStyles;
