import React, { FC } from 'react';
import { createStyles, makeStyles, PickersDate } from 'worldapp-ui/shared';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Moment } from 'moment';
import { tasksActionsCreator } from '../../redux/tasksActions/tasksActions';
import { getLanguage } from '../../redux/localization/localizationSelector';
import { isContentCategoryTasks } from '../../redux/content/contentSelector';
import { getTasksActionsState } from '../../redux/tasksActions/taskActionsSelector';
import { PopoverDialog } from '../Common/PopoverDialog';
import Calendar from '../Common/Calendar';

/* istanbul ignore next */
const styles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
        },
    }),
);

interface CalendarContentProps {
    date: PickersDate | null;
    onChange: (date: PickersDate) => void;
    language: string;
}

export const CalendarContent = ({
    date,
    onChange,
    language,
}: CalendarContentProps): JSX.Element => (
    <div className={styles().container}>
        <Calendar value={date} onChange={onChange} language={language} />
    </div>
);

interface ChangeDueDatePopoverProps {
    open: boolean;
    anchorElement: HTMLElement;
    closePopover: () => void;
}

export const ChangeDueDatePopover: FC<ChangeDueDatePopoverProps> = ({
    anchorElement,
    open,
    closePopover,
}) => {
    const dispatch = useDispatch();

    const isTasks = useSelector(isContentCategoryTasks);
    const language = useSelector(getLanguage);
    const tasksActions = useSelector(getTasksActionsState, shallowEqual);

    const onClose = () => {
        dispatch(tasksActionsCreator.closeActionPopup());
        closePopover();
    };

    const onConfirm = () => {
        dispatch(tasksActionsCreator.setDueDate(true));
        closePopover();
    };

    const changeCurrentDueDate = (date: Moment) =>
        dispatch(tasksActionsCreator.changeCurrentDueDate(date));

    if (isTasks && tasksActions.type === 'ChangeDueDate') {
        // TODO
        const dueDate = tasksActions.task!.dueDate || null;

        return (
            <PopoverDialog
                anchorEl={anchorElement}
                open={open}
                onClose={onClose}
                onCancel={onClose}
                onConfirm={onConfirm}
                withoutPaddings
            >
                <div>
                    <CalendarContent
                        date={dueDate}
                        onChange={changeCurrentDueDate}
                        language={language}
                    />
                </div>
            </PopoverDialog>
        );
    }

    return null;
};
