import request from 'superagent';
import { ABORT, CallbackResolution, ErrorType } from './Http';
import { ApiMethodInfo, TOKEN } from '../datalayer/ApiMethodInfo';
import { AppDispatch, ThunkActionPromise } from '../redux';
import {
    STATUS_BAD_GATEWAY,
    STATUS_INTERNAL_SERVER_ERROR,
    STATUS_NO_CONNECTION,
    STATUS_NOT_IMPLEMENTED,
    STATUS_UNAUTHORIZED_ERROR,
} from '../constants/statusCodes.constants';
import i18n from '../localization/i18n';

const METHODS_WITH_SPECIFIC_TOASTS = [
    'assignTask',
    'setStatus',
    'changeDueDate',
    'unassignTask',
    'fetchCMViewColumns',
    'getContactFilterFields',
];

export type ErrorCallback = (
    error: ErrorType,
    apiInfo: ApiMethodInfo,
) => Promise<CallbackResolution>;

let errorCallback: ErrorCallback = () => Promise.reject();

export const getErrorCallback = (): ErrorCallback => {
    return errorCallback;
};

export const getErrorMessage = (error: ErrorType): string => {
    if (error.status === STATUS_NO_CONNECTION) {
        return i18n.t('Errors.NoConnection');
    }

    return error.message.toLocaleString();
};

const logConnectionError = (error: request.ResponseError) => {
    // eslint-disable-next-line no-console
    console.error('connection error', JSON.parse(JSON.stringify(error)));
};

// Use API inside creator with caution, and do not use API calls that require authentication, as
// this may lead to infinite loops of API calls and execution of error callbacks
export const httpCallbacksCreator = {
    genericError: (
        error: ErrorType,
        apiInfo: ApiMethodInfo,
    ): ThunkActionPromise<CallbackResolution> => {
        return (
            dispatch,
            getState,
            { toastsCreator, loginCreator, loginSelector },
        ): Promise<CallbackResolution> => {
            if (
                error.status === STATUS_UNAUTHORIZED_ERROR &&
                apiInfo.accessType === TOKEN &&
                loginSelector.isLoggedIn(getState())
            ) {
                dispatch(loginCreator.logout(true));
                return Promise.resolve(ABORT);
            }
            if (
                [
                    STATUS_INTERNAL_SERVER_ERROR,
                    STATUS_NOT_IMPLEMENTED,
                    STATUS_BAD_GATEWAY,
                    STATUS_NO_CONNECTION,
                ].includes(error.status) &&
                !METHODS_WITH_SPECIFIC_TOASTS.includes(apiInfo.name)
            ) {
                logConnectionError(error);
                dispatch(toastsCreator.createToast(getErrorMessage(error), 'error'));
                return Promise.resolve(ABORT);
            }
            return Promise.resolve(ABORT);
        };
    },
};

export const initCallbacks = (dispatch: AppDispatch): void => {
    errorCallback = (error: ErrorType, apiInfo: ApiMethodInfo) =>
        dispatch(httpCallbacksCreator.genericError(error, apiInfo));
};
