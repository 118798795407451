export const CHANGE_SEARCH_VALUE = 'StartForm/CHANGE_SEARCH_VALUE';
export const CHANGE_SELECTED_ITEM = 'StartForm/CHANGE_SELECTED_ITEM';
export const CLEAR_START_FORM = 'StartForm/CLEAR_START_FORM';

export type StartFormItem = 'form' | 'td';

interface ChangeSearchValueAction {
    type: typeof CHANGE_SEARCH_VALUE;
    searchValue: string;
}

interface ChangeSelectedItemAction {
    type: typeof CHANGE_SELECTED_ITEM;
    selectedItem: string;
}

interface ClearStartFormAction {
    type: typeof CLEAR_START_FORM;
}

export type StartFormAction =
    | ChangeSearchValueAction
    | ChangeSelectedItemAction
    | ClearStartFormAction;

export interface StartFormState {
    searchValue: string;
    selectedItem: string;
}
