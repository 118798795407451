export const CONTENT_CATEGORY_TASKS = 'tasks';
export const CONTENT_CATEGORY_FORMS = 'forms';
export const CONTENT_CATEGORY_DASHBOARDS = 'dashboards';

export const categories = [
    CONTENT_CATEGORY_TASKS,
    CONTENT_CATEGORY_FORMS,
    CONTENT_CATEGORY_DASHBOARDS,
] as const;

export type Category = typeof categories[number];
