import { LoginStatus, LoginState } from '../redux/login/login.types';

export const PORTAL_ERROR_401 = 'Login.PortalError401';
export const PORTAL_ERROR_403 = 'Login.PortalError403';
export const UNKNOWN_ERROR = 'Login.UnknownError';
export const LOGIN_ERROR_401 = 'Login.LoginError401';
export const AUTH_FAILED = 'Login.AuthFailed';

const getLoginError = (errCode: number | undefined, loginStatus: LoginStatus): string => {
    if (loginStatus === LoginStatus.Portal || loginStatus === LoginStatus.Loading) {
        switch (errCode) {
            case 401:
            case 404:
                return PORTAL_ERROR_401;
            case 403:
                return PORTAL_ERROR_403;
            default:
                return UNKNOWN_ERROR;
        }
    }
    if (loginStatus === LoginStatus.Login) {
        switch (errCode) {
            case 400:
            case 401:
            case 404:
                return LOGIN_ERROR_401;
            case 403:
                return PORTAL_ERROR_403;
            default:
                return UNKNOWN_ERROR;
        }
    }
    if (loginStatus === LoginStatus.ResetPassword) {
        return AUTH_FAILED;
    }
    return '';
};

const checkIsNextStepEnabled = (state: LoginState): boolean => {
    const { portalId, username, password, loginStatus, errorMessage, isLoading } = state;
    switch (loginStatus) {
        case LoginStatus.Portal:
            return !!portalId && !isLoading && !errorMessage;
        case LoginStatus.Login:
            return !!username && !!password && !isLoading && !errorMessage;
        case LoginStatus.ResetPassword:
            return !!username && !!portalId && !isLoading && !errorMessage;
        default:
            return false;
    }
};

export { getLoginError, checkIsNextStepEnabled };
