import React from 'react';
import { connect } from 'react-redux';

import { Accordion } from 'worldapp-ui';

import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { menuCreator } from '../../../redux/menu/menu';
import { isMobile, isIE } from '../../../utils/browser.utils';

import { getCategoryByIndex, getNavigationItems } from '../../../utils/navigation.utils';
import navTreeStyles, { NavTreeStylesProps, stylesAccordion } from './NavigationTree.styles';
import { AppState } from '../../../redux/AppState.types';

import { getNavigation } from '../../../redux/menu/menuSelector';
import {
    getNavigationItemsData,
    getTabs,
} from '../../../redux/portalSettings/portalSettingsSelector';
import {
    NavigationTreeSummaryItem,
    NavigationTreeSummaryItemProps,
} from './NavigationTreeSummaryItem';

export const mapStateToProps = (state: AppState) => ({
    navigationItemsData: getNavigationItemsData(state),
    navigation: getNavigation(state),
    tabs: getTabs(state),
});

const mapDispatchToProps = mapCreatorsToDispatchProps({ ...menuCreator });

export type NavigationTreeProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & { isCompactView: boolean };

export const NavigationTree = ({
    isCompactView,
    navigationItemsData,
    navigation,
    setActiveCategory,
    setActiveItem,
    tabs,
}: NavigationTreeProps): JSX.Element => {
    const toggleExpanded = (index: number) => {
        const category = getCategoryByIndex(index, navigationItemsData);
        setActiveCategory(category);
    };

    const onItemSelectHandler = (itemId: number | null) => {
        setActiveItem(itemId);
    };

    const navigationItems = navigationItemsData.map(item =>
        getNavigationItems(item, tabs, onItemSelectHandler, navigation),
    );

    const selectedIndex = navigationItems.findIndex(item => item.id === navigation.activeCategory);

    const expandedIndex = navigation.activeCategoryExpanded ? selectedIndex : null;

    const navTreeStylesProps: NavTreeStylesProps = {
        isCompactView,
        isIE,
    };

    const classes = navTreeStyles(navTreeStylesProps);
    const classesAccordion = stylesAccordion({
        isCompactView,
        isMobile,
    });

    const summaryComponent = (props: NavigationTreeSummaryItemProps): JSX.Element => (
        <NavigationTreeSummaryItem
            isHeaderActive={props.isHeaderActive}
            isCompactView={props.isCompactView}
            title={props.title}
            IconComponent={props.IconComponent}
        />
    );
    return (
        <div className={classes.container}>
            <Accordion
                items={navigationItems}
                selectedIndex={selectedIndex}
                expandedIndex={expandedIndex}
                toggleExpandedIndex={toggleExpanded}
                isCompactView={isCompactView}
                isMobile={isMobile}
                isColored={false}
                classes={classesAccordion}
                summaryComponent={summaryComponent}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTree);
