export const SET_PASSWORD = 'changePassword/SET_PASSWORD';
export const SET_CONFIRM_PASSWORD = 'changePassword/SET_CONFIRM_PASSWORD';
export const IS_LOADING = 'changePassword/IS_LOADING';
export const ADD_ERROR = 'changePassword/ADD_ERROR';
export const RESET_STATE = 'changePassword/RESET_STATE';
export const SHOW_PASSWORDS = 'changePassword/SHOW_PASSWORDS';
export const DISPLAY_ERROR_MESSAGE = 'changePassword/DISPLAY_ERROR_MESSAGE';

export const ERROR_DIFFERENT_PASSWORDS = 'Login.DIFFERENT_PASSWORDS';
export const ERROR_CONNECTION = 'Login.CONNECTION_ERROR';

export enum UpdatePasswordErrorType {
    PASSWORD = 'password',
    CONFIRM_PASSWORD = 'confirmPassword',
    GENERAL = 'general',
}

export type PasswordError = string;
export type ConfirmPasswordError = typeof ERROR_DIFFERENT_PASSWORDS;
export type GeneralError = typeof ERROR_CONNECTION;

interface UpdatePasswordErrorState<T extends string> {
    message: T | null;
    display: boolean;
}

export interface UpdatePasswordErrors {
    [UpdatePasswordErrorType.PASSWORD]: UpdatePasswordErrorState<PasswordError>;
    [UpdatePasswordErrorType.CONFIRM_PASSWORD]: UpdatePasswordErrorState<ConfirmPasswordError>;
    [UpdatePasswordErrorType.GENERAL]: UpdatePasswordErrorState<GeneralError>;
}

export interface UpdatePasswordState {
    password: string;
    confirmPassword: string;
    isLoading: boolean;
    showPasswords: boolean;
    errors: UpdatePasswordErrors;
}

interface SetPasswordAction {
    type: typeof SET_PASSWORD;
    password: string;
}

interface SetConfirmPasswordAction {
    type: typeof SET_CONFIRM_PASSWORD;
    confirmPassword: string;
}

interface LoadingAction {
    type: typeof IS_LOADING;
    isLoading: boolean;
}

interface AddError {
    type: typeof ADD_ERROR;
    errorType: UpdatePasswordErrorType;
    errorMessage: PasswordError | ConfirmPasswordError | GeneralError;
}

interface ResetState {
    type: typeof RESET_STATE;
}

interface ShowPasswords {
    type: typeof SHOW_PASSWORDS;
    showPasswords: boolean;
}

interface DisplayErrorMessage {
    type: typeof DISPLAY_ERROR_MESSAGE;
    errorType: UpdatePasswordErrorType;
    display: boolean;
}

export type UpdatePasswordAction =
    | SetPasswordAction
    | SetConfirmPasswordAction
    | LoadingAction
    | AddError
    | ResetState
    | ShowPasswords
    | DisplayErrorMessage;
