import React from 'react';
import { Box, Button, Grid, Typography } from 'worldapp-ui/shared';

import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { loginCreator } from '../../redux/login/login';
import { LoginStatus } from '../../redux/login/login.types';
import { BUTTON_HEIGHT } from '../../constants/ui.constants';

export const BackToLogin = (): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleBackToLogin = () => {
        dispatch(loginCreator.changeLoginStatus(LoginStatus.Login));
    };

    return (
        <Grid container={true} spacing={2} direction="column" wrap="nowrap">
            <Grid item={true}>
                <Box mb={4}>
                    <Typography align="center" data-testid="login_email-sent">
                        {t('Login.EMAIL_SENT')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item={true}>
                <Button
                    fullWidth={true}
                    onClick={handleBackToLogin}
                    data-testid="button_back-to-login"
                    style={{ height: BUTTON_HEIGHT }}
                >
                    {t('Login.BACK_TO_LOGIN')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default BackToLogin;
