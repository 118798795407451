import React from 'react';
import cs from 'classnames';
import { Tooltip, IconButton, makeStyles } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { ExpandArrows } from 'worldapp-ui/shared/icons';

export interface ExpandButtonProps {
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
}

export const useEditButtonStyles = makeStyles(theme => ({
    root: {
        width: 44,
        height: 42,
        position: 'absolute',
        right: -16,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 4,
        cursor: 'pointer',
    },
    icon: {
        color: theme.palette.neutral[75],
    },
}));

const ExpandButton = (props: ExpandButtonProps): JSX.Element => {
    const { onClick, disabled } = props;
    const classes = useEditButtonStyles();
    const { t } = useTranslation();
    const button = (
        <div
            className={classes.root}
            onClick={onClick}
            data-testid="btn_icon-open-view-task-details"
        >
            <IconButton
                icon={<ExpandArrows className={cs({ [classes.icon]: !disabled })} />}
                disabled={disabled}
                aria-label={t('TaskActions.OpenTaskDetails.Label')}
            />
        </div>
    );
    return disabled ? (
        button
    ) : (
        <Tooltip title={t('TaskActions.OpenTaskDetails.Label') as string} placement="bottom-start">
            {button}
        </Tooltip>
    );
};

export default ExpandButton;
