import React, { FunctionComponent, useState, useCallback } from 'react';
import { PlaceholderBlock } from './PlaceholderBlock';
import { placeholderStyles } from './Placeholder.styles';
import { isMobile } from '../../utils/browser.utils';

const COLUMN_WIDTH = 148;

export const TableColumn: FunctionComponent = () => {
    const classes = placeholderStyles();
    return (
        <div className={classes.tableColumn} data-testid="placeholder-table-column">
            <PlaceholderBlock height={32} width={COLUMN_WIDTH} dark={true} />
            <PlaceholderBlock height={24} width={COLUMN_WIDTH} />
            <PlaceholderBlock height={24} width={COLUMN_WIDTH} />
            <PlaceholderBlock height={24} width={COLUMN_WIDTH} />
        </div>
    );
};

const TablePlaceholder: FunctionComponent = () => {
    const [containerWidth, setContainerWidth] = useState(0);
    const ref = useCallback(node => {
        if (node !== null) {
            setContainerWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const columnsCount = Math.floor(containerWidth / COLUMN_WIDTH);

    const classes = placeholderStyles();
    return (
        <div
            className={classes.tableContainer}
            data-testid="placeholder-table"
            ref={ref}
            data-ismobile={isMobile}
        >
            {Array.from(Array(columnsCount)).map((_, index) => (
                <TableColumn key={index} />
            ))}
        </div>
    );
};

export default TablePlaceholder;
