import produce from 'immer';
import {
    ADD_ERROR,
    ConfirmPasswordError,
    DISPLAY_ERROR_MESSAGE,
    ERROR_CONNECTION,
    ERROR_DIFFERENT_PASSWORDS,
    IS_LOADING,
    RESET_STATE,
    SET_CONFIRM_PASSWORD,
    SET_PASSWORD,
    SHOW_PASSWORDS,
    UpdatePasswordAction,
    UpdatePasswordErrorType,
    UpdatePasswordState,
} from './changePassword.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';

export const initialState: UpdatePasswordState = {
    password: '',
    confirmPassword: '',
    isLoading: false,
    showPasswords: false,
    errors: {
        password: {
            message: null,
            display: false,
        },
        confirmPassword: {
            message: null,
            display: false,
        },
        general: {
            message: null,
            display: true,
        },
    },
};

function getConfirmPasswordError(state: UpdatePasswordState): ConfirmPasswordError | null {
    if (state.password !== state.confirmPassword) {
        return ERROR_DIFFERENT_PASSWORDS;
    }
    return null;
}

export const reducer = (
    state: UpdatePasswordState = initialState,
    action: UpdatePasswordAction,
): UpdatePasswordState =>
    produce(state, (draft: UpdatePasswordState) => {
        switch (action.type) {
            case SET_PASSWORD:
                draft.password = action.password;
                draft.errors.confirmPassword.message = getConfirmPasswordError(draft);
                break;
            case SET_CONFIRM_PASSWORD:
                draft.confirmPassword = action.confirmPassword;
                draft.errors.confirmPassword.message = getConfirmPasswordError(draft);
                break;
            case IS_LOADING:
                draft.isLoading = action.isLoading;
                break;
            case ADD_ERROR:
                draft.errors[action.errorType].message = action.errorMessage;
                break;
            case RESET_STATE:
                return {
                    ...initialState,
                };
            case SHOW_PASSWORDS:
                draft.showPasswords = action.showPasswords;
                break;
            case DISPLAY_ERROR_MESSAGE:
                draft.errors[action.errorType].display = action.display;
                break;
            default:
                return state;
        }
    });

export const changePasswordCreator = {
    changeNewPassword:
        (password: string): ThunkActionVoid =>
        dispatch =>
            dispatch({
                password,
                type: SET_PASSWORD,
            }),
    changeConfirmPassword:
        (confirmPassword: string): ThunkActionVoid =>
        dispatch =>
            dispatch({
                confirmPassword,
                type: SET_CONFIRM_PASSWORD,
            }),
    sendNewPassword:
        (): ThunkActionVoid =>
        (dispatch, getState, { api, loginCreator, loginSelector, changePasswordSelector }) => {
            dispatch({
                type: IS_LOADING,
                isLoading: true,
            });
            const state = getState();
            const username = loginSelector.getUsername(state);
            return api
                .updatePassword(changePasswordSelector.getPassword(state))
                .then(() => dispatch(loginCreator.passwordChanged(username)))
                .catch(err => {
                    if (err.status === 422) {
                        return dispatch({
                            type: ADD_ERROR,
                            errorType: UpdatePasswordErrorType.PASSWORD,
                            errorMessage: err.response.body.message,
                        });
                    }
                    return dispatch({
                        type: ADD_ERROR,
                        errorType: UpdatePasswordErrorType.GENERAL,
                        errorMessage: ERROR_CONNECTION,
                    });
                })
                .finally(() =>
                    dispatch({
                        type: IS_LOADING,
                        isLoading: false,
                    }),
                );
        },
    resetState: (): ThunkActionVoid => dispatch =>
        dispatch({
            type: RESET_STATE,
        }),
    toggleShowPasswords:
        (showPasswords: boolean): ThunkActionVoid =>
        dispatch =>
            dispatch({
                showPasswords,
                type: SHOW_PASSWORDS,
            }),
    toggleDisplayErrorMessage:
        (errorType: UpdatePasswordErrorType, display: boolean): ThunkActionVoid =>
        dispatch =>
            dispatch({
                errorType,
                display,
                type: DISPLAY_ERROR_MESSAGE,
            }),
};
