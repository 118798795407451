import { createSelector } from 'reselect';
import {
    ChangeAssigneeFilter,
    ChangeAssigneeState,
    ChangeStatusState,
    ContactManagerView,
    TasksActionsState,
} from './tasksActions.types';
import { AppState } from '../AppState.types';
import { isTaskBound } from './taskActions.utils';
import { Task } from '../content/content.types';
import { ApiGW } from '../../types/DTO/api-gw';

export interface Contact {
    name: string;
    id: number;
}

export const EMPTY_FILTER_VALUE = '[EMPTY]';

export const toContacts = (cm: ContactManagerView | null): Contact[] => {
    if (!cm || !cm.values) {
        return [];
    }
    return cm.values.items.map(value => {
        const nameField = value.fields.find(field => field.columnId === cm.nameFieldId);
        return {
            id: value.id,
            name: nameField ? nameField.value : '',
        };
    });
};

export const getSuggestions = (filter: ChangeAssigneeFilter): Array<{ label: string }> => {
    return filter.items.map(item => {
        const suggestion = item.formattedValue === '' ? EMPTY_FILTER_VALUE : item.formattedValue;
        return { label: suggestion };
    });
};

export const getSelectedItem = (filter: ChangeAssigneeFilter): string | null => {
    return filter.selectedItemIndex !== null && filter.items[filter.selectedItemIndex]
        ? filter.items[filter.selectedItemIndex].formattedValue
        : null;
};

export const getTasksActionsState = ({ tasksActions }: AppState): TasksActionsState => tasksActions;

export const getType = ({
    tasksActions,
}: AppState):
    | 'ChangeStatus'
    | 'UnAssign'
    | 'AssignToMe'
    | 'ChangeAssignee'
    | 'ChangeDueDate'
    | 'ConfirmChangeDueDate'
    | 'NullState'
    | 'ChangeStatusPending' => tasksActions.type;

export const getIsLoading = ({ tasksActions }: AppState): boolean =>
    !!(tasksActions as any).isLoading;

export const getFiltersDraft = ({ tasksActions }: AppState): ChangeAssigneeFilter[] | null =>
    tasksActions.type === 'ChangeAssignee' ? tasksActions.filtersDraft : null;

export const getChangeAssigneeState = ({ tasksActions }: AppState): ChangeAssigneeState | null => {
    return tasksActions.type === 'ChangeAssignee' ? (tasksActions as ChangeAssigneeState) : null;
};

export const getChangeStatusState = ({ tasksActions }: AppState): ChangeStatusState | null => {
    return tasksActions.type === 'ChangeStatus' ? tasksActions : null;
};

export const getBulkUpdates = ({ tasksActions }: AppState): ApiGW.TaskPartialUpdateDTOV3 | null =>
    tasksActions.type !== 'NullState' ? tasksActions.bulkUpdates : null;

export const getTask = ({ tasksActions }: AppState): Task | null =>
    isTaskBound(tasksActions) && tasksActions.task ? tasksActions.task : null;

export const getTaskId = createSelector(getTask, task => (task ? task.id : null));

export const getActiveContactId = createSelector(
    getTask,
    getBulkUpdates,
    (task, bulkUpdates) =>
        ((task && task.assignee?.id) || (bulkUpdates && bulkUpdates.assigneeId)) ?? null,
);

export const getDate = createSelector(getTask, task => (task ? task.dueDate : null));

export const getCurrentStatusId = createSelector(getTask, task => (task ? task.statusId : null));

export const getIsUnAssign = createSelector(getType, type => type === 'UnAssign');

export const getIsConfirmChangeDueDate = createSelector(
    getType,
    type => type === 'ConfirmChangeDueDate',
);

export const getTaskDefinitionId = createSelector(getTask, task =>
    task ? task.taskDefinitionId : null,
);

export const getContacts = createSelector(getChangeAssigneeState, state =>
    state ? toContacts(state.activeContactManager) : null,
);

export const getChangeAssigneContent = createSelector(getChangeAssigneeState, state =>
    state ? state.changeAssigneeContent : null,
);

export const getFilterViews = createSelector(getChangeAssigneeState, state => {
    if (!state) return null;
    return state.filtersDraft.map(filter => {
        return {
            ...filter,
            suggestions: getSuggestions(filter),
            selectedItem: getSelectedItem(filter),
        };
    });
});

export const getHasFilters = createSelector(getChangeAssigneeState, state =>
    state ? state.filters.length > 0 : false,
);

export const getIsChangeAssigneeButtonDisabled = createSelector(getChangeAssigneeState, state => {
    if (!state || state.activeContactManager.values === null) {
        return true;
    }
    if (state.changeAssigneeContent === 'filter') return false;
    // TODO
    return Boolean(
        state.task &&
            state.activeContactManager.values.items.every(
                item => item.id !== state.task!.assignee?.id,
            ),
    );
});

export const getHasMoreContacts = createSelector(getChangeAssigneeState, state =>
    state ? state.hasMoreContacts : false,
);

export const getSearchQuery = createSelector(getChangeAssigneeState, state =>
    state ? state.searchQuery : '',
);

export const getShowVotingMessage = createSelector(getChangeStatusState, state =>
    state ? state.showVotingMessage : false,
);

export const getBulkActionsTasks = ({ tasksActions }: AppState): Task[] | null =>
    tasksActions.type !== 'NullState' && tasksActions.bulkActionsTasks
        ? tasksActions.bulkActionsTasks
        : null;

export const getTasksInActiveJobsMap = ({ tasksActions }: AppState): Record<number, boolean> => {
    const result: Record<number, boolean> = {};
    tasksActions.bulkActionsQueue.forEach(bulkActionEl => {
        bulkActionEl.bulkActionsTasks.forEach(task => {
            result[task.id] = true;
        });
    });
    return result;
};

export default {
    getType,
    getIsLoading,
    getTaskId,
    getActiveContactId,
    getDate,
    getCurrentStatusId,
    getFiltersDraft,
    getChangeAssigneeState,
    getIsUnAssign,
    getShowVotingMessage,
    getBulkActionsTasks,
    getBulkUpdates,
    getTasksInActiveJobsMap,
};
