export interface TaskMessage {
    text: string;
    showRecent: boolean;
}

export const getTaskMessageAfterVoting = (
    shouldChangeStatus: boolean,
    submit: boolean,
    contentHasActiveItem: boolean,
    resultHasBeenSaved?: boolean,
): TaskMessage => {
    const message = {
        text: '',
        showRecent: false,
    };
    if (contentHasActiveItem) {
        if (submit) {
            message.text = shouldChangeStatus
                ? 'Voting.ChangesApplied'
                : 'ResponseActions.Submit.Success';
        }
        if (resultHasBeenSaved) message.text = 'Voting.ChangesApplied';
    } else {
        if (submit) {
            message.text = shouldChangeStatus
                ? 'Voting.ChangesAppliedButFilteredOut'
                : 'Voting.ResponseSubmittedButFilteredOut';
        }
        if (resultHasBeenSaved) message.text = 'Voting.ChangesAppliedButFilteredOut';
        message.showRecent = true;
    }
    return message;
};

export const getResponseMessageAfterVoting = (
    submit: boolean,
    contentHasActiveItem: boolean,
    resultHasBeenSaved?: boolean,
): TaskMessage => {
    const message = {
        text: '',
        showRecent: false,
    };
    if (contentHasActiveItem) {
        if (submit) message.text = 'ResponseActions.Submit.Success';
        if (resultHasBeenSaved) message.text = 'Voting.ChangesApplied';
    } else {
        if (submit) message.text = 'Voting.ResponseSubmittedButFilteredOut';
        if (resultHasBeenSaved) message.text = 'Voting.ResponseAppliedButFilteredOut';

        message.showRecent = true;
    }
    return message;
};
