import { Converter } from '../location.types';

export const searchQueryConverter: Converter = {
    stateToURL: ({ searchQuery }) => {
        if (searchQuery) {
            return { query: { searchQuery: encodeURI(searchQuery) } };
        }
    },
    URLToState: ({ query }) => {
        const searchQuery = query.searchQuery?.[0];
        if (searchQuery) {
            return { searchQuery: decodeURI(searchQuery) };
        }
    },
};
