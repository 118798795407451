import cloneDeep from 'clone-deep';
import { appendParamsToRelativeURL } from '../../utils/window.location.utils';
import { CLEAR_VOTING_STATE, GO_TO_VOTING, VotingAction, VotingState } from './voting.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import { isFetchedTasks } from '../content/content.utils';
import i18n from '../../localization/i18n';
import { STATUS_NOT_FOUND } from '../../constants/statusCodes.constants';
import { getImpersonateMode } from '../portalSettings/portalSettingsSelector';
import { getStartFormSelectedItem } from '../startForm/startFormSelector';
import { parseStartFormId } from '../startForm/startForm.utils';
import { getCreateTaskParams } from '../taskDefinitions/taskDefinitionsSelector.reselect';
import { ActiveCardAction, OPEN_TASK_DETAILS_VIEW } from '../activeCard/activeCard.types';

export const COPY_FAILED = 'ResponseActions.Copy.Fail';

const getVotingURL = (url: string, params?: Record<string, string | string[]>): string => {
    const paramsCopy: Record<string, string | string[]> = params ? cloneDeep(params) : {};
    paramsCopy.comeFromTasksManagement = 'portal_3';
    return appendParamsToRelativeURL(url, paramsCopy);
};

export const initialState: VotingState = {
    isBackUrl: false,
    submit: false,
    createdItemId: null,
    startForm: false,
    resultHasBeenSaved: false,
};

export const reducer = (
    state = initialState,
    action: VotingAction | ActiveCardAction,
): VotingState => {
    switch (action.type) {
        case OPEN_TASK_DETAILS_VIEW:
        case CLEAR_VOTING_STATE:
            return initialState;
        default:
            return state;
    }
};

export const votingCreator = {
    openResponse: (responseId: number, mode: 'view' | 'edit'): ThunkActionVoid => {
        return async (dispatch, getState, { api, goToURLCreator, toastsCreator }) => {
            const state = getState();
            const impersonateMode = getImpersonateMode(state);

            if (impersonateMode) {
                dispatch(toastsCreator.createImpersonateMessageToast());
                return;
            }

            await dispatch({
                type: GO_TO_VOTING,
                category: 'forms',
                cardId: responseId,
            });
            const responseUrl = await api.getResponseUrl(responseId);
            dispatch(
                goToURLCreator.gotoURL(
                    getVotingURL(mode === 'view' ? responseUrl.viewUrl : responseUrl.editUrl),
                ),
            );
        };
    },

    openTaskResponse: (
        taskId: number,
        taskDefinitionId: number,
        mode: 'view' | 'edit',
        responseOfflineId?: string,
    ): ThunkActionVoid => {
        return async (dispatch, getState, { api, goToURLCreator, commonCreator }) => {
            const { content } = getState();

            let offlineId = responseOfflineId;
            if (isFetchedTasks(content) && !responseOfflineId) {
                offlineId = content.items.find(t => t.id === taskId)?.response.offlineId;
            }

            if (!offlineId) return;

            try {
                const responseId = await api.getRespondentId(offlineId);
                const responseUrl = await api.getResponseUrl(responseId.id);

                await dispatch({
                    type: GO_TO_VOTING,
                    category: 'tasks',
                    cardId: taskId,
                    itemId: taskDefinitionId,
                });

                dispatch(
                    goToURLCreator.gotoURL(
                        getVotingURL(mode === 'view' ? responseUrl.viewUrl : responseUrl.editUrl, {
                            taskId: taskId.toString(),
                            taskDefinitionId: taskDefinitionId.toString(),
                        }),
                    ),
                );
            } catch (error: any) {
                if (error.status === STATUS_NOT_FOUND) {
                    return dispatch(commonCreator.handle404());
                }
            }
        };
    },

    addNewResponse: (params?: Record<string, string[]>): ThunkActionVoid => {
        return async (dispatch, getState, { goToURLCreator, toastsCreator, formsCreator }) => {
            const state = getState();
            const impersonateMode = getImpersonateMode(state);
            const selectedItem = getStartFormSelectedItem(state);
            const { menu } = state;

            if (impersonateMode) {
                dispatch(toastsCreator.createImpersonateMessageToast());
                return;
            }

            if (menu.navigation.activeCategory !== 'forms' && !selectedItem) {
                return;
            }

            const [type, id] = parseStartFormId(selectedItem);

            const formId = selectedItem && type === 'form' ? id : menu.navigation.activeItemId;
            await dispatch(formsCreator.updateForm(formId));
            const form = getState().forms.forms.find(f => f.id === formId);
            if (!form || !formId) {
                return;
            }
            await dispatch({
                type: GO_TO_VOTING,
                category: 'forms',
                itemId: formId,
            });
            dispatch(goToURLCreator.gotoURL(getVotingURL(form.votingLink, params)));
        };
    },

    startForm: (params?: Record<string, string[]>): ThunkActionVoid => {
        return async (dispatch, getState, { api, votingCreator: voting }) => {
            const state = getState();
            const selectedItem = getStartFormSelectedItem(state);

            const [type, id] = parseStartFormId(selectedItem);
            if (type === 'form') {
                dispatch(voting.addNewResponse(params));
            } else {
                const createTaskParams = getCreateTaskParams(state);

                if (!createTaskParams) return;

                if (!createTaskParams.summary) {
                    const td = await api.getTaskDefinition(id);
                    createTaskParams.summary = td.defaultTaskSummary;
                    createTaskParams.description = td.defaultTaskDescription;
                }

                const newTask = await api.createTask(id, createTaskParams);
                dispatch(
                    voting.openTaskResponse(newTask.id, id, 'edit', newTask.response.offlineId),
                );
            }
        };
    },

    copyResponse: (responseId: number): ThunkActionVoid => {
        return async (
            dispatch,
            getState,
            { api, toastsCreator, goToURLCreator, responsesActionsCreator },
        ) => {
            const state = getState();
            const impersonateMode = getImpersonateMode(state);

            if (impersonateMode) {
                dispatch(toastsCreator.createImpersonateMessageToast());
                return;
            }

            await dispatch({
                type: GO_TO_VOTING,
                category: 'forms',
                cardId: responseId,
            });
            try {
                dispatch(responsesActionsCreator.changeIsLoading(true));
                const response = await api.copyResponse(responseId);
                const responseUrl = await api.getResponseUrl(response.id);
                dispatch(goToURLCreator.gotoURL(getVotingURL(responseUrl.editUrl)));
            } catch (e) {
                dispatch(toastsCreator.createToast(i18n.t(COPY_FAILED), 'error'));
            } finally {
                dispatch(responsesActionsCreator.changeIsLoading(false));
            }
        };
    },

    goToDashboards: (id: string): ThunkActionVoid => {
        return async (dispatch, _, { goToURLCreator }) => {
            await dispatch({
                type: GO_TO_VOTING,
                category: 'dashboards',
                cardId: id,
            });
            const relativeDashboardUrl = `/dashboards/run/${id}`;
            const dashboardUrlParams = { redirectOnCloseQueryParamKey: 'redirectOnClose' };
            dispatch(
                goToURLCreator.gotoURL(
                    appendParamsToRelativeURL(relativeDashboardUrl, dashboardUrlParams),
                ),
            );
        };
    },
    clearVotingState: (): ThunkActionVoid => dispatch => {
        dispatch({ type: CLEAR_VOTING_STATE });
    },
};
