import { OPEN_TASK_DETAILS_VIEW } from '../activeCard/activeCard.types';

export const TOGGLE_TASK_SELECTED = 'TasksSelectedActions/TOGGLE_TASK_SELECTED';
export const TOGGLE_ALL_TASK_SELECTED = 'TasksSelectedActions/TOGGLE_ALL_TASK_SELECTED';
export const TOGGLE_TASKS_IN_ROW_SELECTED = 'TasksSelectedActions/TOGGLE_TASKS_IN_ROW_SELECTED';
export const DESELECT_TASKS = 'TasksSelectedActions/DESELECT_TASKS';

export type TasksSelectedState = {
    isAllTasksSelected: boolean;
    include: Array<number>;
    exclude: Array<number>;
    lastSelectedTaskId: number | null;
};

export type ToggleTaskSelectedAction = {
    type: typeof TOGGLE_TASK_SELECTED | typeof OPEN_TASK_DETAILS_VIEW;
    taskId: number;
    checked: boolean;
};

export type ToggleTasksInRowSelectedAction = {
    type: typeof TOGGLE_TASKS_IN_ROW_SELECTED;
    taskId: number | null;
    taskIds: number[];
};

export type ToggleAllTaskSelectedAction = {
    type: typeof TOGGLE_ALL_TASK_SELECTED;
};

export type DeselectTasksAction = {
    type: typeof DESELECT_TASKS;
};

export type TasksSelectedAction =
    | ToggleTaskSelectedAction
    | ToggleTasksInRowSelectedAction
    | ToggleAllTaskSelectedAction
    | DeselectTasksAction;
