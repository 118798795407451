import moment, { Moment } from 'moment';
import { DateFilterOption } from '../components/ConfigurableFilters/DateFilter';
import { LOCALES } from '../constants/main.constants';

export interface DateRange {
    from?: Date | null;
    to?: Date | null;
}

export const dateFilterOptionToRange = (
    option: DateFilterOption,
    timestamp: number = new Date().getTime(),
): DateRange => {
    let from: Moment;
    let to: Moment;

    switch (option) {
        case DateFilterOption.Yesterday:
            const yesterday = moment.parseZone(timestamp).subtract(1, 'day');
            [from, to] = [yesterday, yesterday];
            break;
        case DateFilterOption.Today:
            [from, to] = [moment.parseZone(timestamp), moment.parseZone(timestamp)];
            break;
        case DateFilterOption.Tomorrow:
            const tomorrow = moment.parseZone(timestamp).add(1, 'day');
            [from, to] = [tomorrow, tomorrow];
            break;
        case DateFilterOption.PreviousWeek:
            [from, to] = [
                moment.parseZone(timestamp).subtract(1, 'week').startOf('week'),
                moment.parseZone(timestamp).subtract(1, 'week').endOf('week'),
            ];
            break;
        case DateFilterOption.CurrentWeek:
            [from, to] = [
                moment.parseZone(timestamp).startOf('week'),
                moment.parseZone(timestamp).endOf('week'),
            ];
            break;
        case DateFilterOption.NextWeek:
            [from, to] = [
                moment.parseZone(timestamp).add(1, 'week').startOf('week'),
                moment.parseZone(timestamp).add(1, 'week').endOf('week'),
            ];
            break;
        case DateFilterOption.PreviousMonth:
            [from, to] = [
                moment.parseZone(timestamp).subtract(1, 'month').startOf('month'),
                moment.parseZone(timestamp).subtract(1, 'month').endOf('month'),
            ];
            break;
        case DateFilterOption.CurrentMonth:
            [from, to] = [
                moment.parseZone(timestamp).startOf('month'),
                moment.parseZone(timestamp).endOf('month'),
            ];
            break;
        case DateFilterOption.NextMonth:
            [from, to] = [
                moment.parseZone(timestamp).add(1, 'month').startOf('month'),
                moment.parseZone(timestamp).add(1, 'month').endOf('month'),
            ];
            break;
        default:
            return { from: null, to: null };
    }

    return { from: from.toDate(), to: to.toDate() };
};

export const dateToString = (date: Moment | Date | string): string => {
    let dateObj: Date;
    if (typeof date === 'string') {
        dateObj = new Date(date);
    } else if (moment.isMoment(date)) {
        dateObj = date.toDate();
    } else {
        dateObj = date;
    }

    const dateStringOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };
    const [month, day, year] = dateObj.toLocaleDateString(LOCALES, dateStringOptions).split('/');
    return `${year}-${month}-${day}`;
};

export const isDateInFilterRange = (date: Moment, filter: DateRange): boolean => {
    if (!filter.from && !filter.to) return false;
    if (filter.from && !date.isSame(filter.from, 'day') && !date.isAfter(filter.from)) return false;
    if (filter.to && !date.isSame(filter.to, 'day') && !date.isBefore(filter.to)) return false;
    return true;
};

export const savedDateRangeToDateRange = (range: {
    from?: string | Moment;
    to?: string | Moment;
}): DateRange => {
    const savedValueToDate = (value: string | Moment) =>
        typeof value === 'string' ? moment(value).toDate() : value.toDate();
    const dateRange: any = {};
    if (range.from) dateRange.from = savedValueToDate(range.from);
    if (range.to) dateRange.to = savedValueToDate(range.to);
    return dateRange as DateRange;
};

export const dateToDateString = (sDate: string): string => new Date(sDate).toLocaleDateString();

export const dateToDateTimeString = (sDate: string): string =>
    `${new Date(sDate).toLocaleDateString()} ${new Date(sDate).toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
    })}`;
