import { FetchTDSAction } from '../taskDefinitions/taskDefinitions.types';

export const CHANGE_SORT_COLUMN = 'TasksSort/CHANGE_SORT_COLUMN';
export const TOGGLE_SORT_ORDER = 'TasksSort/TOGGLE_SORT_ORDER';
export const CHANGE_SORT_COLUMN_OR_ORDER = 'TasksSort/CHANGE_SORT_COLUMN_OR_ORDER';
export const RESET_SORT = 'TasksSort/RESET_SORT';

export enum SortColumnType {
    Summary = 'SUMMARY',
    Assignee = 'ASSIGNEE',
    DueDate = 'DUE_DATE',
    Status = 'STATUS',
    AssignDate = 'ASSIGN_DATE',
    Custom = 'CUSTOM',
}

export const defaultSortColumn = SortColumnType.AssignDate as const;

export type NotCustomColumn = Exclude<SortColumnType, SortColumnType.Custom>;

export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type SortOrderOptions = SortOrder.Asc | SortOrder.Desc;

export const defaultSortOrder = SortOrder.Asc as const;

export type SortBy =
    | { column: NotCustomColumn }
    | {
          column: SortColumnType.Custom;
          property: string;
      };

export type ChangeSortColumnAction = SortBy & {
    type: typeof CHANGE_SORT_COLUMN;
    taskDefinitionId: number;
};

export type ChangeSortColumnOrOrderAction = SortBy & {
    type: typeof CHANGE_SORT_COLUMN_OR_ORDER;
    taskDefinitionId: number;
};

export interface ToggleSortOrderAction {
    type: typeof TOGGLE_SORT_ORDER;
    taskDefinitionId: number;
}

export interface ResetSortAction {
    type: typeof RESET_SORT;
}

export type TasksSortAction =
    | ToggleSortOrderAction
    | ChangeSortColumnAction
    | ChangeSortColumnOrOrderAction
    | FetchTDSAction
    | ResetSortAction;

export type Sorting = SortBy & { order: SortOrder };
export type TasksSortState = {
    [taskDefinitionId: number]: Sorting | undefined;
};
export type TasksSortData = (
    | { column: NotCustomColumn }
    | {
          column: SortColumnType.Custom;
          property: number;
      }
) & { order: SortOrder };
