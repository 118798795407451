import { createSelector } from 'reselect';
import { AppState } from '../AppState.types';

import { TasksExportState } from './tasksExport.types';
import { FetchTasksParams } from '../content/content.types';
// eslint-disable-next-line import/no-cycle
import { getFetchTasksParams } from '../content/fetchTasksSelector';
import { getTasksSelected } from '../tasksSelectedActions/tasksSelectedActionsSelector';
import { getCurrentTaskDefinitionId } from '../content/contentSelector';

export const getTaskExport = ({ tasksExport }: AppState): TasksExportState => tasksExport;

export const getCurrentTaskExport = createSelector(
    getCurrentTaskDefinitionId,
    getTaskExport,
    (taskDefinitionId, tasksExport) => {
        if (!tasksExport || !taskDefinitionId) return null;
        return tasksExport[taskDefinitionId] || null;
    },
);

export const getTasksExportParams = (
    taskDefinitionId: number,
    limit: number,
    offset: number,
    state: AppState,
    currentTaskId?: number,
): FetchTasksParams => {
    const params = getFetchTasksParams(taskDefinitionId, limit, offset, state);
    const tasksSelected = currentTaskId ? { include: [currentTaskId] } : getTasksSelected(state);
    const exportParams = tasksSelected !== null ? { tasks: { ...tasksSelected } } : {};

    return {
        ...params,
        params: {
            ...params.params,
            filter: { ...params.params.filter, ...exportParams },
        },
    };
};

export default {
    getTaskExport,
    getTasksExportParams,
};
