import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce/lib';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { getTasksCards, isSearchQueryNotEmpty } from '../../../redux/content/contentSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { tasksActionsCreator } from '../../../redux/tasksActions/tasksActions';
import { TaskCardsGridProps } from './CardsGrid.types';
import { isFetchedTasks } from '../../../redux/content/content.utils';
import { AppState } from '../../../redux/AppState.types';
import NothingFound from '../../ui/nothingFound/NothingFound';
import { activeCardCreator } from '../../../redux/activeCard/activeCard';
import { CARDS_WIDTHS, GRID_PADDING } from '../../ui/cards/cardsSizes';

import { getActiveCardPosition } from '../../../redux/activeCard/activeCardSelectors';
import { TaskCardsGridList } from './TaskCardsGridList';
import { tasksSelectedActionsCreator } from '../../../redux/tasksSelectedActions/tasksSelectedActions';
import { tasksExportCreator } from '../../../redux/tasksExport/tasksExport';

const mapDispatchToProps = mapCreatorsToDispatchProps({
    openTaskDetailView: activeCardCreator.openTaskDetailView,
    openActionPopup: tasksActionsCreator.openActionPopup,
    dispatchMainAction: tasksActionsCreator.dispatchMainAction,
    dispatchSelectAction: tasksSelectedActionsCreator.toggleTaskSelected,
    dispatchDeselectTasks: tasksSelectedActionsCreator.deselectTasks,
    dispatchExportAction: tasksExportCreator.exportTasks,
});

export const mapStateToProps = (state: AppState): TaskCardsGridProps => {
    const { content } = state;
    if (isFetchedTasks(content)) {
        return {
            cards: getTasksCards(state),
            nothingFound: !content.hasMore && !content.isLoading && !content.items.length,
            hasSearchQuery: isSearchQueryNotEmpty(state),
            activeCardPosition: getActiveCardPosition(state),
        };
    }
    return {
        cards: [],
        nothingFound: false,
        hasSearchQuery: false,
        activeCardPosition: 0,
    };
};

type CardStateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        loadMore: () => void;
        hasMore: boolean;
    };

export const TaskCardsGridComponent = (props: CardStateProps): JSX.Element => {
    const {
        cards,
        nothingFound,
        openTaskDetailView,
        openActionPopup,
        dispatchMainAction,
        hasSearchQuery,
        loadMore,
        hasMore,
        activeCardPosition,
        dispatchSelectAction,
        dispatchDeselectTasks,
        dispatchExportAction,
    } = props;
    const { t } = useTranslation();

    const [height, setHeight] = useState(0);
    const [width, setwidth] = useState(0);

    const onResize = useCallback(
        (size: { height: number; width: number }) => {
            setwidth(size.width);
            setHeight(size.height);
        },
        [setHeight, setwidth],
    );

    // 225ms - default duration of enter animation in MUI
    const onResizeDebounced = useDebouncedCallback(onResize, 225);

    const loadMoreItems = useCallback(async () => loadMore(), [loadMore]);

    if (nothingFound)
        return <NothingFound hasSearchQuery={hasSearchQuery} label={t('TaskCardsGrid.NoTasks')} />;

    return (
        <>
            <AutoSizer
                onResize={size => {
                    if (height === 0 || width === 0) {
                        onResize(size); // first render as soon as possible
                    } else {
                        onResizeDebounced.callback(size);
                    }
                }}
            >
                {() => {
                    const cardWidthWithPaddings = CARDS_WIDTHS.tasks + GRID_PADDING;
                    const widthWithoutPaddings = width + GRID_PADDING / 2;
                    const itemsPerRow =
                        Math.floor(widthWithoutPaddings / cardWidthWithPaddings) || 1;
                    let rowCount = Math.ceil(cards.length / itemsPerRow);
                    if (hasMore) rowCount += 1; // add row for loading placeholder
                    const isItemLoaded = (index: number) => {
                        return rowCount - index > 2; // start loading before scroll to the end
                    };

                    return (
                        <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={rowCount}
                            loadMoreItems={loadMoreItems}
                        >
                            {({ onItemsRendered, ref }) => (
                                <TaskCardsGridList
                                    cards={cards}
                                    openTaskDetailView={openTaskDetailView}
                                    openActionPopup={openActionPopup}
                                    dispatchMainAction={dispatchMainAction}
                                    dispatchSelectAction={dispatchSelectAction}
                                    dispatchDeselectTasks={dispatchDeselectTasks}
                                    dispatchExportAction={dispatchExportAction}
                                    height={height}
                                    width={width}
                                    rowCount={rowCount}
                                    itemsPerRow={itemsPerRow}
                                    listRef={ref}
                                    onItemsRendered={onItemsRendered}
                                    hasMore={hasMore}
                                    activeCardPosition={activeCardPosition}
                                />
                            )}
                        </InfiniteLoader>
                    );
                }}
            </AutoSizer>
        </>
    );
};

export const TaskCardsGrid = connect(mapStateToProps, mapDispatchToProps)(TaskCardsGridComponent);
