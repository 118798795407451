import React from 'react';
import { DefaultPopup } from 'worldapp-ui/shared';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DefaultPopupProps } from 'worldapp-ui/shared/popup/DefaultPopup';
import { useTranslation } from 'react-i18next';
import { defaultPopupButtonsDirectionOptions } from 'worldapp-ui/shared/popup/components/DefaultPopupFooter';
import { tasksActionsCreator } from '../../../redux/tasksActions/tasksActions';
import { ChangeStatusContent } from './ChangeStatusContent';
import { isContentCategoryTasks } from '../../../redux/content/contentSelector';
import { getTaskDetailViewTaskId } from '../../../redux/activeCard/activeCardSelectors';
import {
    getTasksActionsState,
    getBulkActionsTasks,
} from '../../../redux/tasksActions/taskActionsSelector';
import {
    ACTION_POPUP_DESKTOP_WIDTH,
    TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
} from '../../../constants/ui.constants';

export const ChangeStatus = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isTasks = useSelector(isContentCategoryTasks);
    const bulkActionsTasks = useSelector(getBulkActionsTasks);
    const isTaskDetailsOpen = useSelector(getTaskDetailViewTaskId);
    const tasksActions = useSelector(getTasksActionsState, shallowEqual);

    const onClick = () => {
        if (bulkActionsTasks) {
            dispatch(tasksActionsCreator.changeStatusInBulk());
        } else {
            dispatch(tasksActionsCreator.setStatus());
        }
    };
    const onClose = () => dispatch(tasksActionsCreator.closeActionPopup());
    if (isTasks && tasksActions.type === 'ChangeStatus' && !isTaskDetailsOpen) {
        const popupProps: DefaultPopupProps = {
            id: 'ChangeStatusPopup',
            open: true,
            contentDesktopWidth: ACTION_POPUP_DESKTOP_WIDTH,
            header: {
                title: t('TaskActions.ChangeStatus.Label'),
            },
            footer: {
                confirmButton: {
                    label: t('TaskActions.ChangeStatus.Ok'),
                    clickHandler: onClick,
                },
                cancelButton: {
                    label: t('TaskActions.ChangeStatus.Cancel'),
                    clickHandler: onClose,
                },
                mobileButtonsDirection: defaultPopupButtonsDirectionOptions.ROW,
            },
            showBlockingLoader: tasksActions.isLoading,
            className: TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
        };

        return (
            <DefaultPopup {...popupProps}>
                <ChangeStatusContent />
            </DefaultPopup>
        );
    }

    return null;
};

export default ChangeStatus;
