import { ApiGW } from '../../types/DTO/api-gw';
import { CustomLink, Tabs } from '../menu/menu.types';

export const PORTAL_SETTINGS_FETCHED = 'PortalSettings/PORTAL_SETTINGS_FETCHED';

interface PortalSettingsFetchedAction {
    type: typeof PORTAL_SETTINGS_FETCHED;
    tabs: ApiGW.TabsDTO;
    customLinks: CustomLink[];
    languages: ApiGW.LanguagesDTO;
    portalVersion?: number;
    hideCompleted: boolean;
    impersonateMode: boolean;
}

export type PortalSettingsAction = PortalSettingsFetchedAction;

export interface PortalSettingsState {
    customLinks: CustomLink[];
    tabs: Tabs;
    languages: ApiGW.LanguagesDTO;
    portalVersion?: number;
    impersonateMode: boolean;
    hideCompleted: boolean;
}
