import React from 'react';
import { Grid, Theme, createStyles, makeStyles } from 'worldapp-ui/shared';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import MainHeader from '../MainHeader';
import { CardsViewToggle } from '../CardsViewToggle';
import TasksSort from '../TasksSort';
import { TaskQuickFilter } from '../TaskQuickFilters';
import { TasksView } from '../../../redux/menu/menu.types';
import { menuCreator } from '../../../redux/menu/menu';
import { getTasksView } from '../../../redux/menu/menuSelector';
import TasksExport from '../TasksExport';
import { isMobile } from '../../../utils/browser.utils';
import { GRID_MOBILE_PADDING, GRID_PADDING } from '../../ui/cards/cardsSizes';

const useTasksHeaderPanelStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainLine: {
            padding: `0 ${GRID_PADDING}px 8px ${GRID_PADDING}px`,
            '&[data-ismobile="true"]': {
                padding: `0 16px 8px ${GRID_MOBILE_PADDING}px`,
                height: 40,
                boxSizing: 'border-box',
            },
        },
        filterLine: {
            minHeight: theme.spacing(5),
            padding: `8px ${GRID_PADDING}px 16px ${GRID_PADDING}px`,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 8,
            '&[data-ismobile="true"]': {
                padding: '0 0 10px 0',
            },
        },
        contentDivider: {
            margin: `0 ${GRID_PADDING}px`,
            marginBottom: 0,
            '&[data-ismobile="true"]': {
                margin: `0 ${GRID_MOBILE_PADDING}px`,
            },
        },
        contentDividerCardView: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        sortedByContainer: {
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'flex-end',
            flex: '1 0 auto',
            paddingBottom: 0,
        },
        mainHeaderAction: {
            position: 'relative',
            display: 'flex',
            marginRight: -4,
            gap: 8,
        },
        filtersContainer: {
            display: 'flex',
            flex: '1 1 auto',
            overflowY: 'hidden',
            overflowX: 'auto',
            boxSizing: 'border-box',
            scrollbarWidth: 'none',
            '& ::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
        },
    }),
);

export const TasksHeaderPanel = (): JSX.Element => {
    const dispatch = useDispatch();
    const tasksView = useSelector(getTasksView);
    const classes = useTasksHeaderPanelStyles();

    const onToggleViewChange = (view: TasksView) => {
        dispatch(menuCreator.changeTasksView(view));
    };

    const TaskGridActions = () => (tasksView === 'cards' ? <TasksSort /> : <TasksExport />);

    return (
        <>
            <Grid
                container={true}
                justify="space-between"
                alignItems="center"
                className={classes.mainLine}
                wrap="nowrap"
                data-ismobile={isMobile}
            >
                <MainHeader />
                <Grid
                    item={true}
                    className={classes.mainHeaderAction}
                    data-testid="main-header-action-container"
                >
                    {isMobile && <TaskGridActions />}
                    <CardsViewToggle viewType={tasksView} onChange={onToggleViewChange} />
                </Grid>
            </Grid>
            <div
                className={classes.filterLine}
                data-testid="main-header-filter-line-container"
                data-ismobile={isMobile}
            >
                <div className={classes.filtersContainer}>
                    <TaskQuickFilter />
                </div>
                {!isMobile && (
                    <div className={classes.sortedByContainer}>
                        <TaskGridActions />
                    </div>
                )}
            </div>
            <div
                className={classNames(classes.contentDivider, {
                    [classes.contentDividerCardView]: tasksView === 'cards',
                })}
                data-ismobile={isMobile}
            />
        </>
    );
};
