import { mapProperties } from 'worldapp-fe-utils';
import { Converter } from '../location.types';
import {
    initialManagerTaskQuickFilters,
    initialResponseQuickFilters,
} from '../../redux/contentQuickFilters/contentQuickFilters';
import { CONTENT_CATEGORY_FORMS, CONTENT_CATEGORY_TASKS } from '../../redux/content/category.types';

export const quickFiltersConverter: Converter = {
    stateToURL: state => {
        if (state.quickFilters) {
            const activeFilters = Object.entries(state.quickFilters.filters)
                .filter(([_, value]) => value)
                .map(([key]) => key);
            if (activeFilters.length > 0) {
                return { query: { qfilter: activeFilters } };
            }
        }
    },
    URLToState: url => {
        const qFilter = url.query.qfilter ?? [];
        const navigation = url.query.nav?.[0];
        if (navigation === CONTENT_CATEGORY_TASKS) {
            const filters = mapProperties(initialManagerTaskQuickFilters, (_, key) =>
                qFilter.includes(key),
            );
            return { quickFilters: { type: 'task', filters } };
        }
        if (navigation === CONTENT_CATEGORY_FORMS) {
            const filters = mapProperties(initialResponseQuickFilters, (_, key) =>
                qFilter.includes(key),
            );
            return { quickFilters: { type: 'response', filters } };
        }
    },
};
