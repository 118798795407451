import React, { useLayoutEffect, useState, useMemo, MouseEvent } from 'react';
import { Card, CardContent, CircularContouredLoader, Grid, Text } from 'worldapp-ui/shared';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Calendar12Icon, User12Icon } from 'worldapp-ui/shared/icons';
import Title from './Title';
import { Status, StatusCompleted } from './Status';
import MoreButton from '../buttons/MoreButton';
import { cardStyles } from './card.styles';
import ExpandButton from '../buttons/ExpandButton';
import { TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS } from '../../../constants/ui.constants';
import { ActionItem } from '../../ApplicationContent/TableView/TableActions';

interface UnreadStatus {
    value: boolean;
    text: string;
}

export type DueDate =
    | { dueDateStatus: 'notSet' }
    | { dueDateStatus: 'set'; date: string }
    | { dueDateStatus: 'overdue'; date: string };

const defaultDueDate: DueDate = { dueDateStatus: 'notSet' };

export interface TaskCardProps {
    unread?: UnreadStatus;
    status?: string;
    dueDate?: DueDate;
    title?: string;
    searchQuery?: string;
    assignee?: string;
    taskDefinition?: string;
    onCardClick?: (shiftKey?: boolean) => void;
    onExpandButtonClick?: () => void;
    isActive?: boolean;
    menuActions?: ActionItem[];
    shouldHighlight?: boolean;
    isTaskDetailViewOpened: boolean;
    isStatusClosed?: boolean;
    selected: boolean;
    deselectTasks?: () => void;
    inProgress: boolean;
    cardWidth: number;
}

export const DueDate = (props: DueDate): JSX.Element | null => {
    const classes = cardStyles({});
    if (props.dueDateStatus === 'notSet') return null;

    const { date } = props;
    const isOverdue = props.dueDateStatus === 'overdue';

    return (
        <Grid
            item
            wrap="nowrap"
            className={classNames(classes.labelContainer, classes.label, {
                [classes.labelOverdue]: isOverdue,
            })}
        >
            <Calendar12Icon className={classes.icon12} />
            <Text variant="r12r" data-testid="card-task-dueDate">
                {date}
            </Text>
        </Grid>
    );
};

export const AssigneeComponent = (props: { assignee?: string }): JSX.Element => {
    const { t } = useTranslation();
    const classes = cardStyles({});
    return (
        <Grid item wrap="nowrap" xs="auto" className={classes.assigneeLabelContainer} zeroMinWidth>
            <User12Icon className={classes.icon12} />
            <Text
                variant="r12r"
                noWrap={true}
                data-testid="card-task-assignee"
                className={classes.assigneeLabel}
            >
                {props.assignee || t('TaskCard.Unassigned')}
            </Text>
        </Grid>
    );
};

export const TaskDefinitionComponent = (props: { taskDefinition?: string }): JSX.Element => {
    const classes = cardStyles({});
    return (
        <div className={classes.displayFlex}>
            <Text
                variant="r12m"
                noWrap={true}
                className={classes.taskDefinitionLabel}
                data-testid="card-task-taskDefinition"
            >
                {props.taskDefinition || ''}
            </Text>
        </div>
    );
};

const TaskCard = (props: TaskCardProps): JSX.Element => {
    const {
        status,
        dueDate = defaultDueDate,
        title,
        searchQuery,
        assignee,
        taskDefinition,
        onCardClick,
        onExpandButtonClick,
        isActive = false,
        menuActions = [],
        shouldHighlight,
        isTaskDetailViewOpened,
        isStatusClosed,
        selected,
        deselectTasks,
        inProgress,
        cardWidth,
        ...restProps
    } = props;
    const classes = cardStyles({ cardWidth });

    const [isCardActive, setIsCardActive] = useState(isActive);

    useLayoutEffect(() => {
        setIsCardActive(isActive);
    }, [isActive]);

    const ref = useOnclickOutside(
        () => {
            if (isCardActive) {
                setIsCardActive(false);
            }
            if (deselectTasks) {
                deselectTasks();
            }
        },
        {
            ignoreClass: !deselectTasks ? '' : TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
            disabled: (!isCardActive && !deselectTasks) || isTaskDetailViewOpened,
        },
    );

    const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
        if (onExpandButtonClick) {
            event.stopPropagation();
            onExpandButtonClick();
        }
    };

    const handleCardClick = (event: MouseEvent) => {
        if (onCardClick) onCardClick(event.shiftKey);
    };

    const resultDueDate: DueDate = useMemo(
        () =>
            isStatusClosed && dueDate.dueDateStatus === 'overdue'
                ? { ...dueDate, dueDateStatus: 'set' }
                : dueDate,
        [isStatusClosed, dueDate],
    );

    return (
        <div className={classes.cardWrapper}>
            <Card
                className={classNames(
                    classes.card,
                    classes.taskCard,
                    TASK_CARD_OUTSIDE_CLICK_IGNORE_CLASS,
                    {
                        [classes.active]: isCardActive,
                        [classes.highlighted]: shouldHighlight,
                        [classes.cardWithAction]: onCardClick,
                        [classes.cardCloses]: isStatusClosed,
                        [classes.selected]: selected,
                        [classes.inProgress]: inProgress,
                    },
                )}
                ref={ref}
                {...restProps}
            >
                <CardContent onClick={handleCardClick} className={classes.taskCardContent}>
                    <Grid container={true} justify="space-between" className={classes.cardHeader}>
                        {isStatusClosed ? (
                            <StatusCompleted status={status} color="primary" />
                        ) : (
                            <Status status={status} color="primary" />
                        )}
                        <ExpandButton onClick={handleIconClick} disabled={!onExpandButtonClick} />
                    </Grid>
                    <Title title={title} searchQuery={searchQuery} className={classes.title} />
                    <TaskDefinitionComponent taskDefinition={taskDefinition} />
                    <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.cardFooter}
                    >
                        <AssigneeComponent assignee={assignee} />
                        <DueDate {...resultDueDate} />
                        {!!menuActions.length && (
                            <Grid item wrap="nowrap" className={classes.menuActions}>
                                <MoreButton
                                    menuActions={menuActions}
                                    disabled={!onExpandButtonClick}
                                    key="more"
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
            {inProgress ? (
                <div className={classes.loader} data-testid="task-card_loader">
                    <CircularContouredLoader />
                </div>
            ) : null}
        </div>
    );
};

export default TaskCard;
