import React from 'react';
import {
    Button,
    Truncate,
    Text,
    IconButton,
    Grid,
    Icon,
    Tooltip,
    TabsControl,
} from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon, ExportIcon } from 'worldapp-ui/shared/icons';
import { TabsControlItem } from 'worldapp-ui/shared/tabs/TabsControl';
import { connect, useSelector } from 'react-redux';
import { Task, TasksContentTab } from '../../../redux/content/content.types';
import { TaskDefinition } from '../../../redux/taskDefinitions/taskDefinitions.types';
import { TDViewValueActionRow } from './TDViewValueActionRow';
import { taskDetailViewStyles } from './TaskDetailView.styles';
import { getDueDate } from '../../../redux/content/contentSelector';
import { TDViewDescriptionValue } from './TDViewDescriptionValue';
import { TDViewCustomValues } from './TDViewCustomValues';
import { ActionItem } from '../TableView/TableActions';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { AppState } from '../../../redux/AppState.types';
import { activeCardCreator } from '../../../redux/activeCard/activeCard';
import { getTaskContentTab, getTaskHistory } from '../../../redux/activeCard/activeCardSelectors';
import { TaskHistoryItem } from '../../../redux/activeCard/activeCard.types';
import { TaskHistoryTab } from './TaskHistoryTab';
import { hasManagementRights } from '../../../redux/contentQuickFilters/contentQuickFiltersSelector';
import { getTaskHistoryFeature } from '../../../redux/features/featuresSelector';

interface OwnProps {
    task: Task & { index?: number | undefined };
    taskDefinition: TaskDefinition;
    mainAction?: ActionItem;
    actions?: Record<string, () => void>;
    editFormAction?: ActionItem;
    onClose: () => void;
    onExport: (taskId: number) => void;
}

interface Props extends OwnProps {
    activeTab: TasksContentTab;
    taskHistory?: TaskHistoryItem[];
    isManager: boolean;
    changeTab: (stage: TasksContentTab) => void;
}

const mapStateToProps = (state: AppState) => {
    return {
        activeTab: getTaskContentTab(state),
        taskHistory: getTaskHistory(state),
        isManager: hasManagementRights(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    changeTab: activeCardCreator.changeTab,
});

export const TaskDetailViewPanelContentComponent = (props: Props): JSX.Element => {
    const {
        task,
        taskDefinition,
        mainAction,
        editFormAction,
        actions,
        activeTab,
        taskHistory,
        isManager,
        onClose,
        onExport,
        changeTab,
    } = props;
    const classes = taskDetailViewStyles();

    const { t } = useTranslation();

    const status = taskDefinition.statuses.find(i => i.id === task.statusId);

    const hasTaskHistoryFeature = useSelector(getTaskHistoryFeature);
    const dueDate = getDueDate(task, status?.closed);
    const isOverdue = dueDate.dueDateStatus === 'overdue';
    const assignDate = task.assignDate && new Date(task.assignDate).toLocaleDateString();
    const dueDateText =
        dueDate.dueDateStatus !== 'notSet' ? dueDate.date : t('TaskDetailView.NotDueDate');

    const tabsControlItems: TabsControlItem[] = [
        {
            value: TasksContentTab.Details,
            label: t('TaskDetailView.Details'),
            onClick: () => changeTab(TasksContentTab.Details),
        },
        {
            value: TasksContentTab.History,
            label: t('TaskDetailView.History'),
            onClick: () => changeTab(TasksContentTab.History),
        },
    ];

    const MainActionButton = () =>
        mainAction ? (
            <Button
                data-testid="button_task-dtview_main-action"
                onClick={mainAction.onClick}
                style={{ width: 156 }}
            >
                {mainAction.name}
            </Button>
        ) : null;

    return (
        <div className={classes.animationWrapper}>
            <Grid
                container={true}
                direction="column"
                wrap="nowrap"
                justify="space-between"
                className={classes.animationWrapperChild}
            >
                <div className={classes.tdContainer}>
                    {/* Header task details popup */}
                    <div className={classes.tdHeader}>
                        <MainActionButton />
                        <Grid spacing={3} container={true} className={classes.tdHeaderIcon}>
                            {editFormAction && (
                                <Grid item={true}>
                                    <Tooltip title={editFormAction?.name} placement="bottom-start">
                                        <span>
                                            <IconButton
                                                icon={
                                                    <Icon
                                                        name="fill-out-form"
                                                        size="l"
                                                        mode="stroke"
                                                    />
                                                }
                                                onClick={editFormAction.onClick}
                                                data-testid="task-dtview_edit-icon"
                                            />
                                        </span>
                                    </Tooltip>
                                </Grid>
                            )}
                            <Grid item={true}>
                                <Tooltip
                                    title={t('TaskDetailView.Export') as string}
                                    placement="bottom-start"
                                >
                                    <span>
                                        <IconButton
                                            icon={<ExportIcon size="medium" />}
                                            onClick={() => onExport(task.id)}
                                            data-testid="task-dtview_export-icon"
                                            aria-label={t('TaskDetailView.Export')}
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>{' '}
                            <Grid item={true}>
                                <Tooltip
                                    title={t('TaskDetailView.Close') as string}
                                    placement="bottom-start"
                                >
                                    <span>
                                        <IconButton
                                            icon={<CloseIcon size="small" />}
                                            onClick={onClose}
                                            data-testid="task-dtview_close-icon"
                                            aria-label={t('TaskDetailView.Close')}
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.tdBody}>
                        {/* Task td`s name */}
                        <div className={classes.tdActionSection}>
                            <div>
                                <Text
                                    variant="r12m"
                                    className={classes.tdName}
                                    data-testid="task-dtview_td-name"
                                >
                                    <Truncate lines={2}>{taskDefinition.title}</Truncate>
                                </Text>
                            </div>
                            {/* Task summary */}
                            <div className={classes.tdTitle}>
                                <Text variant="r16m" data-testid="task-dtview_summary">
                                    <Truncate lines={2}>{task.summary}</Truncate>
                                </Text>
                            </div>
                            <TDViewValueActionRow
                                iconName="change-status"
                                value={status?.title || ''}
                                dataTestid="task-dtview_status"
                                action={actions?.ChangeStatus}
                                actionName="changeStatus"
                                actionTooltip={t('TaskActions.ChangeStatus.Label')}
                            />
                            <TDViewValueActionRow
                                iconName="assign-to-me"
                                value={task.assignee?.name ?? t('TaskCard.Unassigned')}
                                dataTestid="task-dtview_assignee"
                                action={
                                    actions?.ChangeAssignee ||
                                    actions?.UnAssign ||
                                    actions?.AssignToMe
                                }
                                actionName="changeAssignee"
                                actionTooltip={t('TaskActions.ChangeAssignee.Label')}
                            />
                            {isOverdue ? (
                                <>
                                    <TDViewValueActionRow
                                        iconName="date"
                                        value={dueDateText}
                                        dataTestid="task-dtview_due-date"
                                        hasError={!status?.closed}
                                        action={actions?.ChangeDueDate}
                                        actionName="dueDate"
                                        actionTooltip={t('TaskActions.ChangeDueDate.Label')}
                                    />
                                </>
                            ) : (
                                <TDViewValueActionRow
                                    iconName="date"
                                    value={dueDateText}
                                    dataTestid="task-dtview_due-date"
                                    hasError={false}
                                    action={actions?.ChangeDueDate}
                                    actionName="dueDate"
                                    actionTooltip={t('TaskActions.ChangeDueDate.Label')}
                                    disabled={dueDate.dueDateStatus === 'notSet'}
                                />
                            )}
                        </div>
                        {hasTaskHistoryFeature && isManager && (
                            <TabsControl
                                items={tabsControlItems}
                                value={activeTab}
                                containerClassName={classes.tabControl}
                            />
                        )}
                        {/* Task description */}
                        {activeTab === TasksContentTab.Details && (
                            <div className={classes.tdDescriptionSection}>
                                <div>
                                    {task.description && (
                                        <TDViewDescriptionValue
                                            title={t('Description')}
                                            value={task.description}
                                            dataTestid="task-dtview_description"
                                        />
                                    )}
                                    <TDViewDescriptionValue
                                        title={t('TaskDetailView.CreatedBy')}
                                        value={task.createdBy}
                                        dataTestid="task-dtview_created-by"
                                    />
                                    <TDViewDescriptionValue
                                        title={t('TaskDetailView.AssignedDate')}
                                        value={assignDate}
                                        dataTestid="task-dtview_assign-date"
                                    />
                                    <TDViewCustomValues
                                        customValues={task.customValues}
                                        customProperties={taskDefinition.customProperties}
                                    />
                                </div>
                            </div>
                        )}
                        {activeTab === TasksContentTab.History && (
                            <TaskHistoryTab items={taskHistory} />
                        )}
                    </div>
                </div>
            </Grid>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailViewPanelContentComponent);
