import React, { ChangeEvent, useCallback, useState } from 'react';
import { AppBar, Toolbar, Icon, IconButton, SearchBox } from 'worldapp-ui/shared';
import classNames from 'classnames';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { menuCreator } from '../../redux/menu/menu';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { useAppHeaderStyles } from './AppHeader.styles';
import { contentCreator } from '../../redux/content/content';
import { getContextSearchQuery } from '../../redux/content/contentSelector';
import { AppState } from '../../redux/AppState.types';
import BrandImage from '../LoginPage/BrandImage';
import { selectMainHeader } from '../../redux/selectors';
import { getTaskDetailViewTaskId } from '../../redux/activeCard/activeCardSelectors';
import { isMobile } from '../../utils/browser.utils';
import { getOnlineIniOsFeature } from '../../redux/features/featuresSelector';

export const mapStateToProps = (state: AppState) => {
    return {
        searchQuery: getContextSearchQuery(state),
        searchCategory: selectMainHeader(state),
        openedTaskDetailsId: getTaskDetailViewTaskId(state),
        hasFeatureOnlineIniOs: getOnlineIniOsFeature(state),
    };
};

export const mapDispatchToProps = mapCreatorsToDispatchProps({
    toggleDrawerPermanent: menuCreator.toggleDrawerPermanent,
    contextSearchChangeInput: contentCreator.contextSearchChangeQuery,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const AppHeader = (props: Props): JSX.Element => {
    const {
        toggleDrawerPermanent,
        contextSearchChangeInput,
        searchCategory,
        searchQuery,
        openedTaskDetailsId,
        hasFeatureOnlineIniOs,
    } = props;

    const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
    const { t } = useTranslation();
    const classes = useAppHeaderStyles();
    const handleMenuClick = useCallback(() => toggleDrawerPermanent(), [toggleDrawerPermanent]);

    const isDisabled = Boolean(openedTaskDetailsId !== null);

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        contextSearchChangeInput(e.target.value);
    };

    const onClearSearch = () => {
        contextSearchChangeInput('');
        if (searchQuery === '') setMobileSearchVisible(false);
    };

    return (
        <AppBar
            position="static"
            className={classNames(classes.appBar, { [classes.appBariOS]: hasFeatureOnlineIniOs })}
        >
            <Toolbar
                disableGutters={true}
                className={classNames(classes.toolbar, {
                    [classes.toolbariOS]: hasFeatureOnlineIniOs,
                })}
            >
                <div className={classes.toolbarItem} data-ismobile={isMobile}>
                    {isMobile && !hasFeatureOnlineIniOs && (
                        <IconButton
                            icon={<Icon name="menu" size="l" mode="stroke" />}
                            aria-label="Open drawer"
                            onClick={handleMenuClick}
                            data-testid="btn-open-drawer"
                        />
                    )}
                    <div className={classes.logoWrapper} data-ismobile={isMobile}>
                        <BrandImage height={28} data-testid="brand-image_header" />
                    </div>
                </div>
                <div className={classes.toolbarItem}>
                    {isMobile && !mobileSearchVisible && (
                        <IconButton
                            icon={<Icon name="search" size="l" mode="stroke" />}
                            classes={{ root: classes.searchIcon }}
                            onClick={() => setMobileSearchVisible(true)}
                            data-testid="btn-mobile_open-search-box"
                        />
                    )}

                    {((isMobile && mobileSearchVisible) || !isMobile) && (
                        <div
                            className={classNames({
                                [classes.mobileSearchPanel]: isMobile && mobileSearchVisible,
                                [classes.mobileSearchPaneliOS]:
                                    hasFeatureOnlineIniOs && mobileSearchVisible,
                            })}
                        >
                            <SearchBox
                                value={searchQuery}
                                onChange={onSearch}
                                placeholder={t('MainHeader.SearchIn', { searchCategory })}
                                fullWidth
                                showClearSearch={!!searchQuery || isMobile}
                                onClearSearch={onClearSearch}
                                data-testid="app-header_search-box"
                                className={classNames(classes.searchBox, {
                                    [classes.searchBoxOS]: hasFeatureOnlineIniOs,
                                })}
                                disabled={isDisabled}
                                autoFocus={isMobile}
                                data-ismobile={isMobile}
                                clearSearchLabel={t('NavigationPanel.ClearSearch')}
                            />
                        </div>
                    )}

                    {/* {!isMobile && <Divider className={classes.divider} />} */}
                    {/* <DotBadge className={classNames(isMobile && classes.badge)}> */}
                    {/*    <NotificationsIcon className={classes.menuIcon} color="disabled" /> */}
                    {/* </DotBadge> */}
                </div>
            </Toolbar>
            {hasFeatureOnlineIniOs && (
                <div className={classes.appMode} data-testid="app-header_indicator">
                    <div className={classes.indicator}>&bull;</div>
                    {t('MainHeader.OnlineMode')}
                </div>
            )}
        </AppBar>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
