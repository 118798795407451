import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'worldapp-ui/shared';

export default (props: ComponentProps<typeof Calendar>): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Calendar
            leftArrowButtonProps={{ 'aria-label': t('Calendar.Prev') }}
            rightArrowButtonProps={{ 'aria-label': t('Calendar.Next') }}
            {...props}
        />
    );
};
