import { useSelector } from 'react-redux';
import { useEffect, useCallback } from 'react';

import i18n from './localization/i18n';
import { isInLanguagesList } from './localization/localizationDefaultData';
import { getDefaultLanguage } from './redux/portalSettings/portalSettingsSelector';
import { useActionCreators } from './hooks/useActionCreators';

const LanguageHelper = (): null => {
    const defaultLanguage = useSelector(getDefaultLanguage);
    const { localizationCreator } = useActionCreators();

    const changeLanguage = useCallback(
        (language: string) => {
            const mainLanguage = language.split('-')[0];
            if (isInLanguagesList(language)) {
                localizationCreator.changeLanguage(language);
            } else if (isInLanguagesList(mainLanguage)) {
                localizationCreator.changeLanguage(mainLanguage);
            } else {
                localizationCreator.changeLanguage(defaultLanguage);
            }
        },
        [defaultLanguage, localizationCreator],
    );

    useEffect(() => {
        i18n.on('languageChanged', changeLanguage);
        return () => i18n.off('languageChanged', changeLanguage);
    }, [changeLanguage]);

    return null;
};

export default LanguageHelper;
