import React, { useState } from 'react';
import classNames from 'classnames';
import { Icon, IconButton, Text, Tooltip } from 'worldapp-ui/shared';
import { taskDetailViewStyles } from './TaskDetailView.styles';
import { ChangeDueDatePopover } from '../../Actions/ChangeDueDatePopover';
import { ChangeStatusPopover } from '../../Actions/ChangeStatus/ChangeStatusPopover';
import ChangeAssigneePopover from '../../Actions/ChangeAssignee/ChangeAssigneePopover';

interface TDViewValueRowProps {
    value: string;
    dataTestid?: string;
    hasError?: boolean;
    iconName?: any;
    action?: () => void;
    actionName?: string;
    disabled?: boolean;
    actionTooltip?: string;
}

export const TDViewValueActionRow = (props: TDViewValueRowProps): JSX.Element => {
    const { value, dataTestid, hasError, iconName, action, actionName, disabled, actionTooltip } =
        props;
    const classes = taskDetailViewStyles();
    const [referencedElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [showPopper, setShowPopper] = useState(false);

    const onIconButtonClick = () => {
        if (actionName) {
            setShowPopper(!showPopper);
        }
        if (action) {
            action();
        }
    };

    const iconButton = (
        <Tooltip
            title={`${actionTooltip}`}
            data-testid={`${dataTestid}_actionTooltip`}
            placement="bottom-start"
        >
            <span>
                <IconButton
                    icon={<Icon name="edit" mode="stroke" />}
                    onClick={onIconButtonClick}
                    data-testid={`${dataTestid}_action`}
                    aria-label={actionTooltip}
                />
            </span>
        </Tooltip>
    );
    return (
        <div className={classNames(classes.tdValueRow, { [classes.disabled]: disabled })}>
            <div className={classes.tdColumnElementLeft}>
                {!!iconName && (
                    <Icon
                        name={iconName}
                        size="l"
                        mode="stroke"
                        data-testid={`${dataTestid}_icon`}
                        className={classNames({ [classes.error]: hasError })}
                    />
                )}
            </div>
            <div>
                <Text
                    variant="r12r"
                    data-testid={`${dataTestid}_value`}
                    className={classNames(classes.tdFieldValue, {
                        [classes.error]: hasError,
                        [classes.disabled]: disabled,
                    })}
                >
                    {value}
                </Text>
            </div>
            <div ref={setReferenceElement} className={classes.actionBtnContainer}>
                {action &&
                    (showPopper ? (
                        <div className={classes.actionBtnWrapperForcedActive}>{iconButton}</div>
                    ) : (
                        iconButton
                    ))}
            </div>
            {actionName === 'dueDate' && referencedElement && showPopper && (
                <ChangeDueDatePopover
                    anchorElement={referencedElement}
                    closePopover={() => setShowPopper(false)}
                    open={showPopper}
                />
            )}
            {actionName === 'changeStatus' && referencedElement && showPopper && (
                <ChangeStatusPopover
                    anchorElement={referencedElement}
                    closePopover={() => setShowPopper(false)}
                    open={showPopper}
                />
            )}
            {actionName === 'changeAssignee' && referencedElement && showPopper && (
                <ChangeAssigneePopover
                    anchorElement={referencedElement}
                    closePopover={() => setShowPopper(false)}
                    open={showPopper}
                />
            )}
        </div>
    );
};
