import React, { FunctionComponent, RefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Grid,
    createStyles,
    makeStyles,
    CircularContouredLoader,
    ReactInfiniteScroll,
} from 'worldapp-ui/shared';

import { Category } from '../../../redux/content/category.types';
import {
    CARDS_HEIGHTS,
    CARDS_PADDING,
    GRID_MOBILE_PADDING,
    GRID_PADDING,
} from '../../ui/cards/cardsSizes';
import { scrollToElement } from '../../../utils/scroll.utils';
import { JustifyValue } from '../../../redux/content/content.types';
import { getActiveCardPosition } from '../../../redux/activeCard/activeCardSelectors';
import { deviceType, isMobile } from '../../../utils/browser.utils';

export interface InfiniteScrollProps {
    hasMore: boolean;
    length: number;
    category: Category;
    loadMore: () => void;
    scrollableTarget: RefObject<HTMLDivElement>;
    isLoading: boolean;
    justifyContentValue: JustifyValue;
}

const useInfiniteScrollStyles = makeStyles(theme =>
    createStyles({
        loader: {
            marginTop: theme.spacing(),
        },
        gridContainer: (props: InfiniteScrollProps) => ({
            minHeight: CARDS_HEIGHTS[props.category] + CARDS_PADDING * 4,
            padding:
                props.justifyContentValue === 'center'
                    ? `${CARDS_PADDING}px 0`
                    : `${CARDS_PADDING}px 0 ${CARDS_PADDING}px ${
                          isMobile ? GRID_MOBILE_PADDING : GRID_PADDING
                      }px`,
            '&[data-ismobile="true"]': {
                gap: 4,
                padding:
                    props.justifyContentValue === 'center'
                        ? '12px 0'
                        : `12px 0 ${CARDS_PADDING}px ${GRID_PADDING}px`,
            },
        }),
        nothingFindContainer: {
            padding: CARDS_PADDING,
            display: 'flex',
            height: '100%',
        },
    }),
);

const InfiniteScrollWrapper: FunctionComponent<InfiniteScrollProps> = props => {
    const classes = useInfiniteScrollStyles(props);
    const activeElementPosition = useSelector(getActiveCardPosition);
    useEffect(() => {
        scrollToElement(props.scrollableTarget, {
            elementHeight: CARDS_HEIGHTS[props.category],
            elementPadding: CARDS_PADDING,
            elementPosition: activeElementPosition,
            elementsCount: props.length,
            containerScrollHeightCorrection: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isMobileDevice = deviceType === 'mobile';
    return (
        <ReactInfiniteScroll
            next={props.loadMore}
            hasMore={props.hasMore}
            loader={
                <div className={classes.loader}>
                    <CircularContouredLoader />
                </div>
            }
            dataLength={props.length}
            scrollableTarget={props.scrollableTarget.current?.id}
            style={{ overflow: 'hidden' }}
        >
            <Grid
                container={true}
                spacing={isMobileDevice ? 1 : 2}
                justify={props.justifyContentValue}
                className={classes.gridContainer}
                data-ismobile={isMobileDevice}
            >
                {props.children}
            </Grid>
        </ReactInfiniteScroll>
    );
};

export const InfiniteScroll: FunctionComponent<InfiniteScrollProps> = props => {
    const classes = useInfiniteScrollStyles(props);
    if (props.isLoading && props.length === 0) {
        return (
            <div className={classes.loader}>
                <CircularContouredLoader />
            </div>
        );
    }

    if (!props.isLoading && props.length === 0) {
        return <div className={classes.nothingFindContainer}>{props.children}</div>;
    }

    return <InfiniteScrollWrapper {...props} />;
};
