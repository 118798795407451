import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { blockStyles } from './Placeholder.styles';

interface BlockProps {
    className?: string;
    width?: number;
    height?: number;
    dark?: boolean;
}

export const PlaceholderBlock: FunctionComponent<BlockProps> = ({
    width,
    height,
    className,
    dark,
}) => {
    const classes = blockStyles({ width, height });
    return <div className={classNames(classes.block, className, dark && classes.darkBlock)} />;
};
