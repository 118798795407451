import { createStyles, Theme, makeStyles, fade, lighten } from 'worldapp-ui/shared';

export const useAppHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        badge: {
            marginLeft: theme.spacing(2),
        },
        divider: {
            backgroundColor: theme.palette.neutral[12],
            height: theme.spacing(4),
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(2),
            width: 1,
        },
        logoWrapper: {
            '&[data-ismobile="true"]': {
                color: theme.palette.primary.main,
                margin: 'auto',
            },
        },
        mobileSearchPanel: {
            background: theme.palette.white.main,
            position: 'absolute',
            left: 0,
            right: 0,
            padding: '0 20px',
            zIndex: 1,
        },
        mobileSearchPaneliOS: {
            background: theme.palette.neutral[6],
        },
        menuIcon: {
            fontSize: 20,
            color: theme.palette.neutral[50],
        },
        toolbarIcon: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            padding: 0,
        },
        toolbarItem: {
            alignItems: 'center',
            display: 'flex',
            '&[data-ismobile="true"]': {
                flex: 1,
            },
        },
        appBar: {
            backgroundColor: theme.palette.common.white,
            boxShadow: `0px 2px 2px ${fade(theme.palette.neutral.main, 0.16)}`,
            position: 'relative',
            transition: theme.transitions.create(['width', 'margin'], {
                duration: theme.transitions.duration.leavingScreen,
                easing: theme.transitions.easing.sharp,
            }),
            zIndex: theme.zIndex.drawer + 2,
        },
        appBariOS: {
            backgroundColor: theme.palette.neutral[6],
            boxShadow: 'none',
            color: theme.palette.neutral[75],
        },
        toolbar: {
            display: 'flex',
            height: theme.spacing(6),
            justifyContent: 'space-between',
            minHeight: theme.spacing(6),
            padding: '0 16px',
            '&[data-ismobile="true"]': {
                flex: 1,
            },
        },
        toolbariOS: {
            paddingLeft: 40,
        },
        searchBox: {
            width: 360,
            '&[data-ismobile="true"]': {
                border: 'none',
                width: '100%',
            },
        },
        searchBoxOS: {
            background: theme.palette.white.main,
            '&[data-ismobile="true"]': {
                border: `1px solid ${theme.palette.neutral[12]}`,
            },
        },
        searchIcon: {
            color: theme.palette.neutral[50],
        },
        appMode: {
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
        },
        indicator: {
            color: theme.palette.green.main && lighten(theme.palette.green.main, 0.4),
            fontSize: 24,
            lineHeight: '10px',
        },
    }),
);
