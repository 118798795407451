import { Converter } from '../location.types';

export const activeCardConverter: Converter = {
    URLToState: url => ({ activeCardId: url.hash }),
    stateToURL: state => {
        if (state.activeCardId)
            return {
                hash: state.activeCardId.toString(),
            };
    },
};
