import { Category } from '../../../redux/content/category.types';

export const CARDS_WIDTHS: Record<Category, number> = {
    tasks: 336,
    forms: 336,
    dashboards: 336,
};

export const CARDS_MAX_WIDTHS: Record<Category, number> = {
    tasks: 646,
    forms: 646,
    dashboards: 646,
};

export const CARDS_HEIGHTS: Record<Category, number> = {
    tasks: 140,
    forms: 140,
    dashboards: 180,
};

export const DEFAULT_PADDING = 8;
export const CARDS_PADDING = 16;
export const GRID_PADDING = 24;
export const GRID_MOBILE_PADDING = 20;

export const MIN_CARDS_IN_ROW = 3;

export const MIN_CARDS_CONTAINER_WIDTH =
    MIN_CARDS_IN_ROW * CARDS_WIDTHS.tasks + CARDS_PADDING * (MIN_CARDS_IN_ROW - 1) + CARDS_PADDING;

export const TASK_CARD_CONTENT_WIDTH = CARDS_WIDTHS.tasks - CARDS_PADDING * 2;
