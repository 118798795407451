import {
    ChangeContentMetaAction,
    ClearContentAction,
    LoadedContentAction,
    TasksContentTab,
} from '../content/content.types';
import { OpenPopupAction } from '../tasksActions/tasksActions.types';
import { OpenPopupAction as OpenResponsePopupAction } from '../responsesActions/responsesActions.types';
import { GoToVotingAction } from '../voting/voting.types';
import { Category } from '../content/category.types';

export const ACTIVE_CARD_CLEAR = 'ActiveCard/CLEAR';
export const OPEN_TASK_DETAILS_VIEW = 'ActiveCard/OPEN_TASK_DETAILS_VIEW';
export const CLOSE_TASK_DETAILS_VIEW = 'ActiveCard/CLOSE_TASK_DETAILS_VIEW';
export const CHANGE_TASK_CONTENT_TAB = 'ActiveCard/CHANGE_TASK_CONTENT_TAB';
export const SWITCHED_TO_TASK_HISTORY = 'ActiveCard/SWITCHED_TO_TASK_HISTORY';
export const TASK_HISTORY_LOADED = 'ActiveCard/TASK_HISTORY_LOADED';

type CreatedTaskHistoryItem = {
    created: true;
    fields: { type: string; label?: string; value?: string }[];
};
type UpdatedTaskHistoryItem = {
    created: false;
    type: string;
    label?: string;
    previous?: string;
    current?: string;
};
export type TaskHistoryItem = (CreatedTaskHistoryItem | UpdatedTaskHistoryItem) & {
    key: string;
    user: string;
    date: string;
};

export interface ClearActiveCardAction {
    type: typeof ACTIVE_CARD_CLEAR;
}

export interface OpenTaskDetailViewAction {
    type: typeof OPEN_TASK_DETAILS_VIEW;
    taskId: number;
}

export interface CloseTaskDetailViewAction {
    type: typeof CLOSE_TASK_DETAILS_VIEW;
}

export interface ChangeTaskContentTabAction {
    type: typeof CHANGE_TASK_CONTENT_TAB;
    tab: TasksContentTab;
}

export interface SetTaskHistoryLoadingAction {
    type: typeof SWITCHED_TO_TASK_HISTORY;
}

export interface TaskHistoryLoadedAction {
    type: typeof TASK_HISTORY_LOADED;
    taskHistory: TaskHistoryItem[];
}

export type ActiveCardState = {
    category: Category;
    cardId: string | number;
    taskDetailView?: boolean;
    taskContentTab?: TasksContentTab;
    taskHistory?: TaskHistoryItem[];
    switchedToTaskHistory?: boolean;
} | null;

export type ActiveCardAction =
    | ChangeContentMetaAction
    | ClearContentAction
    | OpenTaskDetailViewAction
    | CloseTaskDetailViewAction
    | OpenPopupAction
    | GoToVotingAction
    | LoadedContentAction
    | ClearActiveCardAction
    | OpenResponsePopupAction
    | ChangeTaskContentTabAction
    | SetTaskHistoryLoadingAction
    | TaskHistoryLoadedAction;
