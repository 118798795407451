import { PortalId } from '../redux/login/login.types';
import { fromString, portalIdToString } from '../redux/login/portalId';

const LAST_PORTAL_ID = 'portalId'; // same as the old portal
const AUTHENTICATED = 'accessToken';
const LAST_BRAND_COLOR = 'brandColor';

const setLastPortalId = (portalId: PortalId): void => {
    localStorage.setItem(LAST_PORTAL_ID, portalIdToString(portalId));
};

const getLastPortalId = (): PortalId => {
    const portalId = localStorage.getItem(LAST_PORTAL_ID);
    return portalId ? fromString(portalId) : null;
};

// this hack is needed to interop with the old portal
const setAuthenticated = (): void => {
    localStorage.setItem(AUTHENTICATED, ' ');
};

const removeAuthenticated = (): void => {
    localStorage.removeItem(AUTHENTICATED);
};

const getLastBrandColor = (): string | undefined => {
    return localStorage.getItem(LAST_BRAND_COLOR) || undefined;
};

const setLastBrandColor = (brandColor: string): void => {
    localStorage.setItem(LAST_BRAND_COLOR, brandColor);
};

export {
    setLastPortalId,
    getLastPortalId,
    setAuthenticated,
    removeAuthenticated,
    getLastBrandColor,
    setLastBrandColor,
};
