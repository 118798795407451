import { AppState } from '../AppState.types';

export const getOnlineIniOsFeature = (state: AppState): boolean =>
    !!state.features?.online_in_iOS_opx202;

export const getTaskCalendarMonthFeature = (state: AppState): boolean =>
    !!state.features?.calendar_month_portal_opx142;

export const getTaskCalendarWeekFeature = (state: AppState): boolean =>
    !!state.features?.calendar_week_portal_opx142;

export const getTaskCalendarDayFeature = (state: AppState): boolean =>
    !!state.features?.calendar_day_portal_opx142;

export const getTaskCalendarFeature = (state: AppState): boolean =>
    getTaskCalendarMonthFeature(state) ||
    getTaskCalendarWeekFeature(state) ||
    getTaskCalendarDayFeature(state);

export const getAlwaysShowStartButtonFeature = (state: AppState): boolean =>
    !!state.features?.portal3_always_show_start_form;

export const getFilterByTDFeature = (state: AppState): boolean =>
    !!state.features?.TD_filtering_portal_opx484;

export const getTaskHistoryFeature = (state: AppState): boolean =>
    !!state.features?.task_history_portal_opx488;

export const getOptimizeTasksLoadFeature = (state: AppState): boolean =>
    !!state.features?.optimize_tasks_load_opx506;

export const getOptimizeFormsTitleLoadFeature = (state: AppState): boolean =>
    !!state.features?.optimize_forms_title_load_opx540;

export const getNoAllTasksFeature = (state: AppState): boolean =>
    !!state.features?.portal_all_task_experiment_opx540;

export default {
    getFilterByTDFeature,
    getOptimizeTasksLoadFeature,
    getOptimizeFormsTitleLoadFeature,
    getNoAllTasksFeature,
};
