import React, { useCallback, useState } from 'react';
import { Grid } from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useResizeObserver from '@react-hook/resize-observer';
import FormResponseCard from '../../ui/cards/FormResponseCard';
import {
    cardSelector,
    getContextSearchQuery,
    isSearchQueryNotEmpty,
} from '../../../redux/content/contentSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { responsesActionsCreator } from '../../../redux/responsesActions/responsesActions';
import { votingCreator } from '../../../redux/voting/voting';
import DeleteResponse from '../../Actions/DeleteResponse/DeleteResponse';
import { ResponseCardsGridProps } from './CardsGrid.types';
import { getContentJustifyValue, isFetchedResponses } from '../../../redux/content/content.utils';
import { AppState } from '../../../redux/AppState.types';
import NothingFound from '../../ui/nothingFound/NothingFound';
import { selectActiveResponseId } from '../../../redux/activeCard/activeCardSelectors';
import { getVotingCreatedItemId } from '../../../redux/voting/votingSelector';
import { isDeleteResponseActive } from '../../../redux/responsesActions/responsesActionsSelector';
import {
    CARDS_MAX_WIDTHS,
    CARDS_PADDING,
    CARDS_WIDTHS,
    GRID_MOBILE_PADDING,
    GRID_PADDING,
} from '../../ui/cards/cardsSizes';
import { isMobile } from '../../../utils/browser.utils';
import { JustifyValue } from '../../../redux/content/content.types';

const mapDispatchToProps = mapCreatorsToDispatchProps({
    openResponseAction: responsesActionsCreator.openActionPopup,
    openResponse: votingCreator.openResponse,
    copyResponse: votingCreator.copyResponse,
});

export const mapStateToProps = (state: AppState): ResponseCardsGridProps => {
    const { content, forms, responsesActions } = state;
    if (isFetchedResponses(content) && content.itemId) {
        const activeFormId = content.itemId;
        const activeForm = forms.forms.find(form => form.id === activeFormId);
        const allowDeleteResponses = activeForm && activeForm.settings?.allowDeleteResponses;
        const allowCopyResponses = activeForm && activeForm.settings?.allowCopyResponses;
        const allowEditResponses = activeForm && activeForm.settings?.allowEditResponses;
        const searchQuery = getContextSearchQuery(state);
        return {
            allowDeleteResponses,
            allowCopyResponses,
            allowEditResponses,
            cards: content.items.map(
                cardSelector.cardFromResponse(
                    activeFormId,
                    searchQuery,
                    selectActiveResponseId(state) || undefined,
                    getVotingCreatedItemId(state) || undefined,
                    isDeleteResponseActive(state),
                ),
            ),
            nothingFound: !content.hasMore && !content.isLoading && !content.items.length,
            isActionLoading: responsesActions.isLoading || false,
            hasSearchQuery: isSearchQueryNotEmpty(state),
        };
    }
    return { cards: [], nothingFound: false, isActionLoading: false, hasSearchQuery: false };
};

type CardStateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        parentDiv: HTMLDivElement | null;
        justifyFunction: (a: JustifyValue) => void;
    };

export const ResponseCardsGridComponent = (props: CardStateProps): JSX.Element => {
    const {
        cards,
        nothingFound,
        openResponseAction,
        allowDeleteResponses,
        allowCopyResponses,
        allowEditResponses,
        openResponse,
        copyResponse,
        isActionLoading,
        hasSearchQuery,
        parentDiv,
        justifyFunction,
    } = props;
    const { t } = useTranslation();
    const gridPadding = isMobile ? GRID_MOBILE_PADDING : GRID_PADDING;

    const [parentWidth, setParentWidth] = useState(0);

    const updateParentWidth = useCallback(() => {
        const newParentWidth = parentDiv?.offsetWidth || 0;
        setParentWidth(newParentWidth);
        const newAlign = getContentJustifyValue(
            newParentWidth ? newParentWidth - 2 * GRID_PADDING : 0,
            'forms',
        );
        justifyFunction(newAlign);
    }, [parentDiv, justifyFunction]);

    useResizeObserver(parentDiv, () => updateParentWidth());

    const width = parentWidth || CARDS_WIDTHS.forms;
    const cardWidthWithPaddings = CARDS_WIDTHS.forms + CARDS_PADDING;
    const widthWithoutPaddings = width - 2 * gridPadding + CARDS_PADDING;
    const itemsPerRow = Math.floor(widthWithoutPaddings / cardWidthWithPaddings) || 1;
    let cardWidth = Math.min(
        widthWithoutPaddings / itemsPerRow - CARDS_PADDING,
        CARDS_MAX_WIDTHS.forms,
    );

    cardWidth = cardWidth < CARDS_WIDTHS.forms ? CARDS_WIDTHS.forms : cardWidth;

    if (nothingFound)
        return (
            <NothingFound
                hasSearchQuery={hasSearchQuery}
                label={t('ResponseCardsGrid.NoResponses')}
            />
        );

    const cardsGrid = cards.map(card => {
        const onDelete =
            allowDeleteResponses || !card.isCompleted
                ? () => openResponseAction('DeleteResponse', card.activeFormId, card.id)
                : undefined;

        const onCopy = allowCopyResponses ? () => copyResponse(card.id) : undefined;

        const onEdit =
            !card.isCompleted || (card.isCompleted && allowEditResponses)
                ? () => openResponse(card.id, 'edit')
                : undefined;

        const cardProps = {
            onClick: () => openResponse(card.id, 'view'),
            onEdit,
            onCopy,
            onDelete,
            isActionLoading,
            isCompleted: card.isCompleted,
            title: card.title,
            updated: card.updated,
            isActive: card.isActive,
            searchQuery: card.searchQuery,
            shouldHighlight: card.shouldHighlight,
            isDeleteResponseActive: card.isDeleteResponseActive,
        };

        return (
            <Grid item={true} key={card.id}>
                <FormResponseCard
                    {...cardProps}
                    data-testid={`card-response_id-${card.id}`}
                    width={cardWidth}
                />
            </Grid>
        );
    });
    return (
        <>
            {cardsGrid}
            <DeleteResponse />
        </>
    );
};

export const ResponseCardsGrid = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResponseCardsGridComponent);
