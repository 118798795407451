import { connect } from 'react-redux';
import React from 'react';
import { FilterButton, makeStyles } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import {
    getQuickFilterButtons,
    TasksQuickFiltersButton,
} from '../../redux/contentQuickFilters/contentQuickFiltersSelector';
import { TaskQuickFilters } from '../../redux/contentQuickFilters/contentQiuckFilters.types';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { tasksConfigurableFiltersCreator } from '../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import { getActivePredefinedFilters } from '../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { AppState } from '../../redux/AppState.types';

const useStyles = makeStyles(() => ({
    filterButton: {
        flexShrink: 0,
    },
}));

export interface PredefinedFiltersListProps {
    predefinedFilters: TaskQuickFilters | null;
    predefinedFiltersButtons: TasksQuickFiltersButton[];
    togglePredefinedFilter: (filter: keyof TaskQuickFilters) => void;
}

const mapStateToProps = (state: AppState) => {
    return {
        predefinedFilters: getActivePredefinedFilters(state),
        predefinedFiltersButtons: getQuickFilterButtons(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    togglePredefinedFilter: tasksConfigurableFiltersCreator.togglePredefinedFilter,
});

export const PredefinedFiltersListComponent = (props: PredefinedFiltersListProps): JSX.Element => {
    const { predefinedFilters, predefinedFiltersButtons, togglePredefinedFilter } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const handleFilterButtonClick = (filterKey: keyof TaskQuickFilters) => () =>
        togglePredefinedFilter(filterKey);

    const filters = predefinedFiltersButtons.map(({ key, text, testId }) => (
        <FilterButton
            isChecked={!!(predefinedFilters && predefinedFilters[key])}
            key={key}
            data-testid={testId}
            onClick={handleFilterButtonClick(key)}
            className={classes.filterButton}
        >
            {t(text)}
        </FilterButton>
    ));

    return <>{filters}</>;
};

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedFiltersListComponent);
