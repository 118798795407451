import React from 'react';
import {
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    lighten,
    makeStyles,
    Radio,
    Theme,
} from 'worldapp-ui/shared';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import MoreButton from '../../ui/buttons/MoreButton';
import { useIsMobileScreenWidth } from '../../../hooks/useIsMobileScreenWidth';
import {
    getActiveFiltersSetName,
    getSavedFilterSetsAsFiltersSetsItems,
} from '../../../redux/tasksConfigurableFilters/tasksConfigurableFiltersSelector';
import { mapCreatorsToDispatchProps } from '../../../utils/redux.utils';
import { tasksConfigurableFiltersCreator } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters';
import { FiltersSetsContentItem } from '../../../redux/tasksConfigurableFilters/tasksConfigurableFilters.types';
import { AppState } from '../../../redux/AppState.types';

export interface FiltersSetsContentProps {
    checkedFiltersSetName: string | null;
    items: FiltersSetsContentItem[];
    deleteSavedFiltersSet: (filtersSetName: string) => void;
    editSavedFiltersSet: (filtersSetName: string) => void;
    openMessageBox: () => void;
    setGeneralAsActive: () => void;
    setSavedAsActive: (filtersSetName: string) => void;
}

const GENERAL_FILTERS_SET_ID = 'general_filters_set_id';

/* istanbul ignore next */
const mapStateToProps = (state: AppState) => ({
    checkedFiltersSetName: getActiveFiltersSetName(state),
    items: getSavedFilterSetsAsFiltersSetsItems(state),
});

const mapDispatchToProps = mapCreatorsToDispatchProps({
    deleteSavedFiltersSet: tasksConfigurableFiltersCreator.deleteSavedFiltersSet,
    editSavedFiltersSet: tasksConfigurableFiltersCreator.editSavedFiltersSet,
    setGeneralAsActive: tasksConfigurableFiltersCreator.setGeneralAsActiveSet,
    setSavedAsActive: tasksConfigurableFiltersCreator.setSavedAsActiveSet,
});

const useFiltersSetsStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 40,
        marginBottom: theme.spacing(0.5),
    },
    control: {},
    label: {
        fontSize: theme.typography.body1.fontSize,
    },
    labelSelected: {
        color: theme.palette.primary.main,
    },
    taskNumber: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.neutral[65],
        marginTop: theme.spacing(0.5),
    },
    actions: {
        width: 44,
        height: 42,
    },
    formControlLabelRoot: {
        borderRadius: theme.spacing(0.5),
        color: theme.palette.neutral[75],
        width: '100%',
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.9),
            color: theme.palette.neutral[75],
        },
    },
    gridItem: {
        flex: 1,
    },
    icon: {
        color: theme.palette.primary.main,
    },
    buttonContainer: {
        display: 'flex',
    },
    buttonDelete: {
        marginRight: 8,
    },
    iconDelete: {
        color: theme.palette.rubyRed.main,
    },
    iconEdit: {
        color: theme.palette.primary.main,
    },
}));

export const FiltersSetsContentComponent = (props: FiltersSetsContentProps): JSX.Element => {
    const {
        checkedFiltersSetName,
        items,
        deleteSavedFiltersSet,
        editSavedFiltersSet,
        openMessageBox,
        setGeneralAsActive,
        setSavedAsActive,
    } = props;
    const classes = useFiltersSetsStyles();
    const isMobile = useIsMobileScreenWidth();
    const { t } = useTranslation();

    const handleSelectSavedFiltersSet =
        (filterSetName: string) =>
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            if (checked) {
                setSavedAsActive(filterSetName);
            }
        };

    const handleSelectGeneralFilters = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setGeneralAsActive();
        }
    };

    const handleSaveGeneralFilters = () => {
        setGeneralAsActive();
        openMessageBox();
    };

    const handleEditSavedFiltersSet = (filterSetName: string) => () => {
        editSavedFiltersSet(filterSetName);
    };

    const handleRemoveSavedFiltersSet = (filtersSetName: string) => () => {
        deleteSavedFiltersSet(filtersSetName);
    };

    const isGeneralSelected = checkedFiltersSetName === null;

    return (
        <div className={classes.wrapper}>
            <Grid
                container={true}
                key={GENERAL_FILTERS_SET_ID}
                justify={'space-between'}
                spacing={1}
            >
                <Grid item={true} className={classes.gridItem}>
                    <FormControlLabel
                        id={'selected_general_filters_btn'}
                        control={
                            <Radio
                                color={'primary'}
                                checked={isGeneralSelected}
                                onChange={handleSelectGeneralFilters}
                            />
                        }
                        label={
                            <div
                                className={classNames(
                                    classes.label,
                                    isGeneralSelected && classes.labelSelected,
                                )}
                            >
                                {t('TaskFilters.SelectedGeneralFilters')}
                            </div>
                        }
                        data-testid={'filters_set__selected_general_filters'}
                        classes={{ root: classes.formControlLabelRoot }}
                    />
                </Grid>

                <Grid item={true} className={classes.actions}>
                    <IconButton
                        icon={<Icon name="save" size="l" mode="stroke" className={classes.icon} />}
                        id={'save_general_filter_btn'}
                        onClick={handleSaveGeneralFilters}
                        data-testid={'filters_set__save_general_filter_btn'}
                        title={t('TaskFilters.SaveSelectedGeneralFilters')}
                    />
                </Grid>
            </Grid>
            {items.map(item => {
                const isChecked = item.id === checkedFiltersSetName;
                return (
                    <Grid container={true} key={item.id} justify={'space-between'} spacing={1}>
                        <Grid item={true} className={classes.gridItem}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        color={'primary'}
                                        checked={isChecked}
                                        onChange={handleSelectSavedFiltersSet(item.id)}
                                    />
                                }
                                label={
                                    <div
                                        className={classNames(
                                            classes.label,
                                            isChecked && classes.labelSelected,
                                        )}
                                    >
                                        {item.label}
                                    </div>
                                }
                                data-testid={`filters_set__${item.id}`}
                                classes={{ root: classes.formControlLabelRoot }}
                            />
                        </Grid>

                        <Grid item={true} className={classes.actions}>
                            {isMobile ? (
                                <MoreButton
                                    menuActions={[
                                        {
                                            name: t('TaskFilters.EditButton'),
                                            onClick: handleEditSavedFiltersSet(item.id),
                                        },
                                        {
                                            name: t('TaskFilters.RemoveButton'),
                                            onClick: handleRemoveSavedFiltersSet(item.id),
                                        },
                                    ]}
                                    data-testid={`filters_set__more_btn_${item.id}`}
                                />
                            ) : (
                                <div className={classes.buttonContainer}>
                                    <div className={classes.buttonDelete}>
                                        <IconButton
                                            icon={
                                                <Icon
                                                    name="trash"
                                                    size="l"
                                                    mode="stroke"
                                                    className={classes.iconDelete}
                                                />
                                            }
                                            onClick={handleRemoveSavedFiltersSet(item.id)}
                                            data-testid={`filters_set__remove_button_${item.id}`}
                                            title={t('TaskFilters.DeleteFilterSet')}
                                        />
                                    </div>
                                    <IconButton
                                        icon={
                                            <Icon
                                                name="edit"
                                                size="l"
                                                mode="stroke"
                                                className={classes.iconEdit}
                                            />
                                        }
                                        onClick={handleEditSavedFiltersSet(item.id)}
                                        data-testid={`filters_set__edit_button_${item.id}`}
                                        title={t('TaskFilters.EditFilterSet')}
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                );
            })}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersSetsContentComponent);
