import React, { FC } from 'react';
import { createStyles, Text, makeStyles, lighten, Tooltip } from 'worldapp-ui';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { User12Icon } from 'worldapp-ui/shared/icons';
import { Task } from '../../../redux/content/content.types';
import { getTaskDefinitionsById } from '../../../redux/taskDefinitions/taskDefinitionsSelector';
import { getDueDate, getStatus } from '../../../redux/content/contentSelector';
import { getCalendarView } from '../../../redux/calendar/calendarSelector';

interface CalendarTaskCardProps {
    task: Task;
    disableTootip?: boolean;
}

interface CalendarTaskCardInnerProps {
    task: Task;
}

const CalendarTaskCardStyles = makeStyles(theme =>
    createStyles({
        taskCard: {
            color: theme.palette.neutral.main,
            borderRadius: 4,
            padding: '0 8px 0 6px',
            borderLeft: `6px solid ${lighten(theme.palette.primary.main, 0.6)}`,
            background: `${theme.palette.white.main}`,
            '& > *': {
                lineHeight: '14px',
            },
        },
        taskCardOver: {
            borderColor: `${lighten(theme.palette.error.main, 0.6)}`,
        },
        taskCardClosed: {
            borderColor: theme.palette.green.main && lighten(theme.palette.green.main, 0.6),
        },
        tooltipContainer: {
            padding: '4px 8px',
        },
        tooltipTitle: {
            color: theme.palette.neutral.main,
            height: theme.spacing(4),
            display: '-webkit-box',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            wordWrap: 'break-word',
            marginBottom: theme.spacing(1.5),
        },
        tooltipTaskDefinition: {
            color: `${theme.palette.neutral[65]}`,
            marginBottom: theme.spacing(1.5),
            marginTop: theme.spacing(1.5),
        },

        tooltipAssigneeLabelContainer: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.neutral[75],
            whiteSpace: 'nowrap',
            marginTop: theme.spacing(1.5),
        },
        icon12: {
            width: 12,
            height: 12,
            fontSize: 12,
            marginRight: 8,
        },
        tooltipAssigneeLabel: {
            ...theme.mixins.truncate,
        },
        taskWeekCard: {
            padding: '6px 0',
            color: theme.palette.neutral[65],
        },
        taskWeekCardHeader: {
            marginBottom: 4,
            color: theme.palette.neutral.main,
        },
    }),
);

export const CalendarTaskCardInner: FC<CalendarTaskCardInnerProps> = ({ task }) => {
    const classes = CalendarTaskCardStyles();
    const { t } = useTranslation();
    const calendarView = useSelector(getCalendarView);
    const taskDefinitionsById = useSelector(getTaskDefinitionsById);
    const taskDefinition = taskDefinitionsById[task.taskDefinitionId];

    if (calendarView === 'month') {
        return (
            <Text variant="r12r" noWrap>
                {task.summary}
            </Text>
        );
    }

    return (
        <div className={classes.taskWeekCard}>
            <Text variant="r14r" noWrap className={classes.taskWeekCardHeader}>
                {task.summary}
            </Text>
            <Text variant="r12r" noWrap>
                {taskDefinition!.title}
            </Text>
            <Text variant="r12r" noWrap>
                {task.assignee?.name ?? t('TaskCard.Unassigned')}
            </Text>
        </div>
    );
};

export const CalendarTaskCard: FC<CalendarTaskCardProps> = ({ task, disableTootip }) => {
    const classes = CalendarTaskCardStyles();
    const { t } = useTranslation();
    const taskDefinitionsById = useSelector(getTaskDefinitionsById);
    const taskDefinition = taskDefinitionsById[task.taskDefinitionId];

    const { isClosed: isStatusClosed = undefined } = getStatus(task.statusId, taskDefinition) || {};

    const dueDate = getDueDate(task, isStatusClosed);
    const isOverdue = dueDate.dueDateStatus === 'overdue';

    const cardClass = classes.taskCard;
    const cardClassOver = isOverdue ? classes.taskCardOver : ' ';
    const cardClassClosed = isStatusClosed ? classes.taskCardClosed : ' ';
    return disableTootip ? (
        <div
            className={classNames(cardClass, cardClassOver, cardClassClosed)}
            data-testid="calendar-task-card"
        >
            <CalendarTaskCardInner task={task} />
        </div>
    ) : (
        <div
            className={classNames(cardClass, cardClassOver, cardClassClosed)}
            data-testid="calendar-task-card"
        >
            <Tooltip
                disableTouchListener
                maxWidth={360}
                title={
                    <>
                        <div className={classes.tooltipContainer}>
                            <Text variant="r14r" className={classes.tooltipTitle}>
                                {task.summary}
                            </Text>
                            <Text variant="r12m" noWrap className={classes.tooltipTaskDefinition}>
                                {taskDefinition!.title}
                            </Text>
                            <div className={classes.tooltipAssigneeLabelContainer}>
                                <User12Icon className={classes.icon12} />
                                <Text
                                    variant="r12r"
                                    noWrap
                                    className={classes.tooltipAssigneeLabel}
                                >
                                    {task.assignee?.name ?? t('TaskCard.Unassigned')}
                                </Text>
                            </div>
                        </div>
                    </>
                }
            >
                <div>
                    <CalendarTaskCardInner task={task} />
                </div>
            </Tooltip>
        </div>
    );
};
