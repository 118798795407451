import { createStyles, Theme, makeStyles, fade } from 'worldapp-ui/shared';

export const taskDetailViewStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: `0 ${theme.spacing(4)}px`,
            overflow: 'auto',
        },
        img: {
            maxHeight: theme.spacing(5),
            width: '100%',
            objectFit: 'contain',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },
        alignCenter: {
            textAlign: 'center',
        },
        columnElementLeft: {
            minWidth: '30%',
        },
        columnElementRight: {
            minWidth: '70%',
        },
        title: {
            color: theme.palette.neutral.main,
        },
        tdName: {
            color: theme.palette.neutral[65],
        },
        tdTitle: {
            color: theme.palette.neutral.main,
            padding: '8px 0 16px',
        },
        tdColumnElementLeft: {
            minWidth: 32,
            flexGrow: 0,
        },
        tdValueRow: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(),
        },
        tdFieldValue: {
            color: theme.palette.neutral.main,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        tdDescriptionValue: {
            marginBottom: 16,
        },
        fieldName: {
            paddingBottom: theme.spacing(),
            color: theme.palette.neutral[65],
            wordBreak: 'break-word',
        },
        fieldValue: {
            color: theme.palette.neutral.main,
            paddingBottom: theme.spacing(),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        error: {
            color: theme.palette.rubyRed.main,
        },
        valueRow: {
            marginBottom: theme.spacing(),
            flexShrink: 0,
        },
        description: {
            color: theme.palette.neutral.main,
            marginBottom: theme.spacing(4),
        },
        divider: {
            backgroundColor: theme.palette.primary.main,
        },
        outerGrid: {
            overflow: 'auto',
            height: '100%',
        },
        mainActionButton: {
            marginBottom: theme.spacing(4),
        },
        ieFix: {
            '-ms-flex-negative': 0,
        },
        root: {},
        paper: {
            width: 580,
            [theme.breakpoints.down('xs')]: {
                width: 'calc(100% - 48px)',
            },
            maxWidth: 'unset',
            height: '100%',
            maxHeight: 600,
            margin: 0,
            borderRadius: 0,
            background: 'transparent',
            boxShadow: 'none',
            overflow: 'unset',
        },
        content: {
            paddingTop: theme.spacing(7),
        },
        icon: {
            width: 36,
            height: 36,
            fontSize: 36,
        },
        iconBtn: {
            color: theme.palette.white.main,
            display: 'flex',
            alignSelf: 'center',
            position: 'absolute',
            '& svg': {
                width: 32,
                height: 32,
                fontSize: 32,
            },
        },
        iconBtnDisabled: {
            pointerEvents: 'none',
            '& svg': {
                color: theme.palette.neutral[30],
            },
        },
        iconBtnPrev: {
            left: 'calc(50% - 358px)',
        },
        iconBtnNext: {
            right: 'calc(50% - 358px)',
            transform: 'rotate(180deg)',
        },
        tdOuterGrid: {
            height: '100%',
            borderRadius: 8,
            overflow: 'hidden',
        },
        tdOuterGridLoading: {
            background: theme.palette.white.main,
        },
        tdContainer: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
            borderRadius: 8,
            boxShadow: `0px 2px 4px  ${fade(theme.palette.neutral.main, 0.25)}, 
                    0px 2px 2px  ${fade(theme.palette.neutral.main, 0.24)}`,
        },
        tdHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexShrink: 0,
            height: 60,
            padding: '0 16px 0 24px',
            boxSizing: 'border-box',
            borderBottom: `1px solid ${theme.palette.neutral[12]}`,
        },
        tdHeaderIcon: {
            display: 'flex',
            alignSelf: 'center',
            marginLeft: 'auto',
            justifyContent: 'flex-end',
        },
        tdBody: {
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            touchAction: 'pan-y',
            borderRadius: 8, // IOS
        },
        tdActionSection: {
            padding: '12px 24px',
        },
        tdDescriptionSection: {
            padding: 24,
            background: theme.palette.neutral[6],
            flexGrow: 1,
        },
        tdDescriptionTitle: {
            color: theme.palette.neutral.main,
            paddingBottom: 8,
        },
        placeholderBlock: {
            marginTop: 8,
            marginBottom: 8,
        },
        placeholderBlockMiddle: {
            marginTop: 12,
            marginBottom: 12,
        },
        actionBtnWrapperForcedActive: {
            background: theme.palette.accent[16],
            borderRadius: 4,
        },
        disabled: {
            color: theme.palette.neutral[65],
        },
        actionBtnContainer: {
            marginLeft: 12,
        },
        animationWrapper: {
            width: '100%',
            background: theme.palette.white.main,
            borderRadius: 8,
        },
        animationWrapperChild: {
            height: '100%',
        },
        carouselBackdrop: {
            position: 'fixed',
            width: '100vw',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: theme.zIndex.drawer + 2,
            background: 'rgba(0, 0, 0, 0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        carouselCardWrapper: {
            width: 580,
            height: '70%',
            touchAction: 'pan-y',
            position: 'absolute',
            '&[data-ismobile="true"]': {
                width: 'calc(100% - 48px)',
                height: 'calc(100% - 48px)',
            },
        },
        carouselCardContentWrapper: {
            height: '100%',
            display: 'flex',
        },
        placeholderRoot: {
            height: '100%',
            background: theme.palette.white.main,
            borderRadius: 8,
        },
        tabControl: {
            flexGrow: 0,
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
        },
        thTitle: {
            color: theme.palette.neutral.main,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        thAccentText: {
            fontWeight: 500,
        },
        thFieldText: {
            color: theme.palette.neutral[75],
        },
        thDateText: {
            color: theme.palette.neutral[75],
            float: 'right',
        },
        thRow: { display: 'flex' },
        thRowNav: {
            width: 16,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 0,
            flexShrink: 0,
        },
        thBullet: {
            height: 8,
            width: 8,
            margin: '8px 4px 4px 4px',
            backgroundColor: theme.palette.neutral[50],
            borderRadius: '50%',
        },
        thLine: {
            borderLeft: `1px solid ${theme.palette.neutral[50]}`,
            margin: '4px 0px 4px 6.5px',
            flexGrow: 1,
        },
        thRowContent: {
            padding: 4,
            flexGrow: 1,
        },
        thDate: {
            paddingLeft: 4,
            float: 'right',
        },
        thField: {
            paddingTop: 4,
        },
    }),
);
