import { stateToURL, URLToState } from 'worldapp-fe-utils';
import { Converter } from '../location.types';
import { defaultNavigationConverter } from './defaultNavigationConverter';
import { portalIdConverter } from './portalIdConverter';
import { loginPageConverter } from './loginPageConverter';
import { submitConverter } from './submitConverter';
import { categoryConverter } from './portal2/categoryConverter';
import { loginConverter } from './portal2/loginConverter';
import { rURLConverter as rURLConverter2 } from './portal2/rURLConverter';
import { rURLConverter } from './rURLConverter';
import { newResponseParamsConverter } from './newResponseParamsConverter';
import { isBackUrlConverter } from './isBackUrlConverter';
import { respondentIdConverter } from './respondentIdConverter';
import { resultHasBeenSavedConverter } from './resultHasBeenSavedConverter';

const converters: Converter[] = [
    defaultNavigationConverter,
    portalIdConverter,
    loginPageConverter,
    submitConverter,
    rURLConverter,
    newResponseParamsConverter,
    isBackUrlConverter,
    respondentIdConverter,
    resultHasBeenSavedConverter,
];

export const portal2Converters: Converter[] = [loginConverter, categoryConverter, rURLConverter2];

export const portalVersionConverter: Converter = {
    URLToState: url => {
        const portalVersion = url.query.portalVersion?.[0];
        return URLToState(portalVersion === '2' ? portal2Converters : converters)(url);
    },
    stateToURL: state => stateToURL(converters)(state),
};
