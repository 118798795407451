import { makeStyles, fade, Theme } from 'worldapp-ui/shared';
import { CARDS_HEIGHTS } from './cardsSizes';

export const cardStyles = makeStyles<Theme, { cardWidth?: number }>(theme => ({
    cardWrapper: {
        position: 'relative',
        display: ' flex',
    },
    card: {
        border: `1px solid ${theme.palette.neutral[12]}`,
        borderRadius: theme.spacing(),
        boxShadow: 'none',
    },
    cardWithAction: {
        cursor: 'pointer',
        '&:hover': {
            borderColor: `${fade(theme.palette.primary.main, 0.5)}`,
            boxShadow: `0px 2px 4px ${fade(theme.palette.neutral.main, 0.25)}`,
        },
        '-moz-user-select': 'none',
        '-webkit-user-select': 'none',
        ' -ms-user-select': 'none',
        'user-select': 'none',
    },

    cardCloses: {
        background: theme.palette.neutral[12],
    },

    cardHeader: {
        alignItems: 'center',
        paddingTop: 12,
        paddingBottom: 8,
        position: 'relative',
    },
    cardFooter: {
        position: 'relative',
        paddingTop: 4,
    },
    title: {
        color: theme.palette.neutral.main,
        height: theme.spacing(4),
        display: '-webkit-box',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        wordWrap: 'break-word',
    },
    highlighted: {
        animationDuration: '7s',
        animationDelay: '0s',
        animationName: `$highlight`,
        animationTimingFunction: 'ease-in',
    },

    // Response card
    responseCard: {
        width: props => props?.cardWidth,
        height: CARDS_HEIGHTS.forms,
    },
    cardContent: {
        height: theme.spacing(16),
        paddingBottom: theme.spacing(),
        paddingTop: 0,
        cursor: 'pointer',
    },

    // Task card
    taskCard: {
        width: props => props?.cardWidth,
        height: CARDS_HEIGHTS.tasks,
    },

    taskCardContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 12,
    },
    label: {
        color: theme.palette.neutral[75],
    },
    labelOverdue: {
        color: `${theme.palette.rubyRed.main}`,
    },
    marginRight: {
        marginRight: theme.spacing(0.5),
    },
    assigneeLabelContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 12,
        color: theme.palette.neutral[75],
        maxWidth: 170,
    },
    assigneeLabel: {
        ...theme.mixins.truncate,
    },
    active: {
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
    },
    selected: {
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
        background: fade(theme.palette.primary.main, 0.05),
    },
    inProgress: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    displayFlex: {
        display: 'flex',
        paddingBottom: 12,
    },
    taskDefinitionLabel: {
        color: `${theme.palette.neutral[75]}`,
    },

    icon12: {
        width: 12,
        height: 12,
        fontSize: 12,
        marginRight: 8,
    },

    menuActions: {
        position: 'absolute',
        right: -16,
        bottom: -12,
        width: 44,
        height: 42,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 4,
        cursor: 'pointer',
        zIndex: 1,
    },

    // Dashboard card
    dashboardCard: {
        width: props => props?.cardWidth,
        height: CARDS_HEIGHTS.dashboards,
        cursor: 'pointer',
    },
    media: {
        height: 94,
        width: 240,
        marginTop: 20,
        marginLeft: 46,
    },
    dashboardTitle: {
        color: theme.palette.neutral[75],
        padding: '8px 0 6px',
        ...theme.mixins.truncate,
    },
    dashboardCardContent: {
        padding: theme.spacing(1, 2),
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
    },

    loader: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },

    // card title truncate
    titleWrapper: {
        position: 'relative',
        marginBottom: 12,
    },
    truncatedText: {
        position: 'absolute',
        opacity: 0,
        left: 0,
        top: 0,
    },
    '@keyframes highlight': {
        '0%': {
            boxShadow:
                '0px 6px 12px 4px rgba(0, 0, 0, 0.3), 1px 1px 5px rgba(232, 232, 232, 0.599496)',
        },
        '90%': {
            boxShadow:
                '0px 6px 12px 4px rgba(0, 0, 0, 0.3), 1px 1px 5px rgba(232, 232, 232, 0.599496)',
        },
        '100%': {
            boxShadow: 'none',
        },
    },
}));
