import React, { FunctionComponent } from 'react';
import { Grid } from 'worldapp-ui';
import { placeholderStyles } from './Placeholder.styles';
import { PlaceholderBlock } from './PlaceholderBlock';
import { isMobile } from '../../utils/browser.utils';

const HeaderPlaceholder: FunctionComponent = () => {
    const classes = placeholderStyles();
    return (
        <div
            className={classes.headerWrapper}
            data-testid="placeholder-header"
            data-ismobile={isMobile}
        >
            <Grid container={true} justify="space-between" alignItems="center" wrap="nowrap">
                <Grid item={true}>
                    <PlaceholderBlock height={40} width={374} />
                </Grid>
                <Grid item={true}>
                    <PlaceholderBlock height={40} width={170} />
                </Grid>
            </Grid>
            <div className={classes.headerSecondRow}>
                <Grid container={true} spacing={1}>
                    <Grid item={true}>
                        <PlaceholderBlock height={32} width={128} />
                    </Grid>
                    <Grid item={true}>
                        <PlaceholderBlock height={32} width={128} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default HeaderPlaceholder;
