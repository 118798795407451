import { extractTextFromHtml } from 'worldapp-fe-utils';
import { FETCH_FORM, FETCH_FORMS, FormsAction, FormsState } from './forms.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionPromise } from '../index';
import { getForms } from './formsSelector';

export const initialState: FormsState = {
    loading: true,
    forms: [],
};

export default function reducer(state = initialState, action: FormsAction): FormsState {
    switch (action.type) {
        case FETCH_FORMS:
            const forms = action.forms.map(form => ({
                ...form,
                title: extractTextFromHtml(form.title),
            }));
            return {
                ...state,
                loading: false,
                forms,
            };
        case FETCH_FORM:
            if (!action.form) return state;
            const updatedForms = state.forms.map(form =>
                form.id === action.form.id ? { ...action.form, title: form.title } : form,
            );
            return {
                ...state,
                forms: updatedForms,
            };
        default:
            return state;
    }
}

export const formsCreator = {
    fetchForms: (): ThunkActionPromise => {
        return async (dispatch, getState, { api, featureSelector }) => {
            const response = await (featureSelector.getOptimizeFormsTitleLoadFeature(getState())
                ? api.getFormsTitles()
                : api.getForms());
            dispatch({
                type: FETCH_FORMS,
                forms: response.items,
            });
        };
    },

    updateForm: (formId: number | null): ThunkActionPromise => {
        return async (dispatch, getState, { api }) => {
            const form = getForms(getState()).find(f => f.id === formId);
            if (!formId || !form || form.settings) return;
            try {
                const response = await api.getForm(formId);
                dispatch({
                    type: FETCH_FORM,
                    form: response,
                });
                // eslint-disable-next-line no-empty
            } catch (e) {}
        };
    },
};
