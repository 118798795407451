import { SelectDropdownItem } from 'worldapp-ui/shared/dropdowns/SelectDropdown';
import { createSelector } from 'reselect';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import {
    NotCustomColumn,
    SortBy,
    SortColumnType,
    SortOrder,
    TasksSortState,
} from './tasksSort.types';
// eslint-disable-next-line import/no-cycle
import { initialSort } from './tasksSort';
import { AppState } from '../AppState.types';
import { getContentItemId, isContentCategoryTasks } from '../content/contentSelector';
import { getTaskDefinitionsById } from '../taskDefinitions/taskDefinitionsSelector';

export interface PredefinedOption {
    name: string;
    sortBy: SortBy | null;
    value: string;
}

export interface SortColumnOption extends SelectDropdownItem, PredefinedOption {
    isActive: boolean;
}

export const predefinedOptions: PredefinedOption[] = [
    {
        name: 'TasksSort.Summary',
        sortBy: { column: SortColumnType.Summary },
        value: 'task-name',
    },
    {
        name: 'TasksSort.Assignee',
        sortBy: { column: SortColumnType.Assignee },
        value: 'assignee',
    },
    {
        name: 'TasksSort.Status',
        sortBy: { column: SortColumnType.Status },
        value: 'status',
    },
    {
        name: 'TasksSort.DueDate',
        sortBy: { column: SortColumnType.DueDate },
        value: 'due-date',
    },
    {
        name: 'TasksSort.AssignDate',
        sortBy: { column: SortColumnType.AssignDate },
        value: 'assign-date',
    },
];

export const getTasksSortState = ({ tasksSort }: AppState): TasksSortState => tasksSort;

export const getCurrentTaskSort = createSelector(
    isContentCategoryTasks,
    getContentItemId,
    getTasksSortState,
    (isContentTasks, taskDefinitionId, tasksSort) => {
        if (!isContentTasks || !taskDefinitionId) return initialSort();
        return tasksSort[taskDefinitionId] || initialSort();
    },
);

export const getPredefinedOptions = createSelector(
    isContentCategoryTasks,
    getCurrentTaskSort,
    (isTasks, tasksSort): SortColumnOption[] => {
        if (!isTasks) return [];
        return predefinedOptions.map((option: PredefinedOption) => {
            return {
                isActive: !!(option.sortBy && option.sortBy.column === tasksSort.column),
                ...option,
                disabled: option.sortBy === null,
            };
        });
    },
);

export const getCustomOptions = createSelector(
    isContentCategoryTasks,
    getTaskDefinitionsById,
    getContentItemId,
    getCurrentTaskSort,
    (isTasks, taskDefinitionsById, itemId, taskDefSort): SortColumnOption[] => {
        if (!isTasks || itemId === null) return [];
        const taskDefinition = taskDefinitionsById[itemId];
        if (taskDefinition === undefined) return [];
        return Array.from(taskDefinition.customProperties)
            .sort((a, b) => {
                return a.title.localeCompare(b.title);
            })
            .map(({ title }) => {
                return {
                    name: title,
                    sortBy: {
                        column: SortColumnType.Custom,
                        property: title,
                    },
                    isActive:
                        taskDefSort.column === SortColumnType.Custom &&
                        taskDefSort.property === title,
                    value: title,
                };
            });
    },
);

export const getTaskSortOptions = createSelector(
    getPredefinedOptions,
    getContentItemId,
    getCustomOptions,
    (predefined, itemId, customOptions): SortColumnOption[] => {
        if (predefined.length === 0) return [];
        if (itemId === ALL_TASKS_ID) {
            return predefined;
        }

        return [...predefined, ...customOptions];
    },
);

type TaskSort =
    | {
          column: NotCustomColumn;
          order: SortOrder;
      }
    | {
          column: SortColumnType.Custom;
          property: string;
          order: SortOrder;
      };

export const getTaskSort = (tasksSort: TasksSortState, taskDefinitionId: number): TaskSort => ({
    ...(tasksSort[taskDefinitionId] ?? initialSort()),
});

export const getCurrentTaskSortOrder = createSelector(getCurrentTaskSort, sort => sort.order);
