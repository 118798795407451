import React, { FunctionComponent } from 'react';
import { Grid } from 'worldapp-ui';
import { PlaceholderBlock } from './PlaceholderBlock';
import { placeholderStyles } from './Placeholder.styles';

const MenuPlaceholder: FunctionComponent = () => {
    const classes = placeholderStyles();
    return (
        <Grid
            container={true}
            spacing={3}
            direction="column"
            className={classes.menuWrapper}
            data-testid="placeholder-menu"
        >
            <Grid item={true}>
                <PlaceholderBlock height={24} />
            </Grid>
            <Grid item={true}>
                <PlaceholderBlock height={24} />
            </Grid>
            <Grid item={true}>
                <PlaceholderBlock height={24} />
            </Grid>
            <Grid item={true}>
                <PlaceholderBlock height={24} />
            </Grid>
        </Grid>
    );
};

export default MenuPlaceholder;
