import React, { Fragment, ElementType } from 'react';
import { makeStyles, useTheme, Text, resolution } from 'worldapp-ui';

const useStyles = makeStyles(theme => ({
    hiddenContent: {
        opacity: 0,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.sharp,
        }),
    },
    title: {
        color: theme.palette.neutral.main,
        marginLeft: theme.spacing(2),
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        paddingRight: '0 !important',
    },
}));

interface IconProps {
    htmlColor: string;
}

export type NavigationTreeSummaryItemProps = {
    isHeaderActive: boolean;
    isCompactView: boolean;
    title: string;
    IconComponent: ElementType<IconProps>;
};

export const NavigationTreeSummaryItem = ({
    isCompactView,
    title,
    IconComponent,
    isHeaderActive,
}: NavigationTreeSummaryItemProps): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const iconColor = theme.palette.neutral && theme.palette.neutral[75];

    return (
        <div className={classes.root}>
            <IconComponent htmlColor={iconColor} />
            {!isCompactView && (
                <Fragment>
                    <Text
                        className={classes.title}
                        variant={isHeaderActive ? 'r16m' : 'r16r'}
                        size={resolution.loRes}
                        data-testid="summary-label"
                    >
                        {title}
                    </Text>
                </Fragment>
            )}
        </div>
    );
};
